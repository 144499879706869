import { Pipe, PipeTransform } from '@angular/core';
import { Filter, FilterDateRange, FilterType } from '../models/filter.model';
import { Label } from '../models/label.model';
import * as moment from 'moment';
import { StudentTableData } from '../components/eleguide/education/students/students.component';

@Pipe({
  name: 'filterStudent',
})
export class FilterStudentPipe implements PipeTransform {
  transform(
    students: StudentTableData[],
    filter: Filter[]
  ): StudentTableData[] {
    if (!students || !filter) {
      return students;
    }

    return students.filter(student => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.DATE_RANGE:
            const start = moment((filter.value as FilterDateRange).start)
              .startOf('day')
              .toDate();
            const end = moment((filter.value as FilterDateRange).end)
              .endOf('day')
              .toDate();
            return (
              moment(student.user.entryDate).isSameOrAfter(start) &&
              moment(student.user.entryDate).isSameOrBefore(end)
            );

          case FilterType.LABEL:
            if (this.isLabel(filter.value)) {
              return student.user.status?.id === filter.value.id;
            }
            return false;
          default:
            return true;
        }
      });
    });
  }

  private isLabel(obj: any): obj is Label {
    return obj && typeof obj === 'object' && 'name' in obj && 'color' in obj;
  }
}
