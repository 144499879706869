import { Component } from '@angular/core';
import { NavLink } from 'src/app/models/nav-link.model';
import { Feature, Permission } from 'src/app/models/permission.model';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponente {
  public navLinks: NavLink[] = [
    {
      label: 'Stammdaten',
      link: 'master-data',
      feature: Feature.ONBOARDING,
      permission: Permission.VIEW,
    },
    {
      label: 'Aus- und Weiterbildungsgänge',
      link: 'education-course',
      feature: Feature.ONBOARDING,
      permission: Permission.VIEW,
    },
    {
      label: 'Benutzer und Rollen',
      link: 'user-roles',
      feature: Feature.ONBOARDING,
      permission: Permission.VIEW,
    },
    {
      label: 'Kurserstellung & Prüfungen',
      link: 'course-creation',
      feature: Feature.ONBOARDING,
      permission: Permission.VIEW,
    },
    {
      label: 'Übersicht',
      link: 'overview',
      feature: Feature.ONBOARDING,
      permission: Permission.VIEW,
    },
  ];

  ngOnInit(): void {}
}
