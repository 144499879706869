import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { EventDate } from 'src/app/models/event.model';
import { Room } from 'src/app/models/room.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EventService } from 'src/app/services/event.service';
import { RoomOrganizationService } from 'src/app/services/room-organization.service';
import { RoomService } from 'src/app/services/room.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-event-room-planning',
  templateUrl: './event-room-planning.component.html',
  styleUrls: ['./event-room-planning.component.scss'],
})
export class EventRoomPlanningComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() events: EventDate[];
  @Input() participants: number = 2; // default value for participants, because in patient sessions there are always 2 participants
  @Input() numberOfLecturers?: number;
  @Input() isCourse: boolean = false;
  @Output() onRoomPlanningClose = new EventEmitter<EventDate[]>();

  public totalPersons: number;

  private initialEvents: EventDate[];
  public selectedRow: EventDate;
  public rooms: Room[];
  public bookRoomForEveryEvent: boolean = true;

  public pastEventDates: EventDate[] = [];
  public futureEventDates: EventDate[] = [];

  public allEventDates: EventDate[] = [];
  public allEventDatesLoaded: boolean = false;
  public allRoomsLoaded: boolean = false;

  public displayedColumns: string[] = ['event', 'room'];
  public dataSource: MatTableDataSource<EventDate> = new MatTableDataSource();
  private tableData: EventDate[];

  public showPastEventDates: boolean = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (this.hasUnsavedChanges()) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private roomorganizationService: RoomOrganizationService,
    private roomService: RoomService,
    public dialog: MatDialog,
    private userService: UserService,
    private eventService: EventService,
    private router: Router,
    private cancellationService: CancellationService
  ) {}

  async ngOnInit() {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: NavigationEnd) => {
        if (
          event.url?.includes('room-organization') &&
          this.router.url.includes('room-organization')
        ) {
          this.cancelRoomPlanning();
        }
      });

    this.totalPersons = this.participants + (this.numberOfLecturers ?? 0);

    if (this.events.length > 0) {
      // decrypt the events
      this.events = this.events
        ? await Promise.all(
            this.events.map(async event => {
              event.room = event.room
                ? await this.roomService.parseBackendRoom(event.room)
                : null;
              return event;
            })
          )
        : [];

      // create a deep copy of the objects so the original objects won't be updated
      this.initialEvents = JSON.parse(JSON.stringify(this.events));
      this.events = JSON.parse(JSON.stringify(this.events));

      // get past and future events
      const { pastEventDates, futureEventDates } =
        this.getPastAndFutureEventDates(this.events);
      this.pastEventDates = pastEventDates;
      this.futureEventDates = futureEventDates;

      // get all Rooms of institute
      const id_institute = this.userService.currentUser.id_institute;
      this.roomService
        .getAllRooms(id_institute)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            console.debug('getAllRooms Backend Response', response);
            if (!response.success) {
              console.error(response.message);
              return;
            }
            this.rooms = response.data
              ? await Promise.all(
                  response.data?.map(async (roomData: any): Promise<Room> => {
                    return await this.roomService.parseBackendRoom(roomData);
                  })
                )
              : [];
            this.allRoomsLoaded = true;
          },
          error: error => {
            console.error('error', error);
          },
        });

      // get all Events of institute for room planning in event period
      const endDate = this.events[this.events.length - 1].endDate;
      this.eventService
        .getAllInstituteEventDatesForRoomPlanning('eventPeriod', endDate)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: response => {
            console.debug('all events in event period', response);
            if (!response.success) {
              console.error(response.message);
              return;
            }
            this.allEventDates = response.data;
            this.allEventDatesLoaded = true;
          },
          error: error => {
            console.error('error', error);
          },
        });

      // Create table data to display events
      this.tableData = this.showPastEventDates
        ? this.pastEventDates
        : this.futureEventDates;

      this.dataSource = new MatTableDataSource(this.tableData);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'event':
            return item.startDate;
          default:
            return item[property];
        }
      };
      this.dataSource.sort = this.sort;

      // set selected row to first row when upcoming events are shown
      this.selectedRow = this.showPastEventDates ? null : this.tableData[0];
      console.debug('this.selectedRow', this.selectedRow);
    }
  }

  /**
   * changing selectedRow to the row that the user has clicked
   * @param row row of table
   */
  onChangeSelectedRow(row: EventDate) {
    this.selectedRow = row;
  }

  /**
   *
   * @param row row of table
   * @returns if the selected row is the current selected row
   */
  isSelectedRow(row: EventDate) {
    return this.selectedRow === row;
  }

  /**
   * rowClickable
   */
  public rowClickable(row: EventDate) {
    const currentDate = new Date();
    // if row end date is in the past, row is not clickable
    const eventDateEndDate = new Date(row.endDate);
    if (eventDateEndDate < currentDate) {
      return false;
    }
    return true;
  }

  /**
   *
   * @param room
   * @param eventDate
   * @returns if the room is available for event
   */
  isRoomAvailable(room: Room, eventDate: EventDate) {
    if (!room.available) {
      return false;
    }

    return this.roomorganizationService.isRoomAvailable(
      this.allEventDates,
      room,
      eventDate,
      this.totalPersons
    );
  }

  /**
   * getReasonForRoomNotAvailable
   * get the reason why the room is not available
   * @param room
   * @returns
   */
  public getReasonForRoomNotAvailable(room: Room): string {
    if (!room.available) {
      return 'Raum inaktiv';
    }

    if (this.totalPersons > room.seatNumber) {
      return 'Zu wenig Plätze';
    }

    return 'Belegt';
  }

  /**
   * Handles the button click on a room and tries to book it for the event
   * @param roomId
   */
  onRoomSelect(room: Room) {
    if (this.isRoomAvailable(room, this.selectedRow)) {
      // Check if more then 1 future event has no room
      if (this.futureEventDatesWithNoRoom() > 1 && this.bookRoomForEveryEvent) {
        // Open dialog and let the user decide, if the room should be used for all events with no room
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: {
            title: 'Raumplanung',
            message:
              'Soll dieser Raum für jeden Termin ohne Raum gebucht werden? (Falls möglich)',
          },
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult) {
            // try to book room for every event with no room
            this.futureEventDates.forEach(event => {
              if (!event.room) {
                if (this.isRoomAvailable(room, event)) {
                  event.room = room;
                }
              }
            });
            this.updateTableData();
          } else {
            this.bookRoomForEveryEvent = false;
            this.selectedRow.room = room;
            this.updateTableData();
          }
        });
      } else {
        // update event where id is equal to selected row id
        this.selectedRow.room = room;
        this.updateTableData();
      }
    }
  }

  /**
   * update table data after room was selected, to show room in table
   */
  updateTableData() {
    // Create table data to displa correct events
    this.tableData = this.showPastEventDates
      ? this.pastEventDates
      : this.futureEventDates;

    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sort = this.sort;
    const selectedRow = this.tableData.find(row => row === this.selectedRow);
    this.selectedRow = selectedRow;
  }

  /**
   * returns number of future events with no room
   */
  private futureEventDatesWithNoRoom() {
    const eventsWithNoRoom = this.futureEventDates.filter(event => !event.room);

    return eventsWithNoRoom.length;
  }

  /**
   * restore initial rooms of events and emit onRoomPlanningClose with the events
   */
  cancelRoomPlanning() {
    // check if there are unsaved changes
    if (this.hasUnsavedChanges()) {
      this.onRoomPlanningClose.emit(null);
      return;
    }

    this.onRoomPlanningClose.emit(this.initialEvents);
  }

  /**
   * emit the onRoomPlanningClose Event with the events
   */
  saveRoomPlanning() {
    this.onRoomPlanningClose.emit(this.events);
  }

  /**
   * getPastAndFutureEvents
   * @param eventDates
   * @returns past and future events
   */
  private getPastAndFutureEventDates(eventDates: EventDate[]): {
    pastEventDates: EventDate[];
    futureEventDates: EventDate[];
  } {
    const currentDate = new Date();
    let pastEventDates = eventDates.filter(
      eventDate => new Date(eventDate.endDate) < currentDate
    );
    let futureEventDates = eventDates.filter(
      eventDate => new Date(eventDate.endDate) >= currentDate
    );

    return { pastEventDates, futureEventDates };
  }

  /**
   * onShowUpcomingEventDatesChanged
   * changes the table data to show past or future events
   * @param slideToggleData
   * @returns void
   */
  public onShowUpcomingEventDatesChanged(
    slideToggleData: MatSlideToggleChange
  ) {
    this.showPastEventDates = slideToggleData.checked;
    if (this.showPastEventDates) {
      this.tableData = this.pastEventDates;
    } else {
      this.tableData = this.futureEventDates;
    }
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.sort = this.sort;

    // set selected row to first row when upcoming events are shown
    this.selectedRow = this.showPastEventDates ? null : this.tableData[0];
  }

  /**
   * hasUnsavedChanges
   * @returns if there are unsaved changes
   */
  public hasUnsavedChanges(): boolean {
    return JSON.stringify(this.initialEvents) !== JSON.stringify(this.events);
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
