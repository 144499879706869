import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/configs/environment';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
    private cookieService: CookieService
  ) {}

  public async isAuthenticated(): Promise<Boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const sessionCookie = this.cookieService.get(
        'Impact.SessionTimeOutCookie'
      );

      if (sessionCookie) {
        // check if session is expired
        if (this.isSessionExpired()) {
          window.location.href = environment.authority_short + 'login';
          return;
        }
        // check if the user is logged in via TGP
        this.isLoggedIn().subscribe({
          next: response => {
            console.debug('isLoggedIn backend response', response);
            if (response.body === 'true') {
              // user is logged in via TGP but not in local account
              if (!this.userService.currentUser) {
                this.loginUser().subscribe({
                  next: async response => {
                    console.debug('loginUser backend response', response);
                    if (!response?.success) {
                      console.debug(response?.message);
                      resolve(false);
                      return;
                    }
                    this.userService.currentUser =
                      await this.userService.parseBackendUser(response.data);
                    if (
                      !this.userService.currentUser.id_institute ||
                      (!this.userService.currentUser.id_role &&
                        !this.userService.currentUser.isSuperadmin)
                    ) {
                      resolve(false);
                    }
                    resolve(true);
                  },
                  error: error => {
                    console.error(error);
                    resolve(false);
                  },
                });
                // user is logged in via TGP and in local account
              } else {
                resolve(true);
              }
            } else {
              resolve(false);
            }
          },
          error: error => {
            console.error(error);
            resolve(false);
          },
        });
      } else {
        // no active login
        resolve(false);
      }
    });
  }

  /**
   *
   * @description check if session is expired
   * @returns
   */
  public isSessionExpired(): boolean {
    const sessionTimeoutCookie = this.cookieService.get(
      'Impact.SessionTimeOutCookie'
    );
    const sessionTimeout = moment(new Date(sessionTimeoutCookie + ' UTC'));
    console.debug('sessionCookie', sessionTimeout);
    return moment().isAfter(sessionTimeout);
  }

  /**
   *
   * @description check if user is logged in through tgp
   * @returns
   */
  public isLoggedIn(): Observable<any> {
    return this.http.get(environment.authority_short + 'isLoggedIn', {
      responseType: 'text',
      observe: 'response',
    });
  }

  /**
   *
   * @description login user to tgp
   * @returns
   */
  public loginTgp(): Observable<any> {
    return this.http.get(environment.authority_short + 'login', {
      responseType: 'text',
      observe: 'response',
    });
  }

  /**
   *
   * @description logout user from tgp
   * @returns
   */
  public logoutTgp(): Observable<any> {
    return this.http.get(environment.authority_short + 'logout', {
      responseType: 'text',
      observe: 'response',
    });
  }

  /**
   *
   * @description login cidaas user to local account
   * @returns
   */
  public loginUser(): Observable<any> {
    return this.http.get(environment.authority_short + 'user/loginUser.php');
  }

  /**
   * logout
   */
  public logout() {
    this.logoutTgp().subscribe({
      next: response => {
        console.debug('Logout TGP Result:', response);
        if (response.status == 200) {
          this.router.navigate(['logout']);
        }
      },
      error: error => {
        console.error(error);
        this.router.navigate(['logout']);
      },
    });
  }

  /**
   *
   * @description check if tgp is reachable
   * @returns
   */
  public checkTgpOnline(): Observable<any> {
    return this.http.get(environment.authority_short + 'status', {
      responseType: 'text',
      observe: 'response',
    });
  }
}
