import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { Institute } from 'src/app/models/institute.model';
import { User } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { InstituteService } from 'src/app/services/institute.service';
import { UserService } from 'src/app/services/user.service';

interface InstituteUser extends User {
  institute?: {
    id: number;
    name: string;
  };
}

@Component({
  selector: 'app-update-user-dialog',
  templateUrl: './update-user-dialog.component.html',
  styleUrls: ['./update-user-dialog.component.scss'],
})
export class UpdateUserDialogComponent implements OnInit, OnDestroy {
  public availableInstitutes: Institute[];
  public editUserForm: FormGroup;
  public user: InstituteUser;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<UpdateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { user: User },
    private instituteService: InstituteService,
    private userService: UserService,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {
    this.user = data.user;
  }

  public ngOnInit(): void {
    this.editUserForm = new FormGroup({
      firstname: new FormControl(this.user.name.firstname),
      lastname: new FormControl(this.user.name.lastname),
      genderTitle: new FormControl(this.user.name.genderTitle),
      academicTitle: new FormControl(this.user.name.academicTitle),
      email: new FormControl(this.user.email),
      institute: new FormControl(this.user.institute?.id),
      role: new FormControl(this.user.id_role),
    });
    this.getAllInstitutes();
  }

  private getAllInstitutes() {
    this.instituteService
      .getAllInstitutes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          console.debug('getAllInstitutes backend response: ', response);
          if (!response.success) {
            console.error(response.message);
            return;
          }
          this.availableInstitutes = response.data?.map((data: any) => {
            return this.instituteService.parseBackendInstitute(data);
          });
        },
      });
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public async onSave(): Promise<void> {
    // TODO
    this.user.id_institute = this.editUserForm.value.institute;
    this.user.id_role = this.editUserForm.value.role;
    this.user.name.firstname = this.editUserForm.value.firstname;
    this.user.name.lastname = this.editUserForm.value.lastname;
    this.user.name.genderTitle = this.editUserForm.value.genderTitle;
    this.user.name.academicTitle = this.editUserForm.value.academicTitle;
    this.user.email = this.editUserForm.value.email;

    const observable = await this.userService.updateUser(this.user);
    observable.subscribe({
      next: response => {
        console.debug('updateUser backend response: ', response);
        if (!response.success) {
          console.error(response.message);
          this.alertService.showErrorAlert(
            'Fehler',
            'Der Benutzer konnte nicht aktualisiert werden'
          );
          return;
        }
        this.alertService.showSuccessAlert(
          'Benutzer aktualisiert',
          'Der Benutzer wurde erfolgreich aktualisiert'
        );
        this.dialogRef.close(true);
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          'Der Benutzer konnte nicht aktualisiert werden'
        );
      },
    });
  }

  public onDelete(): void {
    // TODO
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
