<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Kandidatenübersicht</h1>
      <h3 class="hasomed-text-h3">Verwalten Sie Ihre Kandidaten.</h3>
    </div>
    <form class="header-buttons" [formGroup]="searchForm">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="exportDataToExcel()">
        <i class="fa-light fa-arrow-down-to-line hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Liste exportieren</span>
      </button>

      <!-- Search -->
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche"
          (keyup)="applySearch($event)"
          #input />
      </div>

      <div class="hasomed-date-range">
        <mat-date-range-input
          class="hasomed-text-input hasomed-date-range-input"
          formGroupName="dateRange"
          [rangePicker]="picker">
          <input
            id="startDate"
            matStartDate
            placeholder="Startdatum"
            formControlName="start"
            readonly />
          <input
            id="endDate"
            matEndDate
            placeholder="Enddatum"
            formControlName="end"
            (dateChange)="applyDateRange()"
            readonly />
        </mat-date-range-input>
        <mat-datepicker-toggle
          class="hasomed-date-range-toggle"
          matIconSuffix
          [for]="picker">
          <mat-icon
            matDatepickerToggleIcon
            class="calendar-toggle-icon fa-light fa-calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          [restoreFocus]="false"></mat-date-range-picker>
      </div>

      <!-- Filter Button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        [ngClass]="{
          'hasomed-button-primary': hasActiveFilterValue(studentFilter, true),
          'hasomed-button-white-grey': !hasActiveFilterValue(
            studentFilter,
            true
          ),
          'hasomed-button-active': filterOpened
        }"
        [matMenuTriggerFor]="filterMenu"
        (onMenuOpen)="filterOpened = true"
        (onMenuClose)="filterOpened = false">
        <i class="fa-light fa-filter-list hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Filter</span>
      </button>

      <!-- Filter Menu -->
      <mat-menu class="filter-overlay" #filterMenu="matMenu">
        <app-filter
          *ngIf="filterOpened"
          [filters]="studentFilter"
          (filterChanged)="studentFilterChanged($event)"
          [labelFilter]="true">
        </app-filter>
      </mat-menu>
    </form>
  </div>
  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="studentFilter"
      (filterChanged)="studentFilterChanged($event)">
    </app-filter-tags>
    <div
      class="table"
      [class.table-bicolor]="dataSource?.filteredData?.length > 1">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Student column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Kandidaten
            <app-custom-table-sort-icon [sort]="sort" columnName="name">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="user-container">
              <div
                class="user-icon"
                (click)="openProfilePictureDialog(row.user)"
                matTooltip="Profilbild ansehen">
                <img
                  src="{{
                    row.user.profilePicture ?? '../assets/img/user.jpg'
                  }}"
                  alt=""
                  class="user-icon-image" />
              </div>
              <div
                class="user-info"
                (click)="openStudentDetail(row.user.id)"
                matTooltip="Profil öffnen">
                <span class="user-name hasomed-text-body">
                  {{ row.user.name.academicTitle }}
                  {{ row.user.name.firstname }}
                  {{ row.user.name.lastname }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- userIdentifier column -->
        <ng-container matColumnDef="userIdentifier">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Bildungs-ID
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="userIdentifier">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <span class="hasomed-text-body gray-600">
              {{ row.user.userIdentifier ?? '-' }}
            </span>
          </td>
        </ng-container>

        <!-- Education progress column -->
        <ng-container matColumnDef="education_progress">
          <th
            mat-header-cell
            *matHeaderCellDef
            style="width: 500px"
            mat-sort-header>
            Ausbildungsfortschritt
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="education_progress">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row" style="width: 500px">
            <div class="education_progress">
              <div class="progress-container">
                <div
                  class="progress-bar"
                  ngClass="progress-orange"
                  [ngStyle]="{
                    width: getTotalEducationProgress(row) + '%'
                  }"></div>
              </div>
              <span
                class="progress-label"
                [ngClass]="
                  getTotalEducationProgress(row) < 10 ? 'text-red' : ''
                ">
                {{ getTotalEducationProgress(row) ?? '0' }}%
              </span>
            </div>
          </td>
        </ng-container>

        <!-- entryDate column -->
        <ng-container matColumnDef="entryDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Eintrittsdatum
            <app-custom-table-sort-icon [sort]="sort" columnName="entryDate">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="hasomed-text-body gray-600">
            {{
              row.user.entryDate
                ? (row.user.entryDate | date: 'dd.MM.YYYY')
                : '-'
            }}
          </td>
        </ng-container>

        <!-- Label column -->
        <ng-container matColumnDef="label">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Etikett
            <app-custom-table-sort-icon [sort]="sort" columnName="label">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <div
              *ngIf="row.user.status"
              class="label"
              [style.background]="row.user.status.color">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="label-text">{{ row.user.status.name }}</span>
            </div>
            <span *ngIf="!row.user.status">-</span>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th
            class="gray-600 center-header-cell"
            mat-header-cell
            *matHeaderCellDef>
            Aktionen
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="table-button-icons">
              <button
                *appCheckPermissions="{
                  feature: feature.STUDENT_ADMINISTRATION,
                  permission: permission.VIEW
                }"
                type="button"
                class="table-button-icon"
                [matMenuTriggerFor]="actionsMenu"
                #t="matMenuTrigger"
                matTooltip="Benutzer Aktionen">
                <i class="hasomed-button-icon-sm fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button
                  *appCheckPermissions="{
                    feature: feature.STUDENT_ADMINISTRATION,
                    permission: permission.VIEW
                  }"
                  mat-menu-item
                  type="button"
                  (click)="openStudentDetail(row.user.id)">
                  <div
                    class="flex-r gap-16 align-center action-menu-item gray-600">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600"> Anzeigen </span>
                  </div>
                </button>
                <mat-divider
                  class="menu-divider"
                  *appCheckPermissions="{
                    feature: feature.STUDENT_ADMINISTRATION,
                    permission: permission.ADMIN
                  }"></mat-divider>
                <button
                  *appCheckPermissions="{
                    feature: feature.STUDENT_ADMINISTRATION,
                    permission: permission.ADMIN
                  }"
                  mat-menu-item
                  type="button"
                  (click)="openStudentDetail(row.user.id)">
                  <div
                    class="flex-r gap-16 align-center action-menu-item gray-600">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600"> Bearbeiten </span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <mat-progress-spinner
                color="primary"
                mode="indeterminate"
                diameter="32">
              </mat-progress-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Keine Kandidaten gefunden.
            </div>
          </td>
        </tr>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
