<span mat-dialog-title>{{ title }}</span>
<div mat-dialog-content>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="aspectRatio"
    [roundCropper]="roundCropper"
    [resizeToHeight]="height"
    [onlyScaleDown]="true"
    format="webp"
    [imageQuality]="92"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()">
  </image-cropper>
</div>

<div mat-dialog-actions class="action-buttons">
  <button
    type="button"
    class="hasomed-text-button hasomed-button-primary"
    (click)="onSave()">
    <i class="hasomed-button-icon-sm fa-light fa-check"></i>
    <span class="hasomed-button-text">Speichern</span>
  </button>
  <button
    type="button"
    class="hasomed-text-button hasomed-button-white"
    (click)="onCancel()">
    <i class="hasomed-button-icon-sm fa-light fa-x"></i>
    <span class="hasomed-button-text">Abbrechen</span>
  </button>
</div>
