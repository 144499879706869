<div class="flex-c course-details-main">
  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>
  <div *ngIf="!isLoading" class="flex-c gap-32">
    <h1 class="hasomed-text-h1">
      {{ course.title }}
    </h1>
    <div *ngIf="course.description">
      {{ course.description }}
    </div>
    <div class="flex-c gap-16">
      <div class="flex-r gap-32 line-height-45">
        <span class="title">Kurstyp</span>
        <span>{{ course.type?.name }}</span>
      </div>
      <mat-divider></mat-divider>
      <div class="flex-r gap-32 line-height-45">
        <span class="title">Aus- und Weiterbildungsgang</span>
        <span>{{ getEducationCourseTitles(course) }}</span>
      </div>
      <mat-divider></mat-divider>
      <div class="flex-r gap-32 line-height-45">
        <span class="title">Kurs</span>
        <div class="flex-r gap-8 align-center">
          <i class="fa-light fa-lock"></i>
          <span>{{ course.closed ? 'geschlossen' : 'offen' }}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="flex-r gap-32 line-height-45">
        <span class="title">Art der Prüfungen</span>
        <span *ngIf="!course.examTypes || course.examTypes?.length == 0">
          -
        </span>
        <span *ngFor="let examType of course.examTypes; let last = last">
          {{ examType?.name }}<span *ngIf="!last">, </span>
        </span>
      </div>
      <mat-divider *ngIf="course.registered"></mat-divider>
      <div *ngIf="course.registered" class="flex-r gap-32 line-height-45">
        <span class="title">Online beitreten</span>
        <span>{{ course.link ?? '-' }}</span>
      </div>
      <mat-divider *ngIf="!isStudent"></mat-divider>
      <div class="flex-r gap-32 line-height-45" *ngIf="!isStudent">
        <span class="title">Notizen für den Kurs</span>
        <span>{{ course.internalNote ?? '-' }}</span>
      </div>
    </div>
  </div>
</div>
