<div class="component-content">
  <!-- HEADER  -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Persönliche Daten</h1>
      <h3 class="hasomed-text-h3">Füllen Sie Ihre Daten aus.</h3>
    </div>
    <div class="header-buttons">
      <!-- Submit button -->
      <button
        type="button"
        (click)="onEdit()"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-pen hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Anrede und Titel</span>
        <span class="label-description">Ihre Anrede und Titel</span>
      </div>
      <div
        class="user-detail-value"
        *ngIf="!user.name.genderTitle && !user.name.academicTitle; else titles">
        Noch nicht hinterlegt.
      </div>
      <ng-template #titles>
        <div class="user-detail-value">
          {{ user.name.genderTitle }} {{ user.name.academicTitle }}
        </div>
      </ng-template>
    </div>
    <mat-divider></mat-divider>
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Name</span>
        <span class="label-description">Ihr Vorname und Nachname</span>
      </div>
      <div class="user-detail-value">
        {{ user.name.firstname }} {{ user.name.lastname }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Email -->
    <div class="formular-row">
      <label class="formular-row-label" for="email">
        <span class="label-title">E-Mail Adresse</span>
        <span class="label-description">Ihre E-Mail Adresse</span>
      </label>
      <div class="user-detail-value">
        <i class="fa-light fa-envelope"></i>
        <span>{{ user.email }}</span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Birthdate -->
    <div class="formular-row">
      <div class="formular-row-label">
        <div class="label-title">Geburtsdatum</div>
        <div class="label-description">Ihr Geburtsdatum</div>
      </div>
      <div class="user-detail-value">
        <span>{{
          (user?.birthdate | date: 'dd.MM.yyyy') ?? 'Noch nicht hinterlegt.'
        }}</span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Student number -->
    <div class="formular-row">
      <div class="formular-row-label">
        <ng-container [ngSwitch]="user?.id_role">
          <span class="label-title" *ngSwitchCase="3">
            Ihre "Lehrpersonalnummer"
          </span>
          <span class="label-title" *ngSwitchCase="4">
            Ihre "Matrikelnummer"
          </span>
          <span class="label-title" *ngSwitchDefault>
            Ihre Personalnummer
          </span>
        </ng-container>
        <span class="label-description">Wird automatisch vergeben</span>
      </div>
      <div class="user-detail-value">
        <i class="fa-light fa-lock"></i>
        <span>{{
          user?.userIdentifier != '' && user?.userIdentifier
            ? user?.userIdentifier
            : (user?.id_role === 4
                ? '"Matrikelnummer"'
                : user?.id_role === 3
                  ? '"Lehrpersonalnummer"'
                  : 'Personalnummer') + ' noch nicht definiert'
        }}</span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- entry date -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Eintrittsdatum</span>
        <span class="label-description">Ihr Eintrittsdatum</span>
      </div>
      <div class="user-detail-value">
        <i class="fa-light fa-lock"></i>
        <span>{{
          (user?.entryDate | date: 'dd.MM.yyyy') ??
            'Ihr Eintrittsdatum wurde noch nicht hinterlegt'
        }}</span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Avatar -->
    <div class="formular-row">
      <span class="formular-row-label">
        <span class="label-title">Profilfoto</span>
        <span class="label-description">Wird in Ihrem Profil angezeigt</span>
      </span>
      <div class="formular-row-input-image-upload">
        <div
          class="uploaded-image-container"
          (click)="openProfilePictureDialog()">
          <img
            id="user-icon-image"
            class="uploaded-image"
            alt="Ihr Profilbild"
            matTooltip="Ihr Profilbild"
            #userIconImage
            [attr.src]="
              user.profilePicture ?? '../../../../../assets/img/user.jpg'
            " />
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Education Type -->
    <div class="formular-row" *ngIf="user?.id_role === 4">
      <div class="formular-row-label">
        <span class="label-title">Aus- oder Weiterbildungsgang</span>
        <span class="label-description">
          Ihr Aus- oder Weiterbildungsgang
        </span>
      </div>
      <div class="user-detail-value">
        <i class="fa-light fa-lock"></i>
        <span>
          {{
            user?.educationCourse?.title
              ? user?.educationCourse?.title
              : 'Ihr Aus- oder Weiterbildungsgang wurde noch nicht hinterlegt'
          }}
        </span>
      </div>
    </div>
    <mat-divider *ngIf="user?.id_role === 4"></mat-divider>

    <!-- Measles Protection -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Masernstatus</span>
        <span class="label-description"> Impfschutz für Masern </span>
      </div>
      <div class="user-detail-value">
        <i class="fa-light fa-lock"></i>
        <span>
          {{ user?.measlesProtection ? 'Geprüft' : 'Nicht geprüft' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Adresse</span>
        <span class="label-description">
          Ihre Anschrift für Schriftverkehr
        </span>
      </div>
      <div class="flex-c">
        <span
          class="user-detail-value"
          *ngIf="
            !user.address?.street &&
            !user.address?.houseNumber &&
            !user.address?.addressAddition &&
            !user.address?.zipCode &&
            !user.address?.city &&
            !user.address?.country
          ">
          Nicht hinterlegt.
        </span>
        <span
          class="user-detail-value"
          *ngIf="user.address?.street || user.address?.houseNumber">
          {{ user.address?.street }} {{ user.address?.houseNumber }}
        </span>
        <span class="user-detail-value" *ngIf="user.address?.addressAddition">
          {{ user.address?.addressAddition }}
        </span>
        <span
          class="user-detail-value"
          *ngIf="user.address?.zipCode || user.address?.city">
          {{ user.address?.zipCode }} {{ user.address?.city }}
        </span>
        <span class="user-detail-value" *ngIf="user.address?.country">
          {{ user.address?.country }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Document -->
    <div class="formular-row">
      <label class="formular-row-label" for="profileDocuments">
        <span class="label-title">Dokumentenupload</span>
        <span class="label-description">
          Hier können Sie Dokumente für Ihre Aus- und Weiterbildung hochladen
        </span>
      </label>
      <div class="formular-row-input">
        <div class="flex-grow">
          <app-file-view
            *ngFor="let file of user.files"
            [file]="file"></app-file-view>
          <span
            class="user-detail-value"
            *ngIf="!user.files || user.files?.length == 0">
            Keine Dokumente hochgeladen.
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
