<app-event-room-planning
  *ngIf="roomPlanningOpen"
  [events]="events"
  [title]="'Raumplanung für Patient ' + currentTreatmentCase.chiffre"
  subtitle="Planen Sie die Räume der Supervision"
  (onRoomPlanningClose)="roomPlanningChanged($event)">
</app-event-room-planning>

<form
  *ngIf="!roomPlanningOpen"
  class="component-content"
  [formGroup]="supervisionAppointmentForm"
  (ngSubmit)="onSubmit()">
  <!-- HEADER  -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{
          editMode
            ? 'Supervisionstermin bearbeiten'
            : 'Supervisionstermin erstellen'
        }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Berarbeiten Sie Supervisionstermine'
            : 'Erstellen Sie Supervisionstermine'
        }}
      </h3>
    </div>

    <!-- Cancel button -->
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <!-- MAIN CONTENT -->
  <div class="main-content">
    <!-- title -->
    <div class="formular-row">
      <label class="formular-row-label" for="title">
        <span class="label-title">
          Titel
          <span *ngIf="isRequired(supervisionAppointmentForm.get('title'))">
            *
          </span>
        </span>
        <span class="label-description"> Titel für den Eintrag </span>
      </label>

      <div class="formular-row-input">
        <input
          id="patient"
          type="text"
          formControlName="title"
          class="hasomed-text-input" />
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- supervisionType -->
    <div class="formular-row">
      <label class="formular-row-label" for="supervisionType">
        <span class="label-title">
          Supervisionstyp
          <span
            *ngIf="
              isRequired(supervisionAppointmentForm.get('supervisionType'))
            ">
            *
          </span>
        </span>
        <span class="label-description">
          Soll es eine Einzelsupervision oder eine Gruppensupervision sein?
        </span>
      </label>

      <div class="flex-c">
        <div class="formular-row-input">
          <div class="hasomed-toggle-button">
            <button
              type="button"
              class="hasomed-text-button btn-left"
              (click)="
                onSupervisionTypeChanged(treatmentCaseEventType.SUPERVISION)
              "
              [ngClass]="{
                'border-red':
                  supervisionAppointmentForm.get('supervisionType').invalid &&
                  supervisionAppointmentForm.get('supervisionType').touched,
                'hasomed-button-white-grey':
                  supervisionAppointmentForm.get('supervisionType').value !==
                  treatmentCaseEventType.SUPERVISION,
                'hasomed-button-primary':
                  supervisionAppointmentForm.get('supervisionType').value ===
                  treatmentCaseEventType.SUPERVISION
              }">
              <div class="hasomed-button-text">Einzelsupervision</div>
            </button>
            <button
              type="button"
              class="hasomed-text-button btn-right"
              (click)="
                onSupervisionTypeChanged(
                  treatmentCaseEventType.GROUP_SUPERVISION
                )
              "
              [ngClass]="{
                'border-red':
                  supervisionAppointmentForm.get('supervisionType').invalid &&
                  supervisionAppointmentForm.get('supervisionType').touched,
                'hasomed-button-white-grey':
                  supervisionAppointmentForm.get('supervisionType').value !==
                  treatmentCaseEventType.GROUP_SUPERVISION,
                'hasomed-button-primary':
                  supervisionAppointmentForm.get('supervisionType').value ===
                  treatmentCaseEventType.GROUP_SUPERVISION
              }">
              <div class="hasomed-button-text">Gruppensupervision</div>
            </button>
          </div>
        </div>
        <app-error-message
          [formField]="
            supervisionAppointmentForm.get('supervisionType')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- patientAppointment -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Patiententermin
          <span
            *ngIf="
              isRequired(supervisionAppointmentForm.get('patientAppointments'))
            ">
            *
          </span>
        </span>
        <span class="label-description"> Für welchen Patiententermin? </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select
              id="patientAppointments"
              formControlName="patientAppointments"
              #patientAppointment
              multiple>
              <mat-option
                *ngFor="
                  let patientAppointmentEvent of patientAppointmentEventDates
                "
                class="hasomed-text-body"
                [value]="patientAppointmentEvent.id">
                {{ currentTreatmentCase.chiffre }} -
                {{ patientAppointmentEvent.startDate | date: 'dd.MM.yyyy' }} -
                {{ patientAppointmentEvent.room?.name ?? 'Kein Raum' }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <app-error-message
          [formField]="
            supervisionAppointmentForm.get('patientAppointments')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- timeunits -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer in Zeiteinheiten
          <span *ngIf="isRequired(supervisionAppointmentForm.get('timeUnit'))">
            *
          </span>
        </span>
        <span class="label-description"> Eine Einheit 45min </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select id="timeUnit" formControlName="timeUnit" #duration>
              <mat-option value="1" class="hasomed-text-body"> 1 </mat-option>
              <mat-option value="2" class="hasomed-text-body"> 2 </mat-option>
              <mat-option value="3" class="hasomed-text-body"> 3 </mat-option>
              <mat-option value="4" class="hasomed-text-body"> 4 </mat-option>
              <mat-option value="5" class="hasomed-text-body"> 5 </mat-option>
              <mat-option value="6" class="hasomed-text-body"> 6 </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <app-error-message
          [formField]="
            supervisionAppointmentForm.get('timeUnit')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Termine -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Termine
          <span> * </span>
        </span>
        <span class="label-description"> Termine der Sitzungen </span>
      </div>
      <div class="flex-c">
        <app-event-picker
          *ngIf="!isLoading"
          class="formular-row-input"
          [events]="events"
          [duration]="+supervisionAppointmentForm.get('timeUnit').value * 45"
          [startDate]="startDate"
          [endDate]="endDate"
          [recurrencePattern]="recurrencePattern"
          (eventsChanged)="onEventsChanged($event)"
          (recurrencePatternChanged)="recurrencePattern = $event"
          (datesChanged)="onDatesChanged($event)"
          [parentFormSubmitValidation]="formSubmitted"
          formControlName="dateGroup"
          id="dateGroup"></app-event-picker>
        <app-error-message
          [formField]="
            supervisionAppointmentForm.get('dateGroup')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Raumplanung -->
    <div class="formular-row">
      <label class="formular-row-label" for="room">
        <span class="label-title"> Raumplanung </span>
        <span class="label-description">
          Buchen Sie Räume für Ihre Patienten Sitzungen
        </span>
      </label>
      <div class="formular-row-input">
        <button
          id="room"
          type="button"
          (click)="onOpenRoomPlanning()"
          class="hasomed-text-button hasomed-button-primary button-full-width">
          <i
            class="fa-light fa-arrow-up-right-from-square hasomed-button-icon-sm"></i>
          <span class="hasomed-button-text">Raumplanung</span>
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="supervisor">
        <span class="label-title">
          Supervisor*in
          <span
            *ngIf="isRequired(supervisionAppointmentForm.get('supervisor'))">
            *
          </span>
        </span>
        <span class="label-description"> Wähle eine Supervisor*in </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input-columns gap-32">
          <div class="flex-c gap-16 select-user-container">
            <div class="hasomed-search">
              <i class="fa-light fa-search hasomed-search-icon"></i>
              <input
                id="supervisorSearch"
                class="hasomed-search-input"
                type="text"
                placeholder="Personensuche"
                formControlName="supervisorSearch"
                #supervisorInput />
            </div>
            <div
              id="supervisor"
              class="flex-c vertical-overflow gap-8 users"
              [ngClass]="{
                'error-border':
                  supervisionAppointmentForm.get('supervisor').invalid &&
                  supervisionAppointmentForm.get('supervisor').touched
              }"
              *ngIf="supervisors">
              <div
                class="flex-r user-container"
                [ngClass]="{
                  active: selectedSupervisor?.id === supervisor.id
                }"
                *ngFor="
                  let supervisor of supervisors
                    | searchFilter: supervisorInput.value
                "
                (click)="onSupervisorSelected(supervisor)">
                <div class="flex-r user">
                  <div class="user-icon">
                    <img
                      src="{{
                        supervisor.profilePicture ??
                          '../../../../../assets/img/user.jpg'
                      }}"
                      alt=""
                      class="user-icon-image" />
                  </div>
                  <div class="flex-c justify-center">
                    <span class="hasomed-text-body">
                      {{ supervisor.name.academicTitle }}
                      {{ supervisor.name.firstname }}
                      {{ supervisor.name.lastname }}
                    </span>
                    <span class="hasomed-text-small">
                      Supervisor*in für XYZ
                    </span>
                  </div>
                </div>
                <div *ngIf="selectedSupervisor?.id === supervisor.id">
                  <i class="fa-light fa-circle-check user-check"></i>
                </div>
              </div>
            </div>
            <span *ngIf="supervisors?.length === 0" class="hasomed-text-small">
              Keine Supervisor*innen gefunden
            </span>
          </div>
        </div>
        <app-error-message
          [formField]="
            supervisionAppointmentForm.get('supervisor')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Freitext
          <span
            *ngIf="isRequired(supervisionAppointmentForm.get('internalNote'))">
            *
          </span>
        </span>
        <span class="label-description"> Beschreibung </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="internalNote"
            type="text"
            formControlName="internalNote"
            class="hasomed-textarea-input"
            rows="5"
            placeholder="Beispieltext"></textarea>
        </div>
        <app-error-message
          [formField]="
            supervisionAppointmentForm.get('internalNote')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title"> Dokumente für den Kurs </span>
        <span class="label-description">
          Hier können alle Kursrelevanten Dokumente hochgeladen werden.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            [context]="'supervisionAppointmentUpload'"
            [logs]="true"
            inputId="documentUpload"
            [multiple]="true"
            [scrollTo]="true"
            [showFileList]="true"
            [disabled]="true"
            matTooltip="Der Dokumentenupload ist in dieser Version nicht verfügbar."
            [requiredFileTypes]="requiredFileTypesDocuments"
            maxFileSize="12 MB"
            (change)="onDocumentsSelected($event)">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
