import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Institute } from 'src/app/models/institute.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { InstituteService } from 'src/app/services/institute.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-onb-overview',
  templateUrl: './onb-overview.component.html',
  styleUrls: ['./onb-overview.component.scss'],
})
export class OnbOverviewComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public instituteService: InstituteService,
    private userService: UserService,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    // if a current institute is not set, get the id_institute from the user and get the institute
    // if the user has no id_institute, show the user a message that he has to create an institute first
    if (!this.instituteService.currentInstitute) {
      if (this.userService.currentUser.id_institute) {
        this.instituteService
          .getInstitute(this.userService.currentUser.id_institute)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: response => {
              console.debug('getInstitute Backend Response', response);
              if (!response.success) {
                console.error(response.message);
                this.isLoading = false;
                return;
              }
              this.instituteService.setCurrentInstitute(
                this.instituteService.parseBackendInstitute(response.data)
              );
              this.isLoading = false;
            },
            error: error => {
              console.error(error);
              this.isLoading = false;
            },
          });
      } else {
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }

  /**
   * onCancel
   * cancel the onboarding process
   * @returns void
   */
  public onCancel(): void {
    // TODO
  }

  /**
   * onGoBack
   * navigate to course-creation
   * @returns void
   */
  public onGoBack(): void {
    this.router.navigate(['../course-creation'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onCreateInstitut
   * create a new institute
   * @returns void
   */
  public onCreateInstitut(): void {
    this.instituteService.currentInstitute.is_founded = true;
    this.instituteService
      .foundInstitute(this.instituteService.currentInstitute)
      .subscribe({
        next: response => {
          console.debug('foundInstitute Backend Response', response);
          if (!response.success) {
            console.error(response.message);
            return;
          }
          this.alertService.showSuccessAlert(
            'Institut gegründet',
            'Das Institut wurde erfolgreich gegründet.'
          );
        },
        error: error => {
          console.error(error);
        },
      });
  }

  /**
   * navigateTo
   * navigate to route
   * @param route
   * @returns void
   */
  public navigateTo(route: string): void {
    const relativeRoute = '../' + route;
    this.router.navigate([relativeRoute], { relativeTo: this.activatedRoute });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
