import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Feature, Permission } from 'src/app/models/permission.model';
import { SharedService } from 'src/app/services/shared.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { FeedbackDialogComponent } from '../../shared-components/feedback-dialog/feedback-dialog.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  feature = Feature;
  permission = Permission;

  constructor(
    public sharedService: SharedService,
    public sidenavService: SidenavService,
    private dialog: MatDialog
  ) {}

  /**
   * onOpenFeedbackDialog
   * Opens the feedback dialog
   */
  public onOpenFeedbackDialog() {
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {
      maxWidth: '400px',
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
      }
    });
  }
}
