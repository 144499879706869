<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Etikett verwalten</h1>
      <h3 class="hasomed-text-h3">Verwalte Sie Ihre Etiketten</h3>
    </div>
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="createLabel()">
        <i class="fa-light fa-file-plus hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Neues Etikett</span>
      </button>
    </div>
  </div>
  <div class="main-content">
    <div
      class="table"
      [class.table-bicolor]="dataSource?.filteredData?.length > 1">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Label column -->
        <ng-container matColumnDef="label">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Etikett
            <app-custom-table-sort-icon [sort]="sort" columnName="label">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-900" mat-cell *matCellDef="let label">
            <div class="label" [style.background]="label.color">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="hasomed-text-small label-text">
                {{ label.name }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- createdAt column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Erstellt am
            <app-custom-table-sort-icon [sort]="sort" columnName="createdAt">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-600" mat-cell *matCellDef="let label">
            <span>
              {{ label.createdAt | date: 'dd.MM.yyyy' }}
            </span>
          </td>
        </ng-container>

        <!-- createdBy column -->
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Erstellt von
            <app-custom-table-sort-icon [sort]="sort" columnName="createdBy">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-600" mat-cell *matCellDef="let label">
            <div class="user-container">
              <div class="user-icon">
                <img
                  src="{{
                    label.createdBy.profilePicture ?? '../assets/img/user.jpg'
                  }}"
                  alt=""
                  class="user-icon-image" />
              </div>
              <div class="user-info">
                <span class="user-name hasomed-text-body">
                  {{
                    (label.createdBy.name.academicTitle
                      ? label.createdBy.name.academicTitle + ''
                      : '') +
                      label.createdBy.name.firstname +
                      ' ' +
                      label.createdBy.name.lastname
                  }}
                </span>
                <span class="hasomed-text-small">
                  <ng-container [ngSwitch]="label.createdBy.id_role">
                    <ng-container *ngSwitchCase="role.ADMINISTRATOR">
                      Verwaltung
                    </ng-container>
                    <ng-container *ngSwitchCase="role.LECTURER">
                      Lehrpersonal
                    </ng-container>
                    <ng-container *ngSwitchCase="role.STUDENT">
                      Kandidat*in
                    </ng-container>
                  </ng-container>
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
          <th class="center-header-cell" mat-header-cell *matHeaderCellDef>
            Aktionen
          </th>
          <td class="gray-600" mat-cell *matCellDef="let label">
            <div class="table-button-icons">
              <button
                type="button"
                class="table-button-icon"
                (click)="deleteLabel(label)"
                matTooltip="Etikett löschen">
                <i class="fa-light fa-trash-can"></i>
              </button>
              <button
                type="button"
                class="table-button-icon"
                (click)="editLabel(label)"
                matTooltip="Etikett bearbeiten">
                <i class="fa-light fa-pen"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <mat-progress-spinner
                color="primary"
                mode="indeterminate"
                diameter="32">
              </mat-progress-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Es wurde kein Etikett gefunden.
            </div>
          </td>
        </tr>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
