<div *ngIf="isLoading" class="loading-wrapper">
  <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
  </mat-progress-spinner>
</div>

<div class="card" *ngIf="!isLoading && studentEducationProgress.length > 0">
  <div class="flex-c">
    <div class="education-progress-container flex-r gap-16">
      <div
        class="education-progress flex-c gap-16"
        *ngFor="let studentEducationProgress of studentEducationProgress">
        <div class="flex-r justify-space-between align-center">
          <span class="hasomed-text-small">
            {{ studentEducationProgress.type }}
          </span>
          <button
            *ngIf="this.userService.currentUser.id_role === role.STUDENT"
            type="button"
            class="table-button-icon"
            [matTooltip]="getTooltip(studentEducationProgress.type)"
            (click)="goToPage(studentEducationProgress.type)">
            <i class="fa-light fa-arrow-up-right-from-square"></i>
          </button>
        </div>
        <div class="flex-r gap-16 align-center">
          <div class="progress-bar-container">
            <div
              class="progress-bar"
              ngClass="progress-orange"
              [ngStyle]="{
                width: studentEducationProgress.procentual + '%'
              }"></div>
          </div>
          <span class="progress-label">
            {{
              studentEducationProgress.progress +
                ' / ' +
                studentEducationProgress.contingent +
                'h'
            }}
          </span>
        </div>
      </div>
    </div>

    <mat-divider class="divider"></mat-divider>

    <div class="education-progress flex-c gap-16">
      <span class="hasomed-text-small">
        {{ totalProgress.type }}
      </span>
      <div class="flex-r gap-16 align-center">
        <div class="progress-bar-container">
          <div
            class="progress-bar"
            ngClass="progress-orange"
            [ngStyle]="{
              width: totalProgress.procentual + '%'
            }"></div>
        </div>
        <span class="progress-label">
          {{ totalProgress.progress + ' / ' + totalProgress.contingent + 'h' }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="!isLoading && studentEducationProgress.length === 0">
  Dieser Nutzer hat keinen Aus- oder Weiterbildungsgang!
</div>
