import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/configs/environment';

export const AuthGuard: CanActivateFn = async (
  route,
  state
): Promise<boolean | UrlTree> => {
  const authService = inject(AuthService);

  const isAuthenticated = await authService.isAuthenticated();

  if (isAuthenticated) {
    return true;
  } else {
    window.location.href = environment.authority_short + 'login';
    return false;
  }
};
