export interface EducationCourse {
  id?: number;
  id_institute?: number;
  regulation?: Regulation;
  title: string;
  expertise?: Expertise;
  policy_procedure?: PolicyProcedure;
  professional_association?: ProfessionalAssociation;
  accreditation_year?: number;
  chamber?: string;
  internal_notes?: string;
  external_notes?: string;
  c_theoretical_scientific_h?: number;
  c_theoretical_scientific_auto_training_h?: number;
  c_theoretical_scientific_balintgroupwork_h?: number;
  c_practical_1_h?: number;
  c_practical_1_medical_history_survey_min?: number;
  c_practical_2_h?: string;
  c_treatment_internship_h?: number;
  c_completed_treatment_cases?: number;
  c_treatment_cases?: number;
  c_treatment_cases_min?: number;
  c_treatment_cases_min_h?: number;
  c_supervision_h?: number;
  c_supervision_single_session_h?: number;
  c_supervision_group_session_h?: number;
  c_supervision_medical_history_survey_min?: number;
  c_self_experience_h?: number;
  c_self_experience_sessions_min?: number;
}
export enum Regulation {
  EDUCATION = 'Ausbildungsordnung',
  FURTHER_EDUCATION = 'Weiterbildungsordnung',
  GROUP_THERAPY = 'Gruppentherapie',
  BUILDUP_COURSE = 'Aufbaukurs',
  MEDICAL_PSYCHOTHERAPY = 'Ärztliche Psychotherapieausbildung',
  ADDITIONAL_CERTIFICATION_MEDICAL_PSYCHOTHERAPY = 'Zusatzbezeichnung: Ärztliche Psychotherapie',
  ADDITIONAL_CERTIFICATION_MEDICAL_PSYCHOANALYSIS = 'Zusatzbezeichnung: Ärztliche Psychoanalyse',
}

export enum Expertise {
  ADULT = 'Erwachsene',
  KJP = 'KJP',
  NEUROPSYCHOLOGY = 'Neuropsychologie',
}

export enum PolicyProcedure {
  TP = 'TP',
  AP = 'AP',
  ST = 'ST',
  VT = 'VT',
  AP_TP = 'AP/TP (verklammert)',
}

export enum ProfessionalAssociation {
  NONE = 'Keine',
  DGPT = 'DGPT',
  DGVT = 'DGVT',
  DPTV = 'DPTV',
  DGSF = 'DGSF',
  OTHER = 'Sonstige',
}
