<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Ausbildungsfortschritt</h1>
      <h3 class="hasomed-text-h3">Sehen Sie Ihren Ausbildungsfortschritt</h3>
    </div>
  </div>

  <div class="main-content">
    <app-student-education-progress></app-student-education-progress>
  </div>
</div>
