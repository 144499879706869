import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-default-route',
  templateUrl: './default-route.component.html',
  styleUrls: ['./default-route.component.scss'],
})
export class DefaultRouteComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(data => {
      /* iterate over all routePermissions and check if the user has permission */
      const routePermissions = data.routePermissions;
      for (const route of routePermissions) {
        if (
          this.permissionService.checkPermission(
            route.feature,
            route.permission
          )
        ) {
          /* If the user has the permission, navigate to the corresponding route */
          this.router.navigate([route.route], {
            relativeTo: this.activatedRoute,
          });
          return;
        }
      }

      /* Did not find a route with permission, navigate to dashboard */
      this.router.navigate(['eleguide/dashboard']);

      this.alertService.showErrorAlert(
        'Fehler!',
        'Keine Berechtigung für die Route'
      );
    });
  }
}
