import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TreatmentCaseEventType } from 'src/app/models/event.model';
import { Room } from 'src/app/models/room.model';
import { TreatmentCase } from 'src/app/models/treatment-case.model';
import { User } from 'src/app/models/user.model';
import { CreateEditPatientAppointmentComponent } from '../create-edit-patient-appointment/create-edit-patient-appointment.component';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { EventService } from 'src/app/services/event.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { CalendarEventDetailComponent } from '../../../dashboard/dashboard-my-space/calendar/calendar-event-detail/calendar-event-detail.component';
import { EventType } from 'src/app/models/event.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';

interface TreatmentCaseEventData {
  id: number;
  id_appointment: number;
  type: TreatmentCaseEventType;
  startDate: Date;
  endDate: Date;
  canceled: boolean;
  room: Room;
  supervisor: User;
  supervisorText: string;
}

@Component({
  selector: 'app-treatment-case-details',
  templateUrl: './treatment-case-details.component.html',
  styleUrls: ['./treatment-case-details.component.scss'],
})
export class TreatmentCaseDetailsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public columnsToDisplay = [
    'title',
    'startDate',
    'canceled',
    'room',
    'supervisors',
    'actions',
  ];
  public dataSource: MatTableDataSource<TreatmentCaseEventData> =
    new MatTableDataSource<TreatmentCaseEventData>();
  private events: TreatmentCaseEventData[] = [];
  private selectedTreatmentCaseSubscription: Subscription;
  public selectedTreatmentCase: TreatmentCase;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private treatmentCaseService: TreatmentCaseService,
    private eventService: EventService,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.selectedTreatmentCaseSubscription =
      this.treatmentCaseService.selectedTreatmentCase.subscribe(
        treatmentCase => {
          console.debug('selectedTreatmentCase', treatmentCase);
          this.selectedTreatmentCase = treatmentCase;
          this.events = [];
          this.selectedTreatmentCase.appointments?.forEach(appointment => {
            appointment.eventDates?.forEach(eventDate => {
              this.events.push({
                id: eventDate.id,
                id_appointment: appointment.id,
                type: appointment.type,
                startDate: moment(eventDate.startDate).toDate(),
                endDate: moment(eventDate.endDate).toDate(),
                canceled: eventDate.canceled,
                room: eventDate.room,
                supervisor: appointment.supervisor,
                supervisorText: appointment.supervisorText,
              });
            });
          });

          this.dataSource.data = this.events;

          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'title':
                return item.type;
              case 'room':
                return item.room?.name;
              case 'startDate':
                return item.startDate.getTime();
              default:
                return item[property];
            }
          };
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public onCancelAppointment(appointment: TreatmentCaseEventData) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Termin Absagen',
        message: `Möchten Sie den Termin wirklich absagen?`,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.eventService.cancelEventDate(appointment.id).subscribe({
          next: response => {
            console.debug('eventDate Canceled:', response);

            if (response.success) {
              this.alertService.showSuccessAlert(
                `Termin abgesagt`,
                `Der Termin wurde abgesagt!`
              );

              // update entry from table data without reloading from backend
              const index = this.dataSource.data.indexOf(appointment);
              if (index > -1) {
                this.dataSource.data.at(index).canceled = true;
              }
            } else {
              this.alertService.showErrorAlert(
                `Fehler`,
                `Fehler beim Absagen des Termins`
              );
            }
          },
          error: error => {
            console.error(error);
          },
        });
      }
    });
  }

  /**
   * onViewAppointment
   * view appointment details
   * @param appointment
   * @returns void
   */
  public onViewAppointment(appointment: TreatmentCaseEventData): void {
    console.debug('view appointment', appointment);
    if (appointment.type !== TreatmentCaseEventType.PATIENT_SESSION) {
      this.router.navigate(
        [
          './supervision',
          btoa(this.selectedTreatmentCase.id.toString()),
          btoa(appointment.id_appointment.toString()),
        ],
        { relativeTo: this.activatedRoute }
      );
      return;
    } else {
      this.dialog.open(CalendarEventDetailComponent, {
        width: '500px',
        data: {
          id_eventDate: appointment.id,
          id_treatmentCase: this.selectedTreatmentCase.id,
          type: EventType.PATIENT_SESSION,
          title: 'Patient',
          chiffre: this.selectedTreatmentCase?.chiffre,
          startDate: appointment.startDate,
          endDate: appointment.endDate,
          canceled: appointment.canceled,
          room: appointment.room,
          isTreatmentCaseView: true,
        },
      });
    }
  }

  public onEditAppointment(appointment: TreatmentCaseEventData) {
    if (appointment.type === TreatmentCaseEventType.PATIENT_SESSION) {
      console.debug('edit patient appointment', this.selectedTreatmentCase);
      const dialogRef = this.dialog.open(
        CreateEditPatientAppointmentComponent,
        {
          width: '66%',
          data: {
            treatmentCase: this.selectedTreatmentCase,
            appointment: this.selectedTreatmentCase.appointments.find(
              it => it.id === appointment.id_appointment
            ),
          },
        }
      );

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.treatmentCaseService
            .getTreatmentCase(this.selectedTreatmentCase.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: result => {
                console.debug('TreatmentCase:', result);
                if (result.success) {
                  this.treatmentCaseService
                    .parseBackendTreatmentCase(result.data)
                    .then(treatmentCase => {
                      this.selectedTreatmentCase = treatmentCase;
                      this.treatmentCaseService.selectedTreatmentCase.next(
                        this.selectedTreatmentCase
                      );
                    });
                }
              },
              error: error => {
                console.error(error);
              },
            });
        }
      });
    } else {
      this.router.navigate(
        [
          'edit-supervision-appointment',
          btoa(this.selectedTreatmentCase.id.toString()),
          btoa(appointment.id_appointment.toString()),
        ],
        { relativeTo: this.activatedRoute }
      );
    }
  }

  public onDeleteAppointment(appointment: TreatmentCaseEventData) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Termin Löschen',
        message: `Möchten Sie diesen Termin wirklich löschen?`,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.eventService.deleteEventDate(appointment.id).subscribe({
          next: result => {
            console.debug('Event Date Deleted:', result);

            if (result.success) {
              this.alertService.showSuccessAlert(
                `Termin gelöscht`,
                `Der Termin wurde gelöscht!`
              );

              // delete entry from table data without reloading from backend
              const index = this.dataSource.data.indexOf(appointment);
              if (index > -1) {
                this.dataSource.data = this.dataSource.data.filter(
                  item => item !== appointment
                );
              }
            } else {
              this.alertService.showErrorAlert(
                `Fehler`,
                `Fehler beim Löschen des Termins`
              );
            }
          },
          error: error => {
            console.error(error);
          },
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.selectedTreatmentCaseSubscription.unsubscribe();
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
