import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';

import { CookieService } from 'ngx-cookie-service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './modules/material.module';

import { SharedService } from './services/shared.service';
import { SidenavService } from './services/sidenav.service';
import { UserService } from './services/user.service';

import { Error404Component } from './components/404/error-404.component';
import { LogoutComponent } from './components/authentication/logout/logout.component';
import { EleGuideComponent } from './components/eleguide/eleguide.component';
import { SidenavComponent } from './components/eleguide/sidenav/sidenav.component';

import { LayoutModule } from '@angular/cdk/layout';
import { EducationModule } from './components/eleguide/education/education.module';
import { DashboardModule } from './components/eleguide/dashboard/dashboard.module';
import { OrganizationModule } from './components/eleguide/organization/organization.module';
import { OnboardingModule } from './components/eleguide/onboarding/onboarding.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './modules/shared.module';
import { ConfirmDialogComponent } from './components/shared-components/confirm-dialog/confirm-dialog.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HttpErrorInterceptor } from './services/http-error-interceptor.service';
import { CallbackComponent } from './components/authentication/callback/callback.component';
import { SessionWarningComponent } from './components/shared-components/session-warning/session-warning.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { AuditLogComponent } from './components/eleguide/organization/audit-log/audit-log.component';
import { AdministrationModule } from './components/eleguide/administration/administration.module';
import { FilterStudentPipe } from './pipes/filter-student.pipe';
import { FilterLecturerPipe } from './pipes/filter-lecturer.pipe';
import { TreatmentCaseOverviewComponent } from './components/shared-components/treatment-case-overview/treatment-case-overview.component';
import { SupervisionDetailsComponent } from './components/shared-components/supervision-details/supervision-details.component';
import { FeedbackDialogComponent } from './components/shared-components/feedback-dialog/feedback-dialog.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SettingsModule } from './components/eleguide/settings/settings.module';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY', // date parsed from Input
  },
  display: {
    dateInput: 'DD.MM.YYYY', // date displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    EleGuideComponent,
    SidenavComponent,
    Error404Component,
    ConfirmDialogComponent,
    CallbackComponent,
    AuditLogComponent,
    SessionWarningComponent,
    FilterStudentPipe,
    FilterLecturerPipe,
    TreatmentCaseOverviewComponent,
    SupervisionDetailsComponent,
    FeedbackDialogComponent,
  ],
  providers: [
    SharedService,
    SidenavService,
    UserService,
    MessageService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    DashboardModule,
    EducationModule,
    SharedModule,
    OrganizationModule,
    OnboardingModule,
    FullCalendarModule,
    AdministrationModule,
    ToastModule,
    SettingsModule,
  ],
})
export class AppModule {}
