import { User } from 'oidc-client';
import { EventType } from './event.model';
import { Room } from './room.model';

export interface CalendarEvent {
  title: string;
  start: Date;
  end: Date;
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  extendedProps: {
    id_eventDate: number;
    id_treatmentCase?: number;
    id_course?: number;
    eventType: EventType;
    room: Room;
    canceled: boolean;
    chiffre?: string;
    supervisor?: string;
    lecturer?: string;
    treatmentCaseStudent?: string;
  };
}

export const CalendarEventColors = {
  course: {
    backgroundColor: '#eff3f8',
    borderColor: '#1c53f3',
    textColor: '#1c53f3',
  },
  patient: {
    backgroundColor: '#f9f0e4',
    borderColor: '#f08a00',
    textColor: '#f08a00',
  },
  canceled: {
    backgroundColor: '#ffeeee',
    borderColor: '#f93816',
    textColor: '#f93816',
  },
};
