import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private messageService: MessageService) {}

  /**
   * showSuccessAlert
   * show toastr success alert
   * @param title
   * @param message
   * @param life duration in ms
   */
  public showSuccessAlert(title: string, message: string, life: number = 5000) {
    this.messageService.add({
      summary: title,
      detail: message,
      styleClass: 'alert success',
      icon: 'fa-light fa-circle-check',
      life: life,
    });
  }

  /**
   * showErrorAlert
   * show toastr error alert
   * @param title
   * @param message
   * @param life duration in ms
   */
  public showErrorAlert(title: string, message: string, life: number = 5000) {
    this.messageService.add({
      summary: title,
      detail: message,
      styleClass: 'alert error',
      icon: 'fa-light fa-circle-xmark',
      life: 5000,
    });
  }

  /**
   * showWarningAlert
   * show toastr warning alert
   * @param title
   * @param message
   * @param life duration in ms
   */
  public showWarningAlert(title: string, message: string, life: number = 5000) {
    this.messageService.add({
      summary: title,
      detail: message,
      styleClass: 'alert warning',
      icon: 'fa-light fa-circle-exclamation',
      life: life,
    });
  }

  /**
   * showInfoAlert
   * show toastr info alert
   * @param title
   * @param message
   * @param life duration in ms
   */
  public showInfoAlert(title: string, message: string, life: number = 5000) {
    this.messageService.add({
      summary: title,
      detail: message,
      styleClass: 'alert info',
      icon: 'fa-light fa-circle-info',
      life: life,
    });
  }
}
