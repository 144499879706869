import { CourseEvent } from '../models/course.model';

/**
 * getParticipantAmount
 * get the amount of participants for the course event by summing up the elogs
 * @param event
 * @returns number
 */
export function getParticipantAmount(event: CourseEvent): number {
  if (!event.eLogStatusCounts) {
    return 0;
  }
  return (
    event.eLogStatusCounts.absent +
    event.eLogStatusCounts.checked +
    event.eLogStatusCounts.excused +
    event.eLogStatusCounts.pending +
    event.eLogStatusCounts.unexcused +
    event.eLogStatusCounts.upcoming
  );
}
