<div class="empty-state-content">
  <div class="icon">
    <i class="fa-light fa-elephant"></i>
  </div>

  <div class="text">
    <h1 class="hasomed-text-title">Spannende Neuerungen sind unterwegs!</h1>
    <h3 class="hasomed-text-h3">
      Wir entwickeln EleGuide stetig weiter. Daher gibt es Stellen, wo der volle
      Funktionsumfang noch nicht sichtbar ist. Wir wollen gemeinsam
      herausfinden, was spannende neue Funktionen für unsere Kund:innen sein
      können.
      <br />
      <br />
      Da Sie geklickt haben, interessiert uns Ihr Feedback! <br />
      Benötigen Sie diese Funktion? Wie würde Sie Ihnen helfen?
      <br />
      <br />
      Teilen Sie Ihr Feedback einfach und unverbindlich über den Feedback-Button
      mit.
      <br />
      <br />
      Zusammen gestalten wir die Zukunft von EleGuide.
    </h3>
  </div>

  <button
    type="button"
    class="hasomed-text-button hasomed-button-primary"
    (click)="onOpenFeedbackPage()">
    <i class="hasomed-button-icon-sm fa-light fa-message-question"></i>
    <span class="hasomed-button-text">Feedback / Support</span>
  </button>
</div>
