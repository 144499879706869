import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import * as moment from 'moment';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { RecurrencePattern } from 'src/app/models/course.model';
import {
  TreatmentCaseEventDate,
  TreatmentCaseEventType,
} from 'src/app/models/event.model';
import {
  TreatmentCase,
  TreatmentCaseAppointment,
} from 'src/app/models/treatment-case.model';
import { AlertService } from 'src/app/services/alert.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-create-edit-patient-appointment',
  templateUrl: './create-edit-patient-appointment.component.html',
  styleUrls: ['./create-edit-patient-appointment.component.scss'],
})
export class CreateEditPatientAppointmentComponent implements OnInit {
  public patientAppointmentForm: FormGroup;
  public editMode: boolean = false;
  public roomPlanningOpen: boolean = false;
  public events: TreatmentCaseEventDate[] = [];

  public startDate: any;
  public endDate: any;
  public recurrencePattern: RecurrencePattern;
  public initialTreatmentCase: TreatmentCase;
  public initialFormValues: {};
  public formSubmitted: boolean = false;

  isRequired = isRequired;

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.patientAppointmentForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private treatmentCaseService: TreatmentCaseService,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CreateEditPatientAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      treatmentCase: TreatmentCase;
      appointment: TreatmentCaseAppointment;
    },
    private dialog: MatDialog,
    private formDeactivateService: FormDeactivateService,
    private alertService: AlertService,
    private userService: UserService,
    private formSubmitValidationService: FormSubmitValidationService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    if (this.data.treatmentCase) {
      this.patientAppointmentForm.patchValue({
        patient: this.data.treatmentCase.chiffre,
      });

      this.initialTreatmentCase = JSON.parse(
        JSON.stringify(this.data.treatmentCase)
      );
    }
    if (this.data.appointment) {
      this.editMode = this.data.appointment?.id !== null;

      this.startDate = this.data.appointment?.startDate;
      this.endDate = this.data.appointment?.endDate;
      this.recurrencePattern = this.data.appointment?.recurrencePattern;
      this.events = this.data.appointment?.eventDates;

      this.patientAppointmentForm.patchValue({
        timeUnit: this.data.appointment?.timeUnits.toString(),
      });
    }
    this.patientAppointmentForm.get('patient').disable();
    this.initialFormValues = this.patientAppointmentForm.value;
  }

  private createForm() {
    this.patientAppointmentForm = new FormGroup({
      patient: new FormControl('', [Validators.required]),
      timeUnit: new FormControl('', [Validators.required]),
      dateGroup: new FormControl(null),
    });
  }

  /**
   * roomPlanningChanged
   * @param events
   */
  public roomPlanningChanged(events: TreatmentCaseEventDate[]): void {
    if (!events) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
              Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomPlanningOpen = !this.roomPlanningOpen;
          return;
        }
      });
    } else {
      this.events = events ?? [];
      this.roomPlanningOpen = false;
    }
  }

  /**
   * onSubmit
   */
  public onSubmit(): void {
    this.formSubmitted = true;
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.patientAppointmentForm
      );

    if (formInvalid) {
      return;
    }
    if (!this.checkForEventsWithoutRoom()) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Raumplanung offen',
          message:
            'Sie haben noch keine Räume gewählt, wollen Sie die Termine ohne Raumbuchung speichern?',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.initialFormValues = this.patientAppointmentForm.value;
          this.editMode ? this.updateAppointment() : this.createAppointment();
        }
      });
      return;
    } else {
      this.initialFormValues = this.patientAppointmentForm.value;
      this.editMode ? this.updateAppointment() : this.createAppointment();
    }
  }

  public createAppointment() {
    const treatmentCase: TreatmentCase = this.data.treatmentCase;

    if (treatmentCase.id) {
      const appointment: any = {
        appointmentType: { id: 1, name: 'Patientensitzung' },
        startDate: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'),
        timeUnits: this.patientAppointmentForm.value.timeUnit,
        recurrencePattern: this.recurrencePattern,
        eventDates: this.events?.map(event => {
          return {
            eventTypeId: 2,
            startDate: moment(event.startDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(event.endDate).format('YYYY-MM-DD HH:mm:ss'),
            roomId: event.room?.id,
            canceled: event.canceled,
            instituteId: this.userService.currentUser.id_institute,
          };
        }),
      };

      this.treatmentCaseService
        .createAppointment(treatmentCase.id, appointment)
        .subscribe({
          next: response => {
            console.debug('createAppointment', response);
            if (response.success) {
              this.dialogRef.close(true);
            }
          },
          error: error => {
            console.error(error);
          },
        });
    } else {
      if (!treatmentCase.appointments) {
        treatmentCase.appointments = [];
      }
      treatmentCase.appointments.push({
        id: null,
        type: TreatmentCaseEventType.PATIENT_SESSION,
        startDate: moment(this.startDate).toDate(),
        endDate: moment(this.endDate).toDate(),
        timeUnits: this.patientAppointmentForm.value.timeUnit,
        recurrencePattern: this.recurrencePattern,
        eventDates: this.events,
      });
      this.dialogRef.close(treatmentCase);
    }
  }

  public updateAppointment() {
    const appointment: any = {
      id: this.data.appointment.id,
      appointmentType: { id: 1, name: 'Patientensitzung' },
      startDate: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'),
      timeUnits: this.patientAppointmentForm.value.timeUnit,
      recurrencePattern: this.recurrencePattern,
      eventDates: this.events?.map(event => {
        return {
          id: event.id,
          eventTypeId: 2,
          startDate: moment(event.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(event.endDate).format('YYYY-MM-DD HH:mm:ss'),
          roomId: event.room?.id,
          canceled: event.canceled,
          instituteId: this.userService.currentUser.id_institute,
        };
      }),
    };

    console.debug('updateAppointment', appointment);

    this.treatmentCaseService.updateAppointment(appointment).subscribe({
      next: response => {
        console.debug('updateAppointment', response);
        if (response.success) {
          this.dialogRef.close(true);
        }
      },
      error: error => {
        console.error(error);
      },
    });
  }

  /**
   * onCancel
   */
  public onCancel(): void {
    this.formDeactivateService
      .confirmDeactivation(
        this.patientAppointmentForm.value,
        this.initialFormValues
      )
      .subscribe(result => {
        if (result) {
          this.dialogRef.close(true);
        }
      });
  }

  /**
   * onOpenRoomPlanning
   */
  public onOpenRoomPlanning(): void {
    if (!this.events || this.events?.length === 0) {
      this.alertService.showErrorAlert(
        'Keine Termine vorhanden',
        'Bitte legen Sie mindestens einen Termine an!'
      );
      return;
    }
    this.roomPlanningOpen = true;
  }

  private checkForEventsWithoutRoom(): boolean {
    let eventsWithoutRoom = this.events?.filter(
      event =>
        (event.room === null || event.room === undefined) &&
        !event.canceled &&
        event.endDate > new Date()
    );
    if (eventsWithoutRoom.length > 0) {
      return false;
    }
    return true;
  }

  public onEventsChanged(events: TreatmentCaseEventDate[]): void {
    this.events = events;
  }

  public onDatesChanged(dates: {
    start: Date;
    end: Date;
    allDayEvent: Boolean;
  }) {
    this.startDate = dates.start;
    this.endDate = dates.end;
    this.cdr.detectChanges();
  }

  /**
   * hasUnsavedChanges
   * @returns if the form has unsaved changes
   */
  public hasUnsavedChanges(): boolean {
    return (
      JSON.stringify(this.initialFormValues) !==
      JSON.stringify(this.patientAppointmentForm.value)
    );
  }

  public canDeactivate(): CanDeactivateType {
    return this.formDeactivateService.confirmDeactivation(
      this.patientAppointmentForm.value,
      this.initialFormValues
    );
  }
}
