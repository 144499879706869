import { CustomFileType } from './custom-file-type.model';
import { eLog, eLogStatusCounts } from './elog.model';
import { EventDate } from './event.model';
import { ExamType } from './exams.model';
import { EducationCourse } from './education-course.model';
import { Room } from './room.model';
import { User } from './user.model';

export interface Course {
  id: number;
  educationCourses: EducationCourse[];
  title: string;
  description: string;
  mandatory: boolean;
  titlePicture?: string;
  defaultTitlePictureId?: number;
  type: CourseType;
  duration: number;
  link?: string;
  maxStudents: number;
  registeredStudents?: number;
  room: Room;
  differentRooms?: boolean;
  internalNote?: string;
  lecturers: User[];
  startDate: Date;
  endDate: Date;
  allDayEvent?: boolean;
  logonEnabled: boolean;
  closed: boolean;
  courseEvents?: CourseEvent[];
  learningContents: LearningContent[];
  examTypes: ExamType[];
  eLogStatusCounts?: eLogStatusCounts;
  courseStudents?: User[];
  registered?: boolean;
  recurrencePattern?: RecurrencePattern;
  location?: CourseLocation;
  files?: CustomFileType[];
  finished?: boolean;
}

export interface CourseEvent extends EventDate {
  id?: number;
  courseId?: number;
  lecturers?: User[];
  eLogStatusCounts?: eLogStatusCounts;
  eLogs?: eLog[];
}

export interface CourseFilter {
  typeId?: number;
  lecturerId?: number;
  roomId?: number;
  registeredCourses?: boolean;
  finishedCourses?: boolean;
  openCourses?: boolean;
  pendingELogs?: boolean;
}

export interface LearningContent {
  id: number;
  name: string;
  short_name: string;
}

export interface CourseType {
  id: number;
  id_institute?: number;
  name: string;
}

export interface RecurrencePattern {
  id: number;
  frequency: recurrenceFrequency;
  interval: number;
  daysOfWeek?: string;
  occuranceDayInMonth?: number;
}

export enum recurrenceFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum eLogStatus {
  PENDING = 'eLog ausstehend',
  CHECKED = 'eLog geprüft',
  FAILED = 'eLog fehlgeschlagen',
}

export enum CourseLocation {
  ONLINE = 'Online',
  ONSITE = 'Präsenz',
  HYBRID = 'Hybrid',
}
