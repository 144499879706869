<div class="edit-master-data-dialog flex-c">
  <!-- HEADER -->
  <div class="header flex-r">
    <span class="hasomed-text-h1">Stammdaten ändern</span>
    <button
      type="button"
      class="hasomed-button-icon"
      matTooltip="Abbrechen"
      (click)="onCancel()">
      <i class="fa-light fa-x"></i>
    </button>
  </div>

  <!-- FORMULAR -->
  <form class="edit-master-data-form" [formGroup]="editMasterDataForm">
    <div class="flex-c gap-24 scrollable-content">
      <!-- Anrede & Titel -->
      <div class="hasomed-input-field">
        <div class="flex-r gap-8">
          <!-- Anrede -->
          <div class="formular-row flex-grow">
            <label for="genderTitle" class="formular-row-label">
              <div class="label-title">Anrede</div>
            </label>
            <div class="hasomed-input-field">
              <div class="formular-row-input">
                <input
                  id="genderTitle"
                  class="hasomed-text-input flex-grow"
                  formControlName="genderTitle"
                  placeholder="Anrede" />
              </div>
            </div>
          </div>

          <!-- Titel -->
          <div class="formular-row flex-grow">
            <label for="academicTitle" class="formular-row-label">
              <div class="label-title">Titel</div>
            </label>
            <div class="hasomed-input-field">
              <div class="formular-row-input">
                <input
                  id="academicTitle"
                  class="hasomed-text-input flex-grow"
                  formControlName="academicTitle"
                  placeholder="Titel" />
              </div>
            </div>
          </div>
        </div>
        <app-error-message
          [formField]="
            editMasterDataForm.get('genderTitle')
          "></app-error-message>
        <app-error-message
          [formField]="
            editMasterDataForm.get('academicTitle')
          "></app-error-message>
      </div>

      <div class="hasomed-input-field">
        <!-- Vor- und Nachname -->
        <div class="flex-r gap-8">
          <!-- Vorname -->
          <div class="formular-row flex-grow">
            <label for="firstname" class="formular-row-label">
              <div class="label-title">Vorname</div>
            </label>
            <div class="hasomed-input-field">
              <div class="formular-row-input">
                <input
                  id="firstname"
                  class="hasomed-text-input flex-grow"
                  formControlName="firstname"
                  placeholder="Vorname" />
              </div>
            </div>
          </div>

          <!-- Nachname -->
          <div class="formular-row flex-grow">
            <label for="lastname" class="formular-row-label">
              <div class="label-title">Nachname</div>
            </label>
            <div class="hasomed-input-field">
              <div class="formular-row-input">
                <input
                  id="lastname"
                  class="hasomed-text-input flex-grow"
                  formControlName="lastname"
                  placeholder="Nachname" />
              </div>
            </div>
          </div>
        </div>
        <app-error-message
          [formField]="editMasterDataForm.get('firstname')"></app-error-message>
        <app-error-message
          [formField]="editMasterDataForm.get('lastname')"></app-error-message>
      </div>

      <div class="flex-r gap-8">
        <!-- Geburtsdatum -->
        <div class="formular-row flex-grow">
          <label for="birthdate" class="formular-row-label">
            <div class="label-title">Geburtsdatum</div>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <mat-form-field
                class="hasomed-date-field hasomed-date-field-center">
                <input
                  class="hasomed-date-input"
                  maxlength="10"
                  size="9"
                  [max]="today"
                  matInput
                  [matDatepicker]="birthDatePicker"
                  placeholder="Datum wählen"
                  formControlName="birthdate" />
                <mat-datepicker-toggle
                  class="hasomed-date-toggle"
                  matIconPrefix
                  [for]="birthDatePicker">
                  <mat-icon
                    matDatepickerToggleIcon
                    class="calendar-toggle-icon fa-light fa-calendar">
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker
                  startView="multi-year"
                  startAt="1990-01-01"
                  #birthDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- PiA seit (entryDate) -->
        <div class="formular-row flex-grow">
          <label for="entryDate" class="formular-row-label">
            <div class="label-title">
              {{ isStudent ? 'PiA' : 'Lehrpersonal' }} seit
            </div>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <mat-form-field
                class="hasomed-date-field hasomed-date-field-center">
                <input
                  class="hasomed-date-input"
                  maxlength="10"
                  size="9"
                  [max]="today"
                  matInput
                  [matDatepicker]="entryDatePicker"
                  placeholder="Datum wählen"
                  formControlName="entryDate" />
                <mat-datepicker-toggle
                  class="hasomed-date-toggle"
                  matIconPrefix
                  [for]="entryDatePicker">
                  <mat-icon
                    matDatepickerToggleIcon
                    class="calendar-toggle-icon fa-light fa-calendar">
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker
                  startView="multi-year"
                  #entryDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Straße und Hausnummer -->
      <div class="hasomed-input-field">
        <div class="flex-r gap-8">
          <!-- Straße -->
          <div class="formular-row flex-grow">
            <label for="street" class="formular-row-label">
              <div class="label-title">Straße</div>
            </label>
            <div class="hasomed-input-field">
              <div class="formular-row-input">
                <input
                  id="street"
                  class="hasomed-text-input flex-grow"
                  formControlName="street"
                  placeholder="Straße"
                  size="20" />
              </div>
            </div>
          </div>

          <!-- Hausnummer -->
          <div class="formular-row flex-grow">
            <label for="houseNumber" class="formular-row-label">
              <div class="label-title">Hausnummer</div>
            </label>
            <div class="hasomed-input-field">
              <div class="formular-row-input">
                <input
                  id="houseNumber"
                  class="hasomed-text-input flex-grow"
                  formControlName="houseNumber"
                  placeholder="Hausnummer"
                  size="5" />
              </div>
            </div>
          </div>
        </div>
        <app-error-message
          [formField]="editMasterDataForm.get('street')"></app-error-message>
        <app-error-message
          [formField]="
            editMasterDataForm.get('houseNumber')
          "></app-error-message>
      </div>

      <!-- PLZ und Ort -->
      <div class="hasomed-input-field">
        <div class="flex-r gap-8">
          <!-- PLZ -->
          <div class="formular-row flex-grow">
            <label for="zipCode" class="formular-row-label">
              <div class="label-title">PLZ</div>
            </label>
            <div class="hasomed-input-field">
              <div class="formular-row-input">
                <input
                  id="zipCode"
                  class="hasomed-text-input flex-grow"
                  formControlName="zipCode"
                  placeholder="PLZ"
                  size="5" />
              </div>
            </div>
          </div>

          <!-- Ort -->
          <div class="formular-row flex-grow">
            <label for="city" class="formular-row-label">
              <div class="label-title">Ort</div>
            </label>
            <div class="hasomed-input-field">
              <div class="formular-row-input">
                <input
                  id="city"
                  class="hasomed-text-input flex-grow"
                  formControlName="city"
                  placeholder="Ort"
                  size="20" />
              </div>
            </div>
          </div>
        </div>
        <app-error-message
          [formField]="editMasterDataForm.get('zipCode')"></app-error-message>
        <app-error-message
          [formField]="editMasterDataForm.get('city')"></app-error-message>
      </div>

      <!-- Email -->
      <div class="formular-row">
        <label for="email" class="formular-row-label">
          <div class="label-title">Email</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="email"
              class="hasomed-text-input flex-grow"
              formControlName="email"
              placeholder="Email"
              readonly />
          </div>
        </div>
      </div>

      <!-- Status -->
      <div class="formular-row">
        <label for="status" class="formular-row-label">
          <div class="label-title">Etikett</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <mat-form-field class="hasomed-select">
              <mat-select
                class="hasomed-select"
                formControlName="status"
                placeholder="Etikett">
                <mat-option class="hasomed-text-body" [value]="null">
                  -- Kein Etikett --
                </mat-option>
                <mat-option
                  *ngFor="let status of statusOptions"
                  class="hasomed-text-body"
                  [value]="status">
                  {{ status.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Zusatz Qualifikationen -->
      <div class="formular-row" *ngIf="isLecturer">
        <label for="additionalQualifications" class="formular-row-label">
          <div class="label-title">Zusatz Qualifikationen</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <mat-form-field class="hasomed-select">
              <mat-select
                id="additionalQualifications"
                formControlName="additionalQualifications"
                class="hasomed-select"
                placeholder="Zusatz Qualifikationen"
                [compareWith]="compareAdditionalQualification"
                multiple>
                <mat-option
                  [value]="qualification"
                  class="hasomed-text-body"
                  *ngFor="let qualification of additionalQualifications">
                  {{ qualification.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Ausbildungsgang -->
      <div class="formular-row" *ngIf="isStudent">
        <label for="educationCourse" class="formular-row-label">
          <div class="label-title">Ausbildungsgang</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <mat-form-field class="hasomed-select">
              <mat-select
                id="educationCourse"
                formControlName="educationCourse"
                class="hasomed-select"
                placeholder="Aus- und Weiterbildungsgang">
                <mat-option class="hasomed-text-body" [value]="null">
                  -- Kein Ausbildungsgang --
                </mat-option>
                <mat-option
                  [value]="educationCourse.id"
                  class="hasomed-text-body"
                  *ngFor="let educationCourse of educationCourses">
                  {{ educationCourse.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Kontoinhaber -->
      <div class="formular-row" *ngIf="isStudent">
        <label for="accountHolder" class="formular-row-label">
          <div class="label-title">Kontoinhaber</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="accountHolder"
              class="hasomed-text-input flex-grow"
              formControlName="accountHolder"
              placeholder="Kontoinhaber" />
          </div>
          <app-error-message
            [formField]="
              editMasterDataForm.get('accountHolder')
            "></app-error-message>
        </div>
      </div>

      <!-- IBAN -->
      <div class="formular-row" *ngIf="isStudent">
        <label for="iban" class="formular-row-label">
          <div class="label-title">IBAN</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="iban"
              class="hasomed-text-input flex-grow"
              formControlName="iban"
              placeholder="IBAN" />
          </div>
          <app-error-message
            [formField]="editMasterDataForm.get('iban')"></app-error-message>
        </div>
      </div>

      <!-- BIC -->
      <div class="formular-row" *ngIf="isStudent">
        <label for="bic" class="formular-row-label">
          <div class="label-title">BIC</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="bic"
              class="hasomed-text-input flex-grow"
              formControlName="bic"
              placeholder="BIC" />
          </div>
          <app-error-message
            [formField]="editMasterDataForm.get('bic')"></app-error-message>
        </div>
      </div>

      <!-- MEASLES PROTECTION -->
      <div class="formular-row" *ngIf="isStudent || isLecturer">
        <label for="measlesProtection" class="formular-row-label">
          <div class="label-title">Masernstatus</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <mat-slide-toggle
              hideIcon
              (change)="onMeaslesProtectionChanged($event)"
              [checked]="editMasterDataForm.get('measlesProtection').value">
            </mat-slide-toggle>
            <span class="hasomed-text-small">
              {{
                editMasterDataForm.get('measlesProtection').value
                  ? 'Geprüft'
                  : 'Nicht geprüft'
              }}
            </span>
          </div>
          <app-error-message
            [formField]="
              editMasterDataForm.get('measlesProtection')
            "></app-error-message>
        </div>
      </div>
    </div>

    <!-- BUTTONS -->
    <div class="formular-buttons flex-r">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>
      <button
        type="submit"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onSave()">
        <i class="hasomed-button-icon-sm fa-light fa-floppy-disk"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </form>
</div>
