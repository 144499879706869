import { Injectable } from '@angular/core';
import { WikiPage } from '../models/wiki.model';
import { Observable, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/configs/environment';
import { UserService } from './user.service';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { DecryptionService } from './decryption.service';
import * as moment from 'moment';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class WikiService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private cryptoService: CryptoService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getAllWikiPages
   * @returns Observable<any>
   */
  public getAllWikiPages(): Observable<any> {
    const id_institute = this.userService.currentUser.id_institute;
    return this.http
      .get(
        environment.authority_short +
          `wiki/getAllWikiPages.php?id_institute=${id_institute}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getWikiPageById
   * @param id
   * @returns Observable<any>
   */
  public getWikiPageById(id: number): Observable<any> {
    return this.http
      .get(environment.authority_short + `wiki/getWikiPage.php?id=${id}`)
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createWikiPage
   * @param wikiPage: WikiPage
   * @returns Observable<any>
   */
  public async createWikiPage(wikiPage: WikiPage): Promise<Observable<any>> {
    const id_institute = this.userService.currentUser.id_institute;
    const id_user = this.userService.currentUser.id;

    const formData = new FormData();
    formData.append('id_institute', id_institute.toString());
    formData.append('id_user', id_user.toString());
    wikiPage.title &&
      formData.append(
        'title',
        await this.cryptoService.encrypt(wikiPage.title)
      );
    wikiPage.subtitle &&
      formData.append(
        'subtitle',
        await this.cryptoService.encrypt(wikiPage.subtitle)
      );
    wikiPage.content &&
      formData.append(
        'content',
        await this.cryptoService.encrypt(wikiPage.content)
      );
    wikiPage.picture &&
      formData.append(
        'picture',
        await this.cryptoService.encrypt(wikiPage.picture)
      );
    wikiPage.link &&
      formData.append('link', await this.cryptoService.encrypt(wikiPage.link));

    return this.http.post(
      environment.authority_short + `wiki/createWikiPage.php`,
      formData
    );
  }

  /**
   * updateWikiPage
   * @param wikiPage: WikiPage
   * @returns Observable<any>
   */
  public async updateWikiPage(wikiPage: WikiPage): Promise<Observable<any>> {
    const id_user = this.userService.currentUser.id;
    const formData = new FormData();
    formData.append('id', wikiPage.id.toString());
    formData.append('id_user', id_user.toString());
    wikiPage.title &&
      formData.append(
        'title',
        await this.cryptoService.encrypt(wikiPage.title)
      );
    wikiPage.subtitle &&
      formData.append(
        'subtitle',
        await this.cryptoService.encrypt(wikiPage.subtitle)
      );
    wikiPage.content &&
      formData.append(
        'content',
        await this.cryptoService.encrypt(wikiPage.content)
      );
    wikiPage.picture &&
      formData.append(
        'picture',
        await this.cryptoService.encrypt(wikiPage.picture)
      );
    wikiPage.link &&
      formData.append('link', await this.cryptoService.encrypt(wikiPage.link));

    return this.http.post(
      environment.authority_short + `wiki/updateWikiPage.php`,
      formData
    );
  }

  /**
   * deleteWikiPage
   * @param id
   * @returns Observable<any>
   */
  public deleteWikiPage(id: number): Observable<any> {
    const formData = new FormData();
    formData.append('id', id.toString());

    return this.http.post(
      environment.authority_short + `wiki/deleteWikiPage.php`,
      formData
    );
  }

  /**
   * parseBackendWikiPage
   * decrypts the wiki page
   * @param wikiPage
   * @returns Promise<WikiPage>
   */
  public async parseBackendWikiPage(wikiPage: any): Promise<WikiPage> {
    const parsedWikiPage: WikiPage = {
      id: wikiPage.id,
      title:
        wikiPage.title &&
        (await this.decryptionService.decryptString(wikiPage.title)),
      subtitle:
        wikiPage.subtitle &&
        (await this.decryptionService.decryptString(wikiPage.subtitle)),
      content:
        wikiPage.content &&
        (await this.decryptionService.decryptString(wikiPage.content)),
      picture:
        wikiPage.picture &&
        (await this.decryptionService.decryptString(wikiPage.picture)),
      link:
        wikiPage.link &&
        (await this.decryptionService.decryptString(wikiPage.link)),
      creator:
        wikiPage.creator &&
        (await this.userService.parseBackendUser(wikiPage.creator)),
      modifier:
        wikiPage.modifier &&
        (await this.userService.parseBackendUser(wikiPage.modifier)),
      timeCreated:
        wikiPage.timeCreated &&
        moment(wikiPage.timeCreated, 'YYYY-MM-DD HH:mm:ss').toDate(),
      timeModified:
        wikiPage.timeModified &&
        moment(wikiPage.timeModified, 'YYYY-MM-DD HH:mm:ss').toDate(),
    };

    return parsedWikiPage;
  }
}
