<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Kursübersicht</h1>
      <h3 class="hasomed-text-h3">
        Erstellen Sie oder bearbeiten Sie {{ isLecturer ? 'ihre' : '' }} Kurse.
      </h3>
    </div>
    <form class="header-buttons" [formGroup]="searchForm">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          (keyup)="applySearch($event)"
          placeholder="Suche" />
      </div>
      <div class="hasomed-date-range">
        <mat-date-range-input
          class="hasomed-text-input hasomed-date-range-input"
          formGroupName="dateRange"
          [rangePicker]="picker">
          <input
            id="startDate"
            matStartDate
            placeholder="Startdatum"
            formControlName="start"
            readonly />
          <input
            id="endDate"
            matEndDate
            placeholder="Enddatum"
            formControlName="end"
            (dateChange)="applyDateRange()"
            readonly />
        </mat-date-range-input>
        <mat-datepicker-toggle
          class="hasomed-date-range-toggle"
          matIconSuffix
          [for]="picker">
          <mat-icon
            matDatepickerToggleIcon
            class="calendar-toggle-icon fa-light fa-calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          [restoreFocus]="false"></mat-date-range-picker>
      </div>

      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        [ngClass]="{
          'hasomed-button-primary': hasActiveFilterValue(courseFilter, true),
          'hasomed-button-white-grey': !hasActiveFilterValue(
            courseFilter,
            true
          ),
          'hasomed-button-active': filterOpened
        }"
        [matMenuTriggerFor]="filterMenu"
        (onMenuOpen)="filterOpened = true"
        (onMenuClose)="filterOpened = false">
        <i class="fa-light fa-filter-list hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Filter</span>
      </button>

      <!-- Filter Menu -->
      <mat-menu class="filter-overlay" #filterMenu="matMenu">
        <app-filter
          *ngIf="filterOpened"
          [filters]="courseFilter"
          (filterChanged)="courseFilterChanged($event)"
          [courses]="courses">
        </app-filter>
      </mat-menu>

      <button
        type="button"
        *ngIf="!isLecturer"
        class="hasomed-text-button hasomed-button-primary"
        (click)="createCourse()">
        <i class="fa-light fa-calendar-plus hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Neuen Kurs anlegen</span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="courseFilter"
      (filterChanged)="courseFilterChanged($event)">
    </app-filter-tags>
    <div
      class="table"
      [class.table-bicolor]="dataSource?.filteredData?.length > 1">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Course title column -->
        <ng-container matColumnDef="title">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span>Kurs</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="title">
            </app-custom-table-sort-icon>
          </th>
          <td
            class="gray-900 title-cell cell-overflow-hidden"
            mat-cell
            *matCellDef="let course">
            <span
              class="hasomed-text-body text-overflow-hidden"
              [matTooltip]="course.title">
              {{ course.title }}
            </span>
          </td>
        </ng-container>

        <!-- Course Lecturer Column -->
        <ng-container matColumnDef="lecturer">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span class="gray-600">Lehrpersonal</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="lecturer">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-600 lecturer-cell" mat-cell *matCellDef="let course">
            <div class="flex-r cell-overflow-hidden">
              <span
                class="text-overflow-hidden"
                [matTooltip]="getFullLecturerNames(course)">
                {{ getFullLecturerNames(course) }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- Course Dates Column -->
        <ng-container matColumnDef="dates">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span>Kurstermine</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="dates">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-600" mat-cell *matCellDef="let course">
            <span class="">{{ course.startDate | date: 'dd.MM.yyyy' }}</span>
            <span *ngIf="!isSameDay(course.startDate, course.endDate)">
              - {{ course.endDate | date: 'dd.MM.yyyy' }}</span
            >
          </td>
        </ng-container>

        <!-- Course elog column -->
        <ng-container matColumnDef="elog">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span>Anwesenheiten</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="elog">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let course">
            <app-elog-cell
              [eLogStatusCounts]="course.eLogStatusCounts"></app-elog-cell>
          </td>
        </ng-container>

        <!-- Course room column -->
        <ng-container matColumnDef="rooms">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span>Räume</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="rooms">
            </app-custom-table-sort-icon>
          </th>
          <td
            class="hasomed-text-body text-overflow-hidden gray-600"
            mat-cell
            *matCellDef="let course">
            {{ getCourseRoom(course) }}
          </td>
        </ng-container>

        <!-- Course active column -->
        <ng-container matColumnDef="active">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="">
            <span>Aktiv</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="active">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-600" mat-cell *matCellDef="let course">
            <mat-slide-toggle
              hideIcon
              [disabled]="course.finished"
              [matTooltip]="
                course.finished
                  ? 'Der Kurs ist bereits abgeschlossen und kann nicht temporär deaktiviert werden.'
                  : ''
              "
              class="hasomed-slide-toggle-green-red"
              (change)="onCourseActiveChanged($event, course)"
              [checked]="course.logonEnabled">
            </mat-slide-toggle>
          </td>
        </ng-container>

        <!-- Course actions column -->
        <ng-container matColumnDef="actions">
          <th
            class="gray-600 center-header-cell"
            mat-header-cell
            *matHeaderCellDef>
            Aktionen
          </th>
          <td mat-cell *matCellDef="let course">
            <div class="table-button-icons">
              <button
                type="button"
                class="table-button-icon"
                [matMenuTriggerFor]="actionsMenu"
                #t="matMenuTrigger"
                matTooltip="Kurs Aktionen">
                <i class="hasomed-button-icon-sm fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button
                  mat-menu-item
                  type="button"
                  (click)="viewCourse(course)">
                  <div
                    class="flex-r gap-16 align-center action-menu-item gray-600">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600"> Anzeigen </span>
                  </div>
                </button>

                <mat-divider class="menu-divider"></mat-divider>
                <button
                  mat-menu-item
                  type="button"
                  (click)="editCourse(course.id)">
                  <div
                    class="flex-r gap-16 align-center action-menu-item gray-600">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600"> Bearbeiten </span>
                  </div>
                </button>

                <mat-divider
                  class="menu-divider"
                  *ngIf="isAdministrator"></mat-divider>
                <button
                  type="button"
                  mat-menu-item
                  *ngIf="isAdministrator"
                  (click)="duplicateCourse(course.id)">
                  <div
                    class="flex-r gap-16 align-center action-menu-item gray-600">
                    <i class="fa-light fa-copy icon-sm"></i>
                    <span class="hasomed-text-body gray-600">
                      Duplizieren
                    </span>
                  </div>
                </button>

                <mat-divider
                  class="menu-divider"
                  *ngIf="
                    isAdministrator && getParticipantAmount(course) === 0
                  "></mat-divider>
                <button
                  type="button"
                  mat-menu-item
                  *ngIf="isAdministrator && getParticipantAmount(course) === 0"
                  (click)="deleteCourse(course)">
                  <div
                    class="flex-r gap-16 align-center action-menu-item gray-600">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600"> Löschen </span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <mat-progress-spinner
                color="primary"
                mode="indeterminate"
                diameter="32">
              </mat-progress-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Es wurde kein Kurs gefunden.
            </div>
          </td>
        </tr>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
    <div class="vertical-spacer"></div>
  </div>
</div>
