<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        Behandlungsfall:
        {{
          treatmentCase?.chiffre
            ? 'Chiffre (' + treatmentCase?.chiffre + ')'
            : 'Lade...'
        }}
      </h1>
      <h3 class="hasomed-text-h3">Sehen Sie den Behandlungsfall ein</h3>
    </div>
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onGoBack()">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onEditTreatmentCase()"
        [disabled]="!userService.currentUserIsStudent()">
        <i class="fa-light fa-pen hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Daten bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Patienten Chiffre </span>
      </div>
      <div class="flex-r align-center full-height">
        {{ treatmentCase?.chiffre }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Alter </span>
        <span class="label-description"> Alter des Patienten </span>
      </div>

      <div class="flex-r align-center full-height">
        {{ treatmentCase?.age }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- therapyForm -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Zusammensetzung der Therapie </span>
        <span class="label-description">
          Wie soll die Therapie durchgeführt werden?
        </span>
      </div>
      <div class="flex-r align-center full-height">
        {{ treatmentCase?.therapyForm }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Art der Therapie </span>
      </div>
      <div class="flex-r align-center full-height">
        {{ treatmentCase?.therapyType.name }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Verfahren </span>
      </div>

      <div class="flex-r align-center full-height gap-8">
        <div
          *ngFor="let procedure of treatmentCase.procedures; let last = last">
          {{ procedure.name + (!last ? ', ' : '') }}
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Patiententermine </span>
      </div>
      <div class="hasomed-input-field justify-center full-height gap-8">
        <ng-container *ngFor="let eventDate of patientAppointmentEventDates">
          <div class="flex-r gap-8 align-center">
            <div class="card align-center gap-8">
              <div><i class="fa-light fa-calendar"></i></div>
              <span> {{ eventDate.startDate | date: 'dd.MM.yyyy' }} </span>
            </div>
            <div class="card align-center gap-8">
              <div><i class="fa-light fa-clock-eight"></i></div>
              <span>{{ eventDate.startDate | date: 'HH:mm' }}</span>
            </div>
            <div class="card align-center gap-8">
              <div><i class="fa-light fa-clock-eight"></i></div>
              <span>{{ eventDate.endDate | date: 'HH:mm' }}</span>
            </div>
            <div *ngIf="eventDate.canceled" class="label label-red">
              <i class="fa-light fa-ban label-icon"></i>
              <span class="hasomed-text-small label-text"> Abgesagt </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Supervisor(en) </span>
        <span class="label-description">
          Supervisor(en) des Behandlungsfalls
        </span>
      </div>
      <div class="hasomed-input-field justify-center full-height">
        <span *ngFor="let supervisor of treatmentCase.supervisors">
          {{
            supervisor.name.academicTitle
              ? supervisor.name.academicTitle + ' '
              : '' + supervisor.name.firstname + ' ' + supervisor.name.lastname
          }}
        </span>
        <span> {{ treatmentCase?.supervisorText }} </span>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Notizen für den Behandlungsfall </span>
        <span class="label-description">
          Interne Notizen des Behandlungsfalls
        </span>
      </div>
      <div class="hasomed-input-field justify-center full-height">
        {{ treatmentCase?.internalNote ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dokumente für den Behandlungsfall </span>
        <span class="label-description">
          Hier können alle Behandlungsfallrelevanten Dokumente eingesehen werden
        </span>
      </div>
      <ng-container
        *ngIf="!treatmentCase?.files || treatmentCase?.files?.length === 0">
        <div class="card">Kein Dokument hochgeladen</div>
      </ng-container>
      <div class="flex-c flex-1 gap-8">
        <app-file-view
          *ngFor="let file of treatmentCase?.files"
          [file]="file"></app-file-view>
      </div>
    </div>
  </div>
</div>
