<div class="loading-wrapper" *ngIf="isLoading">
  <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
  </mat-progress-spinner>
</div>
<div class="flex-c gap-16" *ngIf="!isLoading">
  <div
    *ngFor="
      let treatmentCase of treatmentCases | searchFilter: searchText : 'chiffre'
    "
    id="treatmentCase-{{ treatmentCase.id }}"
    class="flex-c treatment-case">
    <div class="flex-r treatment-case-summary align-center">
      <div class="flex-r treatment-case-summary-details">
        <div class="flex-c treatment-case-metadata">
          <div class="flex-c treatment-case-metadata-content">
            <div class="flex-c treatment-case-metadata-body">
              <div class="flex-r gap-8 align-center">
                <h1 class="hasomed-text-h1 m-z">
                  Patient: Chiffre ({{ treatmentCase.chiffre }})
                </h1>

                <button
                  type="button"
                  class="table-button-icon"
                  matTooltip="Behandlungsfall anzeigen"
                  (click)="onShowTreatmentCaseOverview(treatmentCase)">
                  <i class="fa-light fa-arrow-up-right-from-square"></i>
                </button>

                <button
                  *ngIf="this.userService.currentUserIsStudent()"
                  type="button"
                  class="table-button-icon"
                  matTooltip="Behandlungsfall bearbeiten"
                  (click)="onEditTreatmentCase(treatmentCase)">
                  <i class="fa-light fa-pen"></i>
                </button>
              </div>

              <p class="m-z gray-600">
                Supervision -
                <span
                  *ngFor="
                    let supervisor of treatmentCase.supervisors;
                    let last = last
                  ">
                  {{ supervisor.name.academicTitle }}
                  {{ supervisor.name.firstname }} {{ supervisor.name.lastname
                  }}<span *ngIf="!last">, </span>
                </span>
                <span
                  *ngIf="
                    treatmentCase.supervisors?.length > 0 &&
                    treatmentCase.supervisorText
                  "
                  >,
                </span>
                <span *ngIf="treatmentCase.supervisorText">
                  {{ treatmentCase.supervisorText }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-c treatment-case-summary-right gap-16">
        <div class="flex-end">
          <button
            class="hasomed-text-button hasomed-button-white-grey"
            (click)="showDetails(treatmentCase)"
            *ngIf="treatmentCase.id !== selectedTreatmentCase?.id">
            <i class="hasomed-button-icon-sm fa-light fa-square-info"></i>
            <span class="hasomed-button-text">Details anzeigen</span>
            <i class="hasomed-button-icon-sm fa-chevron-down fa-light"></i>
          </button>

          <button
            class="hasomed-text-button hasomed-button-primary"
            (click)="hideDetails()"
            *ngIf="treatmentCase.id === selectedTreatmentCase?.id">
            <i class="hasomed-button-icon-sm fa-light fa-square-info"></i>
            <span class="hasomed-button-text">Details ausblenden</span>
            <i class="hasomed-button-icon-sm fa-chevron-up fa-light"></i>
          </button>
        </div>

        <div class="vertical-spacer"></div>
        <div
          *ngIf="this.userService.currentUserIsStudent()"
          class="flex-r treatment-case-summary-buttons">
          <button
            class="hasomed-text-button hasomed-button-white-grey"
            (click)="onCreateSupervisionAppointment(treatmentCase)">
            <i class="hasomed-button-icon-sm fa-light fa-user-group"> </i>
            <span class="hasomed-button-text"> Neuer Supervisionstermin </span>
          </button>
          <button
            class="hasomed-text-button hasomed-button-white-grey"
            (click)="onCreatePatientAppointment(treatmentCase)">
            <i class="hasomed-button-icon-sm fa-light fa-user"> </i>
            <span class="hasomed-button-text"> Neuer Patiententermin </span>
          </button>
        </div>
      </div>
    </div>
    <app-treatment-case-details
      *ngIf="treatmentCase.id === selectedTreatmentCase?.id">
    </app-treatment-case-details>
  </div>
  <div
    class="flex-c treatment-case"
    *ngIf="
      (treatmentCases | searchFilter: searchText : 'chiffre').length === 0
    ">
    <div class="no-treatment-case">
      Es wurden keine Behandlungsfälle gefunden!
    </div>
  </div>
</div>
