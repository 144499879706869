import { Course, CourseLocation } from '../models/course.model';

/**
 * getFullLecturerNames
 * get list of full lecturer names concatenated with ',' from course
 * @param course course
 * @returns string
 */
export function getFullLecturerNames(course: Course): string {
  return course.lecturers
    ?.map(
      lecturer =>
        `${lecturer.name.academicTitle ?? ''} ${
          lecturer.name.firstname ?? ''
        } ${lecturer.name.lastname ?? ''}`
    )
    .join(', ');
}

/**
 * getEducationCourseTitles
 * get list of education course titles concatenated with ',' from course
 * @param course
 * @returns
 */
export function getEducationCourseTitles(course: Course): string {
  return course.educationCourses
    ?.map(educationCourse => educationCourse.title)
    .join(', ');
}

/**
 * getCourseRoom
 * get course room based on location from course
 * @param course
 * @returns
 */
export function getCourseRoom(course: Course): string {
  if (course.location === CourseLocation.ONLINE) {
    return 'Digital';
  }
  if (course.location === CourseLocation.HYBRID) {
    if (course.differentRooms) {
      return 'Hybrid / Unterschiedliche';
    }
    if (!course.differentRooms && course.room?.name) {
      return 'Hybrid / ' + course.room?.name;
    }
    return 'Hybrid';
  }
  if (course.differentRooms) {
    return 'Unterschiedliche';
  }
  return course.room?.name ?? '';
}
