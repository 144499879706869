import { Pipe, PipeTransform } from '@angular/core';
import { Course, CourseType } from '../models/course.model';
import {
  FilterDateRange,
  Filter,
  FilterType,
  FilterUser,
} from '../models/filter.model';
import { Room } from '../models/room.model';
import { User } from '../models/user.model';
import * as moment from 'moment';

@Pipe({
  name: 'filterCourse',
  standalone: true,
})
export class FilterCoursePipe implements PipeTransform {
  transform(courses: Course[], filter: Filter[]): Course[] {
    if (!courses || !filter) {
      return courses;
    }

    return courses.filter(course => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.DATE_RANGE:
            const start = moment((filter.value as FilterDateRange).start)
              .startOf('day')
              .toDate();
            const end = moment((filter.value as FilterDateRange).end)
              .endOf('day')
              .toDate();
            return (
              moment(course.startDate).isSameOrAfter(start) &&
              moment(course.endDate).isSameOrBefore(end)
            );
          case FilterType.COURSE_TYPE:
            if (this.isCourseType(filter.value)) {
              return course.type.id === filter.value.id;
            }
            return false;
          case FilterType.LECTURER:
            return course.lecturers.find(
              it => it.id === (filter.value as FilterUser).id
            );
          case FilterType.ROOM_NAME:
            if (this.isRoom(filter.value)) {
              return course.room?.id === filter.value.id;
            }
            return false;
          case FilterType.REGISTERED_COURSES:
            return course.registered;
          case FilterType.FINISHED_COURSES:
            return (
              course.eLogStatusCounts.checked === course.courseEvents?.length
            );
          case FilterType.OPEN_COURSES:
            return !course.registered;
          case FilterType.PENDING_ELOGS:
            return course.eLogStatusCounts.pending > 0;
          default:
            return true;
        }
      });
    });
  }

  private isCourseType(obj: any): obj is CourseType {
    return obj && typeof obj === 'object' && 'name' in obj;
  }

  private isRoom(obj: any): obj is Room {
    return obj && typeof obj === 'object' && 'floor' in obj;
  }
}
