import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/configs/environment';
import { Observable, takeUntil } from 'rxjs';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class LearningContentService {
  constructor(
    private http: HttpClient,
    private cancellationService: CancellationService
  ) {}

  getLearningContentById(id: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `learningContent/getLearningContent.php?id=${id}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  getInstituteLearningContents(institude_id: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `learningContent/getInstituteLearningContents.php?id_institute=${institude_id}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }
}
