import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, interval, of, startWith, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private cookieService: CookieService,
    private authService: AuthService
  ) {}

  public checkSessionValidity(): Observable<boolean> {
    return interval(60000).pipe(
      startWith(0),
      switchMap(() => {
        const currentTime = moment();
        const sessionCookie = this.cookieService.get(
          'Impact.SessionTimeOutCookie'
        );
        const sessionTimeoutCookie = new Date(sessionCookie + ' UTC');
        const timeLeft = moment(sessionTimeoutCookie).diff(
          currentTime,
          'seconds'
        );

        // if session is expired, logout
        if (timeLeft <= 60) {
          this.authService.logout();
          return of(false); // Emit false as the session is being terminated
        }

        return of(timeLeft > 0 && timeLeft <= 300); // Emit true if less than or equal to 5 minutes left
      })
    );
  }
}
