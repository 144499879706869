import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { ChangePasswordDialogComponent } from 'src/app/components/shared-components/change-password-dialog/change-password-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { ImageCropperDialogComponent } from 'src/app/components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { ImageDetailDialogComponent } from 'src/app/components/shared-components/image-detail-dialog/image-detail-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { CustomFileType } from 'src/app/models/custom-file-type.model';
import { User } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import { houseNumberValidator } from 'src/app/validators/house-number.validator';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';

@Component({
  selector: 'app-edit-personal-data',
  templateUrl: './edit-personal-data.component.html',
  styleUrls: ['./edit-personal-data.component.scss'],
})
export class EditPersonalDataComponent implements OnInit, OnDestroy {
  public personalDataForm: FormGroup;
  public initialFormValues: {};
  public user: User;
  public today = moment();
  public datePickerStartDate = new Date(1990, 0, 1);
  public isLoading = true;
  public uploadedFiles: CustomFileType[] = [];
  public existingFiles: CustomFileType[] = [];
  public imageUrl: string | ArrayBuffer | null;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.personalDataForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  // import from form.utils.ts
  public isRequired = isRequired;

  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private alert: AlertService,
    private formDeactivateService: FormDeactivateService,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private formSubmitValidationService: FormSubmitValidationService
  ) {}

  public ngOnInit() {
    this.getUserData(this.userService.currentUser.id);

    // create form
    this.createForm();

    // subscribe to uploadedFiles changes and convert them to base64
    this.personalDataForm
      .get('uploadedFiles')
      .valueChanges.subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.personalDataForm.get('documents').setValue(null);
          return;
        }
        const newDocumentsArray: CustomFileType[] = [];

        value.forEach((file: any) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            newDocumentsArray.push({
              id: file.id,
              filename: file.name,
              file: e.target.result,
              fileSize: file.size,
              creator: this.userService.currentUser,
              timeCreated: file.timeCreated,
              timeModified: file.timeModified,
            });

            // Check if all files have been read and update the documents array once
            if (newDocumentsArray.length === value.length) {
              this.personalDataForm
                .get('documents')
                .setValue(newDocumentsArray);
            }
          };
          reader.readAsDataURL(file);
        });
      });
  }

  public requiredFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'GIF', mimeType: 'image/gif' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];
  public requiredFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  /**
   * createForm
   */
  private createForm() {
    this.personalDataForm = this.formBuilder.group({
      genderTitle: new FormControl('', Validators.maxLength(25)),
      academicTitle: new FormControl('', Validators.maxLength(25)),
      firstname: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      lastname: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      email: new FormControl(''),
      birthdate: new FormControl(null),
      profilePicture: new FormControl(null),
      educationCourse: new FormControl(null),
      measlesProtection: new FormControl(null),
      street: new FormControl('', Validators.maxLength(255)),
      houseNumber: new FormControl('', [
        houseNumberValidator(),
        Validators.maxLength(6),
      ]),
      addressAddition: new FormControl('', Validators.maxLength(100)),
      zipCode: new FormControl('', [
        maxNumberLength(5),
        positiveNumbersOnlyValidator(true),
      ]),
      city: new FormControl('', Validators.maxLength(255)),
      country: new FormControl('', Validators.maxLength(100)),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });
  }

  /**
   * getUserData
   * @param user_id
   */
  public getUserData(user_id: number) {
    this.userService
      .getUserById(user_id, 'educationCourse')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getUserById Backend Response', response);
          if (!response.success) {
            console.error(response.message);
            return;
          }
          this.user = await this.userService.parseBackendUser(response.data);

          this.user.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          console.debug('User', this.user);
          this.isLoading = false;
          this.personalDataForm.patchValue({
            genderTitle: this.user.name.genderTitle,
            academicTitle: this.user.name.academicTitle,
            firstname: this.user.name.firstname,
            lastname: this.user.name.lastname,
            email: this.user.email,
            birthdate: this.user.birthdate,
            educationCourse: this.user.educationCourse?.id,
            measlesProtection: this.user.measlesProtection,
            street: this.user.address?.street,
            houseNumber: this.user.address?.houseNumber,
            addressAddition: this.user.address?.addressAddition,
            zipCode: this.user.address?.zipCode,
            city: this.user.address?.city,
            country: this.user.address?.country,
            documents: this.user.files,
            uploadedFiles: this.uploadedFiles,
            existingFiles: JSON.parse(JSON.stringify(this.existingFiles)),
          });

          if (this.user.profilePicture) {
            this.personalDataForm
              .get('profilePicture')
              .setValue(this.user.profilePicture);
          }

          // if measlesProtection is not set and currentUser is Student, show alert
          if (
            this.user.measlesProtection === false &&
            (this.userService.currentUserIsStudent() ||
              this.userService.currentUserIsLecturer())
          ) {
            this.alertService.showInfoAlert(
              'Impfnachweis fehlt',
              'Bitte reichen Sie Ihren Impfnachweis für Masern bei der Verwaltung ein.',
              20000
            );
          }

          this.cdr.detectChanges();
          this.initialFormValues = this.personalDataForm.value;

          console.debug('initialFormValues', this.initialFormValues);
        },
      });
  }

  /**
   * onSubmit
   * @returns
   */
  public async onSubmit() {
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.personalDataForm
      );

    if (formInvalid) {
      return;
    }

    const files: CustomFileType[] = this.personalDataForm.value.documents
      ? this.existingFiles.concat(this.personalDataForm.value.documents)
      : this.existingFiles;

    /* reset inititalFormValue to current value */
    this.initialFormValues = this.personalDataForm.value;

    this.user.email = this.personalDataForm.value.email;
    this.user.name.genderTitle = this.personalDataForm.value.genderTitle;
    this.user.name.academicTitle = this.personalDataForm.value.academicTitle;
    this.user.name.firstname = this.personalDataForm.value.firstname;
    this.user.name.lastname = this.personalDataForm.value.lastname;
    this.user.birthdate = this.personalDataForm.value.birthdate;
    this.user.profilePicture = this.personalDataForm.value.profilePicture;
    this.user.address = {
      street: this.personalDataForm.value.street,
      houseNumber: this.personalDataForm.value.houseNumber,
      addressAddition: this.personalDataForm.value.addressAddition,
      zipCode: this.personalDataForm.value.zipCode,
      city: this.personalDataForm.value.city,
      country: this.personalDataForm.value.country,
    };
    this.user.userIdentifier = this.userService.createUserIdentifier(this.user);
    this.user.files = files;

    if (this.userService.currentUserIsAdministrator()) {
      this.user.measlesProtection =
        this.personalDataForm.value.measlesProtection;
    }

    this.isLoading = true;
    const observable = await this.userService.updateUser(this.user);
    observable.subscribe({
      next: response => {
        console.debug('updateUser backend response:', response);
        if (!response.success) {
          console.error(response.message);
          this.alert.showErrorAlert(
            'Fehler',
            'Ihre Daten konnten nicht gespeichert werden.'
          );
          this.isLoading = false;
          return;
        }
        this.userService.updateCurrentUser(response.data);
        this.alert.showSuccessAlert(
          'Daten aktualisiert',
          'Ihre Daten wurden erfolgreich aktualisiert.'
        );
        this.isLoading = false;
        this.onCancel();
      },
      error: error => {
        console.error(error);
        this.isLoading = false;
        this.alert.showErrorAlert(
          'Fehler',
          'Ihre Daten konnten nicht gespeichert werden.'
        );
      },
    });
  }

  /**
   * onCancel
   */
  public onCancel() {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * changePassword
   * @description opens the change password dialog
   * @returns void
   */
  public changePassword(): void {
    this.dialog.open(ChangePasswordDialogComponent, {
      width: '600px',
    });
  }

  /**
   * onDeleteAccount
   * @description opens the delete account dialog
   */
  public onDeleteAccount(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {
        title: 'Account löschen',
        message:
          'Möchten Sie Ihren Account wirklich löschen? \
          Wenn Sie den Account löschen, verlieren Sie jegliche Informationen,\
          die mit ihrem Account verbunden sind. \
          Eine Wiederherstellung ist nicht mehr möglich. \
          Sind Sie sicher, dass Sie fortfahren möchten?',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.alertService.showSuccessAlert(
          'Account Löschung beantragt',
          'Wir haben eine Löschanfrage an das Supportteam gesendet und melden uns, sobald die Löschung erfolgreich war.'
        );
      }
    });
  }

  /**
   * imageChangeEvent
   * @param event
   */
  public imageChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '500px',
      data: {
        image: event,
        title: 'Profilbild zuschneiden',
        round: true,
        height: 300,
        aspectRatio: 1,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.personalDataForm.get('profilePicture').setValue(result);
      }
    });
  }

  /**
   * openProfilePictureDialog
   */
  public openProfilePictureDialog(): void {
    const dialogRef = this.dialog.open(ImageDetailDialogComponent, {
      data: {
        image: this.personalDataForm.get('profilePicture').value,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result === 'delete') {
        this.personalDataForm.get('profilePicture').setValue(null);
      }
    });
  }

  /**
   * onDeleteExistingFile
   * @param file
   */
  public onDeleteExistingFile(file: CustomFileType) {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.personalDataForm.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * onMeaslesProtectionChanged
   * Set the measlesProtection value in the form
   * @param data
   */
  public onMeaslesProtectionChanged(data: MatSlideToggleChange) {
    this.personalDataForm.get('measlesProtection').setValue(data.checked);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    console.debug('canDeactivate', this.personalDataForm.value);
    console.debug('canDeactivate', this.initialFormValues);
    return this.formDeactivateService.confirmDeactivation(
      this.personalDataForm.value,
      this.initialFormValues
    );
  }

  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
