export enum Role {
  ADMINISTRATOR = 2,
  LECTURER = 3,
  STUDENT = 4,
}

export enum Permission {
  NONE = 'None',
  VIEW = 'View',
  ADMIN = 'Admin',
}

export enum Feature {
  /* Dashboard */
  DB_MYSPACE = 'DBMySpace',
  DB_BULLETIN_BOARD = 'DBBulletinBoard',
  DB_MESSAGES = 'DBMessages',
  DB_DOCUMENTS = 'DBDocuments',
  DB_INSTITUTE_NEWS = 'DBInstituteNews',
  DB_WIKI = 'DBWiki',

  /* Education */
  COURSE_ADMINISTRATION = 'CourseAdministration',
  THEORETICAL_EDUCATION = 'TheoreticalEducation',
  CREATE_COURSE = 'CreateCourse',
  PRACTICAL_WORK = 'PracticalWork',
  PRACTICAL_WORK_NAVIGATION = 'PracticalWorkNavigation',
  AMBULATORY_PART = 'AmbulatoryPart',
  SELF_AWARENESS = 'SelfAwareness',
  TREATMENT_CASE = 'TreatmentCase',

  /* Organization */
  ORGANIZATION = 'Organization',
  STUDENT_ADMINISTRATION = 'StudentAdministration',
  LECTURER_ADMINISTRATION = 'LecturerAdministration',
  ELOG = 'ELog',
  ROOM_ADMINISTRATION = 'RoomAdministration',
  ROOM_PLANNING = 'RoomPlanning',
  PERSONAL_DATA = 'PersonalData',
  OVERVIEW_OF_FEES = 'OverviewOfFees',
  EDIT_MASTER_DATA = 'EditMasterData',
  LABEL = 'Label',
  AUDIT_LOG = 'AuditLog',
  ROLE_MANAGEMENT = 'RoleManagement',
  EDUCATION_PROGRESS = 'EducationProgress',
  ANALYTICS = 'Analytics',
  BILLING = 'Billing',

  /* Onboarding */
  ONBOARDING = 'Onboarding',

  /* Exams */
  EXAMS = 'Exams',

  /* Administration */
  ADMINISTRATION = 'Administration',
}

export interface FeaturePermission {
  feature: Feature;
  permission: Permission;
}
