import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import * as moment from 'moment';
import { AdditionalQualification, User } from 'src/app/models/user.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { Label } from 'src/app/models/label.model';
import { LabelService } from 'src/app/services/label.service';
import { BankDetailsService } from 'src/app/services/bank-details.service';
import { BankDetails } from 'src/app/models/bank-details.model';
import { ibanValidator } from 'src/app/validators/iban.validator';
import { EducationCourse } from 'src/app/models/education-course.model';
import { EducationCourseService } from 'src/app/services/education-course.service';
import { houseNumberValidator } from 'src/app/validators/house-number.validator';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-edit-master-data-dialog',
  templateUrl: './edit-master-data-dialog.component.html',
  styleUrls: ['./edit-master-data-dialog.component.scss'],
})
export class EditMasterDataDialogComponent implements OnInit, OnDestroy {
  public user: User;
  public isStudent: boolean;
  public isLecturer: boolean;
  public bankDetails: BankDetails;
  public additionalQualifications: AdditionalQualification[];

  public editMasterDataForm: FormGroup;
  public initialFormValues: {};
  public today = moment();

  public statusOptions: Label[] = [];
  public selectedStatus?: Label;
  public educationCourses: EducationCourse[];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<EditMasterDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      user: User;
      isStudent: boolean;
      isLecturer: boolean;
      bankDetails: BankDetails;
    },
    private dialog: MatDialog,
    private userService: UserService,
    private alertService: AlertService,
    private labelService: LabelService,
    private bankDetailsService: BankDetailsService,
    private educationCourseService: EducationCourseService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService
  ) {
    this.user = data.user;
    this.isStudent = data.isStudent;
    this.isLecturer = data.isLecturer;
    this.bankDetails = data.bankDetails;

    // disable closing the dialog by clicking outside of it
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.getEducationCourses();
    this.getAdditionalQualifications();
    this.editMasterDataForm = new FormGroup({
      firstname: new FormControl(
        this.user.name.firstname,
        Validators.maxLength(255)
      ),
      lastname: new FormControl(
        this.user.name.lastname,
        Validators.maxLength(255)
      ),
      genderTitle: new FormControl(
        this.user.name.genderTitle,
        Validators.maxLength(25)
      ),
      academicTitle: new FormControl(
        this.user.name.academicTitle,
        Validators.maxLength(25)
      ),
      birthdate: new FormControl(this.user.birthdate),
      street: new FormControl(
        this.user.address?.street,
        Validators.maxLength(255)
      ),
      houseNumber: new FormControl(this.user.address?.houseNumber, [
        houseNumberValidator(),
        Validators.maxLength(6),
      ]),
      zipCode: new FormControl(this.user.address?.zipCode, [
        maxNumberLength(5),
        positiveNumbersOnlyValidator(true),
      ]),
      city: new FormControl(this.user.address?.city, Validators.maxLength(255)),
      email: new FormControl(this.user.email),
      status: new FormControl(this.user.status),
      entryDate: new FormControl(this.user.entryDate),
      accountHolder: new FormControl(
        this.bankDetails?.accountHolder,
        Validators.maxLength(255)
      ),
      iban: new FormControl(this.bankDetails?.iban, [
        ibanValidator(),
        Validators.maxLength(255),
      ]),
      bic: new FormControl(this.bankDetails?.bic, Validators.maxLength(20)),
      educationCourse: new FormControl(this.user.educationCourse?.id),
      measlesProtection: new FormControl(this.user.measlesProtection),
      additionalQualifications: new FormControl(
        this.user.additionalQualifications
      ),
    });

    // get all status options and set the selected status
    this.labelService
      .getAllLabels()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          console.debug('result labels', result);
          if (!result.success) {
            console.error(result.message);
            return;
          }
          this.statusOptions = result.data;
          this.selectedStatus = this.statusOptions.find(
            status => status.id === this.user.status?.id
          );
          this.editMasterDataForm.get('status').setValue(this.selectedStatus);
          this.initialFormValues = this.editMasterDataForm.value;
        },
        error: error => {
          console.error(error.message);
        },
      });
  }

  /**
   * getEducationCourses
   * Get the education courses of the institute
   * @returns void
   */
  private getEducationCourses(): void {
    const id_institute = this.userService.currentUser.id_institute;
    this.educationCourseService
      .getInstituteEducationCourses(id_institute)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('EducationCourse BackendData:', response);
          if (!response.success) {
            console.error(response.message);
            return;
          }
          this.educationCourses = response.data?.map(
            (educationCourseData: any): EducationCourse => {
              return {
                id: educationCourseData.id,
                title: educationCourseData.title,
              };
            }
          );
          console.debug('EducationCourses:', this.educationCourses);
        },
        error: error => {
          console.error(error);
        },
      });
  }

  /**
   * getAdditionalQualifications
   * Get the additional qualifications
   * @returns void
   */
  private getAdditionalQualifications(): void {
    this.userService.getUserAdditionalQualifications().subscribe({
      next: result => {
        console.debug('additionalQualifications BackendData:', result);
        if (!result.success) {
          console.error(result.message);
          return;
        }
        this.additionalQualifications = result.data;

        // set selected additional qualifications
        this.editMasterDataForm
          .get('additionalQualifications')
          .setValue(this.user.additionalQualifications);
      },
      error: error => {
        console.error(error);
      },
    });
  }

  /**
   * onSave
   * Close the dialog, store the data and return the user object
   * @returns void
   */
  public async onSave(): Promise<void> {
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.editMasterDataForm
      );

    if (formInvalid) {
      return;
    }

    this.saveValuesToUser();

    const observable = await this.userService.updateUser(this.user);

    observable.subscribe({
      next: async response => {
        console.debug('updateUser Backend Response', response);
        if (!response.success) {
          this.alertService.showErrorAlert(
            'Fehler',
            'Fehler beim Ändern der Stammdaten'
          );
          console.error(response.message);
          return;
        }

        // Close the dialog, return the user object
        this.user = await this.userService.parseBackendUser(response.data);
        this.initialFormValues = this.editMasterDataForm.value;
        this.dialogRef.close(this.user);
        this.alertService.showSuccessAlert(
          'Stammdaten',
          'Stammdaten erfolgreich aktualisiert!'
        );
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Fehler',
          'Fehler beim Ändern der Stammdaten'
        );
        console.error('Error updating user:', error);
      },
    });

    const observable2 = await this.bankDetailsService.updateBankDetails(
      this.data.user.id,
      this.bankDetails?.accountHolder,
      this.bankDetails?.iban,
      this.bankDetails?.bic
    );

    observable2.subscribe({
      next: response => {
        console.debug('updateBankDetails Backend Response', response);
        if (response.success) {
          this.dialogRef.close(true);
        } else {
          this.alertService.showErrorAlert(
            'Bankverbindung nicht geändert.',
            'Bankverbindung konnte nicht geändert werden.'
          );
        }
      },
      error: err => {
        console.error(err);
        this.alertService.showErrorAlert(
          'Bankverbindung nicht geändert.',
          'Bankverbindung konnte nicht geändert werden.'
        );
      },
    });
  }

  /**
   * onCancel
   * Close the dialog, return false
   * @returns void
   */
  onCancel(): void {
    // check if the form has unsaved changes
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.editMasterDataForm.value,
        this.initialFormValues
      )
    ) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
            Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          // Close the dialog, return false
          this.dialogRef.close(false);
        }
      });
    } else {
      // Close the dialog, return false
      this.dialogRef.close(false);
    }
  }

  /**
   * saveValuesToUser
   * Save the form values to the user object
   * @returns void
   */
  private saveValuesToUser(): void {
    console.debug(
      'saveValuesToUser form values',
      this.editMasterDataForm.value
    );
    this.user.name.genderTitle =
      this.editMasterDataForm.get('genderTitle').value;
    this.user.name.academicTitle =
      this.editMasterDataForm.get('academicTitle').value;
    this.user.name.firstname = this.editMasterDataForm.get('firstname').value;
    this.user.name.lastname = this.editMasterDataForm.get('lastname').value;
    this.user.birthdate = this.editMasterDataForm.get('birthdate').value;
    this.user.entryDate = this.editMasterDataForm.get('entryDate').value;
    this.user.address = {
      street: this.editMasterDataForm.get('street').value,
      houseNumber: this.editMasterDataForm.get('houseNumber').value,
      zipCode: this.editMasterDataForm.get('zipCode').value,
      city: this.editMasterDataForm.get('city').value,
    };
    // if everything is empty, set address to null
    if (
      !this.user.address.street &&
      !this.user.address.houseNumber &&
      !this.user.address.zipCode &&
      !this.user.address.city
    ) {
      this.user.address = null;
    }
    this.user.email = this.editMasterDataForm.get('email').value;
    this.user.status = this.editMasterDataForm.get('status').value;
    this.user.educationCourse = this.educationCourses?.find(
      educationCourse =>
        educationCourse.id ===
        this.editMasterDataForm.get('educationCourse').value
    );
    this.user.measlesProtection =
      this.editMasterDataForm.get('measlesProtection').value;
    this.user.additionalQualifications = this.editMasterDataForm.get(
      'additionalQualifications'
    ).value;
    this.user.userIdentifier = this.userService.createUserIdentifier(this.user);
    if (!this.bankDetails) {
      this.bankDetails = {
        accountHolder: null,
        iban: null,
        maskedIban: null,
        bic: null,
      };
    }

    this.bankDetails.accountHolder =
      this.editMasterDataForm.get('accountHolder').value;
    this.bankDetails.iban = this.bankDetailsService.prettyPrintIban(
      this.editMasterDataForm.get('iban').value
    );
    this.bankDetails.maskedIban = this.bankDetailsService.prettyPrintIban(
      this.bankDetailsService.maskIban(
        this.editMasterDataForm.get('iban').value
      )
    );
    this.bankDetails.bic = this.editMasterDataForm.get('bic').value;
  }

  /**
   * onMeaslesProtectionChanged
   * Set the measlesProtection value in the form
   * @param data
   */
  public onMeaslesProtectionChanged(data: MatSlideToggleChange) {
    this.editMasterDataForm.get('measlesProtection').setValue(data.checked);
  }

  /**
   * compareAdditionalQualification
   * Compares two AdditionalQualification by their id
   * @param qualification1 AdditionalQualification
   * @param qualification2 AdditionalQualification
   * @returns boolean
   */
  public compareAdditionalQualification(
    qualification1: AdditionalQualification,
    qualification2: AdditionalQualification
  ): boolean {
    return qualification1 && qualification2
      ? qualification1.id === qualification2.id
      : qualification1 === qualification2;
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
