import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';
import { CreateEditBankDetailsComponent } from './create-edit-bank-details/create-edit-bank-details.component';
import { AlertService } from 'src/app/services/alert.service';
import { BankDetails } from 'src/app/models/bank-details.model';
import { Invoice } from 'src/app/models/invoice.model';
import * as moment from 'moment';
import { BankDetailsService } from 'src/app/services/bank-details.service';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-overview-of-fees',
  templateUrl: './overview-of-fees.component.html',
  styleUrls: ['./overview-of-fees.component.scss'],
})
export class OverviewOfFeesComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'educationPart',
    'sum',
    'payed',
    'invoice',
  ];

  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public bankDetailsLoading = true;
  public invoicesLoading = true;

  public bankData: BankDetails;

  public invoices: Invoice[];
  public selectedInvoice: Invoice;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public userService: UserService,
    private dialog: MatDialog,
    private alert: AlertService,
    private bankDetailsService: BankDetailsService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    // this.getInvoices();
    // this.getBankDetails();
  }

  private getInvoices() {
    this.userService
      .getUserInvoices(this.userService.currentUser.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          console.debug('invoices backend response', response);
          if (response.success) {
            this.invoices = response.data?.map((invoice: Invoice) => {
              return {
                title: invoice.title,
                sum: invoice.sum,
                payed: Boolean(invoice.payed),
                invoice: 'Rechnung',
                date: moment(invoice.date, 'DD.MM.YYYY').toDate(),
              };
            });

            this.dataSource = new MatTableDataSource<any>(this.invoices);
            this.dataSource.sortingDataAccessor = (item, property) => {
              switch (property) {
                case 'educationPart':
                  return item.title;
                default:
                  return item[property];
              }
            };
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.selectedInvoice = this.invoices.find(
              invoice => invoice.payed === false
            );
          }
          this.invoicesLoading = false;
        },
        error: err => {
          console.error(err);
          this.alert.showErrorAlert(
            'Rechnungen nicht geladen.',
            'Rechnungen konnten nicht geladen werden.'
          );
          this.invoicesLoading = false;
        },
      });
  }

  private getBankDetails() {
    this.bankDetailsService
      .getUserBankDetails(this.userService.currentUser.id)
      .subscribe({
        next: async response => {
          console.debug('bank details backend response', response);
          if (response.success) {
            this.bankData = response.data
              ? await this.bankDetailsService.parseBackendBankDetails(
                  response.data?.bankDetails
                )
              : null;
          }
          this.bankDetailsLoading = false;
        },
        error: err => {
          console.error(err);
          this.alert.showErrorAlert(
            'Bankverbindung nicht geladen.',
            'Bankverbindung konnte nicht geladen werden.'
          );
          this.bankDetailsLoading = false;
        },
      });
  }

  public editBankDetails() {
    const dialogRef = this.dialog.open(CreateEditBankDetailsComponent, {
      data: { user: this.userService.currentUser, bankData: this.bankData },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  public ngOnDestroy() {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
