<div class="flex-c gap-40 dialog-content">
  <div class="flex-c gap-24">
    <div class="flex-r gap-24 justify-space-between">
      <h1 class="hasomed-text-h1">{{ title }}</h1>
      <button type="button" class="close-button" (click)="onDismiss()">
        <i class="fa-light fa-x close-button-icon"></i>
      </button>
    </div>
    <div class="hasomed-text-body">{{ message }}</div>
  </div>
  <div class="flex-r gap-24 justify-space-between">
    <button
      type="button"
      class="hasomed-text-button hasomed-button-white-grey"
      (click)="onDismiss()">
      <i class="hasomed-button-icon-sm fa-light fa-circle-x"></i>
      <span class="hasomed-button-text">Nein</span>
    </button>
    <button
      type="button"
      class="hasomed-text-button hasomed-button-primary"
      (click)="onConfirm()">
      <i class="hasomed-button-icon-sm fa-light fa-circle-check"></i>
      <span class="hasomed-button-text">Ja</span>
    </button>
  </div>
</div>
