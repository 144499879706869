import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { NewsArticle } from 'src/app/models/news-article.model';
import { NewsArticleService } from 'src/app/services/news-article.service';
import { Feature, Permission, Role } from 'src/app/models/permission.model';
import { UserService } from 'src/app/services/user.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AlertService } from 'src/app/services/alert.service';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-dashboard-institut-news',
  templateUrl: './dsb-institute-news.component.html',
  styleUrls: ['./dsb-institute-news.component.scss'],
})
export class DSBInstituteNewsComponent implements OnInit, OnDestroy {
  private newInstituteNewsCount = 0;
  private refreshArticleSubscription: any;
  public showOnlyPublishedArticles = true;
  public isLoading = true;
  public newsArticles: NewsArticle[];
  public filteredNewsArticles: NewsArticle[];
  public selectedNewsArticle?: NewsArticle;
  public searchForm = new FormGroup({
    searchText: new FormControl(''),
  });

  public requiredFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'GIF', mimeType: 'image/gif' },
  ];

  public feature = Feature;
  public permission = Permission;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public newsArticleService: NewsArticleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    private sanitizer: DomSanitizer,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    // show drafts if user is admin
    if (this.userService.currentUser.id_role == Role.ADMINISTRATOR) {
      this.showOnlyPublishedArticles = false;
    }

    this.newsArticleService
      .getAllNewsArticles()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getAllNewsArticles backend response', response);

          if (!response.success) {
            console.error('getAllNewsArticles backend error', response);
            this.alertService.showErrorAlert(
              'Fehler',
              'Die Institutsneuigkeiten konnten nicht geladen werden.'
            );
            return;
          }

          this.newsArticles = response.data
            ? await Promise.all(
                response.data?.map(
                  async (newsArticle: any): Promise<NewsArticle> => {
                    return await this.newsArticleService.parseBackendNewsArticle(
                      newsArticle
                    );
                  }
                )
              )
            : [];
          this.filteredNewsArticles = this.newsArticles;

          if (this.activatedRoute.snapshot.params['id']) {
            const id = Number(atob(this.activatedRoute.snapshot.params['id']));
            this.selectedNewsArticle = this.newsArticles.find(
              newsArticle => newsArticle.id === id
            );
            // if selected news article, move it to the top of the list
            if (this.selectedNewsArticle) {
              this.filteredNewsArticles = this.filteredNewsArticles.filter(
                newsArticle => newsArticle.id !== id
              );
              this.filteredNewsArticles.unshift(this.selectedNewsArticle);
            }
          } else {
            // select first news article
            this.selectedNewsArticle = this.filteredNewsArticles
              ? this.filteredNewsArticles[0]
              : null;
          }

          this.isLoading = false;
          this.newsArticleService.newsArticlesVisited.next(0);
        },
        error: error => {
          console.error('getAllNewsArticles backend error', error);
          this.isLoading = false;
        },
      });

    this.refreshArticleSubscription =
      this.newsArticleService.newNewsArticlesCount.subscribe(
        (count: number) => {
          if (count > this.newInstituteNewsCount) {
            this.newInstituteNewsCount = count;
            this.alertService.showInfoAlert(
              'Neue Institutsneuigkeiten',
              'Es gibt neue Institutsneuigkeiten. Bitte aktualisieren Sie die Seite.',
              15000
            );
          }
        }
      );
  }

  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const trimmedFilterValue = filterValue.trim().toLowerCase();
    this.filteredNewsArticles = this.newsArticles.filter(it =>
      it.title.toLowerCase().includes(trimmedFilterValue)
    );
    if (this.filteredNewsArticles.length > 0) {
      this.selectedNewsArticle = this.filteredNewsArticles[0];
    } else {
      this.selectedNewsArticle = null;
    }
  }

  public onEdit(newsArticle: any): void {
    this.router.navigate([
      'eleguide/dashboard/institute-news/',
      'edit',
      btoa(String(newsArticle.id)),
    ]);
  }

  public onCreateNewNewsArticle() {
    this.router.navigate(['eleguide/dashboard/institute-news/create']);
  }

  /**
   * onSelectNewsArticle
   * sets the selectedNewsArticle
   * @param newsArticle
   * @returns void
   */
  public onSelectNewsArticle(newsArticle: NewsArticle): void {
    if (this.selectedNewsArticle === newsArticle) {
      this.selectedNewsArticle = null;
      return;
    }
    this.selectedNewsArticle = newsArticle;
    console.debug('onSelectNewsArticle', this.selectedNewsArticle);
  }

  public getSanitizedArticleContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.selectedNewsArticle?.text
    );
  }

  public ngOnDestroy(): void {
    this.refreshArticleSubscription.unsubscribe();
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
