import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, firstValueFrom, map, takeUntil } from 'rxjs';
import {
  ProgressType,
  StudentEducationProgress,
} from 'src/app/models/education-progress.model';
import { Role } from 'src/app/models/permission.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { EducationProgressService } from 'src/app/services/education-progress.service';
import { ElogService } from 'src/app/services/elog.service';
import { PermissionService } from 'src/app/services/permission.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-my-space-header',
  templateUrl: './my-space-header.component.html',
  styleUrls: ['./my-space-header.component.scss'],
})
export class MySpaceHeaderComponent implements OnInit, OnDestroy {
  // Administrator content
  public currentCourses: Promise<number> | null = null;
  public openAdminELogs: Promise<number> | null = null;
  public currentLecturers: Promise<number> | null = null;

  // Student content
  public currentCoursesOfStudent: Promise<number> | null = null;
  public totalProgress: Promise<StudentEducationProgress> | null = null;
  public studentEducationProgress: Promise<StudentEducationProgress[]> | null =
    null;

  // Lecturer content
  public openLecturerELogs: Promise<number> | null = null;
  public ownCourses: Promise<number> | null = null;
  public lessons: Promise<number> | null = null;

  public role = Role;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public userService: UserService,
    private courseService: CourseService,
    private permissionService: PermissionService,
    private educationProgressService: EducationProgressService,
    private eLogService: ElogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    const userRole = this.userService.currentUser.id_role;
    this.permissionService.permissionsChanged.subscribe(() => {
      switch (userRole) {
        case Role.STUDENT:
          this.initStudentData();
          break;
        case Role.LECTURER:
          this.initLecturerData();
          break;
        case Role.ADMINISTRATOR:
          this.initAdministratorData();
          break;
        default:
          break;
      }
    });

    switch (userRole) {
      case Role.STUDENT:
        this.initStudentData();
        break;
      case Role.LECTURER:
        this.initLecturerData();
        break;
      case Role.ADMINISTRATOR:
        this.initAdministratorData();
        break;
      default:
        break;
    }
  }

  /**
   * initAdministratorData
   * initializes the data for the administrator
   */
  private initAdministratorData() {
    // current courses
    this.currentCourses = firstValueFrom(
      this.courseService
        .getInstituteCourses(this.userService.currentUser.id_institute)
        .pipe(
          map(response => response.data?.courses?.length),
          takeUntil(this.destroy$)
        )
    );

    // open eLogs
    this.eLogService.getNumberOfPendingElogsFromInstitute().subscribe({
      next: (response: any) => {
        console.debug(
          'getNumberOfPendingElogsFromInstitute backend response',
          response
        );
        if (!response.success) {
          console.error(
            'getNumberOfPendingElogsFromInstitute backend error',
            response.message
          );
          this.openAdminELogs = Promise.resolve(0);
          return;
        }
        this.openAdminELogs = Promise.resolve(response.data);
      },
      error: error => {
        console.error(
          'getNumberOfPendingElogsFromLecturer backend error',
          error
        );
        this.openAdminELogs = Promise.resolve(0);
      },
    });

    //current lecturers
    this.currentLecturers = firstValueFrom(
      this.userService
        .getInstituteUsersByRole(Role.LECTURER, false)
        .pipe(map(response => (response.data ? response.data.length : 0)))
    );
  }

  /**
   * initStudentData
   * initializes the data for the student
   */
  private initStudentData() {
    // current courses of student
    this.currentCoursesOfStudent = firstValueFrom(
      this.courseService
        .getStudentsCourses(
          this.userService.currentUser.id,
          this.userService.currentUser.id_institute
        )
        .pipe(
          map(
            response =>
              response.data.courses.filter((it: any) => it.registered === 1)
                .length
          ),
          takeUntil(this.destroy$)
        )
    );

    const id_user = this.userService.currentUser.id;
    let educationProgress: StudentEducationProgress = {
      type: ProgressType.TOTAL,
      progress: 0,
      contingent: 0,
      procentual: 0,
    };
    this.educationProgressService
      .getEducationProgress(id_user)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          console.debug('getEducationProgress backend response', response);

          if (!response.success) {
            if (response.message == 'user has no education course') {
              this.totalProgress = Promise.resolve(educationProgress);
              this.studentEducationProgress = Promise.resolve([]);
              return;
            }
            console.error(
              'getEducationProgress backend error',
              response.message
            );
            return;
          }
          this.studentEducationProgress = Promise.resolve(response.data);
          educationProgress = response.data.find(
            (progress: StudentEducationProgress) =>
              progress.type === ProgressType.TOTAL
          );
          this.totalProgress = Promise.resolve(educationProgress);
        },
        error: error => {
          this.totalProgress = Promise.resolve(educationProgress);
          this.studentEducationProgress = Promise.resolve([]);
          console.error('getEducationProgress backend error', error);
        },
      });
  }

  /**
   * initLecturerData
   * initializes the data for the lecturer
   */
  private initLecturerData() {
    // open eLogs
    this.eLogService.getNumberOfPendingElogsFromLecturer().subscribe({
      next: (response: any) => {
        if (!response.success) {
          console.error(
            'getNumberOfPendingElogsFromLecturer backend error',
            response.message
          );
          this.openLecturerELogs = Promise.resolve(0);
          return;
        }
        this.openLecturerELogs = Promise.resolve(response.data);
      },
      error: error => {
        console.error(
          'getNumberOfPendingElogsFromLecturer backend error',
          error
        );
        this.openLecturerELogs = Promise.resolve(0);
      },
    });

    // courses of lecturer
    this.courseService
      .getNumberOfCoursesFromLecturer()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (!response.success) {
            console.error(
              'getNumberOfCoursesFromLecturer backend error',
              response.message
            );
            this.ownCourses = Promise.resolve(0);
            return;
          }
          this.ownCourses = Promise.resolve(response.data);
        },
        error: error => {
          console.error('getNumberOfCoursesFromLecturer backend error', error);
          this.ownCourses = Promise.resolve(0);
        },
      });
    // lessons TODO
    this.lessons = Promise.resolve(0);
  }

  /**
   * getCardTitle
   * returns the title of the card depending on the user role and the card
   * @param card number of the card
   * @returns string
   */
  public getCardTitle(card: number): string {
    const userRole = this.userService.currentUser.id_role;
    switch (card) {
      case 1:
        switch (userRole) {
          case Role.STUDENT:
            return 'Aktuelle Kurse';
          case Role.LECTURER:
            return 'Eigene Kurse';
          case Role.ADMINISTRATOR:
            return 'Aktive Kurse';
          default:
            return '';
        }
      case 2:
        switch (userRole) {
          case Role.ADMINISTRATOR:
            return 'Offene Anwesenheiten';
          default:
            return '';
        }
      case 3:
        switch (userRole) {
          case Role.STUDENT:
            return 'Ausbildungsfortschritt';
          case Role.LECTURER:
            return 'Offene Anwesenheiten';
          case Role.ADMINISTRATOR:
            return 'Lehrpersonal';
          default:
            return '';
        }
      default:
        return '';
    }
  }

  /**
   * navigateTo
   * navigates to the given route
   * @param route string
   * @returns void
   */
  public navigateTo(route: string): void {
    this.router.navigate([route], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * navigateToELogAndSetFilter
   * navigates to the eLog page and sets the filter to pending eLogs if there are any
   * @param pendingELogs Promise<number>
   * @returns void
   */
  public async navigateToELogAndSetFilter(
    pendingELogs: Promise<number>
  ): Promise<void> {
    // if there are pending eLogs, set the filter to show only pending eLogs
    const setFilter = await pendingELogs.then(value => value > 0);
    if (setFilter) {
      // add query parameter to filter for pending eLogs
      this.router.navigate(['../../education/course-administration'], {
        queryParams: { pendingELogsOnly: 'true' },
        queryParamsHandling: 'merge',
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate(['../../education/course-administration'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  public ngOnDestroy() {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
