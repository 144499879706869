<div class="content-container">
  <div class="content-container-header">
    <h1 class="hasomed-text-title">Institut Onboarding</h1>
    <nav
      mat-tab-nav-bar
      [tabPanel]="onboardingPanel"
      mat-stretch-tabs="false"
      mat-align-tabs="start">
      <a
        mat-tab-link
        *ngFor="let link of navLinks"
        [routerLink]="link.link"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive">
        <span>{{ link.label }}</span>
      </a>
    </nav>
  </div>
  <mat-divider></mat-divider>
  <div class="content-container-content">
    <mat-tab-nav-panel class="onboardingPanel" #onboardingPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</div>
