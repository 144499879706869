import { NgModule } from '@angular/core';
import { OrganizationComponent } from './organization.component';
import { OverviewOfFeesComponent } from './overview-of-fees/overview-of-fees.component';
import { MaterialModule } from 'src/app/modules/material.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DndDirective } from 'src/app/directives/dnd.directive';
import { SharedModule } from 'src/app/modules/shared.module';
import { LabelComponent } from './label/label.component';
import { CreateEditLabelDialogComponent } from './label/create-edit-label-dialog/create-edit-label-dialog.component';
import { CreateEditBankDetailsComponent } from './overview-of-fees/create-edit-bank-details/create-edit-bank-details.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { EditRoleDialogComponent } from './role-management/edit-role-dialog/edit-role-dialog.component';
import { EducationProgressComponent } from './education-progress/education-progress.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { BillingComponent } from './billing/billing.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    OrganizationComponent,
    OverviewOfFeesComponent,
    DndDirective,
    LabelComponent,
    CreateEditLabelDialogComponent,
    CreateEditBankDetailsComponent,
    RoleManagementComponent,
    EditRoleDialogComponent,
    EducationProgressComponent,
    AnalyticsComponent,
    BillingComponent,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ImageCropperModule,
  ],
  exports: [OrganizationComponent],
  providers: [],
})
export class OrganizationModule {}
