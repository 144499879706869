import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { Role } from 'src/app/models/permission.model';
import { User } from 'src/app/models/user.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { AlertService } from 'src/app/services/alert.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { InstituteService } from 'src/app/services/institute.service';
import { UserService } from 'src/app/services/user.service';
import { emailValidator } from 'src/app/validators/email.validator';

@Component({
  selector: 'app-onb-user-and-roles',
  templateUrl: './onb-user-and-roles.component.html',
  styleUrls: ['./onb-user-and-roles.component.scss'],
})
export class OnbUserAndRolesComponent implements OnInit, OnDestroy {
  public administratorForm: FormGroup;
  public lecturerForm: FormGroup;
  public studentForm: FormGroup;

  public administrators: User[] = [];
  public lecturers: User[] = [];
  public students: User[] = [];
  public initialUsers: User[];

  public isLoading: boolean = true;

  public requiredFileTypes: FileFormat[] = [
    { type: 'XLS', mimeType: 'application/vnd.ms-excel' },
    {
      type: 'XLSX',
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  ];

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.administrators.concat(this.lecturers, this.students),
        this.initialUsers
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public instituteService: InstituteService,
    private userService: UserService,
    private formDeactivateService: FormDeactivateService,
    private cancellationService: CancellationService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    // if a current institute is not set, get the id_institute from the user and get the institute
    // if the user has no id_institute, show the user a message that he has to create an institute first
    if (!this.instituteService.currentInstitute) {
      if (this.userService.currentUser.id_institute) {
        this.instituteService
          .getInstitute(this.userService.currentUser.id_institute)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: response => {
              console.debug('getInstitute Backend Response', response);
              if (!response.success) {
                console.error(response.message);
                this.isLoading = false;
                return;
              }
              this.instituteService.setCurrentInstitute(
                this.instituteService.parseBackendInstitute(response.data)
              );
              this.getInstituteUsers();
              this.isLoading = false;
            },
            error: error => {
              console.error(error);
              this.isLoading = false;
            },
          });
      } else {
        console.error('No institute found for user');
        this.isLoading = false;
      }
    } else {
      this.getInstituteUsers();
    }

    this.administratorForm = this.formBuilder.group({
      email: new FormControl('', [emailValidator(), Validators.maxLength(255)]),
    });

    this.lecturerForm = this.formBuilder.group({
      email: new FormControl('', [emailValidator(), Validators.maxLength(255)]),
    });

    this.studentForm = this.formBuilder.group({
      email: new FormControl('', [emailValidator(), Validators.maxLength(255)]),
    });
  }

  private getInstituteUsers() {
    const id_institute = this.instituteService.currentInstitute.id;
    this.userService.getAllUsersByInstitute(id_institute).subscribe({
      next: async response => {
        console.debug('getAllUsersByInstitute Backend Response', response);
        if (!response.success) {
          console.error(response.message);
          return;
        }
        response.data &&
          (await Promise.all(
            response.data.map(async (userData: any) => {
              const user: User =
                await this.userService.parseBackendUser(userData);
              switch (user.id_role) {
                /* Verwaltung */
                case 2:
                  this.administrators.push(user);
                  break;
                /* Dozent */
                case 3:
                  this.lecturers.push(user);
                  break;
                /* Student */
                case 4:
                  this.students.push(user);
                  break;
              }
            })
          ));

        /* set initialUsers */
        this.initialUsers = this.administrators.concat(
          this.lecturers,
          this.students
        );
        this.isLoading = false;
      },
      error: error => {
        console.error(error);
      },
    });
  }

  /**
   * changeExcel
   * read selected excel file for user roles
   * @param event
   * @param role
   */
  public changeExcel(event: any, role: string) {
    this.readURL(event.target.files, role);
  }

  /**
   * readURL
   * Reads the changed excel file with FileReader
   * @param files
   * @param role
   * @returns void
   */
  private async readURL(files: any, role: string): Promise<void> {
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = this.onLoadExcel.bind(this, role);

      reader.readAsDataURL(files[0]);
    }
  }

  /**
   * onLoadExcel
   * Added emails form excel file to arrays
   * @param role
   * @returns void
   */
  private async onLoadExcel(role: string): Promise<void> {
    // TODO: get data from excel ad put user into arrays
    switch (role) {
      case 'administrator':
        break;
      case 'lecturer':
        break;
      case 'student':
        break;
    }
  }

  /**
   * onAddAdministrationUser
   * Adds a new user to the administration role
   * @returns void
   */
  public onAddAdministration(): void {
    if (this.administratorForm.valid) {
      const value = this.administratorForm.get('email').value;

      if (this.checkIfUserExists(value)) {
        this.administratorForm.get('email').setErrors({ userExists: true });
        return;
      }
      const user: User = {
        id: null,
        email: value,
        name: {
          firstname: '',
          lastname: '',
        },
        id_role: Role.ADMINISTRATOR,
        id_institute: this.instituteService.currentInstitute.id,
      };
      if (!this.administrators.includes(user)) {
        this.administrators.unshift(user);
        this.administratorForm.get('email').setValue('');
      }
    }
  }

  /**
   * onRemoveAdministrator
   * Removes a user from the administrator role
   * @param user
   */
  public onRemoveAdministrator(user: User) {
    const index = this.administrators.indexOf(user);
    if (index > -1) {
      this.administrators.splice(index, 1);
    }
  }

  /**
   * onAddLecturer
   * Adds a new user to the lecturer role
   * @returns void
   */
  public onAddLecturer(): void {
    if (this.lecturerForm.valid) {
      const value = this.lecturerForm.get('email').value;

      if (this.checkIfUserExists(value)) {
        this.lecturerForm.get('email').setErrors({ userExists: true });
        return;
      }
      const user: User = {
        id: null,
        email: value,
        name: {
          firstname: '',
          lastname: '',
        },
        id_role: Role.LECTURER,
        id_institute: this.instituteService.currentInstitute.id,
      };
      if (!this.lecturers.includes(user)) {
        this.lecturers.unshift(user);
        this.lecturerForm.get('email').setValue('');
      }
    }
  }

  /**
   * onRemoveLecturer
   * Removes a user from the lecturer role
   * @param user
   */
  public onRemoveLecturer(user: User) {
    const index = this.lecturers.indexOf(user);
    if (index > -1) {
      this.lecturers.splice(index, 1);
    }
  }

  /**
   * onAddStudent
   * Adds a new user to the student role
   * @returns void
   */
  public onAddStudent(): void {
    if (this.studentForm.valid) {
      const value = this.studentForm.get('email').value;

      if (this.checkIfUserExists(value)) {
        this.studentForm.get('email').setErrors({ userExists: true });
        return;
      }
      const user: User = {
        id: null,
        email: value,
        name: {
          firstname: '',
          lastname: '',
        },
        id_role: Role.STUDENT,
        id_institute: this.instituteService.currentInstitute.id,
      };
      if (!this.students.includes(user)) {
        this.students.unshift(user);
        this.studentForm.get('email').setValue('');
      }
    }
  }

  /**
   * onRemoveStudent
   * Removes a user from the student role
   * @param user
   */
  public onRemoveStudent(user: User) {
    const index = this.students.indexOf(user);
    if (index > -1) {
      this.students.splice(index, 1);
    }
  }

  /**
   * hasErrors
   * check if form field has errors
   * @param formGroup
   * @param formFieldName name of form field
   * @returns boolean
   */
  public hasErrors(fromGroup: FormGroup, formFieldName: string): boolean {
    const formField = fromGroup.get(formFieldName);
    if (!formField) {
      return false;
    }
    return (
      formField.invalid &&
      formField.errors &&
      (formField.dirty || formField.touched)
    );
  }

  onCancel() {}

  /**
   * onGoBack
   * navigate to education course
   * @returns void
   */
  public onGoBack(): void {
    this.router.navigate(['../education-course'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onContinue
   * navigate to course creation
   * @returns void
   */
  public onContinue(): void {
    const users = this.administrators.concat(this.lecturers, this.students);
    const data = {
      users: users,
      id_institute: this.instituteService.currentInstitute.id,
    };
    this.isLoading = true;
    this.userService.createInstituteUsers(data).subscribe({
      next: response => {
        console.debug('createInstituteUsers Backend Response', response);
        if (!response.success) {
          console.error(response.message);
          this.isLoading = false;
          return;
        }
        this.initialUsers = users;
        this.router.navigate(['../course-creation'], {
          relativeTo: this.activatedRoute,
        });
      },
      error: error => {
        console.error(error);
        this.isLoading = false;
      },
    });
  }

  private checkIfUserExists(email: string): boolean {
    return this.administrators
      .concat(this.lecturers, this.students)
      .map(user => user.email)
      .includes(email);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.administrators.concat(this.lecturers, this.students),
      this.initialUsers
    );
  }

  /**
   * onInviteUser
   * invite a user to the institute
   * @param user
   */
  public async onInviteUser(user: User) {
    if (user.isInvited) {
      return;
    }
    console.debug('Invite user', user);
    if (!user.id) {
      if (!(await this.createUserAsync(user))) {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Benutzer konnte nicht erstellt werden.'
        );
        return;
      }
    }
    this.userService.inviteUser(user).subscribe({
      next: response => {
        console.debug('inviteUser Backend Response', response);
        if (!response.success) {
          if (response.data === 'user already invited') {
            user.isInvited = true;
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Der Benutzer hat bereits ein HealthyCloud Account.'
            );
            return;
          }
          console.error(response.data);
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Einladung wurde nicht versendet.'
          );
          return;
        }
        user.isInvited = true;
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Die Einladung wurde erfolgreich versendet.'
        );
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Die Einladung wurde nicht versendet.'
        );
      },
    });
  }

  /**
   * createUser
   * create a new user
   * @param user
   */
  private async createUserAsync(user: User): Promise<boolean> {
    try {
      const response = await firstValueFrom(this.userService.createUser(user));
      console.debug('createUser Backend Response', response);
      user.id = response.data;

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
