import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Feature, Permission } from 'src/app/models/permission.model';
import { NewsArticleService } from 'src/app/services/news-article.service';
import {
  Subject,
  Subscription,
  catchError,
  firstValueFrom,
  map,
  of,
  takeUntil,
} from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';
import { NavLink } from 'src/app/models/nav-link.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  private newsArticleVisitedSubscription: Subscription;
  private destroy$: Subject<void> = new Subject<void>();

  public navLinks: NavLink[];

  constructor(
    private userService: UserService,
    private newsArticleService: NewsArticleService,
    private cancellationService: CancellationService
  ) {
    this.navLinks = [
      {
        label: 'Mein Bereich',
        link: 'my-space',
        feature: Feature.DB_MYSPACE,
        permission: Permission.VIEW,
      },
      {
        label: 'Digitale Pinnwand',
        link: 'digital-bulletin-board',
        feature: Feature.DB_BULLETIN_BOARD,
        permission: Permission.VIEW,
      },
      {
        label: 'Nachrichten',
        link: 'messages',
        feature: Feature.DB_MESSAGES,
        permission: Permission.VIEW,
      },
      {
        label: 'Dokumente',
        link: 'documents',
        feature: Feature.DB_DOCUMENTS,
        permission: Permission.VIEW,
      },
      {
        label: 'Institutsneuigkeiten',
        link: 'institute-news',
        notifications: null,
        feature: Feature.DB_INSTITUTE_NEWS,
        permission: Permission.VIEW,
      },
      {
        label: 'Infozentrum',
        link: 'wiki',
        feature: Feature.DB_WIKI,
        permission: Permission.VIEW,
      },
    ];
  }

  public currentUser = this.userService.currentUser;
  public refreshIntervalId: any;

  public ngOnInit(): void {
    this.getNewsNotifications();
    this.newsArticleVisitedSubscription =
      this.newsArticleService.newsArticlesVisited.subscribe(
        it => (this.navLinks[4].notifications = Promise.resolve(it))
      );

    // get the number of new news articles every 5 minutes
    this.refreshIntervalId = setInterval(
      () => this.getNewsNotifications(),
      300000
    );
  }

  /**
   * getNewsNotifications
   * get the number of new news articles
   * @returns Promise<number>
   */
  private async getNewsNotifications(): Promise<void> {
    this.navLinks[4].notifications = firstValueFrom(
      this.newsArticleService.getNewNewsArticleCount().pipe(
        map(response => {
          console.debug('getNewNewsArticleCount backend response', response);
          if (!response.success) {
            console.error('getNewNewsArticleCount backend error', response);
            return 0;
          }
          this.newsArticleService.newNewsArticlesCount.next(response.data);
          return response.data;
        }),
        catchError(error => {
          console.error('getNewNewsArticleCount backend error', error);
          return of(0);
        }),
        takeUntil(this.destroy$)
      )
    );
  }

  public ngOnDestroy(): void {
    this.newsArticleVisitedSubscription.unsubscribe();

    clearInterval(this.refreshIntervalId);

    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
