import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StudentDetailRoutingModule } from './student-detail-routing.module';
import { CourseElogComponent } from './course-elog/course-elog.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { MaterialModule } from 'src/app/modules/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StudentDetailComponent } from './student-detail.component';
import { StudentOverviewOfFeesComponent } from './student-overview-of-fees/student-overview-of-fees.component';

@NgModule({
  declarations: [
    StudentDetailComponent,
    CourseElogComponent,
    StudentOverviewOfFeesComponent,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    RouterModule,
    StudentDetailRoutingModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [StudentDetailComponent],
})
export class StudentDetailModule {}
