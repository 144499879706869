import { RecurrencePattern } from './course.model';
import { CustomFileType } from './custom-file-type.model';
import { TreatmentCaseEventDate, TreatmentCaseEventType } from './event.model';
import { User } from './user.model';

export enum TherapyForm {
  INDIVIDUAL = 'Einzeltherapie',
  GROUP = 'Gruppentherapie',
}

export interface TreatmentCase {
  id: number;
  chiffre: string;
  age: number;
  therapyForm: TherapyForm;
  therapyType: { id: number; name: string };
  procedures: { id: number; name: string }[];
  supervisors: User[];
  supervisorText?: string;
  appointments?: TreatmentCaseAppointment[];
  internalNote?: string;
  files?: CustomFileType[];
}

export interface TreatmentCaseAppointment {
  id: number;
  type: TreatmentCaseEventType;
  startDate: Date;
  endDate: Date;
  timeUnits: number;
  supervisor?: User;
  selectedPatientEventDateIds?: number[];
  recurrencePattern?: RecurrencePattern;
  eventDates: TreatmentCaseEventDate[];
  internalNote?: string;
  supervisorText?: string;
}
