<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Supervision</h1>
      <h3 class="hasomed-text-h3">Detailansicht zur Supervision</h3>
    </div>
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onGoBack()">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onEditSupervisionAppointment()"
        [disabled]="!userService.currentUserIsStudent()">
        <i class="fa-light fa-pen hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Daten bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Titel </span>
        <span class="label-description">Titel für die Supervision</span>
      </div>
      <div class="flex-r align-center full-height">
        Supervision {{ treatmentCase?.chiffre }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Supervisionstyp </span>
      </div>

      <div class="flex-r align-center full-height">
        {{ supervisionAppointment?.type }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- therapyForm -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Zusammensetzung der Therapie </span>
        <span class="label-description">
          Wie soll die Therapie durchgeführt werden?
        </span>
      </div>
      <div class="flex-r align-center full-height">
        {{ treatmentCase?.therapyForm }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Für welchen Termin? </span>
      </div>
      <div class="flex-c justify-center full-height gap-8">
        <ng-container
          *ngFor="let patientEventDate of patientAppointmentEventDates">
          <div class="card align-center gap-8">
            <span>
              {{
                treatmentCase?.chiffre +
                  ' - ' +
                  (patientEventDate.startDate | date: 'dd.MM.yyyy') +
                  ' - ' +
                  (patientEventDate.room?.name ?? 'Kein Raum')
              }}
            </span>
            <div *ngIf="patientEventDate.canceled" class="label label-red">
              <i class="fa-light fa-ban label-icon"></i>
              <span class="hasomed-text-small label-text"> Abgesagt </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dauer in Zeiteinheiten </span>
        <span class="label-description"> In 45min Einheiten </span>
      </div>

      <div class="flex-r align-center full-height">
        {{ supervisionAppointment?.timeUnits }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Supervisionstermine </span>
      </div>
      <div class="hasomed-input-field justify-center full-height gap-8">
        <ng-container
          *ngFor="let eventDate of supervisionAppointment.eventDates">
          <div class="flex-r gap-8 align-center">
            <div class="card align-center gap-8">
              <div><i class="fa-light fa-calendar"></i></div>
              <span> {{ eventDate.startDate | date: 'dd.MM.yyyy' }} </span>
            </div>
            <div class="card align-center gap-8">
              <div><i class="fa-light fa-clock-eight"></i></div>
              <span>{{ eventDate.startDate | date: 'HH:mm' }}</span>
            </div>
            <div class="card align-center gap-8">
              <div><i class="fa-light fa-clock-eight"></i></div>
              <span>{{ eventDate.endDate | date: 'HH:mm' }}</span>
            </div>
            <div *ngIf="eventDate.canceled" class="label label-red">
              <i class="fa-light fa-ban label-icon"></i>
              <span class="hasomed-text-small label-text"> Abgesagt </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="internalNote">
        <span class="label-title"> Supervisor*in(*innen) </span>
        <span class="label-description">
          Supervisor*in(*innen) des Behandlungsfalls
        </span>
      </label>
      <div class="hasomed-input-field justify-center full-height">
        <span *ngFor="let supervisor of treatmentCase.supervisors">
          {{
            supervisor.name.academicTitle
              ? supervisor.name.academicTitle + ' '
              : '' + supervisor.name.firstname + ' ' + supervisor.name.lastname
          }}
        </span>
        <span> {{ treatmentCase?.supervisorText }} </span>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="internalNote">
        <span class="label-title"> Notizen für den Behandlungsfall </span>
        <span class="label-description">
          Interne Notizen des Behandlungsfalls
        </span>
      </label>
      <div class="hasomed-input-field justify-center full-height">
        {{ treatmentCase?.internalNote ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title"> Dokumente für den Behandlungsfall </span>
        <span class="label-description">
          Hier können alle Behandlungsfallrelevanten Dokumente eingesehen werden
        </span>
      </label>
      <app-empty-state></app-empty-state>
    </div>
  </div>
</div>
