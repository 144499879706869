<div class="component-content" *ngIf="!isLoading">
  <div class="header">
    <div class="flex-r gap-24 summary">
      <div class="flex-r image-container">
        <img [src]="course.titlePicture" class="course-image" alt="Titelbild" />
      </div>
      <div class="flex-c gap-16 justify-space-between w-full">
        <h1
          class="hasomed-text-h1"
          [matTooltip]="course.title?.length > 150 ? course.title : ''">
          {{ course.title | slice: 0 : 150
          }}{{ course.title?.length > 150 ? '...' : '' }}
        </h1>
        <div class="flex-r justify-space-between align-center gray-600">
          <div>
            <span> {{ getFullLecturerNames(course) }}, </span>
            <span class="m-z gray-600">
              {{ course.startDate | date: 'dd.MM.yyyy' }} -
              {{ course.endDate | date: 'dd.MM.yyyy' }},
              {{ course.startDate | date: 'HH:mm' }} -
              {{ course.endDate | date: 'HH:mm' }} Uhr
            </span>
          </div>
          <app-elog-cell
            class="flex-r justify-end"
            *ngIf="course?.registered && isStudent"
            [eLogStatusCounts]="course.eLogStatusCounts"
            [shortText]="false"></app-elog-cell>
        </div>
        <mat-divider></mat-divider>
        <div class="flex-r gap-16 justify-space-between">
          <div class="flex-r gap-24">
            <div class="flex-r align-center gap-8">
              <i class="fa-light fa-book hasomed-button-icon-sm"></i>
              <span class="hasomed-text-body gray-700">
                {{ getEducationCourseTitles(course) }}
              </span>
            </div>
            <div *ngIf="course.closed" class="flex-r align-center gap-8">
              <i class="fa-light fa-lock-keyhole hasomed-button-icon-sm"></i>
              <span class="hasomed-text-body gray-700"> geschlossen </span>
            </div>
            <div *ngIf="!course.closed" class="flex-r align-center gap-8">
              <i
                class="fa-light fa-lock-keyhole-open hasomed-button-icon-sm"></i>
              <span class="hasomed-text-body gray-700"> offen </span>
            </div>
            <div class="flex-r align-center gap-8">
              <i class="fa-light fa-map-pin hasomed-button-icon-sm"></i>
              <span class="hasomed-text-body gray-700">
                {{ getCourseRoom(course) }}
              </span>
            </div>
            <div class="flex-r align-center gap-8">
              <i class="fa-light fa-user-pen hasomed-button-icon-sm"></i>
              <span class="hasomed-text-body gray-700">
                {{ course.registeredStudents ?? 0 }} von
                {{ course.maxStudents }}
                angemeldet
              </span>
            </div>
          </div>
          <div class="flex-r gap-16">
            <button
              class="hasomed-text-button hasomed-button-white-grey"
              (click)="onBackToOverview()">
              <i class="hasomed-button-icon-sm fa-light fa-chevron-left"></i>
            </button>
            <button
              *ngIf="course.registered && isStudent"
              class="hasomed-text-button hasomed-button-white-grey"
              (click)="onCourseStudentChange(course)"
              [disabled]="course.closed"
              [matTooltip]="
                course.closed
                  ? 'Abmeldung von einem geschlossenen Kurs nicht möglich.'
                  : ''
              ">
              <i class="hasomed-button-icon-sm fa-light fa-right-to-bracket">
              </i>
              <span class="hasomed-button-text"> Abmelden </span>
            </button>
            <button
              *ngIf="!course.registered && isStudent"
              class="hasomed-text-button hasomed-button-primary"
              (click)="onCourseStudentChange(course)"
              [disabled]="
                course.closed || course.registeredStudents >= course.maxStudents
              "
              [matTooltip]="
                course.closed
                  ? 'Anmeldung an einem geschlossenen Kurs nicht möglich.'
                  : course.registeredStudents >= course.maxStudents
                    ? 'Der Kurs ist bereits belegt.'
                    : ''
              ">
              <i class="hasomed-button-icon-sm fa-light fa-right-to-bracket">
              </i>
              <span class="hasomed-button-text"> Anmelden </span>
            </button>
            <button
              *appCheckPermissions="{
                feature: Feature.COURSE_ADMINISTRATION,
                permission: Permission.ADMIN
              }"
              class="hasomed-text-button hasomed-button-white-grey"
              (click)="onEditCourse()">
              <i class="hasomed-button-icon-sm fa-light fa-pen"></i>
              <span class="hasomed-button-text">Bearbeiten</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-c main-content course-details">
    <div class="child-navigation">
      <ng-container *ngFor="let link of navLinks">
        <a
          [routerLink]="link.link"
          #rla="routerLinkActive"
          routerLinkActive
          [class.hasomed-button-blank]="!rla.isActive"
          routerLinkActive="hasomed-button-transparent-primary"
          class="hasomed-text-button">
          <span class="hasomed-text-menu">{{ link.label }}</span>
        </a>
      </ng-container>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
