import { NgModule } from '@angular/core';

import { OnboardingComponente } from './onboarding.component';

import { MaterialModule } from 'src/app/modules/material.module';
import { RouterModule } from '@angular/router';
import { ONBCourseCreationComponent } from './onboarding-course-creation/onb-course-creation.component';
import { ONBMasterDataComponent } from './onboarding-master-data/onb-master-data.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnbEducationCourseComponent } from './onboarding-education-course/onb-education-course.component';
import { OnbUserAndRolesComponent } from './onboarding-user-and-roles/onb-user-and-roles.component';
import { OnbOverviewComponent } from './onboarding-overview/onb-overview.component';
import { CreateEditEducationCourseComponent } from './onboarding-education-course/create-edit-education-course/create-edit-education-course.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { ONBEducationCourseTableComponent } from './onboarding-education-course/onb-education-course-table/onb-education-course-table.component';
import { ONBCourseCreationTableComponent } from './onboarding-course-creation/onb-course-creation-table/onb-course-creation-table.component';
import { ONBUserRoleTableComponent } from './onboarding-user-and-roles/onb-user-role-table/onb-user-role-table.component';

@NgModule({
  declarations: [
    OnboardingComponente,
    ONBCourseCreationComponent,
    ONBMasterDataComponent,
    OnbEducationCourseComponent,
    OnbUserAndRolesComponent,
    OnbOverviewComponent,
    CreateEditEducationCourseComponent,
    ONBEducationCourseTableComponent,
    ONBCourseCreationTableComponent,
    ONBUserRoleTableComponent,
  ],
  imports: [
    MaterialModule,
    RouterModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [OnboardingComponente],
  providers: [],
})
export class OnboardingModule {}
