import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-role-dialog',
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.scss'],
})
export class EditRoleDialogComponent implements OnInit {
  public editRoleForm: FormGroup;
  public user: User;

  constructor(
    public dialogRef: MatDialogRef<EditRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { user: User },
    private userService: UserService,
    private alertService: AlertService
  ) {
    this.user = data.user;
  }

  public ngOnInit(): void {
    this.editRoleForm = new FormGroup({
      role: new FormControl(this.user.id_role),
    });
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public async onSave(): Promise<void> {
    this.user.id_role = this.editRoleForm.value.role;

    this.userService
      .updateUserRole(this.user.id, this.user.id_role, this.user.id_institute)
      .subscribe({
        next: response => {
          console.debug('updateUser backend response: ', response);
          if (!response.success) {
            console.error(response.message);
            this.alertService.showErrorAlert(
              'Fehler',
              'Der Benutzer konnte nicht aktualisiert werden'
            );
            return;
          }
          this.alertService.showSuccessAlert(
            'Benutzer aktualisiert',
            'Der Benutzer wurde erfolgreich aktualisiert'
          );
          this.dialogRef.close(true);
        },
        error: error => {
          console.error(error);
          this.alertService.showErrorAlert(
            'Fehler',
            'Der Benutzer konnte nicht aktualisiert werden'
          );
        },
      });
  }

  public onDelete(): void {
    // TODO
  }
}
