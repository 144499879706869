import { Pipe, PipeTransform } from '@angular/core';
import { InstituteEvent } from '../models/event.model';
import { Filter, FilterDateRange, FilterType } from '../models/filter.model';
import { Room } from '../models/room.model';
import * as moment from 'moment';
import { co } from '@fullcalendar/core/internal-common';

@Pipe({
  name: 'filterRoomOrganization',
  standalone: true,
})
export class FilterRoomOrganizationPipe implements PipeTransform {
  transform(
    instituteEvents: InstituteEvent[],
    filter: Filter[]
  ): InstituteEvent[] {
    if (!instituteEvents || !filter) {
      return instituteEvents;
    }

    return instituteEvents.filter(instituteEvent => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.DATE_RANGE:
            const start = moment((filter.value as FilterDateRange).start)
              .startOf('day')
              .toDate();
            const end = moment((filter.value as FilterDateRange).end)
              .endOf('day')
              .toDate();
            return (
              moment(instituteEvent.startDate).isSameOrAfter(start) &&
              moment(instituteEvent.endDate).isSameOrBefore(end)
            );
          case FilterType.ROOM_NAME:
            if (this.isRoom(filter.value)) {
              return instituteEvent.currentRoom?.id === filter.value.id;
            }
            return false;
          case FilterType.OPEN_ROOMPLANNING:
            return instituteEvent.eventDates.some(eventDate => {
              // if event is in future and has no room, return true
              if (
                moment(eventDate.startDate).isSameOrAfter(moment()) &&
                !eventDate.room
              ) {
                return true;
              }
              return false;
            });
          default:
            return true;
        }
      });
    });
  }

  private isRoom(obj: any): obj is Room {
    return obj && typeof obj === 'object' && 'floor' in obj;
  }
}
