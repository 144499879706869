<form
  class="component-content"
  [formGroup]="treatmentCaseForm"
  (ngSubmit)="onSubmit()">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{
          editMode
            ? 'Behandlungsfall bearbeiten'
            : 'Neuen Behandlungsfall erstellen'
        }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie einen bestehenden Behandlungsfall'
            : 'Erstellen Sie einen neuen Behandlungsfall'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>
  <div *ngIf="!isLoading" class="main-content">
    <div class="formular-row">
      <label class="formular-row-label" for="chiffre">
        <span class="label-title">
          Patienten Chiffre
          <span *ngIf="isRequired(treatmentCaseForm.get('chiffre'))"> * </span>
        </span>
        <span class="label-description"> Beschreibung </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="chiffre"
            class="hasomed-text-input"
            type="text"
            formControlName="chiffre" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="treatmentCaseForm.get('chiffre')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">
          Alter
          <span *ngIf="isRequired(treatmentCaseForm.get('age'))"> * </span>
        </span>
        <span class="label-description"> Alter des Patienten </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="age"
            class="hasomed-text-input"
            type="number"
            min="0"
            max="100"
            formControlName="age" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="treatmentCaseForm.get('age')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- therapyForm -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Zusammensetzung der Therapie
          <span *ngIf="isRequired(treatmentCaseForm.get('therapyForm'))">
            *
          </span>
        </span>
        <span class="label-description">
          Wie soll die Therapie durchgeführt werden?
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input"></div>
        <div class="hasomed-toggle-button">
          <button
            *ngFor="
              let therapyForm of TherapyForm
                | keyvalue: originalTherapyFormOrder
            "
            type="button"
            class="hasomed-text-button button-full-width"
            [ngClass]="{
              'hasomed-button-orange':
                treatmentCaseForm.get('therapyForm').value ===
                therapyForm.value,
              'hasomed-button-white-grey':
                treatmentCaseForm.get('therapyForm').value != therapyForm.value
            }"
            [class.btn-left]="therapyForm.value === TherapyForm.INDIVIDUAL"
            [class.btn-right]="therapyForm.value === TherapyForm.GROUP"
            (click)="setTherapyForm(therapyForm.value)">
            <span class="hasomed-button-text">{{ therapyForm.value }}</span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            treatmentCaseForm.get('therapyForm')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Art der Therapie
          <span *ngIf="isRequired(treatmentCaseForm.get('therapyType'))">
            *
          </span>
        </span>
        <span class="label-description"> Wählen Sie einen TherapieTyp </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select
              id="therapyType"
              formControlName="therapyType"
              class="hasomed-select">
              <mat-option
                [value]="availableTherapyType.id"
                class="hasomed-text-body"
                *ngFor="let availableTherapyType of availableTherapyTypes">
                {{ availableTherapyType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            treatmentCaseForm.get('therapyType')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Verfahren
          <span *ngIf="isRequired(treatmentCaseForm.get('procedures'))">
            *
          </span>
        </span>
        <span class="label-description">
          Wählen Sie ein oder mehrere Verfahren
        </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select id="procedures" formControlName="procedures" multiple>
              <mat-option
                [value]="procedure.id"
                class="hasomed-text-body"
                *ngFor="let procedure of availableProcedures">
                {{ procedure.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="treatmentCaseForm.get('procedures')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!editMode"></mat-divider>

    <div class="formular-row" *ngIf="!editMode">
      <label class="formular-row-label" for="supervisor">
        <span class="label-title">
          Supervisor*in
          <span *ngIf="isRequired(treatmentCaseForm.get('supervisors'))">
            *
          </span>
        </span>
        <span class="label-description">
          Wählen Sie eine Supervisor*in für den Behandlungsfall
        </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input-columns gap-32">
          <div class="flex-c gap-16 select-user-container">
            <div class="hasomed-search">
              <i class="fa-light fa-search hasomed-search-icon"></i>
              <input
                id="supervisorSearch"
                class="hasomed-search-input"
                type="text"
                placeholder="Personensuche"
                formControlName="supervisorSearch"
                #supervisorInput />
            </div>
            <div
              class="flex-c vertical-overflow gap-8 users"
              [ngClass]="{
                'error-border':
                  treatmentCaseForm.get('supervisors').invalid &&
                  treatmentCaseForm.get('supervisors').touched
              }"
              *ngIf="supervisors && selectedSupervisorIds">
              <div
                class="flex-r user-container"
                [ngClass]="{
                  active: selectedSupervisorIds.includes(supervisor.id)
                }"
                *ngFor="
                  let supervisor of supervisors
                    | searchFilter: supervisorInput.value
                "
                (click)="onSupervisorSelected(supervisor)">
                <div class="flex-r user">
                  <div class="user-icon">
                    <img
                      src="{{
                        supervisor.profilePicture ??
                          '../../../../../assets/img/user.jpg'
                      }}"
                      alt=""
                      class="user-icon-image" />
                  </div>
                  <div class="flex-c justify-center">
                    <span class="hasomed-text-body">
                      {{ supervisor.name.academicTitle }}
                      {{ supervisor.name.firstname }}
                      {{ supervisor.name.lastname }}
                    </span>
                    <span class="hasomed-text-small">
                      Supervisor*in für XYZ
                    </span>
                  </div>
                </div>
                <div *ngIf="selectedSupervisorIds.includes(supervisor.id)">
                  <i class="fa-light fa-circle-check user-check"></i>
                </div>
              </div>
            </div>
            <span *ngIf="supervisors?.length === 0" class="hasomed-text-small">
              Keine Supervisor*innen gefunden
            </span>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            treatmentCaseForm.get('supervisors')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="internalNote">
        <span class="label-title">
          Notizen für den Behandlungsfall (nur INTERN sichtbar)
          <span *ngIf="isRequired(treatmentCaseForm.get('internalNote'))">
            *
          </span>
        </span>
        <span class="label-description">
          Hier können Sie weitere Notizen für den Behandlungsfall anlegen. Diese
          sind nur für die Verwaltung sichtbar.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="note"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="internalNote"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            treatmentCaseForm.get('internalNote')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title">
          Dokumente für den Behandlungsfall
          <span *ngIf="isRequired(treatmentCaseForm.get('documentUpload'))">
            *
          </span>
        </span>
        <span class="label-description">
          Hier können alle Behandlungsfallrelevanten Dokumente hochgeladen
          werden
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            [context]="'treatmentCaseDocumentsUpload'"
            [logs]="true"
            inputId="documentUpload"
            [multiple]="true"
            [disabled]="true"
            matTooltip="Der Dokumentenupload ist in dieser Version nicht verfügbar."
            [scrollTo]="true"
            [showFileList]="true"
            [requiredFileTypes]="requiredFileTypesDocuments"
            maxFileSize="12 MB"
            (change)="onDocumentsSelected($event)">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
