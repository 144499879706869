import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent {
  constructor(public dialogRef: MatDialogRef<FeedbackDialogComponent>) {
    dialogRef.disableClose = false;
  }

  public onConfirm() {
    const emailAddress = 'sebastian.nordenbruch@hasomed.de';
    const subject = 'Feedback EleGuide';
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}`;

    // Open the default email client
    window.location.href = mailtoLink;
    this.dialogRef.close(true);
  }
}
