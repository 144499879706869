<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Praktische Tätigkeit ansehen</h1>
      <h3 class="hasomed-text-h3">Sehen Sie die praktische Tätigkeit ein</h3>
    </div>
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onGoBack()">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onEditPracticalWork()">
        <i class="fa-light fa-pen hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Daten bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Name der Einrichtung </span>
        <span class="label-description"> Ort der praktischen Tätigkeit </span>
      </div>
      <div class="flex-c">
        <span class="label-title">{{ practicalWork?.title }}</span>
        <span class="label-description">
          {{
            practicalWork?.address.street +
              ' ' +
              practicalWork?.address.houseNumber +
              ', ' +
              practicalWork?.address.zipCode +
              ' ' +
              practicalWork?.address.city
          }}
        </span>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title"> Beschreibung </span>
        <span class="label-description"> Ergänzende Informationen </span>
      </label>
      <div class="hasomed-input-field justify-center full-height">
        {{ practicalWork?.description ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dauer der Tätigkeit </span>
        <span class="label-description">
          Gesamtdauer der praktischen Tätigkeit in Stunden
        </span>
      </div>
      <div class="flex-r align-center full-height">
        {{ practicalWork?.duration | floatToHoursMinutes }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Zeitraum </span>
      </div>
      <div class="flex-r align-center full-height">
        {{ practicalWork?.startDate | date: 'dd.MM.yyyy' }} -
        {{ practicalWork?.endDate | date: 'dd.MM.yyyy' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="note">
        <span class="label-title"> Freitext </span>
        <span class="label-description"> Freitext für interne Notizen </span>
      </label>
      <div class="hasomed-input-field justify-center full-height">
        {{ practicalWork?.internalNote ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title"> Dokumente </span>
        <span class="label-description">
          Hier können Sie Dokumente für diesen Eintrag einsehen
        </span>
      </label>
      <div class="formular-row-input">
        <ng-container
          *ngIf="!practicalWork?.files || practicalWork?.files?.length === 0">
          <div class="card">Kein Dokument hochgeladen</div>
        </ng-container>
        <div class="flex-c flex-1 gap-8">
          <app-file-view
            *ngFor="let file of practicalWork?.files"
            [file]="file"></app-file-view>
        </div>
      </div>
    </div>
  </div>
</div>
