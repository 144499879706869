import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { CustomFileType } from 'src/app/models/custom-file-type.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FileService } from 'src/app/services/file.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  public columnsToDisplay = [
    'file',
    'creator',
    'modifiedDate',
    'fileSize',
    'fileType',
    'actions',
  ];
  public isLoading = true;
  public searchForm: FormGroup;

  public Feature = Feature;
  public Permission = Permission;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private fileService: FileService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    this.searchForm = new FormGroup({
      searchText: new FormControl(''),
    });

    const id_institute = this.userService.currentUser?.id_institute;
    const id_role = this.userService.currentUser?.id_role;
    const id_user = this.userService.currentUser?.id;

    this.getFiles(id_institute, id_role, id_user);
  }

  /**
   * getFiles
   * get all institute files from the backend
   * @param id_institute
   * @param id_role
   */
  private getFiles(id_institute: number, id_role: number, id_user: number) {
    this.fileService
      .getAllInstituteFiles(id_institute, id_role, id_user)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('course files Backend response', response);
          if (!response.success) {
            console.error('Course files could not be loaded');
            this.isLoading = false;
            return;
          }

          const files = response.data
            ? await Promise.all(
                response.data.map(
                  async (file: any): Promise<CustomFileType> =>
                    await this.fileService.parseBackendFile(file)
                )
              )
            : [];

          files?.forEach(file => {
            file.fileType = file.filename.split('.').pop();
          });

          this.dataSource.data = files;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'file':
                return item.filename;
              case 'creator':
                return item.name.firstname + ' ' + item.name.lastname;
              default:
                return item[property];
            }
          };
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
        },
        error: error => {
          console.error('Course files could not be loaded', error);
          this.isLoading = false;
        },
      });
  }

  /**
   * filter files by search text
   * @param event
   */
  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * onOpen
   * open the file
   * @param file
   */
  public onOpen(file: any) {
    this.fileService.openFile(file.id).subscribe({
      next: () => {
        console.debug('download successfull');
      },
      error: error => {
        console.error('error', error);
      },
    });
  }

  /**
   * onDownload
   * download the file
   * @param file
   */
  public onDownload(file: any) {
    this.fileService.downloadFile(file.id).subscribe({
      next: () => {
        console.debug('download successfull');
      },
      error: error => {
        console.error('error', error);
      },
    });
  }

  /**
   * onDelete
   * delete the file
   * @param file
   */
  public onDelete(file: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Datei löschen',
        message: `Möchten Sie die Datei "${file.filename}" wirklich löschen?`,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (!dialogResult) {
        return;
      }
      this.fileService.deleteFile(file.id).subscribe({
        next: response => {
          console.debug('deleteFile backend response', response);
          if (!response.success) {
            console.error('delete failed');
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt! ',
              'Die Datei konnte nicht gelöscht werden.'
            );
            return;
          }
          this.alertService.showSuccessAlert(
            'Das hat geklappt! ',
            'Die Datei wurde erfolgreich gelöscht.'
          );
          this.dataSource.data = this.dataSource.data.filter(
            item => item.id !== file.id
          );
        },
        error: error => {
          console.error('error', error);
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt! ',
            'Die Datei konnte nicht gelöscht werden.'
          );
        },
      });
    });
  }

  /**
   * onUpload
   * navigate to the upload page
   */
  public onUpload() {
    this.router.navigate(['create'], { relativeTo: this.activatedRoute });
  }

  /**
   * onEdit
   * navigate to the edit page
   * @param file
   */
  public onEdit(file: CustomFileType) {
    this.router.navigate(['edit', btoa(file.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  public formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
