import { Injectable } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { Course, CourseEvent } from '../models/course.model';
import { environment } from 'src/configs/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { User } from '../models/user.model';
import { UserService } from './user.service';
import { CustomFileType } from '../models/custom-file-type.model';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { RoomService } from './room.service';
import { FileService } from './file.service';
import { eLog } from '../models/elog.model';
import { DecryptionService } from './decryption.service';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private cryptoService: CryptoService,
    private roomService: RoomService,
    private fileService: FileService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  public getInstituteCourseTypes(id_institude: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `course/getInstituteCourseTypes.php?id=${id_institude}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get all courses of an institute
   * @param institute_id
   * @param expands
   * @returns
   */
  public getInstituteCourses(
    institute_id: number,
    expands?: string | null
  ): Observable<any> {
    const expand = expands ? `&expand=${expands}` : '';
    return this.http
      .get(
        environment.authority_short +
          `course/getInstituteCourses.php?id_institute=${institute_id}` +
          expand
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get all courses of a lecturer
   * @param institute_id
   * @param id_lecturer
   * @param expands
   * @returns
   */
  public getLecturersCourses(
    institute_id: number,
    id_lecturer: number
  ): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `course/getLecturersCourses.php?id_institute=${institute_id}&id_lecturer=${id_lecturer}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  // get all courses of a student
  public getStudentsCourses(
    id_user: number,
    id_institute: number,
    expands?: string | null
  ): Observable<any> {
    const expand = expands ? `&expand=${expands}` : '';
    return this.http
      .get(
        environment.authority_short +
          `course/getStudentsCourses.php?id_user=${id_user}&id_institute=${id_institute}` +
          expand
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  // get course by id
  public getCourseById(
    course_id: number,
    expands?: string | null
  ): Observable<any> {
    const id_user = this.userService.currentUser.id;
    const expand = expands ? `&expand=${expands}` : '';
    return this.http
      .get(
        environment.authority_short +
          `course/getCourse.php?id_course=${course_id}&id_user=${id_user}` +
          expand
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /* returns all unique types from all courses */
  public getAllCourseTypes(courses: Course[]) {
    const uniqueTypes = [
      ...new Map(
        courses?.map(course => [course.type.id, course.type])
      ).values(),
    ];
    return uniqueTypes;
  }

  /* returns all unique lecturers */
  public getAllCourseLecturer(courses: Course[]) {
    const uniqueLecturers = [
      ...new Map(
        courses?.flatMap(course =>
          course.lecturers.map(lecturer => [lecturer.id, lecturer])
        )
      ).values(),
    ];
    return uniqueLecturers;
  }

  /**
   * getAllCourseRooms
   * @param courses - Courses to get rooms from
   * @returns all unique rooms from the given courses
   */
  public getAllCourseRooms(courses: Course[]) {
    const uniqueRooms = [
      ...new Map(
        courses
          ?.filter(course => course.room)
          .map(course => [course.room?.id, course.room])
      ).values(),
    ];
    return uniqueRooms;
  }

  /* Registering a student to a course */
  public registerToCourse(
    id_course: number,
    id_student: number
  ): Observable<any> {
    const formData = new FormData();
    formData.append('id_course', id_course.toString());
    formData.append('id_student', id_student.toString());

    console.debug('register to course post data:', formData);

    return this.http.post(
      environment.authority_short + 'course/registerToCourse.php',
      formData
    );
  }

  /**
   * deregisterFromCourse
   * @description deregistering a student from a course
   * @param id_course
   * @param id_student
   * @returns Observable<any>
   */
  public deregisterFromCourse(
    id_course: number,
    id_student: number
  ): Observable<any> {
    const formData = new FormData();
    formData.append('id_course', id_course.toString());
    formData.append('id_student', id_student.toString());

    console.debug('deregister from course post data:', formData);

    return this.http.post(
      environment.authority_short + 'course/deregisterFromCourse.php',
      formData
    );
  }

  /* Create a new Course */
  public async createCourse(createCourseData: any): Promise<Observable<any>> {
    createCourseData.courseStudents =
      createCourseData.courseStudents &&
      (await Promise.all(
        createCourseData.courseStudents.map(async (student: User) => {
          return {
            ...student,
            name: {
              ...student.name,
              firstname:
                student.name.firstname &&
                (await this.cryptoService.encrypt(student.name.firstname)),
              lastname:
                student.name.lastname &&
                (await this.cryptoService.encrypt(student.name.lastname)),
            },
          };
        })
      ));
    createCourseData.lecturers =
      createCourseData.lecturers &&
      (await Promise.all(
        createCourseData.lecturers.map(async (lecturer: User) => {
          return {
            ...lecturer,
            name: {
              ...lecturer.name,
              firstname:
                lecturer.name.firstname &&
                (await this.cryptoService.encrypt(lecturer.name.firstname)),
              lastname:
                lecturer.name.lastname &&
                (await this.cryptoService.encrypt(lecturer.name.lastname)),
            },
          };
        })
      ));
    createCourseData.files =
      createCourseData.files &&
      (await Promise.all(
        createCourseData.files.map(
          async (file: CustomFileType): Promise<CustomFileType> => {
            return {
              ...file,
              filename:
                file.filename &&
                (await this.cryptoService.encrypt(file.filename)),
              file: file.file && (await this.cryptoService.encrypt(file.file)),
            };
          }
        )
      ));
    console.debug('Course Form Data:', createCourseData);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.authority_short + 'course/createCourse.php',
      createCourseData,
      { headers: headers }
    );
  }

  /* Update an existing course */
  public async updateCourse(
    mode: string,
    updatedCourseData: any
  ): Promise<Observable<any>> {
    updatedCourseData.courseStudents =
      updatedCourseData.courseStudents &&
      (await Promise.all(
        updatedCourseData.courseStudents.map(async (student: User) => {
          return {
            ...student,
            name: {
              ...student.name,
              firstname:
                student.name?.firstname &&
                (await this.decryptionService.decryptString(
                  student.name.firstname
                )),
              lastname:
                student.name?.lastname &&
                (await this.decryptionService.decryptString(
                  student.name.lastname
                )),
            },
          };
        })
      ));
    updatedCourseData.lecturers =
      updatedCourseData.lecturers &&
      (await Promise.all(
        updatedCourseData.lecturers.map(async (lecturer: User) => {
          return {
            ...lecturer,
            name: {
              ...lecturer.name,
              firstname:
                lecturer.name.firstname &&
                (await this.decryptionService.decryptString(
                  lecturer.name.firstname
                )),
              lastname:
                lecturer.name.lastname &&
                (await this.decryptionService.decryptString(
                  lecturer.name.lastname
                )),
            },
          };
        })
      ));
    updatedCourseData.files =
      updatedCourseData.files &&
      (await Promise.all(
        updatedCourseData.files.map(
          async (file: CustomFileType): Promise<CustomFileType> => {
            return {
              ...file,
              filename:
                file.filename &&
                (await this.decryptionService.decryptString(file.filename)),
              file:
                file.file &&
                (await this.decryptionService.decryptString(file.file)),
            };
          }
        )
      ));
    updatedCourseData.mode = mode;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    console.debug('UpdateCourse post data', updatedCourseData);

    return this.http.post(
      environment.authority_short + 'course/updateCourse.php',
      updatedCourseData,
      { headers: headers }
    );
  }

  /* Delete an existing course */
  public deleteCourse(id: number): Observable<any> {
    const formData = new FormData();
    formData.append('id', id.toString());

    console.debug('delete course post data', formData);

    return this.http.post(
      environment.authority_short + 'course/deleteCourse.php',
      formData
    );
  }

  public getAllCourseEvents(
    id_course: number,
    id_user: number,
    id_role: number
  ): Observable<any> {
    let params = new HttpParams()
      .set('id_course', id_course.toString())
      .set('id_user', id_user.toString())
      .set('id_role', id_role?.toString());
    return this.http
      .get(environment.authority_short + 'course/getAllCourseEventDates.php', {
        params: params,
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  public getAllCourseFiles(id_course: number): Observable<any> {
    let params = new HttpParams().set('id_course', id_course.toString());
    return this.http
      .get(environment.authority_short + 'course/getAllCourseFiles.php', {
        params: params,
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   *
   * @param courseData
   * @returns
   */
  public async parseBackendCourse(courseData: any) {
    let titlePicture: string;
    if (courseData.defaultTitlePictureId) {
      switch (courseData.defaultTitlePictureId) {
        case 1:
          titlePicture = '/assets/img/course-img-1.jpg';
          break;
        case 2:
          titlePicture = '/assets/img/course-img-2.jpg';
          break;
        case 3:
          titlePicture = '/assets/img/course-img-3.jpg';
          break;
      }
    }
    const course: Course = {
      id: courseData.id,
      title: courseData.title,
      description: courseData.description,
      educationCourses: courseData.educationCourses,
      mandatory: Boolean(courseData.mandatory),
      titlePicture: courseData.titlePicture
        ? atob(courseData.titlePicture)
        : titlePicture,
      defaultTitlePictureId: courseData.defaultTitlePictureId,
      type: courseData.type,
      duration: courseData.duration,
      maxStudents: courseData.maxStudents,
      room: courseData.room
        ? await this.roomService.parseBackendRoom(courseData.room)
        : null,
      differentRooms: Boolean(courseData.differentRooms),
      lecturers: courseData.lecturers
        ? await Promise.all(
            courseData.lecturers?.map(async (userData: User): Promise<User> => {
              return await this.userService.parseBackendUser(userData);
            })
          )
        : null,
      logonEnabled: Boolean(courseData.logonEnabled),
      closed: Boolean(courseData.closed),
      startDate: moment(courseData.startDate, 'YYYY-MM-DD HH:mm:ss').toDate(),
      endDate: moment(courseData.endDate, 'YYYY-MM-DD HH:mm:ss').toDate(),
      allDayEvent: Boolean(courseData.allDayEvent),
      recurrencePattern: courseData.recurrencePattern,
      learningContents: courseData.learningContents,
      examTypes: courseData.examTypes,
      location: courseData.location,
      link: courseData.link,
      courseEvents: courseData.courseEvents
        ? await Promise.all(
            courseData.courseEvents?.map(
              async (event: any): Promise<CourseEvent> => {
                return {
                  id: event.id,
                  startDate: moment(
                    event.startDate,
                    'YYYY-MM-DD HH:mm:ss'
                  ).toDate(),
                  endDate: moment(
                    event.endDate,
                    'YYYY-MM-DD HH:mm:ss'
                  ).toDate(),
                  room: event.room
                    ? await this.roomService.parseBackendRoom(event.room)
                    : null,
                  eLogStatusCounts: event.eLogStatusCounts ?? null,
                  eLogs: event.eLogs
                    ? await Promise.all(
                        event.eLogs?.map(async (elog: any): Promise<eLog> => {
                          return {
                            id: elog.id,
                            student: await this.userService.parseBackendUser(
                              elog.student
                            ),
                            eLogStatus: elog.eLogStatus,
                          };
                        })
                      )
                    : [],
                };
              }
            )
          )
        : [],
      finished: Boolean(courseData.finished),
      registered: Boolean(courseData.registered),
      registeredStudents: courseData.registeredStudents,
      courseStudents: courseData.courseStudents
        ? await Promise.all(
            courseData.courseStudents?.map(
              async (userData: User): Promise<User> => {
                return await this.userService.parseBackendUser(userData);
              }
            )
          )
        : null,
      eLogStatusCounts: courseData.eLogStatusCounts,
      files: courseData.files
        ? await Promise.all(
            courseData.files?.map(async (it: any): Promise<CustomFileType> => {
              return await this.fileService.parseBackendFile(it);
            })
          )
        : null,
    };
    return course;
  }

  /**
   * getNumberOfCoursesFromLecturer
   * get the number of courses from the current lecturer
   * @returns Observable<any>
   */
  public getNumberOfCoursesFromLecturer() {
    const id_lecturer = this.userService.currentUser.id;
    return this.http
      .get(
        environment.authority_short +
          `course/getNumberOfCoursesFromLecturer.php?id_lecturer=${id_lecturer}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getCourseLecturers
   * get all lecturers of a course
   * @param id_course number
   * @returns Observable<any>
   */
  public getCourseLecturers(id_course: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `course/getCourseLecturers.php?id_course=${id_course}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getCourseParticipants
   * get all participants of a course
   * @param id_course number
   * @returns Observable<any>
   */
  public getCourseParticipants(id_course: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `course/getCourseParticipants.php?id_course=${id_course}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }
}
