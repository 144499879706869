import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { Location } from '@angular/common';
import { TreatmentCase } from 'src/app/models/treatment-case.model';
import {
  TreatmentCaseEventDate,
  TreatmentCaseEventType,
} from 'src/app/models/event.model';
import { UserService } from 'src/app/services/user.service';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-treatment-case-overview',
  templateUrl: './treatment-case-overview.component.html',
  styleUrls: ['./treatment-case-overview.component.scss'],
})
export class TreatmentCaseOverviewComponent implements OnInit, OnDestroy {
  public treatmentCase: TreatmentCase;
  public patientAppointmentEventDates: TreatmentCaseEventDate[] = [];
  public isLoading: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private treatmentCaseService: TreatmentCaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    public userService: UserService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (!params.id) {
        return;
      }

      const id = +atob(params.id);

      this.treatmentCaseService
        .getTreatmentCase(id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async result => {
            console.debug('getTreatmentCase result', result);

            if (!result.success) {
              console.error(result.message);
              this.isLoading = false;
              return;
            }
            this.treatmentCase =
              await this.treatmentCaseService.parseBackendTreatmentCase(
                result.data
              );
            this.getPatientAppointmentsEventDates();
            this.isLoading = false;
          },
          error: error => {
            console.error(error.message);
            this.isLoading = false;
          },
        });
    });
  }

  /**
   * getPatientAppointments
   * get the patient appointments of the treatment case
   * @returns void
   */
  public getPatientAppointmentsEventDates(): void {
    this.treatmentCase.appointments.forEach(appointment => {
      if (
        appointment.type === TreatmentCaseEventType.PATIENT_SESSION &&
        appointment?.eventDates?.length > 0
      ) {
        this.patientAppointmentEventDates.push(...appointment.eventDates);
      }
    });

    // sort the patient appointments by start date
    this.patientAppointmentEventDates.sort((a, b) => {
      return a.startDate.getTime() - b.startDate.getTime();
    });
  }

  /**
   * onEditTreatmentCase
   * open the edit treatment case page
   * @returns void
   */
  public onEditTreatmentCase() {
    if (this.treatmentCase) {
      this.router.navigate(
        ['../edit-treatment-case', btoa(String(this.treatmentCase.id))],
        {
          relativeTo: this.activatedRoute,
        }
      );
    }
  }

  /**
   * onGoBack
   */
  public onGoBack() {
    this.location.back();
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
