import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { environment } from 'src/configs/environment';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class EducationProgressService {
  constructor(
    private http: HttpClient,
    private cancellationService: CancellationService
  ) {}

  /**
   * getEducationProgress
   * @param id_user
   * @returns Observable<any>
   */
  public getEducationProgress(id_user: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'educationProgress/getStudentEducationProgress.php?id_user=' +
          id_user
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }
}
