import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Feature, Permission, Role } from '../models/permission.model';
import { PermissionService } from '../services/permission.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appCheckPermissions]',
})
export class CheckPermissionsDirective implements OnInit, OnDestroy {
  private subscription: Subscription;
  private lastPermissionAndFeature: {
    permission: Permission;
    feature: Feature;
  };
  private isViewCreated: boolean = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {}

  @Input() set appCheckPermissions(permissionAndFeature: {
    permission: Permission;
    feature: Feature;
  }) {
    this.lastPermissionAndFeature = permissionAndFeature;
    this.checkPermissions();
  }

  public ngOnInit() {
    // Subscribe to permission changes
    this.subscription = this.permissionService.permissionsChanged.subscribe(
      () => {
        // Recheck permissions when they change
        this.checkPermissions();
      }
    );
  }

  public ngOnDestroy() {
    // Unsubscribe when the directive is destroyed
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private checkPermissions() {
    const { permission, feature } = this.lastPermissionAndFeature || {};

    const hasPermission = this.permissionService.checkPermission(
      feature,
      permission
    );

    if (hasPermission && !this.isViewCreated) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.isViewCreated = true;
      // console.log(
      //   'Show item, because permissions were granted:',
      //   this.lastPermissionAndFeature
      // );
    } else if (!hasPermission && this.isViewCreated) {
      // console.log(
      //   'Hide item because permission were removed',
      //   this.lastPermissionAndFeature
      // );
      this.viewContainer.clear();
      this.isViewCreated = false;
    }
  }
}
