<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        Verwaltung, Lehrpersonal, Kandidat*innen einladen
      </h1>
      <h3 class="hasomed-text-h3">
        Hier können Sie schon die Personen des Instituts einladen
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        *ngIf="false"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <!-- Go back button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onGoBack()">
        <i class="fa-light fa-arrow-left hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Zurück</span>
      </button>

      <!-- Continue button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onContinue()">
        <i class="fa-light fa-arrow-right hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text"> Speichern und weiter</span>
      </button>
    </div>
  </div>

  <!-- LOADING -->
  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <span *ngIf="!instituteService.currentInstitute" class="hasomed-text-h3">
      Gebe Sie dem Institut zunächst einen Namen und speichern Sie die Daten,
      bevor Sie Benutzer anlegen!
    </span>
    <div *ngIf="instituteService.currentInstitute" class="flex-c gap-16">
      <!-- Studenten -->
      <form [formGroup]="studentForm" (ngSubmit)="onAddStudent()">
        <div class="formular-row">
          <label class="formular-row-label" for="student-email">
            <span class="label-title">Kandidat*in einladen</span>
            <span class="label-description">
              Laden Sie Ihre Kandidat*innen ein
            </span>
          </label>
          <div class="formular-row-input-columns">
            <!-- Drag and Drop -->
            <app-upload-area-dnd
              [disabled]="true"
              matTooltip="Diese Funktion ist momentan noch nicht verfügbar"
              context="student"
              inputId="studentUpload"
              [logs]="false"
              [showFileList]="true"
              [requiredFileTypes]="requiredFileTypes"
              (change)="changeExcel($event, 'student')">
            </app-upload-area-dnd>

            <div class="formular-row-input">
              <span class="centered-text">
                Laden Sie die Excel Liste hoch oder fügen Sie manuell hinzu
              </span>
            </div>

            <div class="hasomed-input-field">
              <div class="hasomed-text-input-with-button">
                <input
                  id="student-email"
                  type="email"
                  class="hasomed-text-input"
                  formControlName="email"
                  placeholder="kandidat*in@email.de"
                  maxlength="255" />
                <button
                  type="submit"
                  class="hasomed-text-button hasomed-button-white-grey hover-primary hide-border-left"
                  [disabled]="
                    !studentForm.valid ||
                    studentForm.valid !== true ||
                    !studentForm.value.email
                  ">
                  <div class="hasomed-button-icon">
                    <i
                      class="fa-light fa-square-plus hasomed-button-icon-sm"></i>
                  </div>
                  <span class="hasomed-button-text">Hinzufügen</span>
                </button>
              </div>

              <!-- Error Messages -->
              <app-error-message
                [formField]="studentForm.get('email')"></app-error-message>
            </div>

            <!-- User -->
            <div class="formular-scroll-container" *ngIf="students.length > 0">
              <div class="flex-r gap-8" *ngFor="let user of students">
                <div class="card flex-1">
                  <div class="flex-r gap-8">
                    <span class="email hasomed-text-body">{{
                      user.email
                    }}</span>
                    <div
                      *ngIf="!user.id"
                      class="label-no-icon label-white-green">
                      <span class="label-text"> Neu </span>
                    </div>
                    <div
                      *ngIf="user.isRegistered"
                      class="label label-white-green">
                      <i class="fa-light fa-check label-icon-sm"></i>
                      <span class="label-text"> Registriert </span>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="button-icon-red"
                    matTooltip="Benutzer entfernen"
                    (click)="onRemoveStudent(user)">
                    <i class="fa-light fa-circle-xmark"></i>
                  </button>
                </div>
                <button
                  class="hasomed-icon-button hasomed-button-white-grey"
                  type="button"
                  [disabled]="user.isRegistered || user.isInvited"
                  [matTooltip]="
                    user.isRegistered
                      ? 'Der Benutzer ist bereits registriert und kann deshalb nicht mehr eingeladen werden.'
                      : user.isInvited
                        ? 'Der Benutzer wurde bereits eingeladen.'
                        : 'User einladen'
                  "
                  (click)="onInviteUser(user)">
                  <i class="fa-light fa-paper-plane hasomed-button-icon-sm"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>

      <!-- Dozenten -->
      <form [formGroup]="lecturerForm" (ngSubmit)="onAddLecturer()">
        <div class="formular-row">
          <label class="formular-row-label" for="lecturer-email">
            <span class="label-title">Lehrpersonal einladen</span>
            <span class="label-description">
              Laden Sie Ihr Lehrpersonal ein
            </span>
          </label>
          <div class="formular-row-input-columns">
            <!-- Drag and Drop -->

            <app-upload-area-dnd
              [disabled]="true"
              matTooltip="Diese Funktion ist momentan noch nicht verfügbar"
              context="lecturer"
              inputId="lecturerUpload"
              [logs]="false"
              [showFileList]="true"
              [requiredFileTypes]="requiredFileTypes"
              (change)="changeExcel($event, 'lecturer')">
            </app-upload-area-dnd>

            <div class="formular-row-input">
              <span class="centered-text">
                Laden Sie die Excel Liste hoch oder fügen Sie manuell hinzu
              </span>
            </div>

            <div class="hasomed-input-field">
              <div class="hasomed-text-input-with-button">
                <input
                  id="lecturer-email"
                  type="email"
                  class="hasomed-text-input"
                  formControlName="email"
                  placeholder="lehrpersonal@email.de"
                  maxlength="255" />
                <button
                  type="submit"
                  class="hasomed-text-button hasomed-button-white-grey hover-primary hide-border-left"
                  [disabled]="
                    !lecturerForm.valid ||
                    lecturerForm.valid !== true ||
                    !lecturerForm.value.email
                  ">
                  <div class="hasomed-button-icon">
                    <i
                      class="fa-light fa-square-plus hasomed-button-icon-sm"></i>
                  </div>
                  <span class="hasomed-button-text">Hinzufügen</span>
                </button>
              </div>

              <!-- Error Messages -->
              <app-error-message
                [formField]="lecturerForm.get('email')"></app-error-message>
            </div>

            <!-- User -->
            <div class="formular-scroll-container" *ngIf="lecturers.length > 0">
              <div class="flex-r gap-16" *ngFor="let user of lecturers">
                <div class="card flex-1">
                  <div class="flex-r gap-8">
                    <span class="email hasomed-text-body">{{
                      user.email
                    }}</span>
                    <div
                      *ngIf="!user.id"
                      class="label-no-icon label-white-green">
                      <span class="label-text"> Neu </span>
                    </div>
                    <div
                      *ngIf="user.isRegistered"
                      class="label label-white-green">
                      <i class="fa-light fa-check label-icon-sm"></i>
                      <span class="label-text"> Registriert </span>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="button-icon-red"
                    matTooltip="Benutzer entfernen"
                    (click)="onRemoveLecturer(user)">
                    <i class="fa-light fa-circle-xmark"></i>
                  </button>
                </div>
                <button
                  class="hasomed-icon-button hasomed-button-white-grey"
                  type="button"
                  [disabled]="user.isRegistered || user.isInvited"
                  [matTooltip]="
                    user.isRegistered
                      ? 'Bereits registrierte Benutzer können nicht mehr eingeladen werden.'
                      : user.isInvited
                        ? 'Der Benutzer wurde bereits eingeladen.'
                        : 'User einladen'
                  "
                  (click)="onInviteUser(user)">
                  <i class="fa-light fa-paper-plane hasomed-button-icon-sm"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <mat-divider></mat-divider>

      <!-- Verwaltung -->
      <form [formGroup]="administratorForm" (ngSubmit)="onAddAdministration()">
        <div class="formular-row">
          <label class="formular-row-label" for="administration-email">
            <span class="label-title">Verwaltung einladen</span>
            <span class="label-description">
              Laden Sie Ihre Mitarbeiter in die Verwaltungsrolle ein
            </span>
          </label>
          <div class="formular-row-input-columns">
            <!-- Drag and Drop -->
            <app-upload-area-dnd
              [disabled]="true"
              matTooltip="Diese Funktion ist momentan noch nicht verfügbar"
              context="administrator"
              [logs]="false"
              [showFileList]="true"
              [requiredFileTypes]="requiredFileTypes"
              (change)="changeExcel($event, 'administrator')">
            </app-upload-area-dnd>

            <div class="formular-row-input">
              <span class="centered-text">
                Laden Sie die Excel Liste hoch oder fügen Sie manuell hinzu
              </span>
            </div>

            <div class="hasomed-input-field">
              <div class="hasomed-text-input-with-button">
                <input
                  id="administration-email"
                  type="email"
                  class="hasomed-text-input"
                  formControlName="email"
                  placeholder="verwaltung@email.de"
                  maxlength="255" />
                <button
                  type="submit"
                  class="hasomed-text-button hasomed-button-white-grey hover-primary hide-border-left"
                  [disabled]="
                    !administratorForm.valid ||
                    administratorForm.valid !== true ||
                    !administratorForm.value.email
                  ">
                  <div class="hasomed-button-icon">
                    <i
                      class="fa-light fa-square-plus hasomed-button-icon-sm"></i>
                  </div>
                  <span class="hasomed-button-text">Hinzufügen</span>
                </button>
              </div>
              <!-- Error Messages -->
              <app-error-message
                [formField]="
                  administratorForm.get('email')
                "></app-error-message>
            </div>

            <!-- User -->
            <div
              class="formular-scroll-container"
              *ngIf="administrators.length > 0">
              <div class="flex-r gap-8" *ngFor="let user of administrators">
                <div class="card flex-1">
                  <div class="flex-r gap-8">
                    <span class="email hasomed-text-body">{{
                      user.email
                    }}</span>
                    <div
                      *ngIf="!user.id"
                      class="label-no-icon label-white-green">
                      <span class="label-text"> Neu </span>
                    </div>
                    <div
                      *ngIf="user.isRegistered"
                      class="label label-white-green">
                      <i class="fa-light fa-check label-icon-sm"></i>
                      <span class="label-text"> Registriert </span>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="button-icon-red"
                    matTooltip="Benutzer entfernen"
                    (click)="onRemoveAdministrator(user)">
                    <i class="fa-light fa-circle-xmark"></i>
                  </button>
                </div>
                <button
                  class="hasomed-icon-button hasomed-button-white-grey"
                  type="button"
                  [disabled]="user.isRegistered || user.isInvited"
                  [matTooltip]="
                    user.isRegistered
                      ? 'Bereits registrierte Benutzer können nicht mehr eingeladen werden.'
                      : user.isInvited
                        ? 'Der Benutzer wurde bereits eingeladen.'
                        : 'User einladen'
                  "
                  (click)="onInviteUser(user)">
                  <i class="fa-light fa-paper-plane hasomed-button-icon-sm"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
