import { Room } from './room.model';
import { User } from './user.model';

export enum EventType {
  PATIENT_SESSION = 'Patient',
  COURSE = 'Course',
  EXAM = 'Exam',
  SUPERVISION = 'Supervision',
  GROUP_SUPERVISION = 'Group_supervision',
}

export enum TreatmentCaseEventType {
  PATIENT_SESSION = 'Patientensitzung',
  SUPERVISION = 'Supervision',
  GROUP_SUPERVISION = 'Gruppensupervision',
}

export interface EventDate {
  id?: number;
  startDate: Date;
  endDate?: Date;
  room?: Room;
}

export interface TreatmentCaseEventDate extends EventDate {
  type?: TreatmentCaseEventType;
  canceled?: boolean;
}

export interface InstituteEvent {
  name: string;
  type: EventType;
  currentRoom: Room;
  organizer: User;
  startDate: Date;
  endDate: Date;
  participants: number;
  numberOfLecturers: number;
  eventDates: EventDate[];
}
