import { Component, HostListener, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { Label } from 'src/app/models/label.model';
import { AlertService } from 'src/app/services/alert.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { LabelService } from 'src/app/services/label.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-create-edit-label-dialog',
  templateUrl: './create-edit-label-dialog.component.html',
  styleUrls: ['./create-edit-label-dialog.component.scss'],
})
export class CreateEditLabelDialogComponent {
  public label?: Label;

  public labelForm: FormGroup;
  public initialFormValues: {};
  public availableColors: string[] = [];
  public selectedColor: string;

  // impoert from form.utils.ts
  public isRequired = isRequired;

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.labelForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    public dialogRef: MatDialogRef<CreateEditLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { label?: Label },
    private dialog: MatDialog,
    private labelService: LabelService,
    private userService: UserService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService
  ) {
    this.label = data.label;

    // disable closing the dialog by clicking outside of it
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.availableColors = this.labelService.getAvailableColors();

    this.selectedColor = this.label
      ? this.label.color
      : this.availableColors[0];

    this.labelForm = new FormGroup({
      name: new FormControl(this.label?.name, [
        Validators.required,
        Validators.maxLength(50),
      ]),
      color: new FormControl(this.selectedColor),
    });

    this.initialFormValues = this.labelForm.value;
  }

  /**
   * onColorSelect
   * sets the selected color
   * @param color
   * @returns void
   */
  public onColorSelect(color: string): void {
    this.selectedColor = color;
    this.labelForm.patchValue({ color: color });
  }

  /**
   * onSave
   * Close the dialog, store the data in the database
   * @returns void
   */
  public onSave(): void {
    this.initialFormValues = this.labelForm.value;

    if (this.label) {
      // update label
      this.label.name = this.labelForm.get('name').value.trim();
      this.label.color = this.labelForm.get('color').value;

      this.labelService.updateLabel(this.label).subscribe({
        next: response => {
          console.debug('updateLabel Backend Response', response);
          if (!response.success) {
            if (response.message === 'label_exists') {
              this.alertService.showErrorAlert(
                'Fehler',
                `Etikett ${this.label.name} existiert bereits`
              );
              return;
            }

            this.alertService.showErrorAlert(
              'Fehler',
              'Fehler beim Bearbeiten des Etiketts'
            );
            console.error(response.message);
            this.dialogRef.close(false);
            return;
          }

          this.alertService.showSuccessAlert(
            'Etikett bearbeitet',
            `Etikett ${this.label.name} erfolgreich bearbeitet!`
          );
          this.dialogRef.close(true);
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Fehler',
            'Fehler beim Erstellen des Etiketts'
          );
          console.error('Error creating label:', error);
        },
      });
    } else {
      // create label
      const label: Label = {
        name: this.labelForm.get('name').value.trim(),
        color: this.labelForm.get('color').value,
        createdAt: new Date(),
        createdBy: this.userService.currentUser,
      };

      const id_institute = this.userService.currentUser.id_institute;
      this.labelService.createLabel(label, id_institute).subscribe({
        next: response => {
          console.debug('createLabel Backend Response', response);
          if (!response.success) {
            if (response.message === 'label_exists') {
              this.alertService.showErrorAlert(
                'Fehler',
                `Etikett ${label.name} existiert bereits`
              );
              return;
            }

            this.alertService.showErrorAlert(
              'Fehler',
              'Fehler beim Erstellen des Etiketts'
            );
            console.error(response.message);
            this.dialogRef.close(false);
            return;
          }

          this.alertService.showSuccessAlert(
            'Etikett erstellt',
            `Etikett ${label.name} erfolgreich erstellt!`
          );
          this.dialogRef.close(true);
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Fehler',
            'Fehler beim Erstellen des Etiketts'
          );
          console.error('Error creating label:', error);
        },
      });
    }
  }

  /**
   * onCancel
   * Close the dialog, return false
   * @returns void
   */
  onCancel(): void {
    // check if the form has unsaved changes
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.labelForm.value,
        this.initialFormValues
      )
    ) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
            Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          // Close the dialog, return false
          this.dialogRef.close(false);
        }
      });
    } else {
      // Close the dialog, return false
      this.dialogRef.close(false);
    }
  }
}
