<form
  class="component-content"
  [formGroup]="personalDataForm"
  (ngSubmit)="onSubmit()">
  <!-- HEADER  -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Persönliche Daten</h1>
      <h3 class="hasomed-text-h3">Füllen Sie Ihre Daten aus.</h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Anrede und Titel</span>
        <span class="label-description">Ihre Anrede und Titel</span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select flex-1">
            <mat-select
              class="hasomed-select"
              formControlName="genderTitle"
              placeholder="Anrede">
              <mat-option [value]="null"> Keine Angabe </mat-option>
              <mat-option value="Herr"> Herr </mat-option>
              <mat-option value="Frau"> Frau </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="hasomed-select flex-1">
            <mat-select
              class="hasomed-select"
              formControlName="academicTitle"
              placeholder="Titel">
              <mat-option [value]="null"> Kein Titel </mat-option>
              <mat-option value="Prof. Dr."> Prof. Dr. </mat-option>
              <mat-option value="Dr."> Dr. </mat-option>
              <mat-option value="M.Sc."> M.Sc. </mat-option>
              <mat-option value="M.A."> M.A. </mat-option>
              <mat-option value="Dipl.-Psych."> Dipl.-Psych. </mat-option>
              <mat-option value="Mag."> Mag. </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <app-error-message
          [formField]="personalDataForm.get('genderTitle')"></app-error-message>
        <app-error-message
          [formField]="
            personalDataForm.get('academicTitle')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Name
          <span
            *ngIf="
              isRequired(personalDataForm.get('firstname')) ||
              isRequired(personalDataForm.get('lastname'))
            ">
            *
          </span>
        </span>
        <span class="label-description">Ihr Vorname und Nachname</span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="firstname"
            class="hasomed-text-input"
            autocomplete="given-name"
            type="text"
            size="5"
            formControlName="firstname"
            placeholder="Vorname" />
          <input
            id="lastname"
            size="10"
            class="hasomed-text-input"
            autocomplete="family-name"
            type="text"
            formControlName="lastname"
            placeholder="Nachname" />
        </div>
        <app-error-message
          [formField]="personalDataForm.get('firstname')"></app-error-message>
        <app-error-message
          [formField]="personalDataForm.get('lastname')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Email -->
    <div class="formular-row">
      <label class="formular-row-label" for="email">
        <span class="label-title">E-Mail Adresse</span>
        <span class="label-description">Ihre E-Mail Adresse</span>
      </label>
      <div class="formular-row-input">
        <input
          id="email"
          class="hasomed-text-input"
          type="text"
          size="20"
          autocomplete="email"
          formControlName="email"
          placeholder="Email"
          readonly />
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Birthdate -->
    <div class="formular-row">
      <div class="formular-row-label">
        <div class="label-title">Geburtsdatum</div>
        <div class="label-description">Ihr Geburtsdatum</div>
      </div>
      <div class="formular-row-input">
        <mat-form-field class="hasomed-date-field hasomed-date-field-center">
          <input
            class="hasomed-date-input"
            maxlength="10"
            size="9"
            [max]="today"
            matInput
            [matDatepicker]="picker"
            placeholder="Datum wählen"
            formControlName="birthdate" />
          <mat-datepicker-toggle
            class="hasomed-date-toggle"
            matIconPrefix
            [for]="picker">
            <mat-icon
              matDatepickerToggleIcon
              class="calendar-toggle-icon fa-light fa-calendar">
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker
            startView="multi-year"
            [startAt]="datePickerStartDate"
            #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Student number -->
    <div class="formular-row">
      <div class="formular-row-label">
        <ng-container [ngSwitch]="user?.id_role">
          <span class="label-title" *ngSwitchCase="3">
            Ihre "Lehrpersonalnummer"
          </span>
          <span class="label-title" *ngSwitchCase="4">
            Ihre "Matrikelnummer"
          </span>
          <span class="label-title" *ngSwitchDefault>
            Ihre Personalnummer
          </span>
        </ng-container>
        <span class="label-description">Wird automatisch vergeben</span>
      </div>
      <div class="formular-row-input student-number">
        <i class="fa-light fa-lock"></i>
        <span>{{
          user?.userIdentifier != '' && user?.userIdentifier
            ? user?.userIdentifier
            : (user?.id_role === 4
                ? '"Matrikelnummer"'
                : user?.id_role === 3
                  ? '"Lehrpersonalnummer"'
                  : 'Personalnummer') + ' noch nicht definiert'
        }}</span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- entry date -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Eintrittsdatum</span>
        <span class="label-description">Ihr Eintrittsdatum</span>
      </div>
      <div class="formular-row-input entry-date">
        <i class="fa-light fa-lock"></i>
        <span>{{
          (user?.entryDate | date: 'dd.MM.yyyy') ??
            'Ihr Eintrittsdatum wurde noch nicht hinterlegt'
        }}</span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Avatar -->
    <div class="formular-row">
      <label class="formular-row-label" for="profilePictureUpload">
        <span class="label-title">Profilfoto</span>
        <span class="label-description">Wird in Ihrem Profil angezeigt</span>
      </label>
      <div class="formular-row-input-image-upload">
        <div
          class="uploaded-image-container"
          (click)="openProfilePictureDialog()">
          <img
            id="user-icon-image"
            class="uploaded-image"
            alt="Dein Profilbild"
            matTooltip="Dein Profilbild"
            #userIconImage
            [attr.src]="
              personalDataForm.get('profilePicture').value ??
              '../../../../../assets/img/user.jpg'
            " />
        </div>
        <app-upload-area-dnd
          class="image-upload-area"
          [context]="'profilePictureUpload'"
          inputId="profilePictureUpload"
          [logs]="true"
          [showFileList]="false"
          [requiredFileTypes]="requiredFileTypes"
          (change)="imageChangeEvent($event)">
        </app-upload-area-dnd>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Education Type -->
    <div class="formular-row" *ngIf="user?.id_role === 4">
      <div class="formular-row-label">
        <span class="label-title">Aus- oder Weiterbildungsgang</span>
        <span class="label-description">
          Ihr Aus- oder Weiterbildungsgang
        </span>
      </div>
      <div class="formular-row-input education-course">
        <i class="fa-light fa-lock"></i>
        <span>
          {{
            user?.educationCourse?.title
              ? user?.educationCourse?.title
              : 'Ihr Aus- oder Weiterbildungsgang wurde noch nicht hinterlegt'
          }}
        </span>
      </div>
    </div>
    <mat-divider *ngIf="user?.id_role === 4"></mat-divider>

    <!-- Measles Protection -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Masernstatus</span>
        <span class="label-description"> Impfschutz für Masern </span>
      </div>
      <div
        class="formular-row-input measles-protection"
        *ngIf="
          userService.currentUserIsStudent() ||
            userService.currentUserIsLecturer();
          else measlesToggle
        ">
        <i class="fa-light fa-lock"></i>
        <span>
          {{ user?.measlesProtection ? 'Geprüft' : 'Nicht geprüft' }}
        </span>
      </div>
      <ng-template #measlesToggle>
        <div class="formular-row-input measles-protection">
          <mat-slide-toggle
            hideIcon
            (change)="onMeaslesProtectionChanged($event)"
            [checked]="personalDataForm.get('measlesProtection').value">
          </mat-slide-toggle>
          <span class="hasomed-text-small">
            {{
              personalDataForm.get('measlesProtection').value
                ? 'Geprüft'
                : 'Nicht geprüft'
            }}
          </span>
        </div>
      </ng-template>
    </div>
    <mat-divider></mat-divider>

    <!-- Address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Adresse</span>
        <span class="label-description">
          Ihre Anschrift für Schriftverkehr
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="formular-row-input">
            <input
              id="street"
              class="hasomed-text-input"
              type="text"
              size="15"
              formControlName="street"
              placeholder="Straße" />
            <input
              id="houseNumber"
              class="hasomed-text-input"
              type="text"
              maxlength="5"
              size="5"
              formControlName="houseNumber"
              placeholder="Hausnummer" />
          </div>
          <div class="formular-row-input">
            <input
              id="addressAddition"
              class="hasomed-text-input"
              type="text"
              size="10"
              formControlName="addressAddition"
              placeholder="Adresszusatz (Etage, Appartment)" />
          </div>
          <div class="formular-row-input">
            <input
              id="zipCode"
              class="hasomed-text-input"
              type="text"
              size="5"
              formControlName="zipCode"
              placeholder="PLZ" />
            <input
              id="city"
              class="hasomed-text-input"
              type="text"
              size="15"
              formControlName="city"
              placeholder="Ort" />
          </div>
          <div class="formular-row-input">
            <input
              id="country"
              class="hasomed-text-input"
              type="text"
              size="15"
              autocomplete="country"
              formControlName="country"
              placeholder="Land" />
          </div>
        </div>
        <app-error-message
          [formField]="personalDataForm.get('street')"></app-error-message>
        <app-error-message
          [formField]="personalDataForm.get('houseNumber')"></app-error-message>
        <app-error-message
          [formField]="
            personalDataForm.get('addressAddition')
          "></app-error-message>
        <app-error-message
          [formField]="personalDataForm.get('zipCode')"></app-error-message>
        <app-error-message
          [formField]="personalDataForm.get('city')"></app-error-message>
        <app-error-message
          [formField]="personalDataForm.get('country')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Document -->
    <div class="formular-row">
      <label class="formular-row-label" for="profileDocuments">
        <span class="label-title">Dokumentenupload</span>
        <span class="label-description">
          Hier können Sie Dokumente für Ihre Aus- und Weiterbildung hochladen
        </span>
      </label>
      <div class="formular-row-input">
        <div class="flex-grow">
          <app-upload-area-dnd
            [context]="'profileDocumentUpload'"
            inputId="profileDocuments"
            [logs]="false"
            [existingFiles]="existingFiles"
            (deleteExistingFile)="onDeleteExistingFile($event)"
            formControlName="uploadedFiles"
            [showFileList]="true"
            [multiple]="true"
            [requiredFileTypes]="requiredFileTypesDocuments">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Account Settings -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Account-Einstellungen </span>
        <span class="label-description">
          Diverse Einstellungen für Ihren Account
        </span>
      </div>
      <div class="formular-row-input">
        <button
          type="button"
          class="hasomed-text-button hasomed-button-primary button-full-width"
          (click)="changePassword()"
          [disabled]="true"
          matTooltip="Diese Funktion ist momentan nicht verfügbar">
          <i class="fa-light fa-key hasomed-button-icon-sm"> </i>
          <span class="hasomed-button-text"> Passwort ändern </span>
        </button>
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white-red button-full-width"
          (click)="onDeleteAccount()">
          <i class="fa-light fa-trash-can hasomed-button-icon-sm"> </i>
          <span class="hasomed-button-text"> Account löschen </span>
        </button>
      </div>
    </div>
  </div>
</form>
