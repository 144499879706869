<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Kurse suchen</h1>
      <h3 class="hasomed-text-h3">Finde Sie Ihre nächsten Kurse</h3>
    </div>

    <form class="header-buttons" [formGroup]="searchForm">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <div class="hasomed-date-range">
        <mat-date-range-input
          class="hasomed-text-input hasomed-date-range-input"
          formGroupName="dateRange"
          [rangePicker]="picker">
          <input
            id="startDate"
            matStartDate
            placeholder="Startdatum"
            formControlName="start"
            readonly />
          <input
            id="endDate"
            matEndDate
            placeholder="Enddatum"
            formControlName="end"
            (dateChange)="applyDateRange()"
            readonly />
        </mat-date-range-input>
        <mat-datepicker-toggle
          class="hasomed-date-range-toggle"
          matIconSuffix
          [for]="picker">
          <mat-icon
            matDatepickerToggleIcon
            class="calendar-toggle-icon fa-light fa-calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          [restoreFocus]="false"></mat-date-range-picker>
      </div>
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        [ngClass]="{
          'hasomed-button-primary': hasActiveFilterValue(courseFilter, true),
          'hasomed-button-white-grey': !hasActiveFilterValue(
            courseFilter,
            true
          ),
          'hasomed-button-active': filterOpened
        }"
        [matMenuTriggerFor]="filterMenu"
        (onMenuOpen)="filterOpened = true"
        (onMenuClose)="filterOpened = false">
        <i class="fa-light fa-filter-list hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Filter</span>
      </button>
    </form>
    <!-- Filter Menu -->
    <mat-menu class="filter-overlay" #filterMenu="matMenu">
      <app-filter
        *ngIf="filterOpened && !isLoading"
        [filters]="courseFilter"
        (filterChanged)="courseFilterChanged($event)"
        [courses]="courses">
      </app-filter>
    </mat-menu>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>
  <div *ngIf="!isLoading" class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="courseFilter"
      (filterChanged)="courseFilterChanged($event)">
    </app-filter-tags>
    <div
      *ngFor="
        let course of courses
          | filterCourse: courseFilter
          | searchFilter: searchForm.value.searchText : 'title'
      "
      id="course-{{ course.id }}"
      class="flex-c course">
      <div class="flex-r course-summary">
        <div class="flex-r course-summary-details">
          <div class="flex-r image-container">
            <img
              [src]="course.titlePicture"
              class="course-image"
              alt="Titelbild" />
          </div>

          <div class="flex-c gap-16 justify-space-between w-full">
            <h1
              class="hasomed-text-h1"
              [matTooltip]="course.title?.length > 150 ? course.title : ''">
              {{ course.title | slice: 0 : 150
              }}{{ course.title?.length > 150 ? '...' : '' }}
            </h1>
            <div class="flex-r justify-space-between align-center gray-600">
              <div>
                <span> {{ getFullLecturerNames(course) }}, </span>
                <span class="m-z gray-600">
                  {{ course.startDate | date: 'dd.MM.yyyy' }} -
                  {{ course.endDate | date: 'dd.MM.yyyy' }},
                  {{ course.startDate | date: 'HH:mm' }} -
                  {{ course.endDate | date: 'HH:mm' }} Uhr
                </span>
              </div>
              <app-elog-cell
                class="flex-r justify-end"
                *ngIf="course?.registered"
                [eLogStatusCounts]="course.eLogStatusCounts"
                [shortText]="false"></app-elog-cell>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-r gap-16 justify-space-between">
              <div class="flex-r gap-24">
                <div class="flex-r align-center gap-8">
                  <i class="fa-light fa-book hasomed-button-icon-sm"></i>
                  <span class="hasomed-text-body gray-700">
                    {{ getEducationCourseTitles(course) }}
                  </span>
                </div>
                <div *ngIf="course.closed" class="flex-r align-center gap-8">
                  <i
                    class="fa-light fa-lock-keyhole hasomed-button-icon-sm"></i>
                  <span class="hasomed-text-body gray-700"> geschlossen </span>
                </div>
                <div *ngIf="!course.closed" class="flex-r align-center gap-8">
                  <i
                    class="fa-light fa-lock-keyhole-open hasomed-button-icon-sm"></i>
                  <span class="hasomed-text-body gray-700"> offen </span>
                </div>
                <div class="flex-r align-center gap-8">
                  <i class="fa-light fa-map-pin hasomed-button-icon-sm"></i>
                  <span class="hasomed-text-body gray-700">
                    {{ getCourseRoom(course) }}
                  </span>
                </div>
                <div class="flex-r align-center gap-8">
                  <i class="fa-light fa-user-pen hasomed-button-icon-sm"></i>
                  <span class="hasomed-text-body gray-700">
                    {{ course.registeredStudents ?? 0 }} von
                    {{ course.maxStudents }}
                    angemeldet
                  </span>
                </div>
              </div>
              <div class="flex-r gap-16">
                <button
                  *ngIf="course.registered"
                  class="hasomed-text-button hasomed-button-white-grey"
                  (click)="onCourseStudentChange(course)"
                  [disabled]="course.closed || course.finished"
                  [matTooltip]="
                    course.closed
                      ? 'Abmeldung von einem geschlossenen Kurs nicht möglich.'
                      : course.finished
                        ? 'Der Kurs ist bereits abgeschlossen.'
                        : ''
                  ">
                  <i
                    class="hasomed-button-icon-sm fa-light fa-right-to-bracket">
                  </i>
                  <span class="hasomed-button-text"> Abmelden </span>
                </button>
                <button
                  *ngIf="!course.registered"
                  class="hasomed-text-button hasomed-button-primary"
                  (click)="onCourseStudentChange(course)"
                  [disabled]="
                    course.closed ||
                    course.registeredStudents >= course.maxStudents ||
                    course.finished
                  "
                  [matTooltip]="
                    course.closed
                      ? 'Anmeldung an einem geschlossenen Kurs nicht möglich.'
                      : course.registeredStudents >= course.maxStudents
                        ? 'Der Kurs ist bereits belegt.'
                        : course.finished
                          ? 'Der Kurs ist bereits abgeschlossen.'
                          : ''
                  ">
                  <i
                    class="hasomed-button-icon-sm fa-light fa-right-to-bracket">
                  </i>
                  <span class="hasomed-button-text"> Anmelden </span>
                </button>
                <button
                  class="hasomed-icon-button hasomed-button-white-grey"
                  (click)="showDetails(course)"
                  matTooltip="Details anzeigen">
                  <i
                    class="hasomed-button-icon fa-light fa-arrow-up-right-from-square">
                  </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hasomed-tex-body" *ngIf="!courses || courses?.length === 0">
      Es wurden keine Kurse gefunden!
    </div>
    <div
      *ngIf="
        searchForm.value.searchText !== '' &&
        !isLoading &&
        (
          courses
          | filterCourse: courseFilter
          | searchFilter: searchForm.value.searchText : 'title'
        )?.length === 0
      "
      class="hasomed-tex-body">
      Es wurde kein Kurs gefunden.
    </div>
  </div>
</div>
