import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-practical-work',
  templateUrl: './practical-work.component.html',
  styleUrls: ['./practical-work.component.scss'],
})
export class PracticalWorkComponent implements OnInit {
  public searchForm: FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.searchForm = new FormGroup({
      searchText: new FormControl(''),
    });
  }

  public createPracticalWork() {
    console.log('createPracticalWork');
    this.router.navigate(['./create'], { relativeTo: this.route });
  }
}
