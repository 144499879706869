import { Component } from '@angular/core';

@Component({
  selector: 'app-education-progress',
  templateUrl: './education-progress.component.html',
  styleUrls: ['./education-progress.component.scss']
})
export class EducationProgressComponent {

}
