import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { Observable, takeUntil } from 'rxjs';
import { environment } from 'src/configs/environment';
import { BankDetails } from '../models/bank-details.model';
import { DecryptionService } from './decryption.service';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class BankDetailsService {
  constructor(
    private http: HttpClient,
    private cryptoService: CryptoService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  /**
   *
   * getUserBankDetails
   * @param id_user
   * @returns
   */
  public getUserBankDetails(id_user: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'user/getUserBankDetails.php?id_user=' +
          id_user.toString()
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   *
   * updateBankDetails
   * @param id_user
   * @param accountHolder
   * @param iban
   * @param bic
   * @returns
   */
  public async updateBankDetails(
    id_user: number,
    accountHolder: string,
    iban: string,
    bic: string
  ): Promise<Observable<any>> {
    const formdata = new FormData();
    formdata.append('id_user', id_user.toString());
    accountHolder &&
      formdata.append(
        'accountHolder',
        await this.cryptoService.encrypt(accountHolder)
      );
    iban &&
      formdata.append(
        'iban',
        await this.cryptoService.encrypt(iban.replace(/\s/g, ''))
      );
    bic && formdata.append('bic', await this.cryptoService.encrypt(bic));

    return this.http.post(
      environment.authority_short + 'user/updateUserBankDetails.php',
      formdata
    );
  }

  /**
   *
   * mask iban with asterisks
   * @param input
   * @returns
   */
  public maskIban(input: string): string {
    const replacement = '*';
    const start = 4;
    const end = 20;

    if (!input) {
      return input;
    }

    if (start >= end || start < 0 || end > input.length) {
      return input; // Handle invalid input
    }

    const prefix = input.substring(0, start);
    const suffix = input.substring(end);
    const asterisks = replacement.repeat(end - start);

    return prefix + asterisks + suffix;
  }

  public prettyPrintIban(input: string): string {
    const whitespace = ' ';
    const interval = 4;

    if (!input) {
      return input;
    }

    let result = '';
    let i = 0;
    while (i < input.length) {
      if (i + interval < input.length) {
        result += input.substring(i, i + interval) + whitespace;
      } else {
        result += input.substring(i);
      }
      i += interval;
    }

    return result;
  }

  public async parseBackendBankDetails(data: any): Promise<BankDetails> {
    if (!data) {
      return data;
    }

    const bankDetails: BankDetails = {
      accountHolder: data.accountHolder
        ? await this.decryptionService.decryptString(data.accountHolder)
        : null,
      iban: data.iban
        ? this.prettyPrintIban(
            await this.decryptionService.decryptString(data.iban)
          )
        : null,
      maskedIban: data.iban
        ? this.prettyPrintIban(
            this.maskIban(await this.decryptionService.decryptString(data.iban))
          )
        : null,
      bic: data.bic
        ? await this.decryptionService.decryptString(data.bic)
        : null,
    };

    return bankDetails;
  }
}
