import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, takeUntil } from 'rxjs';
import { environment } from 'src/configs/environment';
import {
  CreateFileModel,
  CustomFileType,
  UpdateFileModel,
} from '../models/custom-file-type.model';
import { User } from '../models/user.model';
import { DecryptionService } from './decryption.service';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private http: HttpClient,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  /**
   * downloadFile
   * get file from backend and download it
   * @param id_file
   * @returns
   */
  public downloadFile(id_file: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short + `file/downloadFile.php?id_file=${id_file}`
      )
      .pipe(
        switchMap(async (response: any) => {
          const parsedFile = response.data
            ? await this.parseBackendFile(response.data)
            : null;
          if (!parsedFile) {
            throw new Error('No file data');
          }
          const [header, base64Data] = parsedFile.file.split(',');
          const mimeString = header.split(':')[1].split(';')[0];
          const byteString = atob(base64Data);
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const intArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([arrayBuffer], { type: mimeString });

          // Trigger download
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = parsedFile.filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      );
  }

  /**
   * getAllInstituteFiles
   *  Get all files for an institute based on the users role
   * @param id_institute
   * @param id_role
   * @returns
   */
  public getAllInstituteFiles(
    id_institute: number,
    id_role: number,
    id_user: number
  ): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `file/getAllInstituteFiles.php?id_institute=${id_institute}&id_role=${id_role}&id_user=${id_user}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getInstituteFile
   * get file from backend
   * @param id_file
   * @returns
   */
  public getInstituteFile(
    id_file: number,
    id_institute: number
  ): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `file/getInstituteFile.php?id_file=${id_file}&id_institute=${id_institute}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createFile
   * upload file with permissions to backend
   * @param file
   * @returns
   */
  public createFile(createFileData: CreateFileModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.authority_short + 'file/createInstituteFile.php',
      createFileData,
      { headers: headers }
    );
  }

  /**
   * updateInstituteFile
   * @param updateFileData
   * @returns
   */
  public updateInstituteFile(updateFileData: UpdateFileModel): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.authority_short + `file/updateInstituteFile.php`,
      updateFileData,
      { headers: headers }
    );
  }

  /**
   * openFile
   * get file from backend and open it in a new tab
   * @param id_file
   * @returns
   */
  public openFile(id_file: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short + `file/downloadFile.php?id_file=${id_file}`
      )
      .pipe(
        switchMap(async (response: any) => {
          const parsedFile = response.data
            ? await this.parseBackendFile(response.data)
            : null;
          if (!parsedFile) {
            throw new Error('No file data');
          }
          const [header, base64Data] = parsedFile.file.split(',');
          const mimeString = header.split(':')[1].split(';')[0];
          const byteString = atob(base64Data);
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const intArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([arrayBuffer], { type: mimeString });

          const url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
          return true;
        })
      );
  }

  /**
   * deleteFile
   * delete file from backend
   * @param id_file
   * @returns
   */
  public deleteFile(id_file: number): Observable<any> {
    return this.http.get(
      environment.authority_short + `file/deleteFile.php?id_file=${id_file}`
    );
  }

  /**
   * parseBackendFile
   * parse file data from backend
   * @param file
   * @returns
   */
  public async parseBackendFile(file: any): Promise<CustomFileType> {
    const creator: User = {
      id: file.creator?.id,
      name: {
        firstname:
          file.creator?.name?.firstname &&
          (await this.decryptionService.decryptString(
            file.creator.name.firstname
          )),
        lastname:
          file.creator?.name?.lastname &&
          (await this.decryptionService.decryptString(
            file.creator.name.lastname
          )),
      },
    };

    return {
      id: file.id,
      filename: await this.decryptionService.decryptString(file.filename),
      file: await this.decryptionService.decryptString(file.file),
      fileSize: file.fileSize,
      creator: creator,
      timeCreated: file.timeCreated,
      timeModified: file.timeModified,
    };
  }
}
