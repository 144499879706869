import { Injectable } from '@angular/core';
import { EducationCourse } from '../models/education-course.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/configs/environment';
import { Observable, takeUntil } from 'rxjs';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { DecryptionService } from './decryption.service';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class EducationCourseService {
  constructor(
    private http: HttpClient,
    private cryptoService: CryptoService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getInstituteEducationCourses
   * get education courses of institute
   * @param id_institute id of the institute
   * @returns Observable
   */
  public getInstituteEducationCourses(id_institute: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'educationCourse/getInstituteEducationCourses.php?id_institute=' +
          id_institute
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getEducationCourseByID
   * get education course by id
   * @param id id of the educationCourse
   * @returns Observable
   */
  public getEducationCourseByID(id_educationCourse: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'educationCourse/getEducationCourse.php?id_education_course=' +
          id_educationCourse
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * newEducationCourse
   * create a new educationCourse
   * @param educationCourse educationCourse to create
   * @returns Observable
   */
  public async createEducationCourse(
    educationCourse: EducationCourse
  ): Promise<Observable<any>> {
    console.debug('educationCourse create: ', educationCourse);

    educationCourse.internal_notes =
      educationCourse.internal_notes &&
      (await this.cryptoService.encrypt(educationCourse.internal_notes));

    educationCourse.external_notes =
      educationCourse.external_notes &&
      (await this.cryptoService.encrypt(educationCourse.external_notes));

    if (educationCourse.internal_notes === '') {
      educationCourse.internal_notes = null;
    }
    if (educationCourse.external_notes === '') {
      educationCourse.external_notes = null;
    }

    return this.http.post(
      environment.authority_short + 'educationCourse/createEducationCourse.php',
      educationCourse
    );
  }

  /**
   * updateEducationCourse
   * update educationCourse
   * @param id_educationCourse id of the educationCourse
   * @param educationCourse educationCourse to update
   * @returns Observable
   */
  public async updateEducationCourse(
    id_educationCourse: number,
    educationCourse: EducationCourse
  ): Promise<Observable<any>> {
    educationCourse.id = id_educationCourse;
    console.debug('educationCourse update: ', educationCourse);

    educationCourse.internal_notes =
      educationCourse.internal_notes &&
      (await this.cryptoService.encrypt(educationCourse.internal_notes));

    educationCourse.external_notes =
      educationCourse.external_notes &&
      (await this.cryptoService.encrypt(educationCourse.external_notes));

    if (educationCourse.internal_notes === '') {
      educationCourse.internal_notes = null;
    }

    if (educationCourse.external_notes === '') {
      educationCourse.external_notes = null;
    }

    return this.http.post(
      environment.authority_short + 'educationCourse/updateEducationCourse.php',
      educationCourse
    );
  }

  /**
   * deleteEducationCourse
   * delete educationCourse
   * @param id_educationCourse id of the educationCourse
   * @returns Observable
   */
  public deleteEducationCourse(id_educationCourse: number): Observable<any> {
    const formData = new FormData();
    formData.append('id_education_course', id_educationCourse.toString());

    return this.http.post(
      environment.authority_short + 'educationCourse/deleteEducationCourse.php',
      formData
    );
  }

  /**
   * parseBackendEducationCourse
   */
  public async parseBackendEducationCourse(
    educationCourse: any
  ): Promise<EducationCourse> {
    const parsedEducationCourse: EducationCourse = {
      id: educationCourse.id,
      id_institute: educationCourse.id_institute,
      regulation: educationCourse.regulation,
      title: educationCourse.title,
      expertise: educationCourse.expertise,
      policy_procedure: educationCourse.policy_procedure,
      professional_association: educationCourse.professional_association,
      accreditation_year: educationCourse.accreditation_year,
      chamber: educationCourse.chamber,
      internal_notes: await this.decryptionService.decryptString(
        educationCourse.internal_notes
      ),
      external_notes: await this.decryptionService.decryptString(
        educationCourse.external_notes
      ),
      c_theoretical_scientific_h: educationCourse.c_theoretical_scientific_h,
      c_theoretical_scientific_auto_training_h:
        educationCourse.c_theoretical_scientific_auto_training_h,
      c_theoretical_scientific_balintgroupwork_h:
        educationCourse.c_theoretical_scientific_balintgroupwork_h,
      c_practical_1_h: educationCourse.c_practical_1_h,
      c_practical_1_medical_history_survey_min:
        educationCourse.c_practical_1_medical_history_survey_min,
      c_practical_2_h: educationCourse.c_practical_2_h,
      c_treatment_internship_h: educationCourse.c_treatment_internship_h,
      c_completed_treatment_cases: educationCourse.c_completed_treatment_cases,
      c_treatment_cases: educationCourse.c_treatment_cases,
      c_treatment_cases_min: educationCourse.c_treatment_cases_min,
      c_treatment_cases_min_h: educationCourse.c_treatment_cases_min_h,
      c_supervision_h: educationCourse.c_supervision_h,
      c_supervision_single_session_h:
        educationCourse.c_supervision_single_session_h,
      c_supervision_group_session_h:
        educationCourse.c_supervision_group_session_h,
      c_supervision_medical_history_survey_min:
        educationCourse.c_supervision_medical_history_survey_min,
      c_self_experience_h: educationCourse.c_self_experience_h,
      c_self_experience_sessions_min:
        educationCourse.c_self_experience_sessions_min,
    };

    return parsedEducationCourse;
  }
}
