import { User } from './user.model';

export enum eLogStatus {
  UPCOMING = 'upcoming',
  CHECKED = 'checked',
  ABSENT = 'absent',
  PENDING = 'pending',
  EXCUSED = 'excused',
  UNEXCUSED = 'unexcused',
}

export interface eLog {
  id: number;
  student: User;
  eLogStatus: eLogStatus;
}

export interface eLogStatusCounts {
  pending: number;
  checked: number;
  absent: number;
  excused: number;
  unexcused: number;
  upcoming?: number;
}
