import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { Course } from 'src/app/models/course.model';
import { CustomFileType } from 'src/app/models/custom-file-type.model';
import { Role } from 'src/app/models/permission.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { FileService } from 'src/app/services/file.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-course-documents',
  templateUrl: './course-documents.component.html',
  styleUrls: ['./course-documents.component.scss'],
})
export class CourseDocumentsComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  public columnsToDisplay = [
    'file',
    'creator',
    'modifiedDate',
    'fileSize',
    'fileType',
    'actions',
  ];
  public isLoading = true;
  public course: Course;
  public uploadedFiles: any[] = [];
  public isAdministrator: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private fileService: FileService,
    private userService: UserService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    const id_course = this.activatedRoute.snapshot.paramMap.get('id')
      ? +atob(this.activatedRoute.snapshot.paramMap.get('id'))
      : +atob(this.activatedRoute.parent?.snapshot.paramMap.get('id'));

    this.isAdministrator =
      this.userService.currentUser?.id_role === Role.ADMINISTRATOR;

    this.getCourseFiles(id_course);
  }

  private getCourseFiles(id_course: number) {
    this.courseService
      .getAllCourseFiles(id_course)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('course files Backend response', response);
          if (!response.success) {
            console.error('Course files could not be loaded');
            this.isLoading = false;
            return;
          }

          const files = response.data
            ? await Promise.all(
                response.data.map(
                  async (file: any): Promise<CustomFileType> =>
                    await this.fileService.parseBackendFile(file)
                )
              )
            : [];

          files?.forEach(file => {
            file.fileType = file.filename.split('.').pop();
          });

          this.dataSource.data = files;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'file':
                return item.filename;
              case 'creator':
                return item.name.firstname + ' ' + item.name.lastname;
              default:
                return item[property];
            }
          };
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
        },
        error: error => {
          console.error('Course files could not be loaded', error);
          this.isLoading = false;
        },
      });
  }

  public onOpen(file: any) {
    this.fileService.openFile(file.id).subscribe({
      next: () => {
        console.debug('download successfull');
      },
      error: error => {
        console.error('error', error);
      },
    });
  }

  public onDownload(file: any) {
    this.fileService.downloadFile(file.id).subscribe({
      next: () => {
        console.debug('download successfull');
      },
      error: error => {
        console.error('error', error);
      },
    });
  }

  public onDelete(file: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Datei löschen',
        message: `Möchten Sie die Datei "${file.filename}" wirklich löschen?`,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (!dialogResult) {
        return;
      }
      this.fileService.deleteFile(file.id).subscribe({
        next: response => {
          console.debug('deleteFile backend response', response);
          if (!response.success) {
            console.error('delete failed');
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt! ',
              'Die Datei konnte nicht gelöscht werden.'
            );
            return;
          }
          this.alertService.showSuccessAlert(
            'Das hat geklappt! ',
            'Die Datei wurde erfolgreich gelöscht.'
          );
          this.dataSource.data = this.dataSource.data.filter(
            item => item.id !== file.id
          );
        },
        error: error => {
          console.error('error', error);
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt! ',
            'Die Datei konnte nicht gelöscht werden.'
          );
        },
      });
    });
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  public formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
