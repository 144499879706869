import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UserService } from './user.service';
import { NewsArticle } from '../models/news-article.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/configs/environment';
import * as moment from 'moment';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { DecryptionService } from './decryption.service';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class NewsArticleService {
  public newsArticlesVisited = new Subject<number>();
  public newNewsArticlesCount = new Subject<number>();

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private cryptoService: CryptoService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getAllNewsArticles
   * get all news articles from institute
   * @returns Observable<any>
   */
  getAllNewsArticles(): Observable<any> {
    const id_institute = this.userService.currentUser.id_institute;
    const id_user = this.userService.currentUser.id;
    return this.http
      .get(
        environment.authority_short +
          `instituteNews/getAllInstitituteNews.php?id_user=${id_user}&id_institute=${id_institute}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getNewNewsArticleCount
   * get the amount of articles that the current user has not read yet
   * @returns Observable<any>
   */
  getNewNewsArticleCount(): Observable<any> {
    const id_institute = this.userService.currentUser.id_institute;
    const id_user = this.userService.currentUser.id;
    return this.http
      .get(
        environment.authority_short +
          `instituteNews/getNewNewsArticleCount.php?id_user=${id_user}&id_institute=${id_institute}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getAllNewsTypes
   * get all news types
   * @returns Observable<any>
   */
  getAllNewsTypes(): Observable<any> {
    return this.http
      .get(environment.authority_short + `instituteNews/getAllNewsTypes.php`)
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getNewsArticleById
   * get news article by id
   * @param id_newsArticle
   * @returns Observable<any>
   */
  getNewsArticleById(id_newsArticle: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `instituteNews/getNewsArticle.php?&id=${id_newsArticle}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createNewsArticle
   * @param newsArticle
   * @returns Promise<Observable<any>>
   */
  public async createNewsArticle(newsArticle: any): Promise<Observable<any>> {
    const formData = new FormData();
    newsArticle.title &&
      formData.append(
        'title',
        await this.cryptoService.encrypt(newsArticle.title)
      );
    newsArticle.link &&
      formData.append(
        'link',
        await this.cryptoService.encrypt(newsArticle.link)
      );
    newsArticle.picture &&
      formData.append(
        'picture',
        await this.cryptoService.encrypt(newsArticle.picture)
      );
    newsArticle.text &&
      formData.append(
        'text',
        await this.cryptoService.encrypt(newsArticle.text)
      );
    newsArticle.type && formData.append('id_news_type', newsArticle.type);
    this.userService.currentUser &&
      formData.append(
        'id_created_by',
        this.userService.currentUser.id.toString()
      );
    this.userService.currentUser &&
      formData.append(
        'id_institute',
        this.userService.currentUser.id_institute.toString()
      );
    newsArticle.published &&
      formData.append('published', newsArticle.published.toString());

    return this.http.post(
      environment.authority_short + `instituteNews/createNewsArticle.php`,
      formData
    );
  }

  /**
   * updateNewsArticle
   * @param newsArticle
   * @returns Promise<Observable<any>>
   */
  public async updateNewsArticle(newsArticle: any): Promise<Observable<any>> {
    const formData = new FormData();
    formData.append('id', newsArticle.id.toString());
    newsArticle.title &&
      formData.append(
        'title',
        await this.cryptoService.encrypt(newsArticle.title)
      );
    newsArticle.link &&
      formData.append(
        'link',
        await this.cryptoService.encrypt(newsArticle.link)
      );
    newsArticle.picture &&
      formData.append(
        'picture',
        await this.cryptoService.encrypt(newsArticle.picture)
      );
    newsArticle.text &&
      formData.append(
        'text',
        await this.cryptoService.encrypt(newsArticle.text)
      );
    formData.append(
      'id_news_type',
      newsArticle.type.id ? newsArticle.type.id.toString() : newsArticle.type
    );
    formData.append('published', newsArticle.published.toString());

    return this.http.post(
      environment.authority_short + `instituteNews/updateNewsArticle.php`,
      formData
    );
  }

  /* Delete an existing article */
  deleteNewsArticle(articleId: number): Observable<any> {
    const formData = new FormData();
    formData.append('id', articleId.toString());

    return this.http.post(
      environment.authority_short + `instituteNews/deleteNewsArticle.php`,
      formData
    );
  }

  /**
   * parseBackendNewsArticle
   * @param newsArticle
   * @returns NewsArticle
   */
  public async parseBackendNewsArticle(newsArticle: any): Promise<NewsArticle> {
    const parsedNewsArticle: NewsArticle = {
      id: newsArticle.id,
      title:
        newsArticle.title &&
        (await this.decryptionService.decryptString(newsArticle.title)),
      link:
        newsArticle.link &&
        (await this.decryptionService.decryptString(newsArticle.link)),
      picture:
        newsArticle.picture &&
        (await this.decryptionService.decryptString(newsArticle.picture)),
      text:
        newsArticle.text &&
        (await this.decryptionService.decryptString(newsArticle.text)),
      type: newsArticle.newsType,
      createdBy:
        newsArticle.createdBy &&
        (await this.userService.parseBackendUser(newsArticle.createdBy)),
      createdDate: moment(
        newsArticle.createdDate,
        'YYYY-MM-DD HH:mm:ss'
      ).toDate(),
      modifiedDate:
        newsArticle.modifiedDate &&
        moment(newsArticle.modifiedDate, 'YYYY-MM-DD HH:mm:ss').toDate(),
      publishedDate:
        newsArticle.publishedDate &&
        moment(newsArticle.publishedDate, 'YYYY-MM-DD HH:mm:ss').toDate(),
      published: Boolean(newsArticle.published),
    };
    return parsedNewsArticle;
  }
}
