import { Injectable } from '@angular/core';
import { Room } from '../models/room.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/configs/environment';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { isNotNullOrUndefined } from '../utils/utils';
import { DecryptionService } from './decryption.service';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  constructor(
    private http: HttpClient,
    private cryptoService: CryptoService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getAllRooms
   * Get all rooms of an institute
   * @returns Observable
   */
  public getAllRooms(institute_id: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'room/getAllRooms.php?id_institute=' +
          institute_id
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getRoomById
   * Get room by id
   * @param roomId
   * @returns Observable
   */
  public getRoomById(roomId: number): Observable<any> {
    return this.http
      .get(environment.authority_short + 'room/getRoom.php?id_room=' + roomId)
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createRoom
   * Creates a new room
   * @param room
   * @returns Observable
   */
  public async createRoom(room: Room): Promise<Observable<any>> {
    const formData = new FormData();
    formData.append('name', await this.cryptoService.encrypt(room.name));
    room.address.street &&
      formData.append(
        'street',
        await this.cryptoService.encrypt(room.address.street)
      );
    room.address.houseNumber &&
      formData.append(
        'house_number',
        await this.cryptoService.encrypt(room.address.houseNumber)
      );
    room.address.addressAddition &&
      formData.append(
        'address_addition',
        await this.cryptoService.encrypt(room.address.addressAddition)
      );
    room.address.zipCode &&
      formData.append(
        'zip_code',
        await this.cryptoService.encrypt(room.address.zipCode)
      );
    room.address.city &&
      formData.append(
        'city',
        await this.cryptoService.encrypt(room.address.city)
      );
    room.address.country &&
      formData.append(
        'country',
        await this.cryptoService.encrypt(room.address.country)
      );

    isNotNullOrUndefined(room.floor) &&
      formData.append('floor', String(room.floor));
    room.building && formData.append('building', room.building);
    isNotNullOrUndefined(room.seatNumber) &&
      formData.append('seat_number', String(room.seatNumber));
    room.equipment && formData.append('equipment', room.equipment);
    room.responsible && formData.append('responsible', room.responsible);
    formData.append('available', String(room.available));
    formData.append('id_institute', String(room.id_institute));

    return this.http.post(
      environment.authority_short + 'room/createRoom.php',
      formData
    );
  }

  /**
   * Updates a room object or the available state of a room
   * @param id_room id of the room
   * @param room room object
   * @param mode mode of the update
   * @returns Observable
   */
  public async updateRoom(
    id_room: number,
    room: Room,
    mode: string
  ): Promise<Observable<any>> {
    const formData = new FormData();
    formData.append('mode', mode);
    formData.append('id_room', String(id_room));
    formData.append('id_institute', String(room.id_institute));

    console.debug('updateRoom room', room);

    if (mode === 'updateRoom') {
      formData.append('name', await this.cryptoService.encrypt(room.name));
      room.address.street &&
        formData.append(
          'street',
          await this.cryptoService.encrypt(room.address.street)
        );
      room.address.houseNumber &&
        formData.append(
          'house_number',
          await this.cryptoService.encrypt(room.address.houseNumber)
        );
      room.address.addressAddition &&
        formData.append(
          'address_addition',
          await this.cryptoService.encrypt(room.address.addressAddition)
        );
      room.address.zipCode &&
        formData.append(
          'zip_code',
          await this.cryptoService.encrypt(room.address.zipCode)
        );
      room.address.city &&
        formData.append(
          'city',
          await this.cryptoService.encrypt(room.address.city)
        );
      room.address.country &&
        formData.append(
          'country',
          await this.cryptoService.encrypt(room.address.country)
        );
      isNotNullOrUndefined(room.floor) &&
        formData.append('floor', String(room.floor));
      room.building && formData.append('building', room.building);
      isNotNullOrUndefined(room.seatNumber) &&
        formData.append('seat_number', String(room.seatNumber));
      room.equipment && formData.append('equipment', room.equipment);
      room.responsible && formData.append('responsible', room.responsible);
      formData.append('available', String(room.available));
    } else if (mode === 'updateAvailableState') {
      formData.append('available', String(room.available));
    }

    console.debug('updateRoom formData floor', formData.get('floor'));

    return this.http.post(
      environment.authority_short + 'room/updateRoom.php',
      formData
    );
  }

  /**
   * deleteRoom
   * Deletes a room
   * @param id_room id of the room
   * @returns Observable
   */
  public deleteRoom(id_room: number): Observable<any> {
    const formData = new FormData();
    formData.append('id_room', String(id_room));

    return this.http.post(
      environment.authority_short + 'room/deleteRoom.php',
      formData
    );
  }

  /**
   * parseBackendRoom
   * Parses the backend room data to a frontend room object, decrypts the encrypted data
   * @param room backend room data
   * @returns Promise<Room>
   */
  public async parseBackendRoom(room: any): Promise<Room> {
    try {
      const parsedRoom: Room = {
        id: room.id,
        name:
          room.name && (await this.decryptionService.decryptString(room.name)),
        address: {
          street:
            room.address?.street &&
            (await this.decryptionService.decryptString(room.address.street)),
          houseNumber:
            room.address?.houseNumber &&
            (await this.decryptionService.decryptString(
              room.address.houseNumber
            )),
          addressAddition:
            room.address?.addressAddition &&
            (await this.decryptionService.decryptString(
              room.address.addressAddition
            )),
          zipCode:
            room.address?.zipCode &&
            (await this.decryptionService.decryptString(room.address.zipCode)),
          city:
            room.address?.city &&
            (await this.decryptionService.decryptString(room.address.city)),
          country:
            room.address?.country &&
            (await this.decryptionService.decryptString(room.address.country)),
        },
        floor: room.floor,
        building: room.building,
        seatNumber: room.seatNumber,
        equipment: room.equipment,
        responsible: room.responsible,
        available: room.available === 1,
        id_institute: room.id_institute,
      };

      return parsedRoom;
    } catch (error) {
      const parsedRoom: Room = {
        id: room.id,
        name: room.name,
        address: {
          street: room.address?.street,
          houseNumber: room.address?.houseNumber,
          addressAddition: room.address?.addressAddition,
          zipCode: room.address?.zipCode,
          city: room.address?.city,
          country: room.address?.country,
        },
        floor: room.floor,
        building: room.building,
        seatNumber: room.seatNumber,
        equipment: room.equipment,
        responsible: room.responsible,
        available: room.available === 1,
        id_institute: room.id_institute,
      };

      return parsedRoom;
    }
  }
}
