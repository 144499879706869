<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Übersicht des Instituts</h1>
      <h3 class="hasomed-text-h3">
        Hier sehen Sie alle Ihre Eingaben im Überblick
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        *ngIf="false"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <!-- Go back button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onGoBack()">
        <i class="fa-light fa-arrow-left hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Zurück</span>
      </button>

      <!-- Create institut button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onCreateInstitut()"
        [matTooltip]="
          instituteService.currentInstitute?.is_founded
            ? 'Institut wurde bereits gegründet.'
            : 'Institut gründen und Benutzer einladen.'
        "
        [disabled]="
          !instituteService.currentInstitute ||
          instituteService.currentInstitute.is_founded
        ">
        <i class="fa-light fa-check hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">
          Abschließen und Institut gründen
        </span>
      </button>
    </div>
  </div>

  <!-- LOADING -->
  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <!-- Stammdaten -->
    <div class="sub-header flex-r">
      <div class="flex-c">
        <h1 class="hasomed-text-h1">Stammdaten des Instituts</h1>
        <h3 class="hasomed-text-h3">Stammdaten</h3>
      </div>
      <div class="flex-r">
        <!-- button -->
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white-grey"
          (click)="navigateTo('master-data')">
          <i class="fa-light fa-rotate-left hasomed-button-icon-sm"> </i>
          <span class="hasomed-button-text">
            Zurück zum Abschnitt zur Bearbeitung
          </span>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Name -->
    <div class="master-data-row">
      <div class="master-data-label">
        <span class="title">Institutsname</span>
        <span class="description">Name des Instituts</span>
      </div>
      <div class="data">
        <span class="hasomed-text-body font-greydark">
          {{
            instituteService.currentInstitute?.name
              ? instituteService.currentInstitute?.name
              : 'Kein Name angegeben.'
          }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Beschreibung -->
    <div class="master-data-row">
      <div class="master-data-label">
        <span class="title">Beschreibung des Instituts oder Slogan</span>
        <span class="description">Ergänzende Informationen</span>
      </div>
      <div class="data">
        <span class="hasomed-text-body font-greydark">
          {{
            instituteService.currentInstitute?.description
              ? instituteService.currentInstitute?.description
              : 'Keine Beschreibung angegeben.'
          }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Logo -->
    <div class="master-data-row">
      <div class="master-data-label">
        <span class="title">Logo des Instituts</span>
        <span class="description">Ihr Logo</span>
      </div>
      <div class="data">
        <img
          *ngIf="instituteService.currentInstitute?.logo"
          class="institut-logo-image"
          [attr.src]="instituteService.currentInstitute?.logo"
          alt="institut logo" />
        <span
          *ngIf="!instituteService.currentInstitute?.logo"
          class="hasomed-text-body font-greydark">
          Kein Logo hochgeladen.
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Aus- Weiterbildungsgäne -->
    <div class="sub-header flex-r">
      <div class="flex-c">
        <h1 class="hasomed-text-h1">
          Aus- und Weiterbildungsgänge des Instituts
        </h1>
        <h3 class="hasomed-text-h3">Aus- und Weiterbildungsgänge</h3>
      </div>
      <div class="flex-r">
        <!-- button -->
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white-grey"
          (click)="navigateTo('education-course')">
          <i class="fa-light fa-rotate-left hasomed-button-icon-sm"> </i>
          <span class="hasomed-button-text">
            Zurück zum Abschnitt zur Bearbeitung
          </span>
        </button>
      </div>
    </div>
    <app-onb-education-course-table></app-onb-education-course-table>
    <mat-divider></mat-divider>

    <!-- Kurse -->
    <div class="sub-header flex-r">
      <div class="flex-c">
        <h1 class="hasomed-text-h1">Kurse des Instituts</h1>
        <h3 class="hasomed-text-h3">Kursregister</h3>
      </div>
      <div class="flex-r">
        <!-- button -->
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white-grey"
          (click)="navigateTo('course-creation')">
          <i class="fa-light fa-rotate-left hasomed-button-icon-sm"> </i>
          <span class="hasomed-button-text">
            Zurück zum Abschnitt zur Bearbeitung
          </span>
        </button>
      </div>
    </div>
    <app-onb-course-creation-table></app-onb-course-creation-table>
    <mat-divider></mat-divider>

    <!-- Nutzer -->
    <div class="sub-header flex-r">
      <div class="flex-c">
        <h1 class="hasomed-text-h1">Benutzer und Rollen</h1>
        <h3 class="hasomed-text-h3">Rollenverwaltung</h3>
      </div>
      <div class="lex-r">
        <!-- button -->
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white-grey"
          (click)="navigateTo('user-roles')">
          <i class="fa-light fa-rotate-left hasomed-button-icon-sm"> </i>
          <span class="hasomed-button-text">
            Zurück zum Abschnitt zur Bearbeitung
          </span>
        </button>
      </div>
    </div>
    <app-onb-user-role-table></app-onb-user-role-table>
  </div>
</div>
