<form [formGroup]="wikiPageForm" class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Artikel bearbeiten' : 'Artikel erstellen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie einen Informations-Artikel.'
            : 'Erstellen Sie einen Informations-Artikel.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Abbrechen</span>
      </button>
      <button
        type="submit"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onSubmit()">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading && editMode" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <div *ngIf="!isLoading || !editMode" class="main-content">
    <div class="formular-row">
      <label class="formular-row-label" for="title">
        <span class="label-title">
          Artikeltitel
          <span *ngIf="isRequired(wikiPageForm.get('title'))"> * </span>
        </span>
        <span class="label-description">
          Schreiben Sie einen einprägsamen Artikeltitel.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="type"
            type="text"
            formControlName="title"
            class="hasomed-text-input"
            placeholder="Title" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="wikiPageForm.get('title')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="subtitle">
        <span class="label-title">
          Unterüberschrift
          <span *ngIf="isRequired(wikiPageForm.get('subtitle'))"> * </span>
        </span>
        <span class="label-description">
          Was kann man in dem Artikel erfahren.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="subtitle"
            type="text"
            formControlName="subtitle"
            class="hasomed-text-input"
            placeholder="Unterüberschrift" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="wikiPageForm.get('subtitle')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="text">
        <span class="label-title">
          Artikeltext
          <span *ngIf="isRequired(wikiPageForm.get('tinyMCE'))"> * </span>
        </span>
        <span class="label-description">
          Was kann man in dem Artikel erfahren.
        </span>
      </label>
      <div class="flex-c">
        <div class="formular-row-input tinymce-div">
          <editor
            class="custom-tinymce"
            formControlName="tinyMCE"
            apiKey="pwuyu1utswoe8a7fq51fce9sg0l14qtxvcpxxnbpi180702l"
            [init]="tinyMceSettings"></editor>
        </div>
        <app-error-message
          [formField]="wikiPageForm.get('tinyMCE')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="articlePicture">
        <span class="label-title">
          Artikelbild
          <span *ngIf="isRequired(wikiPageForm.get('articlePicture'))">
            *
          </span>
        </span>
        <span class="label-description">
          Laden Sie ein passendes Bild für den Artikel hoch.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-image-upload">
          <div
            class="uploaded-image-container"
            *ngIf="wikiPageForm.get('articlePicture').value">
            <img
              id="user-icon-image"
              class="uploaded-image"
              alt="Dein Profilbild"
              matTooltip="Dein Profilbild"
              #userIconImage
              [attr.src]="wikiPageForm.get('articlePicture').value" />
          </div>

          <app-upload-area-dnd
            class="image-upload-area"
            [context]="'articlePicture'"
            inputId="articlePicture"
            [logs]="true"
            [showFileList]="false"
            [multiple]="false"
            maxFileSize="10 MB"
            [requiredFileTypes]="requiredFileTypes"
            (change)="imageChangeEvent($event)">
          </app-upload-area-dnd>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="wikiPageForm.get('articlePicture')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="link">
        <span class="label-title">
          Link <span *ngIf="isRequired(wikiPageForm.get('link'))"> * </span>
        </span>
        <span class="label-description">
          Fügen Sie einen Link in den Artikel ein.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="hasomed-input-with-icon">
            <i class="fa-light fa-link input-icon"></i>
            <input
              id="link"
              type="url"
              formControlName="link"
              class="hasomed-text-input"
              placeholder="https://google.de" />
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="wikiPageForm.get('link')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="id_currentWikiPage"></mat-divider>

    <div class="formular-row" *ngIf="id_currentWikiPage">
      <div class="formular-row-label">
        <span class="label-title"> Artikel-Einstellungen </span>
        <span class="label-description">
          Diverse Einstellungen für Ihren Artikel
        </span>
      </div>

      <div class="formular-row-input">
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white-red button-full-width"
          (click)="onDeleteWikiPage()">
          <i class="hasomed-button-icon-sm fa-light fa-trash"></i>
          <span class="hasomed-button-text">Artikel löschen</span>
        </button>
      </div>
    </div>
  </div>
</form>
