import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ImageCropperDialogComponent } from 'src/app/components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { Institute } from 'src/app/models/institute.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { InstituteService } from 'src/app/services/institute.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-onb-master-data',
  templateUrl: './onb-master-data.component.html',
  styleUrls: ['./onb-master-data.component.scss'],
})
export class ONBMasterDataComponent implements OnInit, OnDestroy {
  public onboardingForm: FormGroup;
  private initialInstitute: Institute;
  private selectedInstituteId: number = null;

  /* Color*/
  public color_primary: string;
  public color_secondary: string;
  public color_akzent1: string;
  public color_akzent2: string;

  public requiredFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'SVG', mimeType: 'image/svg+xml' },
  ];

  // import from form.utils.ts
  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.buildInstitute(),
        this.initialInstitute
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private instituteService: InstituteService,
    private userService: UserService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private location: Location,
    private dialog: MatDialog,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    if (this.activatedRoute.snapshot.params['id']) {
      this.selectedInstituteId = +atob(
        this.activatedRoute.snapshot.params['id']
      );
    }

    this.createForm();

    // if no currentInstitut is set, get the id_institute from the User
    if (!this.instituteService.currentInstitute || this.selectedInstituteId) {
      const id_institute =
        this.selectedInstituteId ?? this.userService.currentUser.id_institute;
      // if the user has an id_institute, get the institute data
      if (id_institute) {
        this.instituteService
          .getInstitute(id_institute)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: response => {
              console.debug('onboarding data', response);
              if (!response.success) {
                console.error(response.message);
                return;
              }

              const currentInstitute: Institute = {
                id: response.data.id,
                name: response.data.name,
                description: response.data.description,
                logo: response.data.logo ? atob(response.data.logo) : null,
                color_primary: response.data.color_primary,
                color_secondary: response.data.color_secondary,
                color_akzent1: response.data.color_akzent1,
                color_akzent2: response.data.color_akzent2,
                education_courses: [],
              };

              // set currentInstitut and patch form values
              this.instituteService.setCurrentInstitute(currentInstitute);
              this.onboardingForm.patchValue({
                id: currentInstitute.id,
                name: currentInstitute.name,
                description: currentInstitute.description,
                logo: response.data.logo ? atob(response.data.logo) : null,
              });

              if (currentInstitute.logo) {
                this.onboardingForm.get('logo').setValue(currentInstitute.logo);
              }

              // set color values
              this.color_primary = response.data.color_primary;
              this.color_secondary = response.data.color_secondary;
              this.color_akzent1 = response.data.color_akzent1;
              this.color_akzent2 = response.data.color_akzent2;

              // store initial institute to check if the form has unsaved changes later
              this.initialInstitute = this.buildInstitute();
            },
            error: error => {
              console.error(error);
            },
          });
      } else {
        console.debug('no current institut and no id_institute on user');
        this.instituteService.setCurrentInstitute(null);

        // store initial instiute to check if the form has unsaved changes later
        this.initialInstitute = this.buildInstitute();
      }
    } else {
      // if a currentInstitut is set, patch form values and set color values
      const currentInstitut = this.instituteService.currentInstitute;
      this.color_primary = currentInstitut.color_primary;
      this.color_secondary = currentInstitut.color_secondary;
      this.color_akzent1 = currentInstitut.color_akzent1;
      this.color_akzent2 = currentInstitut.color_akzent2;

      this.onboardingForm.patchValue({
        id: currentInstitut.id,
        name: currentInstitut.name,
        description: currentInstitut.description,
        logo: currentInstitut.logo,
      });

      // store initial instiute to check if the form has unsaved changes later
      this.initialInstitute = this.buildInstitute();
    }
  }

  /**
   * createForm
   * create the onboarding form
   * @returns void
   */
  private createForm(): void {
    this.onboardingForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      logo: new FormControl(''),
      color_primary: [],
      color_secondary: new FormControl(''),
      color_akzent1: new FormControl(''),
      color_akzent2: new FormControl(''),
    });
  }

  /**
   * onCancel
   * cancel the onboarding process
   * @returns void
   */
  public onCancel(): void {
    // TODO
    this.instituteService.setCurrentInstitute(null);
    this.location.back();
  }

  /**
   * onSaveAndContinue
   * save the form and navigate to the next step
   * @returns void
   */
  public onSaveAndContinue(): void {
    const currentInstitute = this.instituteService.currentInstitute;
    // create new institut if it doesn't exist
    if (!currentInstitute) {
      const institut: Institute = this.buildInstitute();
      this.createInstitute(institut);
    } else {
      // update current institut if it exists
      const institut: Institute = {
        id: currentInstitute.id,
        name: this.onboardingForm.get('name').value,
        description: this.onboardingForm.get('description').value,
        logo: this.onboardingForm.get('logo').value,
        color_primary: this.color_primary,
        color_secondary: this.color_secondary,
        color_akzent1: this.color_akzent1,
        color_akzent2: this.color_akzent2,
        education_courses: currentInstitute.education_courses,
      };
      this.updateInstitute(institut.id, institut);
    }

    // reset initialInstitute to current institute
    this.initialInstitute = this.buildInstitute();
  }

  /**
   * createInstitute
   * create a new institute
   * @param institute
   * @returns void
   */
  public async createInstitute(institute: Institute): Promise<void> {
    this.instituteService.createInstitute(institute).subscribe({
      next: response => {
        console.debug('create institute data', response);
        if (!response.success) {
          console.error(response.message);
          this.alertService.showErrorAlert(
            'Fehler!',
            'Speichern fehlgeschlagen.'
          );
          return;
        }
        // set id of new institute, update currentInstitute and id_institute of currentUser
        institute.id = response.data;
        this.instituteService.setCurrentInstitute(institute);
        this.userService.currentUser.id_institute = institute.id;
        this.userService
          .updateInstituteFromUser(
            institute.id,
            this.userService.currentUser.id
          )
          .subscribe({
            next: response => {
              console.debug(
                'updateInstituteFromUser backend response',
                response
              );
              if (!response.success) {
                console.error(response.message);
                this.alertService.showErrorAlert(
                  'Fehler!',
                  'Speichern fehlgeschlagen.'
                );
                return;
              }
            },
            error: error => {
              console.error(error);
              this.alertService.showErrorAlert(
                'Fehler!',
                'Speichern fehlgeschlagen.'
              );
              return;
            },
          });
        this.router.navigate(['../education-course'], {
          relativeTo: this.activatedRoute,
        });
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler!',
          'Speichern fehlgeschlagen.'
        );
      },
    });
  }

  /**
   * updateInstitut
   * update an institute
   * @param id_institute
   * @param institute
   * @returns void
   */
  public async updateInstitute(
    id_institute: number,
    institute: Institute
  ): Promise<void> {
    this.instituteService.updateInstitute(id_institute, institute).subscribe({
      next: response => {
        console.debug('update institut data', response);
        if (!response.success) {
          console.error(response.message);
          this.alertService.showErrorAlert(
            'Fehler!',
            'Speichern fehlgeschlagen.'
          );
          return;
        }
        const currentInstitut: Institute = {
          id: id_institute,
          name: response.data.name,
          description: response.data.description,
          logo: response.data.logo ? atob(response.data.logo) : null,
          color_primary: response.data.color_primary,
          color_secondary: response.data.color_secondary,
          color_akzent1: response.data.color_akzent1,
          color_akzent2: response.data.color_akzent2,
        };

        console.debug('update institut', currentInstitut);
        this.instituteService.setCurrentInstitute(currentInstitut);
        this.selectedInstituteId
          ? this.router.navigate(['../../education-course'], {
              relativeTo: this.activatedRoute,
            })
          : this.router.navigate(['../education-course'], {
              relativeTo: this.activatedRoute,
            });
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler!',
          'Speichern fehlgeschlagen.'
        );
      },
    });
  }

  /**
   * buildInstitute
   * build an institute object from the form values
   * @returns Institute
   */
  private buildInstitute(): Institute {
    const institute: Institute = {
      name: this.onboardingForm.get('name').value,
      description: this.onboardingForm.get('description').value,
      logo: this.onboardingForm.get('logo').value,
      color_primary: this.color_primary,
      color_secondary: this.color_secondary,
      color_akzent1: this.color_akzent1,
      color_akzent2: this.color_akzent2,
      education_courses: [],
    };

    return institute;
  }

  public imageChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '500px',
      data: {
        image: event,
        title: 'Logo zuschneiden',
        round: false,
        height: 300,
        aspectRatio: 2.5 / 1,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.onboardingForm.get('logo').setValue(result);
      }
    });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    return this.formDeactivateService.confirmDeactivation(
      this.buildInstitute(),
      this.initialInstitute
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
