import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { EmptyStateDialogComponent } from 'src/app/components/shared-components/empty-state-dialog/empty-state-dialog.component';
import { NavLink } from 'src/app/models/nav-link.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { User } from 'src/app/models/user.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.scss'],
})
export class StudentDetailComponent implements OnInit, OnDestroy {
  public student: User;
  public isLoading = true;
  public navLinks: NavLink[];

  /* for permission */
  feature = Feature;
  permission = Permission;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private location: Location,
    private cancellationService: CancellationService
  ) {
    this.navLinks = [
      {
        label: 'Kurse & eLog',
        link: 'course-elog',
        feature: Feature.STUDENT_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Ausbildungsfortschritt',
        link: 'education-progress',
        feature: Feature.STUDENT_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Praktische Tätigkeit',
        link: 'practical-work',
        feature: Feature.STUDENT_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Ambulanter Teil',
        link: 'ambulatory-part',
        feature: Feature.STUDENT_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Gebührenübersicht',
        link: 'overview-of-fees',
        feature: Feature.STUDENT_ADMINISTRATION,
        permission: Permission.VIEW,
      },
    ];
  }

  public ngOnInit() {
    const user_id = +atob(this.activatedRoute.snapshot.paramMap.get('id'));
    this.getStudent(user_id);
  }

  /**
   * getStudent
   * @description get student data from backend
   * @param user_id id of the student
   * @returns void
   */
  private getStudent(user_id: number): void {
    this.userService
      .getUserById(user_id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getUserById Backend response', response);
          if (!response.success) {
            console.error(response.message);
            this.isLoading = false;
            return;
          }
          this.student = await this.userService.parseBackendUser(response.data);
          this.isLoading = false;
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  /**
   * onChangeInstitut
   * @description change the institute of the student
   * @returns void
   */
  public onChangeInstitut(): void {
    // Placeholder
    console.log('change institut');
    this.dialog.open(EmptyStateDialogComponent);
  }

  /**
   * goBack
   * go back to the overview
   */
  public goBack(): void {
    this.location.back();
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
