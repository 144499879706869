import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
  @Input() public formField: AbstractControl;

  constructor() {}

  ngOnInit(): void {}

  /**
   * hasErrors
   * checks if the formControl has errors
   * @param formControl formControl of the formGroup
   * @returns boolean
   */
  public hasErrors() {
    if (!this.formField) {
      return false;
    }

    return (
      this.formField.invalid &&
      this.formField.errors &&
      (this.formField.dirty || this.formField.touched)
    );
  }

  /**
   * getErrorMessage
   * returns the error message of the formControl
   * @returns string
   */
  public getErrorMessage() {
    if (this.formField && this.hasErrors()) {
      //console.debug('formField', this.formField);

      if (this.formField.hasError('required')) {
        return 'Dies ist ein Plichtfeld.';
      }

      if (this.formField.hasError('invalidChiffre')) {
        return 'Dies ist keine gültige Chiffre. Format: Anfangsbuchstabe \
        Familienname + 6 stellig Geb. Datum.';
      }

      if (this.formField.hasError('invalidIBAN')) {
        return 'Dies ist keine gültige IBAN.';
      }

      if (this.formField.hasError('invalidEmail')) {
        return 'Dies ist keine gültige E-Mail Adresse.';
      }

      if (this.formField.hasError('maxlength')) {
        return `Maximale Länge: ${this.formField.errors.maxlength.requiredLength} Zeichen.`;
      }

      if (this.formField.hasError('maxNumberLength')) {
        return `Maximale Länge: ${this.formField.errors.maxNumberLength.value} Zeichen.`;
      }

      if (this.formField.hasError('minlength')) {
        return `Minimale Länge: ${this.formField.errors.minlength.requiredLength} Zeichen.`;
      }

      if (this.formField.hasError('minNumberLength')) {
        return `Minimale Länge: ${this.formField.errors.minNumberLength.value} Zeichen.`;
      }

      if (this.formField.hasError('positiveNumbersOnly')) {
        return 'Nur positive Zahlen sind erlaubt.';
      }

      if (this.formField.hasError('notPositiveDecimalNumber')) {
        return 'Nur positive Zahlen sind erlaubt.';
      }

      if (this.formField.hasError('invalidHouseNumber')) {
        return 'Dies ist keine gültige Hausnummer.';
      }
      if (this.formField.hasError('userExists')) {
        return 'Der Benutzer existiert bereits.';
      }

      if (this.formField.hasError('invalidDateGroup')) {
        return 'Bitte Datum und Uhrzeit auswählen.';
      }

      if (this.formField.hasError('min')) {
        return `Mindestwert: ${this.formField.errors.min.min}`;
      }
    }

    return '';
  }
}
