<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Benutzerverwaltung</h1>
      <h3 class="hasomed-text-h3">
        Benutzer ohne Rolle oder Institut verwalten
      </h3>
    </div>
    <form class="header-buttons" [formGroup]="searchForm">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          (keyup)="applySearch($event)"
          placeholder="Suche" />
      </div>
    </form>
  </div>
  <div class="main-content">
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- User column -->
        <ng-container matColumnDef="user">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span> Nutzername </span>
            <app-custom-table-sort-icon [sort]="sort" columnName="user">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let user">
            <div class="user-container">
              <div class="user-icon">
                <img
                  src="{{ user.profilePicture ?? '../assets/img/user.jpg' }}"
                  alt=""
                  class="user-icon-image" />
              </div>
              <div class="user-info">
                <span class="user-name hasomed-text-body">
                  {{ user.name.academicTitle }}
                  {{ user.name.firstname }}
                  {{ user.name.lastname }}
                </span>
                <span class="user-info hasomed-text-small">
                  {{ user.email }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Role column -->
        <ng-container matColumnDef="role">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span> Rolle </span>
            <app-custom-table-sort-icon [sort]="sort" columnName="role">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let user">
            <div
              class="label"
              [style.background]="getLabelBackgroundColor(user.id_role)">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="label-text">{{ getRoleName(user.id_role) }}</span>
            </div>
          </td>
        </ng-container>

        <!-- Institute column -->
        <ng-container matColumnDef="institute">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span> Institut </span>
            <app-custom-table-sort-icon [sort]="sort" columnName="institute">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let user">
            <span>{{ user.institute?.name ?? 'Kein Institut' }}</span>
          </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let user">
            <div class="table-button-icons">
              <button
                type="button"
                class="table-button-icon"
                matTooltip="Rolle bearbeiten"
                (click)="openEditUserDialog(user)">
                <i class="fa-light fa-edit"></i>
              </button>
              <button
                type="button"
                class="table-button-icon"
                matTooltip="Nutzer löschen"
                (click)="openDeleteUserDialog(user)">
                <i class="fa-light fa-trash"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <mat-progress-spinner
                color="primary"
                mode="indeterminate"
                diameter="32">
              </mat-progress-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Keinen Nutzer gefunden.
            </div>
          </td>
        </tr>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
