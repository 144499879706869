import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { Course } from 'src/app/models/course.model';
import {
  FilterDateRange,
  Filter,
  FilterType,
} from 'src/app/models/filter.model';
import { FilterCoursePipe } from 'src/app/pipes/filter-course.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import {
  getCourseRoom,
  getFullLecturerNames,
} from 'src/app/utils/course.utils';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';

@Component({
  selector: 'app-course-administration',
  templateUrl: './course-administration.component.html',
  styleUrls: ['./course-administration.component.scss'],
})
export class CourseAdministrationComponent implements OnInit, OnDestroy {
  public selectedCourse?: Course;
  public courses: Course[];
  public searchForm: FormGroup;
  public filterOpened: boolean = false;
  public displayedColumns = [
    'title',
    'lecturer',
    'dates',
    'rooms',
    'elog',
    'actions',
  ];
  public dataSource: MatTableDataSource<Course> = new MatTableDataSource();
  private tableData: Subject<Course[]> = new Subject();
  public isLoading = true;
  public isLecturer: boolean = false;
  public isAdministrator: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;

  private dateRangeSubject: Subject<FilterDateRange | null> = new Subject();
  public dateRangeFilter: Filter = { type: FilterType.DATE_RANGE, value: null };
  public courseFilter: Filter[] = [
    this.dateRangeFilter,
    {
      type: FilterType.COURSE_TYPE,
      value: null,
    },
    {
      type: FilterType.ROOM_NAME,
      value: null,
    },
    {
      type: FilterType.PENDING_ELOGS,
      value: null,
    },
  ];
  public hasActiveFilterValue = hasActiveFilterValue;

  public getFullLecturerNames = getFullLecturerNames;
  public getCourseRoom = getCourseRoom;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private courseService: CourseService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      searchText: new FormControl(''),
      dateRange: new FormGroup({
        start: new FormControl(null, Validators.required),
        end: new FormControl(null, Validators.required),
      }),
    });

    this.route.queryParams.subscribe(params => {
      if (params.pendingELogsOnly) {
        // set pending eLogs filter to true
        this.courseFilter.find(
          filter => filter.type === FilterType.PENDING_ELOGS
        ).value = true;
      }
    });

    this.dateRangeSubject.subscribe({
      next: dateRange => {
        if (!dateRange) {
          this.searchForm.patchValue({
            dateRange: {
              start: null,
              end: null,
            },
          });
        }
        this.dateRangeFilter.value = dateRange;
        this.applyCourseFilter();
      },
    });
    this.isLecturer = this.userService.currentUserIsLecturer();
    this.isAdministrator = this.userService.currentUserIsAdministrator();

    if (!this.isLecturer) {
      this.courseFilter.push({
        type: FilterType.LECTURER,
        value: null,
      });
      this.displayedColumns = [
        'title',
        'lecturer',
        'dates',
        'rooms',
        'elog',
        'active',
        'actions',
      ];
    }

    this.getCourses();

    this.tableData.subscribe((courses: Course[]) => {
      this.courses = courses;
      this.dataSource = new MatTableDataSource<Course>(this.courses);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'title':
            return item.title;
          case 'lecturer':
            return item.lecturers[0].name.firstname;
          case 'dates':
            return item.startDate;
          case 'rooms':
            return item.differentRooms ? 'Unterschiedliche' : item.room.name;
          case 'active':
            return item.logonEnabled;
          case 'elog':
            return item.eLogStatusCounts.pending;
          default:
            return item[property];
        }
      };
      this.isLoading = false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.applyCourseFilter();
    });
  }

  /**
   * getCourses to initialize the table data
   */
  private getCourses() {
    const id_institute = this.userService.currentUser.id_institute;
    if (this.isLecturer) {
      const id_user = this.userService.currentUser.id;
      this.getLecturersCourses(id_institute, id_user);
    } else {
      this.getInstituteCourses(id_institute);
    }
  }

  /**
   * get all courses of the institute
   * @param id_institute
   */
  private getInstituteCourses(id_institute: number) {
    this.courseService
      .getInstituteCourses(id_institute, 'educationCourses')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('courses Backend Data:', response);
          if (!response.success) {
            console.error(response.message);
            this.isLoading = false;
            return;
          }
          this.tableData.next(
            response.data
              ? await Promise.all(
                  response.data.courses.map(
                    async (courseData: any): Promise<Course> => {
                      return await this.courseService.parseBackendCourse(
                        courseData
                      );
                    }
                  )
                )
              : []
          );
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  /**
   * get all courses of the lecturer
   * @param id_institute
   * @param id_user
   */
  private getLecturersCourses(id_institute: number, id_user: number) {
    this.courseService
      .getLecturersCourses(id_institute, id_user)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('courses Backend Data:', response);
          if (!response.success) {
            console.error(response.message);
            this.isLoading = false;
            return;
          }
          this.tableData.next(
            response.data
              ? await Promise.all(
                  response.data.courses.map(
                    async (courseData: any): Promise<Course> => {
                      return await this.courseService.parseBackendCourse(
                        courseData
                      );
                    }
                  )
                )
              : []
          );
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  /**
   * courseFilterChanged
   * gets called when the course filter changed
   * @param courseFilter Filter[]
   * @returns void
   */
  public courseFilterChanged(courseFilter: Filter[]): void {
    console.debug('Course Filter Changed:', courseFilter);
    this.courseFilter = courseFilter;

    if (!courseFilter.includes(this.dateRangeFilter)) {
      courseFilter.push(this.dateRangeFilter);
    }

    this.applyCourseFilter();
    this.filterMenuTrigger.closeMenu();

    // find FILTER_TYPE.DATE_RANGE and update date range
    const dateRangeFilter = courseFilter.find(
      filter => filter.type === FilterType.DATE_RANGE
    );

    if (dateRangeFilter && !dateRangeFilter.value) {
      this.dateRangeSubject.next(null);
    }

    // if pendingElogs filter gets removed, remove it from query params
    const pendingELogsFilter = courseFilter.find(
      filter => filter.type === FilterType.PENDING_ELOGS
    );

    if (pendingELogsFilter && !pendingELogsFilter.value) {
      this.router.navigate([], {
        queryParams: {
          pendingELogsOnly: null,
        },
      });
    }
  }

  /**
   * applyCourseFilter
   * applies the course filter
   * @returns void
   */
  public applyCourseFilter(): void {
    this.dataSource.data = FilterCoursePipe.prototype.transform(
      this.courses,
      this.courseFilter
    );
  }

  /**
   * filter courses by search text
   * @param event
   */
  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * onCourseActiveChanged
   * gets called when the course active state changed
   * @param data MatSlideToggleChange
   * @param course Course
   * @returns void
   */
  public async onCourseActiveChanged(
    data: MatSlideToggleChange,
    course: Course
  ) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Achtung',
        message: `Möchten Sie den Kurs '${course.title}' wirklich ${
          data.checked ? 'aktivieren' : 'deaktivieren'
        }?`,
      },
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      if (dialogResult) {
        let updatedCourse = JSON.parse(JSON.stringify(course));
        updatedCourse.logonEnabled = data.checked;

        const updateCourseObservable = await this.courseService.updateCourse(
          'updateLogonState',
          updatedCourse
        );

        const activeStatus = data.checked ? 'aktiviert' : 'deaktiviert';

        updateCourseObservable.subscribe({
          next: response => {
            console.debug('Course Updated:', response);

            if (!response.success) {
              console.error(response.message);
              this.alertService.showErrorAlert(
                `Kurs nicht ${activeStatus}`,
                `Der Kurs '${course.title}' konnte nicht ${activeStatus} werden!`
              );
              updatedCourse.logonEnabled = !data.checked;
              return;
            }

            this.alertService.showSuccessAlert(
              `Kurs ${activeStatus}`,
              `Der Kurs '${course.title}' wurde ${activeStatus}!`
            );
          },
          error: error => {
            console.error(error);
            this.alertService.showErrorAlert(
              `Kurs nicht ${activeStatus}`,
              `Der Kurs '${course.title}' konnte nicht ${activeStatus} werden!`
            );
            updatedCourse.logonEnabled = !data.checked;
          },
        });
      } else {
        data.source.checked = !data.checked;
      }
    });
  }

  /**
   * applyDateRange
   * applies the date range filter
   * @returns void
   */
  public applyDateRange() {
    this.dateRangeSubject.next({
      start: this.searchForm.value.dateRange?.start,
      end: this.searchForm.value.dateRange?.end,
    });
  }

  /**
   * open eLog for the course
   * @param courseId
   */
  public openELog(courseId: number) {
    this.router.navigate(['../elog'], {
      queryParams: {
        course_id: btoa(String(courseId)),
      },
      relativeTo: this.route,
    });
  }

  /**
   * viewCourse in theoretical education
   * @param course
   */
  public viewCourse(course: Course) {
    // this.selectedCourse = course;
    this.router.navigate([btoa(course.id.toString())], {
      relativeTo: this.route,
    });
  }

  /**
   * createCourse
   */
  public createCourse() {
    this.router.navigate(['./create'], { relativeTo: this.route });
  }

  /**
   * editCourse
   * @param id
   */
  public editCourse(id: number) {
    this.router.navigate(['./', 'edit', btoa(String(id))], {
      relativeTo: this.route,
    });
  }

  /**
   * duplicateCourse
   * @param id
   */
  public duplicateCourse(id: number) {
    this.router.navigate(['./', 'create', btoa(String(id))], {
      relativeTo: this.route,
    });
  }

  /**
   * deleteCourse
   * @param course
   */
  public deleteCourse(course: Course) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Löschen',
        message: `Möchten Sie den Kurs '${course.title}' wirklich löschen?`,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.courseService.deleteCourse(course.id).subscribe({
          next: response => {
            console.debug('Course Deleted:', response);
            if (response.success) {
              this.alertService.showSuccessAlert(
                'Kurs gelöscht',
                `Der Kurs '${course.title}' wurde gelöscht!`
              );

              // delete entry from table data without reloading from backend
              const index = this.dataSource.data.indexOf(course);
              if (index > -1) {
                this.dataSource.data = this.dataSource.data.filter(
                  item => item !== course
                );
              }
            } else {
              this.alertService.showErrorAlert(
                'Kurs nicht gelöscht',
                `Der Kurs '${course.title}' konnte nicht gelöscht werden!`
              );
            }
          },
          error: error => {
            console.error(error);
          },
        });
      }
    });
  }

  /**
   * isSameDay
   * checks if two dates are the same day
   * @param date1
   * @param date2
   * @returns boolean
   */
  public isSameDay(date1: Date, date2: Date): boolean {
    return moment(date1).isSame(date2, 'day');
  }

  /**
   * getParticipantAmount
   * get the amount of participants for the course from the eLogStatusCounts
   * @param course
   */
  public getParticipantAmount(course: Course): number {
    if (!course.eLogStatusCounts) {
      return 0;
    }
    return (
      course.eLogStatusCounts.absent +
      course.eLogStatusCounts.checked +
      course.eLogStatusCounts.excused +
      course.eLogStatusCounts.pending +
      course.eLogStatusCounts.unexcused +
      course.eLogStatusCounts.upcoming
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
