<form
  class="component-content"
  [formGroup]="selfAwarenessForm"
  (ngSubmit)="onSubmit()">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Eintrag bearbeiten' : 'Neuen Eintrag vornehmen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie einen bestehenden Eintrag'
            : 'Erstellen Sie einen neuen Eintrag'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>
  <div *ngIf="!isLoading" class="main-content">
    <div class="formular-row">
      <label class="formular-row-label" for="title">
        <span class="label-title">
          Titel
          <span *ngIf="isRequired(selfAwarenessForm.get('title'))"> * </span>
        </span>
        <span class="label-description"> Titel für den Eintrag </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="title"
            class="hasomed-text-input"
            type="text"
            formControlName="title"
            placeholder="Titel" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="selfAwarenessForm.get('title')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">
          Beschreibung
          <span *ngIf="isRequired(selfAwarenessForm.get('description'))">
            *
          </span>
        </span>
        <span class="label-description"> Ergänzende Informationen </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="description"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="description"
            placeholder="Beschreibung"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            selfAwarenessForm.get('description')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer
          <span *ngIf="isRequired(selfAwarenessForm.get('duration'))"> * </span>
        </span>
        <span class="label-description">
          Wählen Sie die Dauer des Eintrags in Stunden
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="duration"
            class="hasomed-text-input"
            min="0"
            step="0.5"
            type="number"
            formControlName="duration"
            placeholder="Dauer" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="selfAwarenessForm.get('duration')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="reponsiblePerson">
        <span class="label-title">
          Verantwortliche*r
          <span *ngIf="isRequired(selfAwarenessForm.get('responsiblePerson'))">
            *
          </span>
        </span>
        <span class="label-description"> Beschreibung </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="responsiblePerson"
            class="hasomed-text-input"
            type="text"
            formControlName="responsiblePerson"
            placeholder="Verantwortliche*r" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            selfAwarenessForm.get('responsiblePerson')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Datum
          <span *ngIf="isRequired(selfAwarenessForm.get('date'))"> * </span>
        </span>
        <span class="label-description"> Datum des Eintrags </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-date-field hasomed-date-field-center">
            <input
              class="hasomed-date-input"
              maxlength="10"
              size="9"
              matInput
              [matDatepicker]="picker"
              placeholder="Datum wählen"
              formControlName="date" />
            <mat-datepicker-toggle
              class="hasomed-date-toggle"
              matIconPrefix
              [for]="picker">
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="selfAwarenessForm.get('date')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="note">
        <span class="label-title">
          Freitext
          <span *ngIf="isRequired(selfAwarenessForm.get('note'))"> * </span>
        </span>
        <span class="label-description"> Freitext für interne Notizen </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="note"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="internalNote"
            placeholder="Freitext für interne Notizen"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="selfAwarenessForm.get('note')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title">
          Dokumentenupload
          <span *ngIf="isRequired(selfAwarenessForm.get('documentUpload'))">
            *
          </span>
        </span>
        <span class="label-description">
          Hier können Sie Dokumente für diesen Eintrag hochladen.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            [context]="'selfAwarenessDocumentsUpload'"
            [logs]="false"
            [existingFiles]="existingFiles"
            (deleteExistingFile)="onDeleteExistingFile($event)"
            inputId="documentUpload"
            formControlName="uploadedFiles"
            [multiple]="true"
            [scrollTo]="true"
            [showFileList]="true"
            [requiredFileTypes]="requiredFileTypesDocuments"
            maxFileSize="12 MB">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
