import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { CustomFileType } from 'src/app/models/custom-file-type.model';
import { SelfAwarenessEntry } from 'src/app/models/self-awareness-entry.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { SelfAwarenessService } from 'src/app/services/self-awareness.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { positiveDecimalNumbersValidator } from 'src/app/validators/positive-decimal-number.validator';

@Component({
  selector: 'app-create-edit-self-awareness-entry',
  templateUrl: './create-edit-self-awareness-entry.component.html',
  styleUrls: ['./create-edit-self-awareness-entry.component.scss'],
})
export class CreateEditSelfAwarenessEntryComponent
  implements OnInit, OnDestroy
{
  public isLoading = true;
  public editMode = false;
  public currentSelfAwarenessEntry: SelfAwarenessEntry;
  public selfAwarenessForm: FormGroup;
  public initialFormValues: {};
  public uploadedFiles: CustomFileType[] = [];
  public existingFiles: CustomFileType[] = [];

  public requiredFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  // import from form.utils.ts
  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.selfAwarenessForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private selfAwarenessService: SelfAwarenessService,
    private userService: UserService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.editMode = true;
        this.selfAwarenessService
          .getSelfAwarenessEntry(+atob(params.id))
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: async result => {
              console.debug('Self Awareness Backend Data:', result);
              if (!result.success) {
                this.alertService.showErrorAlert(
                  'Fehler!',
                  'Fehler beim Laden des Selbsterfahrungseintrags.'
                );
                // reset initial form values to prevent unsaved changes alert
                this.initialFormValues = this.selfAwarenessForm.value;
                // redirect to overview
                this.router.navigate(['../../'], {
                  relativeTo: this.activatedRoute,
                });
                console.error(result.message);
                return;
              }

              this.currentSelfAwarenessEntry =
                await this.selfAwarenessService.parseBackendSelfAwarenessEntry(
                  result.data
                );

              this.currentSelfAwarenessEntry.files?.forEach(file => {
                this.existingFiles.push(file);
              });

              this.selfAwarenessForm.patchValue({
                title: this.currentSelfAwarenessEntry.title,
                description: this.currentSelfAwarenessEntry.description,
                duration: this.currentSelfAwarenessEntry.duration,
                responsiblePerson:
                  this.currentSelfAwarenessEntry.responsiblePerson,
                date: moment(this.currentSelfAwarenessEntry.date).toDate(),
                internalNote: this.currentSelfAwarenessEntry.internalNote,
                files: this.currentSelfAwarenessEntry.files,
                uploadedFiles: this.uploadedFiles,
                existingFiles: this.existingFiles,
              });

              this.initialFormValues = this.selfAwarenessForm.value;

              this.cdr.detectChanges();
              this.isLoading = false;
            },
          });
      } else {
        this.initialFormValues = this.selfAwarenessForm.value;
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    });

    this.selfAwarenessForm
      .get('uploadedFiles')
      .valueChanges.subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.selfAwarenessForm.get('files').setValue(null);
          return;
        }
        const newDocumentsArray: any[] = [];

        value.forEach((file: any) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            newDocumentsArray.push({
              id: file.id,
              filename: file.name,
              file: e.target.result,
              id_creator: !file.id_creator
                ? this.userService.currentUser.id
                : file.id_creator,
              fileSize: file.size,
              timeCreated: file.timeCreated,
              timeModified: file.timeModified,
            });

            // Check if all files have been read and update the documents array once
            if (newDocumentsArray.length === value.length) {
              this.selfAwarenessForm.get('files').setValue(newDocumentsArray);
            }
          };
          reader.readAsDataURL(file);
        });
      });
  }

  private createForm() {
    this.selfAwarenessForm = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      description: new FormControl('', Validators.maxLength(6000)),
      duration: new FormControl('', [
        Validators.required,
        positiveDecimalNumbersValidator(),
        maxNumberLength(5),
      ]),
      responsiblePerson: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      date: new FormControl(null, [Validators.required]),
      internalNote: new FormControl('', Validators.maxLength(6000)),
      files: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });
  }

  public onSubmit() {
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.selfAwarenessForm
      );

    if (formInvalid) {
      return;
    }

    const id_student = this.userService.currentUser.id;
    const id_institute = this.userService.currentUser.id_institute;

    const files: CustomFileType[] = this.selfAwarenessForm.value.files
      ? this.existingFiles.concat(this.selfAwarenessForm.value.files)
      : this.existingFiles;
    this.selfAwarenessForm.get('files').setValue(files);

    this.isLoading = true;

    this.editMode
      ? this.updateSelfAwarenessEntry(this.currentSelfAwarenessEntry.id)
      : this.createSelfAwarenessEntry(id_student, id_institute);
  }

  private async updateSelfAwarenessEntry(id: number) {
    const updateSelfAwarenessPromise =
      await this.selfAwarenessService.updateSelfAwareness(
        this.selfAwarenessForm.value,
        id
      );

    updateSelfAwarenessPromise.subscribe({
      next: result => {
        console.log('updateSelfAwareness', result);

        if (!result.success) {
          console.error(result.message);
          this.alertService.showErrorAlert(
            'Fehler!',
            `Selbsterfahrungs Eintrag '${this.selfAwarenessForm.value.title}' konnte nicht bearbeitet werden.`
          );
          this.isLoading = false;
          return;
        }

        // reset initialformvalues to current form values
        this.initialFormValues = this.selfAwarenessForm.value;
        this.onCancel();

        this.alertService.showSuccessAlert(
          'Selbsterfahrung Eintrag bearbeitet!',
          `Selbsterfahrung Eintrag '${this.selfAwarenessForm.value.title}' erfolgreich bearbeitet.`
        );
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler!',
          `Selbsterfahrungs Eintrag '${this.selfAwarenessForm.value.title}' konnte nicht bearbeitet werden.`
        );
        this.isLoading = false;
      },
    });
  }

  private async createSelfAwarenessEntry(
    id_student: number,
    id_institute: number
  ) {
    const createSelfAwarenessPromise =
      await this.selfAwarenessService.createSelfAwarenessEntry(
        this.selfAwarenessForm.value,
        id_student,
        id_institute
      );
    createSelfAwarenessPromise.subscribe({
      next: result => {
        console.log('createSelfAwarenessEntry', result);
        if (!result.success) {
          console.error(result.message);
          this.alertService.showErrorAlert(
            'Fehler!',
            `Selbsterfahrungs Eintrag '${this.selfAwarenessForm.value.title}' konnte nicht erstellt werden.`
          );
          this.isLoading = false;
          return;
        }

        // reset initialformvalues to current form values
        this.initialFormValues = this.selfAwarenessForm.value;
        this.onCancel();

        this.alertService.showSuccessAlert(
          'Selbsterfahrung Eintrag hinzugefügt!',
          `Selbsterfahrung Eintrag '${this.selfAwarenessForm.value.title}' erfolgreich hinzugefügt.`
        );
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler!',
          `Selbsterfahrungs Eintrag '${this.selfAwarenessForm.value.title}' konnte nicht erstellt werden.`
        );
        this.isLoading = false;
      },
    });
  }

  /**
   * onCancel
   */
  public onCancel() {
    if (this.editMode) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

  /**
   * onDeleteExistingFile
   * @param file
   */
  public onDeleteExistingFile(file: CustomFileType) {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.selfAwarenessForm.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.selfAwarenessForm.value,
      this.initialFormValues
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
