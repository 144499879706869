<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Institute verwalten</h1>
      <h3 class="hasomed-text-h3">Verwalten Sie alle Institute</h3>
    </div>
    <form class="header-buttons" [formGroup]="searchForm">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          (keyup)="applySearch($event)"
          placeholder="Suche" />
      </div>
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="createInstitute()">
        <i class="fa-light fa-calendar-plus hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Neues Institut anlegen</span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Institute column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Institut</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="name">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <div
              class="institute-container flex-r gap-16 align-center"
              (click)="openInstituteDetails(row)">
              <div class="institute-icon justify-center">
                <img
                  src="{{
                    row.logo ?? '../assets/img/EleGuide_Logo-Fullsize.svg'
                  }}"
                  alt=""
                  class="institute-icon-image" />
              </div>
              <div class="institute-info">
                <span class="institute-name hasomed-text-body">
                  {{ row.name }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let row">
            <div class="table-button-icons">
              <button
                type="button"
                class="table-button-icon"
                matTooltip="Institut anzeigen"
                (click)="openInstituteDetails(row)">
                <i class="fa-light fa-arrow-up-right-from-square"></i>
              </button>
              <button
                type="button"
                class="table-button-icon"
                matTooltip="Institut bearbeiten"
                (click)="editInstitute(row)">
                <i class="fa-light fa-pen"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Description column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Beschreibung</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="description">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <span class="hasomed-text-body">
              {{ row.description }}
            </span>
          </td>
        </ng-container>

        <!-- Onboarding column -->
        <ng-container matColumnDef="onboarding">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="center-header-cell">
            <span>Onboarding</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="onboarding">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="center-cell-text">
            <div
              class="label label-green"
              *ngIf="row.is_founded; else notFounded">
              <i class="fa-light fa-badge-check label-icon-sm"></i>
              <span class="label-text">Onboarding abgeschlossen</span>
            </div>
            <ng-template #notFounded>
              <div class="label label-white">
                <i class="fa-light fa-list-check label-icon-sm"></i>
                <span class="label-text">Im Onboarding</span>
              </div>
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <mat-progress-spinner
                color="primary"
                mode="indeterminate"
                diameter="32">
              </mat-progress-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Es wurde kein Institut gefunden.
            </div>
          </td>
        </tr>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
