import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function positiveDecimalNumbersValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const value = control.value;
    // Prüft, ob der Wert eine Zahl ist und größer als 0
    const isValid = !isNaN(value) && value > 0;
    return isValid
      ? null
      : { notPositiveDecimalNumber: { value: control.value } };
  };
}
