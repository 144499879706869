import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { Course } from 'src/app/models/course.model';
import { NavLink } from 'src/app/models/nav-link.model';
import { Feature, Permission, Role } from 'src/app/models/permission.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import {
  getCourseRoom,
  getEducationCourseTitles,
  getFullLecturerNames,
} from 'src/app/utils/course.utils';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrl: './course-details.component.scss',
})
export class CourseDetailsComponent implements OnInit, OnDestroy {
  @Input() public course: Course;
  public navLinks: NavLink[];
  public isLoading = true;
  public isStudent = this.userService.currentUser.id_role === Role.STUDENT;

  public Feature = Feature;
  public Permission = Permission;

  // Helper functions from course.utils.ts
  public getFullLecturerNames = getFullLecturerNames;
  public getEducationCourseTitles = getEducationCourseTitles;
  public getCourseRoom = getCourseRoom;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {
    this.navLinks = [
      {
        label: 'Termine & Anwesenheit',
        link: 'events',
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Beschreibung',
        link: 'description',
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Lehrpersonal',
        link: 'lecturers',
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Teilnehmer*innen',
        link: 'participants',
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Dokumente',
        link: 'documents',
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.VIEW,
      },
    ];
  }

  ngOnInit(): void {
    const id_course = +atob(this.activatedRoute.snapshot.paramMap.get('id'));

    this.courseService
      .getCourseById(id_course, 'educationCourses')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('courseDetails Backend response', response);
          if (!response.success) {
            console.error('Course details could not be loaded');
            this.isLoading = false;
            return;
          }
          this.course = await this.courseService.parseBackendCourse(
            response.data
          );
          this.isLoading = false;
        },
        error: error => {
          console.error('Course details could not be loaded', error);
          this.isLoading = false;
        },
      });
  }

  /**
   * onBackToOverview
   * navigate back to course overview
   */
  public onBackToOverview() {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onEditCourse
   * navigate to edit course
   */
  public onEditCourse() {
    this.router.navigate(['../edit/', btoa(this.course.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  public onCourseStudentChange(course: Course) {
    const id_student = this.userService.currentUser.id;
    const dialogTitle = course.registered ? 'Abmelden' : 'Anmelden';
    const dialogMessage = course.registered
      ? `Möchten Sie sich wirklich vom Kurs '${course.title}' abmelden?`
      : `Möchten Sie sich wirklich am Kurs '${course.title}' anmelden?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: dialogTitle,
        message: dialogMessage,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (!dialogResult) {
        return;
      }
      if (course.registered) {
        this.deregisterFromCourse(course, id_student);
      } else {
        this.registerToCourse(course, id_student);
      }
    });
  }

  private registerToCourse(course: Course, id_student: number) {
    this.courseService.registerToCourse(course.id, id_student).subscribe({
      next: response => {
        console.debug('Register to Course BackendData', response);
        if (response.success) {
          //this.getStudentsCourses();

          this.alertService.showSuccessAlert(
            `Angemeldet`,
            `Erfolgreich am Kurs '${course.title}' angemeldet!`
          );
        } else {
          this.alertService.showErrorAlert(
            'Fehler',
            `Beim anmelden am Kurs '${course.title}' ist ein Fehler aufgetreten!`
          );
        }
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Beim anmelden am Kurs '${course.title}' ist ein Fehler aufgetreten!`
        );
      },
    });
  }

  private deregisterFromCourse(course: Course, id_student: number) {
    this.courseService.deregisterFromCourse(course.id, id_student).subscribe({
      next: response => {
        console.debug('Deregister from Course BackendData:', response);
        if (response.success) {
          //this.getStudentsCourses();

          this.alertService.showSuccessAlert(
            'Abgemeldet',
            `Erfolgreich vom Kurs '${course.title}' abgemeldet!`
          );
        } else {
          this.alertService.showErrorAlert(
            'Fehler',
            `Beim Abmelden vom Kurs '${course.title}' ist ein Fehler aufgetreten!`
          );
        }
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Beim Abmelden vom Kurs '${course.title}' ist ein Fehler aufgetreten!`
        );
      },
    });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
