<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Eintrag ansehen</h1>
      <h3 class="hasomed-text-h3">Sehen Sie den Selbsterfahrungseintrag ein</h3>
    </div>
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onGoBack()">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onEditSelfAwarenessEntry()">
        <i class="fa-light fa-pen hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Daten bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <div class="formular-row">
      <label class="formular-row-label" for="title">
        <span class="label-title"> Titel </span>
        <span class="label-description"> Titel für den Eintrag </span>
      </label>
      <div class="flex-r align-center full-height">
        {{ selfAwarenessEntry?.title }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title"> Beschreibung </span>
        <span class="label-description"> Ergänzende Informationen </span>
      </label>
      <div class="hasomed-input-field justify-center full-height">
        {{ selfAwarenessEntry?.description ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dauer </span>
        <span class="label-description">
          Gesamtdauer des Eintrags in Stunden
        </span>
      </div>
      <div class="flex-r align-center full-height">
        {{ selfAwarenessEntry?.duration | floatToHoursMinutes }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="reponsiblePerson">
        <span class="label-title"> Verantwortliche*r </span>
      </label>
      <div class="flex-r align-center full-height">
        {{ selfAwarenessEntry?.responsiblePerson }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Datum </span>
        <span class="label-description"> Datum des Eintrags </span>
      </div>
      <div class="flex-r align-center full-height">
        {{ selfAwarenessEntry?.date | date: 'dd.MM.yyyy' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="note">
        <span class="label-title"> Freitext </span>
        <span class="label-description"> Freitext für interne Notizen </span>
      </label>
      <div class="hasomed-input-field justify-center full-height">
        {{ selfAwarenessEntry?.internalNote ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title"> Dokumente </span>
        <span class="label-description">
          Hier können Sie Dokumente für diesen Eintrag einsehen
        </span>
      </label>
      <div class="formular-row-input">
        <ng-container
          *ngIf="
            !selfAwarenessEntry?.files ||
            selfAwarenessEntry?.files?.length === 0
          ">
          <div class="card">Kein Dokument hochgeladen</div>
        </ng-container>
        <div class="flex-c flex-1 gap-8">
          <app-file-view
            *ngFor="let file of selfAwarenessEntry?.files"
            [file]="file"></app-file-view>
        </div>
      </div>
    </div>
  </div>
</div>
