<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Dokumentenübersicht</h1>
      <h3 class="hasomed-text-h3">Verwalten Sie Ihre Dokumente.</h3>
    </div>
    <form class="header-buttons" [formGroup]="searchForm">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          (keyup)="applySearch($event)"
          placeholder="Suche" />
      </div>

      <!-- <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        [ngClass]="{
          'hasomed-button-primary': hasActiveFilterValue(courseFilter, true),
          'hasomed-button-white-grey': !hasActiveFilterValue(
            courseFilter,
            true
          ),
          'hasomed-button-active': filterOpened
        }"
        [matMenuTriggerFor]="filterMenu"
        (onMenuOpen)="filterOpened = true"
        (onMenuClose)="filterOpened = false">
        <i class="fa-light fa-filter-list hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Filter</span>
      </button> -->

      <!-- Filter Menu -->
      <!-- <mat-menu class="filter-overlay" #filterMenu="matMenu">
        <app-filter
          *ngIf="filterOpened"
          [filters]="courseFilter"
          (filterChanged)="courseFilterChanged($event)"
          [courses]="courses">
        </app-filter>
      </mat-menu> -->

      <button
        *appCheckPermissions="{
          feature: Feature.DB_DOCUMENTS,
          permission: Permission.ADMIN
        }"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onUpload()">
        <i class="fa-light fa-file-plus hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Dokument hochladen</span>
      </button>
    </form>
  </div>

  <div class="main-content">
    <div
      class="flex-c table course-details-main"
      [class.table-bicolor]="dataSource?.filteredData?.length > 1">
      <table
        mat-table
        [dataSource]="dataSource"
        class="course-details-table"
        matSort>
        <!-- lecturer column / using firstname for sorting -->
        <ng-container matColumnDef="file">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Dateien
            <app-custom-table-sort-icon [sort]="sort" columnName="file">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="flex-r gap-8 align-center">
              <i class="fa-light fa-file"></i>

              <span>
                {{ row.filename }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- owner column -->
        <ng-container matColumnDef="creator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Eigentümer</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="creator">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.creator">
              {{ row.creator.name.academicTitle }}
              {{ row.creator.name.firstname }}
              {{ row.creator.name.lastname }}
            </span>

            <span *ngIf="!row.creator">nicht gespeichert</span>
          </td>
        </ng-container>

        <!-- mofiedDate column -->
        <ng-container matColumnDef="modifiedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Änderungsdatum
            <app-custom-table-sort-icon [sort]="sort" columnName="modifiedDate">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            {{
              (row.timeModified | date: 'dd.MM.yyyy') ??
                (row.timeCreated | date: 'dd.MM.yyyy')
            }}
          </td>
        </ng-container>

        <!-- fileSize column -->
        <ng-container matColumnDef="fileSize">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Dateigröße
            <app-custom-table-sort-icon [sort]="sort" columnName="fileSize">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ formatBytes(row.fileSize, 2) }}
          </td>
        </ng-container>

        <!-- fileType column -->
        <ng-container matColumnDef="fileType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Dateityp
            <app-custom-table-sort-icon [sort]="sort" columnName="fileType">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            <ng-container [ngSwitch]="row.fileType">
              <ng-container *ngSwitchCase="'pdf'">
                <div class="flex-r gap-8 align-center">
                  <i class="fa-light fa-file-pdf"></i>
                  <span>PDF-Dokument</span>
                </div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div class="flex-r gap-8 align-center">
                  <i class="fa-light fa-file"></i>
                  <span>{{ row.fileType.toUpperCase() }} -Datei</span>
                </div>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th class="center-header-cell" mat-header-cell *matHeaderCellDef>
            Aktionen
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="table-button-icons">
              <button
                type="button"
                class="table-button-icon"
                [matMenuTriggerFor]="actionsMenu"
                #t="matMenuTrigger"
                matTooltip="Datei Aktionen">
                <i class="hasomed-button-icon-sm fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button mat-menu-item type="button" (click)="onOpen(row)">
                  <div
                    class="flex-r gap-16 align-center action-menu-item gray-600">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600"> Anzeigen </span>
                  </div>
                </button>

                <mat-divider
                  class="menu-divider"
                  *appCheckPermissions="{
                    feature: Feature.DB_DOCUMENTS,
                    permission: Permission.ADMIN
                  }"></mat-divider>
                <button
                  type="button"
                  mat-menu-item
                  *appCheckPermissions="{
                    feature: Feature.DB_DOCUMENTS,
                    permission: Permission.ADMIN
                  }"
                  (click)="onEdit(row)">
                  <div
                    class="flex-r gap-16 align-center action-menu-item gray-600">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600"> Bearbeiten </span>
                  </div>
                </button>

                <mat-divider class="menu-divider"></mat-divider>
                <button mat-menu-item type="button" (click)="onDownload(row)">
                  <div
                    class="flex-r gap-16 align-center action-menu-item gray-600">
                    <i class="fa-light fa-cloud-arrow-down icon-sm"></i>
                    <span class="hasomed-text-body gray-600">
                      Herunterladen
                    </span>
                  </div>
                </button>

                <mat-divider
                  class="menu-divider"
                  *appCheckPermissions="{
                    feature: Feature.DB_DOCUMENTS,
                    permission: Permission.ADMIN
                  }"></mat-divider>
                <button
                  type="button"
                  mat-menu-item
                  *appCheckPermissions="{
                    feature: Feature.DB_DOCUMENTS,
                    permission: Permission.ADMIN
                  }"
                  (click)="onDelete(row)">
                  <div
                    class="flex-r gap-16 align-center action-menu-item gray-600">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600"> Löschen </span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="columnsToDisplay.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <mat-progress-spinner
                color="primary"
                mode="indeterminate"
                diameter="32">
              </mat-progress-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Es wurden keine Dateien gefunden.
            </div>
          </td>
        </tr>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
