import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  EducationCourse,
  Expertise,
  PolicyProcedure,
  ProfessionalAssociation,
  Regulation,
} from 'src/app/models/education-course.model';
import { AlertService } from 'src/app/services/alert.service';
import { EducationCourseService } from 'src/app/services/education-course.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { minNumberLength } from 'src/app/validators/min-number-length.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';

@Component({
  selector: 'app-create-edit-education-course',
  templateUrl: './create-edit-education-course.component.html',
  styleUrls: ['./create-edit-education-course.component.scss'],
})
export class CreateEditEducationCourseComponent {
  public educationCourseForm: FormGroup;
  public educationCourseId: string;
  public editMode: boolean = false;
  public initialFormValues: {};
  public formIsValid: boolean = false;
  public isLoading: boolean = true;

  // to access enum in template
  public Regulation = Regulation;
  public Expertise = Expertise;
  public ProfessionalAssociation = ProfessionalAssociation;
  public PolicyProcedure = PolicyProcedure;

  // import from form.utils.ts
  public isRequired = isRequired;

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.educationCourseForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private educationCourseService: EducationCourseService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService
  ) {}

  ngOnInit() {
    if (this.activatedRoute.snapshot.paramMap.get('id')) {
      this.educationCourseId = atob(
        this.activatedRoute.snapshot.paramMap.get('id')
      );
      this.editMode = true;
    }

    this.createForm();

    if (this.editMode) {
      this.educationCourseService
        .getEducationCourseByID(+this.educationCourseId)
        .subscribe({
          next: async response => {
            console.debug('edit get education course data', response);
            if (!response.success) {
              console.error(response.message);
              this.onCancel();
              return;
            }

            const educationCourse: EducationCourse =
              await this.educationCourseService.parseBackendEducationCourse(
                response.data
              );

            // put data into form
            this.educationCourseForm.patchValue(educationCourse);

            this.initialFormValues = this.educationCourseForm.value;
            this.isLoading = false;
          },
          error: error => {
            console.error(error);
          },
        });
    }

    this.initialFormValues = this.educationCourseForm.value;
  }

  /**
   * createForm creates the form and sets Validators
   */
  private createForm() {
    this.educationCourseForm = this.formBuilder.group({
      regulation: new FormControl(Regulation.EDUCATION),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      expertise: new FormControl(Expertise.ADULT),
      policy_procedure: new FormControl(PolicyProcedure.TP),
      professional_association: new FormControl(ProfessionalAssociation.NONE),
      accreditation_year: new FormControl(2000, [
        Validators.required,
        positiveNumbersOnlyValidator(false),
        maxNumberLength(4),
        minNumberLength(4),
      ]),
      chamber: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      internal_notes: new FormControl('', Validators.maxLength(6000)),
      external_notes: new FormControl('', Validators.maxLength(6000)),
      c_theoretical_scientific_h: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_theoretical_scientific_auto_training_h: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_theoretical_scientific_balintgroupwork_h: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_practical_1_h: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_practical_1_medical_history_survey_min: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_practical_2_h: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_treatment_internship_h: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_completed_treatment_cases: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_treatment_cases: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_treatment_cases_min: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_treatment_cases_min_h: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_supervision_h: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_supervision_single_session_h: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_supervision_group_session_h: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_supervision_medical_history_survey_min: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_self_experience_h: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
      c_self_experience_sessions_min: new FormControl(null, [
        positiveNumbersOnlyValidator(true),
      ]),
    });

    this.educationCourseForm.statusChanges.subscribe(status => {
      this.formIsValid = status === 'VALID';
      this.cdr.detectChanges();
    });

    // mark depentent fields as touched when parent field changes
    this.educationCourseForm
      .get('c_treatment_internship_h')
      .valueChanges.subscribe(value => {
        this.educationCourseForm.get('c_treatment_cases_min_h').markAsTouched();
      });

    this.educationCourseForm
      .get('c_treatment_cases')
      .valueChanges.subscribe(value => {
        this.educationCourseForm.get('c_treatment_cases_min').markAsTouched();
      });

    this.educationCourseForm
      .get('c_supervision_h')
      .valueChanges.subscribe(value => {
        this.educationCourseForm
          .get('c_supervision_single_session_h')
          .markAsTouched();
        this.educationCourseForm
          .get('c_supervision_group_session_h')
          .markAsTouched();
      });
  }

  /**
   * onCancel
   * navigates back to education course list
   * @returns void
   */
  public onCancel(): void {
    const route = this.editMode ? '../../' : '../';
    this.router.navigate([route], { relativeTo: this.activatedRoute });
  }

  /**
   * onSubmit
   * creates or updates an education course on submit
   * @returns void
   */
  public onSubmit(): void {
    // check if form is valid
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.educationCourseForm
      );

    if (formInvalid) {
      return;
    }

    this.editMode ? this.updateEducationCourse() : this.createEducationCourse();
    this.isLoading = true;
  }

  /**
   * createEducationCourse
   * creates a new education course
   * @returns void
   */
  private async createEducationCourse(): Promise<void> {
    const educationCourse: EducationCourse = this.buildEducationCourse();
    const createEducationCourseObservable =
      await this.educationCourseService.createEducationCourse(educationCourse);

    createEducationCourseObservable.subscribe({
      next: response => {
        console.debug('create education course data: ', response);

        if (!response.success) {
          if (response.message === 'educationCourse_exists') {
            this.alertService.showErrorAlert(
              'Existiert bereits',
              'Der Aus-/Weiterbildungsgang existiert bereits.'
            );
            this.isLoading = false;
            return;
          }
          this.alertService.showErrorAlert(
            'Fehler',
            'Der Aus-/Weiterbildungsgang konnte nicht erstellt werden.'
          );
          console.error(response.message);
          this.isLoading = false;
          return;
        }

        /* reset inititalFormValue to current value */
        this.initialFormValues = this.educationCourseForm.value;
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        this.alertService.showSuccessAlert(
          'Aus-/Weiterbildungsgang erstellt',
          'Der Aus-/Weiterbildungsgang wurde erfolgreich erstellt.'
        );
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          'Der Aus-/Weiterbildungsgang konnte nicht erstellt werden.'
        );
        this.isLoading = false;
      },
    });
  }

  /**
   * updateEducationCourse
   * updates an existing education course
   * @returns void
   */
  private async updateEducationCourse(): Promise<void> {
    let educationCourse: EducationCourse = this.buildEducationCourse();
    const updateEducationCourseObservable =
      await this.educationCourseService.updateEducationCourse(
        +this.educationCourseId,
        educationCourse
      );
    updateEducationCourseObservable.subscribe({
      next: response => {
        console.debug('update education course data: ', response);
        if (!response.success) {
          this.alertService.showErrorAlert(
            'Fehler',
            'Der Aus-/Weiterbildungsgang konnte nicht bearbeitet werden.'
          );
          console.error(response.message);
          this.isLoading = false;
          return;
        }
        /* reset inititalFormValue to current value */
        this.initialFormValues = this.educationCourseForm.value;
        this.router.navigate(['../../'], {
          relativeTo: this.activatedRoute,
        });
        this.alertService.showSuccessAlert(
          'Aus-/Weiterbildungsgang bearbeitet',
          'Der Aus-/Weiterbildungsgang wurde erfolgreich bearbeitet.'
        );
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          'Der Aus-/Weiterbildungsgang konnte nicht bearbeitet werden.'
        );
        this.isLoading = false;
      },
    });
  }

  /**
   * buildEducationCourse
   * builds an education course object from the form data
   * @returns EducationCourse
   */
  private buildEducationCourse(): EducationCourse {
    const id_institute = this.userService.currentUser.id_institute;
    const educationCourse: EducationCourse = {
      id_institute: id_institute,
      regulation: this.educationCourseForm.get('regulation').value,
      title: this.educationCourseForm.get('title').value,
      expertise: this.educationCourseForm.get('expertise').value,
      policy_procedure: this.educationCourseForm.get('policy_procedure').value,
      professional_association: this.educationCourseForm.get(
        'professional_association'
      ).value,
      accreditation_year:
        this.educationCourseForm.get('accreditation_year').value,
      chamber: this.educationCourseForm.get('chamber').value,
      internal_notes: this.educationCourseForm.get('internal_notes').value,
      external_notes: this.educationCourseForm.get('external_notes').value,
      c_theoretical_scientific_h: this.educationCourseForm.get(
        'c_theoretical_scientific_h'
      ).value,
      c_theoretical_scientific_auto_training_h: this.educationCourseForm.get(
        'c_theoretical_scientific_auto_training_h'
      ).value,
      c_theoretical_scientific_balintgroupwork_h: this.educationCourseForm.get(
        'c_theoretical_scientific_balintgroupwork_h'
      ).value,
      c_practical_1_h: this.educationCourseForm.get('c_practical_1_h').value,
      c_practical_1_medical_history_survey_min: this.educationCourseForm.get(
        'c_practical_1_medical_history_survey_min'
      ).value,
      c_practical_2_h: this.educationCourseForm.get('c_practical_2_h').value,
      c_treatment_internship_h: this.educationCourseForm.get(
        'c_treatment_internship_h'
      ).value,
      c_completed_treatment_cases: this.educationCourseForm.get(
        'c_completed_treatment_cases'
      ).value,
      c_treatment_cases:
        this.educationCourseForm.get('c_treatment_cases').value,
      c_treatment_cases_min: this.educationCourseForm.get(
        'c_treatment_cases_min'
      ).value,
      c_treatment_cases_min_h: this.educationCourseForm.get(
        'c_treatment_cases_min_h'
      ).value,
      c_supervision_h: this.educationCourseForm.get('c_supervision_h').value,
      c_supervision_single_session_h: this.educationCourseForm.get(
        'c_supervision_single_session_h'
      ).value,
      c_supervision_group_session_h: this.educationCourseForm.get(
        'c_supervision_group_session_h'
      ).value,
      c_supervision_medical_history_survey_min: this.educationCourseForm.get(
        'c_supervision_medical_history_survey_min'
      ).value,
      c_self_experience_h: this.educationCourseForm.get('c_self_experience_h')
        .value,
      c_self_experience_sessions_min: this.educationCourseForm.get(
        'c_self_experience_sessions_min'
      ).value,
    };
    return educationCourse;
  }

  /**
   * set Regulation
   * @param regulation
   */
  public setRegulation(regulation: Regulation) {
    this.educationCourseForm.get('regulation').setValue(regulation);
  }

  /**
   * set Expertise
   * @param expertise
   */
  public setExpertise(expertise: Expertise) {
    this.educationCourseForm.get('expertise').setValue(expertise);
  }

  /**
   * setPolicyProcedure
   * @param policyProdure PolicyProcedure
   */
  public setPolicyProcedure(policyProdure: PolicyProcedure) {
    this.educationCourseForm.get('policy_procedure').setValue(policyProdure);
  }

  /**
   * set ProfessionalAssociation
   * @param professionalAssociation ProfessionalAssociation
   */
  public setPAA(professionalAssociation: ProfessionalAssociation) {
    this.educationCourseForm
      .get('professional_association')
      .setValue(professionalAssociation);
  }

  /**
   * Preserve the original enum order
   */
  public originalRegulationOrder = (
    a: KeyValue<string, Regulation>,
    b: KeyValue<string, Regulation>
  ): number => {
    return 0;
  };

  /**
   * Preserve the original enum order
   */
  public originalExpertiseOrder = (
    a: KeyValue<string, Expertise>,
    b: KeyValue<string, Expertise>
  ): number => {
    return 0;
  };

  public originalPolicyProcedureOrder = (
    a: KeyValue<string, PolicyProcedure>,
    b: KeyValue<string, PolicyProcedure>
  ): number => {
    return 0;
  };

  public originalPaaOrder = (
    a: KeyValue<string, ProfessionalAssociation>,
    b: KeyValue<string, ProfessionalAssociation>
  ): number => {
    return 0;
  };

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.educationCourseForm.value,
      this.initialFormValues
    );
  }
}
