<div class="flex-c flex-grow">
  <div
    class="flex-c table course-details-main"
    [class.table-bicolor]="dataSource?.filteredData?.length > 1">
    <table
      mat-table
      [dataSource]="dataSource"
      class="course-details-table"
      matSort>
      <!-- lecturer column / using firstname for sorting -->
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Kandidat*innen
          <app-custom-table-sort-icon [sort]="sort" columnName="user">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="user-container">
            <div class="user-icon">
              <img
                [attr.src]="
                  row.profilePicture ?? '../../../../../assets/img/user.jpg'
                "
                alt=""
                class="user-icon-image" />
            </div>
            <div class="user-info">
              <span class="gray-900 hasomed-text-body">
                {{ row.name.academicTitle }}
                {{ row.name.firstname }}
                {{ row.name.lastname }}
              </span>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- userIdentifier column -->
      <ng-container matColumnDef="userIdentifier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Bildungs-ID
          <app-custom-table-sort-icon [sort]="sort" columnName="userIdentifier">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.userIdentifier }}
        </td>
      </ng-container>

      <!-- Education progress column -->
      <ng-container matColumnDef="educationProgress">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="width: 500px"
          mat-sort-header>
          <span>Ausbildungsfortschritt</span>
          <app-custom-table-sort-icon
            [sort]="sort"
            columnName="educationProgress">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let row" style="width: 500px">
          <div class="education_progress">
            <div class="progress-container">
              <div
                class="progress-bar"
                ngClass="progress-orange"
                [ngStyle]="{
                  width: getTotalEducationProgress(row) + '%'
                }"></div>
            </div>
            <span
              class="progress-label"
              [ngClass]="getTotalEducationProgress(row) < 10 ? 'text-red' : ''">
              {{ getTotalEducationProgress(row) }}%
            </span>
          </div>
        </td>
      </ng-container>

      <!-- entryDate column -->
      <ng-container matColumnDef="entryDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Eintrittsdatum
          <app-custom-table-sort-icon [sort]="sort" columnName="entryDate">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ (row.entryDate | date: 'dd.MM.yyyy') ?? '-' }}
        </td>
      </ng-container>

      <!-- label column -->
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Etikett
          <app-custom-table-sort-icon [sort]="sort" columnName="label">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let row">
          <div
            *ngIf="row.status"
            class="label"
            [style.background]="row.status.color">
            <i class="fa-light fa-id-badge label-icon"></i>
            <span class="label-text">{{ row.status.name }}</span>
          </div>
          <span *ngIf="!row.status" class="hasomed-text-body">-</span>
        </td>
      </ng-container>

      <!-- action column -->
      <ng-container matColumnDef="actions">
        <th class="center-header-cell" mat-header-cell *matHeaderCellDef>
          Aktionen
        </th>
        <td class="center-cell-text" mat-cell *matCellDef="let row">
          <div class="table-button-icons">
            <button
              type="button"
              class="table-button-icon"
              matTooltip="Details anzeigen"
              (click)="showParticipantDetails(row)">
              <i class="fa-light fa-arrow-up-right-from-square"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td [attr.colspan]="columnsToDisplay.length">
          <div *ngIf="isLoading" class="loading-wrapper">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate"
              diameter="32">
            </mat-progress-spinner>
          </div>
          <div
            *ngIf="!isLoading"
            class="mat-cell table-no-match hasomed-text-body font-grey">
            Es wurden keine Teilnehmer*innen gefunden.
          </div>
        </td>
      </tr>
    </table>

    <mat-divider></mat-divider>
    <mat-paginator
      custom-paginator
      [appCustomLength]="dataSource.filteredData.length"
      [pageSize]="5"
      [renderButtonsNumber]="1"
      aria-label="Select page"></mat-paginator>
  </div>
  <div class="vertical-spacer"></div>
</div>
