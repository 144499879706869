<div mat-dialog-title>
  <div class="flex-r justify-space-between align-center">
    <span>Feedback und Support</span>
    <button
      type="button"
      class="hasomed-button-icon close-icon"
      matTooltip="Schließen"
      (click)="this.dialogRef.close()">
      <i class="fa-light fa-x"></i>
    </button>
  </div>
</div>
<div mat-dialog-content>
  <span class="hasomed-text-body">
    Teilen Sie Ihr Feedback einfach und unverbindlich per E-Mail mit oder rufen
    uns an unter 03916107647.
  </span>
</div>

<div mat-dialog-actions class="action-buttons">
  <button
    type="button"
    class="hasomed-text-button hasomed-button-primary"
    (click)="onConfirm()">
    <i class="hasomed-button-icon-sm fa-light fa-envelope"></i>
    <span class="hasomed-button-text">E-Mail senden</span>
  </button>
</div>
