import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function chiffreValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const pattern = new RegExp('^[A-Z]\\d{6}$');
    const valid = pattern.test(control.value);

    return valid ? null : { invalidChiffre: true };
  };
}
