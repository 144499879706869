<div class="component-content">
  <div class="header">
    <div class="header-title">
      <div *ngIf="isLoading" class="loading-wrapper">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate"
          diameter="50">
        </mat-progress-spinner>
      </div>
      <div class="user-container-header" *ngIf="!isLoading">
        <div class="user-icon">
          <img
            src="{{
              lecturer.profilePicture ?? '../../../../../assets/img/user.jpg'
            }}"
            alt=""
            class="user-icon-image" />
        </div>
        <div class="user-info">
          <h1 class="hasomed-text-h1">
            {{ lecturer.name.academicTitle }} {{ lecturer.name.firstname }}
            {{ lecturer.name.lastname }}
          </h1>
          <h3 class="user-info hasomed-text-h3">
            {{ lecturer.status ? lecturer.status.name : '-' }}
          </h3>
        </div>
      </div>
    </div>
    <div class="header-buttons">
      <button
        class="hasomed-text-button hasomed-button-white-red"
        (click)="onTerminateLecturerContract()">
        <i class="fa-light fa-circle-xmark hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Lehrpersonalvertrag auflösen</span>
      </button>

      <button
        class="hasomed-text-button hasomed-button-primary"
        (click)="goBack()">
        <i class="fa-light fa-rotate-left hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Zurück zur Übersicht</span>
      </button>
    </div>
  </div>

  <div class="main-content">
    <div class="flex-r gap-16">
      <div class="flex-c flex-1">
        <div class="table">
          <table mat-table [dataSource]="dataSource" matSort>
            <!-- Course title column -->
            <ng-container matColumnDef="courseTitle">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>Kurs</span>
                <app-custom-table-sort-icon
                  [sort]="sort"
                  columnName="courseTitle">
                </app-custom-table-sort-icon>
              </th>
              <td mat-cell *matCellDef="let course" class="title-cell">
                <div class="flex-c cell-overflow-hidden">
                  <span
                    class="hasomed-text-body text-overflow-hidden"
                    [matTooltip]="course.title">
                    {{ course.title }}
                  </span>
                </div>
              </td>
            </ng-container>

            <!-- Dates column -->
            <ng-container matColumnDef="dates">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="center-header-cell">
                <span>Kurstermine</span>
                <app-custom-table-sort-icon [sort]="sort" columnName="dates">
                </app-custom-table-sort-icon>
              </th>
              <td mat-cell *matCellDef="let course" class="center-cell-text">
                <span> {{ course.startDate | date: 'dd.MM.yyyy' }} </span>
                <span *ngIf="course.startDate != course.endDate">
                  - {{ course.endDate | date: 'dd.MM.yyyy' }}
                </span>
              </td>
            </ng-container>

            <!-- eLog Status column -->
            <ng-container matColumnDef="eLogStatus">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="center-header-cell">
                <span>Anwesenheit</span>
                <app-custom-table-sort-icon
                  [sort]="sort"
                  columnName="eLogStatus">
                </app-custom-table-sort-icon>
              </th>
              <td mat-cell *matCellDef="let course">
                <div class="elog_status_cell">
                  <app-elog-cell
                    [eLogStatusCounts]="
                      course.eLogStatusCounts
                    "></app-elog-cell>
                  <button
                    type="button"
                    class="table-button-icon"
                    matTooltip="Anwesenheitsstatus bearbeiten"
                    (click)="openELog(course.id)">
                    <i class="fa-light fa-pen-to-square"></i>
                  </button>
                </div>
              </td>
            </ng-container>

            <!-- Edit column -->
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let course">
                <div class="table-button-icons">
                  <button
                    type="button"
                    class="table-button-icon"
                    matTooltip="Anwesenheitsstatus bearbeiten"
                    (click)="openELog(course.id)">
                    <i class="fa-light fa-arrow-up-right-from-square"></i>
                  </button>
                  <button
                    type="button"
                    class="table-button-icon"
                    matTooltip="Lehrpersonal aus Kurs entfernen"
                    (click)="deleteCourse(course.id)">
                    <i class="fa-light fa-trash-can"></i>
                  </button>
                  <button
                    type="button"
                    class="table-button-icon"
                    matTooltip="Anwesenheitsstatus bearbeiten"
                    (click)="openELog(course.id)">
                    <i class="fa-light fa-pen"></i>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td [attr.colspan]="displayedColumns.length">
                <div *ngIf="isLoading" class="loading-wrapper">
                  <mat-progress-spinner
                    color="primary"
                    mode="indeterminate"
                    diameter="32">
                  </mat-progress-spinner>
                </div>
                <div
                  *ngIf="!isLoading"
                  class="mat-cell table-no-match hasomed-text-body font-grey">
                  Es wurden keine Kurse gefunden.
                </div>
              </td>
            </tr>
          </table>
          <mat-divider></mat-divider>
          <mat-paginator
            custom-paginator
            [appCustomLength]="dataSource.filteredData.length"
            [pageSize]="10"
            [renderButtonsNumber]="1"
            aria-label="Select page"></mat-paginator>
        </div>
        <div class="vertical-spacer"></div>
      </div>

      <!-- Lecturer card -->
      <div class="lecturer-card" *ngIf="!isLoading">
        <span class="heading hasomed-text-small"> Lehrpersonal seit </span>
        <span class="content hasomed-text-body">
          {{
            lecturer.entryDate ? (lecturer.entryDate | date: 'dd.MM.YYYY') : '-'
          }}
        </span>

        <span class="heading hasomed-text-small"> Lehrpersonalnummer </span>
        <div class="content">
          <span class="hasomed-text-body">
            {{ lecturer.userIdentifier ? lecturer.userIdentifier : '-' }}
          </span>
          <app-copy-to-clipboard
            *ngIf="lecturer.userIdentifier"
            type="Matrikelnummer"
            [textToCopy]="lecturer.userIdentifier">
          </app-copy-to-clipboard>
        </div>

        <span class="heading hasomed-text-small"> Anschrift </span>
        <div class="content">
          <div *ngIf="!lecturer.address" class="adresse-content">-</div>
          <div *ngIf="lecturer.address" class="adresse-content">
            <span class="hasomed-text-body">
              {{ lecturer.address.street }} {{ lecturer.address.houseNumber }}
            </span>
            <span class="hasomed-text-body">
              {{ lecturer.address.zipCode }} {{ lecturer.address.city }}
            </span>
          </div>
          <app-copy-to-clipboard
            *ngIf="lecturer.address"
            type="Adresse"
            [textToCopy]="
              (lecturer.address.street ?? '') +
              ' ' +
              (lecturer.address.houseNumber ?? '') +
              ' ' +
              (lecturer.address.zipCode ?? '') +
              ' ' +
              (lecturer.address.city ?? '')
            ">
          </app-copy-to-clipboard>
        </div>

        <span class="heading hasomed-text-small">Email Adresse</span>
        <div class="content">
          <span class="hasomed-text-body">{{ lecturer.email }}</span>
          <app-copy-to-clipboard
            *ngIf="lecturer.email"
            type="Email Adresse"
            [textToCopy]="lecturer.email">
          </app-copy-to-clipboard>
        </div>

        <span class="heading hasomed-text-small">Geburtsdatum</span>
        <div class="content">
          <span class="hasomed-text-body">
            {{
              lecturer.birthdate
                ? (lecturer.birthdate | date: 'dd.MM.YYYY')
                : '-'
            }}
          </span>
        </div>

        <span class="heading hasomed-text-small"> Etikett </span>
        <div class="label-container">
          <div
            *ngIf="lecturer.status"
            class="label"
            [style.background]="lecturer.status.color">
            <i class="fa-light fa-user label-icon"></i>
            <span class="label-text">{{ lecturer.status.name }}</span>
          </div>
          <span *ngIf="!lecturer.status" class="hasomed-text-body">-</span>
        </div>

        <button
          *appCheckPermissions="{
            feature: feature.EDIT_MASTER_DATA,
            permission: permission.ADMIN
          }"
          type="button"
          class="hasomed-text-button hasomed-button-white-grey justify-center"
          (click)="openEditMasterDataDialog()">
          <i class="fa-light fa-pen-to-square hasomed-button-icon-sm"></i>
          <span class="hasomed-button-text">Stammdaten bearbeiten</span>
        </button>
      </div>
    </div>
  </div>
</div>
