import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { EditMasterDataDialogComponent } from 'src/app/components/shared-components/edit-master-data-dialog/edit-master-data-dialog.component';
import { EducationProgressDialogComponent } from 'src/app/components/shared-components/education-progress-dialog/education-progress-dialog.component';
import { BankDetails } from 'src/app/models/bank-details.model';
import {
  ProgressType,
  StudentEducationProgress,
} from 'src/app/models/education-progress.model';
import { eLogStatusCounts } from 'src/app/models/elog.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { User } from 'src/app/models/user.model';
import { BankDetailsService } from 'src/app/services/bank-details.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EducationProgressService } from 'src/app/services/education-progress.service';
import { UserService } from 'src/app/services/user.service';

export interface StudentDetailTableData {
  id_course: number;
  courseTitle: string;
  registered: boolean;
  eLogStatusCounts: eLogStatusCounts;
}

@Component({
  selector: 'app-course-elog',
  templateUrl: './course-elog.component.html',
  styleUrls: ['./course-elog.component.scss'],
})
export class CourseElogComponent implements OnInit, OnDestroy {
  public student: User;
  public educationProgress: StudentEducationProgress[];
  public totalEducationProgress: StudentEducationProgress;
  public showMore: boolean = false;

  public displayedColumns: string[] = [
    'courseTitle',
    'registered',
    'eLogStatus',
    'actions',
  ];
  public bankDetails: BankDetails;
  public dataSource: MatTableDataSource<StudentDetailTableData> =
    new MatTableDataSource();
  public tableData: Subject<StudentDetailTableData[]> = new Subject();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /* for permission */
  feature = Feature;
  permission = Permission;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private bankDetailsService: BankDetailsService,
    private educationProgressService: EducationProgressService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.activatedRoute.parent?.paramMap.subscribe(params => {
      const id_user = +atob(params.get('id'));
      this.getStudent(id_user);
      this.getBankDetails(id_user);
      this.getEducationProgress(id_user);
    });

    this.tableData.subscribe((courses: StudentDetailTableData[]) => {
      this.dataSource = new MatTableDataSource<StudentDetailTableData>(courses);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'courseTitle':
            return item.courseTitle;
          case 'eLogStatus':
            // sort failed on top, followed by checked and then followed by pending
            if (item.eLogStatusCounts.pending > 0) {
              return item.eLogStatusCounts.pending * 1000;
            } else if (item.eLogStatusCounts.upcoming > 0) {
              return item.eLogStatusCounts.upcoming * 100;
            } else if (item.eLogStatusCounts.checked > 0) {
              return item.eLogStatusCounts.checked * 10;
            } else {
              return item.eLogStatusCounts.pending * 10;
            }
          default:
            return item[property];
        }
      };
      this.isLoading = false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  /**
   * getStudent
   * @description get student data from backend
   * @param user_id id of the student
   * @returns void
   */
  private getStudent(user_id: number): void {
    this.userService
      .getUserById(user_id, 'courses,educationCourse')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getUserById Backend response', response);
          if (!response.success) {
            console.error(response.message);
            this.isLoading = false;
            return;
          }
          this.student = await this.userService.parseBackendUser(response.data);
          this.tableData.next(
            response.data.courses?.map(
              (courseData: any): StudentDetailTableData => {
                return {
                  id_course: courseData.id,
                  courseTitle: courseData.title,
                  registered: true,
                  eLogStatusCounts: courseData.eLogStatusCounts,
                };
              }
            )
          );
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  private getBankDetails(id_user: number) {
    this.bankDetailsService
      .getUserBankDetails(id_user)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('bank details backend response', response);
          if (response.success) {
            this.bankDetails = response.data
              ? await this.bankDetailsService.parseBackendBankDetails(
                  response.data?.bankDetails
                )
              : null;
          }
        },
        error: err => {
          console.error(err);
          // this.alertService.showErrorAlert(
          //   'Bankverbindung nicht geladen.',
          //   'Bankverbindung konnte nicht geladen werden.'
          // );
        },
      });
  }

  /**
   * onChangeInstitut
   * @description change the institute of the student
   * @returns void
   */
  public onChangeInstitut(): void {
    // Placeholder
    console.log('change institut');
  }

  /**
   * showProgressDetails
   * show the progress details of the student
   * @returns void
   */
  public showProgressDetails(): void {
    this.dialog.open(EducationProgressDialogComponent, {
      data: {
        user: this.student,
        educationProgress: this.educationProgress,
        showName: true,
      },
    });
  }

  /**
   * getEducationProgress
   * get the education progress of the student
   * @returns void
   */
  public getEducationProgress(id_user): void {
    this.educationProgressService
      .getEducationProgress(id_user)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          console.debug('getEducationProgress backend response', response);

          if (!response.success) {
            if (response.message === 'user has no education course') {
              this.educationProgress = [];
              this.totalEducationProgress = null;
              return;
            }
            console.error(response.message);
            return;
          }

          this.educationProgress = response.data;

          this.totalEducationProgress = this.educationProgress.find(
            (progress: StudentEducationProgress) =>
              progress.type === ProgressType.TOTAL
          );
        },
        error: error => {
          console.error(error);
        },
      });
  }

  /**
   * openELog
   * @description open the eLog page
   * @param course_id
   * @returns void
   */
  public openELog(course_id: number): void {
    this.router.navigate(['/eleguide/education/elog'], {
      queryParams: {
        course_id: btoa(String(course_id)),
        student_id: btoa(String(this.student.id)),
      },
    });
  }

  /**
   * deleteCourse
   * @description delete the course
   * @param course_id
   * @returns void
   */
  public deleteCourse(course_id: number): void {
    console.log('deleteCourse');
  }

  /**
   * openEditMasterDataDialog
   * opens a dialog to edit the master data of the student
   * @returns void
   */
  public openEditMasterDataDialog(): void {
    this.dialog.open(EditMasterDataDialogComponent, {
      data: {
        user: this.student,
        isStudent: true,
        bankDetails: this.bankDetails,
      },
    });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
