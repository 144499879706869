<div class="component-content">
  <div class="header">
    <div class="header-title">
      <span class="hasomed-text-h1">Räume verwalten</span>
      <span class="hasomed-text-h3">Verwalten Sie die Räume des Instituts</span>
    </div>
    <div class="header-buttons">
      <!-- Filter Button -->
      <button
        class="hasomed-text-button hasomed-button-white-grey"
        [ngClass]="{
          'hasomed-button-primary': hasActiveFilterValue(roomFilter, true),
          'hasomed-button-white-grey': !hasActiveFilterValue(roomFilter, true),
          'hasomed-button-active': filterOpened
        }"
        [matMenuTriggerFor]="filterMenu"
        (onMenuOpen)="filterOpened = true"
        (onMenuClose)="filterOpened = false">
        <i class="fa-light fa-filter-list hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Filter</span>
      </button>

      <!-- Filter Menu -->
      <mat-menu class="filter-overlay" #filterMenu="matMenu">
        <app-filter
          *ngIf="filterOpened"
          [filters]="roomFilter"
          (filterChanged)="roomFilterChanged($event)">
        </app-filter>
      </mat-menu>

      <button
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="goBackToRoomOrganization()">
        <i class="fa-light fa-rotate-left hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Zurück zur Raumorganisation</span>
      </button>

      <!-- Add room button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onCreateNewRoom()">
        <i class="hasomed-button-icon-sm fa-light fa-square-plus"></i>
        <span class="hasomed-button-text">Raum hinzufügen</span>
      </button>
    </div>
  </div>

  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="roomFilter"
      (filterChanged)="roomFilterChanged($event)">
    </app-filter-tags>

    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Name column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Bezeichnung</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="name">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let room">
            <span class="hasomed-text-body">
              {{ room.name }}
            </span>
          </td>
        </ng-container>

        <!-- Floor column -->
        <ng-container matColumnDef="floor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Etage</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="floor">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let room">
            <span class="hasomed-text-body">
              {{
                room.floor === 0
                  ? 'EG'
                  : room.floor
                    ? room.floor + '. OG'
                    : 'Unbekannte Etage'
              }}
            </span>
          </td>
        </ng-container>

        <!-- Building column -->
        <ng-container matColumnDef="building">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Gebäude</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="building">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let room">
            <span class="hasomed-text-body">
              {{ room.building }}
            </span>
          </td>
        </ng-container>

        <!-- seatNumber column -->
        <ng-container matColumnDef="seatNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Platzanzahl</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="seatNumber">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let room">
            <span class="hasomed-text-body">
              {{ room.seatNumber }}
            </span>
          </td>
        </ng-container>

        <!-- Equipment column -->
        <ng-container matColumnDef="equipment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Ausstattung</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="equipment">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let room">
            <span class="hasomed-text-body">
              {{ room.equipment || '-' }}
            </span>
          </td>
        </ng-container>

        <!-- Available column -->
        <ng-container matColumnDef="available">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Aktiv?</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="available">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let room">
            <mat-slide-toggle
              hideIcon
              (change)="onRoomActiveChanged($event, room)"
              [checked]="room.available"
              [matTooltip]="
                room.available ? 'Raum deaktivieren' : 'Raum aktivieren'
              ">
            </mat-slide-toggle>
          </td>
        </ng-container>

        <!-- Edit column -->
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let room">
            <div class="table-button-icons">
              <button
                type="button"
                class="table-button-icon"
                matTooltip="Raum löschen"
                (click)="onDeleteRoom(room)">
                <i class="fa-light fa-trash-can"></i>
              </button>
              <button
                type="button"
                class="table-button-icon"
                matTooltip="Raum bearbeiten"
                (click)="onEditRoom(room.id)">
                <i class="fa-light fa-pen"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <mat-progress-spinner
                color="primary"
                mode="indeterminate"
                diameter="32">
              </mat-progress-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="table-no-match hasomed-text-body font-grey">
              <span>Es wurden keine Räume gefunden</span>
            </div>
          </td>
        </tr>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
