<div *ngIf="!eLogStatus && !eLogStatusCounts" class="label label-white">
  <i class="fa-light fa-circle-exclamation label-icon"></i>
  <span class="hasomed-text-small label-text">
    Keine {{ !shortText ? 'Anwesenheiten' : '' }} gefunden
  </span>
</div>
<ng-container *ngIf="eLogStatus">
  <div class="label label-gray-300" *ngIf="eLogStatus === eLog.UPCOMING">
    <i class="fa-light fa-circle-calendar label-icon"></i>
    <span class="hasomed-text-small label-text"> offen </span>
  </div>
  <div class="label label-white" *ngIf="eLogStatus === eLog.PENDING">
    <i class="fa-light fa-circle-dashed label-icon"></i>
    <span class="hasomed-text-small label-text"> ausstehend </span>
  </div>
  <div class="label label-green" *ngIf="eLogStatus === eLog.CHECKED">
    <i class="fa-light fa-circle-check label-icon"></i>
    <span class="hasomed-text-small label-text"> geprüft </span>
  </div>
  <div class="label label-yellow" *ngIf="eLogStatus === eLog.ABSENT">
    <i class="fa-light fa-circle-dot label-icon"></i>
    <span class="hasomed-text-small label-text"> abwesend </span>
  </div>
  <div class="label label-yellow" *ngIf="eLogStatus === eLog.EXCUSED">
    <i class="fa-light fa-circle-minus label-icon"></i>
    <span class="hasomed-text-small label-text"> entschuldigt </span>
  </div>
  <div class="label label-red" *ngIf="eLogStatus === eLog.UNEXCUSED">
    <i class="fa-light fa-circle-xmark label-icon"></i>
    <span class="hasomed-text-small label-text"> nicht entschuldigt </span>
  </div>
</ng-container>
<ng-container *ngIf="eLogStatusCounts">
  <!-- Checks if all elogs are checked -->
  <div
    *ngIf="
      eLogStatusCounts.checked > 0 &&
        eLogStatusCounts.absent == 0 &&
        eLogStatusCounts.excused == 0 &&
        eLogStatusCounts.unexcused == 0 &&
        eLogStatusCounts.pending == 0 &&
        eLogStatusCounts.upcoming == 0;
      else pendingElogs
    "
    class="label label-green">
    <i class="fa-light fa-circle-check label-icon"></i>
    <span class="hasomed-text-small label-text">
      {{ !shortText ? 'Anwesenheit' : '' }} vollständig
    </span>
  </div>
  <ng-template #pendingElogs>
    <div
      *ngIf="eLogStatusCounts?.pending > 0; else upcomingElogs"
      class="label label-white">
      <i class="fa-light fa-circle-dashed label-icon"></i>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.pending > 1">
        {{ eLogStatusCounts?.pending }}
        {{ !shortText ? 'Anwesenheiten' : '' }} ausstehend
      </span>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.pending == 1">
        {{ eLogStatusCounts?.pending }}
        {{ !shortText ? 'Anwesenheit' : '' }} ausstehend
      </span>
    </div>
  </ng-template>
  <ng-template #upcomingElogs>
    <div
      class="label label-gray-300"
      *ngIf="eLogStatusCounts?.upcoming > 0; else notExcusedElogs">
      <i class="fa-light fa-circle-calendar label-icon"></i>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.upcoming > 1">
        {{ eLogStatusCounts?.upcoming }}
        {{ !shortText ? 'Anwesenheiten' : '' }} offen
      </span>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.upcoming == 1">
        {{ eLogStatusCounts?.upcoming }}
        {{ !shortText ? 'Anwesenheit' : '' }} offen
      </span>
    </div>
  </ng-template>
  <ng-template #notExcusedElogs>
    <div
      *ngIf="eLogStatusCounts.unexcused > 0; else checkedElogs"
      class="label label-red">
      <i class="fa-light fa-circle-xmark label-icon"></i>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.unexcused > 1">
        {{ eLogStatusCounts?.unexcused }}
        {{ !shortText ? 'Anwesenheiten' : '' }} nicht entschuldigt
      </span>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.unexcused == 1">
        {{ eLogStatusCounts?.unexcused }}
        {{ !shortText ? 'Anwesenheit' : '' }} nicht entschuldigt
      </span>
    </div>
  </ng-template>
  <ng-template #checkedElogs>
    <div
      *ngIf="eLogStatusCounts?.checked > 0; else excusedElogs"
      class="label label-green">
      <i class="fa-light fa-circle-check label-icon"></i>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.checked > 1">
        {{ eLogStatusCounts?.checked }}
        {{ !shortText ? 'Anwesenheiten' : '' }} vollständig
      </span>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.checked == 1">
        {{ eLogStatusCounts?.checked }}
        {{ !shortText ? 'Anwesenheit' : '' }} vollständig
      </span>
    </div>
  </ng-template>
  <ng-template #excusedElogs>
    <div
      *ngIf="eLogStatusCounts?.excused > 0; else absentElogs"
      class="label label-yellow">
      <i class="fa-light fa-circle-minus label-icon"></i>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.excused > 1">
        {{ eLogStatusCounts?.excused }}
        {{ !shortText ? 'Anwesenheiten' : '' }} entschuldigt
      </span>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.excused == 1">
        {{ eLogStatusCounts?.excused }}
        {{ !shortText ? 'Anwesenheit' : '' }} entschuldigt </span
      >å
    </div>
  </ng-template>
  <ng-template #absentElogs>
    <div
      *ngIf="eLogStatusCounts?.absent > 0; else noElogs"
      class="label label-yellow">
      <i class="fa-light fa-circle-dot label-icon"></i>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.absent > 1">
        {{ eLogStatusCounts?.absent }}
        {{ !shortText ? 'Anwesenheiten' : '' }} abwesend
      </span>
      <span
        class="hasomed-text-small label-text"
        *ngIf="eLogStatusCounts?.absent == 1">
        {{ eLogStatusCounts?.absent }}
        {{ !shortText ? 'Anwesenheit' : '' }} abwesend
      </span>
    </div>
  </ng-template>
  <ng-template #noElogs>
    <div class="label label-white">
      <i class="fa-light fa-circle-exclamation label-icon"></i>
      <span class="hasomed-text-small label-text">
        Keine {{ !shortText ? 'Anwesenheiten' : '' }} gefunden
      </span>
    </div>
  </ng-template>
</ng-container>
