<div class="dialog">
  <button
    type="button"
    class="hasomed-button-icon close-icon"
    matTooltip="Schließen"
    (click)="this.dialogRef.close()">
    <i class="fa-light fa-x"></i>
  </button>
  <app-empty-state></app-empty-state>
</div>
