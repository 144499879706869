<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Aus- und Weiterbildungsgänge</h1>
      <h3 class="hasomed-text-h3">
        Anlegen oder Bearbeiten von Aus- oder Weiterbildungsgängen
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        *ngIf="false"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <!-- Go back button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onGoBack()">
        <i class="fa-light fa-arrow-left hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Zurück</span>
      </button>

      <!-- Continue button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onContinue()">
        <i class="fa-light fa-arrow-right hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Weiter</span>
      </button>
    </div>
  </div>

  <div
    *ngIf="instituteService.currentInstitute"
    class="create-education-course flex-r">
    <!-- Create education course button -->
    <button
      type="button"
      class="hasomed-text-button hasomed-button-primary"
      (click)="onCreateEducationCourse()">
      <i class="fa-light fa-plus hasomed-button-icon-sm"> </i>
      <span class="hasomed-button-text">Neue Ausbildung konfigurieren</span>
    </button>
  </div>

  <!-- LOADING -->
  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content flex-c vertical-overflow">
    <span *ngIf="!instituteService.currentInstitute" class="hasomed-text-h3">
      Gebe Sie dem Institut zunächst einen Namen und speichern Sie die Daten,
      bevor Sie die Aus- und Weiterbildungsgänge anlegen!
    </span>
    <app-onb-education-course-table
      *ngIf="
        instituteService.currentInstitute
      "></app-onb-education-course-table>
  </div>
</div>
