import { Component, HostBinding, OnInit, isDevMode } from '@angular/core';
import { environment } from 'src/configs/environment';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { TenantAndUserKeys } from '@healthycloud/lib-crypto';
import { ArrayBufferUtil } from '@healthycloud/lib-crypto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'mvp-eleguide';

  @HostBinding('class') componentCssClass;

  constructor(private cryptoService: CryptoService) {}

  public ngOnInit() {
    console.log('<----- ngOnInit App ----->');
    // Check if development or production mode
    if (isDevMode()) {
      console.log('Development!');
    } else {
      console.log('Production!');
    }

    const userKeys: TenantAndUserKeys = {
      encryptedDmk: ArrayBufferUtil.toArrayBuffer(
        environment.exportedKeyMaterial.encryptedDmk
      ),
      encryptedKek: ArrayBufferUtil.toArrayBuffer(
        environment.exportedKeyMaterial.encryptedKek
      ),
      encryptedPrivateKey: ArrayBufferUtil.toArrayBuffer(
        environment.exportedKeyMaterial.privateKey
      ),
      publicKey: ArrayBufferUtil.toArrayBuffer(
        environment.exportedKeyMaterial.publicKey
      ),
    };

    const pek = ArrayBufferUtil.toArrayBuffer(
      environment.exportedKeyMaterial.pek
    );

    this.cryptoService.importKeyMaterial(userKeys, pek);
  }
}
