import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { CustomFileType } from 'src/app/models/custom-file-type.model';
import { PracticalWork } from 'src/app/models/practical-work.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { PracticalWorkService } from 'src/app/services/practical-work.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import { houseNumberValidator } from 'src/app/validators/house-number.validator';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { positiveDecimalNumbersValidator } from 'src/app/validators/positive-decimal-number.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';

@Component({
  selector: 'app-create-edit-practical-work',
  templateUrl: './create-edit-practical-work.component.html',
  styleUrls: ['./create-edit-practical-work.component.scss'],
})
export class CreateEditPracticalWorkComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public editMode = false;
  public currentPracticalWork: PracticalWork;
  public practicalWorkForm: FormGroup;
  public initialFormValues: {};
  public uploadedFiles: CustomFileType[] = [];
  public existingFiles: CustomFileType[] = [];
  public minDate: Date = new Date(1960, 0, 1);
  public requiredFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  // import from form.utils.ts
  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.practicalWorkForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private practicalWorkService: PracticalWorkService,
    private userService: UserService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit(): void {
    this.createForm();

    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.editMode = true;
        this.practicalWorkService
          .getPracticalWork(+atob(params.id), false)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: async result => {
              console.debug('getPracticalWork', result);
              if (!result.success) {
                this.alertService.showErrorAlert(
                  'Fehler!',
                  'Fehler beim Laden der Praktischen Tätigkeit.'
                );
                // reset initial form values to prevent unsaved changes alert
                this.initialFormValues = this.practicalWorkForm.value;
                // redirect to overview
                this.router.navigate(['../../'], {
                  relativeTo: this.activatedRoute,
                });
                console.error(result.message);
                return;
              }

              console.debug('getPracticalWork', result.data);

              this.currentPracticalWork =
                await this.practicalWorkService.parseBackendPracticalWork(
                  result.data
                );

              console.debug('currentPracticalWork', this.currentPracticalWork);

              this.currentPracticalWork.files?.forEach(fileData => {
                this.existingFiles.push(fileData);
              });

              this.practicalWorkForm.patchValue({
                type: this.currentPracticalWork.type,
                title: this.currentPracticalWork.title,
                street: this.currentPracticalWork.address.street,
                houseNumber: this.currentPracticalWork.address.houseNumber,
                addressAddition:
                  this.currentPracticalWork.address.addressAddition,
                zipCode: this.currentPracticalWork.address.zipCode,
                city: this.currentPracticalWork.address.city,
                country: this.currentPracticalWork.address.country,
                description: this.currentPracticalWork.description,
                responsiblePerson: this.currentPracticalWork.responsiblePerson,
                dateRange: {
                  startDate: moment(
                    this.currentPracticalWork.startDate
                  ).toDate(),
                  endDate: moment(this.currentPracticalWork.endDate).toDate(),
                },
                duration: this.currentPracticalWork.duration,
                internalNote: this.currentPracticalWork.internalNote,
                documents: this.currentPracticalWork.files,
                uploadedFiles: this.uploadedFiles,
                existingFiles: this.existingFiles,
              });

              this.initialFormValues = this.practicalWorkForm.value;

              console.debug('initialFormValues', this.initialFormValues);

              this.cdr.detectChanges();
              this.isLoading = false;
            },
            error: error => {
              console.error(error);
              this.isLoading = false;
            },
          });
      } else {
        this.initialFormValues = this.practicalWorkForm.value;
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    });

    this.practicalWorkForm
      .get('uploadedFiles')
      .valueChanges.subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.practicalWorkForm.get('documents').setValue(null);
          return;
        }
        const newDocumentsArray: CustomFileType[] = [];

        value.forEach((file: any) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            newDocumentsArray.push({
              id: file.id,
              filename: file.name,
              file: e.target.result,
              id_creator: !file.id_creator
                ? this.userService.currentUser.id
                : file.id_creator,
              fileSize: file.size,
              timeCreated: file.timeCreated,
              timeModified: file.timeModified,
            });

            // Check if all files have been read and update the documents array once
            if (newDocumentsArray.length === value.length) {
              this.practicalWorkForm
                .get('documents')
                .setValue(newDocumentsArray);
            }
          };
          reader.readAsDataURL(file);
        });
      });
  }

  private createForm() {
    this.practicalWorkForm = new FormGroup({
      type: new FormControl('', Validators.required),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      street: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      houseNumber: new FormControl('', [
        Validators.required,
        houseNumberValidator(),
        Validators.maxLength(6),
      ]),
      addressAddition: new FormControl('', Validators.maxLength(100)),
      zipCode: new FormControl('', [
        Validators.required,
        maxNumberLength(5),
        positiveNumbersOnlyValidator(true),
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      country: new FormControl('Deutschland', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      description: new FormControl('', Validators.maxLength(6000)),
      responsiblePerson: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      dateRange: new FormGroup(
        {
          startDate: new FormControl(null, Validators.required),
          endDate: new FormControl(null, Validators.required),
        },
        Validators.required
      ),
      duration: new FormControl('', [
        Validators.required,
        positiveDecimalNumbersValidator(),
        maxNumberLength(5),
      ]),
      internalNote: new FormControl('', Validators.maxLength(6000)),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });
  }

  private async getPracticalWork(id: number) {
    return this.practicalWorkService
      .getPracticalWork(id, false)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getPracticalWork', response);
          if (!response.success) {
            this.alertService.showErrorAlert(
              'Fehler!',
              'Fehler beim Laden der Praktischen Tätigkeit.'
            );
            console.error(response.message);
          }

          const practicalWork: PracticalWork =
            await this.practicalWorkService.parseBackendPracticalWork(
              response.data
            );

          return practicalWork;
        },
        error: error => {
          console.error(error);
        },
      });
  }

  /**
   * onSubmit
   */
  public async onSubmit() {
    // this.practicalWorkForm.get('dateRange').markAllAsTouched();
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.practicalWorkForm
      );

    if (formInvalid) {
      return;
    }
    const practicalWork = this.buildPracticalWork();

    this.isLoading = true;
    if (this.editMode) {
      const practicalWorkObservable =
        await this.practicalWorkService.updatePracticalWork(practicalWork);

      practicalWorkObservable.subscribe({
        next: result => {
          console.log('updatePracticalWork', result);

          if (!result.success) {
            console.error(result.message);

            this.alertService.showErrorAlert(
              'Fehler!',
              `Praktische Tätigkeit '${this.practicalWorkForm.value.title}' konnte nicht bearbeitet werden.`
            );
            this.isLoading = false;
            return;
          }

          // reset initialFormValues to current form values
          this.initialFormValues = this.practicalWorkForm.value;
          this.onCancel();

          this.alertService.showSuccessAlert(
            'Praktische Tätigkeit bearbeitet!',
            `Praktische Tätigkeit '${this.practicalWorkForm.value.title}' erfolgreich bearbeitet.`
          );
        },
        error: error => {
          console.error(error);
          this.alertService.showErrorAlert(
            'Fehler!',
            `Praktische Tätigkeit '${this.practicalWorkForm.value.title}' konnte nicht bearbeitet werden.`
          );
          this.isLoading = false;
        },
      });
    } else {
      const practicalWorkObservable =
        await this.practicalWorkService.createPracticalWork(practicalWork);

      practicalWorkObservable.subscribe({
        next: result => {
          console.log('createPracticalWork', result);

          if (!result.success) {
            this.alertService.showErrorAlert(
              'Fehler!',
              `Beim Erstellen der Praktischen Tätigkeit '${this.practicalWorkForm.value.title}' ist ein Fehler aufgetreten.`
            );
            console.error(result.message);
            this.isLoading = false;
            return;
          }

          // reset initialFormValues to current form values
          this.initialFormValues = this.practicalWorkForm.value;
          this.onCancel();

          this.alertService.showSuccessAlert(
            'Praktische Tätigkeit hinzugefügt!',
            `Praktische Tätigkeit '${this.practicalWorkForm.value.title}' erfolgreich hinzugefügt.`
          );
        },
        error: error => {
          console.error(error);
          this.alertService.showErrorAlert(
            'Fehler!',
            `Beim Erstellen der Praktischen Tätigkeit '${this.practicalWorkForm.value.title}' ist ein Fehler aufgetreten.`
          );
          this.isLoading = false;
        },
      });
    }
  }

  /**
   * buildPracticalWork
   * builds a practical work object from the form values
   * @returns PracticalWork
   */
  private buildPracticalWork(): PracticalWork {
    const files: CustomFileType[] = this.practicalWorkForm.value.documents
      ? this.existingFiles.concat(this.practicalWorkForm.value.documents)
      : this.existingFiles;

    const practicalWork: PracticalWork = {
      id: this.currentPracticalWork?.id,
      type: this.practicalWorkForm.value.type,
      title: this.practicalWorkForm.value.title,
      address: {
        zipCode: this.practicalWorkForm.value.zipCode,
        city: this.practicalWorkForm.value.city,
        street: this.practicalWorkForm.value.street,
        houseNumber: this.practicalWorkForm.value.houseNumber,
        addressAddition: this.practicalWorkForm.value.addressAddition,
        country: this.practicalWorkForm.value.country,
      },
      description: this.practicalWorkForm.value.description,
      responsiblePerson: this.practicalWorkForm.value.responsiblePerson,
      startDate: this.practicalWorkForm.value.dateRange.startDate,
      endDate: this.practicalWorkForm.value.dateRange.endDate,
      duration: this.practicalWorkForm.value.duration,
      internalNote: this.practicalWorkForm.value.internalNote,
      files: files,
    };

    return practicalWork;
  }

  /**
   * onCancel
   */
  public onCancel() {
    if (this.editMode) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

  /**
   * onDeleteExistingFile
   * @param file
   */
  public onDeleteExistingFile(file: CustomFileType) {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.practicalWorkForm.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    console.debug('initialFormValues', this.initialFormValues);
    console.debug('currentFormValues', this.practicalWorkForm.value);

    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.practicalWorkForm.value,
      this.initialFormValues
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
