import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { ImageCropperDialogComponent } from 'src/app/components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { WikiPage } from 'src/app/models/wiki.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { WikiService } from 'src/app/services/wiki.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-create-edit-page',
  templateUrl: './create-edit-page.component.html',
  styleUrls: ['./create-edit-page.component.scss'],
})
export class CreateEditPageComponent implements OnInit, OnDestroy {
  public id_currentWikiPage: number;
  public editMode = false;
  public imageUrl: string | ArrayBuffer | null;
  private initialFormValues: {};
  public isLoading = true;

  public requiredFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];

  // import from form.utils.ts
  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.wikiPageForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private wikiService: WikiService,
    private formDeactivateService: FormDeactivateService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService
  ) {}

  tinyMceSettings = {
    // plugins:
    //   'lists link image table help emoticons anchor autolink charmap emoticons searchreplace visualblocks checklist casechange export formatpainter pageembed permanentpen tableofcontents powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss ',
    toolbar:
      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | help',
    help_tabs: ['shortcuts'],
    menubar: false,
  };
  wikiPageForm: FormGroup = new FormGroup({
    title: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    subtitle: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    tinyMCE: new FormControl('', [
      Validators.required,
      Validators.maxLength(20000),
    ]),
    articlePicture: new FormControl(''),
    link: new FormControl('', Validators.maxLength(2000)),
  });

  public ngOnInit() {
    if (this.activatedRoute.snapshot.params['id']) {
      this.id_currentWikiPage = +atob(
        this.activatedRoute.snapshot.params['id']
      );
      this.editMode = true;
      this.wikiService
        .getWikiPageById(this.id_currentWikiPage)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            console.debug('getWikiPageById', response);

            if (!response.success) {
              this.alertService.showErrorAlert(
                'Fehler',
                'Fehler beim Laden des Wiki Artikels.'
              );
              console.error(response.message);
              return;
            }

            const wikiPage = response.data
              ? await this.wikiService.parseBackendWikiPage(response.data)
              : null;

            if (!wikiPage) {
              this.alertService.showErrorAlert(
                'Fehler',
                'Fehler beim Laden des Wiki Artikels.'
              );
              console.error(response.message);
              return;
            }

            this.wikiPageForm.patchValue({
              title: wikiPage.title,
              subtitle: wikiPage.subtitle,
              tinyMCE: wikiPage.content,
              link: wikiPage.link,
              articlePicture: wikiPage.picture,
            });
            this.initialFormValues = this.wikiPageForm.value;
            this.isLoading = false;
          },
          error: error => {
            console.error(error.message);
          },
        });
    }
    this.initialFormValues = this.wikiPageForm.value;
  }

  public onSubmit() {
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.wikiPageForm
      );

    if (formInvalid) {
      return;
    }

    console.debug('formValues', this.wikiPageForm.value);
    this.initialFormValues = this.wikiPageForm.value;
    const wikiPage = this.buildWikiPage();

    this.editMode
      ? this.updateWikiPage(wikiPage)
      : this.createWikiPage(wikiPage);
  }

  public onCancel() {
    this.editMode
      ? this.router.navigate(['../../'], { relativeTo: this.activatedRoute })
      : this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  /**
   * buildWikiPage
   * builds a WikiPage object from the form values
   * @returns WikiPage
   */
  private buildWikiPage(): WikiPage {
    return {
      id: this.id_currentWikiPage ?? null,
      title: this.wikiPageForm.get('title').value,
      subtitle: this.wikiPageForm.get('subtitle').value,
      content: this.wikiPageForm.get('tinyMCE').value,
      picture: this.wikiPageForm.get('articlePicture').value ?? null,
      link: this.wikiPageForm.get('link').value ?? null,
    };
  }

  /**
   * createWikiPage
   * creates a new WikiPage
   * @param wikiPage: WikiPage
   */
  private async createWikiPage(wikiPage: WikiPage) {
    const createPageObservable =
      await this.wikiService.createWikiPage(wikiPage);
    createPageObservable.subscribe({
      next: response => {
        console.debug('createWikiPage', response);
        if (!response.success) {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Informations-Artikel konnte nicht erstellt werden.'
          );
          console.error(response.message);
          return;
        }

        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Der Informations-Artikel "${wikiPage.title}" wurde erfolgreich erstellt.`
        );
        this.onCancel();
      },
      error: error => {
        console.error(error.message);
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Informations-Artikel konnte nicht erstellt werden.'
        );
      },
    });
  }

  /**
   * updateWikiPage
   * updates an existing WikiPage
   * @param wikiPage: WikiPage
   */
  private async updateWikiPage(wikiPage: WikiPage) {
    const updatePageObservable =
      await this.wikiService.updateWikiPage(wikiPage);
    updatePageObservable.subscribe({
      next: response => {
        console.debug('updateWikiPage', response);
        if (!response.success) {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Informations-Artikel konnte nicht bearbeitet werden.'
          );
          console.error(response.message);
          return;
        }

        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Der Informations-Artikel "${wikiPage.title}" wurde erfolgreich aktualisiert.`
        );
        this.onCancel();
      },
      error: error => {
        console.error(error.message);
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Informations-Artikel konnte nicht bearbeitet werden.'
        );
      },
    });
  }

  public onDeleteWikiPage() {
    console.debug('onDeleteWikiPage', this.id_currentWikiPage);
    if (this.id_currentWikiPage) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Löschen',
          message: 'Möchten Sie den Artikel wirklich löschen?',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.wikiService.deleteWikiPage(this.id_currentWikiPage).subscribe({
            next: response => {
              console.debug('deleteWikiPage', response);
              if (!response.success) {
                this.alertService.showErrorAlert(
                  'Das hat leider nicht geklappt!',
                  'Der Informations-Artikel konnte nicht gelöscht werden.'
                );
                console.error(response.message);
                return;
              }

              this.alertService.showSuccessAlert(
                'Das hat geklappt!',
                `Der Informations-Artikel wurde erfolgreich gelöscht.`
              );
              this.onCancel();
            },
            error: error => {
              console.error(error.message);
              this.alertService.showErrorAlert(
                'Das hat leider nicht geklappt!',
                'Der Informations-Artikel konnte nicht gelöscht werden.'
              );
            },
          });
        }
      });
    }
  }

  public imageChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '500px',
      data: {
        image: event,
        title: 'Artikelbild zuschneiden',
        round: false,
        height: 500,
        aspectRatio: 16 / 9,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.wikiPageForm.get('articlePicture').setValue(result);
      }
    });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }

    return this.formDeactivateService.confirmDeactivation(
      this.wikiPageForm.value,
      this.initialFormValues
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
