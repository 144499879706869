import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomFileType } from 'src/app/models/custom-file-type.model';
import { FileService } from 'src/app/services/file.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-file-view',
  standalone: false,
  templateUrl: './file-view.component.html',
  styleUrl: './file-view.component.scss',
})
export class FileViewComponent implements OnInit {
  @Input() public file: CustomFileType;
  @Input() public deleteEnabled: boolean = false;
  @Output() public deleteFile: EventEmitter<CustomFileType> =
    new EventEmitter();

  constructor(
    private fileService: FileService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  public onDownload(file: CustomFileType) {
    this.fileService.downloadFile(file.id).subscribe({
      next: () => {
        console.debug('download successfull');
      },
      error: error => {
        console.error('error', error);
      },
    });
  }

  public onDelete(file: CustomFileType) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Datei löschen',
        message: 'Möchten Sie die Datei wirklich löschen?',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteFile.emit(file);
      }
    });
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   * @returns (Formatted file size)
   */
  public formatBytes(bytes: number, decimals: number): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
