import { Injectable } from '@angular/core';
import { EventDate, InstituteEvent } from '../models/event.model';
import { Room } from '../models/room.model';

@Injectable({
  providedIn: 'root',
})
export class RoomOrganizationService {
  constructor() {}

  /**
   * Checks if the given room is available for timeslot
   * @param allEventDates EventDate[]
   * @param room Room
   * @param currentEventDate EventDate
   * @param totalPersons number
   * @returns isRoomAvailable
   */
  public isRoomAvailable(
    allEventDates: EventDate[],
    room: Room,
    currentEventDate: EventDate,
    totalPersons: number
  ) {
    let isRoomAvailable = true;

    if (room.seatNumber < totalPersons) {
      return false;
    }

    if (allEventDates?.length > 0) {
      const eventsInRoom = allEventDates.filter(eventDate => {
        return (
          eventDate.room?.id === room.id && eventDate.id !== currentEventDate.id
        );
      });

      const selectedEventStart = new Date(currentEventDate.startDate).valueOf();
      const selectedEventEnd = new Date(currentEventDate.endDate).valueOf();
      for (let eventInRoom of eventsInRoom) {
        const eventInRoomStart = new Date(eventInRoom.startDate).valueOf();
        const eventInRoomEnd = new Date(eventInRoom.endDate).valueOf();

        if (selectedEventStart == eventInRoomStart) {
          isRoomAvailable = false;
        } else if (selectedEventEnd == eventInRoomEnd) {
          isRoomAvailable = false;
        } else if (
          selectedEventStart > eventInRoomStart &&
          selectedEventStart < eventInRoomEnd
        ) {
          isRoomAvailable = false;
        } else if (
          selectedEventEnd > eventInRoomStart &&
          selectedEventEnd < eventInRoomEnd
        ) {
          isRoomAvailable = false;
        }
      }
    }

    return isRoomAvailable;
  }

  /**
   * getAllInstituteEventRooms
   * @param instituteEvents - instituteEvents to get rooms from
   * @returns all unique rooms from the given instituteEvents
   */
  public getAllInstituteEventRooms(instituteEvents: InstituteEvent[]) {
    const uniqueRooms = [
      ...new Map(
        instituteEvents
          ?.filter(instituteEvent => instituteEvent.currentRoom)
          .map(instituteEvent => [
            instituteEvent.currentRoom?.id,
            instituteEvent.currentRoom,
          ])
      ).values(),
    ];
    return uniqueRooms;
  }
}
