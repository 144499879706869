import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PracticalWork } from 'src/app/models/practical-work.model';
import { AlertService } from 'src/app/services/alert.service';
import { PracticalWorkService } from 'src/app/services/practical-work.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserService } from 'src/app/services/user.service';
import { FileService } from 'src/app/services/file.service';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-practical-work-table',
  templateUrl: './practical-work-table.component.html',
  styleUrls: ['./practical-work-table.component.scss'],
})
export class PracticalWorkTableComponent implements OnInit, OnDestroy {
  @Input() searchText: string;

  public displayedColumns = [
    'title',
    'responsiblePerson',
    'date',
    'duration',
    'confirmation',
    'actions',
  ];
  public dataSource: MatTableDataSource<PracticalWork> =
    new MatTableDataSource();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private practicalWorkService: PracticalWorkService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private userService: UserService,
    private fileService: FileService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.activatedRoute.parent?.paramMap.subscribe(params => {
      let id_user = +atob(params.get('id'));

      if (!id_user) {
        id_user = this.userService.currentUser.id;
      }

      this.getPracticalWorksFromUser(id_user);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchText) {
      this.applySearch(this.searchText);
    }
  }

  /**
   * getPracticalWorksFromUser
   * get the practical works from user
   * @returns void
   */
  public async getPracticalWorksFromUser(id_user: number): Promise<void> {
    this.practicalWorkService
      .getPracticalWorksFromStudent(id_user)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getPracticalWorksFromStudent', response);
          if (!response.success) {
            console.error(response.message);
            this.isLoading = false;
            this.alertService.showErrorAlert(
              'Fehler beim Laden der praktischen Tätigkeiten!',
              'Es ist ein Fehler beim Laden der praktischen Tätigkeiten aufgetreten!'
            );
            return;
          }

          const practicalWorks = response.data
            ? await Promise.all(
                response.data?.map(
                  async (practicalWorkData: any): Promise<PracticalWork> => {
                    return await this.practicalWorkService.parseBackendPracticalWork(
                      practicalWorkData
                    );
                  }
                )
              )
            : [];

          this.dataSource.data = practicalWorks;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  public applySearch(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewPracticalWork(practialWork: any) {
    console.log('viewPracticalWork', practialWork);
    this.router.navigate(['./', btoa(String(practialWork.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  public editPracticalWork(practicalWork: any) {
    console.log('editPracticalWork', practicalWork);
    this.router.navigate(['./', 'edit', btoa(String(practicalWork.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  public deletePracticalWork(practicalWork: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Löschen',
        message: `Möchten Sie den Eintrag '${practicalWork.title}' wirklich löschen?`,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        console.debug('deletePracticalWork', practicalWork);
        this.practicalWorkService
          .deletePracticalWork(practicalWork.id)
          .subscribe({
            next: result => {
              console.debug('deletePracticalWork', result);
              if (!result.success) {
                // TODO: alert error
                console.error(result.message);
                return;
              }

              this.alertService.showSuccessAlert(
                'Praktische Tätigkeit gelöscht!',
                `Der Eintrag '${practicalWork.title}' wurde gelöscht!`
              );

              // delete entry from table data without reloading from backend
              const index = this.dataSource.data.indexOf(practicalWork);
              if (index > -1) {
                this.dataSource.data = this.dataSource.data.filter(
                  item => item !== practicalWork
                );
              }
            },
            error: error => {
              console.error(error);
            },
          });
      }
    });
  }

  public onDownload(id_file: number) {
    this.fileService.downloadFile(id_file).subscribe({
      next: () => {
        console.debug('download successfull');
      },
      error: error => {
        console.error('error', error);
      },
    });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
