<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Selbsterfahrung Einträge</h1>
      <h3 class="hasomed-text-h3">
        Füge neue Selbsterfahrungs Einträge hinzu oder bearbeite bestehende
      </h3>
    </div>

    <form class="header-buttons" [formGroup]="searchForm">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          (keyup)="applySearch($event)"
          placeholder="Suche" />
      </div>
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="createSelfAwareness()">
        <i class="fa-light fa-calendar-plus hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Neuen Eintrag vornehmen</span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- title column -->
        <ng-container matColumnDef="title">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span>Titel</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="title">
            </app-custom-table-sort-icon>
          </th>
          <td
            class="gray-900 self-awareness-title-cell"
            mat-cell
            *matCellDef="let selfAwarenessEntry"
            (click)="viewSelfAwareness(selfAwarenessEntry)"
            matTooltip="Eintrag ansehen">
            <span>{{ selfAwarenessEntry.title }}</span>
          </td>
        </ng-container>

        <!-- responiblePerson Column -->
        <ng-container matColumnDef="responsiblePerson">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span class="gray-600">Verantwortliche*r</span>
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="responsiblePerson">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-600" mat-cell *matCellDef="let selfAwarenessEntry">
            <span>{{ selfAwarenessEntry.responsiblePerson }}</span>
          </td>
        </ng-container>

        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span class="gray-600">Datum</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="date">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-600" mat-cell *matCellDef="let selfAwarenessEntry">
            <span>{{ selfAwarenessEntry.date | date: 'dd.MM.yyyy' }}</span>
          </td>
        </ng-container>

        <!-- duration Column -->
        <ng-container matColumnDef="duration">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span class="gray-600">Dauer</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="duration">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-600" mat-cell *matCellDef="let selfAwarenessEntry">
            <span
              >{{ selfAwarenessEntry.duration | floatToHoursMinutes }}
            </span>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th class="gray-600" mat-header-cell *matHeaderCellDef></th>
          <td class="gray-600" mat-cell *matCellDef="let selfAwarenessEntry">
            <div class="table-button-icons">
              <button
                type="button"
                class="table-button-icon"
                (click)="viewSelfAwareness(selfAwarenessEntry)"
                matTooltip="Eintrag ansehen">
                <i class="fa-light fa-arrow-up-right-from-square"></i>
              </button>
              <button
                type="button"
                class="table-button-icon"
                (click)="deleteSelfAwareness(selfAwarenessEntry)"
                matTooltip="Eintrag löschen">
                <i class="fa-light fa-trash-can"></i>
              </button>
              <button
                type="button"
                class="table-button-icon"
                (click)="editSelfAwareness(selfAwarenessEntry)"
                matTooltip="Eintrag bearbeiten">
                <i class="fa-light fa-pen"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <mat-progress-spinner
                color="primary"
                mode="indeterminate"
                diameter="32">
              </mat-progress-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Es wurde kein Eintrag gefunden.
            </div>
          </td>
        </tr>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
    <div class="vertical-spacer"></div>
  </div>
</div>
