<form class="component-content" [formGroup]="roomForm" (ngSubmit)="onSubmit()">
  <!-- HEADER  -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Raum bearbeiten' : 'Raum erstellen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie einen bestehenden Raum'
            : 'Erstellen Sie einen neuen Raum'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-orange">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <!-- LOADING WRAPPER -->
  <div *ngIf="isLoading && editMode" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading || !editMode" class="main-content">
    <!-- Room name -->
    <div class="formular-row">
      <label class="formular-row-label" for="name">
        <span class="label-title">
          Bezeichnung
          <span *ngIf="isRequired(roomForm.get('name'))"> * </span>
        </span>
        <span class="label-description"> Bezeichnung des Raumes </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="name"
            class="hasomed-text-input"
            type="text"
            formControlName="name"
            autocomplete="off"
            placeholder="z.B Seminarraum" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('name')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Room floor -->
    <div class="formular-row">
      <label class="formular-row-label" for="floor">
        <span class="label-title">
          Etage <span *ngIf="isRequired(roomForm.get('floor'))"> * </span>
        </span>
        <span class="label-description"> Etage des Raums </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="floor"
            class="hasomed-text-input"
            type="number"
            min="-2"
            max="10"
            formControlName="floor"
            placeholder="z.B. 1" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('floor')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Room building -->
    <div class="formular-row">
      <label class="formular-row-label" for="building">
        <span class="label-title">
          Gebäude
          <span *ngIf="isRequired(roomForm.get('building'))"> * </span>
        </span>
        <span class="label-description">
          Gebäude in dem sich der Raum befindet
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="building"
            class="hasomed-text-input"
            type="text"
            formControlName="building"
            placeholder="z.B. H2" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('building')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Room seatNumber -->
    <div class="formular-row">
      <label class="formular-row-label" for="seatNumber">
        <span class="label-title">
          Platzanzahl
          <span *ngIf="isRequired(roomForm.get('seatNumber'))"> * </span>
        </span>
        <span class="label-description"> Anzahl der Sitzplätze des Raums </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="seatNumber"
            class="hasomed-text-input"
            type="number"
            min="0"
            max="1000"
            formControlName="seatNumber"
            placeholder="z.B. 250" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('seatNumber')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Room equipment -->
    <div class="formular-row">
      <label class="formular-row-label" for="equipment">
        <span class="label-title">
          Ausstattung
          <span *ngIf="isRequired(roomForm.get('equipment'))"> * </span>
        </span>
        <span class="label-description">
          Ausstattung des Raumes (optional)
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="equipment"
            class="hasomed-text-input"
            type="text"
            formControlName="equipment"
            placeholder="z.B. Beamer " />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('equipment')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Room address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Adresse </span>
        <span class="label-description"> Anschrift des Raumes </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="flex-c flex-grow gap-8">
            <!-- Street, houseNumber -->
            <div class="formular-row-input">
              <input
                id="street"
                class="hasomed-text-input"
                type="text"
                formControlName="street"
                [placeholder]="
                  'Straße' + (isRequired(roomForm.get('street')) ? ' *' : '')
                " />
              <input
                id="houseNumber"
                size="6"
                class="hasomed-text-input"
                type="text"
                formControlName="houseNumber"
                [placeholder]="
                  'Hausnummer' +
                  (isRequired(roomForm.get('houseNumber')) ? ' *' : '')
                " />
            </div>

            <!-- addressAddition -->
            <input
              id="addressAddition"
              class="hasomed-text-input"
              type="text"
              formControlName="addressAddition"
              [placeholder]="
                'Adresszusatz (Etage, ...)' +
                (isRequired(roomForm.get('addressAddition')) ? ' *' : '')
              " />

            <!-- zipCode, city -->
            <div class="formular-row-input">
              <input
                size="5"
                maxlength="5"
                id="zipCode"
                class="hasomed-text-input"
                type="text"
                formControlName="zipCode"
                [placeholder]="
                  'Postleitzahl' +
                  (isRequired(roomForm.get('zipCode')) ? ' *' : '')
                " />
              <input
                id="city"
                class="hasomed-text-input"
                type="text"
                formControlName="city"
                [placeholder]="
                  'Stadt / Ort' + (isRequired(roomForm.get('city')) ? ' *' : '')
                " />
            </div>

            <!-- country -->
            <input
              id="country"
              class="hasomed-text-input"
              type="text"
              formControlName="country"
              [placeholder]="
                'Land' + (isRequired(roomForm.get('country')) ? ' *' : '')
              " />
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('street')"></app-error-message>
        <app-error-message
          [formField]="roomForm.get('houseNumber')"></app-error-message>
        <app-error-message
          [formField]="roomForm.get('addressAddition')"></app-error-message>
        <app-error-message
          [formField]="roomForm.get('zipCode')"></app-error-message>
        <app-error-message
          [formField]="roomForm.get('city')"></app-error-message>
        <app-error-message
          [formField]="roomForm.get('country')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Responsible -->
    <div class="formular-row">
      <label class="formular-row-label" for="responsible">
        <span class="label-title">
          Verantwortliche*r
          <span *ngIf="isRequired(roomForm.get('responsible'))"> * </span>
        </span>
        <span class="label-description">
          Wer ist für den Raum verantwortlich?
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="responsible"
            class="hasomed-text-input"
            type="text"
            formControlName="responsible"
            placeholder="Verantwortliche*r" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('responsible')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Available -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Kann der Raum aktuell genutzt werden?
          <span *ngIf="isRequired(roomForm.get('available'))"> * </span>
        </span>
        <span class="label-description">
          Hier können Sie den Raum temporär deaktivieren falls umbauten oder
          sonstige Planungen vorliegen.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-slide-toggle
            hideIcon
            formControlName="available"
            [matTooltip]="
              this.roomForm.get('available').value
                ? 'Raum deaktivieren'
                : 'Raum aktivieren'
            ">
            <span>
              {{ this.roomForm.get('available').value ? 'Aktiv' : 'Inaktiv' }}
            </span>
          </mat-slide-toggle>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="roomForm.get('available')"></app-error-message>
      </div>
    </div>
  </div>
</form>
