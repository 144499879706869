import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { Filter, FilterType } from 'src/app/models/filter.model';
import { Room } from 'src/app/models/room.model';
import { FilterRoomOverviewPipe } from 'src/app/pipes/filter-room-overview.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EventService } from 'src/app/services/event.service';
import { RoomService } from 'src/app/services/room.service';
import { UserService } from 'src/app/services/user.service';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';

@Component({
  selector: 'app-room-overview',
  templateUrl: './room-overview.component.html',
  styleUrls: ['./room-overview.component.scss'],
})
export class RoomOverviewComponent implements OnInit, OnDestroy {
  public searchForm: FormGroup;
  public rooms: Room[] = [];

  displayedColumns: string[] = [
    'name',
    'floor',
    'building',
    'seatNumber',
    'equipment',
    'available',
    'edit',
  ];
  public dataSource: MatTableDataSource<Room> = new MatTableDataSource();
  public tableData: Subject<Room[]> = new Subject();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;

  public roomFilter: Filter[] = [
    {
      type: FilterType.ROOM_FLOOR,
      value: null,
    },
    {
      type: FilterType.ROOM_CAPACITY,
      value: null,
    },
    {
      type: FilterType.ROOM_ACTIVE,
      value: null,
    },
  ];
  public filterOpened: boolean = false;
  public hasActiveFilterValue = hasActiveFilterValue;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private roomService: RoomService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private eventService: EventService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.initTableData();

    this.tableData.subscribe(rooms => {
      this.dataSource = new MatTableDataSource<any>(rooms);

      /* set sortingDataAccessor */
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'name':
            return item.name;
          case 'floor':
            return item.floor;
          case 'building':
            return item.building;
          case 'seatNumber':
            return item.seatNumber;
          case 'equipment':
            return item.equipment;
          case 'available':
            return item.available;
          default:
            return item[property];
        }
      };

      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });

    this.searchForm = new FormGroup({
      searchText: new FormControl(''),
    });
  }

  /**
   * roomFilterChanged
   * gets called when the room filter changed
   * @param roomFilter Filter[]
   * @returns void
   */
  public roomFilterChanged(roomFilter: Filter[]): void {
    this.roomFilter = roomFilter;
    this.applyRoomFilter();
    this.filterMenuTrigger.closeMenu();
  }

  /**
   * applyRoomFilter
   * applies the room filter
   * @returns void
   */
  private applyRoomFilter(): void {
    this.dataSource.data = FilterRoomOverviewPipe.prototype.transform(
      this.rooms,
      this.roomFilter
    );
  }

  /**
   * initTableData
   * gets all rooms from the room service and maps them to the tableData
   * @returns void
   */
  public initTableData(): void {
    const institute_id = this.userService.currentUser.id_institute;
    this.roomService
      .getAllRooms(institute_id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getAllRooms Backend Response', response);

          if (!response.success) {
            console.error(response.message);
            this.isLoading = false;
            this.alertService.showErrorAlert(
              'Fehler beim Laden der Räume!',
              'Es ist ein Fehler beim Laden der Räume aufgetreten!'
            );
            this.tableData.next([]);
            return;
          }

          this.rooms = response.data
            ? await Promise.all(
                response.data?.map(async (roomData: any): Promise<Room> => {
                  return await this.roomService.parseBackendRoom(roomData);
                })
              )
            : [];
          this.tableData.next(this.rooms);
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  /**
   * goBackToRoomOrganization
   * navigates back to the room organization
   * @returns void
   */
  public goBackToRoomOrganization(): void {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onCreateNewRoom
   * navigates to the create new room component
   * @returns void
   */
  public onCreateNewRoom(): void {
    this.router.navigate(['./create'], { relativeTo: this.activatedRoute });
  }

  /**
   * onEditRoom
   * navigates to the edit room component
   * @param id_room
   * @returns void
   */
  public onEditRoom(id_room: number): void {
    this.router.navigate(['./edit/', btoa(String(id_room))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onDeleteRoom
   * check if there are upcoming eventDates in the room
   * Ask the user if he wants to delete the room
   * @param room
   * @returns void
   */
  public onDeleteRoom(room: Room): void {
    this.eventService
      .getEventDatesByRoomId(room.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          console.debug('EventDates in room', response);

          if (response.success && response.data.length > 0) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '400px',
              data: {
                title: `Bevorstehende Termine in Raum '${room.name}'!`,
                message:
                  'Es sind noch bevorstehende Termine in diesem Raum vorhanden.  \
              Möchten Sie den Raum trotzdem löschen?',
              },
            });

            dialogRef.afterClosed().subscribe(dialogResult => {
              if (dialogResult) {
                this.deleteRoom(room);
              }
            });
          } else {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '400px',
              data: {
                title: 'Raum löschen',
                message: 'Möchten Sie den Raum wirklich löschen?',
              },
            });

            dialogRef.afterClosed().subscribe(dialogResult => {
              if (dialogResult) {
                this.deleteRoom(room);
              }
            });
          }
        },
      });
  }

  /**
   * deleteRoom
   * calls the deleteRoom service function
   * @param room
   * @returns void
   */
  private deleteRoom(room: Room): void {
    this.roomService.deleteRoom(room.id).subscribe({
      next: response => {
        console.debug('deleteRoom Backend Response', response);
        if (!response.success) {
          console.error(response.message);
          this.alertService.showErrorAlert(
            'Fehler',
            `Der Raum '${room.name}' wurde nicht gelöscht!`
          );
          return;
        }
        this.alertService.showSuccessAlert(
          'Raum gelöscht',
          `Der Raum '${room.name}' wurde gelöscht!`
        );
        this.initTableData();
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Der Raum '${room.name}' wurde nicht gelöscht!`
        );
      },
    });
  }

  /**
   * onRoomActiveChanged
   * checks if there are upcoming eventDates in the room and asks if the user wants to deactivate the room
   * @param slideToggleData MatSlideToggleChange
   * @param room Room
   * @returns void
   */
  public onRoomActiveChanged(
    slideToggleData: MatSlideToggleChange,
    room: Room
  ): void {
    // check if there are upcoming eventDates in the room
    // if so, ask if the user wants to deactivate the room
    if (!slideToggleData.checked) {
      this.eventService
        .getEventDatesByRoomId(room.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: response => {
            console.debug('EventDates in room', response);
            if (response.success && response.data.length > 0) {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                maxWidth: '400px',
                data: {
                  title: `Bevorstehende Termine in Raum '${room.name}'!`,
                  message:
                    'Es sind noch bevorstehende Termine in diesem Raum vorhanden.  \
                  Wenn Sie den Raum deaktivieren, wird der Raum aus allen bevorstehenden Terminen entfernt. \
                  Möchten Sie den Raum trotzdem deaktivieren?',
                },
              });

              dialogRef.afterClosed().subscribe(dialogResult => {
                if (dialogResult) {
                  this.updateRoomAvailableState(slideToggleData, room);
                  this.removeRoomFromFutureEventDates(room);
                } else {
                  slideToggleData.source.checked = true;
                }
              });
            } else {
              this.updateRoomAvailableState(slideToggleData, room);
            }
          },
        });
    } else {
      this.updateRoomAvailableState(slideToggleData, room);
    }
  }

  /**
   * updateRoomAvailableState
   * updates the available state of the room
   * @param data MatSlideToggleChange
   * @param room Room
   * @returns void
   */
  private async updateRoomAvailableState(
    data: MatSlideToggleChange,
    room: Room
  ): Promise<void> {
    room.available = data.checked;
    const updateRoomObservable = await this.roomService.updateRoom(
      room.id,
      room,
      'updateAvailableState'
    );
    updateRoomObservable.subscribe({
      next: response => {
        console.debug('updateRoom Backend Response:', response);
        if (!response.success) {
          console.error(response.message);
          this.alertService.showErrorAlert(
            'Fehler',
            `Der Raum '${room.name}' konnte nicht ${
              data.checked ? 'aktiviert' : 'deaktiviert'
            } werden!`
          );
          return;
        }
        const message = ((status: string) =>
          ({ available: 'aktiviert', not_available: 'deaktiviert' })[status] ||
          status)(response.data.toString());

        this.alertService.showSuccessAlert(
          `Raum ${message}`,
          `Der Raum '${room.name}' wurde ${message}!`
        );
      },
      error: error => {
        console.error(error);
      },
    });
  }

  /**
   * removeRoomFromFutureEventDates
   * @param room
   */
  private removeRoomFromFutureEventDates(room: Room): void {
    this.eventService.removeRoomFromFutureEventDates(room.id).subscribe({
      next: result => {
        if (!result.success) {
          console.error(result.message);
          this.alertService.showErrorAlert(
            'Fehler',
            `Der Raum '${room.name}' konnte nicht aus den bevorstehenden Terminen entfernt werden!`
          );
          return;
        }

        this.alertService.showSuccessAlert(
          'Raum aus Terminen entfernt',
          `Der Raum '${room.name}' wurde aus allen bevorstehenden Terminen entfernt!`
        );
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Der Raum '${room.name}' konnte nicht aus den bevorstehenden Terminen entfernt werden!`
        );
      },
    });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
