<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        Praktische Tätigkeiten suchen / hinzufügen
      </h1>
      <h3 class="hasomed-text-h3">
        Erstellen sowie bearbeiten und suchen Sie praktische Tätigkeiten
      </h3>
    </div>

    <form class="header-buttons" [formGroup]="searchForm">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="createPracticalWork()">
        <i class="fa-light fa-calendar-plus hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Neuen Eintrag vornehmen</span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <app-practical-work-table
      [searchText]="
        searchForm.get('searchText').value
      "></app-practical-work-table>
    <div class="vertical-spacer"></div>
  </div>
</div>
