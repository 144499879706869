import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  TreatmentCase,
  TreatmentCaseAppointment,
} from 'src/app/models/treatment-case.model';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { Location } from '@angular/common';
import {
  TreatmentCaseEventDate,
  TreatmentCaseEventType,
} from 'src/app/models/event.model';
import { UserService } from 'src/app/services/user.service';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-supervision-details',
  templateUrl: './supervision-details.component.html',
  styleUrls: ['./supervision-details.component.scss'],
})
export class SupervisionDetailsComponent implements OnInit, OnDestroy {
  public treatmentCase: TreatmentCase;
  public supervisionAppointment: TreatmentCaseAppointment;
  public patientAppointmentEventDates: TreatmentCaseEventDate[] = [];
  public isLoading: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private treatmentCaseService: TreatmentCaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    public userService: UserService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (!params.id) {
        return;
      }

      const id_treatmentCase = +atob(params.id);

      this.treatmentCaseService
        .getTreatmentCase(id_treatmentCase)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async result => {
            console.debug('getTreatmentCase result', result);

            if (!result.success) {
              console.error(result.message);
              this.isLoading = false;
              return;
            }
            this.treatmentCase =
              await this.treatmentCaseService.parseBackendTreatmentCase(
                result.data
              );

            if (params.id_appointment) {
              const id_appointment = +atob(params.id_appointment);
              this.supervisionAppointment =
                this.treatmentCase.appointments.find(
                  appointment => appointment.id === id_appointment
                );
              this.getPatientAppointmentEventDates();
              this.isLoading = false;
            }

            console.debug('this.treatmentCase', this.treatmentCase);
            console.debug(
              'this.supervisionAppointment',
              this.supervisionAppointment
            );
          },
          error: error => {
            console.error(error.message);
            this.isLoading = false;
          },
        });
    });
  }

  /**
   * getPatientAppointmentEventDates
   * get all patient appointment event dates
   */
  public getPatientAppointmentEventDates() {
    this.treatmentCase?.appointments.forEach(appointment => {
      if (appointment.type === TreatmentCaseEventType.PATIENT_SESSION) {
        appointment.eventDates.forEach(eventDate => {
          if (
            this.supervisionAppointment.selectedPatientEventDateIds.includes(
              eventDate.id
            )
          ) {
            this.patientAppointmentEventDates.push(eventDate);
          }
        });
      }
    });

    // sort the patient appointments by start date
    this.patientAppointmentEventDates.sort((a, b) => {
      return a.startDate.getTime() - b.startDate.getTime();
    });

    return this.patientAppointmentEventDates;
  }

  /**
   * onEditSupervisionAppointment
   * open the edit supervision appointment page
   * @returns void
   */
  public onEditSupervisionAppointment() {
    if (this.treatmentCase) {
      this.router.navigate(
        [
          '../../../edit-supervision-appointment',
          btoa(String(this.treatmentCase.id)),
          btoa(this.supervisionAppointment.id.toString()),
        ],
        {
          relativeTo: this.activatedRoute,
        }
      );
    }
  }

  /**
   * onGoBack
   */
  public onGoBack() {
    this.location.back();
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
