import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { SearchFilterPipe } from '../search-filter.pipe';
import { CustomTableSortIconComponent } from '../components/shared-components/custom-table-sort-icon/custom-table-sort-icon.component';
import { CustomPaginatorDirective } from '../directives/custom-paginator.directive';
import { UploadAreaDNDComponent } from '../components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { ProgressBarComponent } from '../components/shared-components/upload-area-dnd/progress-bar/progress-bar.component';
import { EventRoomPlanningComponent } from '../components/eleguide/education/room-organization/event-room-planning/event-room-planning.component';
import { NewsArticleComponent } from '../components/shared-components/news-article/news-article.component';
import { EventPickerComponent } from '../components/shared-components/event-picker/event-picker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckPermissionsDirective } from '../directives/check-permissions.directive';
import { DefaultRouteComponent } from '../components/shared-components/default-route/default-route.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CopyToClipboardComponent } from '../components/shared-components/copy-to-clipboard/copy-to-clipboard.component';
import { ChangePasswordDialogComponent } from '../components/shared-components/change-password-dialog/change-password-dialog.component';
import { EditMasterDataDialogComponent } from '../components/shared-components/edit-master-data-dialog/edit-master-data-dialog.component';
import { FilterNewsArticlesPipe } from '../pipes/filter-news-articles.pipe';
import { EducationProgressDialogComponent } from '../components/shared-components/education-progress-dialog/education-progress-dialog.component';
import { ErrorMessageComponent } from '../components/shared-components/error-message/error-message.component';
import { FilterComponent } from '../components/shared-components/filter/filter.component';
import { FilterTagsComponent } from '../components/shared-components/filter-tags/filter-tags.component';
import { ElogCellComponent } from '../components/shared-components/elog-cell/elog-cell.component';
import { PracticalWorkTableComponent } from '../components/shared-components/practical-work-table/practical-work-table.component';
import { CustomTimePipe } from '../pipes/custom-time.pipe';
import { TreatmentCasesComponent } from '../components/shared-components/treatment-cases/treatment-cases.component';
import { TreatmentCaseDetailsComponent } from '../components/eleguide/education/ambulatory-part/treatment-case-details/treatment-case-details.component';
import { CreateEditSupervisionAppointmentsComponent } from '../components/eleguide/education/ambulatory-part/create-supervision-appointments/create-edit-supervision-appointments.component';
import { EmptyStateComponent } from '../components/shared-components/empty-state/empty-state.component';
import { NoSensitiveDataAlertComponent } from '../components/shared-components/no-sensitive-data-alert/no-sensitive-data-alert.component';
import { EmptyStateDialogComponent } from '../components/shared-components/empty-state-dialog/empty-state-dialog.component';
import { PracticalWorkDetailsComponent } from '../components/shared-components/practical-work-details/practical-work-details.component';
import { SessionExpiredDialogComponent } from '../components/shared-components/session-expired-dialog/session-expired-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperDialogComponent } from '../components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { ImageDetailDialogComponent } from '../components/shared-components/image-detail-dialog/image-detail-dialog.component';
import { StripHtmlPipe } from '../pipes/strip-html.pipe';
import { FileViewComponent } from '../components/shared-components/file-view/file-view.component';
import { StudentEducationProgressComponent } from '../components/eleguide/education/students/student-detail/student-education-progress/student-education-progress.component';

@NgModule({
  declarations: [
    CustomTableSortIconComponent,
    UploadAreaDNDComponent,
    ProgressBarComponent,
    CustomPaginatorDirective,
    SearchFilterPipe,
    NewsArticleComponent,
    EventPickerComponent,
    EventRoomPlanningComponent,
    CheckPermissionsDirective,
    DefaultRouteComponent,
    CopyToClipboardComponent,
    ChangePasswordDialogComponent,
    EditMasterDataDialogComponent,
    FilterNewsArticlesPipe,
    EducationProgressDialogComponent,
    ErrorMessageComponent,
    FilterComponent,
    FilterTagsComponent,
    ElogCellComponent,
    PracticalWorkTableComponent,
    PracticalWorkDetailsComponent,
    TreatmentCasesComponent,
    TreatmentCaseDetailsComponent,
    CreateEditSupervisionAppointmentsComponent,
    EmptyStateComponent,
    EmptyStateDialogComponent,
    NoSensitiveDataAlertComponent,
    SessionExpiredDialogComponent,
    ImageCropperDialogComponent,
    ImageDetailDialogComponent,
    FileViewComponent,
    StudentEducationProgressComponent,
  ],
  exports: [
    CustomTableSortIconComponent,
    UploadAreaDNDComponent,
    ProgressBarComponent,
    CustomPaginatorDirective,
    SearchFilterPipe,
    NewsArticleComponent,
    EventPickerComponent,
    EventRoomPlanningComponent,
    CheckPermissionsDirective,
    DefaultRouteComponent,
    CopyToClipboardComponent,
    ChangePasswordDialogComponent,
    EditMasterDataDialogComponent,
    FilterNewsArticlesPipe,
    EducationProgressDialogComponent,
    ErrorMessageComponent,
    FilterComponent,
    FilterTagsComponent,
    ElogCellComponent,
    PracticalWorkTableComponent,
    PracticalWorkDetailsComponent,
    TreatmentCasesComponent,
    TreatmentCaseDetailsComponent,
    CreateEditSupervisionAppointmentsComponent,
    EmptyStateComponent,
    EmptyStateDialogComponent,
    NoSensitiveDataAlertComponent,
    SessionExpiredDialogComponent,
    ImageCropperModule,
    ImageCropperDialogComponent,
    ImageDetailDialogComponent,
    FileViewComponent,
    StudentEducationProgressComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    ClipboardModule,
    CustomTimePipe,
    ImageCropperModule,
    StripHtmlPipe,
  ],
})
export class SharedModule {}
