import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/configs/environment';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  private sessionTimeout: moment.Moment;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private alertService: AlertService,
    private cookieService: CookieService
  ) {
    const sessionTimeoutCookie = this.cookieService.get(
      'Impact.SessionTimeOutCookie'
    );
    this.sessionTimeout = moment(new Date(sessionTimeoutCookie + ' UTC'));
  }

  public isLoading: boolean = true;
  ngOnInit(): void {
    if (moment().isAfter(this.sessionTimeout)) {
      window.location.href = environment.authority_short + 'login';
      return;
    }

    this.authService.loginUser().subscribe({
      next: async response => {
        console.debug('loginUser backend response', response);
        if (!response?.success) {
          console.error(response?.message);
          this.alertService.showErrorAlert(
            'Login fehlgeschlagen',
            'Bitte versuchen Sie es erneut.'
          );
          this.router.navigate(['logout']);
          return;
        }
        this.userService.currentUser = await this.userService.parseBackendUser(
          response.data
        );
        if (
          !this.userService.currentUser.id_institute ||
          (!this.userService.currentUser.id_role &&
            !this.userService.currentUser.isSuperadmin)
        ) {
          this.isLoading = false;
          return;
        } else {
          this.router.navigate(['eleguide']);
        }
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Login fehlgeschlagen',
          'Bitte versuchen Sie es erneut.'
        );
        this.router.navigate(['logout']);
      },
    });
  }
}
