import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Role } from 'src/app/models/permission.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { UpdateUserDialogComponent } from './update-user-dialog/update-user-dialog.component';

interface TableUser extends User {
  institute?: {
    id: number;
    name: string;
  };
}

@Component({
  selector: 'app-users-and-roles',
  templateUrl: './users-and-roles.component.html',
  styleUrls: ['./users-and-roles.component.scss'],
})
export class UsersAndRolesComponent {
  public dataSource: MatTableDataSource<TableUser> =
    new MatTableDataSource<TableUser>();
  public isLoading = true;
  public displayedColumns = ['user', 'role', 'institute', 'actions'];
  public searchForm = new FormGroup({
    searchText: new FormControl(''),
  });

  private Role: Role;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private userService: UserService,
    private dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.getAllUsersWithoutInstituteOrRole();
  }

  private getAllUsersWithoutInstituteOrRole() {
    this.userService.getAllUsersWithoutInstituteOrRole().subscribe({
      next: async response => {
        console.debug(
          'getAllUsersWithoutInstituteOrRole Backend Response',
          response
        );
        if (!response.success) {
          console.error(response.message);
          return;
        }
        this.dataSource.data = response.data
          ? await Promise.all(
              response.data?.map(async (userData: any): Promise<User> => {
                const user: TableUser =
                  await this.userService.parseBackendUser(userData);
                user.institute = userData.institute;
                return user;
              })
            )
          : [];

        this.dataSource.filterPredicate = (data, filter) => {
          const dataStr =
            data.email?.toLowerCase() +
            data.name.academicTitle?.toLowerCase() +
            data.name.firstname?.toLowerCase() +
            data.name.lastname?.toLowerCase();
          return dataStr ? dataStr.indexOf(filter) != -1 : false;
        };

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'email':
              return item.email;
            case 'role':
              return item.id_role;
            default:
              return item[property];
          }
        };
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      error: error => {
        console.error(error);
        this.isLoading = false;
      },
    });
  }

  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * getRoleName
   * Get the role name based on the role id
   * @returns void
   */
  public getRoleName(role: number): string {
    switch (role) {
      case Role.ADMINISTRATOR:
        return 'Verwaltung';
      case Role.LECTURER:
        return 'Lehrpersonal';
      case Role.STUDENT:
        return 'Kandidat';
      default:
        return '-';
    }
  }

  /**
   * getLabelBackgroundColor
   * Get the label background color
   * @param role
   * @returns
   */
  public getLabelBackgroundColor(role: number): string {
    switch (role) {
      case Role.ADMINISTRATOR:
        return '#49881D';
      case Role.LECTURER:
        return '#20BBAB';
      case Role.STUDENT:
        return '#79B63F';
      default:
        return '#BBBDBE';
    }
  }

  public openEditUserDialog(user: User) {
    const dialogRef = this.dialog.open(UpdateUserDialogComponent, {
      data: {
        user: user,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllUsersWithoutInstituteOrRole();
      }
    });
  }

  public openDeleteUserDialog(user: User) {
    // TODO
  }
}
