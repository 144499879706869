import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StudentDetailComponent } from './student-detail.component';
import { CourseElogComponent } from './course-elog/course-elog.component';
import { featureGuard } from 'src/app/guards/feature.guard';
import { Feature, Permission } from 'src/app/models/permission.model';
import { StudentEducationProgressComponent } from './student-education-progress/student-education-progress.component';
import { PracticalWorkTableComponent } from 'src/app/components/shared-components/practical-work-table/practical-work-table.component';
import { CreateEditPracticalWorkComponent } from '../../../education/practical-work/create-edit-practical-work/create-edit-practical-work.component';
import { StudentOverviewOfFeesComponent } from './student-overview-of-fees/student-overview-of-fees.component';
import { TreatmentCasesComponent } from 'src/app/components/shared-components/treatment-cases/treatment-cases.component';
import { CreateEditSupervisionAppointmentsComponent } from '../../../education/ambulatory-part/create-supervision-appointments/create-edit-supervision-appointments.component';
import { PracticalWorkDetailsComponent } from 'src/app/components/shared-components/practical-work-details/practical-work-details.component';
import { formGuard } from 'src/app/guards/form.guard';
import { TreatmentCaseOverviewComponent } from 'src/app/components/shared-components/treatment-case-overview/treatment-case-overview.component';
import { SupervisionDetailsComponent } from 'src/app/components/shared-components/supervision-details/supervision-details.component';

const routes: Routes = [
  {
    path: '',
    component: StudentDetailComponent,
    children: [
      {
        path: '',
        redirectTo: 'course-elog',
        pathMatch: 'full',
      },
      {
        path: 'course-elog',
        component: CourseElogComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.VIEW,
        },
      },
      {
        path: 'education-progress',
        component: StudentEducationProgressComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.VIEW,
        },
      },
      {
        path: 'practical-work',
        component: PracticalWorkTableComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.VIEW,
        },
      },
      {
        path: 'practical-work/:id',
        component: PracticalWorkDetailsComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.PRACTICAL_WORK,
          permission: Permission.VIEW,
        },
      },
      {
        path: 'practical-work/edit/:id',
        component: CreateEditPracticalWorkComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.VIEW,
        },
      },
      {
        path: 'ambulatory-part',
        component: TreatmentCasesComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.VIEW,
        },
      },
      {
        path: 'ambulatory-part/supervision/:id/:id_appointment',
        component: SupervisionDetailsComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.TREATMENT_CASE,
          permission: Permission.VIEW,
        },
      },
      {
        path: 'ambulatory-part/:id',
        component: TreatmentCaseOverviewComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.TREATMENT_CASE,
          permission: Permission.VIEW,
        },
      },
      {
        path: 'ambulatory-part/edit-supervision-appointment/:id/:id_appointment',
        component: CreateEditSupervisionAppointmentsComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.TREATMENT_CASE,
          permission: Permission.ADMIN,
        },
        canDeactivate: [formGuard],
      },
      {
        path: 'overview-of-fees',
        component: StudentOverviewOfFeesComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.VIEW,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StudentDetailRoutingModule {}
