import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent {
  @Input() textToCopy: string;
  @Input() type: string;

  constructor(
    private clipboard: Clipboard,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  /**
   * copyToClipboard
   * @text Copy the text to the clipboard
   * @returns void
   */
  public copyToClipboard(type: string, text: string): void {
    const success = this.clipboard.copy(text);

    if (success) {
      this.alertService.showSuccessAlert(
        `${type} kopiert!`,
        `${type} '${text}' erfolgreich kopiert.`
      );
    } else {
      this.alertService.showErrorAlert(
        'Fehler!',
        'Fehler beim Kopieren des Textes.'
      );
    }
  }
}
