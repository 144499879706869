import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { environment } from 'src/configs/environment';
import { Label } from '../models/label.model';
import { UserService } from './user.service';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  private availableColors: string[] = [
    '#F6A500',
    '#D26405',
    '#F93816',
    '#FF6584',
    '#49881D',
    '#79B63F',
    '#4CD68B',
    '#192A51',
    '#004976',
    '#573EE9',
    '#436EF0',
    '#667085',
    '#BBBDBE',
    '#000000',
  ];

  /**
   * getAvailableColors
   * returns all available colors
   * @returns string[]
   */
  public getAvailableColors(): string[] {
    return this.availableColors;
  }

  /**
   * getAllLabels
   * gets all labels from the current institute
   * @returns Observable<any>
   */
  public getAllLabels(): Observable<any> {
    const id_institute = this.userService.currentUser.id_institute;
    return this.http
      .get(
        environment.authority_short +
          'label/getAllLabels.php?id_institute=' +
          id_institute
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createLabel
   * creates a new label
   * @param label
   * @returns Observable<any>
   */
  public createLabel(label: Label, id_institute: number): Observable<any> {
    const formData = new FormData();
    formData.append('id_institute', id_institute.toString());
    formData.append('name', label.name);
    formData.append('color', label.color);
    formData.append('id_created_by', label.createdBy.id.toString());

    return this.http.post(
      environment.authority_short + 'label/createLabel.php',
      formData
    );
  }

  /**
   * updateLabel
   * updates a label
   * @param label
   * @returns Observable<any>
   */
  public updateLabel(label: Label): Observable<any> {
    const formData = new FormData();
    formData.append('id', label.id.toString());
    formData.append('name', label.name);
    formData.append('color', label.color);

    return this.http.post(
      environment.authority_short + 'label/updateLabel.php',
      formData
    );
  }

  /**
   * deleteLabel
   * deletes a label
   * @param label
   * @returns Observable<any>
   */
  public deleteLabel(label: Label): Observable<any> {
    const formData = new FormData();
    formData.append('id', label.id.toString());

    return this.http.post(
      environment.authority_short + 'label/deleteLabel.php',
      formData
    );
  }
}
