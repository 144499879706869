<div class="table">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- Education column -->
    <ng-container matColumnDef="education">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Ausbildung</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="education">
        </app-custom-table-sort-icon>
      </th>
      <td mat-cell *matCellDef="let educationCourse">
        {{ educationCourse.title }}
      </td>
    </ng-container>

    <!-- Accreditation column -->
    <ng-container matColumnDef="accreditation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Akkreditiert in</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="accreditation">
        </app-custom-table-sort-icon>
      </th>
      <td mat-cell *matCellDef="let educationCourse">
        {{ educationCourse.accreditation_year }}
      </td>
    </ng-container>

    <!-- Regulation column -->
    <ng-container matColumnDef="regulation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Ordnung</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="regulation">
        </app-custom-table-sort-icon>
      </th>
      <td mat-cell *matCellDef="let educationCourse">
        <div class="label label-white">
          <i class="fa-light fa-book label-icon"></i>
          <span class="label-text"> {{ educationCourse.regulation }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Edit column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let educationCourse">
        <div class="table-button-icons">
          <button
            type="button"
            class="table-button-icon"
            [matTooltip]="
              educationCourse.regulation === 'Ausbildungsordnung'
                ? 'Ausbildungsgang löschen'
                : 'Weiterbildungsgang löschen'
            "
            (click)="onDeleteEducationCourse(educationCourse.id)">
            <i class="fa-light fa-trash-can"></i>
          </button>
          <button
            type="button"
            class="table-button-icon"
            [matTooltip]="
              educationCourse.regulation === 'Ausbildungsordnung'
                ? 'Ausbildungsgang bearbeiten'
                : 'Weiterbildungsgang bearbeiten'
            "
            (click)="onEditEducationCourse(educationCourse.id)">
            <i class="fa-light fa-pen"></i>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        <div *ngIf="isLoading" class="loading-wrapper">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate"
            diameter="32">
          </mat-progress-spinner>
        </div>
        <div
          *ngIf="!isLoading"
          class="mat-cell table-no-match hasomed-text-body font-grey">
          Keinen Aus- Weiterbildungsgang angelegt.
        </div>
      </td>
    </tr>
  </table>
  <mat-divider></mat-divider>
  <mat-paginator
    custom-paginator
    [appCustomLength]="dataSource.filteredData.length"
    [pageSize]="7"
    [renderButtonsNumber]="1"
    aria-label="Select page"></mat-paginator>
</div>
