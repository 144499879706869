<div class="education-progress-dialog flex-c gap-24">
  <!-- HEADER -->
  <div class="header flex-r gap-16 align-center">
    <h1 class="hasomed-text-h1">
      Ausbildungsfortschritt
      {{
        showName ? 'von ' + user.name.firstname + ' ' + user.name.lastname : ''
      }}
    </h1>
    <button
      type="button"
      class="hasomed-button-icon"
      matTooltip="Schließen"
      (click)="onClose()">
      <i class="fa-light fa-x"></i>
    </button>
  </div>

  <div class="flex-c gap-24 scrollable-content align-center">
    <div class="hasomed-text-body" *ngIf="educationProgress.length === 0">
      Dieser Nutzer hat keinen Aus- oder Weiterbildungsgang!
    </div>
    <div *ngFor="let studentProgress of educationProgress; let i = index">
      <div class="education-progress flex-c gap-16">
        <div class="flex-r justify-space-between">
          <span class="hasomed-text-small">
            {{ studentProgress.type }}
          </span>
          <button
            *ngIf="this.userService.currentUser.id_role === role.STUDENT"
            type="button"
            class="table-button-icon"
            [matTooltip]="getTooltip(studentProgress.type)"
            (click)="goToPage(studentProgress.type)">
            <i class="fa-light fa-arrow-up-right-from-square"></i>
          </button>
        </div>
        <div class="flex-r gap-16 align-center">
          <div class="progress-bar-container">
            <div
              class="progress-bar"
              ngClass="progress-orange"
              [ngStyle]="{
                width: studentProgress.procentual + '%'
              }"></div>
          </div>
          <span class="progress-label">
            {{
              studentProgress.progress +
                ' / ' +
                studentProgress.contingent +
                'h'
            }}
          </span>
        </div>
      </div>

      <mat-divider
        class="divider"
        *ngIf="i === educationProgress.length - 2"></mat-divider>
    </div>
  </div>

  <!-- close button -->
  <div class="flex-r close-button">
    <button
      type="button"
      class="hasomed-text-button hasomed-button-white-grey"
      (click)="onClose()">
      <i class="fa-light fa-x hasomed-button-icon-sm"></i>
      <span class="hasomed-button-text">Schließen</span>
    </button>
  </div>
</div>
