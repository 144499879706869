<div class="file-upload" [ngClass]="{ disabled: disabled }">
  <div class="file-upload-base" appDnd (fileDropped)="onFileDropped($event)">
    <input
      type="file"
      #fileDropRef
      [id]="inputId"
      [disabled]="disabled"
      [multiple]="multiple"
      accept="{{ requiredFileMimeTypesString }}"
      (change)="fileBrowseHandler($event)"
      #fileInput />
    <div class="file-upload-icon">
      <i class="fa-light fa-cloud-arrow-up icon"></i>
    </div>
    <div class="file-upload-text">
      <span class="hasomed-text-body title">
        <span class="link">Klicken für Upload</span> oder ziehen Sie eine Datei
        auf diese Fläche.
      </span>
      <span class="hasomed-text-small description">
        Dateiformate: {{ requiredFileTypesString }} (max. {{ maxFileSize }})
      </span>
    </div>
    <mat-error *ngIf="error == true"> Upload fehlgeschlagen. </mat-error>
    <mat-error *ngIf="errorSize == true"> Datei zu groß. </mat-error>
  </div>
  <app-no-sensitive-data-alert
    *ngIf="
      showNoSensitiveDataAlert && sensitiveDataAlert
    "></app-no-sensitive-data-alert>
  <div class="files-list">
    <app-file-view
      class="existing-file"
      [deleteEnabled]="true"
      (deleteFile)="onDeleteExistingFile($event)"
      *ngFor="let file of existingFiles"
      [file]="file"></app-file-view>
  </div>
  <div *ngIf="showFileList && uploadedFiles?.length > 0" class="files-list">
    <div
      class="single-file"
      *ngFor="let file of uploadedFiles; let i = index"
      id="{{ scrollTo ? 'file-' + i : null }}">
      <div class="single-file-content">
        <div class="file-icon">
          <i class="fa-light fa-file"></i>
        </div>
        <div class="file-info">
          <div class="text-info">
            <div class="hasomed-text-body file-name">
              <span>{{ file?.name }}</span>
              <div *ngIf="file.uploaded === true" class="flex-r gap-8">
                <div
                  class="icon-button"
                  (click)="onDownload(file)"
                  matTooltip="Datei herunterladen">
                  <i class="fa-light fa-cloud-arrow-down icon-sm"></i>
                </div>
                <div
                  class="icon-button"
                  (click)="onDelete(file)"
                  matTooltip="Datei löschen">
                  <i class="fa-light fa-trash-can icon-sm"></i>
                </div>
              </div>
              <div
                *ngIf="file.progress == 100 && !file.uploaded"
                class="file-upload-icon">
                <i class="fa-solid fa-check icon"></i>
              </div>
              <div *ngIf="file.progress < 100" class="file-upload-waiting">
                <mat-spinner class="progress-spinner"></mat-spinner>
              </div>
            </div>
            <div class="hasomed-text-small">
              <span>{{ formatBytes(file?.size, 2) }}</span>
            </div>
          </div>
          <div class="progress-info">
            <div class="progress-bar">
              <app-progress-bar [progress]="file?.progress"></app-progress-bar>
            </div>
            <div class="hasomed-text-small">
              <span>{{ file?.progress }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
