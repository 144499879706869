import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelfAwarenessEntry } from 'src/app/models/self-awareness-entry.model';
import { SelfAwarenessService } from 'src/app/services/self-awareness.service';
import { Location } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-self-awareness-details',
  templateUrl: './self-awareness-details.component.html',
  styleUrls: ['./self-awareness-details.component.scss'],
})
export class SelfAwarenessDetailsComponent implements OnInit, OnDestroy {
  public selfAwarenessEntry: SelfAwarenessEntry;
  public isLoading: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private selfAwarenessService: SelfAwarenessService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (!params.id) {
        return;
      }

      const id = +atob(params.id);

      this.selfAwarenessService
        .getSelfAwarenessEntry(id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async result => {
            console.debug('getSelfAwarenessEntry result', result);

            if (!result.success) {
              console.error(result.message);
              this.isLoading = false;
              return;
            }
            this.selfAwarenessEntry = result.data
              ? await this.selfAwarenessService.parseBackendSelfAwarenessEntry(
                  result.data
                )
              : null;
            this.isLoading = false;
          },
          error: error => {
            console.error(error.message);
            this.isLoading = false;
          },
        });
    });
  }

  /**
   * onEditSelfAwarenessEntry
   */
  public onEditSelfAwarenessEntry() {
    if (this.selfAwarenessEntry) {
      this.router.navigate(
        ['../edit', btoa(String(this.selfAwarenessEntry.id))],
        {
          relativeTo: this.activatedRoute,
        }
      );
    }
  }

  /**
   * onGoBack
   */
  public onGoBack() {
    this.location.back();
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
