<form
  class="component-content"
  [formGroup]="onboardingForm"
  (ngSubmit)="onSaveAndContinue()">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Stammdaten</h1>
      <h3 class="hasomed-text-h3">Stammdaten des Instituts</h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        *ngIf="false"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <!-- Submit button -->
      <button
        type="submit"
        class="hasomed-text-button hasomed-button-primary"
        [disabled]="!onboardingForm.valid || onboardingForm.valid !== true">
        <i class="fa-light fa-arrow-right hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Speichern und weiter</span>
      </button>
    </div>
  </div>

  <!-- MAIN CONTENT -->
  <div class="main-content">
    <!-- NAME -->
    <div class="formular-row">
      <label class="formular-row-label" for="name">
        <span class="label-title">
          Institutsname
          <span *ngIf="isRequired(onboardingForm.get('name'))"> * </span>
        </span>
        <span class="label-description">
          Geben Sie Ihrem Institut einen Namen
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="name"
            class="hasomed-text-input"
            type="text"
            formControlName="name"
            placeholder="Name" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="onboardingForm.get('name')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- DESCRIPTION -->
    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">
          Beschreibung des Instituts oder Slogan
          <span *ngIf="isRequired(onboardingForm.get('description'))"> * </span>
        </span>
        <span class="label-description"> Ergänzende Informationen </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="description"
            class="hasomed-text-input"
            type="text"
            formControlName="description"
            placeholder="Beschreibung" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="onboardingForm.get('description')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- LOGO -->
    <div class="formular-row">
      <label class="formular-row-label" for="logo">
        <span class="label-title">
          Logo des Instituts
          <span *ngIf="isRequired(onboardingForm.get('logo'))"> * </span>
        </span>
        <span class="label-description">
          Bitte laden Sie ein Logo für Ihr Institut hoch
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-image-upload">
          <div
            class="uploaded-image-container"
            *ngIf="onboardingForm.value.logo">
            <img
              class="uploaded-image"
              alt="Institut Logo"
              [attr.src]="onboardingForm.value.logo" />
            <div class="vertical-spacer"></div>
          </div>

          <app-upload-area-dnd
            class="image-upload-area"
            [logs]="false"
            inputId="logo"
            [showFileList]="false"
            [requiredFileTypes]="requiredFileTypes"
            (change)="imageChangeEvent($event)">
          </app-upload-area-dnd>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="onboardingForm.get('logo')"></app-error-message>
      </div>
    </div>
    <!-- <mat-divider></mat-divider> -->

    <!-- COLOR PRIMARY -->
    <!-- <div class="formular-row">
      <label class="formular-row-label" for="color_primary">
        <span class="label-title">
          Primäre Farbe
          <span *ngIf="isRequired(onboardingForm.get('color_primary'))">
            *
          </span>
        </span>
        <span class="label-description">
          Welche Farbe verwendet Ihr Institut?
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="hasomed-color-input">
            <input
              id="color_primary"
              formControlName="color_primary"
              [(colorPicker)]="color_primary"
              color="$event"
              [style.background]="color_primary"
              #colorPickerPrimary />
            <button
              type="button"
              class="hasomed-button hasomed-button-color-picker"
              (click)="colorPickerPrimary.click()">
              <div class="hasomed-button-icon">
                <mat-icon class="fa-light fa-eye-dropper"></mat-icon>
              </div>
              <div class="hasomed-button-text">
                <span>Farbe auswählen</span>
              </div>
            </button>
          </div>
        </div>
        <app-error-message
          [formField]="onboardingForm.get('color_primary')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider> -->

    <!-- COLOR SECONDARY  -->
    <!-- <div class="formular-row">
      <label class="formular-row-label" for="color_secondary">
        <span class="label-title">
          Sekundäre Farbe
          <span *ngIf="isRequired(onboardingForm.get('color_secondary'))">
            *
          </span>
        </span>
        <span class="label-description">
          Welche Farbe verwendet Ihr Institut?
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="hasomed-color-input">
            <input
              id="color_secondary"
              formControlName="color_secondary"
              [(colorPicker)]="color_secondary"
              color="$event"
              [style.background]="color_secondary"
              #colorPickerSecondary />
            <button
              type="button"
              class="hasomed-button hasomed-button-color-picker"
              (click)="colorPickerSecondary.click()">
              <div class="hasomed-button-icon">
                <mat-icon class="fa-light fa-eye-dropper"></mat-icon>
              </div>
              <div class="hasomed-button-text">
                <span>Farbe auswählen</span>
              </div>
            </button>
          </div>
        </div>
        <app-error-message
          [formField]="
            onboardingForm.get('color_secondary')
          "></app-error-message>
      </div>
    </div> -->
    <!-- <mat-divider></mat-divider> -->

    <!-- COLOR ACCENT 1  -->
    <!-- <div class="formular-row">
      <label class="formular-row-label" for="color_akzent1">
        <span class="label-title">
          Akzent Farbe
          <span *ngIf="isRequired(onboardingForm.get('color_akzent1'))">
            *
          </span>
        </span>
        <span class="label-description">
          Welche Farbe verwendet Ihr Institut?
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="hasomed-color-input">
            <input
              id="color_akzent1"
              formControlName="color_akzent1"
              [(colorPicker)]="color_akzent1"
              color="$event"
              [style.background]="color_akzent1"
              #colorPickerAccent1 />
            <button
              type="button"
              class="hasomed-button hasomed-button-color-picker"
              (click)="colorPickerAccent1.click()">
              <div class="hasomed-button-icon">
                <mat-icon class="fa-light fa-eye-dropper"></mat-icon>
              </div>
              <div class="hasomed-button-text">
                <span>Farbe auswählen</span>
              </div>
            </button>
          </div>
        </div>
        <app-error-message
          [formField]="onboardingForm.get('color_akzent1')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider> -->

    <!-- COLOR ACCENT 2  -->
    <!-- <div class="formular-row">
      <label class="formular-row-label" for="color_akzent2">
        <span class="label-title">
          Akzent Farbe 2
          <span *ngIf="isRequired(onboardingForm.get('color_akzent2'))">
            *
          </span>
        </span>
        <span class="label-description">
          Welche Farbe verwendet Ihr Institut?
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="hasomed-color-input">
            <input
              id="color_akzent2"
              formControlName="color_akzent2"
              [(colorPicker)]="color_akzent2"
              color="$event"
              [style.background]="color_akzent2"
              #colorPickerAccent2 />
            <button
              type="button"
              class="hasomed-button hasomed-button-color-picker"
              (click)="colorPickerAccent2.click()">
              <div class="hasomed-button-icon">
                <mat-icon class="fa-light fa-eye-dropper"></mat-icon>
              </div>
              <div class="hasomed-button-text">
                <span>Farbe auswählen</span>
              </div>
            </button>
          </div>
        </div>
        <app-error-message
          [formField]="onboardingForm.get('color_akzent2')"></app-error-message>
      </div>
    </div> -->
  </div>
</form>
