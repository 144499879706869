<div *ngIf="isLoading" class="loading-wrapper">
  <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
  </mat-progress-spinner>
</div>
<div class="component-content">
  <!-- Header -->
  <div class="header">
    <div class="header-title">
      <div class="user-container-header" *ngIf="!isLoading">
        <div class="user-icon">
          <img
            src="{{
              student.profilePicture ?? '../../../../../assets/img/user.jpg'
            }}"
            alt=""
            class="user-icon-image" />
        </div>
        <div class="user-info">
          <h1 class="hasomed-text-h1">
            {{ student.name.academicTitle }} {{ student.name.firstname }}
            {{ student.name.lastname }}
          </h1>
          <h3 class="user-info hasomed-text-h3">
            {{ student.userIdentifier ?? '-' }}
          </h3>
        </div>
      </div>
    </div>
    <div class="header-buttons">
      <button
        *appCheckPermissions="{
          feature: feature.STUDENT_ADMINISTRATION,
          permission: permission.ADMIN
        }"
        class="hasomed-text-button hasomed-button-white-red"
        (click)="onChangeInstitut()">
        <i class="fa-light fa-right-left-large hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">PiA möchte Institut wechseln</span>
      </button>

      <button
        class="hasomed-text-button hasomed-button-primary"
        (click)="goBack()">
        <i class="fa-light fa-rotate-left hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Zurück zur Übersicht</span>
      </button>
    </div>
  </div>

  <div class="padding-0-20">
    <div class="child-navigation student-detail-child-nav">
      <a
        *ngFor="let link of navLinks"
        #rla="routerLinkActive"
        [routerLink]="link.link"
        routerLinkActive="hasomed-button-transparent-primary"
        [class.hasomed-button-blank]="!rla.isActive"
        class="hasomed-text-button">
        <span class="hasomed-text-menu"> {{ link.label }}</span>
      </a>
    </div>
  </div>

  <!-- Main content -->
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
</div>
