import { Component, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { EmptyStateDialogComponent } from 'src/app/components/shared-components/empty-state-dialog/empty-state-dialog.component';
import { ImageDetailDialogComponent } from 'src/app/components/shared-components/image-detail-dialog/image-detail-dialog.component';
import {
  FilterDateRange,
  Filter,
  FilterType,
} from 'src/app/models/filter.model';
import { User } from 'src/app/models/user.model';
import { FilterLecturerPipe } from 'src/app/pipes/filter-lecturer.pipe';
import { UserService } from 'src/app/services/user.service';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-lecturers',
  templateUrl: './lecturers.component.html',
  styleUrls: ['./lecturers.component.scss'],
})
export class LecturersComponent {
  displayedColumns: string[] = [
    'name',
    'hiringDate',
    'label',
    'activeCourses',
    'actions',
  ];
  dataSource: MatTableDataSource<User> = new MatTableDataSource();
  public allLecturers: User[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isLoading = true;

  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;

  private dateRangeSubject: Subject<FilterDateRange | null> = new Subject();
  public dateRangeFilter: Filter = { type: FilterType.DATE_RANGE, value: null };
  public lecturerFilter: Filter[] = [
    this.dateRangeFilter,
    {
      type: FilterType.PENDING_ELOGS,
      value: null,
    },
    {
      type: FilterType.LABEL,
      value: null,
    },
  ];
  public hasActiveFilterValue = hasActiveFilterValue;
  public filterOpened: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getData();

    this.dateRangeSubject.subscribe({
      next: dateRange => {
        if (!dateRange) {
          this.searchForm.patchValue({
            dateRange: {
              start: null,
              end: null,
            },
          });
        }
        this.dateRangeFilter.value = dateRange;
        this.applyLecturerFilter();
      },
    });
  }

  searchForm = this.formBuilder.group({
    searchText: ['', noWhitespaceValidator],
    dateRange: new FormGroup({
      start: new FormControl(null, Validators.required),
      end: new FormControl(null, Validators.required),
    }),
  });

  // get lecturers from backend
  async getData() {
    this.userService.getInstituteUsersByRole(3, true).subscribe({
      next: async response => {
        console.debug('get lecturers backend response:', response);
        if (!response.success) {
          console.error(response.message);
          this.isLoading = false;
          return;
        }

        this.allLecturers = response.data
          ? await Promise.all(
              response.data?.map(async (userData: User): Promise<User> => {
                return this.userService.parseBackendUser(userData);
              })
            )
          : [];
        this.dataSource.data = this.allLecturers;

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'name':
              return item.name.firstname + ' ' + item.name.lastname;
            case 'hiringDate':
              return item.entryDate;
            case 'label':
              return item.status?.name;
            default:
              return item[property];
          }
        };

        this.dataSource.filterPredicate = (data, filter) => {
          const dataStr =
            data.name.academicTitle?.toLowerCase() +
            ' ' +
            data.name.firstname.toLowerCase() +
            ' ' +
            data.name.lastname.toLowerCase();
          return dataStr.indexOf(filter) != -1;
        };

        this.isLoading = false;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      error: error => {
        console.error(error);
        this.isLoading = false;
      },
    });
  }

  /**
   * lecturerFilterChanged
   * gets called when the lecturer filter changed
   * @param lecturerFilter Filter[]
   * @returns void
   */
  public lecturerFilterChanged(lecturerFilter: Filter[]): void {
    this.lecturerFilter = lecturerFilter;

    if (!lecturerFilter.includes(this.dateRangeFilter)) {
      lecturerFilter.push(this.dateRangeFilter);
    }

    this.applyLecturerFilter();
    this.filterMenuTrigger.closeMenu();

    // find FILTER_TYPE.DATE_RANGE and update date range
    const dateRangeFilter = lecturerFilter.find(
      filter => filter.type === FilterType.DATE_RANGE
    );

    if (dateRangeFilter && !dateRangeFilter.value) {
      this.dateRangeSubject.next(null);
    }
  }

  /**
   * applyLecturerFilter
   * applies the lecturer filter
   * @returns void
   */
  public applyLecturerFilter(): void {
    this.dataSource.data = FilterLecturerPipe.prototype.transform(
      this.allLecturers,
      this.lecturerFilter
    );
  }

  applyDateRange() {
    this.dateRangeSubject.next({
      start: this.searchForm.value.dateRange?.start,
      end: this.searchForm.value.dateRange?.end,
    });
  }

  applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openLecturerDetail(lecturer_id: number) {
    this.router.navigate(['detail', btoa(String(lecturer_id))], {
      relativeTo: this.activatedRoute,
    });
  }

  deleteLecturer(id: number) {
    this.dialog.open(EmptyStateDialogComponent);
  }

  /**
   * openProfilePictureDialog
   * opens the profile picture dialog
   * @param user
   */
  public openProfilePictureDialog(user: User): void {
    this.dialog.open(ImageDetailDialogComponent, {
      data: {
        image: user.profilePicture,
        viewOnly: true,
      },
    });
  }
}
