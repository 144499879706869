<form [formGroup]="form" class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Dokument bearbeiten' : 'Dokument hochladen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie IhrDokument.'
            : 'Laden Sie Ihr Dokument hoch.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Abbrechen</span>
      </button>
      <button
        type="submit"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onSubmit()">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading && editMode" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <div *ngIf="!isLoading || !editMode" class="main-content">
    <div class="formular-row" *ngIf="editMode">
      <label class="formular-row-label" for="filename">
        <span class="label-title">
          Titel
          <span *ngIf="isRequired(form.get('filename'))"> * </span>
        </span>
        <span class="label-description"> Benennen Sie die Datei. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="type"
            type="text"
            formControlName="filename"
            class="hasomed-text-input"
            placeholder="Dateiname" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="form.get('filename')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="editMode"></mat-divider>

    <div class="formular-row" *ngIf="!editMode">
      <label class="formular-row-label" for="fileUpload">
        <span class="label-title">
          Datei hochladen
          <span *ngIf="isRequired(form.get('fileUpload'))"> * </span>
        </span>
        <span class="label-description">
          Hier können Sie eine Datei für die unten ausgewählten Rollen
          hochladen.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            context="fileUpload"
            [sensitiveDataAlert]="false"
            [logs]="false"
            [existingFiles]="existingFiles"
            (deleteExistingFile)="onDeleteExistingFile()"
            inputId="fileUpload"
            formControlName="fileUpload"
            [multiple]="false"
            [scrollTo]="true"
            [showFileList]="true"
            [requiredFileTypes]="allowedFileTypes"
            maxFileSize="10 MB">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>

    <mat-divider *ngIf="!editMode"></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dokument teilen
          <span *ngIf="isRequired(form.get('rolesWithAccess'))">*</span>
        </span>
        <span class="label-description">
          Bestimmen Sie für welche Rollen die Datei sichtbar sein soll.
        </span>
      </div>
      <div class="hasomed-input-field">
        <mat-form-field class="hasomed-select">
          <mat-select
            id="rolesWithAccess"
            formControlName="rolesWithAccess"
            class="hasomed-select"
            placeholder="Berechtigte Rollen"
            multiple>
            <mat-option [value]="2" class="hasomed-text-body">
              Verwaltung
            </mat-option>
            <mat-option [value]="3" class="hasomed-text-body">
              Lehrpersonal
            </mat-option>
            <mat-option [value]="4" class="hasomed-text-body">
              Kandidaten
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Error Messages -->
        <app-error-message
          [formField]="form.get('rolesWithAccess')"></app-error-message>
      </div>
    </div>
  </div>
</form>
