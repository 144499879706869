<form
  class="component-content"
  [formGroup]="practicalWorkForm"
  (ngSubmit)="onSubmit()">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Eintrag bearbeiten' : 'Neuen Eintrag vornehmen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie einen bestehenden Eintrag'
            : 'Erstellen Sie einen neuen Eintrag'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <!-- LOADING WRAPPER -->
  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Praktische Tätigkeit
          <span *ngIf="isRequired(practicalWorkForm.get('type'))"> * </span>
        </span>
        <span class="label-description">
          Um welche Art der praktischen Tätigkeit handelt es sich?
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select
              id="type"
              formControlName="type"
              class="hasomed-select"
              placeholder="Wählen Sie Ihre Praktische Tätigkeit">
              <mat-option [value]="1" class="hasomed-text-body">
                Psychiatrisch klinische Einrichtung (§2 Abs. 2 Nr. 1
                PsychTh-APrV)
              </mat-option>
              <mat-option [value]="2" class="hasomed-text-body">
                Einrichtung der psychotherapeutischen Versorgung (§2 Abs. 2 Nr.
                2 PsychTh-APrV)
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="practicalWorkForm.get('type')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="title">
        <span class="label-title">
          Name der Einrichtung
          <span *ngIf="isRequired(practicalWorkForm.get('title'))"> * </span>
        </span>
        <span class="label-description"> Ort der praktischen Tätigkeit </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="title"
            class="hasomed-text-input"
            type="text"
            formControlName="title"
            placeholder="Titel" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="practicalWorkForm.get('title')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Adresse </span>
        <span class="label-description"> Anschrift der Einrichtung </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="flex-c flex-grow gap-8">
            <!-- Street, houseNumber -->
            <div class="formular-row-input">
              <input
                id="street"
                class="hasomed-text-input"
                type="text"
                formControlName="street"
                [placeholder]="
                  'Straße' +
                  (isRequired(practicalWorkForm.get('street')) ? ' *' : '')
                " />
              <input
                id="houseNumber"
                size="6"
                class="hasomed-text-input"
                type="text"
                formControlName="houseNumber"
                [placeholder]="
                  'Hausnummer' +
                  (isRequired(practicalWorkForm.get('houseNumber')) ? ' *' : '')
                " />
            </div>

            <!-- addressAddition -->
            <input
              id="addressAddition"
              class="hasomed-text-input"
              type="text"
              formControlName="addressAddition"
              [placeholder]="
                'Adresszusatz (Etage, ...)' +
                (isRequired(practicalWorkForm.get('addressAddition'))
                  ? ' *'
                  : '')
              " />

            <!-- zipCode, city -->
            <div class="formular-row-input">
              <input
                size="5"
                maxlength="5"
                id="zipCode"
                class="hasomed-text-input"
                type="text"
                formControlName="zipCode"
                [placeholder]="
                  'Postleitzahl' +
                  (isRequired(practicalWorkForm.get('zipCode')) ? ' *' : '')
                " />
              <input
                id="city"
                class="hasomed-text-input"
                type="text"
                formControlName="city"
                [placeholder]="
                  'Stadt / Ort' +
                  (isRequired(practicalWorkForm.get('city')) ? ' *' : '')
                " />
            </div>

            <!-- country -->
            <input
              id="country"
              class="hasomed-text-input"
              type="text"
              formControlName="country"
              [placeholder]="
                'Land' +
                (isRequired(practicalWorkForm.get('country')) ? ' *' : '')
              " />
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="practicalWorkForm.get('street')"></app-error-message>
        <app-error-message
          [formField]="
            practicalWorkForm.get('houseNumber')
          "></app-error-message>
        <app-error-message
          [formField]="
            practicalWorkForm.get('addressAddition')
          "></app-error-message>
        <app-error-message
          [formField]="practicalWorkForm.get('zipCode')"></app-error-message>
        <app-error-message
          [formField]="practicalWorkForm.get('city')"></app-error-message>
        <app-error-message
          [formField]="practicalWorkForm.get('country')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">
          Beschreibung
          <span *ngIf="isRequired(practicalWorkForm.get('description'))">
            *
          </span>
        </span>
        <span class="label-description"> Ergänzende Informationen </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="description"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="description"
            placeholder="Beschreibung"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            practicalWorkForm.get('description')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer der Tätigkeit
          <span *ngIf="isRequired(practicalWorkForm.get('duration'))"> * </span>
        </span>
        <span class="label-description">
          Gesamtdauer der praktischen Tätigkeit in Stunden
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="duration"
            class="hasomed-text-input"
            min="0"
            step="0.5"
            type="number"
            formControlName="duration"
            placeholder="Dauer" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="practicalWorkForm.get('duration')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="responsiblePerson">
        <span class="label-title">
          Verantwortliche*r
          <span *ngIf="isRequired(practicalWorkForm.get('responsiblePerson'))">
            *
          </span></span
        >
        <span class="label-description">
          Zuständige Person für die praktische Tätigkeit
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="responsiblePerson"
            class="hasomed-text-input"
            type="text"
            formControlName="responsiblePerson"
            placeholder="Verantwortliche*r" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            practicalWorkForm.get('responsiblePerson')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label flex-1 align-center">
        <span class="label-title">
          Zeitraum
          <span *ngIf="isRequired(practicalWorkForm.get('dateRange'))">
            *
          </span></span
        >
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div
            class="hasomed-date-range"
            [class.error-border]="
              practicalWorkForm.get('dateRange').invalid &&
              practicalWorkForm.get('dateRange').touched
            ">
            <mat-date-range-input
              class="hasomed-date-range-input"
              formGroupName="dateRange"
              [min]="minDate"
              [rangePicker]="picker">
              <input
                id="startDate"
                matStartDate
                [min]="minDate"
                placeholder="Startdatum"
                formControlName="startDate" />
              <input
                id="endDate"
                matEndDate
                [min]="minDate"
                placeholder="Enddatum"
                formControlName="endDate" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              class="hasomed-date-range-toggle"
              matIconSuffix
              [for]="picker">
              <div matDatepickerToggleIcon class="hasomed-button-icon">
                <i class="fa-light fa-calendar"></i>
              </div>
            </mat-datepicker-toggle>
            <mat-date-range-picker
              #picker
              [restoreFocus]="false"></mat-date-range-picker>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          *ngIf="!practicalWorkForm.get('dateRange').get('endDate').invalid"
          [formField]="
            practicalWorkForm.get('dateRange').get('startDate')
          "></app-error-message>
        <app-error-message
          [formField]="
            practicalWorkForm.get('dateRange').get('endDate')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="note">
        <span class="label-title">
          Freitext
          <span *ngIf="isRequired(practicalWorkForm.get('note'))">
            *
          </span></span
        >
        <span class="label-description"> Freitext für interne Notizen </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="note"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="internalNote"
            placeholder="Freitext für interne Notizen"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="practicalWorkForm.get('note')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title">
          Bestätigung
          <span *ngIf="isRequired(practicalWorkForm.get('documentUpload'))">
            *
          </span>
        </span>
        <span class="label-description">
          Laden Sie hier Ihre Bestätigung für die praktische Tätigkeit hoch.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            context="practicalWorkDocumentsUpload"
            [logs]="false"
            [existingFiles]="existingFiles"
            (deleteExistingFile)="onDeleteExistingFile($event)"
            inputId="documentUpload"
            formControlName="uploadedFiles"
            [multiple]="false"
            [scrollTo]="true"
            [showFileList]="true"
            [requiredFileTypes]="requiredFileTypesDocuments"
            maxFileSize="12 MB">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
