import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, defaultIfEmpty, firstValueFrom, takeUntil } from 'rxjs';
import { Course } from 'src/app/models/course.model';
import {
  ProgressType,
  StudentEducationProgress,
} from 'src/app/models/education-progress.model';
import { Role } from 'src/app/models/permission.model';
import { User } from 'src/app/models/user.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { EducationProgressService } from 'src/app/services/education-progress.service';
import { UserService } from 'src/app/services/user.service';

interface StudentTableData extends User {
  educationProgress: StudentEducationProgress[];
}

@Component({
  selector: 'app-course-participants',
  templateUrl: './course-participants.component.html',
  styleUrls: ['./course-participants.component.scss'],
})
export class CourseParticipantsComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  public columnsToDisplay = ['user'];
  public isLoading = true;
  public course: Course;
  public isStudent: boolean;
  private courseParticipants: User[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private educationProgressService: EducationProgressService,
    private router: Router,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    const id_course = this.activatedRoute.snapshot.paramMap.get('id')
      ? +atob(this.activatedRoute.snapshot.paramMap.get('id'))
      : +atob(this.activatedRoute.parent?.snapshot.paramMap.get('id'));

    this.isStudent = this.userService.currentUser.id_role === Role.STUDENT;

    if (!this.isStudent) {
      this.columnsToDisplay = [
        'user',
        'userIdentifier',
        'educationProgress',
        'entryDate',
        'label',
        'actions',
      ];
    }

    this.getCourseParticipants(id_course);
  }

  /**
   * getCourseParticipants
   * get the course participants by id
   * @param id_course
   */
  private getCourseParticipants(id_course: number): void {
    this.courseService
      .getCourseParticipants(id_course)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getCourseParticipants Backend response', response);
          if (!response.success) {
            console.error('Course participants could not be loaded');
            this.isLoading = false;
            return;
          }
          this.course = await this.courseService.parseBackendCourse(
            response.data
          );

          this.courseParticipants = response.data
            ? await Promise.all(
                response.data.map(async (lecturer: any) => {
                  return await this.userService.parseBackendUser(lecturer);
                })
              )
            : [];

          // add educationProgress to courseStudents when not student
          if (!this.isStudent) {
            const promises = this.courseParticipants?.map(
              async (user: StudentTableData): Promise<any> => {
                const educationProgress =
                  await this.fetchEducationProgress(user);
                user.educationProgress = educationProgress;
                return user;
              }
            );
            this.dataSource.data = promises
              ? await Promise.all(promises)
              : this.courseParticipants;
          } else {
            this.dataSource.data = this.courseParticipants;
          }

          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'user':
                return item.name.firstname + ' ' + item.name.lastname;
              case 'educationProgress':
                return item.educationProgress;
              case 'label':
                return item.status?.name;
              default:
                return item[property];
            }
          };
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
        },
        error: error => {
          console.error('Course details could not be loaded', error);
          this.isLoading = false;
        },
      });
  }

  /**
   * showParticipantDetails
   * navigates to the participants details page
   * @param row
   */
  public showParticipantDetails(row: StudentTableData) {
    this.router.navigate(
      ['../../../', 'students', 'detail', btoa(row.id.toString())],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  /**
   * fetchEducationProgress
   * fetches the education progress from the backend
   * @param user
   * @returns education progress
   */
  private async fetchEducationProgress(
    user: User
  ): Promise<StudentEducationProgress[]> {
    const educationProgress: StudentEducationProgress[] = [];
    try {
      const response: any = await firstValueFrom(
        this.educationProgressService.getEducationProgress(user.id).pipe(
          takeUntil(this.destroy$),
          defaultIfEmpty({
            success: false,
            data: [],
            message: 'No data emitted',
          })
        )
      );
      if (!response.success) {
        if (response.message === 'user has no education course') {
          return educationProgress;
        }
        // if subscription was cancelled
        if (response.message === 'No data emitted') {
          return educationProgress;
        }
        console.error(response.message);
        return educationProgress;
      }
      return response.data;
    } catch (error) {
      console.error('error fetching educationProgress', error);
      return educationProgress;
    }
  }

  /**
   * getTotalEducationProgress
   * get the total education progress
   * @param row
   * @returns total education progress
   */
  public getTotalEducationProgress(row: StudentTableData): number {
    const totalProcentualProgress = row.educationProgress.find(
      (progress: StudentEducationProgress) =>
        progress.type === ProgressType.TOTAL
    )?.procentual;

    return totalProcentualProgress ? totalProcentualProgress : 0;
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
