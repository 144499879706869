<app-event-room-planning
  *ngIf="roomPlanningOpen"
  [title]="courseForm.value.title"
  subtitle="Planen Sie die Räume des Kurses"
  [participants]="courseForm.value.maxStudents"
  [numberOfLecturers]="selectedLecturerIds.length"
  [isCourse]="true"
  [events]="events"
  (onRoomPlanningClose)="roomPlanningChanged($event)">
</app-event-room-planning>

<form
  class="component-content"
  [formGroup]="courseForm"
  (ngSubmit)="onSubmit()">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Kurs bearbeiten' : 'Kurs erstellen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie einen bestehenden Kurs'
            : 'Erstellen Sie einen neuen Kurs'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <i class="fa-light fa-rotate-left hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text"> Zurück zur Übersicht </span>
      </button>

      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>
  <div *ngIf="!isLoading" class="main-content">
    <div class="formular-row" *ngIf="!isLecturer">
      <div class="formular-row-label">
        <span class="label-title">
          Aus- und Weiterbildungsgang
          <span *ngIf="isRequired(courseForm.get('educationCourses'))">*</span>
        </span>
        <span class="label-description">
          Gehört zu welchem Ausbildungsgang?
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="flex-c flex-grow gap-8">
            <ng-container formArrayName="educationCourses">
              <ng-container
                *ngFor="
                  let educationCourse of educationCourses.controls;
                  let i = index
                ">
                <div class="formular-row-input with-delete-btn">
                  <mat-form-field class="hasomed-select">
                    <mat-select
                      [id]="'educationCourse' + i"
                      class="hasomed-select"
                      [formControlName]="i"
                      placeholder="Aus- und Weiterbildungsgang"
                      (valueChange)="updateEducationCourse($event, i)"
                      [compareWith]="compareEducationCourses">
                      <mat-option
                        [value]="educationCourse"
                        class="hasomed-text-body"
                        *ngFor="
                          let educationCourse of availableEducationCourses
                        ">
                        {{ educationCourse.title }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    [disabled]="educationCourses.length <= 1"
                    type="button"
                    class="hasomed-text-button hasomed-button-white-grey"
                    (click)="removeEducationCourse(i)">
                    <i class="hasomed-button-icon-sm fa-light fa-trash-can"></i>
                  </button>
                </div>
              </ng-container>
            </ng-container>
            <button
              [disabled]="
                educationCourses?.length === availableEducationCourses?.length
              "
              type="button"
              (click)="addEducationCourse()"
              class="hasomed-text-button hasomed-button-white-grey button-flex-start">
              <i class="fa-light fa-plus hasomed-button-icon-sm"></i>
              <span class="hasomed-button-text"> weitere hinzufügen </span>
            </button>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('educationCourses')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="title">
        <span class="label-title">
          Kurstitel <span *ngIf="isRequired(courseForm.get('title'))">*</span>
        </span>
        <span class="label-description"> Name des Kurses </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="title"
            class="hasomed-text-input"
            type="text"
            formControlName="title" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('title')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">
          Beschreibung
          <span *ngIf="isRequired(courseForm.get('description'))">*</span>
        </span>
        <span class="label-description"> Ergänzende Informationen </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="description"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="description"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('description')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="titlePicture">
        <span class="label-title">
          Titelbild des Kurses
          <span>*</span>
        </span>
        <span class="label-description">
          Hier können Sie ein Bild für den Kurs hochladen oder eins aus der
          Vorauswahl nehmen.
        </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div id="title-picture" class="flex-c title-picture-upload-wrapper">
            <div class="formular-row-input-image-upload">
              <div
                class="uploaded-image-container"
                *ngIf="courseForm.get('titlePicture').value">
                <img
                  class="uploaded-image"
                  alt="Titelbild Kurs"
                  [attr.src]="courseForm.get('titlePicture').value" />
              </div>
              <app-upload-area-dnd
                class="image-upload-area"
                [context]="'courseTitlePictureUpload'"
                [logs]="false"
                inputId="titlePicture"
                [multiple]="false"
                [scrollTo]="false"
                [showFileList]="false"
                [requiredFileTypes]="requiredFileTypes"
                maxFileSize="10 MB"
                (change)="imageChangeEvent($event)">
              </app-upload-area-dnd>
            </div>
            <span
              class="span-between"
              *ngIf="!courseForm.get('titlePicture').value">
              oder
            </span>
            <div
              class="formular-row-input"
              *ngIf="!courseForm.get('titlePicture').value">
              <div class="image" (click)="onDefaultImageSelected(1)">
                <img
                  src="/assets/img/course-img-1.jpg"
                  class="image"
                  alt="Default Image 1" />
                <div *ngIf="selectedImageId === 1" class="selected">
                  <div class="selected-background"></div>
                  <i class="fa-light fa-circle-check check-icon"></i>
                </div>
              </div>

              <div class="image" (click)="onDefaultImageSelected(2)">
                <img
                  src="/assets/img/course-img-2.jpg"
                  class="image"
                  alt="Default Image 2" />
                <div *ngIf="selectedImageId === 2" class="selected">
                  <div class="selected-background"></div>
                  <i class="fa-light fa-circle-check check-icon"></i>
                </div>
              </div>

              <div class="image" (click)="onDefaultImageSelected(3)">
                <img
                  src="/assets/img/course-img-3.jpg"
                  class="image"
                  alt="Default Image 3 - Vorlesungssaal" />
                <div *ngIf="selectedImageId === 3" class="selected">
                  <div class="selected-background"></div>
                  <i class="fa-light fa-circle-check check-icon"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('titlePicture')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row" *ngIf="!isLecturer">
      <div class="formular-row-label">
        <span class="label-title">
          Kurstyp <span *ngIf="isRequired(courseForm.get('type'))">*</span>
        </span>
        <span class="label-description"> Wählen Sie einen Kurstyp </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select
              id="type"
              [(value)]="selectedType"
              formControlName="type"
              placeholder="Kurstyp"
              class="hasomed-select">
              <mat-option
                value="{{ courseType.id }}"
                class="hasomed-text-body"
                *ngFor="let courseType of availableCourseTypes">
                {{ courseType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('type')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row" *ngIf="!isLecturer">
      <div class="formular-row-label">
        <span class="label-title">
          Lerninhalt
          <span *ngIf="isRequired(courseForm.get('learningContents'))">*</span>
        </span>
        <span class="label-description"> Wählen Sie einen Lerninhalt </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="flex-c flex-grow gap-8">
            <ng-container formArrayName="learningContents">
              <ng-container
                *ngFor="
                  let learningContent of learningContents.controls;
                  let i = index
                ">
                <div class="formular-row-input with-delete-btn">
                  <mat-form-field class="hasomed-select">
                    <mat-select
                      id="learningContent"
                      class="hasomed-select"
                      [formControlName]="i"
                      placeholder="Lerninhalt"
                      (valueChange)="updateLearningContent($event, i)"
                      [compareWith]="compareLearningContents">
                      <mat-option
                        [value]="learningContent"
                        class="hasomed-text-body"
                        *ngFor="
                          let learningContent of availableLearningContents
                        ">
                        {{ learningContent.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    [disabled]="learningContents.length <= 1"
                    type="button"
                    class="hasomed-text-button hasomed-button-white-grey"
                    (click)="removeLearningContent(i)">
                    <i class="hasomed-button-icon-sm fa-light fa-trash-can"></i>
                  </button>
                </div>
              </ng-container>
            </ng-container>
            <button
              [disabled]="
                learningContents?.length === availableLearningContents?.length
              "
              type="button"
              (click)="addLearningContent()"
              class="hasomed-text-button hasomed-button-white-grey button-flex-start">
              <i class="fa-light fa-plus hasomed-button-icon-sm"></i>
              <span class="hasomed-button-text"> weitere hinzufügen </span>
            </button>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('learningContent')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row" *ngIf="!isLecturer">
      <div class="formular-row-label">
        <span class="label-title">
          Art der Prüfung
          <span *ngIf="isRequired(courseForm.get('examType'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie eine oder mehrere Prüfungsart/-en
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="flex-c flex-grow gap-8">
            <ng-container formArrayName="examTypes">
              <ng-container
                *ngFor="let exam of examTypes.controls; let i = index">
                <div class="formular-row-input with-delete-btn">
                  <mat-form-field class="hasomed-select">
                    <mat-select
                      id="type"
                      class="hasomed-select"
                      [formControlName]="i"
                      placeholder="Prüfungsart"
                      (valueChange)="updateExamType($event, i)"
                      [compareWith]="compareExamTypes">
                      <mat-option
                        [value]="examType"
                        class="hasomed-text-body"
                        *ngFor="let examType of availableExamTypes">
                        {{ examType.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button
                    type="button"
                    class="hasomed-text-button hasomed-button-white-grey"
                    (click)="removeExamType(i)">
                    <i class="hasomed-button-icon-sm fa-light fa-trash-can"></i>
                  </button>
                </div>
              </ng-container>
            </ng-container>
            <button
              [disabled]="examTypes?.length === availableExamTypes?.length"
              id="addExamType"
              type="button"
              (click)="addExamType()"
              class="hasomed-text-button hasomed-button-white-grey button-flex-start">
              <i class="fa-light fa-plus hasomed-button-icon-sm"></i>
              <span class="hasomed-button-text"> weitere hinzufügen </span>
            </button>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('examTypes')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row" *ngIf="!isLecturer">
      <div class="formular-row-label">
        <span class="label-title">
          Curriculum relevant
          <span *ngIf="isRequired(courseForm.get('mandatory'))">*</span>
        </span>
        <span class="label-description">
          Legen Sie fest, ob der Kurs ausbildungsrelevant ist.
        </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-checkbox
            id="mandatoryCheckbox"
            class="input-checkbox"
            type="checkbox"
            color="primary"
            formControlName="mandatory">
            <div class="checkbox-title">Curriculum relevant</div>
          </mat-checkbox>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('mandatory')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row" *ngIf="!isLecturer">
      <label class="formular-row-label" for="lecturerSearch">
        <span class="label-title">
          Lehrpersonal
          <span *ngIf="isRequired(courseForm.get('lecturers'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie ein / mehrere Lehrpersonen für den Kurs
        </span>
      </label>

      <div class="hasomed-input-field gap-16">
        <div class="formular-row-input">
          <div class="flex-c gap-16 select-user-container">
            <div class="hasomed-search">
              <i class="fa-light fa-search hasomed-search-icon"></i>
              <input
                id="lecturerSearch"
                class="hasomed-search-input"
                type="text"
                placeholder="Personensuche"
                formControlName="lecturerSearch"
                #lecturerInput />
            </div>
            <div
              id="lecturers"
              class="flex-c vertical-overflow gap-8 users"
              [ngClass]="{
                'error-border':
                  courseForm.get('lecturers').invalid &&
                  courseForm.get('lecturers').touched
              }"
              *ngIf="lecturers && selectedLecturerIds">
              <div
                class="flex-r user-container"
                [ngClass]="{
                  active: selectedLecturerIds.includes(lecturer.id)
                }"
                *ngFor="
                  let lecturer of lecturers | searchFilter: lecturerInput.value
                "
                (click)="onLecturerSelected(lecturer)">
                <div class="flex-r user">
                  <div class="user-icon">
                    <img
                      src="{{
                        lecturer.profilePicture ??
                          '../../../../../assets/img/user.jpg'
                      }}"
                      alt=""
                      class="user-icon-image" />
                  </div>
                  <div class="user-info">
                    <span class="hasomed-text-body">
                      {{ lecturer.name.academicTitle }}
                      {{ lecturer.name.firstname }}
                      {{ lecturer.name.lastname }}
                    </span>
                    <span class="hasomed-text-small">
                      Lehrpersonal für XYZ
                    </span>
                  </div>
                </div>
                <div *ngIf="selectedLecturerIds.includes(lecturer.id)">
                  <i class="fa-light fa-circle-check user-check"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('lecturers')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row" *ngIf="!isLecturer">
      <div class="formular-row-label">
        <span class="label-title">
          Geschlossener Kurs
          <span *ngIf="isRequired(courseForm.get('closedCourse'))">*</span>
        </span>
        <span class="label-description">
          Legen Sie fest, ob nur eingeladene Kandidaten an dem Kurs teilnehmen
          können.
        </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select
              id="type"
              class="hasomed-select"
              formControlName="closedCourse"
              placeholder="offen / geschlossen">
              <mat-option [value]="false" class="hasomed-text-body">
                offen
              </mat-option>
              <mat-option [value]="true" class="hasomed-text-body">
                geschlossen
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('closedCourse')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row" *ngIf="!isLecturer && courseClosed">
      <label class="formular-row-label" for="participantsSearch">
        <span class="label-title">
          Teilnehmer*innen
          <span *ngIf="isRequired(courseForm.get('participants'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie die Teilnehmer*innen für den Kurs
        </span>
      </label>

      <div class="hasomed-input-field gap-16">
        <div class="formular-row-input">
          <div class="flex-c gap-16 select-user-container">
            <div class="hasomed-search">
              <i class="fa-light fa-search hasomed-search-icon"></i>
              <input
                id="participantsSearch"
                class="hasomed-search-input"
                type="text"
                placeholder="Personensuche"
                formControlName="participantSearch"
                #participantInput />
            </div>
            <div
              class="flex-c vertical-overflow gap-8 users"
              *ngIf="participants && selectedParticipantIds">
              <div
                class="flex-r user-container"
                [ngClass]="{
                  active: selectedParticipantIds.includes(participant.id)
                }"
                *ngFor="
                  let participant of participants
                    | searchFilter: participantInput.value
                "
                (click)="onParticipantSelected(participant)">
                <div class="flex-r user">
                  <div class="user-icon">
                    <img
                      src="{{
                        participant.profilePicture ??
                          '../../../../../assets/img/user.jpg'
                      }}"
                      alt=""
                      class="user-icon-image" />
                  </div>
                  <div class="user-info">
                    <span class="hasomed-text-body">
                      {{ participant.name.academicTitle }}
                      {{ participant.name.firstname }}
                      {{ participant.name.lastname }}
                    </span>
                    <span class="hasomed-text-small">
                      {{
                        participant.educationCourse?.title
                          ? 'Teilnehmer*in für ' +
                            participant.educationCourse?.title
                          : 'Kein Aus- / Weiterbildungsgang'
                      }}
                    </span>
                  </div>
                </div>
                <div *ngIf="selectedParticipantIds.includes(participant.id)">
                  <i class="fa-light fa-circle-check user-check"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('participants')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer && courseClosed"></mat-divider>

    <div class="formular-row" *ngIf="!isLecturer">
      <label class="formular-row-label" for="maxStudents">
        <span class="label-title">
          Maximale Anzahl an Kandidat*innen
          <span *ngIf="isRequired(courseForm.get('maxStudents'))">*</span>
        </span>
        <span class="label-description">
          Regulieren Sie die Teilnehmerzahl
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="maxStudents"
            class="hasomed-text-input"
            type="number"
            [min]="participantCount"
            max="999"
            formControlName="maxStudents" />
          <span class="hasomed-text-body italic">
            + {{ lecturerCount }} Lehrpersonal =
            {{ courseForm.get('maxStudents').value + lecturerCount }}
            Teilnehmer*innen
          </span>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('maxStudents')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row" *ngIf="!isLecturer">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer <span *ngIf="isRequired(courseForm.get('duration'))">*</span>
        </span>
        <span class="label-description"> Wählen Sie die Dauer des Kurses </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select id="duration" formControlName="duration" #duration>
              <mat-option value="270" class="hasomed-text-body">
                270
              </mat-option>
              <mat-option value="210" class="hasomed-text-body">
                210
              </mat-option>
              <mat-option value="180" class="hasomed-text-body">
                180
              </mat-option>
              <mat-option value="120" class="hasomed-text-body">
                120
              </mat-option>
              <mat-option value="90" class="hasomed-text-body"> 90 </mat-option>
              <mat-option value="45" class="hasomed-text-body"> 45 </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('duration')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row" *ngIf="!isLecturer">
      <div class="formular-row-label">
        <span class="label-title">
          Gesamtzeitraum des Kurses <span>*</span>
        </span>
        <span class="label-description">
          Definieren Sie zunächst den Gesamtzeitraum für den Kurs und
          konfigurieren Sie anschließend alle individuellen Termine.
        </span>
      </div>

      <div class="flex-c">
        <app-event-picker
          class="formular-row-input"
          *ngIf="events.length > 0 || !editMode || (startDate && endDate)"
          [events]="events"
          [duration]="+courseForm.get('duration').value"
          [startDate]="startDate"
          [endDate]="endDate"
          [allDayEvent]="allDayEvent"
          [recurrencePattern]="recurrencePattern"
          (eventsChanged)="onEventsChanged($event)"
          (recurrencePatternChanged)="recurrencePattern = $event"
          (datesChanged)="onDatesChanged($event)"
          [parentFormSubmitValidation]="formSubmitted"
          formControlName="dateGroup"
          id="dateGroup"></app-event-picker>
        <app-error-message
          [formField]="courseForm.get('dateGroup')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row" *ngIf="!isLecturer">
      <label class="formular-row-label" for="roomPlanning">
        <span class="label-title">
          Ort des Kurses
          <span *ngIf="isRequired(courseForm.get('location'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie zunächst den Ort des Kurses und rufen Sie anschließend die
          Raumplanung auf oder/und fügen Sie eine URL für ein Online-Meeting
          hinzu.
        </span>
      </label>

      <div class="formular-row-input-columns">
        <div class="flex-r gap-16">
          <button
            *ngFor="
              let courseLocation of CourseLocation
                | keyvalue: originalCourseLocationOrder
            "
            type="button"
            class="hasomed-text-button"
            [ngClass]="{
              'hasomed-button-primary':
                courseForm.get('location').value === courseLocation.value,
              'hasomed-button-white-grey':
                courseForm.get('location').value != courseLocation.value
            }"
            (click)="setCourseLocation(courseLocation.value)">
            <span class="hasomed-button-text">{{ courseLocation.value }}</span>
          </button>
        </div>

        <button
          id="roomPlanning"
          type="button"
          (click)="openRoomPlanning()"
          class="hasomed-text-button hasomed-button-primary button-full-width"
          [class.hasomed-button-red]="roomPlanningValid === false"
          [disabled]="roomPlanningDisabled">
          <i
            class="fa-light fa-arrow-up-right-from-square hasomed-button-icon-sm"></i>
          <span class="hasomed-button-text">{{
            roomPlanningValid ? 'Raumplanung bearbeiten' : 'Raumplanung'
          }}</span>
        </button>
        <span *ngIf="roomPlanningValid === false" class="flex-r justify-center">
          Nicht alle Termine haben einen Raum
        </span>

        <!-- LINK  -->
        <div *ngIf="showLink" class="hasomed-input-field">
          <div class="formular-row-input">
            <label class="formular-row-label link-label" for="link">
              <span class="label-title">
                URL für Video-Meeting
                <span *ngIf="isRequired(courseForm.get('link'))">*</span>
              </span>
            </label>
            <input
              id="link"
              class="hasomed-text-input"
              type="text"
              formControlName="link"
              placeholder="URL für Video-Meeting" />
          </div>
          <app-error-message
            [formField]="courseForm.get('link')"></app-error-message>
        </div>
      </div>
    </div>

    <mat-divider *ngIf="!isLecturer"></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="internalNote">
        <span class="label-title">
          Notizen für den Kurs
          <span *ngIf="isRequired(courseForm.get('internalNote'))">*</span>
        </span>
        <span class="label-description">
          Diese Notizen sind nur für die Verwaltung und das Lehrpersonal
          sichtbar.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="internalNote"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="internalNote"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="courseForm.get('internalNote')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title">
          Dokumente für den Kurs
          <span *ngIf="isRequired(courseForm.get('courseDocumentsUpload'))">
            *
          </span>
        </span>
        <span class="label-description">
          Hier können alle Kursrelevanten Dokumente hochgeladen werden.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            [context]="'courseDocumentsUpload'"
            [logs]="false"
            [existingFiles]="existingFiles"
            (deleteExistingFile)="onDeleteExistingFile($event)"
            inputId="documentUpload"
            formControlName="uploadedFiles"
            [multiple]="true"
            [scrollTo]="true"
            [showFileList]="true"
            [requiredFileTypes]="requiredFileTypesDocuments"
            maxFileSize="12 MB">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
