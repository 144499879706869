<form class="component-content" [formGroup]="newsForm">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Artikel bearbeiten' : 'Artikel erstellen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie den Artikel.'
            : 'Erstellen Sie einen neuen Artikel.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        *ngIf="!currentArticle"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        [disabled]="!newsForm.valid"
        (click)="onCreateNewArticle()"
        [matTooltip]="
          newsForm.valid
            ? 'Entwurf speichern und neuen Artikel erstellen'
            : null
        ">
        <i class="fa-light fa-file-plus hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text"> Neuer Artikel </span>
      </button>

      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onSubmit(false)">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text"> Entwurf speichern </span>
      </button>

      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="currentArticle ? onPublish() : onSubmit(true)">
        <i class="fa-light fa-upload hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text"> Veröffentlichen </span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading && editMode" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <div *ngIf="!isLoading || !editMode" class="main-content">
    <div class="formular-row">
      <label class="formular-row-label" for="type">
        <span class="label-title">
          Artikeltyp <span *ngIf="isRequired(newsForm.get('type'))"> * </span>
        </span>
        <span class="label-description"> Wählen Sie ein Artikeltyp aus </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select
              id="type"
              formControlName="type"
              class="hasomed-select"
              placeholder="Artikeltyp">
              <mat-option
                [value]="articleType.id"
                class="hasomed-text-body"
                *ngFor="let articleType of availableNewsArticleTypes">
                {{ articleType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="newsForm.get('type')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="title">
        <span class="label-title">
          Artikeltitel
          <span *ngIf="isRequired(newsForm.get('title'))"> * </span>
        </span>
        <span class="label-description"> Überschrift des Artikels </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="title"
            class="hasomed-text-input"
            type="text"
            formControlName="title"
            placeholder="Artikeltitel" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="newsForm.get('title')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="link">
        <span class="label-title">
          Link <span *ngIf="isRequired(newsForm.get('link'))"> * </span>
        </span>
        <span class="label-description">
          Fügen Sie einen Link in den Artikel ein
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div class="hasomed-input-with-icon">
            <i class="fa-light fa-link input-icon"></i>
            <input
              id="link"
              class="hasomed-text-input"
              type="url"
              formControlName="link"
              placeholder="https://www.google.de/" />
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="newsForm.get('link')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="newsArticlePictureUpload">
        <span class="label-title">
          Artikelbild
          <span *ngIf="isRequired(newsForm.get('articlePicture'))"> * </span>
        </span>
        <span class="label-description">
          Laden Sie ein passendes Bild für den Artikel hoch
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-image-upload">
          <div
            class="uploaded-image-container"
            *ngIf="newsForm.get('articlePicture').value">
            <img
              id="user-icon-image"
              class="uploaded-image"
              alt="Artikelbild"
              matTooltip="Artikelbild"
              #userIconImage
              [attr.src]="newsForm.get('articlePicture').value" />
          </div>

          <app-upload-area-dnd
            class="image-upload-area"
            [context]="'newsArticlePictureUpload'"
            inputId="newsArticlePictureUpload"
            [logs]="true"
            [showFileList]="false"
            [multiple]="false"
            maxFileSize="10 MB"
            [requiredFileTypes]="requiredFileTypes"
            (change)="imageChangeEvent($event)">
          </app-upload-area-dnd>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="newsForm.get('articlePicture')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="tinyMCE">
        <span class="label-title">
          Artikeltext
          <span *ngIf="isRequired(newsForm.get('tinyMCE'))"> * </span>
        </span>
        <span class="label-description"> Schreiben Sie einen Artikeltext </span>
      </label>
      <div class="flex-c">
        <div class="formular-row-input tinymce-div">
          <editor
            class="custom-tinymce"
            formControlName="tinyMCE"
            apiKey="pwuyu1utswoe8a7fq51fce9sg0l14qtxvcpxxnbpi180702l"
            [init]="tinyMceSettings"></editor>
        </div>
        <app-error-message
          [formField]="newsForm.get('tinyMCE')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="currentArticle"></mat-divider>

    <div class="formular-row" *ngIf="currentArticle">
      <div class="formular-row-label">
        <span class="label-title"> Artikel-Einstellungen </span>
        <span class="label-description">
          Diverse Einstellungen für Ihren Artikel
        </span>
      </div>
      <div class="formular-row-input-columns">
        <div class="formular-row-input">
          <button
            [disabled]="!currentArticle?.published"
            type="button"
            class="hasomed-text-button hasomed-button-white-grey button-full-width"
            (click)="onUnpublish(currentArticle)">
            <i class="fa-light fa-eye-slash hasomed-button-icon-sm"></i>
            <span class="hasomed-button-text"> Verbergen </span>
          </button>
          <button
            type="button"
            class="hasomed-text-button hasomed-button-white-red button-full-width"
            (click)="onDelete(currentArticle)">
            <i class="hasomed-button-icon-sm fa-light fa-trash"></i>
            <span class="hasomed-button-text">Artikel löschen</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
