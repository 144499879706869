<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Anwesenheit</h1>
      <h3 class="hasomed-text-h3">
        Überprüfen Sie die Anwesenheit für Ihren Kurs
      </h3>
    </div>
    <div class="header-buttons">
      <button
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onBackToOverview()">
        <i class="fa-light fa-chevron-left hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Zurück zur Übersicht</span>
      </button>
    </div>
  </div>

  <div class="main-content gap-40">
    <div *ngIf="courseIsLoading" class="loading-wrapper">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
      </mat-progress-spinner>
    </div>
    <div class="flex-r gap-24 summary" *ngIf="!courseIsLoading">
      <div class="flex-r image-container">
        <img [src]="course.titlePicture" class="course-image" alt="Titelbild" />
      </div>

      <div class="flex-c gap-8 justify-space-between">
        <h1
          class="hasomed-text-h1"
          [matTooltip]="course.title?.length > 150 ? course.title : ''">
          {{ course.title | slice: 0 : 150
          }}{{ course.title?.length > 150 ? '...' : '' }}
        </h1>
        <div class="flex-c gap-16">
          <div class="flex-c gap-8">
            <span class="hasomed-text-body gray-600">
              {{ getFullLecturerNames(course) }}
            </span>
            <span class="hasomed-text-body gray-600">
              {{ course.startDate | date: 'dd.MM.yyyy' }} -
              {{ course.endDate | date: 'dd.MM.yyyy' }}
            </span>
            <span class="hasomed-text-body gray-600">{{
              getEducationCourseTitles(course)
            }}</span>
          </div>
          <div class="label label-white">
            <i class="label-icon fa-light fa-book"></i>
            <span class="label-text">{{
              course.mandatory ? 'Verpflichten' : 'Freiwillig'
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-c course-details-main table-no-paginator">
      <table
        mat-table
        [dataSource]="eventDataSource"
        class="course-details-table">
        <!-- date column-->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Datum</th>
          <td mat-cell *matCellDef="let row">
            <span>{{ row.startDate | date: 'dd.MM.yyyy' }}</span>
          </td>
        </ng-container>

        <!-- time column -->
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef>Uhrzeit</th>
          <td mat-cell *matCellDef="let row">
            <span class="gray-600">
              {{ row.startDate | date: 'HH:mm' }} -
              {{ row.endDate | date: 'HH:mm' }} Uhr
            </span>
          </td>
        </ng-container>

        <!-- room column -->
        <ng-container matColumnDef="room">
          <th mat-header-cell *matHeaderCellDef>Raum</th>
          <td mat-cell *matCellDef="let row">{{ row.room?.name ?? '-' }}</td>
        </ng-container>

        <!-- participants column -->
        <ng-container matColumnDef="participantAmount">
          <th mat-header-cell *matHeaderCellDef>Teilnehmeranzahl</th>
          <td mat-cell *matCellDef="let row">
            {{ getParticipantAmount(row) + ' Teilnehmende' }}
          </td>
        </ng-container>

        <!-- eLog column -->
        <ng-container matColumnDef="eLog">
          <th mat-header-cell *matHeaderCellDef>Anwesenheit</th>
          <td mat-cell *matCellDef="let row">
            <app-elog-cell
              [eLogStatus]="row.eLogStatus"
              [eLogStatusCounts]="row.eLogStatusCounts"></app-elog-cell>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th
            class="gray-600 center-header-cell"
            mat-header-cell
            *matHeaderCellDef>
            Aktionen
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="table-button-icons"></div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="eventColumnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: eventColumnsToDisplay"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="eventColumnsToDisplay.length">
            <div *ngIf="courseIsLoading" class="loading-wrapper">
              <mat-progress-spinner
                color="primary"
                mode="indeterminate"
                diameter="32">
              </mat-progress-spinner>
            </div>
            <div
              *ngIf="!courseIsLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Es wurden keine Kurstermine gefunden.
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="flex-c gap-24">
      <div class="flex-r gap-16">
        <button
          type="button"
          class="hasomed-text-button"
          [matMenuTriggerFor]="setMultipleELogMenu"
          #t="matMenuTrigger"
          [disabled]="isSetELogStatusDisabled"
          [class.hasomed-button-orange]="t.menuOpen"
          [class.hasomed-button-white-grey]="!t.menuOpen"
          [matTooltip]="setStatusTooltip">
          <i class="hasomed-button-icon-sm fa-light fa-sliders"></i>
          <span class="hasomed-button-text">Mehrfachauswahl</span>
          <div *ngIf="t.menuOpen">
            <i class="hasomed-button-icon-sm fa-chevron-up fa-light"></i>
          </div>
          <div *ngIf="!t.menuOpen">
            <i class="hasomed-button-icon-sm fa-chevron-down fa-light"></i>
          </div>
        </button>

        <mat-menu #setMultipleELogMenu="matMenu" class="eLog-menu">
          <button
            mat-menu-item
            *ngFor="let status of getAvailableElogs(); let last = last"
            [disabled]="isSetELogStatusDisabled"
            (click)="updateSelectedUsersELogStatus(status)">
            <div
              class="flex-r gap-16 align-center eLog-menu-item"
              [class.margin]="!last">
              <i
                class="fa-light"
                [ngClass]="{
                  'fa-circle-check': status === eLogStatus.CHECKED,
                  'fa-circle-dot': status === eLogStatus.ABSENT,
                  'fa-circle-dashed': status === eLogStatus.PENDING,
                  'fa-circle-minus': status === eLogStatus.EXCUSED,
                  'fa-circle-xmark': status === eLogStatus.UNEXCUSED
                }"></i>
              <span class="hasomed-text-body">
                <ng-container *ngIf="status === eLogStatus.CHECKED">
                  anwesend
                </ng-container>
                <ng-container *ngIf="status === eLogStatus.ABSENT">
                  abwesend
                </ng-container>
                <ng-container *ngIf="status === eLogStatus.PENDING">
                  ausstehend
                </ng-container>
                <ng-container *ngIf="status === eLogStatus.EXCUSED">
                  entschuldigt
                </ng-container>
                <ng-container *ngIf="status === eLogStatus.UNEXCUSED">
                  nicht entschuldigt
                </ng-container>
              </span>
            </div>
            <mat-divider *ngIf="!last"></mat-divider>
          </button>
        </mat-menu>

        <form class="search-form flex-grow" [formGroup]="searchForm">
          <div class="hasomed-search">
            <i class="fa-light fa-search hasomed-search-icon"></i>
            <input
              id="searchInput"
              class="hasomed-search-input"
              type="text"
              formControlName="searchText"
              (keyup)="applyFilter($event)"
              placeholder="Suche" />
          </div>
        </form>
      </div>
      <div
        class="table"
        [class.table-bicolor]="eLogDataSource?.filteredData?.length > 1">
        <table mat-table [dataSource]="eLogDataSource" matSort>
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                color="primary"
                (change)="$event ? toggleAllRows() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="$event ? selectRow(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Student column -->
          <ng-container matColumnDef="studentName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Kandidat*innen</span>
              <app-custom-table-sort-icon
                [sort]="sort"
                columnName="studentName">
              </app-custom-table-sort-icon>
            </th>
            <td mat-cell *matCellDef="let eLog">
              <div class="user-container">
                <div class="user-icon">
                  <img
                    src="{{
                      eLog.student.profilePicture ??
                        '../../../../../../../../assets/img/user.jpg'
                    }}"
                    alt=""
                    class="user-icon-image" />
                </div>
                <div class="user-info">
                  <span class="hasomed-text-body">
                    {{ eLog.student.name.firstname }}
                    {{ eLog.student.name.lastname }}
                  </span>
                </div>
              </div>
            </td>
          </ng-container>

          <!-- userIdentifier column -->
          <ng-container matColumnDef="userIdentifier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Bildungs-ID</span>
              <app-custom-table-sort-icon
                [sort]="sort"
                columnName="userIdentifier">
              </app-custom-table-sort-icon>
            </th>
            <td class="elog-cell" mat-cell *matCellDef="let eLog">
              {{ eLog.student.userIdentifier ?? '-' }}
            </td>
          </ng-container>

          <!-- eLog Status column -->
          <ng-container matColumnDef="eLogStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span>Anwesenheit</span>
              <app-custom-table-sort-icon [sort]="sort" columnName="eLogStatus">
              </app-custom-table-sort-icon>
            </th>
            <td class="elog-cell" mat-cell *matCellDef="let eLog">
              <app-elog-cell [eLogStatus]="eLog.eLogStatus"></app-elog-cell>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th
              class="gray-600 center-header-cell"
              mat-header-cell
              *matHeaderCellDef>
              Aktionen
            </th>
            <td mat-cell *matCellDef="let eLog">
              <div class="table-button-icons">
                <button
                  [disabled]="
                    eLog.eLogStatus === eLogStatus.CHECKED ||
                    isSingleELogStatusDisabled(eLog)
                  "
                  type="button"
                  class="table-button-icon"
                  [matTooltip]="
                    eLog.eLogStatus === eLogStatus.CHECKED ||
                    isSingleELogStatusDisabled(eLog)
                      ? ''
                      : 'Als anwesend setzen'
                  "
                  (click)="updateSingleELogStatus(eLog, eLogStatus.CHECKED)">
                  <i class="fa-light fa-circle-check"></i>
                </button>
                <button
                  type="button"
                  class="table-button-icon"
                  matTooltip="Anwesenheitsstatus setzen"
                  [matMenuTriggerFor]="setSingleELogMenu"
                  #triggerSingleELog="matMenuTrigger"
                  [matTooltip]="
                    isSingleELogStatusDisabled(eLog)
                      ? ''
                      : 'Anwesenheitsstatus setzen'
                  "
                  [class.color-primary]="triggerSingleELog.menuOpen"
                  [disabled]="isSingleELogStatusDisabled(eLog)">
                  <i class="fa-light fa-pen-to-square"></i>
                </button>
              </div>
              <mat-menu #setSingleELogMenu="matMenu" class="eLog-menu">
                <button
                  mat-menu-item
                  *ngFor="let status of getAvailableElogs(); let last = last"
                  (click)="updateSingleELogStatus(eLog, status)"
                  [disabled]="isSingleELogStatusDisabled(eLog)">
                  <div
                    class="flex-r gap-16 align-center eLog-menu-item"
                    [class.margin]="!last">
                    <i
                      class="fa-light"
                      [ngClass]="{
                        'fa-circle-check': status === eLogStatus.CHECKED,
                        'fa-circle-dot': status === eLogStatus.ABSENT,
                        'fa-circle-dashed': status === eLogStatus.PENDING,
                        'fa-circle-minus': status === eLogStatus.EXCUSED,
                        'fa-circle-xmark': status === eLogStatus.UNEXCUSED
                      }"></i>
                    <span class="hasomed-text-body">
                      <ng-container *ngIf="status === eLogStatus.CHECKED">
                        anwesend
                      </ng-container>
                      <ng-container *ngIf="status === eLogStatus.ABSENT">
                        abwesend
                      </ng-container>
                      <ng-container *ngIf="status === eLogStatus.PENDING">
                        ausstehend
                      </ng-container>
                      <ng-container *ngIf="status === eLogStatus.EXCUSED">
                        entschuldigt
                      </ng-container>
                      <ng-container *ngIf="status === eLogStatus.UNEXCUSED">
                        nicht entschuldigt
                      </ng-container>
                    </span>
                  </div>
                  <mat-divider *ngIf="!last"></mat-divider>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="eLogColumnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: eLogColumnsToDisplay"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td [attr.colspan]="eLogColumnsToDisplay.length">
              <div *ngIf="eLogIsLoading" class="loading-wrapper">
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate"
                  diameter="32">
                </mat-progress-spinner>
              </div>
              <div
                *ngIf="!eLogIsLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Es wurde kein Kandidat*in gefunden.
              </div>
            </td>
          </tr>
        </table>
        <mat-divider></mat-divider>
        <mat-paginator
          custom-paginator
          [appCustomLength]="eLogDataSource.filteredData.length"
          [pageSize]="6"
          [renderButtonsNumber]="1"
          aria-label="Select page"></mat-paginator>
      </div>
    </div>
  </div>
</div>
