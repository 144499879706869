import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { ImageCropperDialogComponent } from 'src/app/components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  Course,
  CourseEvent,
  CourseType,
  LearningContent,
  RecurrencePattern,
  CourseLocation,
  recurrenceFrequency,
} from 'src/app/models/course.model';
import { CustomFileType } from 'src/app/models/custom-file-type.model';
import { ExamType } from 'src/app/models/exams.model';
import { EducationCourse } from 'src/app/models/education-course.model';
import { User } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CourseService } from 'src/app/services/course.service';
import { EducationCourseService } from 'src/app/services/education-course.service';
import { ExamService } from 'src/app/services/exam.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { LearningContentService } from 'src/app/services/learning-content.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { KeyValue, Location } from '@angular/common';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-create-edit-course',
  templateUrl: './create-edit-course.component.html',
  styleUrls: ['./create-edit-course.component.scss'],
})
export class CreateEditCourseComponent implements OnInit, OnDestroy {
  public selectedImageId: number;
  public selectedType: string;
  public selectedDuration = '45';
  public editMode: boolean = false;
  public duplicateMode: boolean = false;
  public currentCourse: Course;
  public currentCourseSubscription: Subject<Course> = new Subject();
  public courseForm: FormGroup;
  public lecturers: User[];
  public participants: User[];
  public selectedLecturerIds: number[] = [];
  public selectedParticipantIds: number[] = [];
  public uploadedImage: File;
  public uploadedFiles: CustomFileType[] = [];
  public existingFiles: CustomFileType[] = [];
  public startDate: any;
  public endDate: any;
  public allDayEvent: Boolean;
  public recurrenceFrequency: recurrenceFrequency;
  public recurrencePattern: RecurrencePattern;
  public events: CourseEvent[] = [];
  public initialEvents: CourseEvent[] = [];
  public roomPlanningOpen: boolean = false;
  public roomPlanningValid: boolean = null;
  public roomPlanningDisabled: boolean = false;
  public availableEducationCourses: EducationCourse[];
  public availableLearningContents: LearningContent[];
  public availableExamTypes: ExamType[];
  public availableCourseTypes: CourseType[];
  public isLoading = true;
  public isLecturer: boolean = false;
  public formSubmitted = false;
  private initialFormValues: {};
  public showLink: boolean = false;

  public requiredFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'GIF', mimeType: 'image/gif' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];

  public requiredFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  // import from form.utils.ts
  public isRequired = isRequired;

  // to access enum in template
  public CourseLocation = CourseLocation;

  private destroy$ = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.courseForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private userService: UserService,
    private examService: ExamService,
    private learningContentService: LearningContentService,
    private educationCourseService: EducationCourseService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    private dialog: MatDialog,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private location: Location,
    private router: Router,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.isLecturer = this.userService.currentUser.id_role == 3;
    this.getEducationCourses();
    this.getParticipants();
    this.getLecturers();
    this.getLearningContents();
    this.getExamTypes();
    this.getCourseTypes();

    this.courseForm = this.fb.group({
      educationCourses: this.fb.array([], Validators.required),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      description: new FormControl('', [Validators.maxLength(6000)]),
      titlePicture: new FormControl(null, Validators.required),
      selectedTitlePicture: new FormControl(null),
      type: new FormControl('', Validators.required),
      learningContents: this.fb.array([], Validators.required),
      examTypes: this.fb.array([]),
      mandatory: new FormControl(null),
      lecturers: new FormControl(null, Validators.required),
      lecturerSearch: new FormControl(null),
      closedCourse: new FormControl(false),
      participants: new FormControl(null),
      participantSearch: new FormControl(null),
      maxStudents: new FormControl(null, [
        Validators.required,
        positiveNumbersOnlyValidator(false),
        maxNumberLength(3),
      ]),
      duration: new FormControl(
        {
          value: null,
        },
        Validators.required
      ),
      location: new FormControl(CourseLocation.ONSITE, Validators.required),
      dateGroup: new FormControl(null),
      link: new FormControl(''),
      internalNote: new FormControl(''),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });

    if (this.activatedRoute.snapshot.params['id']) {
      this.editMode = true;
      // if route contains create, set duplicateMode to true
      if (this.router.url.includes('create')) {
        this.duplicateMode = true;
        this.editMode = false;
      }

      const course_id = +atob(this.activatedRoute.snapshot.params['id']);
      this.getCourse(course_id);
      this.currentCourseSubscription.subscribe(course => {
        this.currentCourse = course;
        this.selectedLecturerIds = [];
        course.lecturers?.map((lecturer: User) =>
          this.selectedLecturerIds.push(lecturer.id)
        );
        if (!this.duplicateMode) {
          course.courseStudents?.map((participant: User) =>
            this.selectedParticipantIds.push(participant.id)
          );
          this.startDate = moment(course.startDate);
          this.endDate = moment(course.endDate);
        }
        this.events = this.duplicateMode ? [] : course.courseEvents ?? [];
        this.initialEvents = course.courseEvents.slice() ?? [];
        this.recurrenceFrequency = course.recurrencePattern.frequency;
        this.recurrencePattern = course.recurrencePattern;
        this.selectedDuration = course.duration.toString();
        this.allDayEvent = course.allDayEvent;
        this.selectedImageId = course.defaultTitlePictureId;

        console.debug('Course', course);

        course.files?.forEach(file => {
          this.existingFiles.push(file);
        });

        // set room planning to valid
        let invalidCourseRooms = 0;
        for (const courseEvent of this.events) {
          if (
            !courseEvent.room &&
            moment().isSameOrBefore(courseEvent.startDate)
          ) {
            invalidCourseRooms++;
          }
        }
        if (invalidCourseRooms > 0) {
          this.roomPlanningValid = false;
        } else {
          this.roomPlanningValid = true;
        }

        if (this.selectedImageId) {
          this.courseForm.controls['selectedTitlePicture'].setValue(
            this.selectedImageId
          );
          this.courseForm.controls['titlePicture'].clearValidators();
          this.courseForm.controls['titlePicture'].updateValueAndValidity();
        } else {
          this.courseForm.get('titlePicture').setValue(course.titlePicture);
        }

        this.courseForm.patchValue({
          title: course.title + (this.duplicateMode ? ' (Kopie)' : ''),
          description: course.description,
          mandatory: course.mandatory,
          closedCourse: course.closed,
          type: course.type.id.toString(),
          duration: course.duration.toString(),
          link: course.link,
          maxStudents: course.maxStudents,
          internalNote: course.internalNote,
          documents: course.files,
          uploadedFiles: this.uploadedFiles,
          existingFiles: this.existingFiles,
          participants: JSON.parse(JSON.stringify(this.selectedParticipantIds)),
          lecturers: JSON.parse(JSON.stringify(this.selectedLecturerIds)),
          location: course.location,
        });

        if (
          course.location == CourseLocation.ONLINE ||
          course.location == CourseLocation.HYBRID
        ) {
          this.showLink = true;
          this.courseForm.get('link').setValidators([Validators.required]);
        }

        // create formControls for all educationCourses
        course.educationCourses?.forEach(educationCourse => {
          const educationCourseControl = new FormControl(
            educationCourse,
            Validators.required
          );
          this.educationCourses.push(educationCourseControl);
        });

        // create formControls for all learningContents
        course.learningContents?.forEach(learningContent => {
          const learningContentControl = new FormControl(
            learningContent,
            Validators.required
          );
          this.learningContents.push(learningContentControl);
        });

        // create formControls for all examTypes
        course.examTypes?.forEach(examType => {
          const exam = new FormControl(examType);
          this.examTypes.push(exam);
        });

        if (!course.examTypes || course.examTypes.length == 0) {
          this.addExamType();
        }

        this.isLoading = false;
        this.initialFormValues = this.courseForm.value;
      });
    }

    if (!this.editMode && !this.duplicateMode) {
      this.selectedImageId = 1;
      this.isLoading = false;
      this.courseForm.patchValue({
        duration: this.selectedDuration,
        selectedTitlePicture: this.selectedImageId,
      });
      this.courseForm.controls['titlePicture'].clearValidators();
      this.courseForm.controls['titlePicture'].updateValueAndValidity();

      // if a new course is created add a default educationCourse
      this.educationCourses.push(new FormControl(null, Validators.required));

      // if a new course is created add a default learningContent
      this.learningContents.push(new FormControl(null, Validators.required));

      // if a new course is created add a default examType
      this.addExamType();

      this.initialFormValues = this.courseForm.value;
    }

    // subscribe to closedCourse changes and remove selected participants if the course is not closed
    this.courseForm.controls['closedCourse'].valueChanges.subscribe(closed => {
      if (!closed) {
        this.selectedParticipantIds = [];
        this.courseForm.get('participants').setValue(null);
      }
    });

    // subscribe to participants and set the value of maxStudents to the number of selected participants if it is greater than the current maxStudents
    this.courseForm.controls['participants'].valueChanges.subscribe(
      participants => {
        const maxStudents = this.courseForm.get('maxStudents').value;
        if (participants?.length > maxStudents) {
          this.courseForm.get('maxStudents').setValue(participants.length);
        }
      }
    );

    // subscribe to location changes and set the value of roomPlanningDisabled to true if the location is ONLINE
    this.courseForm.controls['location'].valueChanges.subscribe(location => {
      if (location == CourseLocation.ONLINE) {
        this.roomPlanningDisabled = true;
        this.roomPlanningValid = true;
        this.showLink = true;

        // set required validator for link if location is ONLINE
        this.courseForm.get('link').setValidators([Validators.required]);
        return;
      }

      if (location == CourseLocation.ONSITE) {
        this.roomPlanningDisabled = false;
        this.showLink = false;

        // remove required validator for link if location is ONSITE
        this.courseForm.get('link').clearValidators();
        this.courseForm.get('link').updateValueAndValidity();
        return;
      }

      if (location == CourseLocation.HYBRID) {
        this.roomPlanningDisabled = false;
        this.showLink = true;

        // set required validator for link if location is HYBRID
        this.courseForm.get('link').setValidators([Validators.required]);
        return;
      }
    });

    this.courseForm.controls['duration'].valueChanges.subscribe(duration => {
      this.selectedDuration = duration;
    });

    // subscribe to uploadedFiles changes and convert them to base64
    this.courseForm
      .get('uploadedFiles')
      .valueChanges.subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.courseForm.get('documents').setValue(null);
          return;
        }
        const newDocumentsArray: any[] = [];

        value.forEach((file: any) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            newDocumentsArray.push({
              id: file.id,
              filename: file.name,
              file: e.target.result,
              fileSize: file.size,
              creator: !file.creator
                ? this.userService.currentUser
                : file.creator,
              timeCreated: file.timeCreated,
              timeModified: file.timeModified,
            });

            // Check if all files have been read and update the documents array once
            if (newDocumentsArray.length === value.length) {
              this.courseForm.get('documents').setValue(newDocumentsArray);
            }
          };
          reader.readAsDataURL(file);
        });
      });
  }

  private getEducationCourses() {
    const id_institute = this.userService.currentUser.id_institute;
    this.educationCourseService
      .getInstituteEducationCourses(id_institute)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('EducationCourse BackendData:', response);
          if (!response.success) {
            console.error(response.message);
            return;
          }
          this.availableEducationCourses = response.data?.map(
            (educationCourseData: any): EducationCourse => {
              return {
                id: educationCourseData.id,
                title: educationCourseData.title,
              };
            }
          );
          console.debug('EducationCourses:', this.availableEducationCourses);
        },
      });
  }
  /**
   *  getLecturers
   * @description get all institute lecturers and save them in this.lecturers array
   */
  private getLecturers() {
    this.userService.getInstituteUsersByRole(3, true).subscribe({
      next: async response => {
        console.debug('get lecturers backend response:', response);
        if (!response.success) {
          console.error(response.message);
          return;
        }
        this.lecturers = response.data
          ? await Promise.all(
              response.data?.map(async (userData: User): Promise<User> => {
                return await this.userService.parseBackendUser(userData);
              })
            )
          : [];
      },
    });
  }

  /**
   * getParticipants
   * @description get all institute students and save them in this.participants array
   */
  private getParticipants() {
    this.userService
      .getInstituteUsersByRole(4, true, 'educationCourse')
      .subscribe({
        next: async response => {
          console.debug('get students backend response:', response);
          if (!response.success) {
            console.error(response.message);
            return;
          }
          this.participants = response.data
            ? await Promise.all(
                response.data?.map(async (userData: User): Promise<User> => {
                  return await this.userService.parseBackendUser(userData);
                })
              )
            : [];
        },
      });
  }

  private getLearningContents() {
    const id_institute = this.userService.currentUser.id_institute;
    this.learningContentService
      .getInstituteLearningContents(id_institute)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          console.debug('LearningContents BackendData:', response);
          if (!response.success) {
            console.error(response.message);
            return;
          }
          this.availableLearningContents = response.data;
        },
      });
  }

  private getExamTypes() {
    const id_institute = this.userService.currentUser.id_institute;
    this.examService.getInstituteExamTypes(id_institute).subscribe({
      next: response => {
        console.debug('ExamTypes BackendData:', response);
        if (!response.success) {
          console.error(response.message);
          return;
        }
        this.availableExamTypes = response.data;
      },
    });
  }

  private getCourseTypes() {
    const id_institute = this.userService.currentUser.id_institute;
    this.courseService.getInstituteCourseTypes(id_institute).subscribe({
      next: response => {
        console.debug('CourseTypes BackendData:', response);
        if (!response.success) {
          console.error(response.message);
          return;
        }
        this.availableCourseTypes = response.data;
      },
    });
  }

  private getCourse(course_id: number) {
    this.courseService
      .getCourseById(
        course_id,
        'courseEvents,educationCourses,files,courseStudents'
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('Course BackendData', response);
          if (!response.success) {
            console.error(response.message);
            this.onCancel();
            this.alertService.showErrorAlert(
              'Fehler',
              `Der Kurs konnte nicht geladen werden!`
            );
            return;
          }
          this.currentCourseSubscription.next(
            await this.courseService.parseBackendCourse(response.data)
          );
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
          this.onCancel();
          this.alertService.showErrorAlert(
            'Fehler',
            `Der Kurs konnte nicht geladen werden!`
          );
        },
      });
  }

  /**
   * get courseClosed
   * getter for the closedCourse form control
   * @returns boolean
   */
  get courseClosed(): boolean {
    return this.courseForm.get('closedCourse').value;
  }

  /**
   * get lecturerCount
   * getter for the selected lecturers count
   * @returns number
   */
  get lecturerCount(): number {
    return this.selectedLecturerIds.length;
  }

  /**
   * get participantCount
   * getter for the selected participants count
   * @returns number
   */
  get participantCount(): number {
    return this.selectedParticipantIds.length;
  }

  /**
   * setCourseLocation
   * set the course location in the course form
   * @param location CourseLocation
   */
  public setCourseLocation(location: CourseLocation) {
    this.courseForm.get('location').setValue(location);
  }

  public onEventsChanged(courseEvents: CourseEvent[]) {
    this.events = courseEvents;

    // if location is online or hybrid, set room planning to valid
    if (
      this.courseForm.get('location').value == CourseLocation.ONLINE ||
      this.courseForm.get('location').value == CourseLocation.HYBRID
    ) {
      this.roomPlanningValid = true;
      return;
    }

    let eventsWithoutRoom = 0;
    let eventsWithoutDate = 0;
    this.events?.forEach(event => {
      if (!event.startDate) {
        eventsWithoutDate++;
      }
      if (!event.room) {
        eventsWithoutRoom++;
      }
    });
    if (eventsWithoutDate > 0) {
      this.roomPlanningDisabled = true;
      this.roomPlanningValid = true;
    } else {
      this.roomPlanningDisabled = false;
      if (eventsWithoutRoom > 0) {
        this.roomPlanningValid = false;
      }
    }
  }

  public onDatesChanged(dates: {
    start: Date;
    end: Date;
    allDayEvent: Boolean;
  }) {
    this.startDate = dates.start;
    this.endDate = dates.end;
    this.allDayEvent = dates.allDayEvent;
    this.cdr.detectChanges();
  }

  // update validation of room planning
  public roomPlanningChanged(courseEvents: CourseEvent[]) {
    if (!courseEvents) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
              Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomPlanningOpen = !this.roomPlanningOpen;
          return;
        }
      });
    } else {
      let invalidCourseRooms = 0;
      for (const courseEvent of courseEvents) {
        if (
          !courseEvent.room &&
          moment().isSameOrBefore(courseEvent.startDate)
        ) {
          invalidCourseRooms++;
        }
      }
      if (invalidCourseRooms > 0) {
        this.roomPlanningValid = false;
      } else {
        this.roomPlanningValid = true;
      }
      this.events = courseEvents;
      this.roomPlanningOpen = false;
    }
  }

  // build the new course before opening the room Planning
  public openRoomPlanning() {
    /* only open room planning if courseEvents have been selected */
    if (this.events.length > 0) {
      this.roomPlanningOpen = true;
    } else {
      this.alertService.showErrorAlert(
        'Fehler',
        'Bitte wählen Sie mindestens einen Termin aus!'
      );
    }
  }

  /* on button click update existing or create new course and navigate back */
  public onSubmit() {
    this.formSubmitted = true;
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.courseForm
      );

    if (formInvalid) {
      return;
    }

    if (!this.roomPlanningValid) {
      this.alertService.showErrorAlert(
        'Das hat nicht geklappt!',
        'Bitte weisen Sie allen Terminen einen Raum zu.'
      );

      // scroll to room planning
      const roomPlanningElement = document.getElementById('roomPlanning');
      roomPlanningElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.courseForm.value,
        this.initialFormValues
      ) &&
      !this.formDeactivateService.hasUnsavedChanges(
        this.initialEvents,
        this.events
      )
    ) {
      this.onCancel();
      return;
    }

    const files: CustomFileType[] = this.courseForm.value.documents
      ? this.existingFiles.concat(this.courseForm.value.documents)
      : this.existingFiles;

    const courseData = {
      id: this.duplicateMode ? null : this.currentCourse?.id ?? null,
      title: this.courseForm.value.title,
      description: this.courseForm.value.description,
      courseType: this.courseForm.value.type
        ? this.availableCourseTypes.find(
            it => it.id == this.courseForm.value.type
          )
        : null,
      duration: this.courseForm.getRawValue().duration,
      link: this.courseForm.value.link,
      maxStudents: this.courseForm.value.maxStudents,
      internalNote: this.courseForm.value.internalNote,
      lecturers: this.selectedLecturerIds?.map(lecturerId => {
        const lecturer = this.lecturers.find(
          lecturer => lecturer.id == lecturerId
        );
        if (lecturer) {
          return {
            id: lecturer.id,
            name: lecturer.name,
          };
        } else {
          return { id: lecturerId };
        }
      }),
      closed: this.courseForm.value.closedCourse ? 1 : 0,
      location: this.courseForm.value.location,
      courseStudents: this.selectedParticipantIds?.map(participantId => {
        const participant = this.participants.find(
          participant => participant.id == participantId
        );
        if (participant) {
          return {
            id: participant.id,
            name: participant.name,
          };
        } else {
          return {
            id: participantId,
          };
        }
      }),
      startDate: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'),
      recurrencePattern: this.recurrencePattern,
      mandatory: this.courseForm.value.mandatory,
      learningContents: this.courseForm
        .get('learningContents')
        .value.filter((learningContent: any) => {
          if (learningContent) {
            return learningContent;
          }
        }),
      instituteId: this.userService.currentUser.id_institute,
      events: this.events.map(event => {
        return {
          id: event.id,
          courseId: event.courseId,
          startDate: moment(event.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(event.endDate).format('YYYY-MM-DD HH:mm:ss'),
          roomId: event.room?.id,
          instituteId: this.userService.currentUser.id_institute,
        };
      }),
      examTypes: this.courseForm
        .get('examTypes')
        .value.filter((examType: any) => {
          if (examType) {
            return examType;
          }
        }),
      educationCourses: this.courseForm
        .get('educationCourses')
        .value.filter((educationCourse: any) => {
          if (educationCourse) {
            return educationCourse;
          }
        }),
      id_createdBy: this.userService.currentUser.id,
      titlePicture: this.courseForm.get('titlePicture').value,
      defaultTitlePictureId: this.selectedImageId,
      files: files,
    };

    console.debug('courseData:', courseData);

    this.isLoading = true;

    this.editMode ? this.editCourse(courseData) : this.createCourse(courseData);
  }

  private async createCourse(courseData: any) {
    const createCourseObservable =
      await this.courseService.createCourse(courseData);
    createCourseObservable.subscribe({
      next: response => {
        console.debug('Course Created:', response);

        if (!response.success) {
          console.error(response.message);
          this.alertService.showErrorAlert(
            'Fehler',
            `Der Kurs '${courseData.title}' konnte nicht erstellt werden!`
          );
          this.isLoading = false;
          return;
        }

        this.alertService.showSuccessAlert(
          'Kurs erstellt',
          `Der Kurs '${courseData.title}' wurde erstellt!`
        );
        this.initialFormValues = this.courseForm.value;
        this.onCancel();
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Der Kurs '${courseData.title}' konnte nicht erstellt werden!`
        );
        this.isLoading = false;
      },
    });
  }

  private async editCourse(courseData: any) {
    const updateCourseObservable = await this.courseService.updateCourse(
      'updateCourse',
      courseData
    );
    updateCourseObservable.subscribe({
      next: response => {
        console.debug('Course Updated:', response);

        if (!response.success) {
          console.error(response.message);
          this.alertService.showErrorAlert(
            'Fehler',
            `Der Kurs '${courseData.title}' konnte nicht aktualisiert werden!`
          );
          this.isLoading = false;
          return;
        }

        this.alertService.showSuccessAlert(
          'Kurs aktualisiert',
          `Der Kurs '${courseData.title}' wurde aktualisiert!`
        );
        this.initialFormValues = this.courseForm.value;
        this.onCancel();
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Der Kurs '${courseData.title}' konnte nicht aktualisiert werden!`
        );
        this.isLoading = false;
      },
    });
  }

  /* on button click navigate up to parent route */
  public onCancel() {
    this.location.back();
  }

  /* remove selected picture on click */
  public removePicture() {
    console.debug('remove picture');
  }

  /* remove selected file on click */
  public removeFile(index: number) {
    this.uploadedFiles.splice(index, 1);
  }

  /* 
  set or removes the selected image based on the users action
  Remove validator on image upload when a default image is selected 
  */
  public onDefaultImageSelected(imageId: number) {
    if (this.selectedImageId === imageId) {
      this.selectedImageId = null;
      this.courseForm.controls['titlePicture'].setValidators([
        Validators.required,
      ]);
    } else {
      this.selectedImageId = imageId;
      this.courseForm.controls['titlePicture'].clearValidators();
    }
    this.courseForm.controls['selectedTitlePicture'].setValue(
      this.selectedImageId
    );
    // mark as touched and dirty
    this.courseForm.controls['titlePicture'].markAsTouched();
    this.courseForm.controls['titlePicture'].markAsDirty();
    this.courseForm.controls['titlePicture'].updateValueAndValidity();
  }

  public imageChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '500px',
      data: {
        image: event,
        title: 'Bild zuschneiden',
        round: false,
        height: 300,
        aspectRatio: 4 / 3,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.courseForm.get('titlePicture').setValue(result);
        this.selectedImageId = null;
      }
    });
  }

  /* ------ START Education Course Form Control functions ------ */

  // getter for educationCourses FormArray
  get educationCourses() {
    return this.courseForm.controls['educationCourses'] as FormArray;
  }

  /**
   * addEducationCourse
   * adds a new educationCourse FormControl to the educationCourses FormArray
   * @returns void
   */
  public addEducationCourse(): void {
    const educationCourseControl = this.fb.control(null, Validators.required);
    this.educationCourses.push(educationCourseControl);
  }

  /**
   * updateEducationCourse
   * checks if the selected educationCourse is already in the educationCourse form array
   * and shows an error message if the educationCourse is already in the form array
   * @param newEducationCourse EducationCourse
   * @param index number
   * @returns void
   */
  public updateEducationCourse(
    newEducationCourse: EducationCourse,
    index: number
  ): void {
    if (!newEducationCourse) {
      return;
    }

    // check if the selected educationCourse is already in the educationCourse form array
    if (
      this.educationCourses.value.find(
        (it: any) => it?.id == newEducationCourse.id
      )
    ) {
      this.alertService.showErrorAlert(
        'Das hat nicht geklappt!',
        'Der Aus- Weiterbildungsgang wurde bereits hinzugefügt!'
      );

      // remove the value from the formControl
      this.educationCourses.at(index).setValue(null);

      return;
    }
  }

  /**
   * removeEducationCourse
   * removes the educationCourse FormControl at the given index from the educationCourses FormArray
   * @param index number
   * @returns void
   */
  public removeEducationCourse(index: number): void {
    this.educationCourses.removeAt(index);
  }

  /**
   * compareEducationCourses
   * compares two educationCourses by their id
   * @param educationCourse1 EducationCourse
   * @param educationCourse2 EducationCourse
   * @returns boolean
   */
  public compareEducationCourses(
    educationCourse1: EducationCourse,
    educationCourse2: EducationCourse
  ): boolean {
    return educationCourse1 && educationCourse2
      ? educationCourse1.id === educationCourse2.id
      : educationCourse1 === educationCourse2;
  }

  /* ------ END Education Course Form Control functions ------ */

  /* ------ START Learning Content Form Control functions ------ */

  // getter for learningContents FormArray
  get learningContents() {
    return this.courseForm.controls['learningContents'] as FormArray;
  }

  /**
   * addLearningContent
   * adds a new learningContent FormControl to the learningContents FormArray
   * @returns void
   */
  public addLearningContent(): void {
    const learningContentControl = this.fb.control(null, Validators.required);
    this.learningContents.push(learningContentControl);
  }

  /**
   * updateLearningContent
   * checks if the selected learningContent is already in the learningContent form array
   * and shows an error message if the learningContent is already in the form array
   * @param newLearningContent LearningContent
   * @param index number
   * @returns void
   */
  public updateLearningContent(
    newLearningContent: LearningContent,
    index: number
  ): void {
    if (!newLearningContent) {
      return;
    }

    // check if the selected learningContent is already in the learningContent form array
    if (
      this.learningContents.value.find(
        (it: any) => it?.id == newLearningContent.id
      )
    ) {
      this.alertService.showErrorAlert(
        'Das hat nicht geklappt!',
        'Der Lerninhalt wurde bereits hinzugefügt!'
      );

      // remove the value from the formControl
      this.learningContents.at(index).setValue(null);

      return;
    }
  }

  /**
   * removeLearningContent
   * removes the learningContent FormControl at the given index from the learningContents FormArray
   * @param index number
   * @returns void
   */
  public removeLearningContent(index: number): void {
    this.learningContents.removeAt(index);
  }

  /**
   * compareLearningContents
   * compares two learningContents by their id
   * @param learningContent1 LearningContent
   * @param learningContent2 LearningContent
   * @returns boolean
   */
  public compareLearningContents(
    learningContent1: LearningContent,
    learningContent2: LearningContent
  ): boolean {
    return learningContent1 && learningContent2
      ? learningContent1.id === learningContent2.id
      : learningContent1 === learningContent2;
  }

  /* ------ END Learning Content Form Control functions ------ */

  /* ------ START Exam Type Form Control functions ------ */

  // getter for eventList FormArray
  get examTypes() {
    return this.courseForm.controls['examTypes'] as FormArray;
  }

  public addExamType() {
    const examTypeControl = new FormControl(null);
    this.examTypes.push(examTypeControl);
  }

  public updateExamType(newExamType: ExamType, index: number) {
    if (!newExamType) {
      return;
    }

    // check if the selected examType is already in the examType form array
    if (
      this.examTypes.value.find(it => it?.id == newExamType.id) &&
      newExamType.id
    ) {
      this.alertService.showErrorAlert(
        'Das hat nicht geklappt!',
        'Die Prüfungsart wurde bereits hinzugefügt!'
      );

      // remove the value from the formControl
      this.examTypes.at(index).setValue(null);

      return;
    }
  }

  public removeExamType(index: number) {
    this.examTypes.removeAt(index);
  }

  public compareExamTypes(examType1: ExamType, examType2: ExamType): boolean {
    return examType1 && examType2
      ? examType1.id === examType2.id
      : examType1 === examType2;
  }

  /* ------ END Exam Type Form Control functions ------ */

  /* 
  adds or removes the clicked lecturer to the selected lecturers array 
  */
  public onLecturerSelected(lecturer: User) {
    if (this.selectedLecturerIds.includes(lecturer.id)) {
      this.selectedLecturerIds.splice(
        this.selectedLecturerIds.findIndex(it => it == lecturer.id),
        1
      );
    } else {
      this.selectedLecturerIds.push(lecturer.id);
    }
    this.courseForm.get('lecturers').setValue(this.selectedLecturerIds);

    if (this.selectedLecturerIds.length == 0) {
      this.courseForm.get('lecturers').setErrors({ required: true });
    } else {
      this.courseForm.get('lecturers').setErrors(null);
    }

    this.courseForm.get('lecturers').markAsTouched();
    this.courseForm.get('lecturers').markAsDirty();
    this.courseForm.get('lecturers').updateValueAndValidity();
  }

  /* 
  adds or removes the clicked lecturer to the selected lecturers array 
  */
  public onParticipantSelected(participant: User) {
    if (this.selectedParticipantIds.includes(participant.id)) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Teilnehmer*in entfernen',
          message: `Möchten Sie die Teilnehmer*in ${participant.name.firstname} ${participant.name.lastname} wirklich entfernen?`,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.selectedParticipantIds.splice(
            this.selectedParticipantIds.findIndex(it => it == participant.id),
            1
          );
        }
      });
    } else {
      this.selectedParticipantIds.push(participant.id);
    }
    this.courseForm.get('participants').setValue(this.selectedParticipantIds);

    if (this.selectedParticipantIds.length == 0) {
      this.courseForm.get('participants').setErrors({ required: true });
    } else {
      this.courseForm.get('participants').setErrors(null);
    }

    this.courseForm.get('participants').markAsTouched();
    this.courseForm.get('participants').markAsDirty();
    this.courseForm.get('participants').updateValueAndValidity();
  }

  /**
   * onDeleteExistingFile
   * @param file
   */
  public onDeleteExistingFile(file: CustomFileType) {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.courseForm.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.courseForm.value,
      this.initialFormValues
    );
  }

  /**
   * Preserve the original enum order
   */
  public originalCourseLocationOrder = (
    a: KeyValue<string, CourseLocation>,
    b: KeyValue<string, CourseLocation>
  ): number => {
    return 0;
  };

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
