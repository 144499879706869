import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import {
  ProgressType,
  StudentEducationProgress,
} from 'src/app/models/education-progress.model';
import { Role } from 'src/app/models/permission.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EducationProgressService } from 'src/app/services/education-progress.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-student-education-progress',
  templateUrl: './student-education-progress.component.html',
  styleUrls: ['./student-education-progress.component.scss'],
})
export class StudentEducationProgressComponent implements OnInit, OnDestroy {
  public studentEducationProgress: StudentEducationProgress[];
  public totalProgress: StudentEducationProgress;
  public isLoading = true;

  public role = Role;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private educationProgressService: EducationProgressService,
    public userService: UserService,
    private router: Router,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.activatedRoute.parent?.paramMap.subscribe(params => {
      let id_user = +atob(params.get('id'));

      if (!id_user) {
        id_user = this.userService.currentUser.id;
      }

      this.getEducationProgress(id_user);
    });
  }

  /**
   * getEducationProgress
   * get the education progress of the student
   * @returns void
   */
  public getEducationProgress(id_user): void {
    this.educationProgressService
      .getEducationProgress(id_user)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          console.debug('getEducationProgress backend response', response);
          if (!response.success) {
            if (response.message === 'user has no education course') {
              this.isLoading = false;
              this.studentEducationProgress = [];
              this.totalProgress = null;
              return;
            }
            console.error(response.message);
            this.isLoading = false;
            return;
          }
          this.studentEducationProgress = response.data.filter(
            progress => progress.type !== ProgressType.TOTAL
          );
          this.totalProgress = response.data.find(
            progress => progress.type === ProgressType.TOTAL
          );
          console.debug(
            'this.educationProgress',
            this.studentEducationProgress
          );
          this.isLoading = false;
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  /**
   * goToPage
   * Redirects to the given page
   * @param type
   */
  public goToPage(type: ProgressType) {
    switch (type) {
      case ProgressType.THEORETICAL:
        this.router.navigate(['eleguide/education/theoretical-education']);
        break;
      case ProgressType.PRACTICAL_1:
        this.router.navigate(['eleguide/education/practical-work']);
        break;
      case ProgressType.PRACTICAL_2:
        this.router.navigate(['eleguide/education/practical-work']);
        break;
      case ProgressType.TREATMENT:
        this.router.navigate(['eleguide/education/ambulatory-part']);
        break;
      case ProgressType.SUPERVISION:
        this.router.navigate(['eleguide/education/ambulatory-part']);
        break;
      case ProgressType.SELF_AWARENESS:
        this.router.navigate(['eleguide/education/self-awareness']);
        break;
      case ProgressType.TEACHING_ANALYSIS:
        this.router.navigate(['eleguide/education/self-awareness']);
        break;
      default:
        break;
    }
  }

  /**
   * getTooltip
   */
  public getTooltip(progressType: ProgressType) {
    switch (progressType) {
      case ProgressType.THEORETICAL:
        return 'Zur Kursübersicht';
      case ProgressType.PRACTICAL_1:
        return 'Zur Praktischen Tätigkeit';
      case ProgressType.PRACTICAL_2:
        return 'Zur Praktischen Tätigkeit';
      case ProgressType.TREATMENT:
        return 'Zu den Behandlungsfällen';
      case ProgressType.SELF_AWARENESS:
        return 'Zur Selbsterfahrung';
      case ProgressType.TEACHING_ANALYSIS:
        return 'Zur Lehranalyse';
      case ProgressType.SUPERVISION:
        return 'Zur Supervision';
      default:
        return '';
    }
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
