<app-event-room-planning
  class="room-planning"
  *ngIf="roomPlanningOpen"
  [events]="events"
  [title]="
    'Raumplanung für Patient ' + patientAppointmentForm.get('patient').value
  "
  subtitle="Planen Sie die Räume des Patienten Termins"
  (onRoomPlanningClose)="roomPlanningChanged($event)">
</app-event-room-planning>

<form
  *ngIf="!roomPlanningOpen"
  class="component-content"
  [formGroup]="patientAppointmentForm"
  (ngSubmit)="onSubmit()">
  <!-- HEADER  -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{
          editMode ? 'Patiententermin bearbeiten' : 'Patiententermin erstellen'
        }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie Termine für Ihren Patienten'
            : 'Erstellen Sie Termine für Ihren Patienten'
        }}
      </h3>
    </div>
  </div>

  <!-- MAIN CONTENT -->
  <div class="main-content">
    <!-- Patient Chiffre -->
    <div class="formular-row">
      <label class="formular-row-label" for="patient">
        <span class="label-title"> Patient </span>
        <span class="label-description"> Chiffre des Patienten </span>
      </label>

      <div class="formular-row-input">
        <input
          id="patient"
          type="text"
          formControlName="patient"
          class="hasomed-text-input" />
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- timeunits -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer in Zeiteinheiten
          <span *ngIf="isRequired(patientAppointmentForm.get('timeUnit'))">
            *
          </span>
        </span>
        <span class="label-description"> Eine Einheit 50min </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select id="timeUnit" formControlName="timeUnit" #duration>
              <mat-option value="1" class="hasomed-text-body"> 1 </mat-option>
              <mat-option value="2" class="hasomed-text-body"> 2 </mat-option>
              <mat-option value="3" class="hasomed-text-body"> 3 </mat-option>
              <mat-option value="4" class="hasomed-text-body"> 4 </mat-option>
              <mat-option value="5" class="hasomed-text-body"> 5 </mat-option>
              <mat-option value="6" class="hasomed-text-body"> 6 </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <app-error-message
          [formField]="
            patientAppointmentForm.get('timeUnit')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Termine -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Termine </span>
        <span class="label-description"> Termine der Sitzungen </span>
      </div>
      <div class="flex-c">
        <app-event-picker
          class="formular-row-input"
          [events]="events"
          [duration]="+patientAppointmentForm.get('timeUnit').value * 50"
          [startDate]="startDate"
          [endDate]="endDate"
          [recurrencePattern]="recurrencePattern"
          (eventsChanged)="onEventsChanged($event)"
          (recurrencePatternChanged)="recurrencePattern = $event"
          (datesChanged)="onDatesChanged($event)"
          [parentFormSubmitValidation]="formSubmitted"
          formControlName="dateGroup"
          id="dateGroup"></app-event-picker>
        <app-error-message
          [formField]="
            patientAppointmentForm.get('dateGroup')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Raumplanung -->
    <div class="formular-row">
      <label class="formular-row-label" for="room">
        <span class="label-title"> Raumplanung </span>
        <span class="label-description">
          Buchen Sie Räume für Ihre Patienten Sitzungen
        </span>
      </label>
      <div class="formular-row-input">
        <button
          id="room"
          type="button"
          (click)="onOpenRoomPlanning()"
          class="hasomed-text-button hasomed-button-primary button-full-width">
          <i
            class="fa-light fa-arrow-up-right-from-square hasomed-button-icon-sm"></i>
          <span class="hasomed-button-text">Raumplanung</span>
        </button>
      </div>
    </div>
  </div>

  <div class="flex-r gap-16 footer">
    <!-- Cancel button -->
    <button
      type="button"
      class="hasomed-text-button hasomed-button-white-grey"
      (click)="onCancel()">
      <span class="hasomed-button-text"> Abbrechen </span>
    </button>

    <!-- Submit button -->
    <button type="submit" class="hasomed-text-button hasomed-button-primary">
      <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
      <span class="hasomed-button-text">Speichern</span>
    </button>
  </div>
</form>
