import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { SidenavService } from 'src/app/services/sidenav.service';
import { UserService } from 'src/app/services/user.service';
import { Role } from 'src/app/models/permission.model';
import { PermissionService } from 'src/app/services/permission.service';
import { Subject, takeUntil } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionWarningComponent } from '../shared-components/session-warning/session-warning.component';
import { InstituteService } from 'src/app/services/institute.service';
import { Institute } from 'src/app/models/institute.model';
import { AlertService } from 'src/app/services/alert.service';
import { environment } from 'src/configs/environment';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-eleguide',
  templateUrl: './eleguide.component.html',
  styleUrls: ['./eleguide.component.scss'],
})
export class EleGuideComponent implements OnInit, OnDestroy {
  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: Event) {
    const userMenu = this.el.nativeElement.querySelector('#userMenu');
    const isClickInside = userMenu.contains(event.target as HTMLElement);
    const className = (
      (event.target as HTMLElement).className as unknown as SVGAnimatedString
    ).baseVal?.includes('user-menu-trigger');

    if (!isClickInside && !className && this.userMenuOpen) {
      this.userMenuOpen = false;
    }
    const instituteMenu = this.el.nativeElement.querySelector('#instituteMenu');
    const isClickInsideInstituteMenu = instituteMenu.contains(
      event.target as HTMLElement
    );
    const classNameInstitute = (
      (event.target as HTMLElement).className as unknown as SVGAnimatedString
    ).baseVal?.includes('institute-menu-trigger');

    if (
      !isClickInsideInstituteMenu &&
      !classNameInstitute &&
      this.instituteMenuOpen
    ) {
      this.instituteMenuOpen = false;
    }
  }
  public localStorage: Storage = localStorage;
  public JSON: JSON = JSON;

  public userMenuOpen = false;
  public instituteMenuOpen = false;
  public institutes: Institute[] = [];

  private sessionWarningDialogRef: any;
  private destroy$: Subject<void> = new Subject<void>();
  public showRoleSwitch =
    environment.roleSwitchingEnabled &&
    !this.userService.currentUserIsSuperadmin();

  constructor(
    public authService: AuthService,
    private router: Router,
    public sidenavService: SidenavService,
    public userService: UserService,
    private permissionService: PermissionService,
    private el: ElementRef,
    private sessionService: SessionService,
    private dialog: MatDialog,
    public instituteService: InstituteService,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {
    this.instituteService
      .getInstitute(this.userService.currentUser.id_institute)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (!response.success) {
            console.error(response.message);
            return;
          }
          this.instituteService.setCurrentInstitute(
            this.instituteService.parseBackendInstitute(response.data)
          );
        },
        error: error => {
          console.error(error);
        },
      });
  }

  ngOnInit() {
    this.authService.checkTgpOnline().subscribe({
      next: response => {
        if (response.status == 200) {
          this.sessionService
            .checkSessionValidity()
            .pipe(takeUntil(this.destroy$))
            .subscribe(isSessionExpiring => {
              if (isSessionExpiring && !this.sessionWarningDialogRef) {
                this.sessionWarningDialogRef = this.dialog.open(
                  SessionWarningComponent
                );
                this.sessionWarningDialogRef.afterClosed().subscribe(() => {
                  this.sessionWarningDialogRef = null;
                });
              } else if (!isSessionExpiring && this.sessionWarningDialogRef) {
                this.sessionWarningDialogRef.close();
              }
            });
        }
      },
    });
    this.loadInstitutes();
  }

  public onOpenSettings() {
    this.router.navigate(['/eleguide/settings/personal-data']);
    this.userMenuOpen = false;
  }

  public onOpenInformation() {
    this.router.navigate(['/eleguide/settings/imprint']);
    this.userMenuOpen = false;
  }

  public logout() {
    console.debug('Logout');
    this.authService.logout();
    this.userMenuOpen = false;
  }

  /* for presentation only */
  role = Role;
  public setRole(role: Role) {
    this.userService
      .updateUserRole(
        this.userService.currentUser.id,
        role,
        this.userService.currentUser.id_institute
      )
      .subscribe({
        next: response => {
          console.debug('Update Role Result:', response);
          if (!response.success) {
            console.error(response.message);
            return;
          }
          this.userService.currentUser.id_role = role;
          this.permissionService.permissionsChanged.next();
          window.location.reload();
          this.userMenuOpen = false;
        },
        error: error => {
          console.error(error);
        },
      });
  }

  /**
   * open or close user menu
   * @returns void
   */
  public onOpenInstituteMenu() {
    // if there is no institute to switch to, do nothing
    if (this.filterCurrentInstitute()?.length == 0) {
      return;
    }
    !this.instituteMenuOpen
      ? this.loadInstitutes(true)
      : (this.instituteMenuOpen = false);
  }

  /**
   * load all institutes for current user
   * @param openMenu
   */
  private loadInstitutes(openMenu = false) {
    this.instituteService
      .getInstitutesByUser(this.userService.currentUser.id)
      .subscribe({
        next: response => {
          console.debug('getInstitutesByUser backend response:', response);
          if (!response.success) {
            console.error(response.message);
            return;
          }
          this.institutes = response.data?.map((institute: any) =>
            this.instituteService.parseBackendInstitute(institute)
          );
          if (this.filterCurrentInstitute()?.length > 0 && openMenu) {
            this.instituteMenuOpen = true;
          }
        },
        error: error => {
          console.error(error);
        },
      });
  }

  /**
   * filter out current institute from institutes
   * @returns all institutes except the current one
   */
  public filterCurrentInstitute(): Institute[] {
    return this.institutes?.filter(
      institute => institute.id !== this.instituteService.currentInstitute?.id
    );
  }

  /**
   * switch to selected institute
   * @param institute
   */
  public onSwitchInstitute(institute: Institute) {
    console.debug('switch to institute', institute);
    this.instituteMenuOpen = false;
    if (this.userService.currentUser.isSuperadmin === true) {
      this.userService
        .updateInstituteFromUser(institute.id, this.userService.currentUser.id)
        .subscribe({
          next: response => {
            console.debug('updateInstituteFromUser backend response', response);
            if (!response.success) {
              console.error(response.message);
              this.alertService.showErrorAlert(
                'Fehler!',
                'Wechsel des Instituts fehlgeschlagen.'
              );
              return;
            }
            window.location.reload();
          },
          error: error => {
            console.error(error);
            this.alertService.showErrorAlert(
              'Fehler!',
              'Wechsel des Instituts fehlgeschlagen.'
            );
            return;
          },
        });
    } else {
      this.userService
        .switchUsersInstitute(institute.id, this.userService.currentUser.id)
        .subscribe({
          next: response => {
            console.debug('Update Users Institute And Role Result:', response);
            if (!response.success) {
              console.error(response.message);
              this.alertService.showErrorAlert(
                'Fehler!',
                'Wechsel des Instituts fehlgeschlagen.'
              );
              return;
            }
            this.permissionService.permissionsChanged.next();
            window.location.reload();
          },
          error: error => {
            console.error(error);
            this.alertService.showErrorAlert(
              'Fehler!',
              'Wechsel des Instituts fehlgeschlagen.'
            );
          },
        });
    }
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
