import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, defaultIfEmpty, firstValueFrom, takeUntil } from 'rxjs';
import { EducationProgressDialogComponent } from 'src/app/components/shared-components/education-progress-dialog/education-progress-dialog.component';
import { EmptyStateDialogComponent } from 'src/app/components/shared-components/empty-state-dialog/empty-state-dialog.component';
import { ImageDetailDialogComponent } from 'src/app/components/shared-components/image-detail-dialog/image-detail-dialog.component';
import {
  ProgressType,
  StudentEducationProgress,
} from 'src/app/models/education-progress.model';
import {
  FilterDateRange,
  Filter,
  FilterType,
} from 'src/app/models/filter.model';
import { Feature, Permission, Role } from 'src/app/models/permission.model';
import { User } from 'src/app/models/user.model';
import { FilterStudentPipe } from 'src/app/pipes/filter-student.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EducationProgressService } from 'src/app/services/education-progress.service';
import { UserService } from 'src/app/services/user.service';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';
import * as XLSX from 'xlsx';

export interface StudentTableData {
  user: User;
  educationProgress: StudentEducationProgress[];
}

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'name',
    'userIdentifier',
    'education_progress',
    'entryDate',
    'label',
    'actions',
  ];
  public dataSource: MatTableDataSource<StudentTableData> =
    new MatTableDataSource();
  public allStudents: StudentTableData[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public isLoading = true;

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;

  private dateRangeSubject: Subject<FilterDateRange | null> = new Subject();
  public dateRangeFilter: Filter = { type: FilterType.DATE_RANGE, value: null };
  public studentFilter: Filter[] = [
    this.dateRangeFilter,
    {
      type: FilterType.LABEL,
      value: null,
    },
  ];
  public hasActiveFilterValue = hasActiveFilterValue;
  public filterOpened: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private educationProgressService: EducationProgressService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    // Create table data
    this.getData();

    this.dateRangeSubject.subscribe({
      next: dateRange => {
        if (!dateRange) {
          this.searchForm.patchValue({
            dateRange: {
              start: null,
              end: null,
            },
          });
        }
        this.dateRangeFilter.value = dateRange;
        this.applyStudentFilter();
      },
    });
  }

  // get students from backend
  private async getData() {
    this.userService.getInstituteUsersByRole(Role.STUDENT, true).subscribe({
      next: async response => {
        console.debug('get students backend response:', response);
        if (!response.success) {
          console.error(response.message);
          this.isLoading = false;
          return;
        }

        const promises = response.data?.map(
          async (userData: any): Promise<StudentTableData> => {
            const user = await this.userService.parseBackendUser(userData);
            const educationProgress = await this.fetchEducationProgress(user);

            return {
              user: user,
              educationProgress: educationProgress,
            };
          }
        );

        if (!promises) {
          this.isLoading = false;
          return;
        }

        this.allStudents = await Promise.all(promises);
        this.dataSource.data = this.allStudents;
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'name':
              return item.user.name?.lastname;
            case 'education_progress':
              return this.getTotalEducationProgress(item);
            case 'vintage':
              return item.user.entryDate;
            case 'label':
              return item.user.status?.name;
            default:
              return item[property];
          }
        };

        this.dataSource.filterPredicate = (data, filter) => {
          const firstname = data.user.name.firstname
            ? String(data.user.name.firstname).toLowerCase()
            : '';
          const lastname = data.user.name.lastname
            ? String(data.user.name.lastname).toLowerCase()
            : '';

          const dataStr = firstname + ' ' + lastname;
          return dataStr.indexOf(filter.toLowerCase()) !== -1;
        };
        this.isLoading = false;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      error: error => {
        console.error(error);
        this.isLoading = false;
      },
    });
  }

  /**
   * studentFilterChanged
   * gets called when the student filter changed
   * @param student Filter[]
   * @returns void
   */
  public studentFilterChanged(studentFilter: Filter[]): void {
    this.studentFilter = studentFilter;

    if (!studentFilter.includes(this.dateRangeFilter)) {
      studentFilter.push(this.dateRangeFilter);
    }

    this.applyStudentFilter();
    this.filterMenuTrigger.closeMenu();

    // find FILTER_TYPE.DATE_RANGE and update date range
    const dateRangeFilter = studentFilter.find(
      filter => filter.type === FilterType.DATE_RANGE
    );

    if (dateRangeFilter && !dateRangeFilter.value) {
      this.dateRangeSubject.next(null);
    }
  }

  /**
   * applyStudentFilter
   * applies the student filter
   * @returns void
   */
  public applyStudentFilter(): void {
    this.dataSource.data = FilterStudentPipe.prototype.transform(
      this.allStudents,
      this.studentFilter
    );
  }

  public applyDateRange() {
    this.dateRangeSubject.next({
      start: this.searchForm.value.dateRange?.start,
      end: this.searchForm.value.dateRange?.end,
    });
  }

  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  searchForm = this.formBuilder.group({
    searchText: ['', noWhitespaceValidator],
    dateRange: new FormGroup({
      start: new FormControl(null, Validators.required),
      end: new FormControl(null, Validators.required),
    }),
  });

  public deleteStudent(student_id: number) {
    console.log('delete student: ', student_id);
    this.dialog.open(EmptyStateDialogComponent);
  }

  public openStudentDetail(student_id: number) {
    this.router.navigate(['detail', btoa(String(student_id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * showProgressDetails
   * opens the education progress dialog
   * @param row
   * @returns void
   */
  public showProgressDetails(row: StudentTableData): void {
    this.dialog.open(EducationProgressDialogComponent, {
      data: {
        user: row.user,
        educationProgress: row.educationProgress,
        showName: true,
      },
    });
  }

  /**
   * fetchEducationProgress
   * fetches the education progress from the backend
   * @param user
   * @returns education progress
   */
  private async fetchEducationProgress(
    user: User
  ): Promise<StudentEducationProgress[]> {
    const educationProgress: StudentEducationProgress[] = [];
    try {
      const response: any = await firstValueFrom(
        this.educationProgressService.getEducationProgress(user.id).pipe(
          takeUntil(this.destroy$),
          defaultIfEmpty({
            success: false,
            data: [],
            message: 'No data emitted',
          })
        )
      );
      if (!response.success) {
        if (response.message === 'user has no education course') {
          return educationProgress;
        }
        // if subscription was cancelled
        if (response.message === 'No data emitted') {
          return educationProgress;
        }
        console.error(response.message);
        return educationProgress;
      }
      return response.data;
    } catch (error) {
      console.error('error fetching educationProgress', error);
      return educationProgress;
    }
  }

  public getTotalEducationProgress(row: StudentTableData): number {
    const totalProcentualProgress = row.educationProgress.find(
      (progress: StudentEducationProgress) =>
        progress.type === ProgressType.TOTAL
    )?.procentual;

    return totalProcentualProgress ? totalProcentualProgress : 0;
  }

  /**
   * exportDataToExcel
   * exports the student data to excel
   * @returns void
   */
  public exportDataToExcel(): void {
    const users = this.allStudents.map(student => {
      return {
        name: student.user.name.firstname + ' ' + student.user.name.lastname,
        email: student.user.email,
      };
    });

    try {
      // create a worksheet
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(users);

      // rename columns
      worksheet['A1'].v = 'Name';
      worksheet['B1'].v = 'E-Mail';

      // change column width to fit content
      const wscols = [{ wch: 20 }, { wch: 30 }];
      worksheet['!cols'] = wscols;

      // create a workbook
      const woorkbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(woorkbook, worksheet, 'Kandidat_innen');

      // save to file
      const date = moment().format('DD_MM_YYYY-HH_mm');
      const fileName = `Kandidat_innen_${date}.xlsx`;

      // write workbook and export
      XLSX.writeFile(woorkbook, fileName);

      this.alertService.showSuccessAlert(
        'Liste exportiert',
        `Die Liste ${fileName} wurde erfolgreich exportiert.`
      );
    } catch (error) {
      this.alertService.showErrorAlert(
        'Fehler beim Exportieren der Daten',
        'Beim Exportieren der Daten ist ein Fehler aufgetreten.'
      );
      console.error('error exporting data to excel', error);
    }
  }

  /**
   * openProfilePictureDialog
   * opens the profile picture dialog
   * @param user
   */
  public openProfilePictureDialog(user: User): void {
    this.dialog.open(ImageDetailDialogComponent, {
      data: {
        image: user.profilePicture,
        viewOnly: true,
      },
    });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
