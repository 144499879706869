import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';
import { InstituteService } from 'src/app/services/institute.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-onb-education-course',
  templateUrl: './onb-education-course.component.html',
  styleUrls: ['./onb-education-course.component.scss'],
})
export class OnbEducationCourseComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public instituteService: InstituteService,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    // if a current institute is not set, get the id_institute from the user and get the institute
    // if the user has no id_institute, show the user a message that he has to create an institute first
    if (!this.instituteService.currentInstitute) {
      if (this.userService.currentUser.id_institute) {
        this.instituteService
          .getInstitute(this.userService.currentUser.id_institute)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: response => {
              if (!response.success) {
                console.error(response.message);
                this.isLoading = false;
                return;
              }
              this.instituteService.setCurrentInstitute(
                this.instituteService.parseBackendInstitute(response.data)
              );
              this.isLoading = false;
            },
            error: error => {
              console.error(error);
              this.isLoading = false;
            },
          });
      } else {
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
    }
  }

  /**
   * onCancel
   * cancel the onboarding process
   * @returns void
   */
  public onCancel(): void {
    // TODO
  }

  /**
   * onGoBack
   * navigate to master data
   * @returns void
   */
  public onGoBack(): void {
    this.router.navigate(['../master-data'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onContinue
   * navigate to user roles
   * @returns void
   */
  public onContinue(): void {
    this.router.navigate(['../user-roles'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onCreateEducationCourse
   * navigate to create education course
   * @returns void
   */
  public onCreateEducationCourse(): void {
    this.router.navigate(['./create'], { relativeTo: this.activatedRoute });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
