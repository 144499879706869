import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Institute } from 'src/app/models/institute.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { InstituteService } from 'src/app/services/institute.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-institutes',
  templateUrl: './institutes.component.html',
  styleUrls: ['./institutes.component.scss'],
})
export class InstitutesComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<Institute> = new MatTableDataSource();
  public isLoading = true;
  public displayedColumns = ['name', 'description', 'onboarding', 'actions'];
  public searchForm = new FormGroup({
    searchText: new FormControl(''),
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private instituteService: InstituteService,
    private alertService: AlertService,
    private userService: UserService,
    private router: Router,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    this.instituteService
      .getAllInstitutes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          console.debug('getAllInstitutes backend response: ', response);
          if (!response.success) {
            this.alertService.showErrorAlert(
              'Fehler',
              'Institute konnten nicht geladen werden'
            );
            this.isLoading = false;
            return;
          }
          this.dataSource.data = response.data?.map((institute: Institute) => {
            institute.logo = institute.logo ? atob(institute.logo) : null;
            return institute;
          });
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'onboarding':
                return item.is_founded;
              default:
                return item[property];
            }
          };
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
        },
        error: error => {
          console.error(error);
          this.alertService.showErrorAlert(
            'Fehler',
            'Institute konnten nicht geladen werden'
          );
          this.isLoading = false;
        },
      });
  }

  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public createInstitute(): void {
    this.userService.currentUser.id_institute = null;
    this.instituteService.currentInstitute = null;
    this.router.navigate(['/eleguide/onboarding/master-data']);
  }

  public openInstituteDetails(institute: Institute): void {
    this.userService.currentUser.id_institute = institute.id;

    this.userService
      .updateInstituteFromUser(institute.id, this.userService.currentUser.id)
      .subscribe({
        next: response => {
          console.debug('updateInstituteFromUser backend response', response);
          if (!response.success) {
            console.error(response.message);
            this.alertService.showErrorAlert(
              'Fehler!',
              'Wechsel des Instituts fehlgeschlagen.'
            );
            return;
          }
          this.router.navigate([
            '/eleguide/onboarding/master-data',
            btoa(institute.id.toString()),
          ]);
        },
        error: error => {
          console.error(error);
          this.alertService.showErrorAlert(
            'Fehler!',
            'Wechsel des Instituts fehlgeschlagen.'
          );
          return;
        },
      });

    this.router.navigate([
      '/eleguide/onboarding/master-data',
      btoa(institute.id.toString()),
    ]);
  }

  public editInstitute(institute: Institute): void {
    this.userService.currentUser.id_institute = institute.id;

    this.userService
      .updateInstituteFromUser(institute.id, this.userService.currentUser.id)
      .subscribe({
        next: response => {
          console.debug('updateInstituteFromUser backend response', response);
          if (!response.success) {
            console.error(response.message);
            this.alertService.showErrorAlert(
              'Fehler!',
              'Wechsel des Instituts fehlgeschlagen.'
            );
            return;
          }
          this.router.navigate([
            '/eleguide/onboarding/master-data',
            btoa(institute.id.toString()),
          ]);
        },
        error: error => {
          console.error(error);
          this.alertService.showErrorAlert(
            'Fehler!',
            'Wechsel des Instituts fehlgeschlagen.'
          );
          return;
        },
      });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
