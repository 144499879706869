<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Ambulante Einträge</h1>
      <h3 class="hasomed-text-h3">Füge neue ambulante Einträge hinzu</h3>
    </div>
    <form class="header-buttons" [formGroup]="searchForm">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <button
        type="button"
        (click)="onCreateTreatmentCase()"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-calendar-plus hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Neuen Behandlungsfall erstellen</span>
      </button>
    </form>
  </div>

  <div class="main-content">
    <app-treatment-cases
      [searchText]="searchForm.get('searchText').value"></app-treatment-cases>
  </div>
</div>
