<div class="my-space-header">
  <!-- First card -->
  <div class="card">
    <div class="card-header">
      <span class="hasomed-text-h3"> {{ getCardTitle(1) }} </span>
      <!-- navigate to theoretical-education for student -->
      <button
        *ngIf="userService.currentUserIsStudent()"
        class="table-button-icon"
        matTooltip="Kurse anzeigen"
        (click)="navigateTo('../../education/theoretical-education')">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
      <!-- navigate to course-administration for lecturer, admin -->
      <button
        *ngIf="!userService.currentUserIsStudent()"
        class="table-button-icon"
        matTooltip="Kurse anzeigen"
        (click)="navigateTo('../../education/course-administration')">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
    </div>
    <div class="flex-r gap-16 align-center">
      <span class="hasomed-text-h2">
        {{
          userService.currentUserIsStudent()
            ? (currentCoursesOfStudent | async) !== null
              ? (currentCoursesOfStudent | async)
              : 'Lade Daten...'
            : ''
        }}
        {{
          userService.currentUserIsLecturer()
            ? (ownCourses | async) !== null
              ? (ownCourses | async)
              : 'Lade Daten...'
            : ''
        }}
        {{
          userService.currentUserIsAdministrator()
            ? (currentCourses | async) !== null
              ? (currentCourses | async)
              : 'Lade Daten...'
            : ''
        }}
      </span>
    </div>
  </div>

  <!-- Second card -->
  <div class="card" *ngIf="userService.currentUserIsAdministrator()">
    <div class="card-header">
      <span class="hasomed-text-h3"> {{ getCardTitle(2) }} </span>
      <button
        *ngIf="userService.currentUserIsAdministrator()"
        class="table-button-icon"
        matTooltip="Anwesenheiten anzeigen"
        (click)="navigateToELogAndSetFilter(openAdminELogs)">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
    </div>
    <div class="flex-r gap-16 align-center">
      <span class="hasomed-text-h2">
        {{
          userService.currentUserIsAdministrator()
            ? (openAdminELogs | async) !== null
              ? (openAdminELogs | async)
              : 'Lade Daten...'
            : ''
        }}
      </span>
      <button
        *ngIf="
          (openAdminELogs | async) !== null &&
          (openAdminELogs | async) !== 0 &&
          userService.currentUserIsAdministrator()
        "
        type="button"
        class="hasomed-text-button hasomed-button-primary btn-elog-check"
        (click)="navigateToELogAndSetFilter(openAdminELogs)">
        <i class="hasomed-button-icon-sm fa-light fa-user-check"></i>
        <span class="hasomed-button-text">Jetzt checken</span>
      </button>
    </div>
  </div>

  <!-- Third card -->
  <div class="card">
    <div class="card-header">
      <span class="hasomed-text-h3"> {{ getCardTitle(3) }} </span>
      <button
        *ngIf="userService.currentUserIsStudent()"
        class="table-button-icon"
        matTooltip="Ausbildungsfortschritt anzeigen"
        (click)="navigateTo('../../organization/education-progress')">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
      <button
        *ngIf="userService.currentUserIsLecturer()"
        class="table-button-icon"
        matTooltip="Anwesenheiten anzeigen"
        (click)="navigateToELogAndSetFilter(openLecturerELogs)">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
      <button
        *ngIf="userService.currentUserIsAdministrator()"
        class="table-button-icon"
        matTooltip="Lehrpersonal anzeigen"
        (click)="navigateTo('../../education/lecturer')">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
    </div>
    <div
      class="flex-r full-width align-center"
      *ngIf="
        userService.currentUserIsStudent() && (totalProgress | async) !== null
      ">
      <div class="progress-container">
        <div
          class="progress-bar"
          [ngStyle]="{
            width: (totalProgress | async)?.procentual + '%'
          }"></div>
      </div>
      <span class="progress-label hasomed-text-h2">
        {{ (totalProgress | async)?.procentual + '%' }}
      </span>
    </div>
    <span
      class="hasomed-text-h2"
      *ngIf="
        userService.currentUserIsStudent() && (totalProgress | async) === null
      ">
      Lade Daten...
    </span>

    <div class="flex-r gap-16 align-center">
      <span class="hasomed-text-h2">
        {{
          userService.currentUserIsLecturer()
            ? (openLecturerELogs | async) !== null
              ? (openLecturerELogs | async)
              : 'Lade Daten...'
            : ''
        }}
        {{
          userService.currentUserIsAdministrator()
            ? (currentLecturers | async) !== null
              ? (currentLecturers | async)
              : 'Lade Daten...'
            : ''
        }}
      </span>
      <button
        *ngIf="
          (openLecturerELogs | async) !== null &&
          (openLecturerELogs | async) !== 0 &&
          userService.currentUserIsLecturer()
        "
        type="button"
        class="hasomed-text-button hasomed-button-primary btn-elog-check"
        (click)="navigateToELogAndSetFilter(openLecturerELogs)">
        <i class="hasomed-button-icon-sm fa-light fa-user-check"></i>
        <span class="hasomed-button-text">Jetzt checken</span>
      </button>
    </div>
  </div>
</div>
