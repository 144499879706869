import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { SelfAwarenessEntry } from 'src/app/models/self-awareness-entry.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { SelfAwarenessService } from 'src/app/services/self-awareness.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-self-awareness',
  templateUrl: './self-awareness.component.html',
  styleUrls: ['./self-awareness.component.scss'],
})
export class SelfAwarenessComponent implements OnInit, OnDestroy {
  selfAwarenessEntries: SelfAwarenessEntry[];
  searchForm: FormGroup;
  filterOpened: boolean = false;
  filterEmpty: boolean = true;

  displayedColumns = [
    'title',
    'responsiblePerson',
    'date',
    'duration',
    'actions',
  ];
  dataSource: MatTableDataSource<SelfAwarenessEntry> = new MatTableDataSource();
  isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private selfAwarenessService: SelfAwarenessService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      searchText: new FormControl(''),
    });

    this.getSelfAwarenessEntries();
  }

  public getSelfAwarenessEntries() {
    const id_student = this.userService.currentUser.id;
    this.selfAwarenessService
      .getSelfAwarenessEntriesFromStudent(id_student)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async result => {
          console.debug('Self Awareness Backend Data:', result);
          if (!result.success) {
            console.error(result.message);
            this.isLoading = false;
            return;
          }

          this.dataSource.data = result.data
            ? await Promise.all(
                result.data.map(
                  async (
                    selfAwarenessEntry: any
                  ): Promise<SelfAwarenessEntry> =>
                    await this.selfAwarenessService.parseBackendSelfAwarenessEntry(
                      selfAwarenessEntry
                    )
                )
              )
            : [];
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'title':
                return item.title;
              default:
                return item[property];
            }
          };
          this.isLoading = false;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  viewSelfAwareness(selfAwarenessEntry: any) {
    console.log('viewSelfAwareness', selfAwarenessEntry);
    this.router.navigate(['./', btoa(String(selfAwarenessEntry.id))], {
      relativeTo: this.route,
    });
  }

  createSelfAwareness() {
    this.router.navigate(['./create'], { relativeTo: this.route });
  }

  editSelfAwareness(selfAwarenessEntry: SelfAwarenessEntry) {
    this.router.navigate(['./', 'edit', btoa(String(selfAwarenessEntry.id))], {
      relativeTo: this.route,
    });
  }

  deleteSelfAwareness(selfAwarenessEntry: SelfAwarenessEntry) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Löschen',
        message: `Möchten Sie den Eintrag '${selfAwarenessEntry.title}' wirklich löschen?`,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.selfAwarenessService
          .deleteSelfAwarenessEntry(selfAwarenessEntry.id)
          .subscribe({
            next: result => {
              console.debug('Course Deleted:', result);
              if (!result.success) {
                // TODO: alert error
                console.error(result.message);
                return;
              }

              this.alertService.showSuccessAlert(
                'Entrag gelöscht!',
                `Der Eintrag '${selfAwarenessEntry.title}' wurde gelöscht!`
              );

              // delete entry from table data without reloading from backend
              const index = this.dataSource.data.indexOf(selfAwarenessEntry);
              if (index > -1) {
                this.dataSource.data = this.dataSource.data.filter(
                  item => item !== selfAwarenessEntry
                );
              }
            },
            error: error => {
              console.error(error);
            },
          });
      }
    });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
