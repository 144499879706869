import { Injectable } from '@angular/core';
import {
  TreatmentCase,
  TreatmentCaseAppointment,
} from '../models/treatment-case.model';
import { BehaviorSubject, Observable, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { UserService } from './user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user.model';
import { environment } from 'src/configs/environment';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { RoomService } from './room.service';
import { DecryptionService } from './decryption.service';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class TreatmentCaseService {
  constructor(
    private userService: UserService,
    private http: HttpClient,
    private cryptoService: CryptoService,
    private roomService: RoomService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  public selectedTreatmentCase: BehaviorSubject<TreatmentCase> =
    new BehaviorSubject<TreatmentCase>(null);

  public getStudentsTreatmentCases(id_student: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          '/treatmentCase/getStudentsTreatmentCases.php?id_student=' +
          id_student
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  public getTreatmentCase(id_treatment_case: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          '/treatmentCase/getTreatmentCase.php?id_treatment_case=' +
          id_treatment_case
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  public getTherapyTypes(): Observable<any> {
    return this.http
      .get(environment.authority_short + 'treatmentCase/getTherapyTypes.php')
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  public getProcedures(): Observable<any> {
    return this.http
      .get(environment.authority_short + 'treatmentCase/getProcedures.php')
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  public async createTreatmentCase(
    treatmentCase: TreatmentCase
  ): Promise<Observable<any>> {
    treatmentCase.chiffre = await this.cryptoService.encrypt(
      treatmentCase.chiffre
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.authority_short + 'treatmentCase/createTreatmentCase.php',
      treatmentCase,
      { headers: headers }
    );
  }

  public createAppointment(
    treatmentCaseId: number,
    appointment: any
  ): Observable<any> {
    appointment.treatmentCaseId = treatmentCaseId;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.authority_short + 'treatmentCase/createAppointment.php',
      appointment,
      { headers: headers }
    );
  }

  public updateAppointment(appointment: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.authority_short + 'treatmentCase/updateAppointment.php',
      appointment,
      { headers: headers }
    );
  }

  public async updateTreatmentCase(
    treatmentCase: TreatmentCase
  ): Promise<Observable<any>> {
    treatmentCase.chiffre = await this.cryptoService.encrypt(
      treatmentCase.chiffre
    );
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.authority_short + 'treatmentCase/updateTreatmentCase.php',
      treatmentCase,
      { headers: headers }
    );
  }

  public async parseBackendTreatmentCase(
    treatmentCase: any
  ): Promise<TreatmentCase> {
    const parsedTreatmentCase: TreatmentCase = {
      id: treatmentCase.id,
      chiffre: await this.decryptionService.decryptString(
        treatmentCase.chiffre
      ),
      age: treatmentCase.age,
      therapyForm: treatmentCase.therapyForm,
      therapyType: treatmentCase.therapyType,
      procedures: treatmentCase.procedures,
      supervisors: treatmentCase.supervisors
        ? await Promise.all(
            treatmentCase.supervisors?.map(
              async (supervisor: User): Promise<User> => {
                return await this.userService.parseBackendUser(supervisor);
              }
            )
          )
        : [],
      supervisorText: treatmentCase.supervisorText,
      appointments: treatmentCase.appointments
        ? await Promise.all(
            treatmentCase.appointments?.map(
              async (appointment: any): Promise<TreatmentCaseAppointment> => {
                return {
                  id: appointment.id,
                  type: appointment.appointmentType?.name,
                  startDate: appointment.startDate
                    ? moment(
                        appointment.startDate,
                        'YYYY-MM-DD HH:mm:ss'
                      ).toDate()
                    : null,
                  endDate: appointment.endDate
                    ? moment(
                        appointment.endDate,
                        'YYYY-MM-DD HH:mm:ss'
                      ).toDate()
                    : null,
                  timeUnits: appointment.timeUnits,
                  supervisor: appointment.supervisor
                    ? await this.userService.parseBackendUser(
                        appointment.supervisor
                      )
                    : null,
                  supervisorText: appointment.supervisorText,
                  selectedPatientEventDateIds:
                    appointment.selectedPatientEventDateIds?.map(
                      (id: number) => +id
                    ),
                  recurrencePattern: appointment.recurrencePattern,
                  eventDates: appointment.eventDates
                    ? await Promise.all(
                        appointment.eventDates?.map(async (eventDate: any) => {
                          return {
                            id: eventDate.id,
                            startDate: eventDate.startDate
                              ? moment(
                                  eventDate.startDate,
                                  'YYYY-MM-DD HH:mm:ss'
                                ).toDate()
                              : null,
                            endDate: eventDate.endDate
                              ? moment(
                                  eventDate.endDate,
                                  'YYYY-MM-DD HH:mm:ss'
                                ).toDate()
                              : null,
                            canceled: eventDate.canceled,
                            room: eventDate.room
                              ? await this.roomService.parseBackendRoom(
                                  eventDate.room
                                )
                              : null,
                          };
                        })
                      )
                    : [],
                  internalNote: appointment.internalNote,
                };
              }
            )
          )
        : [],
      internalNote: treatmentCase.internalNote,
      files: treatmentCase.files,
    };
    return new Promise<TreatmentCase>(async (resolve, reject) => {
      resolve(parsedTreatmentCase);
    });
  }
}
