import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { Course } from 'src/app/models/course.model';
import {
  FilterDateRange,
  Filter,
  FilterType,
} from 'src/app/models/filter.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { FilterService } from 'src/app/services/filter.service';
import { UserService } from 'src/app/services/user.service';
import {
  getCourseRoom,
  getEducationCourseTitles,
  getFullLecturerNames,
} from 'src/app/utils/course.utils';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';

@Component({
  selector: 'app-theoretical-education',
  templateUrl: './theoretical-education.component.html',
  styleUrls: ['./theoretical-education.component.scss'],
})
export class TheoreticalEducationComponent implements OnInit, OnDestroy {
  private coursesSubject: Subject<Course[]> = new Subject();
  public courses: Course[];

  public selectedCourse: Course;
  public selectedCourseId: number;
  public filterOpened: boolean = false;
  public searchForm: FormGroup;
  public isLoading = true;

  public isStudent: boolean = false;

  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;
  @Input() searchText: string;

  private dateRangeSubject: Subject<FilterDateRange | null> = new Subject();
  public dateRangeFilter: Filter = { type: FilterType.DATE_RANGE, value: null };
  public courseFilter: Filter[] = [
    this.dateRangeFilter,
    {
      type: FilterType.COURSE_TYPE,
      value: null,
    },
    {
      type: FilterType.LECTURER,
      value: this.filterService.getLecturerFilterValue(),
    },
    {
      type: FilterType.ROOM_NAME,
      value: null,
    },
  ];
  public hasActiveFilterValue = hasActiveFilterValue;

  private destroy$ = new Subject<void>();

  public getFullLecturerNames = getFullLecturerNames;
  public getEducationCourseTitles = getEducationCourseTitles;
  public getCourseRoom = getCourseRoom;

  constructor(
    public courseService: CourseService,
    private userService: UserService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private dialog: MatDialog,
    private filterService: FilterService,
    private router: Router,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url === '/eleguide/education/theoretical-education') {
          this.selectedCourseId = null;
        } else {
          this.selectedCourseId = +atob(
            this.route.firstChild?.snapshot.params['id']
          );
        }
      });

    this.route.firstChild?.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params['id'] != undefined) {
          this.selectedCourseId = +atob(params['id']);
        }
      });

    this.isStudent = this.userService.currentUser.id_role === 4;
    if (this.isStudent) {
      this.courseFilter.push(
        { type: FilterType.REGISTERED_COURSES, value: null },
        { type: FilterType.FINISHED_COURSES, value: null },
        { type: FilterType.OPEN_COURSES, value: null },
        { type: FilterType.PENDING_ELOGS, value: null }
      );
    }

    this.dateRangeSubject.subscribe({
      next: dateRange => {
        if (!dateRange) {
          this.searchForm.patchValue({
            dateRange: {
              start: null,
              end: null,
            },
          });
        }

        // update value inside courseFilter
        this.dateRangeFilter.value = dateRange;
        this.courseFilter = this.courseFilter.map(filter => {
          if (filter.type === FilterType.DATE_RANGE) {
            filter.value = dateRange;
          }
          return filter;
        });
      },
    });

    this.getStudentsCourses();
    this.coursesSubject
      // .pipe(takeUntil(this.destroy$))
      .subscribe((courses: Course[]) => {
        this.courses = courses;

        // if a course id was passed as an url paramter set the selected course
        if (this.selectedCourseId) {
          this.selectedCourse = this.courses.find(course => {
            course.id === this.selectedCourseId;
          });

          // move selected course to the top of the list
          this.courses.sort((courseA, courseB) => {
            if (courseA.id === this.selectedCourseId) {
              return -1;
            }
            if (courseB.id === this.selectedCourseId) {
              return 1;
            }
            return 0;
          });
        }
        this.isLoading = false;
      });

    this.searchForm = new FormGroup({
      searchText: new FormControl(''),
      dateRange: new FormGroup({
        start: new FormControl(null, Validators.required),
        end: new FormControl(null, Validators.required),
      }),
    });
  }

  public getStudentsCourses() {
    const id_user = this.userService.currentUser.id;
    const id_institute = this.userService.currentUser.id_institute;
    this.courseService
      .getStudentsCourses(id_user, id_institute, 'educationCourses')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('Courses BackendData', response);
          if (!response.success) {
            console.error(response.message);
            this.isLoading = false;
            return;
          }
          this.coursesSubject.next(
            await Promise.all(
              response.data.courses.map(
                async (courseData: any): Promise<Course> => {
                  return this.courseService.parseBackendCourse(courseData);
                }
              )
            )
          );
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  public showDetails(course: Course) {
    this.router.navigate(['./', btoa(course.id.toString())], {
      relativeTo: this.route,
    });
  }

  public hideDetails() {
    this.router.navigate(['./'], {
      relativeTo: this.route,
    });
  }

  public applyDateRange() {
    this.dateRangeSubject.next({
      start: this.searchForm.value.dateRange?.start,
      end: this.searchForm.value.dateRange?.end,
    });
  }

  /**
   * courseFilterChanged
   * gets called when the course filter changed
   * @param courseFilter Filter[]
   * @returns void
   */
  public courseFilterChanged(courseFilter: Filter[]): void {
    this.courseFilter = courseFilter;

    if (!courseFilter.includes(this.dateRangeFilter)) {
      courseFilter.push(this.dateRangeFilter);
    }

    this.filterMenuTrigger.closeMenu();

    // find FILTER_TYPE.DATE_RANGE and update date range subject
    const dateRangeFilter = courseFilter.find(
      filter => filter.type === FilterType.DATE_RANGE
    );

    if (dateRangeFilter && !dateRangeFilter.value) {
      this.dateRangeSubject.next(null);
    } else if (dateRangeFilter) {
      this.dateRangeSubject.next(dateRangeFilter.value as FilterDateRange);
    }
  }

  public onCourseStudentChange(course: Course) {
    const id_student = this.userService.currentUser.id;
    const dialogTitle = course.registered ? 'Abmelden' : 'Anmelden';
    const dialogMessage = course.registered
      ? `Möchten Sie sich wirklich vom Kurs '${course.title}' abmelden?`
      : `Möchten Sie sich wirklich am Kurs '${course.title}' anmelden?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: dialogTitle,
        message: dialogMessage,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (!dialogResult) {
        return;
      }
      if (course.registered) {
        this.deregisterFromCourse(course, id_student);
      } else {
        this.registerToCourse(course, id_student);
      }
    });
  }

  private registerToCourse(course: Course, id_student: number) {
    this.courseService.registerToCourse(course.id, id_student).subscribe({
      next: response => {
        console.debug('Register to Course BackendData', response);
        if (response.success) {
          this.getStudentsCourses();

          this.alertService.showSuccessAlert(
            `Angemeldet`,
            `Erfolgreich am Kurs '${course.title}' angemeldet!`
          );
        } else {
          this.alertService.showErrorAlert(
            'Fehler',
            `Beim anmelden am Kurs '${course.title}' ist ein Fehler aufgetreten!`
          );
        }
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Beim anmelden am Kurs '${course.title}' ist ein Fehler aufgetreten!`
        );
      },
    });
  }

  private deregisterFromCourse(course: Course, id_student: number) {
    this.courseService.deregisterFromCourse(course.id, id_student).subscribe({
      next: response => {
        console.debug('Deregister from Course BackendData:', response);
        if (response.success) {
          this.getStudentsCourses();

          this.alertService.showSuccessAlert(
            'Abgemeldet',
            `Erfolgreich vom Kurs '${course.title}' abgemeldet!`
          );
        } else {
          this.alertService.showErrorAlert(
            'Fehler',
            `Beim Abmelden vom Kurs '${course.title}' ist ein Fehler aufgetreten!`
          );
        }
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Beim Abmelden vom Kurs '${course.title}' ist ein Fehler aufgetreten!`
        );
      },
    });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
