import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PracticalWork } from 'src/app/models/practical-work.model';
import { PracticalWorkService } from 'src/app/services/practical-work.service';
import { Location } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-practical-work-details',
  templateUrl: './practical-work-details.component.html',
  styleUrls: ['./practical-work-details.component.scss'],
})
export class PracticalWorkDetailsComponent implements OnInit, OnDestroy {
  public practicalWork: PracticalWork;
  public isLoading: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private practicalWorkService: PracticalWorkService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (!params.id) {
        return;
      }

      const id = +atob(params.id);

      this.practicalWorkService
        .getPracticalWork(id, false)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async result => {
            console.debug('getPracticalWork result', result);

            if (!result.success) {
              console.error(result.message);
              this.isLoading = false;
              return;
            }
            this.practicalWork = result.data
              ? await this.practicalWorkService.parseBackendPracticalWork(
                  result.data
                )
              : null;
            this.isLoading = false;
          },
          error: error => {
            console.error(error.message);
            this.isLoading = false;
          },
        });
    });
  }

  /**
   * onEditPracticalWork
   */
  public onEditPracticalWork() {
    if (this.practicalWork) {
      this.router.navigate(['../edit', btoa(String(this.practicalWork.id))], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  /**
   * onGoBack
   */
  public onGoBack() {
    this.location.back();
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
