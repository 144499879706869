import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from '../models/user.model';
import { Exam, ExamsFilter, ExamType } from '../models/exams.model';
import { UserService } from './user.service';
import { environment } from 'src/configs/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ExamService {
  currentUsersExamsChanged = new Subject<Exam[]>();
  filteredExams = new Subject<Exam[]>();

  constructor(private http: HttpClient) {}

  examTypes: ExamType[] = [
    { id: 1, name: 'Referat' },
    { id: 2, name: 'Schrifliches Testat' },
    { id: 3, name: 'Hausarbeit' },
    { id: 4, name: 'Zwischenprüfung' },
    { id: 5, name: 'Abschlussprüfung' },
  ];

  exams: Exam[] = [];

  examStudents = [];

  /* get all exams with the amount of registered students */
  getAllExams() {
    for (let exam of this.exams) {
      this.exams[
        this.exams.findIndex(it => it.id == exam.id)
      ].registeredStudents = this.getAllExamStudentsByExamId(exam.id).length;
    }
    return this.exams;
  }

  /* get one exam by examid */
  getExamById(examId: number) {
    return this.exams.find(exam => exam.id === examId);
  }

  /* return all exams of a specific student */
  getAllExamsByStudentId(studentId: number) {
    let currentStudentsExams = this.getAllExams();

    for (let exam of currentStudentsExams) {
      const examStudent = this.examStudents.find(
        it => it.examId === exam.id && it.studentId === studentId
      );

      if (examStudent) {
        exam.grade = examStudent.grade;
        exam.registered = true;
      } else {
        exam.registered = false;
      }
    }

    return currentStudentsExams;
  }

  getAllExamGradesByStudentId(studentId: number) {
    return this.examStudents.filter(it => it.studentId === studentId);
  }

  /* return all students for a specific exam */
  getAllExamStudentsByExamId(examId: number) {
    const currentExamStudents = this.examStudents.filter(
      it => it.examId === examId
    );

    return currentExamStudents.map(student => ({
      id: student.studentId,
      name: {
        firstname: student.studentId.toString(),
        lastname: 'Student',
      },
    })) as User[];
  }

  /* returns all unique types from all exams */
  getAllExamTypes() {
    return [...new Set(this.exams.map(exam => exam.type))];
  }

  /* returns all unique lecturer from all exams */
  getAllExamLecturer() {
    return [...new Set(this.exams.map(exam => exam.lecturers).flat())];
  }

  /* returns all unique rooms from all exams */
  getAllExamRooms() {
    return [...new Set(this.exams.map(exam => exam.room))];
  }

  /* Registering a student to acourse */
  registerToExam(courseId: number, studentId: number) {
    this.examStudents.push({ examId: courseId, studentId: studentId });
    // update the observable of the current users courses
    this.currentUsersExamsChanged.next(this.getAllExamsByStudentId(studentId));
    console.log(this.getAllExamsByStudentId(studentId));
    // Update the list of courses so the amount of registered students is right
    this.exams = this.getAllExams();
  }

  /* Deregistering a student from a exam */
  deregisterFromExam(courseId: number, studentId: number) {
    const courseStudent = this.examStudents.find(
      it => it.examId === courseId && it.studentId === studentId
    );
    this.examStudents.splice(this.examStudents.indexOf(courseStudent), 1);
    // update the observable of the current users courses
    this.currentUsersExamsChanged.next(this.getAllExamsByStudentId(studentId));
    // Update the list of courses so the amount of registered students is right
    this.exams = this.getAllExams();
  }

  /* Update the filteredExams subject */
  updateFilteredExams(filterData: ExamsFilter | null, studentId?: number) {
    // save the courses of the current student so they only have to be fetched once
    let currentStudentExams: Exam[] = this.getAllExamsByStudentId(studentId);

    /* return all courses if no filters are applied */
    if (!filterData) {
      this.filteredExams.next(this.getAllExams());
      return;
    }

    // save filtered courses to subject filteredExams on method called
    this.filteredExams.next(this.filterExams(filterData, currentStudentExams));

    // update filteredExams when the courses of the current user changed
    this.currentUsersExamsChanged.subscribe(courses => {
      currentStudentExams = courses;
      this.filteredExams.next(this.filterExams(filterData, courses));
    });
  }

  /* Helper function to return all filtered courses*/
  filterExams(filterData: ExamsFilter, currentStudentExams: Exam[]) {
    const filterStartDate = new Date(filterData.startDate);
    const filterEndDate = new Date(filterData.endDate);
    return currentStudentExams.filter(
      exam =>
        (exam.lecturers.find(it => it.id === filterData.lecturerId) ||
          !filterData.lecturerId) &&
        (exam.room.toLocaleLowerCase() === filterData.room ||
          !filterData.room) &&
        (exam.type.id === filterData.type || !filterData.type) &&
        (exam.registered === filterData.registeredExams ||
          !filterData.registeredExams) &&
        /* apply date range filters only if the filters are selected */
        (((exam.startDate.getTime() >= filterStartDate.getTime() ||
          !filterData.startDate) &&
          (exam.startDate.getTime() <= filterEndDate.getTime() ||
            !filterData.endDate)) ||
          ((exam.endDate.getTime() >= filterStartDate.getTime() ||
            !filterData.startDate) &&
            (exam.endDate.getTime() <= filterEndDate.getTime() ||
              !filterData.endDate)))
    );
  }

  /* get all examTypes by instituteId */
  getAllExamTypesByInstitute(institude_id: number) {
    return this.examTypes;
  }

  /* get one examType by examTypeId */
  getExamTypeByID(examTypeId: number) {
    return this.examTypes.find(examType => examType.id === examTypeId);
  }

  getInstituteExamTypes(institude_id: number): Observable<any> {
    return this.http.get(
      environment.authority_short +
        `exam/getInstituteExamTypes.php?id_institute=${institude_id}`
    );
  }
  /* get one examType by examTypeId */
  getExamTypeById(id: number): Observable<any> {
    return this.http.get(
      environment.authority_short + `exam/getExamType.php?id=${id}`
    );
  }
}
