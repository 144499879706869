import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { CourseType } from 'src/app/models/course.model';
import {
  FilterDateRange,
  Filter,
  FilterType,
} from 'src/app/models/filter.model';
import { Room } from 'src/app/models/room.model';
import { User } from 'src/app/models/user.model';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';

@Component({
  selector: 'app-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss'],
})
export class FilterTagsComponent {
  @Input() filters: Filter[];
  @Output() filterChanged: EventEmitter<Filter[]> = new EventEmitter();

  @Input() eLogUserFilter?: boolean;

  public hasActiveFilterValue = hasActiveFilterValue;

  ngOnChanges() {
    console.debug('FilterTagsComponent: ngOnChanges filter', this.filters);
  }

  /**
   * validFilters
   * Returns all filters that have a value
   * @returns Filter[]
   */
  public get validFilters(): Filter[] {
    return this.filters.filter(filter => filter.value !== null);
  }

  /**
   * clearFilter
   * Emits the filterChanged event with the current filter values
   * @param filter: Filter
   * @returns void
   */
  public clearFilter(filter: Filter): void {
    filter.value = null;
    this.filterChanged.emit(this.filters);
  }

  /**
   * getFilterDisplayName
   * Returns the display name for a filter
   * @param filter: Filter
   * @returns string
   */
  public getFilterDisplayName(filter: Filter): string {
    const displayNames = {
      [FilterType.ROOM_NAME]: 'Raum',
      [FilterType.ROOM_FLOOR]: 'Etage',
      [FilterType.ROOM_CAPACITY]: 'Mindestanzahl an Plätzen',
      [FilterType.ROOM_ACTIVE]: 'Nur aktive Räume',
      [FilterType.LECTURER]: 'Lehrpersonal',
      [FilterType.COURSE_TYPE]: 'Kurstyp',
      [FilterType.DATE_RANGE]: 'Zeitraum',
      [FilterType.REGISTERED_COURSES]: 'Nur angemeldete Kurse',
      [FilterType.FINISHED_COURSES]: 'Nur abgeschlossene Kurse',
      [FilterType.OPEN_COURSES]: 'Nur offene Kurse',
      [FilterType.PENDING_ELOGS]: this.eLogUserFilter
        ? 'Nur Lehrpersonal mit ausstehenden Anwesehnheiten in Kursen'
        : 'Nur Kurse mit ausstehenden Anwesehnheiten',
      [FilterType.OPEN_ROOMPLANNING]: 'Nur offene Raumplanungen',
      [FilterType.LABEL]: 'Label',
    };

    if (filter.value === true) {
      return displayNames[filter.type] + ': Ja';
    } else if (filter.value === false) {
      return displayNames[filter.type] + ': Nein';
    } else if (this.isUser(filter.value)) {
      return (
        displayNames[filter.type] +
        ': ' +
        (filter.value.name.academicTitle
          ? filter.value.name.academicTitle + ' '
          : '') +
        filter.value.name.firstname +
        ' ' +
        filter.value.name.lastname
      );
    } else if (this.isCourseType(filter.value)) {
      return displayNames[filter.type] + ': ' + filter.value.name;
    } else if (this.isRoom(filter.value)) {
      return displayNames[filter.type] + ': ' + filter.value.name;
    } else if (this.isDateRange(filter.value)) {
      return (
        displayNames[filter.type] +
        ': ' +
        moment(filter.value.start).format('DD.MM.YYYY') +
        ' - ' +
        moment(filter.value.end).format('DD.MM.YYYY')
      );
    } else {
      return displayNames[filter.type] + ': ' + filter.value;
    }
  }

  /**
   * clearAllFilters
   * Clears all filters
   * @returns void
   */
  public clearAllFilters(): void {
    this.filters.forEach(filter => (filter.value = null));
    this.filterChanged.emit(this.filters);
  }

  private isCourseType(obj: any): obj is CourseType {
    return obj && typeof obj === 'object' && 'name' in obj;
  }

  private isUser(obj: any): obj is User {
    return obj && typeof obj === 'object' && 'email' in obj;
  }

  private isRoom(obj: any): obj is Room {
    return obj && typeof obj === 'object' && 'floor' in obj;
  }

  private isDateRange(obj: any): obj is FilterDateRange {
    return obj && typeof obj === 'object' && 'start' in obj && 'end' in obj;
  }
}
