import { Injectable } from '@angular/core';
import { Institute, InstituteUser } from '../models/institute.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/configs/environment';
import { Observable, takeUntil } from 'rxjs';
import { EducationCourse } from '../models/education-course.model';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class InstituteService {
  educationCourses: EducationCourse[] = [];
  public currentInstitute?: Institute;

  constructor(
    private http: HttpClient,
    private cancellationService: CancellationService
  ) {}

  /**
   * createInstitute
   * creates a new Institute
   * @param institute Institute
   * @returns Observable<any>
   */
  public createInstitute(institute: Institute): Observable<any> {
    console.debug('Institut: ', institute);

    const formData = new FormData();
    formData.append('name', institute.name);
    institute.description &&
      formData.append('description', institute.description);
    institute.logo && formData.append('logo', institute.logo);
    institute.color_primary &&
      formData.append('color_primary', institute.color_primary);
    institute.color_secondary &&
      formData.append('color_secondary', institute.color_secondary);
    institute.color_akzent1 &&
      formData.append('color_akzent1', institute.color_akzent1);
    institute.color_akzent2 &&
      formData.append('color_akzent2', institute.color_akzent2);

    return this.http.post(
      environment.authority_short + 'institute/createInstitute.php',
      formData
    );
  }

  /**
   * updateInstitute
   * updates an existing Institut
   * @param id_institute number
   * @param institut Institut
   * @returns Observable<any>
   */
  public updateInstitute(
    id_institute: number,
    institute: Institute
  ): Observable<any> {
    const formData = new FormData();
    formData.append('id_institute', id_institute.toString());
    formData.append('name', institute.name);
    institute.description &&
      formData.append('description', institute.description);
    institute.logo && formData.append('logo', institute.logo);
    institute.color_primary &&
      formData.append('color_primary', institute.color_primary);
    institute.color_secondary &&
      formData.append('color_secondary', institute.color_secondary);
    institute.color_akzent1 &&
      formData.append('color_akzent1', institute.color_akzent1);
    institute.color_akzent2 &&
      formData.append('color_akzent2', institute.color_akzent2);

    return this.http.post(
      environment.authority_short + 'institute/updateInstitute.php',
      formData
    );
  }

  /**
   * foundInstitute
   * founds an Institute
   * @param institute
   * @returns
   */
  public foundInstitute(institute: Institute): Observable<any> {
    const formData = new FormData();
    formData.append('id_institute', institute.id.toString());

    return this.http.post(
      environment.authority_short + 'institute/foundInstitute.php',
      formData
    );
  }

  /**
   * setCurrentInstitut
   * sets the current Institut
   * @param institute Institut
   * @returns void
   */
  public setCurrentInstitute(institute: Institute) {
    this.currentInstitute = institute;
  }

  /**
   * getInsistut
   * gets an Institut by id
   * @param id_institute number
   * @returns Observable<any>
   */
  public getInstitute(id_institute: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'institute/getInstitute.php?id_institute=' +
          id_institute
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getAllInsistutes
   * gets all Institutes
   * @returns Observable<any>
   */
  public getAllInstitutes(): Observable<any> {
    return this.http
      .get(environment.authority_short + 'institute/getAllInstitutes.php')
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getInstitutesByUser
   * gets all institutes of a user
   * @param id_institute number
   * @returns Observable<any>
   */
  public getInstitutesByUser(id_user: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'institute/getInstitutesByUser.php?id_user=' +
          id_user
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  public parseBackendInstitute(instituteData: any): Institute {
    return {
      id: instituteData.id,
      name: instituteData.name,
      description: instituteData.description,
      logo: instituteData.logo ? atob(instituteData.logo) : null,
      color_primary: instituteData.color_primary,
      color_secondary: instituteData.color_secondary,
      color_akzent1: instituteData.color_akzent1,
      color_akzent2: instituteData.color_akzent2,
      is_founded: instituteData.is_founded,
    };
  }
}
