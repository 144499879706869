import { Component } from '@angular/core';
import { NavLink } from 'src/app/models/nav-link.model';
import { Feature, Permission } from 'src/app/models/permission.model';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent {
  public navLinks: NavLink[] = [
    {
      label: 'Etikett',
      link: 'label',
      feature: Feature.LABEL,
      permission: Permission.VIEW,
    },
    {
      label: 'Analytics',
      link: 'analytics',
      feature: Feature.ANALYTICS,
      permission: Permission.VIEW,
    },
    {
      label: 'Abrechnung',
      link: 'billing',
      feature: Feature.BILLING,
      permission: Permission.VIEW,
    },
    {
      label: 'Rollenverwaltung',
      link: 'role-management',
      feature: Feature.ROLE_MANAGEMENT,
      permission: Permission.VIEW,
    },
    {
      label: 'Audit-Log',
      link: 'audit-log',
      feature: Feature.AUDIT_LOG,
      permission: Permission.VIEW,
    },
    {
      label: 'Gebührenübersicht',
      link: 'overview-of-fees',
      feature: Feature.OVERVIEW_OF_FEES,
      permission: Permission.VIEW,
    },
    {
      label: 'Ausbildungsfortschritt',
      link: 'education-progress',
      feature: Feature.EDUCATION_PROGRESS,
      permission: Permission.VIEW,
    },
  ];

  ngOnInit(): void {}
}
