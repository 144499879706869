import { Component, HostListener, Inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { BankDetails } from 'src/app/models/bank-details.model';
import { User } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { BankDetailsService } from 'src/app/services/bank-details.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { isRequired } from 'src/app/utils/form.utils';
import { ibanValidator } from 'src/app/validators/iban.validator';

@Component({
  selector: 'app-create-edit-bank-details',
  templateUrl: './create-edit-bank-details.component.html',
  styleUrls: ['./create-edit-bank-details.component.scss'],
})
export class CreateEditBankDetailsComponent {
  public editBankDetailsForm: FormGroup;
  public initialFormValues: {};
  public editMode = false;

  // import from form.utils.ts
  public isRequired = isRequired;

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.editBankDetailsForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    public dialogRef: MatDialogRef<CreateEditBankDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User; bankData: BankDetails },
    private dialog: MatDialog,
    private bankDetailsService: BankDetailsService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService
  ) {
    this.editMode = data.bankData?.iban ? true : false;

    // disable closing the dialog by clicking outside of it
    dialogRef.disableClose = true;
  }

  public ngOnInit() {
    this.editBankDetailsForm = new FormGroup({
      accountHolder: new FormControl(
        this.data.bankData.accountHolder,
        Validators.required
      ),
      iban: new FormControl(this.data.bankData.iban, [
        Validators.required,
        ibanValidator(),
      ]),
      bic: new FormControl(this.data.bankData.bic, Validators.required),
    });

    this.initialFormValues = this.editBankDetailsForm.value;
  }

  public async onSubmit() {
    if (this.editBankDetailsForm.valid) {
      const observable = await this.bankDetailsService.updateBankDetails(
        this.data.user.id,
        this.editBankDetailsForm.value.accountHolder,
        this.editBankDetailsForm.value.iban,
        this.editBankDetailsForm.value.bic
      );

      observable.subscribe({
        next: response => {
          if (response.success) {
            this.alertService.showSuccessAlert(
              'Bankverbindung geändert',
              'Bankverbindung erfolgreich geändert.'
            );
            this.dialogRef.close(true);
          } else {
            this.alertService.showErrorAlert(
              'Bankverbindung nicht geändert.',
              'Bankverbindung konnte nicht geändert werden.'
            );
          }
        },
        error: err => {
          console.error(err);
          this.alertService.showErrorAlert(
            'Bankverbindung nicht geändert.',
            'Bankverbindung konnte nicht geändert werden.'
          );
        },
      });
    }
  }

  /**
   * onCancel
   * Close the dialog, return false
   * @returns void
   */
  onCancel(): void {
    // check if the form has unsaved changes
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.editBankDetailsForm.value,
        this.initialFormValues
      )
    ) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
              Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          // Close the dialog, return false
          this.dialogRef.close(false);
        }
      });
    } else {
      // Close the dialog, return false
      this.dialogRef.close(false);
    }
  }
}
