<form
  class="component-content"
  [formGroup]="educationCourseForm"
  (ngSubmit)="onSubmit()">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        Aus- und Weiterbildungsgänge {{ editMode ? 'bearbeiten' : 'anlegen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{ editMode ? 'Bearbeiten' : 'Anlegen' }} des Aus- oder
        Weiterbildungsgangs
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <i class="fa-light fa-arrow-rotate-left hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">
          Speichern und {{ editMode ? 'bearbeiten' : 'hinzufügen' }}
        </span>
      </button>
    </div>
  </div>

  <!-- LOADER -->
  <div *ngIf="isLoading && editMode" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div class="main-content" *ngIf="!isLoading || !editMode">
    <!-- Ordnung -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Ausbildungsordnung oder Weiterbildungsordnung?
          <span *ngIf="isRequired(educationCourseForm.get('regulation'))">
            *
          </span>
        </span>
        <span class="label-description"> Welche Verordnung wünschen Sie? </span>
      </label>
      <!-- AUSWAHL -->
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <button
            *ngFor="
              let regulation of Regulation | keyvalue: originalRegulationOrder
            "
            type="button"
            class="hasomed-text-button button-full-width"
            [ngClass]="{
              'hasomed-button-primary':
                educationCourseForm.get('regulation').value ===
                regulation.value,
              'hasomed-button-white-grey':
                educationCourseForm.get('regulation').value != regulation.value
            }"
            (click)="setRegulation(regulation.value)">
            <span class="hasomed-button-text">{{ regulation.value }}</span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message [formField]="educationCourseForm.get('regulation')">
        </app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Titel -->
    <div class="formular-row">
      <label class="formular-row-label" for="title">
        <span class="label-title">
          Ausbildungstitel
          <span *ngIf="isRequired(educationCourseForm.get('title'))"> * </span>
        </span>
        <span class="label-description">
          Beschreibender kurzer Titel für die Ausbildung
        </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="title"
            class="hasomed-text-input"
            type="text"
            formControlName="title"
            placeholder="Titel" />
        </div>
        <!-- Error Messages -->
        <app-error-message [formField]="educationCourseForm.get('title')">
        </app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Fachkunde -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Fachkunde/Gebietsweiterbildung
          <span *ngIf="isRequired(educationCourseForm.get('expertise'))">
            *
          </span>
        </span>
        <span class="label-description">
          Wählen Sie die passende Fachkunde
        </span>
      </label>
      <!-- AUSWAHL -->
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <button
            *ngFor="
              let expertise of Expertise | keyvalue: originalExpertiseOrder
            "
            type="button"
            class="hasomed-text-button button-full-width"
            [ngClass]="{
              'hasomed-button-primary':
                educationCourseForm.get('expertise').value === expertise.value,
              'hasomed-button-white-grey':
                educationCourseForm.get('expertise').value != expertise.value
            }"
            (click)="setExpertise(expertise.value)">
            <span class="hasomed-button-text">{{ expertise.value }}</span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message [formField]="educationCourseForm.get('expertise')">
        </app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Richtlinienverfahren -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Richtlinienverfahren/Bereichsweiterbildung
          <span *ngIf="isRequired(educationCourseForm.get('policy_procedure'))">
            *
          </span>
        </span>
        <span class="label-description">
          Welches Richtlinenverfahren findet Anwendung?
        </span>
      </label>
      <!-- AUSWAHL -->
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <!-- BUTTONS -->
          <button
            *ngFor="
              let policyProcedure of PolicyProcedure
                | keyvalue: originalPolicyProcedureOrder
            "
            type="button"
            class="hasomed-text-button button-full-width"
            [ngClass]="{
              'hasomed-button-primary':
                educationCourseForm.get('policy_procedure').value ===
                policyProcedure.value,
              'hasomed-button-white-grey':
                educationCourseForm.get('policy_procedure').value !=
                policyProcedure.value
            }"
            (click)="setPolicyProcedure(policyProcedure.value)">
            <span class="hasomed-button-text">{{ policyProcedure.value }}</span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="educationCourseForm.get('policy_procedure')">
        </app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Berufsverband -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Akkreditierung durch Berufsverband
          <span
            *ngIf="
              isRequired(educationCourseForm.get('professional_association'))
            ">
            *
          </span>
        </span>
        <span class="label-description">
          Bei welchem Berufsverband sind Sie akkreditiert?
        </span>
      </label>
      <!-- AUSWAHL -->
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <!-- BUTTONS -->
          <button
            *ngFor="
              let paa of ProfessionalAssociation | keyvalue: originalPaaOrder
            "
            type="button"
            class="hasomed-text-button button-full-width"
            [ngClass]="{
              'hasomed-button-primary':
                educationCourseForm.get('professional_association').value ===
                paa.value,
              'hasomed-button-white-grey':
                educationCourseForm.get('professional_association').value !=
                paa.value
            }"
            (click)="setPAA(paa.value)">
            <span class="hasomed-button-text">{{ paa.value }}</span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="educationCourseForm.get('professional_association')">
        </app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Akkreditierungsjahr -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Akkreditierungsjahr
          <span
            *ngIf="isRequired(educationCourseForm.get('accreditation_year'))">
            *
          </span>
        </span>
        <span class="label-description"> Wann wurden Sie akkreditiert? </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="accreditation_year"
            class="hasomed-text-input"
            type="number"
            formControlName="accreditation_year"
            placeholder="Akkreditierungsjahr"
            min="1900" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="educationCourseForm.get('accreditation_year')">
        </app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kammer -->
    <div class="formular-row">
      <label class="formular-row-label" for="chamber">
        <span class="label-title">
          Zuständige Kammer
          <span *ngIf="isRequired(educationCourseForm.get('chamber'))">
            *
          </span>
        </span>
        <span class="label-description">
          Welche Kammer ist für Sie zuständig?
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="chamber"
            class="hasomed-text-input"
            type="text"
            formControlName="chamber"
            placeholder="Kammer" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="educationCourseForm.get('chamber')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Interne Notiz -->
    <div class="formular-row">
      <label class="formular-row-label" for="internal_notes">
        <span class="label-title">
          Interne Notizen zur Ausbildung
          <span *ngIf="isRequired(educationCourseForm.get('internal_notes'))">
            *
          </span>
        </span>
        <span class="label-description">
          Nur mit der Rolle Verwaltung sichtbar
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="internal_notes"
            class="hasomed-textarea-input"
            type="text"
            formControlName="internal_notes"
            placeholder="Interne Notiz">
          </textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="educationCourseForm.get('internal_notes')">
        </app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Externe Notiz -->
    <div class="formular-row">
      <label class="formular-row-label" for="external_notes">
        <span class="label-title">
          Externe Notizen zur Ausbildung
          <span *ngIf="isRequired(educationCourseForm.get('external_notes'))">
            *
          </span>
        </span>
        <span class="label-description">
          Werden Auszubildenden öffentlich angezeigt
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="external_notes"
            class="hasomed-textarea-input"
            type="text"
            formControlName="external_notes"
            placeholder="Externe Notiz">
          </textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="educationCourseForm.get('external_notes')">
        </app-error-message>
      </div>
    </div>

    <!-- SUB HEADER -->
    <div class="flex-c sub-header">
      <h1 class="hasomed-text-h1 m-z">Kontingente für Ausbildung</h1>
      <h3 class="hasomed-text-h3 m-z">Festlegung der einzelnen Kontingente</h3>
    </div>

    <!-- Kontingent Theoretisch-Wissenschaftlich -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Theoretisch-Wissenschaftlich
          <span
            *ngIf="
              isRequired(educationCourseForm.get('c_theoretical_scientific_h'))
            ">
            *
          </span>
        </span>
        <span class="label-description"> Kontingent für die Ausbildung </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="flex-c">
            <input
              id="c_theoretical_scientific_h"
              class="hasomed-text-input"
              type="number"
              formControlName="c_theoretical_scientific_h"
              placeholder="min. Stunden"
              min="1" />
            <app-error-message
              [formField]="
                educationCourseForm.get('c_theoretical_scientific_h')
              ">
            </app-error-message>
          </div>

          <!-- min. Doppelstunden autogenes Training u.ä. -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="c_theoretical_scientific_auto_training_h">
              <span class="label-title">
                min. Doppelstunden autogenes Training u.ä
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get(
                        'c_theoretical_scientific_auto_training_h'
                      )
                    )
                  ">
                  *
                </span>
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="c_theoretical_scientific_auto_training_h"
                class="hasomed-text-input"
                type="number"
                formControlName="c_theoretical_scientific_auto_training_h"
                placeholder="min. Doppelstunden"
                min="0" />
            </div>
          </div>

          <!-- min. Doppelstunden Balintgruppenarbeit u.ä. -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="c_theoretical_scientific_balintgroupwork_h">
              <span class="label-title">
                min. Doppelstunden Balintgruppenarbeit u.ä.
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get(
                        'c_theoretical_scientific_balintgroupwork_h'
                      )
                    )
                  ">
                  *
                </span>
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="c_theoretical_scientific_balintgroupwork_h"
                class="hasomed-text-input"
                type="number"
                formControlName="c_theoretical_scientific_balintgroupwork_h"
                placeholder="min. Doppelstunden"
                min="0" />
            </div>
          </div>
        </div>
        <app-error-message
          [formField]="
            educationCourseForm.get('c_theoretical_scientific_auto_training_h')
          ">
        </app-error-message>

        <app-error-message
          [formField]="
            educationCourseForm.get(
              'c_theoretical_scientific_balintgroupwork_h'
            )
          ">
        </app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Praktisch-1 -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Praktische Tätigkeit 1
          <span
            *ngIf="
              isRequired(educationCourseForm.get('contingent_practical_1'))
            ">
            *
          </span>
        </span>
        <span class="label-description">
          nach §2 Abs. 2 Nr. 1 PsychTh-APrV
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="flex-c">
            <input
              id="c_practical_1_h"
              class="hasomed-text-input"
              type="number"
              formControlName="c_practical_1_h"
              placeholder="min. Stunden"
              min="1" />
            <!-- Error Messages -->
            <app-error-message
              [formField]="educationCourseForm.get('c_practical_1_h')">
            </app-error-message>
          </div>

          <div class="flex-c">
            <div class="formular-inner-row">
              <label
                class="formular-row-inner-label"
                for="c_practical_1_medical_history_survey_min">
                <span class="label-title">
                  min. Anamneseerhebungen
                  <span
                    *ngIf="
                      isRequired(
                        educationCourseForm.get(
                          'c_practical_1_medical_history_survey_min'
                        )
                      )
                    ">
                    *
                  </span>
                </span>
              </label>
              <div class="formular-row-input">
                <input
                  id="c_practical_1_medical_history_survey_min"
                  class="hasomed-text-input"
                  type="number"
                  formControlName="c_practical_1_medical_history_survey_min"
                  placeholder="min. Anamneseerhebungen"
                  min="0" />
              </div>
            </div>
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                educationCourseForm.get(
                  'c_practical_1_medical_history_survey_min'
                )
              ">
            </app-error-message>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Praktisch-2 -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Praktische Tätigkeit 2
          <span *ngIf="isRequired(educationCourseForm.get('c_practical_2_h'))">
            *
          </span>
        </span>
        <span class="label-description">
          nach §2 Abs. 2 Nr. 2 PsychTh-APrV
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="c_practical_2_h"
            class="hasomed-text-input"
            type="number"
            formControlName="c_practical_2_h"
            placeholder="min. Stunden"
            min="1" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="educationCourseForm.get('c_practical_2_h')">
        </app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Behandlungspraktikum -->
    <div class="formular-row">
      <label class="formular-row-label" for="c_treatment_internship_h">
        <span class="label-title">
          Behandlungspraktikum
          <span
            *ngIf="
              isRequired(educationCourseForm.get('c_treatment_internship_h'))
            ">
            *
          </span>
        </span>
        <span class="label-description"> Kontingent für die Ausbildung </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="formular-row-input">
            <input
              id="c_treatment_internship_h"
              class="hasomed-text-input"
              type="number"
              formControlName="c_treatment_internship_h"
              placeholder="min. Stunden"
              min="1" />
          </div>

          <!-- Abgeschlossene Behandlungen -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="c_completed_treatment_cases">
              <span class="label-title">
                Abgeschlossene Behandlungen
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get('c_completed_treatment_cases')
                    )
                  ">
                  *
                </span>
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="c_completed_treatment_cases"
                class="hasomed-text-input"
                type="number"
                formControlName="c_completed_treatment_cases"
                placeholder="Abgeschlossene Behandlungen"
                min="1" />
            </div>
          </div>

          <!-- Fälle -->
          <div class="formular-inner-row">
            <label class="formular-row-inner-label" for="c_treatment_cases">
              <span class="label-title">
                Fälle (Gesamt)
                <span
                  *ngIf="
                    isRequired(educationCourseForm.get('c_treatment_cases'))
                  ">
                  *
                </span>
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="c_treatment_cases"
                class="hasomed-text-input"
                type="number"
                formControlName="c_treatment_cases"
                placeholder="Fälle"
                min="1" />
            </div>
          </div>

          <!-- min. Fälle -->
          <div class="formular-inner-row">
            <label class="formular-row-inner-label" for="c_treatment_cases_min">
              <span class="label-title">
                davon mindestens
                {{ educationCourseForm.value.c_treatment_cases_min }} Fälle
                <span
                  *ngIf="
                    isRequired(educationCourseForm.get('c_treatment_cases_min'))
                  ">
                  *
                </span>
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="c_treatment_cases_min"
                class="hasomed-text-input"
                type="number"
                formControlName="c_treatment_cases_min"
                placeholder="min. Fälle"
                min="1"
                [max]="educationCourseForm.value.c_treatment_cases" />
            </div>
          </div>

          <!-- mit min. Stunden -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="c_treatment_cases_min_h">
              <span class="label-title">
                mit mindestens
                {{ educationCourseForm.value.c_treatment_cases_min_h }}
                Stunden
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get('c_treatment_cases_min_h')
                    )
                  ">
                  *
                </span>
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="c_treatment_cases_min_h"
                class="hasomed-text-input"
                type="number"
                formControlName="c_treatment_cases_min_h"
                placeholder="min. Stunden"
                min="1"
                [max]="educationCourseForm.value.c_treatment_internship_h" />
            </div>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="educationCourseForm.get('c_treatment_internship_h')">
        </app-error-message>
        <app-error-message
          [formField]="educationCourseForm.get('c_completed_treatment_cases')">
        </app-error-message>
        <app-error-message
          [formField]="educationCourseForm.get('c_treatment_cases')">
        </app-error-message>
        <app-error-message
          [formField]="educationCourseForm.get('c_treatment_cases_min')">
        </app-error-message>
        <app-error-message
          [formField]="educationCourseForm.get('c_treatment_cases_min_h')">
        </app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Supervision -->
    <div class="formular-row">
      <label class="formular-row-label" for="c_supervision_h">
        <span class="label-title">
          Supervision
          <span *ngIf="isRequired(educationCourseForm.get('c_supervision_h'))">
            *
          </span>
        </span>
        <span class="label-description"> Kontingent für die Ausbildung </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="formular-row-input">
            <input
              id="c_supervision_h"
              class="hasomed-text-input"
              type="number"
              formControlName="c_supervision_h"
              placeholder="min. Stunden"
              min="1" />
          </div>

          <!-- min. Einzelsitzung -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="c_supervision_single_session_h">
              <span class="label-title">
                davon mind.
                {{ educationCourseForm.value.c_supervision_single_session_h }}
                Stunden in Einzelsitzung
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get('c_supervision_single_session_h')
                    )
                  ">
                  *
                </span>
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="c_supervision_single_session_h"
                class="hasomed-text-input"
                type="number"
                formControlName="c_supervision_single_session_h"
                placeholder="min. Einzelsitzung"
                min="1"
                [max]="educationCourseForm.value.c_supervision_h" />
            </div>
          </div>

          <!-- min. Gruppensitzung -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="c_supervision_group_session_h">
              <span class="label-title">
                Rest in Gruppe mit max. 4 TN
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get('c_supervision_group_session_h')
                    )
                  ">
                  *
                </span>
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="c_supervision_group_session_h"
                class="hasomed-text-input"
                type="number"
                formControlName="c_supervision_group_session_h"
                placeholder="min. Gruppensitzung"
                [max]="educationCourseForm.value.c_supervision_h" />
            </div>
          </div>

          <!-- min. Anamneseerhebungen -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="c_supervision_medical_history_survey_min">
              <span class="label-title">
                min. Anamneseerhebungen
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get(
                        'c_supervision_medical_history_survey_min'
                      )
                    )
                  ">
                  *
                </span>
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="c_supervision_medical_history_survey_min"
                class="hasomed-text-input"
                type="number"
                formControlName="c_supervision_medical_history_survey_min"
                placeholder="min. Anamneseerhebungen"
                min="0" />
            </div>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="educationCourseForm.get('contingent_sv')">
        </app-error-message>
        <app-error-message
          [formField]="educationCourseForm.get('contingent_sv_single_session')">
        </app-error-message>
        <app-error-message
          [formField]="educationCourseForm.get('contingent_sv_group_session')">
        </app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Supervision -->
    <div class="formular-row">
      <label class="formular-row-label" for="c_self_experience_h">
        <span class="label-title">
          Lehrtherapie, Lehranalyse oder Selbsterfahrung
          <span
            *ngIf="isRequired(educationCourseForm.get('c_self_experience_h'))">
            *
          </span>
        </span>
        <span class="label-description"> Kontingent für die Ausbildung </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="flex-c">
            <input
              id="c_self_experience_h"
              class="hasomed-text-input"
              type="number"
              formControlName="c_self_experience_h"
              placeholder="min. Stunden"
              min="1" />
            <!-- Error Messages -->
            <app-error-message
              [formField]="educationCourseForm.get('c_self_experience_h')">
            </app-error-message>
          </div>
          <div class="flex-c">
            <div class="formular-inner-row">
              <label
                class="formular-row-inner-label"
                for="c_self_experience_sessions_min">
                <span class="label-title">
                  min. Sitzungen
                  <span
                    *ngIf="
                      isRequired(
                        educationCourseForm.get(
                          'c_self_experience_sessions_min'
                        )
                      )
                    ">
                    *
                  </span>
                </span>
              </label>
              <div class="formular-row-input">
                <input
                  id="c_self_experience_sessions_min"
                  class="hasomed-text-input"
                  type="number"
                  formControlName="c_self_experience_sessions_min"
                  placeholder="min. Sitzungen"
                  min="0" />
              </div>
            </div>
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                educationCourseForm.get('c_self_experience_sessions_min')
              ">
            </app-error-message>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
