import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { VNode, createElement } from '@fullcalendar/core/preact';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import * as moment from 'moment';
import { CalendarEventColors } from 'src/app/models/calendar-event.model';
import { EventType } from 'src/app/models/event.model';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/services/event.service';
import { RoomService } from 'src/app/services/room.service';
import { UserService } from 'src/app/services/user.service';
import { CalendarEventDetailComponent } from '../calendar/calendar-event-detail/calendar-event-detail.component';
import { CancellationService } from 'src/app/services/cancellation.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-room-utilization-calendar',
  templateUrl: './room-utilization-calendar.component.html',
  styleUrl: './room-utilization-calendar.component.scss',
})
export class RoomUtilizationCalendarComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  public calendarOptions: CalendarOptions;
  public calendar: Calendar;
  public isTodayInView: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('fullCalendar') calendarComponent: FullCalendarComponent;

  constructor(
    private roomService: RoomService,
    private userService: UserService,
    private eventService: EventService,
    private dialog: MatDialog,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.initCalendarOptions();
    this.initResources();
    this.initEvents();
  }

  ngAfterViewInit() {
    console.debug('ngAfterViewInit');
    this.calendar = this.calendarComponent.getApi();
    this.checkIsTodayInView();
  }

  ngAfterViewChecked() {
    this.updateTitle();
  }

  private initCalendarOptions() {
    this.calendarOptions = {
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      plugins: [resourceTimeGridPlugin],
      locale: 'de',
      initialView: 'resourceTimeGridDay',
      allDaySlot: false,
      nowIndicator: true,
      slotMinTime: '05:00:00',
      slotMaxTime: '23:59:59',
      slotDuration: '1:00',
      // remove header toolbar
      headerToolbar: {
        left: '',
        center: '',
        right: '',
      },
      // start 1.5 hours before now
      scrollTime: moment()
        .minute(0)
        .second(0)
        .subtract(1.5, 'h')
        .format('HH:mm:ss'),
      nowIndicatorContent: args => {
        // create the nor indicator line
        if (args.isAxis) {
          return createElement(
            'span',
            { class: 'hasomed-text-small font-white' },
            moment(args.date).format('HH:mm')
          );
        } else {
          return null;
        }
      },
      slotLabelContent: args => {
        // create the slot label (time to the left) div to display in calendar
        const slotLabelText = args.text;
        let slotLabelDiv = createElement(
          'div',
          { class: 'fc-custom-slot-label' },
          slotLabelText !== '05:00' ? slotLabelText : ''
        );

        return slotLabelDiv;
      },
      eventContent: args => {
        // create the event div to display in calendar
        const event = args.event;
        const time = args.timeText;
        const eventWrapperDiv = this.createEventDiv(event, time);
        return eventWrapperDiv;
      },
      eventDidMount: info => {
        const title = info.event.title;
        const room = info.event.extendedProps['room']
          ? info.event.extendedProps['room'].name
          : 'Kein Raum';
        const date =
          moment(info.event.start).format('DD.MM.YYYY') +
          ', ' +
          moment(info.event.start).format('HH:mm') +
          ' - ' +
          moment(info.event.end).format('HH:mm') +
          ' Uhr';
        const tooltipText = title + ' - ' + room + ' - ' + date;

        info.el.title = tooltipText;
      },
      eventClick: info => {
        // open event detail dialog
        const dialogRef = this.dialog.open(CalendarEventDetailComponent, {
          data: {
            id_eventDate: info.event.extendedProps['id_eventDate'],
            id_treatmentCase: info.event.extendedProps['id_treatmentCase'],
            id_course: info.event.extendedProps['id_course'],
            title: info.event.title,
            lecturer: info.event.extendedProps['lecturer'] || null,
            supervisor: info.event.extendedProps['supervisor'] || null,
            type: info.event.extendedProps['eventType'],
            room: info.event.extendedProps['room'],
            startDate: info.event.start,
            endDate: info.event.end,
            canceled: info.event.extendedProps['canceled'],
            treatmentCaseStudent:
              info.event.extendedProps['treatmentCaseStudent'],
          },
          width: '500px',
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            if (result?.canceled) {
              info.event.setExtendedProp('canceled', true);

              // set colors for canceled event
              info.event.setProp(
                'backgroundColor',
                CalendarEventColors.canceled.backgroundColor
              );
              info.event.setProp(
                'borderColor',
                CalendarEventColors.canceled.borderColor
              );
              info.event.setProp(
                'textColor',
                CalendarEventColors.canceled.textColor
              );
            }
          });
      },
    };
  }

  /**
   * initResources
   * Fetches all rooms from the backend and initializes the resources of the calendar
   * @returns void
   */
  private initResources(): void {
    const id_institute = this.userService.currentUser.id_institute;
    this.roomService
      .getAllRooms(id_institute)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('Rooms fetched: ', response);
          if (!response.success) {
            console.error('Error while fetching rooms: ', response.message);
          }

          const resources = response.data
            ? await Promise.all(
                response.data.map(async (roomData: any): Promise<any> => {
                  const room =
                    await this.roomService.parseBackendRoom(roomData);
                  return {
                    id: room.id,
                    title: room.name,
                  };
                })
              )
            : [];
          this.calendar.setOption('resources', resources);
        },
      });
  }

  private initEvents(): void {
    this.eventService
      .getAllInstituteEventDates()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('Events fetched: ', response);
          if (!response.success) {
            console.error('Error while fetching events: ', response.message);
          }

          const events = response.data
            ? await Promise.all(
                response.data.map(async (event: any): Promise<any> => {
                  // create lecturer string
                  let lecturers = '';
                  if (event.lecturers && event.lecturers.length > 0) {
                    lecturers = '';

                    const decryptedLecturers = event.lecturers
                      ? await Promise.all(
                          event.lecturers.map(async (lecturer: User) => {
                            return this.userService.parseBackendUser(lecturer);
                          })
                        )
                      : [];

                    decryptedLecturers.forEach(async (lecturer: User) => {
                      lecturers +=
                        (lecturer.name.academicTitle
                          ? lecturer.name.academicTitle + ' '
                          : '') +
                        (lecturer.name.firstname ?? '') +
                        ' ' +
                        (lecturer.name.lastname ?? '') +
                        // add comma, if not last lecturer
                        (decryptedLecturers.indexOf(lecturer) <
                        decryptedLecturers.length - 1
                          ? ', '
                          : '');
                    });
                  }

                  // create supervisor string
                  const decryptedSupervisor = event.supervisor
                    ? await this.userService.parseBackendUser(event.supervisor)
                    : null;
                  const supervisorName = decryptedSupervisor
                    ? (decryptedSupervisor.name.academicTitle
                        ? decryptedSupervisor.name.academicTitle + ' '
                        : '') +
                      (decryptedSupervisor.name.firstname ?? '') +
                      ' ' +
                      (decryptedSupervisor.name.lastname ?? '')
                    : 'Kein Supervisor';

                  // create treatmentCaseStudent string
                  const decryptedtreatmenCaseStudent =
                    event.treatment_case_student
                      ? await this.userService.parseBackendUser(
                          event.treatment_case_student
                        )
                      : null;
                  const treatmentCaseStudent = decryptedtreatmenCaseStudent
                    ? (decryptedtreatmenCaseStudent.name.academicTitle
                        ? decryptedtreatmenCaseStudent.name.academicTitle + ' '
                        : '') +
                      (decryptedtreatmenCaseStudent.name.firstname ?? '') +
                      ' ' +
                      (decryptedtreatmenCaseStudent.name.lastname ?? '')
                    : '-';

                  return {
                    id: event.id,
                    resourceId: event.room?.id,
                    start: event.startDate,
                    end: event.endDate,
                    title: this.getEventTitle(event),
                    backgroundColor: this.getColor(event).backgroundColor,
                    borderColor: this.getColor(event).borderColor,
                    textColor: this.getColor(event).textColor,
                    extendedProps: {
                      id_eventDate: event.id,
                      id_treatmentCase: event.id_treatment_case ?? null,
                      id_course: event.id_course ?? null,
                      eventType: event.type,
                      room: event.room
                        ? await this.roomService.parseBackendRoom(event.room)
                        : null,
                      canceled: event.canceled ?? null,
                      supervisor: event.supervisor ? supervisorName : null,
                      lecturer: lecturers ?? null,
                      treatmentCaseStudent: event.treatment_case_student
                        ? treatmentCaseStudent
                        : null,
                    },
                  };
                })
              )
            : [];
          this.calendar.setOption('events', events);
        },
      });
  }

  /**
   * createEventDiv
   * creates the event div
   * @param event
   * @param time
   * @returns VNode<any>
   */
  private createEventDiv(event: EventImpl, time: string): VNode<any> {
    const type = event.extendedProps['eventType'];
    const room = event.extendedProps['room'];
    const canceled = event.extendedProps['canceled'];
    const studentName = event.extendedProps['treatmentCaseStudent'];

    const title =
      event.title + (type === EventType.COURSE ? '' : ' - ' + studentName);

    // get view
    const view = this.calendar.view.type;
    if (view === 'dayGridMonth') {
      return createElement(
        'div',
        {
          class: 'fc-custom-event-month',
          id: 'event',
          style:
            'background-color: ' +
            event.backgroundColor +
            ';' +
            'border-color: ' +
            event.borderColor +
            ';' +
            'color: ' +
            event.textColor +
            ';',
        },
        [title]
      );
    }

    let titleDiv = createElement(
      'div',
      { class: 'fc-custom-event-title' },
      title
    );

    const roomDiv = createElement(
      'div',
      { class: 'fc-custom-event-room' },
      room
        ? 'Raum: ' +
            room.name +
            ' ' +
            (room.floor > 0 ? room.floor + '.OG' : 'EG')
        : ' Raum: Kein Raum'
    );

    let titleRoomDiv = createElement(
      'div',
      { class: 'fc-custom-event-title-room' },
      [titleDiv, roomDiv]
    );

    if (canceled) {
      const canceledDiv = createElement(
        'div',
        {
          class: 'label label-red',
          style: 'width: min-content; margin: 4px 0px',
        },
        createElement(
          'span',
          { class: 'hasomed-text-small label-text' },
          'Abgesagt'
        )
      );

      titleRoomDiv = createElement(
        'div',
        { class: 'fc-custom-event-title-room' },
        [titleDiv, canceledDiv, roomDiv]
      );
    }

    const timeDiv = createElement('div', { class: 'fc-custom-event-time' }, [
      time,
    ]);

    let eventWrapperDiv = createElement(
      'div',
      { class: 'fc-custom-event', id: 'event' },
      [titleRoomDiv, timeDiv]
    );

    return eventWrapperDiv;
  }

  /**
   * getEventTitle
   * returns the title of the event based on the event type
   * @param event
   * @returns string
   */
  private getEventTitle(event: any): string {
    switch (event.type) {
      case EventType.COURSE:
        return event.course_title;
      case EventType.PATIENT_SESSION:
        return 'Patiententermin';
      case EventType.SUPERVISION:
        return 'Supervision';
      case EventType.GROUP_SUPERVISION:
        return 'Gruppensupervision';
      default:
        return '';
    }
  }

  /**
   * getColor
   * returns the color of the event based on the event type
   * @param event
   * @returns { backgroundColor: string, borderColor: string, textColor: string}
   */
  private getColor(event: any): {
    backgroundColor: string;
    borderColor: string;
    textColor: string;
  } {
    if (event.canceled) {
      return CalendarEventColors.canceled;
    }

    switch (event.type) {
      case EventType.COURSE:
        return CalendarEventColors.course;
      case EventType.PATIENT_SESSION:
        return CalendarEventColors.patient;
      case EventType.SUPERVISION:
        return CalendarEventColors.patient;
      case EventType.GROUP_SUPERVISION:
        return CalendarEventColors.patient;
      default:
        return CalendarEventColors.course;
    }
  }

  /**
   * checkIsTodayInView
   * Update isTodayInView to true, when todays date is in current view, else false
   * @returns void
   */
  private checkIsTodayInView(): void {
    const calendarApiView = this.calendar.view;
    const viewStart = calendarApiView.activeStart;
    const viewEnd = calendarApiView.activeEnd;
    const today = new Date();

    if (today >= viewStart && today <= viewEnd) {
      this.isTodayInView = true;
    } else {
      this.isTodayInView = false;
    }
  }

  /**
   * onClickNext
   * trigger calenderApi.next() when clicking on next button, update isTodayInView and title
   * @returns void
   */
  public onClickNext(): void {
    this.calendar.next();
    this.checkIsTodayInView();
    this.updateTitle();
  }

  /**
   * onClickPrev
   * trigger calenderApi.prev() when clicking on prev button, update isTodayInView and title
   * @returns void
   */
  public onClickPrev(): void {
    this.calendar.prev();
    this.checkIsTodayInView();
    this.updateTitle();
  }

  /**
   * onClickToday
   * trigger calenderApi.today() when clicking on today button,  update isTodayInView and title
   * @returns void
   */
  public onClickToday() {
    this.calendar.today();
    this.isTodayInView = true;
    this.updateTitle();
  }

  /**
   * updateTitle
   * updates the title (date-range) of current view
   * @returns void
   */
  private updateTitle(): void {
    const calendarApiView = this.calendar.view;
    const titleSpan = document.getElementById('fcCustomTitle');
    titleSpan.innerHTML = calendarApiView.title;
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
