<mat-sidenav-container
  class="sidenav-container"
  [ngClass]="{ 'container-closed': sidenavService.collapsed }">
  <mat-sidenav #sidenav class="sidenav" fixedInViewport mode="side" opened>
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <mat-toolbar class="topnav-toolbar">
      <div class="horizontal-spacer"></div>
      <div
        [class.bg-white]="instituteMenuOpen"
        [class.institute-menu-hover]="
          filterCurrentInstitute() && filterCurrentInstitute()?.length > 0
        "
        class="institute-menu"
        id="instituteMenu">
        <div class="institute gap-8" (click)="onOpenInstituteMenu()">
          <div class="flex-r align-center justify-center institute-icon">
            <i class="hasomed-button-icon-sm fa-light fa-school"></i>
          </div>

          <div class="flex-r justify-space-between flex-1">
            <span class="hasomed-text-body institute-name">
              {{ instituteService.currentInstitute?.name }}
            </span>
            <div
              class="institute-menu-trigger"
              *ngIf="
                filterCurrentInstitute() && filterCurrentInstitute()?.length > 0
              ">
              <i
                [class.rotated]="instituteMenuOpen"
                class="fa-light fa-chevron-down institute-menu-trigger-icon"></i>
            </div>
          </div>
        </div>
        <div class="institute-menu-content" *ngIf="instituteMenuOpen">
          <button
            *ngFor="let institute of filterCurrentInstitute()"
            class="institute-menu-button"
            (click)="onSwitchInstitute(institute)">
            <div class="hasomed-button-icon-sm institute-icon">
              <i class="fa-light fa-school"></i>
            </div>
            <div class="hasomed-button-text" [matTooltip]="institute.name">
              {{ institute.name }}
            </div>
          </button>
        </div>
      </div>
      <div
        [ngClass]="{ 'bg-white': userMenuOpen }"
        class="user-menu"
        id="userMenu">
        <div class="user" (click)="userMenuOpen = !userMenuOpen">
          <div class="flex-r align-center">
            <div class="user-icon">
              <img
                class="user-icon-image"
                #userIconImage
                [attr.src]="
                  userService.currentUser.profilePicture ??
                  '../../../../../assets/img/user.jpg'
                " />
            </div>
            <div class="user-name hasomed-text-body">
              <span>
                {{ this.userService.currentUser.name.firstname }}
                {{ this.userService.currentUser.name.lastname }}
              </span>
            </div>
          </div>
          <div class="user-menu-trigger">
            <i
              [class.rotated]="userMenuOpen"
              class="fa-light fa-chevron-down user-menu-trigger-icon"></i>
          </div>
        </div>
        <div class="user-menu-content" *ngIf="userMenuOpen">
          <!-- For Stage only -->
          <div class="role-buttons" *ngIf="showRoleSwitch">
            <!-- Administration -->
            <button
              type="button"
              class="user-menu-button"
              [ngClass]="{
                active: userService.currentUser.id_role === role.ADMINISTRATOR
              }"
              (click)="setRole(role.ADMINISTRATOR)">
              <div class="hasomed-button-icon">
                <i class="fa-light fa-toolbox"></i>
              </div>
              <div class="hasomed-button-text">
                <span>Verwaltung</span>
              </div>
            </button>

            <!-- Lecturer -->
            <button
              type="button"
              class="user-menu-button"
              [ngClass]="{
                active: userService.currentUser.id_role === role.LECTURER
              }"
              (click)="setRole(role.LECTURER)">
              <div class="hasomed-button-icon">
                <i class="fa-light fa-toolbox"></i>
              </div>
              <div class="hasomed-button-text">
                <span>Lehrpersonal</span>
              </div>
            </button>

            <!-- Student -->
            <button
              type="button"
              class="user-menu-button"
              [ngClass]="{
                active: userService.currentUser.id_role === role.STUDENT
              }"
              (click)="setRole(role.STUDENT)">
              <div class="hasomed-button-icon">
                <i class="fa-light fa-toolbox"></i>
              </div>
              <div class="hasomed-button-text">
                <span>Kandidat*in</span>
              </div>
            </button>
          </div>

          <mat-divider *ngIf="showRoleSwitch"></mat-divider>

          <button class="user-menu-button" (click)="onOpenSettings()">
            <div class="hasomed-button-icon">
              <i class="fa-light fa-gear"></i>
            </div>
            <div class="hasomed-button-text">
              <span>Einstellungen</span>
            </div>
          </button>

          <button class="user-menu-button" (click)="onOpenInformation()">
            <div class="hasomed-button-icon">
              <i class="fa-light fa-circle-info"></i>
            </div>
            <div class="hasomed-button-text">
              <span>Informationen</span>
            </div>
          </button>

          <button class="user-menu-button logout-button" (click)="logout()">
            <div class="hasomed-button-icon">
              <i class="fa-light fa-right-from-bracket"></i>
            </div>
            <div class="hasomed-button-text">
              <span>Log out</span>
            </div>
          </button>
        </div>
      </div>
    </mat-toolbar>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
