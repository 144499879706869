<div class="table">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- Practial Work title column -->
    <ng-container matColumnDef="title">
      <th class="gray-600" mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Einrichtung</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="title">
        </app-custom-table-sort-icon>
      </th>
      <td
        class="institution-wrapper"
        mat-cell
        *matCellDef="let row"
        (click)="viewPracticalWork(row)">
        <div
          class="flex-c align-start"
          matTooltip="Praktische Tätigkeit ansehen">
          <span class="gray-900 hasomed-text-body">Klinik</span>
          <span class="gray-600 hasomed-text-small lh-16">{{ row.title }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Practial Work responsible Person Column -->
    <ng-container matColumnDef="responsiblePerson">
      <th class="gray-600" mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class="gray-600">Verantwortliche*r</span>
        <app-custom-table-sort-icon
          [sort]="sort"
          columnName="responsiblePerson">
        </app-custom-table-sort-icon>
      </th>
      <td class="gray-600" mat-cell *matCellDef="let row">
        <span>
          {{ row.responsiblePerson }}
        </span>
      </td>
    </ng-container>

    <!-- Practial Work Date Column -->
    <ng-container matColumnDef="date">
      <th class="gray-600" mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Zeitraum</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="date">
        </app-custom-table-sort-icon>
      </th>
      <td class="gray-600" mat-cell *matCellDef="let row">
        <span> {{ row.startDate | date: 'dd.MM.yyyy' }} </span>
        <span *ngIf="row.startDate != row.endDate">
          - {{ row.endDate | date: 'dd.MM.yyyy' }}
        </span>
      </td>
    </ng-container>

    <!-- Practial Work duration column -->
    <ng-container matColumnDef="duration">
      <th class="gray-600" mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Dauer</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="duration">
        </app-custom-table-sort-icon>
      </th>
      <td class="gray-600" mat-cell *matCellDef="let row">
        <span class="">{{ row.duration | floatToHoursMinutes }} </span>
      </td>
    </ng-container>

    <!-- Practial Work confirmation column -->
    <ng-container matColumnDef="confirmation">
      <th class="gray-600" mat-header-cell *matHeaderCellDef mat-sort-header="">
        <span>Bestätigung</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="confirmation">
        </app-custom-table-sort-icon>
      </th>
      <td class="gray-600" mat-cell *matCellDef="let row">
        <div *ngIf="row.isConfirmedFile" class="flex-r align-center">
          <button
            type="button"
            class="table-button-icon"
            (click)="onDownload(row.isConfirmedFile)"
            matTooltip="Datei herunterladen">
            <i class="fa-light fa-file-contract icon-sm"></i>
          </button>
          <span>Dokument</span>
        </div>
        <div *ngIf="!row.isConfirmedFile" class="flex-r gap-8 align-center">
          <i class="fa-light fa-file-slash icon-sm"></i>
          <span class="gray-600">Keine Bestätigung</span>
        </div>
      </td>
    </ng-container>

    <!-- Practial Work buttons column -->
    <ng-container matColumnDef="actions">
      <th class="gray-600" mat-header-cell *matHeaderCellDef></th>
      <td class="gray-600" mat-cell *matCellDef="let row">
        <div class="table-button-icons">
          <button
            type="button"
            class="table-button-icon"
            (click)="viewPracticalWork(row)"
            matTooltip="Praktische Tätigkeit ansehen">
            <i class="fa-light fa-arrow-up-right-from-square"></i>
          </button>
          <button
            type="button"
            class="table-button-icon"
            (click)="deletePracticalWork(row)"
            matTooltip="Praktische Tätigkeit löschen">
            <i class="fa-light fa-trash-can"></i>
          </button>
          <button
            type="button"
            class="table-button-icon"
            (click)="editPracticalWork(row)"
            matTooltip="Praktische Tätigkeit bearbeiten">
            <i class="fa-light fa-pen"></i>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td [attr.colspan]="displayedColumns.length">
        <div *ngIf="isLoading" class="loading-wrapper">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate"
            diameter="32">
          </mat-progress-spinner>
        </div>
        <div
          *ngIf="!isLoading"
          class="mat-cell table-no-match hasomed-text-body font-grey">
          Es wurde keine Tätigkeit gefunden.
        </div>
      </td>
    </tr>
  </table>
  <mat-divider></mat-divider>
  <mat-paginator
    custom-paginator
    [appCustomLength]="dataSource.filteredData.length"
    [pageSize]="7"
    [renderButtonsNumber]="1"
    aria-label="Select page"></mat-paginator>
</div>
