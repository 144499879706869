import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class FormSubmitValidationService {
  constructor(private alertService: AlertService) {}

  /**
   * validateFormAndScrollToError
   * checks if the form group is invalid and marks it as touched, to show the error messages and scrolls to the first invalid form control
   * @param formGroup the form group to check
   * @returns boolean if the form group is invalid
   */
  public validateFormAndScrollToError(formGroup: FormGroup): boolean {
    console.debug('validateFormAndScrollToError', formGroup);
    if (formGroup.invalid) {
      formGroup.markAllAsTouched();

      // get first invalid form control
      const firstInvalidControl = Object.keys(formGroup.controls).find(
        key => formGroup.controls[key].invalid
      );

      // get error for firstInvalidControl
      const error = formGroup.controls[firstInvalidControl].errors;

      // if it is a required error, show a message
      if (error?.required) {
        this.alertService.showErrorAlert(
          'Das hat nicht geklappt!',
          'Es gibt noch unausgefüllte Pflichtfelder.'
        );
      } else {
        this.alertService.showErrorAlert(
          'Das hat nicht geklappt!',
          'Bitte überprüfen Sie Ihre Eingaben.'
        );
      }

      // get the first invalid control element to scroll to it
      const firstInvalidControlElement =
        document.getElementById(firstInvalidControl);

      if (firstInvalidControlElement) {
        // scroll to the first invalid form control
        firstInvalidControlElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
      return true;
    }
    return false;
  }
}
