<div class="callback">
  <mat-progress-spinner
    *ngIf="isLoading"
    color="primary"
    mode="indeterminate"
    diameter="64">
  </mat-progress-spinner>
  <div class="flex-c justify-center card" *ngIf="!isLoading">
    <div class="hasomed-text-h1">Kein Institut zugewiesen</div>
    <div class="hasomed-text-body">
      Sie sind nicht für ein Institut registriert. Bitte wenden Sie sich an
      Ihren Administrator.
    </div>
  </div>
</div>
