import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { TreatmentCase } from 'src/app/models/treatment-case.model';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { UserService } from 'src/app/services/user.service';
import { CreateEditPatientAppointmentComponent } from '../../eleguide/education/ambulatory-part/create-edit-patient-appointment/create-edit-patient-appointment.component';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-treatment-cases',
  templateUrl: './treatment-cases.component.html',
  styleUrls: ['./treatment-cases.component.scss'],
})
export class TreatmentCasesComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public treatmentCases: any[] = [];
  public selectedTreatmentCase: any;

  @Input() searchText: string;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private treatmentCaseService: TreatmentCaseService,
    private dialog: MatDialog,
    public userService: UserService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.parent?.paramMap.subscribe(params => {
      let id_user = +atob(params.get('id'));

      if (!id_user) {
        id_user = this.userService.currentUser.id;
      }

      this.getTreatmentCases(id_user);
    });
  }

  private async getTreatmentCases(id_user: number) {
    this.treatmentCaseService
      .getStudentsTreatmentCases(id_user)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('TreatmentCases:', response);
          if (!response.success) {
            console.error(response.message);
            this.isLoading = false;
            return;
          }
          this.treatmentCases = await Promise.all(
            response.data?.map(
              async (treatmentCaseData: any): Promise<TreatmentCase> => {
                return await this.treatmentCaseService.parseBackendTreatmentCase(
                  treatmentCaseData
                );
              }
            )
          );

          this.activatedRoute.queryParams.subscribe(params => {
            if (params['id_treatment_case'] != undefined) {
              const id_treatmentCase = +atob(params['id_treatment_case']);
              // set selected treatment case based on id_treatmentCase and this.treatmentCases
              this.selectedTreatmentCase = this.treatmentCases.find(
                treatmentCase => treatmentCase.id === id_treatmentCase
              );
              this.treatmentCaseService.selectedTreatmentCase.next(
                this.selectedTreatmentCase
              );

              // move selected treatment case to the top of the list
              this.treatmentCases.sort(
                function (treatmentCaseA, treatmentCaseB) {
                  if (treatmentCaseA.id === id_treatmentCase) {
                    return -1;
                  }
                  if (treatmentCaseB.id === id_treatmentCase) {
                    return 1;
                  }
                  return 0;
                }
              );
            }
          });

          this.isLoading = false;
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  public onCreateTreatmentCase() {
    this.router.navigate(['create-treatment-case'], {
      relativeTo: this.activatedRoute,
    });
  }

  public onCreateSupervisionAppointment(treatmentCase: TreatmentCase) {
    this.router.navigate(
      ['create-supervision-appointment', btoa(treatmentCase.id.toString())],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  public onCreatePatientAppointment(treatmentCase: TreatmentCase) {
    const dialogRef = this.dialog.open(CreateEditPatientAppointmentComponent, {
      width: '66%',
      data: {
        treatmentCase,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.treatmentCaseService
          .getTreatmentCase(treatmentCase.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: result => {
              console.debug('TreatmentCase:', result);
              if (result.success) {
                this.selectedTreatmentCase =
                  this.treatmentCaseService.parseBackendTreatmentCase(
                    result.data
                  );
                this.treatmentCases.find(item => {
                  if (item.id === this.selectedTreatmentCase.id) {
                    item = this.selectedTreatmentCase;
                  }
                });
                this.treatmentCaseService.selectedTreatmentCase.next(
                  this.selectedTreatmentCase
                );
              }
            },
            error: error => {
              console.error(error);
            },
          });
      }
    });
  }

  public showDetails(treatmentCase: TreatmentCase) {
    this.treatmentCaseService.selectedTreatmentCase.next(treatmentCase);
    this.selectedTreatmentCase = treatmentCase;
  }

  public hideDetails() {
    this.selectedTreatmentCase = null;
  }

  /**
   * onShowTreatmentCaseOverview
   * navigate to the treatment case overview
   * @param treatmentCase
   * @returns void
   */
  public onShowTreatmentCaseOverview(treatmentCase: TreatmentCase): void {
    this.router.navigate(['./', btoa(treatmentCase.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onEditTreatmentCase
   * open the edit treatment case page
   * @param treatmentCase
   * @returns void
   *
   */
  public onEditTreatmentCase(treatmentCase: TreatmentCase): void {
    this.router.navigate(
      ['edit-treatment-case', btoa(treatmentCase.id.toString())],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
