import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { EducationCourse } from 'src/app/models/education-course.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EducationCourseService } from 'src/app/services/education-course.service';
import { InstituteService } from 'src/app/services/institute.service';

@Component({
  selector: 'app-onb-education-course-table',
  templateUrl: './onb-education-course-table.component.html',
  styleUrls: ['./onb-education-course-table.component.scss'],
})
export class ONBEducationCourseTableComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'education',
    'accreditation',
    'regulation',
    'edit',
  ];
  public dataSource: MatTableDataSource<EducationCourse> =
    new MatTableDataSource();
  public tableData: Subject<EducationCourse[]> = new Subject();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject();

  constructor(
    public instituteService: InstituteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private educationCourseService: EducationCourseService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.initTableData();
    this.tableData.subscribe(educationCourses => {
      this.dataSource = new MatTableDataSource<any>(educationCourses);

      /* set sortingDataAccessor */
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'education':
            return item.title;
          case 'accreditation':
            return item.accreditation_year;
          case 'regulation':
            return item.regulation;
          default:
            return item[property];
        }
      };

      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });
  }

  /**
   * initTableData
   * initialize table data
   * @returns void
   */
  private async initTableData(): Promise<void> {
    if (this.instituteService.currentInstitute) {
      const id_institute = this.instituteService.currentInstitute.id;
      this.educationCourseService
        .getInstituteEducationCourses(id_institute)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            console.log('institute education courses', response);
            if (!response.success) {
              this.alertService.showErrorAlert(
                'Fehler',
                `Die Aus-/Weiterbildungsgänge konnten nicht geladen werden!`
              );
              console.error(response.message);
              return;
            }
            /* Create table data */
            const eudcationCourses = response.data
              ? await Promise.all(
                  response.data.map(
                    async (
                      educationCourseData: any
                    ): Promise<EducationCourse> => {
                      const parsedEducationCourse: EducationCourse =
                        await this.educationCourseService.parseBackendEducationCourse(
                          educationCourseData
                        );
                      return parsedEducationCourse;
                    }
                  )
                )
              : [];
            this.tableData.next(eudcationCourses);
          },
          error: error => {
            console.error(error);
          },
        });
    } else {
      this.tableData.next([]);
      this.isLoading = false;
    }
  }

  /**
   * onEditEducationCourse
   * navigate to edit education course
   * @param id id of educationCourse
   * @returns void
   */
  public onEditEducationCourse(id_educationCourse: number): void {
    const route =
      this.router.url === '/eleguide/onboarding/overview'
        ? '../education-course/edit'
        : './edit';
    this.router.navigate([route, btoa(String(id_educationCourse))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onDeleteEducationCourse
   * delete education course
   * @param id_educationCourse id of educationCourse
   * @returns void
   */
  public onDeleteEducationCourse(id_educationCourse: number): void {
    // open confirm dialog
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Aus-/Weiterbildungsgang löschen',
        message: 'Möchten Sie den Aus-/Weiterbildungsgang wirklich löschen?',
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.educationCourseService
          .deleteEducationCourse(id_educationCourse)
          .subscribe({
            next: response => {
              console.debug('delete education course response', response);
              if (response.success) {
                this.alertService.showSuccessAlert(
                  'Aus-/Weiterbildungsgang gelöscht',
                  `Der Aus-/Weiterbildungsgang wurde erfolgreich gelöscht!`
                );
                this.initTableData();
              } else {
                this.alertService.showErrorAlert(
                  'Fehler',
                  `Der Aus-/Weiterbildungsgang konnte nicht gelöscht werden!`
                );
              }
            },
            error: error => {
              console.error(error);
            },
          });
      }
    });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
