<div class="flex-c component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Audit-Log</h1>
      <h3 class="hasomed-text-h3">Bekomme einen Einblick in den Audit-Log</h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche"
          (keyup)="applyFilter($event)" />
      </div>
    </form>
  </div>
  <div class="main-content">
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- title column -->
        <ng-container matColumnDef="title">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span>Titel</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="title">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-900 title-cell" mat-cell *matCellDef="let row">
            <div class="flex-c cell-overflow-hidden">
              <span class="hasomed-text-body">{{ row.title }}</span>
              <span
                class="hasomed-text-small text-overflow-hidden"
                [matTooltip]="row.subtitle">
                {{ row.subtitle }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- oldValue column -->
        <ng-container matColumnDef="oldValue">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span>Alter Wert</span>
          </th>
          <td class="gray-900" mat-cell *matCellDef="let row">
            <div class="collapsable" *ngIf="row.oldValue">
              <div
                class="collapsable-trigger"
                (click)="row.collapsed = !row.collapsed">
                <i class="fa-light fa-chevron-down"></i>
                <span>
                  {{ row.collapsed ? 'Ausklappen' : 'Einklappen' }}
                </span>
              </div>
              <div class="flex-c collapsable-content" *ngIf="!row.collapsed">
                <span *ngFor="let item of row.oldValue">
                  {{ item.key }} :
                  {{
                    item.value && item.value !== '' ? item.value : 'Kein Wert'
                  }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>
        <!-- newValue column -->
        <ng-container matColumnDef="newValue">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span>Neuer Wert</span>
          </th>
          <td class="gray-900" mat-cell *matCellDef="let row">
            <div class="collapsable" *ngIf="row.newValue">
              <div
                class="collapsable-trigger"
                (click)="row.collapsed = !row.collapsed">
                <i class="fa-light fa-chevron-down"></i>
                <span>
                  {{ row.collapsed ? 'Ausklappen' : 'Einklappen' }}
                </span>
              </div>
              <div class="flex-c collapsable-content" *ngIf="!row.collapsed">
                <span *ngFor="let item of row.newValue">
                  {{ item.key }} :
                  {{
                    item.value && item.value !== '' ? item.value : 'Kein Wert'
                  }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- responsible column -->
        <ng-container matColumnDef="responsiblePerson">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span>Verantwortlicher</span>
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="responsiblePerson">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-900" mat-cell *matCellDef="let row">
            <span>{{ row.responsiblePerson }}</span>
          </td>
        </ng-container>

        <!-- date column -->
        <ng-container matColumnDef="date">
          <th
            class="gray-600"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            <span>Datum</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="date">
            </app-custom-table-sort-icon>
          </th>
          <td class="gray-900" mat-cell *matCellDef="let row">
            <span>{{ row.date | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <mat-progress-spinner
                color="primary"
                mode="indeterminate"
                diameter="32">
              </mat-progress-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Es wurde kein Audit-Log gefunden.
            </div>
          </td>
        </tr>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="15"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
    <div class="vertical-spacer"></div>
  </div>
</div>
