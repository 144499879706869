import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/configs/environment';
import { eLog, eLogStatus } from '../models/elog.model';
import { UserService } from './user.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class ElogService {
  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {}

  public getAllCourseEventElogs(id_event: number): Observable<any> {
    return this.http.get(
      environment.authority_short +
        `elog/getAllCourseEventElogs.php?id_event=${id_event}`
    );
  }

  /**
   * getCourseEventDatesForElog
   * Get course event dates for eLog
   * @param id_course
   * @param includeOnlyPending
   * @returns Observable<any>
   */
  public getCourseEventDatesForElog(
    id_course: number,
    includeOnlyPending: boolean
  ): Observable<any> {
    return this.http.get(
      environment.authority_short +
        `elog/getAllCourseEventDatesForElog.php?id_course=${id_course}&includeOnlyPending=${includeOnlyPending}`
    );
  }

  /**
   * updateElogs
   * Update multiple eLogs
   * @param eLogs course event eLogs
   * @returns Observable<any>
   */
  public updateElogs(eLogs: eLog[]): Observable<any> {
    console.debug('eLogs update post data: ', eLogs);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      environment.authority_short + 'elog/updateElogs.php',
      eLogs,
      { headers: headers }
    );
  }

  /**
   * updateElog
   * Update a single eLog
   * @param courseEventElog
   * @returns Observable<any>
   */
  public updateElog(id_eLog: number, elogStatus: eLogStatus): Observable<any> {
    console.debug('eLog update post data: ', id_eLog, elogStatus);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const data = {
      id_eLog: id_eLog,
      eLogStatus: elogStatus,
    };

    return this.http.post(
      environment.authority_short + 'elog/updateElog.php',
      data,
      { headers: headers }
    );
  }

  /**
   * getNumberOfPendingElogsFromLecturer
   * Get the number of pending eLogs from the current lecturer
   * @returns Observable<any>
   */
  public getNumberOfPendingElogsFromLecturer(): Observable<any> {
    const id_lecturer = this.userService.currentUser.id;
    const id_institute = this.userService.currentUser.id_institute;
    return this.http.get(
      environment.authority_short +
        `elog/getNumberOfPendingElogsFromLecturer.php?id_lecturer=${id_lecturer}&id_institute=${id_institute}`
    );
  }

  /**
   * getNumberOfPendingElogsFromInstitute
   * Get the number of pending eLogs from the current lecturer
   * @returns Observable<any>
   */
  public getNumberOfPendingElogsFromInstitute(): Observable<any> {
    const id_institute = this.userService.currentUser.id_institute;
    return this.http.get(
      environment.authority_short +
        `elog/getNumberOfPendingElogsFromInstitute.php?id_institute=${id_institute}`
    );
  }
}
