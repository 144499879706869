import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CustomFileType } from 'src/app/models/custom-file-type.model';

export interface FileFormat {
  type: string;
  mimeType: string;
}

@Component({
  selector: 'app-upload-area-dnd',
  templateUrl: './upload-area-dnd.component.html',
  styleUrls: ['./upload-area-dnd.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadAreaDNDComponent),
      multi: true,
    },
  ],
})
export class UploadAreaDNDComponent implements OnInit, ControlValueAccessor {
  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) {}

  @ViewChild('fileInput') fileInput: ElementRef;

  // Inputs
  /* Context Information: e.g. profilePictureUpload */
  @Input() context: string;
  /* Configuration for console outputs */
  @Input() logs: boolean;
  /* Configuration for showing the uploaded files with progress */
  @Input() showFileList: boolean;
  /* Array of required File Types as Array with File Format Object. Type und Mime Type  */
  @Input() requiredFileTypes: Array<FileFormat>;
  /* Configuration for maximum file size */
  @Input() maxFileSize = '10 MB';
  /* Configuration for multiple or single file upload */
  @Input() multiple: boolean;
  /* Configuration for scroll to uploaded file */
  @Input() scrollTo: boolean;

  @Input() inputId: string;

  @Input() disabled: boolean;

  @Input() existingFiles: CustomFileType[];

  @Output() deleteExistingFile: EventEmitter<CustomFileType> =
    new EventEmitter();

  @Input() sensitiveDataAlert: boolean = true;

  // Variables
  public isDisabled: boolean = false;
  public requiredFileTypesString = '';
  public requiredFileMimeTypesString = '';
  public uploadedFiles: any[] = [];
  public error: boolean;
  public errorSize: boolean;
  public scrolledToFile = false;
  public val: any[];
  public showNoSensitiveDataAlert: boolean;

  ngOnInit() {
    this.requiredFileTypes.forEach(fileType => {
      if (fileType.type === 'PDF') {
        this.showNoSensitiveDataAlert = true;
      }

      if (this.requiredFileTypesString != '') {
        this.requiredFileTypesString += ', ' + fileType.type;
      } else {
        this.requiredFileTypesString += fileType.type;
      }
      if (this.requiredFileMimeTypesString != '') {
        this.requiredFileMimeTypesString += ', ' + fileType.mimeType;
      } else {
        this.requiredFileMimeTypesString += fileType.mimeType;
      }
    });
  }

  /*---------------------------------------- UPLOAD DIALOG FUNCTIONS START ----------------------------------------*/
  // run when file in FileChooser is selected
  async onFileDropped(event: any) {
    if (this.logs) {
      console.debug('onFileDropped Event:', event);
    }
    this.prepareFilesList(event);
  }

  /**
   * handle file from browsing
   */
  public fileBrowseHandler(event: any) {
    if (this.logs) {
      console.debug('fileBrowseHandler Event:', event);
    }
    this.prepareFilesList(event.target.files);
  }

  /**
   *
   * @param file
   */
  public onDeleteExistingFile(file: CustomFileType) {
    this.deleteExistingFile.emit(file);
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
  }

  /**
   * Simulate the upload process
   */
  public uploadFilesSimulator(index: number) {
    if (
      index !== this.uploadedFiles.length - 1 &&
      this.uploadedFiles[index]?.progress === 100
    ) {
      this.uploadFilesSimulator(index + 1);
      return;
    }
    setTimeout(() => {
      if (index === this.uploadedFiles.length) {
        /* reset scrolledToFile for next file upload */
        this.scrolledToFile = false;
        return;
      } else {
        const progressInterval = setInterval(() => {
          /* scroll to last uploaded file if scrollto is set to true */
          if (this.uploadedFiles.length > 0 && this.scrollTo) {
            const itemToScrollTo = document.getElementById(
              'file-' + (this.uploadedFiles.length - 1)
            );
            if (itemToScrollTo && this.scrolledToFile === false) {
              this.scrolledToFile = true;
              itemToScrollTo.scrollIntoView({ behavior: 'smooth' });
            }
          }

          if (this.uploadedFiles[index].progress === 100) {
            clearInterval(progressInterval);
            if (this.uploadedFiles[index].uploaded !== true) {
              setTimeout(() => {
                this.uploadedFiles[index].uploaded = true;
              }, 500);
            }
            this.uploadFilesSimulator(index + 1);
          } else {
            this.uploadedFiles[index].progress += 10;
          }
        }, 50);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  private prepareFilesList(files: Array<any>) {
    // reset array if only a single file should be uploaded
    if (!this.multiple) {
      this.uploadedFiles = [];
    }
    for (const item of files) {
      item.progress = 0;
      if (this.logs) {
        console.debug(item);
      }
      // check if file already exists in uploadedFiles or existingFiles
      const duplicate = this.uploadedFiles?.find(
        uploadedFile => uploadedFile.name === item.name
      );
      const duplicateExisting = this.existingFiles?.find(
        existingFile => existingFile.filename === item.name
      );
      if (duplicate || duplicateExisting) {
        this.alertService.showErrorAlert(
          'Dateiname bereits vergeben',
          'Eine Datei mit dem Namen ' + item.name + ' existiert bereits'
        );
        continue;
      }
      // check if file size is too large
      if (item.size > this.convertToBytes(this.maxFileSize)) {
        this.alertService.showErrorAlert(
          'Datei zu groß',
          'Die ausgewählte Datei ist zu groß'
        );
        this.errorSize = true;
        continue;
      } else {
        this.errorSize = false;
      }

      // check if file type is allowed
      let allowed = false;
      this.requiredFileTypes.forEach(fileType => {
        if (fileType.mimeType.includes(item.type)) {
          allowed = true;
        }
      });
      if (!allowed) {
        this.alertService.showErrorAlert(
          'Dateityp nicht erlaubt',
          `Der Dateityp von der Datei '${item.name}' ist nicht erlaubt`
        );
        continue;
      }

      // reset array if only a single file should be uploaded
      if (!this.multiple) {
        this.uploadedFiles = [];
      }
      this.uploadedFiles.push(item);
    }
    this.onChange(this.uploadedFiles);
    this.uploadFilesSimulator(0);
    if (this.logs) {
      console.debug(this.context, 'Uploaded Files: ', this.uploadedFiles);
    }
    // this.fileInput.nativeElement.value = '';
  }

  /**
   * Convert size with unit to bytes
   * @param size
   * @returns
   */
  public convertToBytes(size: string) {
    const sizeArray = size.split(' ');
    let bytes = 0;
    if (sizeArray[1] === 'KB') {
      bytes = parseInt(sizeArray[0], 10) * 1024;
    } else if (sizeArray[1] === 'MB') {
      bytes = parseInt(sizeArray[0], 10) * 1024 * 1024;
    } else if (sizeArray[1] === 'GB') {
      bytes = parseInt(sizeArray[0], 10) * 1024 * 1024 * 1024;
    } else if (sizeArray[1] === 'TB') {
      bytes = parseInt(sizeArray[0], 10) * 1024 * 1024 * 1024 * 1024;
    }
    return bytes;
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  public formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /*---------------------------------------- UPLOAD DIALOG FUNCTIONS END ----------------------------------------*/

  /*---------------------------------------- CONTROL VALUE ACCESSOR FUNCTIONS START ----------------------------------------*/
  onChange: any = () => {};
  onTouch: any = () => {};
  // this is the updated value that the class accesses
  set value(val) {
    // this value is updated by programmatic changes
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }
  // this method sets the value programmatically
  writeValue(value: any) {
    if (value && Array.isArray(value)) {
      this.uploadedFiles = value;
    } else {
      this.uploadedFiles = []; // Reset if the value is not provided
    }
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  /*---------------------------------------- CONTROL VALUE ACCESSOR FUNCTIONS END ----------------------------------------*/
  public onDelete(file: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Datei löschen',
        message: 'Möchten Sie die Datei wirklich löschen?',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.uploadedFiles.indexOf(file);
        if (index > -1) {
          this.uploadedFiles.splice(index, 1);
        }
        this.fileInput.nativeElement.value = '';
        this.onChange(this.uploadedFiles);
      }
    });
  }
  public onDownload(file: any) {
    // download file
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(fileURL);
  }
}
