import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { RecurrencePattern } from 'src/app/models/course.model';
import {
  TreatmentCaseEventDate,
  TreatmentCaseEventType,
} from 'src/app/models/event.model';
import {
  TreatmentCase,
  TreatmentCaseAppointment,
} from 'src/app/models/treatment-case.model';
import { User } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-create-edit-supervision-appointments',
  templateUrl: './create-edit-supervision-appointments.component.html',
  styleUrls: ['./create-edit-supervision-appointments.component.scss'],
})
export class CreateEditSupervisionAppointmentsComponent
  implements OnInit, OnDestroy
{
  public supervisionAppointmentForm: FormGroup;
  public roomPlanningOpen: boolean = false;
  public editMode: boolean = false;

  public startDate: any;
  public endDate: any;
  public recurrencePattern: RecurrencePattern;
  public events: TreatmentCaseEventDate[] = [];
  public supervisors: User[];
  public selectedSupervisor: User;
  private uploadedFiles: File[] = [];
  public isLoading = true;
  public currentTreatmentCase: TreatmentCase;
  private currentAppointment: TreatmentCaseAppointment;
  public patientAppointmentEventDates: TreatmentCaseEventDate[] = [];
  public treatmentCaseEventType = TreatmentCaseEventType;
  public formSubmitted: boolean = false;

  private initialFormValues: {};

  public requiredFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  // import from form.utils.ts
  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.supervisionAppointmentForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private userService: UserService,
    private treatmentCaseService: TreatmentCaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private formDeactivateService: FormDeactivateService,
    private alertService: AlertService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.treatmentCaseService
          .getTreatmentCase(+atob(params.id))
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: async result => {
              console.debug('TreatmentCase:', result);

              if (!result.success) {
                this.alertService.showErrorAlert(
                  'Fehler',
                  'Der Behandlungsfall konnte nicht geladen werden!'
                );
                this.router.navigate(['../../'], {
                  relativeTo: this.activatedRoute,
                });
                return;
              }

              this.currentTreatmentCase =
                await this.treatmentCaseService.parseBackendTreatmentCase(
                  result.data
                );
              this.getPatientAppointments();

              this.supervisionAppointmentForm.patchValue({
                title: 'Supervision ' + this.currentTreatmentCase.chiffre,
              });
              this.supervisionAppointmentForm.get('title').disable();

              if (params.id_appointment) {
                this.editMode = true;
                this.currentAppointment =
                  this.currentTreatmentCase.appointments.find(
                    it => it.id === +atob(params.id_appointment)
                  );

                this.supervisionAppointmentForm.patchValue({
                  supervisionType: this.currentAppointment.type,
                  timeUnit: this.currentAppointment.timeUnits?.toString(),
                  internalNote: this.currentAppointment.internalNote,
                  patientAppointments:
                    this.currentAppointment.selectedPatientEventDateIds,
                });
                this.startDate = moment(this.currentAppointment.startDate);
                this.endDate = moment(this.currentAppointment.endDate);
                this.recurrencePattern =
                  this.currentAppointment.recurrencePattern;
                if (this.currentAppointment.supervisor) {
                  this.selectedSupervisor = this.currentAppointment.supervisor;
                  this.supervisionAppointmentForm
                    .get('supervisor')
                    .setValue(this.selectedSupervisor);
                }
                this.events = this.currentAppointment.eventDates;
                this.cdr.detectChanges();
                this.isLoading = false;
              } else {
                this.isLoading = false;
              }
              this.initialFormValues = this.supervisionAppointmentForm.value;
            },
          });
      } else {
        this.initialFormValues = this.supervisionAppointmentForm.value;
        this.cdr.detectChanges();
      }
    });

    this.getSupervisors();
  }

  public createForm() {
    this.supervisionAppointmentForm = new FormGroup({
      title: new FormControl('', Validators.required),
      supervisionType: new FormControl('', Validators.required),
      patientAppointments: new FormControl('', Validators.required),
      timeUnit: new FormControl('', Validators.required),
      dateGroup: new FormControl(null),
      supervisor: new FormControl(null, Validators.required),
      supervisorSearch: new FormControl(null),
      internalNote: new FormControl('', Validators.maxLength(6000)),
    });
  }

  public getSupervisors() {
    const id_institute = this.userService.currentUser.id_institute;
    this.userService.getInstituteSupervisors(id_institute).subscribe({
      next: async response => {
        console.debug('get supervisors backend response:', response);
        if (!response.success) {
          console.error(response.message);
          return;
        }
        this.supervisors = response.data
          ? await Promise.all(
              response.data?.map(async (userData: User): Promise<User> => {
                return await this.userService.parseBackendUser(userData);
              })
            )
          : [];
      },
      error: error => {
        console.error(error);
      },
    });
  }

  public getPatientAppointments() {
    this.currentTreatmentCase.appointments?.forEach(appointment => {
      if (appointment.type !== TreatmentCaseEventType.PATIENT_SESSION) {
        return;
      }
      appointment.eventDates?.forEach(eventDate => {
        // skip canceled event dates
        if (eventDate.canceled) {
          return;
        }
        this.patientAppointmentEventDates.push(eventDate);
      });
    });
  }

  public onSupervisorSelected(supervisor: User) {
    if (supervisor === this.selectedSupervisor) {
      this.selectedSupervisor = null;
    } else {
      this.selectedSupervisor = supervisor;
    }
    this.supervisionAppointmentForm
      .get('supervisor')
      .setValue(this.selectedSupervisor);
  }

  public onOpenRoomPlanning() {
    if (this.events.length === 0) {
      this.alertService.showErrorAlert(
        'Keine Termine vorhanden',
        'Bitte legen Sie mindestens einen Termine an!'
      );
      return;
    }
    this.roomPlanningOpen = true;
  }

  public onCloseRoomPlanning() {
    this.roomPlanningOpen = false;
  }

  public roomPlanningChanged(events: TreatmentCaseEventDate[]) {
    if (!events) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
              Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.roomPlanningOpen = !this.roomPlanningOpen;
          return;
        }
      });
    } else {
      this.events = events ?? [];
      this.roomPlanningOpen = false;
    }
  }

  public onSubmit() {
    this.formSubmitted = true;
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.supervisionAppointmentForm
      );

    if (formInvalid) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.supervisionAppointmentForm.value,
        this.initialFormValues
      )
    ) {
      this.onCancel();
      return;
    }

    const supervisionAppointment: any = {
      id: this.editMode ? this.currentAppointment.id : null,
      appointmentType:
        this.supervisionAppointmentForm.value.supervisionType === 'Supervision'
          ? { id: 2, name: 'Supervision' }
          : { id: 3, name: 'Gruppensupervision' },
      startDate: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'),
      timeUnits: this.supervisionAppointmentForm.value.timeUnit,
      supervisor: this.selectedSupervisor,
      selectedPatientEventDateIds:
        this.supervisionAppointmentForm.value.patientAppointments,
      recurrencePattern: this.recurrencePattern,
      eventDates: this.events?.map(event => {
        return {
          id: event.id,
          eventTypeId:
            this.supervisionAppointmentForm.value.supervisionType ===
            'Supervision'
              ? 4
              : 5,
          startDate: moment(event.startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(event.endDate).format('YYYY-MM-DD HH:mm:ss'),
          roomId: event.room?.id,
          canceled: event.canceled,
          instituteId: this.userService.currentUser.id_institute,
        };
      }),
      internalNote: this.supervisionAppointmentForm.value.internalNote,
    };

    if (!this.checkForEventsWithoutRoom()) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Raumplanung offen',
          message:
            'Sie haben noch keine Räume gewählt, wollen Sie die Termine ohne Raumbuchung speichern?',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.initialFormValues = this.supervisionAppointmentForm.value;

          this.editMode
            ? this.editAppointment(supervisionAppointment)
            : this.createAppointment(supervisionAppointment);
        }
      });
      return;
    } else {
      this.initialFormValues = this.supervisionAppointmentForm.value;

      this.editMode
        ? this.editAppointment(supervisionAppointment)
        : this.createAppointment(supervisionAppointment);
    }
  }

  public createAppointment(appointment: any) {
    this.treatmentCaseService
      .createAppointment(this.currentTreatmentCase.id, appointment)
      .subscribe({
        next: response => {
          console.debug('createAppointment', response);
          if (response.success) {
            this.alertService.showSuccessAlert(
              `Termin erstellt`,
              `Der Supervisionstermin wurde erfolgreich erstellt!`
            );
            this.onCancel();
          }
        },
        error: error => {
          console.error(error);
        },
      });
  }

  public editAppointment(appointment: TreatmentCaseAppointment) {
    this.treatmentCaseService.updateAppointment(appointment).subscribe({
      next: response => {
        console.debug('updateAppointment', response);
        if (response.success) {
          this.alertService.showSuccessAlert(
            `Termin bearbeitet`,
            `Der Supervisionstermin wurde erfolgreich bearbeitet!`
          );
          this.onCancel();
        }
      },
      error: error => {
        console.error(error);
      },
    });
  }

  public onCancel() {
    if (this.editMode) {
      this.router.navigate(['../../../'], {
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    }
  }

  public onEventsChanged(events: TreatmentCaseEventDate[]) {
    this.events = events;
  }

  public onDatesChanged(dates: {
    start: Date;
    end: Date;
    allDayEvent: Boolean;
  }) {
    this.startDate = dates.start;
    this.endDate = dates.end;
    this.cdr.detectChanges();
  }

  public onDocumentsSelected(event: any) {
    this.uploadedFiles = this.uploadedFiles.concat(
      Array.from(event.target.files)
    );
  }

  public onSupervisionTypeChanged(type: TreatmentCaseEventType) {
    this.supervisionAppointmentForm.get('supervisionType').setValue(type);
  }

  private checkForEventsWithoutRoom(): boolean {
    let eventsWithoutRoom = this.events?.filter(
      event =>
        (event.room === null || event.room === undefined) &&
        !event.canceled &&
        event.endDate > new Date()
    );
    if (eventsWithoutRoom.length > 0) {
      return false;
    }
    return true;
  }

  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.supervisionAppointmentForm.value,
      this.initialFormValues
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
