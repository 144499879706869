import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { environment } from 'src/configs/environment';
import { UserService } from './user.service';
import * as moment from 'moment';
import { DecryptionService } from './decryption.service';
import { CancellationService } from './cancellation.service';

export const dbKeys = {
  birthdate: 'Geburtsdatum',
  academicTitle: 'Titel',
  genderTitle: 'Anrede',
  firstname: 'Vorname',
  lastname: 'Nachname',
  email: 'E-Mail',
  phone: 'Telefon',
  street: 'Straße',
  houseNumber: 'Hausnummer',
  house_number: 'Hausnummer',
  addressAddition: 'Adresszusatz',
  address_addition: 'Adresszusatz',
  city: 'Stadt',
  zipCode: 'PLZ',
  zip_code: 'PLZ',
  country: 'Land',
  bankName: 'Bankname',
  iban: 'IBAN',
  bic: 'BIC',
  accountHolder: 'Kontoinhaber',
  bankDetails: 'Bankverbindung',
  name: 'Name',
  profilePicture: 'Profilbild',
  eLogStatus: 'eLog',
  building: 'Gebäude',
  seat_number: 'Platzanzahl',
  floor: 'Etage',
  responsible: 'Verantwortlicher',
  available: 'Verfügbar',
  equipment: 'Ausstattung',
  title: 'Titel',
  description: 'Beschreibung',
  text: 'Text',
  maxStudents: 'Maximale Teilnehmerzahl',
  events: 'Termine',
  startDate: 'Startdatum',
  start_date: 'Startdatum',
  endDate: 'Enddatum',
  end_date: 'Enddatum',
  duration: 'Dauer',
  titlePicture: 'Titelbild',
  picture: 'Bild',
  defaultTitlePictureId: 'Standard Titelbild',
  recurrencePattern: 'Wiederholung',
  learningContent: 'Lerninhalt',
  educationCourses: 'Aus- Weiterbildungsgänge',
  courseType: 'Kurstyp',
  lecturers: 'Lehrpersonal',
  mandatory: 'Verpflichtend',
  logonEnabled: 'Kurs aktiv',
  closed: 'Geschlossen',
  color: 'Farbcode',
  chamber: 'Kammer',
  expertise: 'Fachkunde/Gebietsweiterbildung',
  regulation: 'Verordnung',
  policy_procedure: 'Richtlinienverfahren',
  professional_association_accreditation: 'Akkreditierender Berufsverband',
  professional_association: 'Akkreditierender Berufsverband',
  accreditation_year: 'Akkreditierungsjahr',
  link: 'Link',
  published: 'Veröffentlicht',
  id_news_type: 'Typ',
  responsible_person: 'Verantwortlicher',
  internal_note: 'Interne Notiz',
  internalNotes: 'Interne Notizen',
  date: 'Datum',
  type: 'Typ',
  age: 'Alter',
  chiffre: 'Chiffre',
  timeUnits: 'Zeiteinheiten',
  eventTypeId: 'Terminart',
  supervisors: 'Supervisoren',
  supervisor: 'Supervisor',
  appointments: 'Termine',
  procedures: 'Verfahren',
  therapyType: 'Therapieart',
  eventDates: 'Termine',
  treatmentCaseId: 'Behandlungsfall',
  appointmentType: 'Terminart',
  eventType: 'Terminart',
  room: 'Raum',
  is_founded: 'Gegründet',
  color_akzent1: 'Akzentfarbe 1',
  color_akzent2: 'Akzentfarbe 2',
  color_primary: 'Primärfarbe',
  color_secondary: 'Sekundärfarbe',
  supervisorText: 'Eigener Supervisor',
  id_status_label: 'Etikett',
  id_role: 'Rolle',
  educationCourseId: 'Aus- und Weiterbildungsgang',
  therapyForm: 'Therapiezusammensetzung',
  examTypes: 'Prüfungsarten',
  courseStudents: 'Kurs-Teilnehmer',
  wiki: 'Wiki',
  content: 'Inhalt',
  subtitle: 'Unterüberschrift',
  contingents: 'Kontingente',
  contingent_practical_1: 'Kontingent Praktikum 1',
  contingent_practical_2: 'Kontingent Praktikum 2',
  c_practical_1_h: 'Praktische Tätigkeit 1 Stunden',
  c_practical_1_medical_history_survey_min:
    'Praktische Tätigkeit 1 Mindeststunden Anamneseerhebungen',
  c_practical_2_h: 'Praktische Tätigkeit 2 Stunden',
  contingent_theoretical_scientific: 'Kontingent Theoretisch-Wissenschaftlich',
  c_theoretical_scientific_h: 'Theoretisch-Wissenschaftlich Stunden',
  c_theoretical_scientific_auto_training_h:
    'Theoretisch-Wissenschaftlich min. Doppelstunden autogenes Training',
  c_theoretical_scientific_balintgroupwork_h:
    'Theoretisch-Wissenschaftlich min. Doppelstunden Balintgruppenarbeit',
  c_supervision_h: 'Supervision Stunden',
  c_supervision_group_session_h: 'Gruppensupervision Stunden',
  c_supervision_single_session_h: 'Einzelsupervision Stunden',
  c_supervision_medical_history_survey_min:
    'Supervision Mindeststunden Anamneseerhebungen',
  c_treatment_cases: 'Behandlungsfälle',
  c_treatment_cases_min: 'Mindestanzahl Behandlungsfälle',
  c_treatment_cases_min_h: 'Behandlungsfälle Mindeststunden',
  c_completed_treatment_cases: 'Abgeschlossene Behandlungsfälle',
  c_self_experience_h: 'Selbsterfahrung Stunden',
  c_self_experience_sessions_min: 'Selbsterfahrung Mindeststunden',
  c_treatment_internship_h: 'Behandlungspraktikum Stunden',
  files: 'Dateien',
  file: 'Datei',
};

export const eventTypes = {
  Course: 'Kurstermin',
  Patient: 'Patiententermin',
  Exam: 'Prüfungstermin',
  Supervision: 'Supervisionstermin',
  Group_supervision: 'Gruppensupervisionstermin',
};

@Injectable({
  providedIn: 'root',
})
export class AuditLogService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  public getAllAuditLogs(): Observable<any> {
    const id_institute = this.userService.currentUser.id_institute;
    return this.http
      .get(
        environment.authority_short +
          'auditLog/getAuditLogs.php?id_institute=' +
          id_institute
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   *
   * @description map audit log keys and values to human readable keys and values
   * @param values
   * @returns
   */
  private mapAuditLogKeys(values: any) {
    const newValues = Object.keys(values).map(async key => {
      if (
        key === 'available' ||
        key === 'mandatory' ||
        key === 'logonEnabled' ||
        key === 'published' ||
        key === 'is_founded' ||
        key === 'closed'
      ) {
        return {
          key: dbKeys[key],
          value:
            values[key] === 1 || values[key] === true || values[key] === 'true'
              ? 'Ja'
              : 'Nein',
        };
      }
      switch (key) {
        case 'eLogStatus':
          switch (values[key]) {
            case 0:
              return {
                key: dbKeys[key],
                value: 'ausstehend',
              };
            case 1:
              return {
                key: dbKeys[key],
                value: 'geprüft',
              };
            case 2:
              return {
                key: dbKeys[key],
                value: 'fehlgeschlagen',
              };
            default:
              return {
                key: dbKeys[key],
                value: values[key],
              };
          }
        case 'recurrencePattern':
          return {
            key: dbKeys[key],
            value:
              values[key] === 'daily'
                ? 'Täglich'
                : values[key] === 'weekly'
                  ? 'Wöchentlich'
                  : values[key] === 'monthly'
                    ? 'Monatlich'
                    : null,
          };
        case 'events':
          return {
            key: dbKeys[key],
            value: values[key]
              .map(event => {
                return moment(event.startDate).format('DD.MM.YYYY');
              })
              .join(', '),
          };
        case 'examTypes':
          return {
            key: dbKeys[key],
            value: values[key]?.map(examType => examType?.name).join(', '),
          };
        case 'appointments':
          return {
            key: dbKeys[key],
            value: values[key]
              .map(appointment => {
                return appointment.eventDates.map(eventDate => {
                  return moment(eventDate.startDate).format('DD.MM.YYYY');
                });
              })
              .join(', '),
          };
        case 'eventDates':
          return {
            key: dbKeys[key],
            value: values[key]
              .map(eventDate => {
                return moment(eventDate.startDate).format('DD.MM.YYYY');
              })
              .join(', '),
          };
        case 'date':
          return {
            key: dbKeys[key],
            value: values[key]
              ? moment(values[key]).format('DD.MM.YYYY')
              : null,
          };
        case 'id_role':
          let value = '';
          switch (values[key]) {
            case 1:
              value = 'Superadmin';
              break;
            case 2:
              value = 'Verwalter';
              break;
            case 3:
              value = 'Lehrpersonal';
              break;
            case 4:
              value = 'Kandidat';
              break;
            default:
              value = values[key];
              break;
          }
          return {
            key: dbKeys[key],
            value: value,
          };
        case 'lecturers':
          const lecturers = (await values[key].split(', '))?.map(
            async (lecturer: any) => {
              const lecturerParts: string[] = lecturer.split(' ');
              return await this.decryptObjectNameParts(lecturerParts);
            }
          );
          return {
            key: dbKeys[key],
            value: (await Promise.all(lecturers)).join(', '),
          };
        case 'courseStudents':
          const courseStudents = (await values[key].split(', '))?.map(
            async (student: any) => {
              const studentParts: string[] = student.split(' ');
              return await this.decryptObjectNameParts(studentParts);
            }
          );
          return {
            key: dbKeys[key],
            value: (await Promise.all(courseStudents)).join(', '),
          };
        default:
          if (dbKeys[key]) {
            return {
              key: dbKeys[key],
              value: values[key],
            };
          } else {
            return { key: key, value: values[key] };
          }
      }
    });

    return newValues.filter(value => value !== null);
  }

  public async parseAuditLogObject(auditLog: any): Promise<any> {
    switch (auditLog.dbTable.toLowerCase()) {
      case 'user':
        auditLog.dbTable =
          auditLog.action === 'update' ? 'Stammdaten' : 'Benutzer';
        const lecturerParts: string[] = auditLog.objectName.split(' ');
        const decryptedLectuer =
          await this.decryptObjectNameParts(lecturerParts);
        auditLog.subtitle = 'von ' + decryptedLectuer;
        break;
      case 'course':
        auditLog.dbTable = 'Kurs';
        auditLog.subtitle = auditLog.objectName;
        break;
      case 'elog':
        auditLog.dbTable = 'eLog';

        const eLogObjectNameParts: string[] = auditLog.objectName.split(' ');
        const decryptedString: string = await this.decryptObjectNameParts(
          eLogObjectNameParts.slice(0, 2)
        );
        const concatenatedString: string = [
          decryptedString,
          ...eLogObjectNameParts.slice(2),
        ].join(' ');

        auditLog.subtitle = 'von ' + concatenatedString;
        break;
      case 'room':
        auditLog.dbTable = 'Raum';
        auditLog.subtitle = await this.decryptObjectName(auditLog.objectName);
        break;
      case 'label':
        auditLog.dbTable = 'Label';
        auditLog.subtitle = auditLog.objectName;
        break;
      case 'education_course':
        auditLog.dbTable = 'Aus- und Weiterbildungsgang';
        auditLog.subtitle = auditLog.objectName;
        break;
      case 'rel_course_student':
        const details = JSON.parse(auditLog.objectName);
        const courseStudentNameParts: string[] = details?.user?.split(' ');
        auditLog.dbTable = await this.decryptObjectNameParts(
          courseStudentNameParts
        );

        auditLog.subtitle =
          auditLog.action === 'register'
            ? `am Kurs '${details.course}'`
            : `vom Kurs '${details.course}'`;
        break;
      case 'institute':
        auditLog.dbTable = 'Institut';
        auditLog.subtitle = auditLog.objectName;
        break;
      case 'news_article':
        auditLog.dbTable = 'des Artikels';
        auditLog.subtitle = await this.decryptObjectName(auditLog.objectName);
        break;
      case 'practical_work':
        auditLog.dbTable = 'Praktische Tätigkeit';
        auditLog.subtitle = auditLog.objectName;
        break;
      case 'self_awareness':
        auditLog.dbTable = 'Selbsterfahrung';
        auditLog.subtitle = await this.decryptObjectName(auditLog.objectName);
        break;
      case 'treatment_case':
        auditLog.dbTable = 'Behandlungsfall';
        auditLog.subtitle =
          'von ' + (await this.decryptObjectName(auditLog.objectName));
        break;
      case 'treatment_case_appointment':
        const appointmentDetails = JSON.parse(auditLog.objectName);
        auditLog.dbTable = appointmentDetails.appointmentType;
        const decryptedChiffre = await this.decryptionService.decryptString(
          appointmentDetails.chiffre
        );
        auditLog.subtitle = `für Behandlungsfall '${decryptedChiffre}'`;
        break;
      case 'event':
        const eventDetails = JSON.parse(auditLog.objectName);
        auditLog.dbTable = eventTypes[eventDetails.type];
        auditLog.subtitle = `vom ${moment(
          eventDetails.date,
          'YYYY-MM-DD HH:mm:ss'
        ).format('DD.MM.yyyy')}`;
        break;
      case 'wiki':
        auditLog.dbTable = 'des Wikiartikels';
        auditLog.subtitle = auditLog.objectName;
        break;
      default:
        break;
    }

    switch (auditLog.action.toLowerCase()) {
      case 'create':
        auditLog.action = 'Erstellung';
        break;
      case 'update':
        auditLog.action = 'Aktualisierung';
        break;
      case 'delete':
        auditLog.action = 'Löschung';
        break;
      case 'register':
        auditLog.action = 'Anmeldung';
        break;
      case 'deregister':
        auditLog.action = 'Abmeldung';
        break;
      case 'publish':
        auditLog.action = 'Veröffentlichung';
        break;
      case 'unpublish':
        auditLog.action = 'Verbergen';
        break;
      case 'cancel':
        auditLog.action = 'Absage';
        break;
      default:
        break;
    }

    if (auditLog.oldValue) {
      auditLog.oldValue = await Promise.all(
        this.mapAuditLogKeys(auditLog.oldValue)
      );
    }
    if (auditLog.newValue) {
      auditLog.newValue = await Promise.all(
        this.mapAuditLogKeys(auditLog.newValue)
      );
    }

    return {
      date: moment(auditLog.timestamp, 'YYYY-MM-DD HH:mm:ss').toDate(),
      title: `${auditLog.action} ${auditLog.dbTable}`,
      subtitle: auditLog.subtitle,
      collapsed: true,
      oldValue: auditLog.oldValue
        ? await Promise.all(
            auditLog.oldValue?.map(async value => {
              return {
                key: value.key,
                value: await this.decryptionService.decryptString(value.value),
              };
            })
          )
        : [],
      newValue: auditLog.newValue
        ? await Promise.all(
            auditLog.newValue?.map(async value => {
              return {
                key: value.key,
                value: await this.decryptionService.decryptString(value.value),
              };
            })
          )
        : [],
      responsiblePerson:
        (auditLog?.actor?.name?.firstname &&
          (await this.decryptionService.decryptString(
            auditLog?.actor?.name?.firstname
          ))) +
        ' ' +
        (auditLog?.actor?.name?.lastname &&
          (await this.decryptionService.decryptString(
            auditLog?.actor?.name?.lastname
          ))),
    };
  }

  private async decryptObjectName(objectName: string): Promise<string> {
    try {
      const decryptedTreatmentCase = objectName
        ? (objectName = await this.decryptionService.decryptString(objectName))
        : '';

      return decryptedTreatmentCase;
    } catch (error) {
      return objectName;
    }
  }

  private async decryptObjectNameParts(
    objectNameParts: string[]
  ): Promise<string> {
    try {
      const decryptedObjectNamePartsPromises: Promise<string>[] =
        objectNameParts.map(
          async part => await this.decryptionService.decryptString(part)
        );

      const decryptedObjectNameParts: string[] = await Promise.all(
        decryptedObjectNamePartsPromises
      );

      return decryptedObjectNameParts.join(' ');
    } catch (error) {
      return objectNameParts.join(' ');
    }
  }
}
