import { Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';

@Injectable({
  providedIn: 'root',
})
export class DecryptionService {
  constructor(private cryptoService: CryptoService) {}
  /**
   * decryptString
   * decrypts the encrypted string with the crypto service
   * @param encryptedString
   * @returns
   */
  public async decryptString(encryptedString: string): Promise<string> {
    let decryptedString = '';
    try {
      decryptedString = await this.cryptoService.decrypt(encryptedString);
    } catch (error) {
      decryptedString = encryptedString;
    }
    return decryptedString;
  }
}
