<div class="edit-master-data-dialog flex-c">
  <!-- HEADER -->
  <div class="header flex-r">
    <span class="hasomed-text-h1">Institut und Rolle ändern</span>
    <button
      type="button"
      class="hasomed-button-icon"
      matTooltip="Abbrechen"
      (click)="onCancel()">
      <i class="fa-light fa-x"></i>
    </button>
  </div>

  <!-- FORMULAR -->
  <form class="edit-master-data-form" [formGroup]="editUserForm">
    <div class="flex-c gap-24 scrollable-content">
      <!-- Anrede & Titel -->
      <div class="flex-r gap-8">
        <!-- Anrede -->
        <div class="formular-row flex-grow">
          <label for="genderTitle" class="formular-row-label">
            <div class="label-title">Anrede</div>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <input
                id="genderTitle"
                class="hasomed-text-input flex-grow"
                formControlName="genderTitle"
                placeholder="Anrede" />
            </div>
          </div>
        </div>

        <!-- Titel -->
        <div class="formular-row flex-grow">
          <label for="academicTitle" class="formular-row-label">
            <div class="label-title">Titel</div>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <input
                id="academicTitle"
                class="hasomed-text-input flex-grow"
                formControlName="academicTitle"
                placeholder="Titel" />
            </div>
          </div>
        </div>
      </div>

      <!-- Vor- und Nachname -->
      <div class="flex-r gap-8">
        <!-- Vorname -->
        <div class="formular-row flex-grow">
          <label for="firstname" class="formular-row-label">
            <div class="label-title">Vorname</div>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <input
                id="firstname"
                class="hasomed-text-input flex-grow"
                formControlName="firstname"
                placeholder="Vorname" />
            </div>
          </div>
        </div>

        <!-- Nachname -->
        <div class="formular-row flex-grow">
          <label for="lastname" class="formular-row-label">
            <div class="label-title">Nachname</div>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <input
                id="lastname"
                class="hasomed-text-input flex-grow"
                formControlName="lastname"
                placeholder="Nachname" />
            </div>
          </div>
        </div>
      </div>

      <!-- Email -->
      <div class="formular-row">
        <label for="email" class="formular-row-label">
          <div class="label-title">Email</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="email"
              class="hasomed-text-input flex-grow"
              formControlName="email"
              placeholder="Email" />
          </div>
        </div>
      </div>

      <!-- Institute -->
      <div class="formular-row">
        <label class="formular-row-label">
          <div class="label-title">Institut</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <mat-form-field class="hasomed-select">
              <mat-select
                class="hasomed-select"
                formControlName="institute"
                placeholder="Institut">
                <mat-option [value]="null">-- Kein Institut --</mat-option>
                <mat-option
                  *ngFor="let institute of availableInstitutes"
                  [value]="institute.id">
                  {{ institute.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Role -->
      <div class="formular-row">
        <label class="formular-row-label">
          <div class="label-title">Rolle</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <mat-form-field class="hasomed-select">
              <mat-select
                class="hasomed-select"
                formControlName="role"
                placeholder="Rolle">
                <mat-option [value]="null">-- Keine Rolle --</mat-option>
                <mat-option [value]="1">Superadmin </mat-option>
                <mat-option [value]="2">Verwaltung </mat-option>
                <mat-option [value]="3">Lehrpersonal </mat-option>
                <mat-option [value]="4">Kandidat*in </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- BUTTONS -->
      <div class="formular-buttons flex-r">
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white"
          (click)="onCancel()">
          <span class="hasomed-button-text"> Abbrechen </span>
        </button>
        <button
          type="submit"
          class="hasomed-text-button hasomed-button-primary"
          [disabled]="!editUserForm.valid"
          (click)="onSave()">
          <i class="hasomed-button-icon-sm fa-light fa-floppy-disk"></i>
          <span class="hasomed-button-text">Speichern</span>
        </button>
      </div>
    </div>
  </form>
</div>
