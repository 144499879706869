import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EditMasterDataDialogComponent } from 'src/app/components/shared-components/edit-master-data-dialog/edit-master-data-dialog.component';
import { Feature, Permission } from 'src/app/models/permission.model';
import { Course } from 'src/app/models/course.model';
import { EmptyStateDialogComponent } from 'src/app/components/shared-components/empty-state-dialog/empty-state-dialog.component';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-lecturer-detail',
  templateUrl: './lecturer-detail.component.html',
  styleUrls: ['./lecturer-detail.component.scss'],
})
export class LecturerDetailComponent implements OnInit, OnDestroy {
  public lecturer: User;

  public displayedColumns: string[] = [
    'courseTitle',
    'dates',
    'eLogStatus',
    'edit',
  ];
  public dataSource: MatTableDataSource<Course> = new MatTableDataSource();
  public tableData: Subject<Course[]> = new Subject();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /* for permission */
  feature = Feature;
  permission = Permission;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    const user_id = +atob(this.activatedRoute.snapshot.paramMap.get('id'));
    this.getLecturer(user_id);

    this.tableData.subscribe((courses: Course[]) => {
      this.dataSource = new MatTableDataSource<Course>(courses);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'courseTitle':
            return item.title;
          case 'dates':
            return item.startDate;
          case 'eLogStatus':
            // sort failed on top, followed by checked and then followed by pending
            if (item.eLogStatusCounts.unexcused > 0) {
              return item.eLogStatusCounts.unexcused * 1000;
            } else if (item.eLogStatusCounts.checked > 0) {
              return item.eLogStatusCounts.checked * 100;
            } else if (item.eLogStatusCounts.pending > 0) {
              return item.eLogStatusCounts.pending * 10;
            } else {
              return item.eLogStatusCounts.upcoming * 1;
            }
          default:
            return item[property];
        }
      };

      this.isLoading = false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  /**
   * getLecturer
   * @description get the lecturer by id
   * @param user_id
   */
  private getLecturer(user_id: number): void {
    this.userService
      .getUserById(user_id, 'courses')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getUserById Backend response', response);
          if (!response.success) {
            console.error(response.message);
            this.isLoading = false;
            return;
          }
          this.lecturer = await this.userService.parseBackendUser(
            response.data
          );
          this.tableData.next(
            response.data.courses?.map((course: Course) => {
              return course;
            })
          );
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  /**
   * goBack
   * @description go back to the previous page
   * @returns void
   */
  public goBack(): void {
    this.location.back();
  }

  /**
   * openElog
   * @description open the eLog page
   * @param course_id
   * @returns void
   */
  public openELog(course_id: number): void {
    this.router.navigate(['/eleguide/education/elog'], {
      queryParams: { course_id: btoa(String(course_id)) },
    });
  }

  /**
   * deleteCourse
   * @description delete the course
   * @param id course id
   */
  public deleteCourse(id: number): void {
    console.log('deleteCourse');
  }

  /**
   * openEditMasterDataDialog
   * opens a dialog to edit the master data of the student
   * @returns void
   */
  public openEditMasterDataDialog(): void {
    const dialogRef = this.dialog.open(EditMasterDataDialogComponent, {
      data: { user: this.lecturer, isLecturer: true },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        console.debug('master data changed', result);
      }
    });
  }

  /**
   * onTerminateLecturerContract
   * terminates the lecturer contract
   */
  public onTerminateLecturerContract() {
    this.dialog.open(EmptyStateDialogComponent);
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
