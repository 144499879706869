import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-detail-dialog',
  templateUrl: './image-detail-dialog.component.html',
  styleUrls: ['./image-detail-dialog.component.scss'],
})
export class ImageDetailDialogComponent {
  public profilePicture: any;
  constructor(
    public dialogRef: MatDialogRef<ImageDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { image: any; viewOnly?: boolean }
  ) {
    this.profilePicture = data.image;
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onDelete(): void {
    this.dialogRef.close('delete');
  }
}
