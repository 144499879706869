<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Kurserstellung & Prüfungen</h1>
      <h3 class="hasomed-text-h3">Anlegen des Kursregisters</h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        *ngIf="false"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>

      <!-- Go back button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onGoBack()">
        <i class="fa-light fa-arrow-left hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Zurück</span>
      </button>

      <!-- Continue button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onContinue()">
        <i class="fa-light fa-arrow-right hasomed-button-icon-sm"> </i>
        <span class="hasomed-button-text">Weiter</span>
      </button>
    </div>
  </div>

  <div *ngIf="instituteService.currentInstitute" class="create-course flex-r">
    <button
      type="button"
      class="hasomed-text-button hasomed-button-primary"
      (click)="onCreateCourse()">
      <i class="fa-light fa-calendar-plus hasomed-button-icon-sm"> </i>
      <span class="hasomed-button-text">Neuen Kurs anlegen</span>
    </button>
  </div>

  <!-- LOADING -->
  <div *ngIf="isLoading" class="loading-wrapper">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="32">
    </mat-progress-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <span *ngIf="!instituteService.currentInstitute" class="hasomed-text-h3">
      Gebe Sie dem Institut zunächst einen Namen und speichern Sie die Daten,
      bevor Sie das Kursregister anlegen!
    </span>
    <app-onb-course-creation-table
      *ngIf="instituteService.currentInstitute"></app-onb-course-creation-table>
  </div>
</div>
