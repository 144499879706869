import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Course, CourseEvent } from 'src/app/models/course.model';
import { eLog, eLogStatus } from 'src/app/models/elog.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { ElogService } from 'src/app/services/elog.service';
import { UserService } from 'src/app/services/user.service';
import {
  getFullLecturerNames,
  getEducationCourseTitles,
} from 'src/app/utils/course.utils';
import { getParticipantAmount } from 'src/app/utils/event.utils';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-elog',
  templateUrl: './elog.component.html',
  styleUrl: './elog.component.scss',
})
export class ElogComponent implements OnInit, OnDestroy {
  public courseIsLoading = true;
  public course: Course;
  private eventId: number;
  public eventDataSource: MatTableDataSource<CourseEvent> =
    new MatTableDataSource<CourseEvent>();
  public eventColumnsToDisplay = [
    'date',
    'time',
    'room',
    'participantAmount',
    'eLog',
  ];
  public eLogIsLoading = true;
  public eLogDataSource: MatTableDataSource<eLog> = new MatTableDataSource();
  public eLogColumnsToDisplay = [
    'select',
    'studentName',
    'userIdentifier',
    'eLogStatus',
    'actions',
  ];
  public eLogStatus = eLogStatus;
  public isSetELogStatusDisabled: boolean = true;
  public setStatusTooltip: string = 'Anwesenheitsstatus setzen';
  public selectedCourseEvent: CourseEvent;
  public selectedCourseEventElogs: eLog[];
  public eLogs: eLog[];
  public selection: SelectionModel<eLog> = new SelectionModel<eLog>();
  public initialSelection: eLog[] = [];

  // Helper functions from utils.ts
  public getFullLecturerNames = getFullLecturerNames;
  public getEducationCourseTitles = getEducationCourseTitles;
  public getParticipantAmount = getParticipantAmount;

  public searchForm = this.formBuilder.group({
    searchText: ['', noWhitespaceValidator],
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private elogService: ElogService,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    const id_course = +atob(
      this.activatedRoute.parent?.snapshot.paramMap.get('id')
    );
    this.eventId = +atob(this.activatedRoute.snapshot.paramMap.get('eventId'));
    this.getCourseById(id_course);
    this.getElogsByCourseEventId(this.eventId);

    const allowMultiSelect = true;
    this.selection = new SelectionModel<eLog>(
      allowMultiSelect,
      this.initialSelection
    );
  }

  /**
   * getCourseById
   * loads the course by the given id_course
   * @param id_course
   */
  private getCourseById(id_course: number): void {
    this.courseService
      .getCourseById(id_course, 'educationCourses,courseEvents')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('courses Backend response', response);
          if (!response.success) {
            this.alertService.showErrorAlert(
              'Fehler',
              'Kurs konnten nicht geladen werden'
            );
            this.courseIsLoading = false;
          }
          this.course = await this.courseService.parseBackendCourse(
            response.data
          );
          this.selectedCourseEvent = this.course.courseEvents.find(
            it => it.id === this.eventId
          );
          this.eventDataSource.data = this.course.courseEvents.filter(
            it => it.id === this.eventId
          );
          this.courseIsLoading = false;
        },
        error: error => {
          console.error('courses Backend error', error);
          this.alertService.showErrorAlert(
            'Fehler',
            'Kurs konnten nicht geladen werden'
          );
          this.courseIsLoading = false;
        },
      });
  }

  /**
   * getElogsByCourseEventId
   * loads the eLogs by the given eventId
   * @param eventId
   */
  private getElogsByCourseEventId(eventId: number) {
    this.elogService.getAllCourseEventElogs(eventId).subscribe({
      next: async result => {
        console.debug('getAllCourseEventElogs Data', result);
        if (!result.success) {
          console.error(result.message);
          this.alertService.showErrorAlert(
            'Fehler beim Laden der Anwesehnheiten',
            'Die Anwesehnheiten konnten nicht geladen werden.'
          );
          this.eLogIsLoading = false;

          return;
        }

        this.eLogs = result.data
          ? await Promise.all(
              result.data.map(async (eLog: eLog) => {
                eLog.student = await this.userService.parseBackendUser(
                  eLog.student
                );
                return eLog;
              })
            )
          : [];
        this.eLogDataSource.data = this.eLogs;

        this.eLogDataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'studentName':
              return (
                item.student.name.firstname + ' ' + item.student.name.lastname
              );
            case 'userIdentifier':
              return item.student.userIdentifier;
            default:
              return item[property];
          }
        };

        this.eLogDataSource.filterPredicate = (data, filter) => {
          const dataStr =
            data.student.name.firstname.toLowerCase() +
            ' ' +
            data.student.name.lastname.toLowerCase();
          return dataStr.indexOf(filter) != -1;
        };
        this.eLogDataSource.sort = this.sort;
        this.eLogDataSource.paginator = this.paginator;
        this.eLogIsLoading = false;
      },
    });
  }
  /**
   * applyFilter
   * Filters the table data by the given filterValue
   * @param event
   */
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.eLogDataSource.filter = filterValue.trim().toLowerCase();
    if (this.eLogDataSource.paginator) {
      this.eLogDataSource.paginator.firstPage();
    }
  }

  /**
   * onBackToOverview
   * navigates back to the course details
   */
  public onBackToOverview() {
    this.router.navigate(['../../'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * isSingleELogStatusDisabled
   * returns true if the eLogStatus of the given eLog is disabled
   * @param eLogTableData eLogTableData
   * @returns boolean
   */
  public isSingleELogStatusDisabled(eLogTableData: eLog): boolean {
    return eLogTableData.eLogStatus === eLogStatus.UPCOMING;
  }

  /**
   * getAvailableElogs
   * returns the available eLogStatus
   * @returns eLogStatus[]
   */
  public getAvailableElogs(): eLogStatus[] {
    let availableElogs: eLogStatus[] = [];
    availableElogs.push(eLogStatus.CHECKED);

    // if course is not mandatory, add absent to availableElogs
    if (!this.course?.mandatory) {
      availableElogs.push(eLogStatus.ABSENT);
    }

    availableElogs.push(
      eLogStatus.PENDING,
      eLogStatus.EXCUSED,
      eLogStatus.UNEXCUSED
    );
    return availableElogs;
  }

  /**
   * updateSelectedUsersELogStatus
   * sets the eLogStatus of the selected students to the given eLogStatus
   * @param elogStatus
   * @returns void
   */
  public updateSelectedUsersELogStatus(elogStatus: eLogStatus) {
    //create a deep copy of the eLogs
    const updatedELogs = JSON.parse(JSON.stringify(this.eLogs));
    const selectedElogRows = this.selection.selected;

    // update eLogStatus of selected students
    selectedElogRows.forEach(selectedELog => {
      updatedELogs.find(it => it.id === selectedELog.id).eLogStatus =
        elogStatus;
    });

    // update eLogs in the backend
    this.elogService.updateElogs(updatedELogs).subscribe({
      next: result => {
        console.debug('update post data', result);
        if (!result.success) {
          console.error(result.message);
          this.alertService.showErrorAlert(
            'Anwesenheitsstatus ändern fehlgeschlagen',
            'Der Anwesenheitsstatus konnte nicht geändert werden.'
          );
          return;
        }
        this.alertService.showSuccessAlert(
          'Anwesenheitsstatus geändert',
          'Der Anwesenheitsstatus wurde erfolgreich geändert.'
        );
        // update eLogStatus in current eLog array when api call was successful
        this.eLogs = updatedELogs;
        this.updateELogTableData();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Anwesenheitsstatus ändern fehlgeschlagen',
          'Der Anwesenheitsstatus konnte nicht geändert werden.'
        );
        console.error(error);
      },
    });
  }

  /**
   * updateSingleELogStatus
   * updates the eLogStatus of the given eLog to the given eLogStatus
   * @param user User
   * @param eLogStatus eLogStatus
   * @returns void
   */
  public updateSingleELogStatus(eLog: eLog, newElogStatus: eLogStatus): void {
    console.debug('setSingleELogStatus', eLog.id, newElogStatus);

    this.elogService.updateElog(eLog.id, newElogStatus).subscribe({
      next: result => {
        console.debug('update post data', result);

        if (!result.success) {
          console.error(result.message);
          this.alertService.showErrorAlert(
            'Anwesenheitsstatus ändern fehlgeschlagen',
            'Der Anwesenheitsstatus konnte nicht geändert werden.'
          );
          return;
        }

        this.alertService.showSuccessAlert(
          'Anwesenheitsstatus geändert',
          'Der Anwesenheitsstatus wurde erfolgreich geändert.'
        );

        // update eLogStatus in current eLog array
        this.eLogs.forEach(courseEventElog => {
          if (courseEventElog.id === eLog.id) {
            courseEventElog.eLogStatus = newElogStatus;
          }
        });

        this.updateELogTableData();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Anwesenheitsstatus ändern fehlgeschlagen',
          'Der Anwesenheitsstatus konnte nicht geändert werden.'
        );
        console.error(error.message);
      },
    });
  }

  /**
   * updateELogTableData
   * updates the eLog table data
   * @returns void
   */
  private updateELogTableData(): void {
    const previousSelection = this.selection.selected;

    this.eLogDataSource.data = this.eLogs;
    this.eLogDataSource.sort = this.sort;

    this.selection.clear();

    previousSelection.forEach(selectedELog => {
      this.selectRow(
        this.eLogDataSource.data.find(it => it.id === selectedELog.id)
      );
    });

    this.updateEventTableData();
    this.updateIsSetElogStatusDisabled();
  }

  /**
   * updateEventTableData
   * updates the data of the event table
   */
  private updateEventTableData(): void {
    this.course.courseEvents.find(
      it => it.id === this.eventId
    ).eLogStatusCounts = {
      pending: this.eLogs.filter(it => it.eLogStatus === eLogStatus.PENDING)
        .length,
      checked: this.eLogs.filter(it => it.eLogStatus === eLogStatus.CHECKED)
        .length,
      absent: this.eLogs.filter(it => it.eLogStatus === eLogStatus.ABSENT)
        .length,
      excused: this.eLogs.filter(it => it.eLogStatus === eLogStatus.EXCUSED)
        .length,
      unexcused: this.eLogs.filter(it => it.eLogStatus === eLogStatus.UNEXCUSED)
        .length,
      upcoming: this.eLogs.filter(it => it.eLogStatus === eLogStatus.UPCOMING)
        .length,
    };

    this.eventDataSource.data = this.course.courseEvents.filter(
      it => it.id === this.eventId
    );
  }

  /**
   * updateIsSetElogStatusDisabled
   * updates the isSetELogStatusDisabled and setStatusTooltip
   * @returns void
   */
  private updateIsSetElogStatusDisabled() {
    if (this.selection.selected.length === 0) {
      this.isSetELogStatusDisabled = true;
      this.setStatusTooltip =
        'Bitte wählen Sie mindestens einen Kandidat*in aus';
      return;
    } else if (this.selectedCourseEvent?.startDate > new Date()) {
      this.isSetELogStatusDisabled = true;
      this.setStatusTooltip =
        'Anwesenheitsstatus kann erst nach stattfinden des Kurses gesetzt werden';
      return;
    } else {
      this.isSetELogStatusDisabled = false;
      this.setStatusTooltip = 'Anwesenheitsstatus setzen';
    }
  }

  /**
   * isAllSelected
   * Whether the number of selected elements matches the total number of rows
   * @returns boolean
   */
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.eLogDataSource.data.length;
    return numSelected == numRows;
  }

  /**
   * toggleAllRows
   * Selects all rows if they are not all selected; otherwise clear selection
   * @returns void
   */
  public toggleAllRows(): void {
    console.log(this.eLogDataSource.data);
    this.isAllSelected()
      ? this.selection.clear()
      : this.eLogDataSource.data.forEach(row => this.selection.select(row));
    this.updateIsSetElogStatusDisabled();
  }

  /**
   * selectRow
   * selects the given row
   * @param row
   * @returns void
   */
  public selectRow(row: eLog) {
    this.selection.toggle(row);
    this.updateIsSetElogStatusDisabled();
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
