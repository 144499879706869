import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Feature, Permission } from 'src/app/models/permission.model';
import { WikiPage } from 'src/app/models/wiki.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { WikiService } from 'src/app/services/wiki.service';

@Component({
  selector: 'app-wiki',
  templateUrl: './wiki.component.html',
  styleUrls: ['./wiki.component.scss'],
})
export class WikiComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public showDrafts = false;
  public selectedPage: WikiPage;
  public searchForm = new FormGroup({
    searchText: new FormControl(''),
  });
  public wikiPages: WikiPage[];
  public filteredWikiPages: WikiPage[];
  public feature = Feature;
  public permission = Permission;
  @ViewChild('articleContainer') articleContainer: ElementRef;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private wikiService: WikiService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.wikiService
      .getAllWikiPages()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getAllWikiPages', response);

          if (!response.success) {
            this.alertService.showErrorAlert(
              'Fehler',
              'Fehler beim Laden der Wikiartikel.'
            );
            console.error('Error loading wiki pages', response.message);
            return;
          }

          if (response.data) {
            this.wikiPages = response.data
              ? await Promise.all(
                  response.data?.map((page: any) => {
                    return this.wikiService.parseBackendWikiPage(page);
                  })
                )
              : [];
            this.filteredWikiPages = this.wikiPages;
            this.selectedPage = this.wikiPages[0];
          } else {
            this.wikiPages = [];
            this.filteredWikiPages = [];
            this.selectedPage = null;
          }
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Fehler',
            'Fehler beim Laden der Wikiartikel.'
          );
          console.error(error.message);
        },
      });
  }

  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const trimmedFilterValue = filterValue.trim().toLowerCase();
    this.filteredWikiPages = this.wikiPages.filter(it =>
      it.title.toLowerCase().includes(trimmedFilterValue)
    );
    if (this.filteredWikiPages.length > 0) {
      this.scrollToTop();
      this.selectedPage = this.filteredWikiPages[0];
    } else {
      this.selectedPage = null;
    }
  }

  private scrollToTop() {
    const element = this.articleContainer.nativeElement;
    element.scrollTop = 0;
  }

  public onCreate() {
    this.router.navigate(['create'], { relativeTo: this.activatedRoute });
  }

  public onEdit(wikiPage: WikiPage) {
    this.router.navigate(['edit', btoa(wikiPage.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  public onSelectPage(wikiPage: WikiPage) {
    //console.debug('WikiComponent.onSelectPage', wikiPage);
    this.scrollToTop();
    this.selectedPage = wikiPage;
  }

  public getSanitizedArticleContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.selectedPage?.content);
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
