import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { EventType } from 'src/app/models/event.model';
import { Room } from 'src/app/models/room.model';
import { AlertService } from 'src/app/services/alert.service';
import { EventService } from 'src/app/services/event.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-calendar-event-detail',
  templateUrl: './calendar-event-detail.component.html',
  styleUrls: ['./calendar-event-detail.component.scss'],
})
export class CalendarEventDetailComponent {
  public eventType = EventType;
  public eventForm: FormGroup;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private dialog: MatDialog,
    private alertService: AlertService,
    public userService: UserService,
    public dialogRef: MatDialogRef<CalendarEventDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id_eventDate: number;
      id_treatmentCase?: number;
      id_course?: number;
      title: string;
      lecturer?: string;
      chiffre?: string;
      supervisor?: string;
      treatmentCaseStudent?: string;
      type: EventType;
      room: Room;
      startDate: Date;
      endDate: Date;
      canceled: boolean;
      isTreatmentCaseView?: boolean;
    }
  ) {
    console.debug('event detail data: ', data);
  }

  ngOnInit() {
    moment.locale('de');

    const formattedDate =
      moment(this.data.startDate).format('dd') +
      ', ' +
      moment(this.data.startDate).format('DD.MM.YYYY') +
      ', ' +
      moment(this.data.startDate).format('HH:mm') +
      ' - ' +
      moment(this.data.endDate).format('HH:mm') +
      ' Uhr';

    this.eventForm = new FormGroup({
      date: new FormControl(formattedDate),
      room: new FormControl(this.data.room?.name ?? 'Kein Raum'),
      chiffre: new FormControl(this.data.chiffre),
      supervisor: new FormControl(this.data.supervisor),
      lecturer: new FormControl(this.data.lecturer),
      treatmentCaseStudent: new FormControl(this.data.treatmentCaseStudent),
    });
  }

  /**
   * onManageAppointment
   * redirect to treatmentCase page
   * @returns void
   */
  public onManageAppointment(): void {
    console.debug('onManageAppointment', this.data);
    this.router.navigate(['../eleguide/education/ambulatory-part'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        id_treatment_case: btoa(this.data.id_treatmentCase.toString()),
      },
    });
    this.dialogRef.close();
  }

  /**
   * onCancelAppointment
   * cancel the appointment
   * @returns void
   */
  public onCancelAppointment(): void {
    console.debug('onCancelAppointment', this.data);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Termin Absagen',
        message: `Möchten Sie den Termin wirklich absagen?`,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.eventService.cancelEventDate(this.data.id_eventDate).subscribe({
          next: response => {
            console.debug('eventDate Canceled:', response);

            if (response.success) {
              this.alertService.showSuccessAlert(
                `Der Termin wurde abgesagt!`,
                `Termin abgesagt`
              );

              this.data.canceled = true;
              this.dialogRef.close({ canceled: true });
            } else {
              this.alertService.showErrorAlert(
                `Fehler beim Absagen des Termins`,
                `Fehler`
              );
            }
          },
          error: error => {
            console.error(error);
          },
        });
      }
    });
  }

  /**
   * openCourseDetail
   * redirect to course detail page
   * @returns void
   */
  public onOpenCourseDetail(): void {
    this.dialogRef.close();
    this.router.navigate(
      [
        '../eleguide/education/theoretical-education/',
        btoa(this.data.id_course?.toString()),
      ],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  /**
   * onClose
   * close the dialog
   * @returns void
   */
  public onClose(): void {
    this.dialogRef.close();
  }
}
