<div class="flex-r course-details">
  <div class="flex-c flex-grow">
    <div class="flex-c course-details-main">
      <table
        mat-table
        [dataSource]="dataSource"
        class="course-details-table"
        matSort>
        <!-- lecturer column / using firstname for sorting -->
        <ng-container matColumnDef="title">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="cell-padding-24">
            Termin
            <app-custom-table-sort-icon [sort]="sort" columnName="title">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="cell-padding-24">
            <div class="flex-c users-wrapper">
              <div class="flex-r user-container">
                <div class="user-info">
                  <span class="hasomed-text-body">
                    {{ row.type }}
                  </span>
                  <span class="user-info hasomed-text-small">
                    <div *ngIf="row.type == 'Patientensitzung'">
                      {{ selectedTreatmentCase.chiffre }}
                    </div>
                    <div
                      *ngIf="
                        row.type == 'Supervision' ||
                        row.type == 'Gruppensupervision'
                      ">
                      {{ row.supervisor?.name?.academicTitle }}
                      {{ row.supervisor?.name?.firstname }}
                      {{ row.supervisor?.name?.lastname }}
                      <span *ngIf="row.supervisor && row.supervisorText"
                        >,
                      </span>
                      <span *ngIf="row.supervisorText">
                        {{ row.supervisorText }}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- date column / using startDate for Sorting-->
        <ng-container matColumnDef="startDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="center-header-cell">
            Wann
            <app-custom-table-sort-icon [sort]="sort" columnName="startDate">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="center-cell-text">
            <div
              class="flex-c"
              matTooltip="{{ row.startDate | date: 'dd.MM.yyyy HH:mm' }} - {{
                row.endDate | date: 'HH:mm'
              }}">
              <span>{{ row.startDate | date: 'dd.MM.yyyy' }}</span>
            </div>
          </td>
        </ng-container>

        <!-- canceled column -->
        <ng-container matColumnDef="canceled">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="center-header-cell">
            Abgesagt
            <app-custom-table-sort-icon [sort]="sort" columnName="canceled">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="center-cell-text">
            {{ row.canceled ? 'ja' : '-' }}
          </td>
        </ng-container>

        <!-- room column -->
        <ng-container matColumnDef="room">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="center-header-cell">
            Raum
            <app-custom-table-sort-icon [sort]="sort" columnName="room">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="center-cell-text">
            {{ row.room?.name ?? '-' }}
          </td>
        </ng-container>

        <!-- supervisors column -->
        <ng-container matColumnDef="supervisors">
          <th mat-header-cell *matHeaderCellDef class="center-header-cell">
            Supervisor*innen
          </th>
          <td mat-cell *matCellDef="let row" class="center-cell-text">
            <span
              *ngFor="
                let supervisor of selectedTreatmentCase.supervisors;
                let last = last
              ">
              {{ supervisor.name.academicTitle }}
              {{ supervisor.name.firstname }} {{ supervisor.name.lastname
              }}<span *ngIf="!last">, </span>
            </span>
            <span
              *ngIf="
                selectedTreatmentCase.supervisors?.length > 0 &&
                selectedTreatmentCase.supervisorText
              "
              >,
            </span>
            <span *ngIf="selectedTreatmentCase.supervisorText">
              {{ selectedTreatmentCase.supervisorText }}
            </span>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="center-header-cell">
            Optionen
          </th>
          <td mat-cell *matCellDef="let row" class="center-cell-text">
            <div class="table-button-icons">
              <button
                type="button"
                matTooltip="Details ansehen"
                class="table-button-icon"
                (click)="onViewAppointment(row)">
                <i class="fa-light fa-arrow-up-right-from-square"></i>
              </button>
              <button
                type="button"
                matTooltip="Termin absagen"
                [disabled]="row.canceled"
                class="table-button-icon"
                (click)="onCancelAppointment(row)">
                <i class="fa-light fa-ban"></i>
              </button>
              <button
                type="button"
                matTooltip="Termin löschen"
                class="table-button-icon"
                (click)="onDeleteAppointment(row)">
                <i class="fa-light fa-trash-can"></i>
              </button>
              <button
                type="button"
                matTooltip="Termin bearbeiten"
                class="table-button-icon"
                (click)="onEditAppointment(row)">
                <i class="fa-light fa-edit"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>

        <tr *matNoDataRow>
          <td class="gray-600" [attr.colspan]="columnsToDisplay.length">
            <div class="mat-cell table-no-match hasomed-text-body font-grey">
              Keine Termine gefunden
            </div>
          </td>
        </tr>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource?.filteredData?.length"
        [pageSize]="5"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
