import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import {
  TherapyForm,
  TreatmentCase,
} from 'src/app/models/treatment-case.model';
import { User } from 'src/app/models/user.model';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { UserService } from 'src/app/services/user.service';
import { CreateEditPatientAppointmentComponent } from '../create-edit-patient-appointment/create-edit-patient-appointment.component';
import * as moment from 'moment';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { isRequired } from 'src/app/utils/form.utils';
import { AlertService } from 'src/app/services/alert.service';
import { chiffreValidator } from 'src/app/validators/chiffre.validator';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { KeyValue } from '@angular/common';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-create-edit-treatment-case',
  templateUrl: './create-edit-treatment-case.component.html',
  styleUrls: ['./create-edit-treatment-case.component.scss'],
})
export class CreateEditTreatmentCaseComponent implements OnInit, OnDestroy {
  public selectedDuration = '45';
  public editMode = false;
  public currentTreatmentCase: TreatmentCase;
  public treatmentCaseForm: FormGroup;
  public supervisors: User[];
  public selectedSupervisorIds: number[];
  public uploadedFiles: File[] = [];
  public selectedTherapyType: string;
  public selectedProcedures: string[any];
  public availableTherapyTypes: { id: number; name: string }[];
  public availableProcedures: { id: number; name: string }[];
  public isLoading = true;
  public initialFormValues: {};
  public TherapyForm = TherapyForm;

  public requiredFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  // import from form.utils.ts
  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.treatmentCaseForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private treatmentCaseService: TreatmentCaseService,
    private fb: FormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.getSupervisors();
    this.getTherapyTypes();
    this.getProcedures();

    this.treatmentCaseForm = this.fb.group({
      chiffre: new FormControl('', [
        Validators.required,
        chiffreValidator(),
        Validators.maxLength(100),
      ]),
      age: new FormControl('', [
        Validators.required,
        maxNumberLength(2),
        positiveNumbersOnlyValidator(true),
      ]),
      therapyForm: new FormControl(TherapyForm.INDIVIDUAL, Validators.required),
      therapyType: new FormControl(null, Validators.required),
      procedures: new FormControl('', Validators.required),
      supervisors: new FormControl(null, Validators.required),
      supervisorSearch: new FormControl(null),
      internalNote: new FormControl('', Validators.maxLength(6000)),
    });

    if (this.activatedRoute.snapshot.params['id']) {
      this.editMode = true;
      const id = +atob(this.activatedRoute.snapshot.params['id']);
      this.getTreatmentCase(id);
    }

    if (!this.editMode) {
      this.isLoading = false;
      this.selectedSupervisorIds = [];
      this.initialFormValues = this.treatmentCaseForm.value;
    }
  }

  /**
   *  getSupervisors
   * @description get all institute supervisors and save them in this.supervisors array
   */
  private getSupervisors() {
    const id_institute = this.userService.currentUser.id_institute;
    this.userService.getInstituteSupervisors(id_institute).subscribe({
      next: async response => {
        console.debug('get supervisors backend response:', response);
        if (!response.success) {
          console.error(response.message);
          return;
        }
        this.supervisors = response.data
          ? await Promise.all(
              response.data?.map(async (userData: User): Promise<User> => {
                return await this.userService.parseBackendUser(userData);
              })
            )
          : [];
      },
    });
  }

  private getTherapyTypes() {
    this.treatmentCaseService
      .getTherapyTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          console.debug('TherapyTypes BackendData:', response);
          if (response.success) {
            this.availableTherapyTypes = response.data;
          }
        },
      });
  }

  private getProcedures() {
    this.treatmentCaseService
      .getProcedures()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          console.debug('Procedures BackendData:', response);
          if (response.success) {
            this.availableProcedures = response.data;
          }
        },
      });
  }

  private getTreatmentCase(id: number) {
    this.treatmentCaseService
      .getTreatmentCase(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async result => {
          console.debug('TreatmentCase BackendData', result);
          if (!result.success) {
            this.alertService.showErrorAlert(
              'Fehler',
              'Der Behandlungsfall konnte nicht geladen werden!'
            );
            this.onCancel();
            return;
          }
          this.currentTreatmentCase =
            await this.treatmentCaseService.parseBackendTreatmentCase(
              result.data
            );
          console.log('currentTreatmentCase', this.currentTreatmentCase);
          this.isLoading = false;
          this.selectedSupervisorIds =
            this.currentTreatmentCase.supervisors?.map(it => it.id);

          this.treatmentCaseForm.patchValue({
            chiffre: this.currentTreatmentCase.chiffre,
            age: this.currentTreatmentCase.age,
            therapyForm: this.currentTreatmentCase.therapyForm,
            therapyType: this.currentTreatmentCase.therapyType?.id,
            procedures: this.currentTreatmentCase.procedures?.map(it => it.id),
            supervisors: this.selectedSupervisorIds,
            internalNote: this.currentTreatmentCase.internalNote,
          });
          this.initialFormValues = this.treatmentCaseForm.value;
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
          this.onCancel();
        },
      });
  }

  /* on button click update existing or create new course and navigate back */
  public onSubmit() {
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.treatmentCaseForm
      );

    if (formInvalid) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.treatmentCaseForm.value,
        this.initialFormValues
      )
    ) {
      this.onCancel();
      return;
    }
    this.editMode ? this.updateTreatmentCase() : this.createTreatmentCase();
  }

  private async createTreatmentCase() {
    const treatmentCase: any = {
      studentId: this.userService.currentUser.id,
      chiffre: this.treatmentCaseForm.value.chiffre,
      age: this.treatmentCaseForm.value.age,
      therapyForm: this.treatmentCaseForm.value.therapyForm,
      therapyType: this.treatmentCaseForm.value.therapyType
        ? this.availableTherapyTypes.find(
            therapyType =>
              therapyType.id === this.treatmentCaseForm.value.therapyType
          )
        : null,
      procedures: this.treatmentCaseForm.value.procedures.map(id => {
        return this.availableProcedures.find(procedure => procedure.id === id);
      }),
      supervisors: this.selectedSupervisorIds.map(id => {
        return this.supervisors.find(supervisor => supervisor.id === id);
      }),
      instituteId: this.userService.currentUser.id_institute,
      appointments: this.currentTreatmentCase?.appointments?.map(
        appointment => {
          return {
            appointmentType: { id: 1, name: 'Patientensitzung' },
            startDate: moment(appointment.startDate).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            endDate: moment(appointment.endDate).format('YYYY-MM-DD HH:mm:ss'),
            timeUnits: appointment.timeUnits,
            recurrencePattern: appointment.recurrencePattern,
          };
        }
      ),
      internalNote: this.treatmentCaseForm.value.internalNote,
    };

    const observable =
      await this.treatmentCaseService.createTreatmentCase(treatmentCase);

    observable.subscribe({
      next: data => {
        console.debug('TreatmentCase Created:', data);
        if (!data.success) {
          this.alertService.showErrorAlert(
            'Fehler',
            'Der Fall konnte nicht erstellt werden!'
          );
          return;
        }
        this.alertService.showSuccessAlert(
          'Fall erstellt',
          'Der Fall wurde erstellt!'
        );
        this.initialFormValues = this.treatmentCaseForm.value;
        this.onCancel();
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          'Der Fall konnte nicht erstellt werden!'
        );
      },
    });
  }

  private async updateTreatmentCase() {
    const treatmentCase: any = {
      id: this.currentTreatmentCase.id,
      studentId: this.userService.currentUser.id,
      chiffre: this.treatmentCaseForm.value.chiffre,
      age: this.treatmentCaseForm.value.age,
      therapyForm: this.treatmentCaseForm.value.therapyForm,
      therapyType: this.treatmentCaseForm.value.therapyType
        ? this.availableTherapyTypes.find(
            therapyType =>
              therapyType.id === this.treatmentCaseForm.value.therapyType
          )
        : null,
      procedures: this.treatmentCaseForm.value.procedures.map(id => {
        return this.availableProcedures.find(procedure => procedure.id === id);
      }),
      instituteId: this.userService.currentUser.id_institute,
      internalNote: this.treatmentCaseForm.value.internalNote,
    };

    const updateObservable =
      await this.treatmentCaseService.updateTreatmentCase(treatmentCase);

    updateObservable.subscribe({
      next: response => {
        console.debug('TreatmentCase Updated:', response);

        if (!response.success) {
          this.alertService.showErrorAlert(
            'Fehler',
            'Der Fall konnte nicht aktualisiert werden!'
          );
          return;
        }

        this.alertService.showSuccessAlert(
          'Fall wurde aktualisiert!',
          `Der Fall '${this.treatmentCaseForm.value.chiffre}' wurde aktualisiert!`
        );
        this.initialFormValues = this.treatmentCaseForm.value;
        this.onCancel();
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          'Der Fall konnte nicht aktualisiert werden!'
        );
      },
    });
  }

  /* on button click navigate up to parent route */
  public onCancel() {
    if (this.editMode) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

  /* save selected files in a variable */
  public onDocumentsSelected(event: any) {
    this.uploadedFiles = this.uploadedFiles.concat(
      Array.from(event.target.files)
    );
  }

  /* remove selected file on click */
  public removeFile(index: number) {
    this.uploadedFiles.splice(index, 1);
  }

  /* 
  adds or removes the clicked supervisor to the selected supervisor array 
  */
  public onSupervisorSelected(supervisor: User) {
    console.debug('selected lecturer', supervisor);
    if (this.selectedSupervisorIds.includes(supervisor.id)) {
      this.selectedSupervisorIds.splice(
        this.selectedSupervisorIds.findIndex(it => it == supervisor.id),
        1
      );
    } else {
      this.selectedSupervisorIds.push(supervisor.id);
    }
    this.treatmentCaseForm
      .get('supervisors')
      .setValue(this.selectedSupervisorIds);
  }

  /**
   * set TherapyForm
   * @param therapyForm
   */
  public setTherapyForm(therapyForm: TherapyForm) {
    this.treatmentCaseForm.get('therapyForm').setValue(therapyForm);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.treatmentCaseForm.value,
      this.initialFormValues
    );
  }

  /**
   * Preserve the original enum order
   */
  public originalTherapyFormOrder = (
    a: KeyValue<string, TherapyForm>,
    b: KeyValue<string, TherapyForm>
  ): number => {
    return 0;
  };

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
