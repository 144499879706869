export interface StudentEducationProgress {
  type: ProgressType;
  contingent: number;
  progress: number;
  procentual: number;
}

export enum ProgressType {
  THEORETICAL = 'Theoretisch Wissenschaftlich',
  PRACTICAL_1 = 'Praktische Tätigkeit §2 Abs. Nr 1',
  PRACTICAL_2 = 'Praktische Tätigkeit §2 Abs. Nr 2',
  TREATMENT = 'Behandlungspraktikum',
  SUPERVISION = 'Supervision',
  SELF_AWARENESS = 'Selbsterfahrung',
  TEACHING_ANALYSIS = 'Lehranalyse',
  TOTAL = 'Gesamt',
}
