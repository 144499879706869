import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../components/shared-components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { SessionExpiredDialogComponent } from '../components/shared-components/session-expired-dialog/session-expired-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let modifiedReq;
    // get access_token from local storage
    const authToken = localStorage.getItem('access_token');
    // clone the request and replace the original headers with
    if (!authToken) {
      modifiedReq = req.clone();
    } else {
      modifiedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    }

    return next.handle(modifiedReq).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          // Handle the HTTP error here
          console.error('HTTP Error:', error);
          if (
            (error.url === 'http://localhost:4200/api/status' ||
              error.url === 'http://localhost:4200/psyguide-backend/status') &&
            error.status === 404
          ) {
            return EMPTY;
          }
        }
        throw error;
      }),
      tap(event => {
        if (event instanceof HttpResponse) {
          //handle no auth
          if (event.status === 200 && event.body?.message === 'no auth') {
            if (
              this.dialog.openDialogs.length > 0 &&
              this.dialog.openDialogs[0].componentInstance instanceof
                SessionExpiredDialogComponent
            ) {
              return;
            }
            const dialogRef = this.dialog.open(SessionExpiredDialogComponent, {
              maxWidth: '400px',
            });

            dialogRef.afterClosed().subscribe(dialogResult => {
              if (dialogResult) {
                console.debug('Logout user');
                this.dialog.closeAll();
                this.authService.logout();
              }
            });
          } else if (
            event.status === 200 &&
            !event.body?.success &&
            !event.url
          ) {
            // console.error('Backend Error:', event.body?.message);
          }
        }
      })
    );
  }
}
