import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsArticle } from 'src/app/models/news-article.model';
import { Role } from 'src/app/models/permission.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-news-article',
  templateUrl: './news-article.component.html',
  styleUrls: ['./news-article.component.scss'],
})
export class NewsArticleComponent {
  constructor(
    public userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
  @Input() newsArticle: NewsArticle;
  @Input() isSelected = false;
  @Output() selectedNewsArticle = new EventEmitter<NewsArticle>();
  public showLabels = false;

  ngOnInit(): void {
    if (this.userService.currentUser.id_role == Role.ADMINISTRATOR) {
      this.showLabels = true;
    }
  }

  public navToArticle(newsArticle: NewsArticle) {
    this.router.navigate(['../institute-news', btoa(String(newsArticle.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onSelectNewsArticle
   * emits the selected news article
   * @param newsArticle
   * @returns void
   */
  public onSelectNewsArticle(newsArticle: NewsArticle): void {
    this.selectedNewsArticle.emit(newsArticle);
  }
}
