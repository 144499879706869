import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { Course } from 'src/app/models/course.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { InstituteService } from 'src/app/services/institute.service';
import { getFullLecturerNames } from 'src/app/utils/course.utils';

@Component({
  selector: 'app-onb-course-creation-table',
  templateUrl: './onb-course-creation-table.component.html',
  styleUrls: ['./onb-course-creation-table.component.scss'],
})
export class ONBCourseCreationTableComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = ['title', 'lecturer', 'events', 'edit'];
  public dataSource: MatTableDataSource<Course> = new MatTableDataSource();
  public isLoading = true;

  public getFullLecturerNames = getFullLecturerNames;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    public instituteService: InstituteService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    this.getInstituteCourses();
  }

  /**
   * getInstituteCourses
   */
  private getInstituteCourses() {
    if (this.instituteService.currentInstitute) {
      const id_institute = this.instituteService.currentInstitute.id;
      this.courseService
        .getInstituteCourses(id_institute, 'courseEvents')
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            console.debug('Courses Backend Data:', response);
            if (!response.success) {
              console.error(response.message);
              this.isLoading = false;
              return;
            }
            this.dataSource.data = response.data?.courses
              ? await Promise.all(
                  response.data?.courses?.map(
                    async (courseData: any): Promise<Course> => {
                      return this.courseService.parseBackendCourse(courseData);
                    }
                  )
                )
              : [];
            this.dataSource.sortingDataAccessor = (item, property) => {
              switch (property) {
                case 'title':
                  return item.title;
                case 'lecturer':
                  return item.lecturers[0].name.firstname;
                case 'events':
                  return item.startDate;
                default:
                  return item[property];
              }
            };

            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;

            this.isLoading = false;
          },
          error: error => {
            console.error(error);
          },
        });
    } else {
      this.isLoading = false;
    }
  }

  /**
   * onEditCourse
   * @param id
   */
  public onEditCourse(id: number) {
    const route =
      this.router.url === '/eleguide/onboarding/overview'
        ? '../course-creation/edit'
        : './edit';
    this.router.navigate([route, btoa(String(id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onDeleteCourse
   * @param course
   */
  public onDeleteCourse(course: Course) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Löschen',
        message: `Möchten Sie den Kurs '${course.title}' wirklich löschen?`,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.courseService.deleteCourse(course.id).subscribe({
          next: response => {
            console.debug('Course Deleted:', response);
            if (response.success) {
              this.alertService.showSuccessAlert(
                'Kurs gelöscht',
                `Der Kurs '${course.title}' wurde gelöscht!`
              );

              // delete entry from table data without reloading from backend
              const index = this.dataSource.data.indexOf(course);
              if (index > -1) {
                this.dataSource.data = this.dataSource.data.filter(
                  item => item !== course
                );
              }
            } else {
              this.alertService.showErrorAlert(
                'Kurs nicht gelöscht',
                `Der Kurs '${course.title}' konnte nicht gelöscht werden!`
              );
            }
          },
          error: error => {
            console.error(error);
          },
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
