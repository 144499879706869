<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Gebührenübersicht</h1>
      <h3 class="hasomed-text-h3">Sehen Sie Ihre Studiengebühren ein</h3>
    </div>
  </div>

  <!-- MAIN CONTENT -->
  <div class="main-content">
    <app-empty-state></app-empty-state>
    <!-- <div class="flex-r gap-16">
      <div class="flex-c flex-1">
        <div class="table">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="educationPart">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span class="gray-600">Ausbildungsteil</span>
                <app-custom-table-sort-icon
                  [sort]="sort"
                  columnName="educationPart">
                </app-custom-table-sort-icon>
              </th>
              <td mat-cell *matCellDef="let row">
                <span class="hasomed-text-body">
                  {{ row.title }}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="sum">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="center-header-cell">
                <span class="gray-600">Betrag</span>
                <app-custom-table-sort-icon [sort]="sort" columnName="sum">
                </app-custom-table-sort-icon>
              </th>
              <td mat-cell *matCellDef="let row" class="center-cell-text">
                <span class="hasomed-text-body">
                  {{ row.sum }}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="payed">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="center-header-cell">
                <span>Bezahlt</span>
                <app-custom-table-sort-icon [sort]="sort" columnName="payed">
                </app-custom-table-sort-icon>
              </th>
              <td mat-cell *matCellDef="let row" class="center-cell-text">
                <i
                  *ngIf="row.payed"
                  class="fa-light fa-square-check table-button-icon table-icon-green"></i>
                <i
                  *ngIf="!row.payed"
                  class="fa-light fa-square table-button-icon table-icon-red"></i>
              </td>
            </ng-container>

            <ng-container matColumnDef="invoice">
              <th mat-header-cell *matHeaderCellDef class="center-header-cell">
                <span>Rechnung</span>
              </th>
              <td mat-cell *matCellDef="let row" class="center-cell-text">
                <i class="fa-light fa-file-download table-button-icon"></i>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td [attr.colspan]="displayedColumns.length">
                <div *ngIf="invoicesLoading" class="loading-wrapper">
                  <mat-progress-spinner
                    color="primary"
                    mode="indeterminate"
                    diameter="32">
                  </mat-progress-spinner>
                </div>
                <div
                  *ngIf="!invoicesLoading"
                  class="mat-cell table-no-match hasomed-text-body font-grey">
                  Es wurde keine Rechnung gefunden.
                </div>
              </td>
            </tr>
          </table>
          <mat-divider></mat-divider>
          <mat-paginator
            custom-paginator
            [appCustomLength]="dataSource.filteredData.length"
            [pageSize]="10"
            [renderButtonsNumber]="1"
            aria-label="Select page"></mat-paginator>
        </div>
        <div class="vertical-spacer"></div>
      </div>

      <div
        class="flex-c gap-24 card padding-24 justify-start align-start"
        *ngIf="!bankDetailsLoading">
        <div class="flex-c gap-16">
          <span class="heading hasomed-text-small">Nächste Zahlung </span>
          <span class="hasomed-text-small" *ngIf="selectedInvoice">
            <strong>{{ selectedInvoice.sum }} €</strong>
            am {{ selectedInvoice.date | date: 'dd.MM.yyyy' }}
          </span>
          <span *ngIf="!selectedInvoice"> Keine Zahlung ausstehend </span>
        </div>
        <div class="flex-c gap-8">
          <span class="heading hasomed-text-small">Bankverbindung </span>
          <span class="hasomed-text-small">
            <span>
              {{ bankData.maskedIban }}
            </span>
            <button
              type="button"
              class="table-button-icon"
              matTooltip="Bankverbindung bearbeiten"
              (click)="editBankDetails()">
              <i class="fa-light fa-pen-to-square"></i>
            </button>
            <app-copy-to-clipboard
              *ngIf="bankData.iban"
              type="Bankverbindung"
              [textToCopy]="bankData.iban">
            </app-copy-to-clipboard>
          </span>
        </div>
        <div class="flex-c gap-8">
          <span class="heading hasomed-text-small">PiA Institutsnummer</span>
          <span class="hasomed-text-small">
            <span>
              {{ userService.currentUser.userIdentifier }}
            </span>
            <app-copy-to-clipboard
              *ngIf="userService.currentUser.userIdentifier"
              type="PiA Institutsnummer"
              [textToCopy]="userService.currentUser.userIdentifier">
            </app-copy-to-clipboard>
          </span>
        </div>
        <div class="flex-c gap-8">
          <span class="heading hasomed-text-small">Etikett</span>

          <div class="label label-green">
            <i class="fa-light fa-file-contract label-icon"></i>
            <span class="label-text">
              {{
                userService.currentUser.status?.name ??
                  'Ordnungsgemäß eingeschrieben'
              }}
            </span>
          </div>
        </div>
        <div class="flex-c gap-8">
          <span class="heading hasomed-text-small">
            Gebührenübersicht (PDF)
          </span>
          <button
            type="button"
            class="hasomed-text-button hasomed-button-primary">
            <i class="fa-light fa-file-pdf hasomed-button-icon-sm"></i>
            <span class="hasomed-button-text"> Herunterladen</span>
          </button>
        </div>
      </div>
    </div> -->
  </div>
</div>
