import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { Room } from 'src/app/models/room.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EventService } from 'src/app/services/event.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { RoomService } from 'src/app/services/room.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import { houseNumberValidator } from 'src/app/validators/house-number.validator';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';

@Component({
  selector: 'app-create-room',
  templateUrl: './create-edit-room.component.html',
  styleUrls: ['./create-edit-room.component.scss'],
})
export class CreateEditRoomComponent implements OnInit, OnDestroy {
  public roomForm: FormGroup;
  public initialFormValues: {};
  public editMode: boolean;
  public id_room: string;
  public isLoading: boolean = true;
  public currentRoom: Room;

  // import from form.utils.ts
  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.roomForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private roomService: RoomService,
    private userService: UserService,
    private dialog: MatDialog,
    private eventService: EventService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService
  ) {}

  ngOnInit() {
    if (this.activatedRoute.snapshot.paramMap.get('id')) {
      this.id_room = atob(this.activatedRoute.snapshot.paramMap.get('id'));
      this.editMode = true;
    }

    this.roomForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      street: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      houseNumber: new FormControl('', [
        Validators.required,
        houseNumberValidator(),
        Validators.maxLength(6),
      ]),
      addressAddition: new FormControl('', Validators.maxLength(100)),
      zipCode: new FormControl('', [
        Validators.required,
        maxNumberLength(5),
        positiveNumbersOnlyValidator(true),
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      country: new FormControl('Deutschland', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      floor: new FormControl(null, [Validators.required, maxNumberLength(2)]),
      building: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      seatNumber: new FormControl(null, [
        Validators.required,
        positiveNumbersOnlyValidator(true),
        maxNumberLength(4),
      ]),
      equipment: new FormControl('', Validators.maxLength(255)),
      responsible: new FormControl('', Validators.maxLength(255)),
      available: new FormControl(true, Validators.required),
    });

    if (this.editMode) {
      this.roomService
        .getRoomById(+this.id_room)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            console.debug('getRoom Backend Response', response);
            if (!response.success) {
              console.error(response.message);
              this.isLoading = false;
              return;
            }
            this.currentRoom = await this.roomService.parseBackendRoom(
              response.data
            );

            this.roomForm.patchValue(this.currentRoom);

            // patch address & available
            this.roomForm.patchValue({
              street: this.currentRoom.address.street,
              houseNumber: this.currentRoom.address.houseNumber,
              addressAddition: this.currentRoom.address.addressAddition,
              zipCode: this.currentRoom.address.zipCode,
              city: this.currentRoom.address.city,
              country: this.currentRoom.address.country,
              available: this.currentRoom.available ? true : false,
            });
            this.isLoading = false;
            this.initialFormValues = this.roomForm.value;
          },
          error: error => {
            console.error(error);
          },
        });

      // subscribe to changes of available
      this.roomForm.get('available').valueChanges.subscribe({
        next: checked => {
          if (!checked) {
            this.eventService
              .getEventDatesByRoomId(this.currentRoom.id)
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: response => {
                  console.debug('EventDates in room', response);
                  if (response.success && response.data.length > 0) {
                    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                      maxWidth: '400px',
                      data: {
                        title: `Bevorstehende Termine in Raum '${this.currentRoom.name}'!`,
                        message:
                          'Es sind noch bevorstehende Termine in diesem Raum vorhanden.  \
                        Wenn Sie den Raum deaktivieren und speichern, wird der Raum aus allen bevorstehenden Terminen entfernt. \
                        Möchten Sie den Raum trotzdem deaktivieren?',
                      },
                    });

                    dialogRef.afterClosed().subscribe(dialogResult => {
                      if (dialogResult) {
                      } else {
                        this.roomForm.get('available').setValue(true);
                      }
                    });
                  }
                },
              });
          }
        },
      });
    }
    this.initialFormValues = this.roomForm.value;
  }

  /**
   * buildRoom
   */
  private buildRoom() {
    const room: Room = {
      id: this.id_room ? +this.id_room : null,
      name: this.roomForm.get('name').value,
      address: {
        street: this.roomForm.get('street').value,
        houseNumber: this.roomForm.get('houseNumber').value,
        addressAddition: this.roomForm.get('addressAddition').value,
        zipCode: this.roomForm.get('zipCode').value,
        city: this.roomForm.get('city').value,
        country: this.roomForm.get('country').value,
      },
      floor: this.roomForm.get('floor').value,
      building: this.roomForm.get('building').value,
      seatNumber: this.roomForm.get('seatNumber').value,
      equipment: this.roomForm.get('equipment').value,
      responsible: this.roomForm.get('responsible').value,
      available: Boolean(this.roomForm.get('available').value),
      id_institute: this.userService.currentUser.id_institute,
    };

    return room;
  }

  onSubmit() {
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.roomForm
      );

    if (formInvalid) {
      return;
    }

    this.editMode ? this.updateRoom() : this.createRoom();
  }

  onCancel() {
    const route = this.editMode ? '../../' : '../';
    this.router.navigate([route], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * createRoom
   * creates a new room
   * @returns void
   */
  public async createRoom(): Promise<void> {
    const room = this.buildRoom();

    const createRoomObservable = await this.roomService.createRoom(room);

    createRoomObservable.subscribe({
      next: response => {
        console.debug('createRoom Backend Response', response);
        if (!response.success) {
          if (response.message != 'room_exists') {
            console.error(response.message);
          }

          const toastrMessage =
            response.message === 'room_exists'
              ? `Ein Raum mit dem Namen '${room.name}' existiert bereits.`
              : `Raum '${room.name}' konnte nicht erstellt werden.`;
          const toastrTitle =
            response.message === 'room_exists'
              ? 'Raum existiert bereits!'
              : 'Fehler!';

          this.alertService.showErrorAlert(toastrTitle, toastrMessage);
          return;
        }
        // reset initialFormValues to current form values
        this.initialFormValues = this.roomForm.value;
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });

        this.alertService.showSuccessAlert(
          'Raum hinzugefügt',
          `Der Raum '${room.name}' wurde hinzugefügt!`
        );
      },
      error: error => {
        console.error(error);
      },
    });
  }

  /**
   * updateRoom
   * updates a room
   * @returns void
   */
  public async updateRoom(): Promise<void> {
    const room = this.buildRoom();

    const updateRoomObservable = await this.roomService.updateRoom(
      +this.id_room,
      room,
      'updateRoom'
    );

    updateRoomObservable.subscribe({
      next: response => {
        console.debug('updateRoom Backend Response', response);
        if (!response.success) {
          console.error(response.message);
          this.alertService.showErrorAlert(
            'Fehler',
            `Der Raum '${room.name}' konnte nicht bearbeitet werden!`
          );
          return;
        }

        if (!room.available && this.initialFormValues['available']) {
          this.removeRoomFromFutureEventDates(room);
        }

        // reset initialFormValues to current form values
        this.initialFormValues = this.roomForm.value;
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });

        this.alertService.showSuccessAlert(
          'Raum bearbeitet',
          `Der Raum '${room.name}' wurde bearbeitet!`
        );
      },
      error: error => {
        console.error(error);
      },
    });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.roomForm.value,
      this.initialFormValues
    );
  }

  /**
   * removeRoomFromFutureEventDates
   * @param room
   */
  private removeRoomFromFutureEventDates(room: Room): void {
    this.eventService.removeRoomFromFutureEventDates(room.id).subscribe({
      next: result => {
        if (!result.success) {
          console.error(result.message);
          this.alertService.showErrorAlert(
            'Fehler',
            `Der Raum '${room.name}' konnte nicht aus den bevorstehenden Terminen entfernt werden!`
          );
          return;
        }

        this.alertService.showSuccessAlert(
          'Raum aus Terminen entfernt',
          `Der Raum '${room.name}' wurde aus allen bevorstehenden Terminen entfernt!`
        );
      },
      error: error => {
        console.error(error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Der Raum '${room.name}' konnte nicht aus den bevorstehenden Terminen entfernt werden!`
        );
      },
    });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
