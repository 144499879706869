<div class="content-container">
  <div class="content-container-header">
    <h1 class="hasomed-text-title">Ihre Organisation</h1>
    <nav
      mat-tab-nav-bar
      [tabPanel]="studyAdministrationPanel"
      mat-stretch-tabs="false"
      mat-align-tabs="start">
      <div *ngFor="let link of navLinks">
        <a
          *appCheckPermissions="{
            feature: link.feature,
            permission: link.permission
          }"
          mat-tab-link
          [routerLink]="link.link"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive">
          <span>{{ link.label }}</span>
        </a>
      </div>
    </nav>
  </div>
  <mat-divider></mat-divider>
  <div class="content-container-content">
    <mat-tab-nav-panel #studyAdministrationPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</div>
