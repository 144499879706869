import { Injectable } from '@angular/core';
import { PracticalWork } from '../models/practical-work.model';
import { Observable, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/configs/environment';
import * as moment from 'moment';
import { UserService } from './user.service';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { CustomFileType } from '../models/custom-file-type.model';
import { FileService } from './file.service';
import { DecryptionService } from './decryption.service';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class PracticalWorkService {
  constructor(
    private http: HttpClient,
    private userSerice: UserService,
    private cryptoService: CryptoService,
    private fileService: FileService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getPracticalWorksFromStudent
   * Gets all practical works from a student
   * @param id_student
   * @returns
   */
  public getPracticalWorksFromStudent(id_student: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'practicalWork/getAllPracticalWorksFromStudent.php?id_student=' +
          id_student
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getPracticalWork
   * Gets a practical work by id
   * @param id
   * @param includeFileData
   * @returns Observable<any>
   */
  public getPracticalWork(
    id: number,
    includeFileData: boolean
  ): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `practicalWork/getPracticalWork.php?id=${id}&includeFileData=${includeFileData}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createPracticalWork
   * Creates a new practical work for a student
   * @param practicalWork
   * @returns Observable<any>
   */
  public async createPracticalWork(
    practicalWork: PracticalWork
  ): Promise<Observable<any>> {
    const id_student = this.userSerice.currentUser.id;
    const id_institute = this.userSerice.currentUser.id_institute;
    const formData = new FormData();

    // encrypt files
    practicalWork.files =
      practicalWork.files &&
      (await Promise.all(
        practicalWork.files.map(
          async (file: CustomFileType): Promise<CustomFileType> => {
            return {
              ...file,
              filename:
                file.filename &&
                (await this.cryptoService.encrypt(file.filename)),
              file: file.file && (await this.cryptoService.encrypt(file.file)),
            };
          }
        )
      ));

    formData.append('id_student', id_student.toString());
    formData.append('id_institute', id_institute.toString());
    practicalWork.type &&
      formData.append('type', practicalWork.type.toString());
    practicalWork.title && formData.append('title', practicalWork.title);
    practicalWork.address.street &&
      formData.append(
        'street',
        await this.cryptoService.encrypt(practicalWork.address.street)
      );
    practicalWork.address.houseNumber &&
      formData.append(
        'house_number',
        await this.cryptoService.encrypt(practicalWork.address.houseNumber)
      );
    practicalWork.address.addressAddition &&
      formData.append(
        'address_addition',
        await this.cryptoService.encrypt(practicalWork.address.addressAddition)
      );
    practicalWork.address.zipCode &&
      formData.append(
        'zip_code',
        await this.cryptoService.encrypt(practicalWork.address.zipCode)
      );
    practicalWork.address.city &&
      formData.append(
        'city',
        await this.cryptoService.encrypt(practicalWork.address.city)
      );
    practicalWork.address.country &&
      formData.append(
        'country',
        await this.cryptoService.encrypt(practicalWork.address.country)
      );
    practicalWork.description &&
      formData.append('description', practicalWork.description);
    practicalWork.responsiblePerson &&
      formData.append('responsible_person', practicalWork.responsiblePerson);
    practicalWork.startDate &&
      formData.append(
        'start_date',
        moment(practicalWork.startDate).format('YYYY-MM-DD HH:mm:ss')
      );
    practicalWork.endDate &&
      formData.append(
        'end_date',
        moment(practicalWork.endDate).format('YYYY-MM-DD HH:mm:ss')
      );
    practicalWork.duration &&
      formData.append('duration', practicalWork.duration.toString());
    practicalWork.internalNote &&
      formData.append('internal_note', practicalWork.internalNote);
    practicalWork.files &&
      formData.append('files', JSON.stringify(practicalWork.files).toString());

    return this.http.post(
      environment.authority_short + 'practicalWork/createPracticalWork.php',
      formData
    );
  }

  /**
   * updatePracticalWork
   * Updates a practical work
   * @param practicalWork
   * @returns Observable<any>
   */
  public async updatePracticalWork(
    practicalWork: PracticalWork
  ): Promise<Observable<any>> {
    const id_student = this.userSerice.currentUser.id;
    const id_institute = this.userSerice.currentUser.id_institute;
    const formData = new FormData();

    // encrypt files
    practicalWork.files =
      practicalWork.files &&
      (await Promise.all(
        practicalWork.files.map(
          async (file: CustomFileType): Promise<CustomFileType> => {
            return {
              ...file,
              filename:
                file.filename &&
                (await this.cryptoService.encrypt(file.filename)),
              file: file.file && (await this.cryptoService.encrypt(file.file)),
            };
          }
        )
      ));

    formData.append('id_student', id_student.toString());
    formData.append('id_institute', id_institute.toString());
    practicalWork.id && formData.append('id', practicalWork.id.toString());
    practicalWork.type &&
      formData.append('type', practicalWork.type.toString());
    practicalWork.title && formData.append('title', practicalWork.title);
    practicalWork.address.street &&
      formData.append(
        'street',
        await this.cryptoService.encrypt(practicalWork.address.street)
      );
    practicalWork.address.houseNumber &&
      formData.append(
        'house_number',
        await this.cryptoService.encrypt(practicalWork.address.houseNumber)
      );
    practicalWork.address.addressAddition &&
      formData.append(
        'address_addition',
        await this.cryptoService.encrypt(practicalWork.address.addressAddition)
      );
    practicalWork.address.zipCode &&
      formData.append(
        'zip_code',
        await this.cryptoService.encrypt(practicalWork.address.zipCode)
      );
    practicalWork.address.city &&
      formData.append(
        'city',
        await this.cryptoService.encrypt(practicalWork.address.city)
      );
    practicalWork.address.country &&
      formData.append(
        'country',
        await this.cryptoService.encrypt(practicalWork.address.country)
      );
    practicalWork.description &&
      formData.append('description', practicalWork.description);
    practicalWork.responsiblePerson &&
      formData.append('responsible_person', practicalWork.responsiblePerson);
    practicalWork.startDate &&
      formData.append(
        'start_date',
        moment(practicalWork.startDate).format('YYYY-MM-DD HH:mm:ss')
      );
    practicalWork.endDate &&
      formData.append(
        'end_date',
        moment(practicalWork.endDate).format('YYYY-MM-DD HH:mm:ss')
      );
    practicalWork.duration &&
      formData.append('duration', practicalWork.duration.toString());
    practicalWork.internalNote &&
      formData.append('internal_note', practicalWork.internalNote);
    practicalWork.files &&
      formData.append('files', JSON.stringify(practicalWork.files).toString());

    return this.http.post(
      environment.authority_short + 'practicalWork/updatePracticalWork.php',
      formData
    );
  }

  /**
   * deletePracticalWork
   * Deletes a practical work
   * @param id
   * @returns
   */
  public deletePracticalWork(id: number): Observable<any> {
    const formData = new FormData();
    formData.append('id', id.toString());

    return this.http.post(
      environment.authority_short + 'practicalWork/deletePracticalWork.php',
      formData
    );
  }

  /**
   * parseBackendPracticalWork
   * Parses a practical work from the backend
   * @param practicalWork any
   * @returns PracticalWork
   */
  public async parseBackendPracticalWork(
    practicalWork: any
  ): Promise<PracticalWork> {
    console.debug('parseBackendPracticalWork', practicalWork);
    const parsedPracticalWork: PracticalWork = {
      id: practicalWork.id,
      type: practicalWork.type,
      title: practicalWork.title,
      address: {
        zipCode:
          practicalWork.address.zipCode &&
          (await this.decryptionService.decryptString(
            practicalWork.address.zipCode
          )),
        city:
          practicalWork.address.city &&
          (await this.decryptionService.decryptString(
            practicalWork.address.city
          )),
        country:
          practicalWork.address.country &&
          (await this.decryptionService.decryptString(
            practicalWork.address.country
          )),
        street:
          practicalWork.address.street &&
          (await this.decryptionService.decryptString(
            practicalWork.address.street
          )),
        houseNumber:
          practicalWork.address.houseNumber &&
          (await this.decryptionService.decryptString(
            practicalWork.address.houseNumber
          )),
        addressAddition:
          practicalWork.address.addressAddition &&
          (await this.decryptionService.decryptString(
            practicalWork.address.addressAddition
          )),
      },
      description: practicalWork.description,
      responsiblePerson: practicalWork.responsiblePerson,
      startDate: moment(
        practicalWork.startDate,
        'YYYY-MM-DD HH:mm:ss'
      ).toDate(),
      endDate: moment(practicalWork.endDate, 'YYYY-MM-DD HH:mm:ss').toDate(),
      duration: practicalWork.duration,
      internalNote: practicalWork.internalNote,
      files: practicalWork.files
        ? await Promise.all(
            practicalWork.files?.map(
              async (it: any): Promise<CustomFileType> => {
                return await this.fileService.parseBackendFile(it);
              }
            )
          )
        : null,
      isConfirmedFile: practicalWork.isConfirmedFile,
    };

    console.debug('parsedPracticalWork', parsedPracticalWork);
    return parsedPracticalWork;
  }
}
