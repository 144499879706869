import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, pipe, takeUntil } from 'rxjs';
import { CourseEvent } from 'src/app/models/course.model';
import { Role } from 'src/app/models/permission.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { RoomService } from 'src/app/services/room.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-course-events',
  templateUrl: './course-events.component.html',
  styleUrl: './course-events.component.scss',
})
export class CourseEventsComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<CourseEvent> =
    new MatTableDataSource<CourseEvent>();
  public columnsToDisplay = ['date', 'time', 'room', 'participantAmount'];
  public isStudent: boolean;
  public isLoading = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private roomService: RoomService,
    private router: Router,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    this.isStudent = this.userService.currentUser.id_role === Role.STUDENT;

    this.activatedRoute.parent?.paramMap.subscribe(params => {
      const id_course = +atob(params.get('id'));
      id_course && this.getCourseEvents(id_course);
    });
  }

  /**
   * getCourseEvents
   * get all course events for the course
   * @param id_course
   */
  private getCourseEvents(id_course: number) {
    const id_user = this.userService.currentUser.id;
    const id_role = this.userService.currentUser.id_role;
    this.courseService
      .getAllCourseEvents(id_course, id_user, id_role)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('eventDates Backend response', response);
          if (!response.success) {
            this.alertService.showErrorAlert(
              'Fehler',
              'Kurstermine konnten nicht geladen werden'
            );
            this.isLoading = false;
            return;
          }

          // only show eLog column if the user has eLogs for the course events or if the user is not a student
          let elogs = 0;
          response.data?.forEach((event: any) => {
            if (event.eLogStatus !== null) {
              elogs++;
            }
          });

          if (
            (id_role === Role.STUDENT && elogs > 0) ||
            id_role !== Role.STUDENT
          ) {
            this.columnsToDisplay.push('eLog');
          }
          if (id_role !== Role.STUDENT) {
            this.columnsToDisplay.push('actions');
          }

          this.dataSource.data = response.data
            ? await Promise.all(
                response.data.map(async (event: any) => {
                  return {
                    ...event,
                    room: event.room
                      ? await this.roomService.parseBackendRoom(event.room)
                      : null,
                    lecturers: event.lecturers
                      ? await Promise.all(
                          event.lecturers.map(async (lecturer: any) => {
                            return await this.userService.parseBackendUser(
                              lecturer
                            );
                          })
                        )
                      : [],
                  };
                })
              )
            : [];
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'date':
                return moment(item.startDate);
              case 'lecturer':
                return item.lecturers[0].name.firstname;
              case 'room':
                return item.room.name;
              case 'participantAmount':
                return this.getParticipantAmount(item);
              default:
                return item[property];
            }
          };
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
        },
        error: error => {
          console.error('Course events could not be loaded', error);
          this.alertService.showErrorAlert(
            'Fehler',
            'Kurstermine konnten nicht geladen werden'
          );
          this.isLoading = false;
        },
      });
  }

  /**
   * viewELog
   * navigate to eLog page
   * @param event
   */
  public viewELog(event: CourseEvent) {
    this.router.navigate(['../elog', btoa(event.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * getParticipantAmount
   * get the amount of participants for the event
   * @param event
   */
  public getParticipantAmount(event: CourseEvent): number {
    if (!event.eLogStatusCounts) {
      return 0;
    }
    return (
      event.eLogStatusCounts.absent +
      event.eLogStatusCounts.checked +
      event.eLogStatusCounts.excused +
      event.eLogStatusCounts.pending +
      event.eLogStatusCounts.unexcused +
      event.eLogStatusCounts.upcoming
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
