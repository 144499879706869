import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Course } from 'src/app/models/course.model';
import { Role } from 'src/app/models/permission.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { getEducationCourseTitles } from 'src/app/utils/course.utils';

@Component({
  selector: 'app-course-description',
  templateUrl: './course-description.component.html',
  styleUrl: './course-description.component.scss',
})
export class CourseDescriptionComponent implements OnInit, OnDestroy {
  public course: Course;
  public isLoading = true;
  public isStudent: boolean = false;

  public getEducationCourseTitles = getEducationCourseTitles;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    const id_course = +atob(
      this.activatedRoute.parent?.snapshot.paramMap.get('id')
    );
    this.isStudent = this.userService.currentUser.id_role === Role.STUDENT;
    this.getCourse(id_course);
  }

  /**
   * getCourse
   * get the course by id
   * @param id_course
   */
  private getCourse(id_course: number): void {
    this.courseService
      .getCourseById(id_course, 'educationCourses')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('courseDetails Backend response', response);
          if (!response.success) {
            console.error('Course details could not be loaded');
            this.isLoading = false;
            return;
          }
          this.course = await this.courseService.parseBackendCourse(
            response.data
          );
          this.isLoading = false;
        },
        error: error => {
          console.error('Course details could not be loaded', error);
          this.isLoading = false;
        },
      });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
