<div class="edit-master-data-dialog flex-c">
  <!-- HEADER -->
  <div class="header flex-r">
    <span class="hasomed-text-h1">Rolle anpassen</span>
    <button
      type="button"
      class="hasomed-button-icon"
      matTooltip="Abbrechen"
      (click)="onCancel()">
      <i class="fa-light fa-x"></i>
    </button>
  </div>

  <!-- FORMULAR -->
  <form class="edit-master-data-form" [formGroup]="editRoleForm">
    <div class="flex-c gap-24 scrollable-content">
      <!-- Role -->
      <div class="formular-row">
        <label for="status" class="formular-row-label">
          <div class="label-title">Rolle</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <mat-form-field class="hasomed-select">
              <mat-select
                class="hasomed-select"
                formControlName="role"
                placeholder="Rolle">
                <mat-option [value]="null">-- Keine Rolle --</mat-option>
                <mat-option [value]="1">Superadmin </mat-option>
                <mat-option [value]="2">Verwaltung </mat-option>
                <mat-option [value]="3">Lehrpersonal </mat-option>
                <mat-option [value]="4">Kandidat*in </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- BUTTONS -->
      <div class="formular-buttons flex-r">
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white"
          (click)="onCancel()">
          <span class="hasomed-button-text"> Abbrechen </span>
        </button>
        <button
          type="submit"
          class="hasomed-text-button hasomed-button-primary"
          [disabled]="!editRoleForm.valid"
          (click)="onSave()">
          <i class="hasomed-button-icon-sm fa-light fa-floppy-disk"></i>
          <span class="hasomed-button-text">Speichern</span>
        </button>
      </div>
    </div>
  </form>
</div>
