import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { VNode, createElement } from '@fullcalendar/core/preact';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import * as moment from 'moment';
import { EventType } from 'src/app/models/event.model';
import { EventService } from 'src/app/services/event.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { CalendarEventDetailComponent } from './calendar-event-detail/calendar-event-detail.component';
import { User } from 'src/app/models/user.model';
import {
  CalendarEvent,
  CalendarEventColors,
} from 'src/app/models/calendar-event.model';
import { UserService } from 'src/app/services/user.service';
import { RoomService } from 'src/app/services/room.service';
import { DecryptionService } from 'src/app/services/decryption.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
  public calendarOptions: CalendarOptions;
  public isTodayInView = true;
  private changeViewBreakpoint: number = 1200;
  private scrollTopPosition: number = 0;
  public calendar: Calendar;
  public onlyDayView = false;
  public selectedCalendarView: string;

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('fullCalendar') calendarComponent: FullCalendarComponent;

  @HostListener('window:resize', ['$event'])
  public resize($event: any) {
    // change view
    this.changeViewBreakpoint = this.sidenavService.collapsed ? 1200 : 1350;
    if (window.innerWidth < this.changeViewBreakpoint) {
      this.calendar.changeView('timeGridDay');
      this.onlyDayView = true;
    } else {
      const previousView = this.calendar.view.type;

      if (previousView === this.selectedCalendarView) {
        this.onlyDayView = false;
        return;
      }

      this.onlyDayView = false;
    }

    this.selectedCalendarView = this.calendar.view.type;
    // keep scroll position on resize
    this.keepScrollPosition();
    this.updateTitle();
  }

  constructor(
    private eventService: EventService,
    private sidenavService: SidenavService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private roomService: RoomService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  async ngOnInit() {
    this.initializeCalendarOptions();
    await this.initializeCalendarEvents();
  }

  ngAfterViewInit() {
    this.calendar = this.calendarComponent.getApi();
    this.checkIsTodayInView();
    this.updateTitle();

    // get scroll position
    const fcScroller = document.getElementsByClassName('fc-scroller')[1];
    this.scrollTopPosition = fcScroller.scrollTop;

    fcScroller.addEventListener('scroll', () => {
      this.scrollTopPosition = fcScroller.scrollTop;
    });

    this.sidenavService.sidenavChangedSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((collapsed: boolean) => {
        this.changeViewBreakpoint = collapsed ? 1200 : 1350;

        if (this.calendar) {
          if (window.innerWidth < this.changeViewBreakpoint) {
            this.onViewChange('timeGridDay');
            this.onlyDayView = true;
          } else {
            this.onViewChange('timeGridSevenDay');
            this.onlyDayView = false;
          }
          this.selectedCalendarView = this.calendar.view.type;
          this.keepScrollPosition();
        }
      });

    if (window.innerWidth < this.changeViewBreakpoint) {
      this.onViewChange('timeGridDay');
      this.onlyDayView = true;
    } else {
      this.onlyDayView = false;
    }

    this.selectedCalendarView = this.calendar.view.type;
    localStorage.setItem('calendarView', this.selectedCalendarView);

    // call detectChanges because calendar.getApi() is called in ngAfterViewInit
    this.cdr.detectChanges();
  }

  /**
   * onViewChange
   * triggerd when the view of the calendar changes
   * @returns void
   */
  public onViewChange(view: string): void {
    this.selectedCalendarView = view;
    this.calendar.changeView(this.selectedCalendarView);
    localStorage.setItem('calendarView', this.selectedCalendarView);
    this.updateTitle();
  }

  /**
   * initializeCalendarOptions
   * initialize the calendar options
   * @returns void
   */
  private initializeCalendarOptions(): void {
    this.changeViewBreakpoint = this.sidenavService.collapsed ? 1200 : 1350;

    // get calendarView from localStorage
    this.selectedCalendarView =
      localStorage.getItem('calendarView') ?? 'timeGridSevenDay';

    this.calendarOptions = {
      locale: 'de',
      plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
      initialView: this.selectedCalendarView,
      views: {
        timeGridSevenDay: {
          type: 'timeGrid',
          duration: { days: 7 },
          slotMinTime: '05:00:00',
          slotMaxTime: '23:59:59',
          slotDuration: '1:00',
          expandRows: true,
          allDaySlot: false,
          nowIndicator: true,
          // start 1.5 hours before now,
          scrollTime: moment()
            .minute(0)
            .second(0)
            .subtract(1.5, 'h')
            .format('HH:mm:ss'),
          eventMaxStack: 1,
        },
        timeGridDay: {
          type: 'timeGrid',
          duration: { days: 1 },
          slotMinTime: '05:00:00',
          slotMaxTime: '23:59:59',
          slotDuration: '1:00',
          expandRows: true,
          allDaySlot: false,
          nowIndicator: true,
          // start 1.5 hours before now
          scrollTime: moment()
            .minute(0)
            .second(0)
            .subtract(1.5, 'h')
            .format('HH:mm:ss'),
          eventMaxStack: 3,
        },
        dayGridMonth: {
          type: 'dayGridMonth',
          dayMaxEventRows: 3,
        },
      },
      firstDay: 1, // start month view with monday
      moreLinkText: 'mehr',
      moreLinkContent(renderProps, createElement) {
        return createElement(
          'span',
          { class: 'hasomed-text-small' },
          renderProps.num +
            ' weitere' +
            (renderProps.num === 1 ? 'r ' : ' ') +
            ' Termin' +
            (renderProps.num === 1 ? '' : 'e')
        );
      },
      moreLinkDidMount: function (info) {
        info.el.title = 'Termine anzeigen'; // Custom tooltip text
      },
      dayHeaderFormat: {
        weekday: 'long',
        month: 'numeric',
        day: 'numeric',
        omitCommas: true,
      },
      nowIndicatorContent: args => {
        // create the nor indicator line
        if (args.isAxis) {
          return createElement(
            'span',
            { class: 'hasomed-text-small font-white' },
            moment(args.date).format('HH:mm')
          );
        } else {
          return null;
        }
      },
      dateClick: info => {
        console.debug('dateClick', info);

        console.debug('selectedCalendarView', this.selectedCalendarView);

        if (this.selectedCalendarView === 'dayGridMonth') {
          this.calendar.changeView('timeGridDay', info.dateStr);
          this.selectedCalendarView = this.calendar.view.type;
          this.updateTitle();
        }
      },
      dayHeaderContent: args => {
        // create the custom header div (weekday, day)
        const date = new Date(args.date);
        const weekday = date.toLocaleDateString('de-DE', { weekday: 'long' });
        const day = date.getDate();

        const weekdayDiv = createElement(
          'div',
          { class: 'fc-custom-header-weekday' },
          weekday
        );

        const dayDiv = createElement(
          'div',
          { class: 'fc-custom-header-day', id: 'day' },
          day
        );

        return createElement('div', { class: 'fc-custom-header-cell' }, [
          weekdayDiv,
          this.calendar?.view.type !== 'dayGridMonth' ? dayDiv : null,
        ]);
      },
      dayHeaderDidMount: ({ el, view }) => {
        const today = new Date();
        const headerDate = new Date(el.getAttribute('data-date'));

        // check, if the header date is todays date
        if (today.toDateString() === headerDate.toDateString()) {
          const dayDiv = document.getElementById('day');
          dayDiv.classList.add('fc-custom-today');
        }
      },
      slotLabelFormat: {
        hour: 'numeric',
        minute: 'numeric',
        omitZeroMinute: false,
      },
      dayCellDidMount: info => {
        info.el.title = moment(info.date).format('DD.MM.YYYY') + ' anzeigen';
      },
      slotLabelContent: args => {
        // create the slot label (time to the left) div to display in calendar
        const slotLabelText = args.text;
        let slotLabelDiv = createElement(
          'div',
          { class: 'fc-custom-slot-label' },
          slotLabelText !== '05:00' ? slotLabelText : ''
        );

        return slotLabelDiv;
      },
      eventContent: args => {
        // create the event div to display in calendar
        const event = args.event;
        const time = args.timeText;
        const eventWrapperDiv = this.createEventDiv(event, time);
        return eventWrapperDiv;
      },
      eventDidMount: info => {
        const title = info.event.title;
        const room = info.event.extendedProps['room']
          ? info.event.extendedProps['room'].name
          : 'Kein Raum';
        const date =
          moment(info.event.start).format('DD.MM.YYYY') +
          ', ' +
          moment(info.event.start).format('HH:mm') +
          ' - ' +
          moment(info.event.end).format('HH:mm') +
          ' Uhr';
        const tooltipText = title + ' - ' + room + ' - ' + date;

        info.el.title = tooltipText;
      },
      eventClick: info => {
        // open event detail dialog
        const dialogRef = this.dialog.open(CalendarEventDetailComponent, {
          data: {
            id_eventDate: info.event.extendedProps['id_eventDate'],
            id_treatmentCase: info.event.extendedProps['id_treatmentCase'],
            id_course: info.event.extendedProps['id_course'],
            title: info.event.title,
            lecturer: info.event.extendedProps['lecturer'] || null,
            chiffre: info.event.extendedProps['chiffre'] || null,
            supervisor: info.event.extendedProps['supervisor'] || null,
            type: info.event.extendedProps['eventType'],
            room: info.event.extendedProps['room'],
            startDate: info.event.start,
            endDate: info.event.end,
            canceled: info.event.extendedProps['canceled'],
            treatmentCaseStudent:
              info.event.extendedProps['treatmentCaseStudent'],
          },
          width: '500px',
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            if (result?.canceled) {
              info.event.setExtendedProp('canceled', true);

              // set colors for canceled event
              info.event.setProp(
                'backgroundColor',
                CalendarEventColors.canceled.backgroundColor
              );
              info.event.setProp(
                'borderColor',
                CalendarEventColors.canceled.borderColor
              );
              info.event.setProp(
                'textColor',
                CalendarEventColors.canceled.textColor
              );
            }
          });
      },
      // remove header toolbar
      headerToolbar: {
        left: '',
        center: '',
        right: '',
      },
      buttonText: {
        today: 'Heute',
      },
    };
  }

  /**
   * initializeCalendarEvents
   * initialize the calendar events of the current user
   * @returns void
   */
  private async initializeCalendarEvents(): Promise<void> {
    // if user is admin, get all institute events

    let getEvents = this.eventService.getAllUserEventDates();

    if (this.userService.currentUserIsAdministrator()) {
      getEvents = this.eventService.getAllInstituteEventDates();
    }

    if (this.userService.currentUserIsLecturer()) {
      getEvents = this.eventService.getAllLecturerEventDates();
    }

    getEvents.pipe(takeUntil(this.destroy$)).subscribe({
      next: async response => {
        console.debug('all user events', response);
        if (!response.success) {
          console.error(response.message);
          return;
        }
        if (!!response.data) {
          const calendarEvents = response.data
            ? await Promise.all(
                response.data.map(async (event: any) => {
                  // create lecturer string
                  let lecturers = '';
                  if (event.lecturers && event.lecturers.length > 0) {
                    lecturers = '';

                    const decryptedLecturers = event.lecturers
                      ? await Promise.all(
                          event.lecturers.map(async (lecturer: User) => {
                            return this.userService.parseBackendUser(lecturer);
                          })
                        )
                      : [];

                    decryptedLecturers.forEach(async (lecturer: User) => {
                      lecturers +=
                        (lecturer.name.academicTitle
                          ? lecturer.name.academicTitle + ' '
                          : '') +
                        (lecturer.name.firstname ?? '') +
                        ' ' +
                        (lecturer.name.lastname ?? '') +
                        // add comma, if not last lecturer
                        (decryptedLecturers.indexOf(lecturer) <
                        decryptedLecturers.length - 1
                          ? ', '
                          : '');
                    });
                  }

                  // create supervisor string
                  const decryptedSupervisor = event.supervisor
                    ? await this.userService.parseBackendUser(event.supervisor)
                    : null;
                  const supervisorName = decryptedSupervisor
                    ? (decryptedSupervisor.name.academicTitle
                        ? decryptedSupervisor.name.academicTitle + ' '
                        : '') +
                      (decryptedSupervisor.name.firstname ?? '') +
                      ' ' +
                      (decryptedSupervisor.name.lastname ?? '')
                    : 'Kein Supervisor';

                  // create treatmentCaseStudent string
                  const decryptedtreatmenCaseStudent =
                    event.treatment_case_student
                      ? await this.userService.parseBackendUser(
                          event.treatment_case_student
                        )
                      : null;
                  const treatmentCaseStudent = decryptedtreatmenCaseStudent
                    ? (decryptedtreatmenCaseStudent.name.academicTitle
                        ? decryptedtreatmenCaseStudent.name.academicTitle + ' '
                        : '') +
                      (decryptedtreatmenCaseStudent.name.firstname ?? '') +
                      ' ' +
                      (decryptedtreatmenCaseStudent.name.lastname ?? '')
                    : '-';

                  const calendarEvent: CalendarEvent = {
                    title: this.getEventTitle(event),
                    start: event.startDate,
                    end: event.endDate,
                    backgroundColor: this.getColor(event).backgroundColor,
                    borderColor: this.getColor(event).borderColor,
                    textColor: this.getColor(event).textColor,
                    extendedProps: {
                      id_eventDate: event.id,
                      id_treatmentCase: event.id_treatment_case ?? null,
                      id_course: event.id_course ?? null,
                      eventType: event.type,
                      room: event.room
                        ? await this.roomService.parseBackendRoom(event.room)
                        : null,
                      canceled: event.canceled ?? null,
                      chiffre: event.treatment_case_chiffre
                        ? await this.decryptionService.decryptString(
                            event.treatment_case_chiffre
                          )
                        : null,
                      supervisor: event.supervisor ? supervisorName : null,
                      lecturer: lecturers ?? null,
                      treatmentCaseStudent: event.treatment_case_student
                        ? treatmentCaseStudent
                        : null,
                    },
                  };

                  return calendarEvent;
                })
              )
            : [];

          // update calendar
          this.calendar.setOption('events', calendarEvents);
        }
      },
      error: error => {
        console.error('error', error);
      },
    });
  }

  /**
   * onClickNext
   * trigger calenderApi.next() when clicking on next button, update isTodayInView and title
   * @returns void
   */
  public onClickNext(): void {
    this.calendar.next();
    this.checkIsTodayInView();
    this.updateTitle();
  }

  /**
   * onClickPrev
   * trigger calenderApi.prev() when clicking on prev button, update isTodayInView and title
   * @returns void
   */
  public onClickPrev(): void {
    this.calendar.prev();
    this.checkIsTodayInView();
    this.updateTitle();
  }

  /**
   * onClickToday
   * trigger calenderApi.today() when clicking on today button,  update isTodayInView and title
   * @returns void
   */
  public onClickToday() {
    this.calendar.today();
    this.isTodayInView = true;
    this.updateTitle();
  }

  /**
   * onChangeCalendarView
   * change calendar view to day or seven day view
   * @returns void
   */
  public onChangeCalendarView(): void {
    // check which view is active and change to the other
    if (this.calendar.view.type === 'timeGridDay') {
      this.calendar.changeView('timeGridSevenDay');
    } else {
      this.calendar.changeView('timeGridDay');
    }
    this.keepScrollPosition();
  }

  /**
   * checkIsTodayInView
   * Update isTodayInView to true, when todays date is in current view, else false
   * @returns void
   */
  private checkIsTodayInView(): void {
    const calendarApiView = this.calendar.view;
    const viewStart = calendarApiView.activeStart;
    const viewEnd = calendarApiView.activeEnd;
    const today = new Date();

    if (today >= viewStart && today <= viewEnd) {
      this.isTodayInView = true;
    } else {
      this.isTodayInView = false;
    }
  }

  /**
   * updateTitle
   * updates the title (date-range) of current view
   * @returns void
   */
  private updateTitle(): void {
    const calendarApiView = this.calendar.view;
    const titleSpan = document.getElementById('fcCustomTitle');
    titleSpan.innerHTML = calendarApiView.title;
  }

  /**
   * getEventsInView
   * returns all events in current view
   * @returns EventImpl[]
   */
  public getEventsInView(): EventImpl[] {
    const viewStart = this.calendar.view.activeStart;
    const viewEnd = this.calendar.view.activeEnd;

    const allEvents = this.calendar.getEvents();
    const eventsInView = [];

    allEvents.forEach(event => {
      if (
        (event.start >= viewStart && event.start <= viewEnd) ||
        (event.end >= viewStart && event.end <= viewEnd)
      ) {
        eventsInView.push(event);
      }
    });

    eventsInView.sort((a, b) => a.start - b.start);

    return eventsInView;
  }

  /**
   * keepScrollPosition
   * keeps the scroll position of the calendar
   * @returns void
   */
  private keepScrollPosition(): void {
    const fcScroller = document.getElementsByClassName('fc-scroller')[1];
    fcScroller.scrollTop = this.scrollTopPosition;
  }

  /**
   * getEventTitle
   * returns the title of the event based on the event type
   * @param event
   * @returns string
   */
  private getEventTitle(event: any): string {
    switch (event.type) {
      case EventType.COURSE:
        return event.course_title;
      case EventType.PATIENT_SESSION:
        return 'Patient';
      case EventType.SUPERVISION:
        return 'Supervision';
      case EventType.GROUP_SUPERVISION:
        return 'Gruppensupervision';
      default:
        return '';
    }
  }

  /**
   * getColor
   * returns the color of the event based on the event type
   * @param event
   * @returns { backgroundColor: string, borderColor: string, textColor: string}
   */
  private getColor(event: any): {
    backgroundColor: string;
    borderColor: string;
    textColor: string;
  } {
    if (event.canceled) {
      return CalendarEventColors.canceled;
    }

    switch (event.type) {
      case EventType.COURSE:
        return CalendarEventColors.course;
      case EventType.PATIENT_SESSION:
        return CalendarEventColors.patient;
      case EventType.SUPERVISION:
        return CalendarEventColors.patient;
      case EventType.GROUP_SUPERVISION:
        return CalendarEventColors.patient;
      default:
        return CalendarEventColors.course;
    }
  }

  /**
   * createEventDiv
   * creates the event div
   * @param event
   * @param time
   * @returns VNode<any>
   */
  private createEventDiv(event: EventImpl, time: string): VNode<any> {
    const type = event.extendedProps['eventType'];
    const lecturer = event.extendedProps['lecturer'];
    const chiffre = event.extendedProps['chiffre'];
    const room = event.extendedProps['room'];
    const canceled = event.extendedProps['canceled'];

    const title =
      event.title + (type === EventType.COURSE ? '' : ' - ' + chiffre);

    // get view
    const view = this.calendar.view.type;
    if (view === 'dayGridMonth') {
      return createElement(
        'div',
        {
          class: 'fc-custom-event-month',
          id: 'event',
          style:
            'background-color: ' +
            event.backgroundColor +
            ';' +
            'border-color: ' +
            event.borderColor +
            ';' +
            'color: ' +
            event.textColor +
            ';',
        },
        [title]
      );
    }

    let titleDiv = createElement(
      'div',
      { class: 'fc-custom-event-title' },
      title
    );

    const roomDiv = createElement(
      'div',
      { class: 'fc-custom-event-room' },
      room
        ? 'Raum: ' +
            room.name +
            ' ' +
            (room.floor > 0 ? room.floor + '.OG' : 'EG')
        : ' Raum: Kein Raum'
    );

    let titleRoomDiv = createElement(
      'div',
      { class: 'fc-custom-event-title-room' },
      [titleDiv, roomDiv]
    );

    if (canceled) {
      const canceledDiv = createElement(
        'div',
        {
          class: 'label label-red',
          style: 'width: min-content; margin: 4px 0px',
        },
        createElement(
          'span',
          { class: 'hasomed-text-small label-text' },
          'Abgesagt'
        )
      );

      titleRoomDiv = createElement(
        'div',
        { class: 'fc-custom-event-title-room' },
        [titleDiv, canceledDiv, roomDiv]
      );
    }

    const timeDiv = createElement('div', { class: 'fc-custom-event-time' }, [
      time,
    ]);

    let eventWrapperDiv = createElement(
      'div',
      { class: 'fc-custom-event', id: 'event' },
      [titleRoomDiv, timeDiv]
    );

    return eventWrapperDiv;
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
