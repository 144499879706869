<div class="flex-r gap-16">
  <!-- Table -->
  <div class="flex-c flex-1">
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Course title column -->
        <ng-container matColumnDef="courseTitle">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="gray-600">Kurs</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="courseTitle">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let course" class="title-cell">
            <div class="flex-c cell-overflow-hidden">
              <span
                class="hasomed-text-body text-overflow-hidden"
                [matTooltip]="course.courseTitle">
                {{ course.courseTitle }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- registered column -->
        <ng-container matColumnDef="registered">
          <th mat-header-cell *matHeaderCellDef class="center-header-cell">
            <span>Angemeldet</span>
          </th>
          <td mat-cell *matCellDef="let course" class="center-cell-text">
            <!-- <span class="">{{ course.registered }}</span> -->
            <i class="fa-light fa-square-check table-icon-green"></i>
          </td>
        </ng-container>

        <!-- eLog Status column -->
        <ng-container matColumnDef="eLogStatus">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="center-header-cell">
            <span>Anwesenheit</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="eLogStatus">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let course" class="center-cell-text">
            <div class="elog_status_cell justify-center">
              <app-elog-cell
                [eLogStatusCounts]="course.eLogStatusCounts"></app-elog-cell>
              <button
                type="button"
                class="table-button-icon"
                matTooltip="Anwesenheitsstatus bearbeiten"
                (click)="openELog(course.id_course)">
                <i class="fa-light fa-pen-to-square"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let course">
            <div class="table-button-icons">
              <button
                type="button"
                class="table-button-icon"
                matTooltip="Anwesenheitsstatus bearbeiten"
                (click)="openELog(course.id_course)">
                <i class="fa-light fa-arrow-up-right-from-square"></i>
              </button>
              <button
                *appCheckPermissions="{
                  feature: feature.STUDENT_ADMINISTRATION,
                  permission: permission.ADMIN
                }"
                type="button"
                class="table-button-icon"
                matTooltip="Kanditat*in aus Kurs entfernen"
                (click)="deleteCourse(course.id_course)">
                <i class="fa-light fa-trash-can"></i>
              </button>
              <button
                type="button"
                class="table-button-icon"
                matTooltip="Anwesenheitsstatus bearbeiten"
                (click)="openELog(course.id_course)">
                <i class="fa-light fa-pen"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <div class="mat-cell table-no-match hasomed-text-body font-grey">
              Es wurde kein Kurs gefunden.
            </div>
          </td>
        </tr>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="10"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
    <div class="vertical-spacer"></div>
  </div>

  <!-- Student card -->
  <div class="student-card" *ngIf="!isLoading">
    <span class="heading hasomed-text-small"> Bildungs-ID (BID) </span>
    <div class="content">
      <span class="hasomed-text-body">
        {{ student.userIdentifier ?? '-' }}
      </span>
      <app-copy-to-clipboard
        *ngIf="student.userIdentifier"
        type="Matrikelnummer"
        [textToCopy]="student.userIdentifier">
      </app-copy-to-clipboard>
    </div>

    <span class="heading hasomed-text-small"> Etikett </span>
    <div class="label-container">
      <div
        *ngIf="student.status"
        class="label"
        [style.background]="student.status.color">
        <i class="fa-light fa-id-badge label-icon"></i>
        <span class="label-text">{{ student.status.name }}</span>
      </div>
      <span *ngIf="!student.status" class="hasomed-text-body">-</span>
    </div>

    <span class="heading hasomed-text-small"> Ausbildungsfortschritt </span>
    <div class="content progress">
      <div class="progress-container">
        <div
          class="progress-bar"
          ngClass="progress-orange"
          [ngStyle]="{
            width: (totalEducationProgress?.procentual ?? 0) + '%'
          }"></div>
      </div>
      <span
        class="progress-label"
        [ngClass]="totalEducationProgress?.procentual < 10 ? 'text-red' : ''">
        {{ totalEducationProgress?.procentual ?? '0' }}%
      </span>

      <button
        type="button"
        matTooltip="Details anzeigen"
        class="hasomed-button-icon"
        (click)="showProgressDetails()">
        <i class="fa-light fa-arrow-up-right-from-square"></i>
      </button>
    </div>

    <button
      type="button"
      class="hasomed-text-button hasomed-button-white-grey justify-center"
      (click)="showMore = !showMore">
      <span class="hasomed-button-text">
        {{ showMore ? 'Weniger' : 'Mehr' }} Informationen
      </span>
      <div *ngIf="!showMore">
        <i class="fa-light fa-chevron-down hasomed-button-icon-sm"></i>
      </div>
      <div *ngIf="showMore">
        <i class="fa-light fa-chevron-up hasomed-button-icon-sm"></i>
      </div>
    </button>

    <div *ngIf="showMore" class="show-more flex-c">
      <span class="heading hasomed-text-small"> PiA seit </span>
      <span class="content hasomed-text-body">
        {{ student.entryDate ? (student.entryDate | date: 'dd.MM.YYYY') : '-' }}
      </span>

      <span class="heading hasomed-text-small"> Anschrift </span>
      <div class="content">
        <div *ngIf="!student.address" class="adresse-content">-</div>
        <div *ngIf="student.address" class="adresse-content">
          <span class="hasomed-text-body">
            {{ student.address.street }} {{ student.address.houseNumber }}
          </span>
          <span class="hasomed-text-body">
            {{ student.address.zipCode }} {{ student.address.city }}
          </span>
        </div>
        <app-copy-to-clipboard
          *ngIf="student.address"
          type="Anschrift"
          [textToCopy]="
            (student.address.street ?? '') +
            ' ' +
            (student.address.houseNumber ?? '') +
            ' ' +
            (student.address.zipCode ?? '') +
            ' ' +
            (student.address.city ?? '')
          ">
        </app-copy-to-clipboard>
      </div>

      <span class="heading hasomed-text-small">Email Adresse</span>
      <div class="content">
        <span class="hasomed-text-body">{{ student.email }}</span>
        <app-copy-to-clipboard
          *ngIf="student.email"
          type="Email Adresse"
          [textToCopy]="student.email">
        </app-copy-to-clipboard>
      </div>

      <span class="heading hasomed-text-small">Geburtsdatum</span>
      <div class="content">
        <span class="hasomed-text-body">
          {{
            student.birthdate ? (student.birthdate | date: 'dd.MM.YYYY') : '-'
          }}
        </span>
      </div>

      <span
        *appCheckPermissions="{
          feature: feature.STUDENT_ADMINISTRATION,
          permission: permission.ADMIN
        }"
        class="heading hasomed-text-small">
        Bankverbindung
      </span>
      <div
        *appCheckPermissions="{
          feature: feature.STUDENT_ADMINISTRATION,
          permission: permission.ADMIN
        }"
        class="content">
        <span class="hasomed-text-body">
          {{ bankDetails?.maskedIban ?? '-' }}
        </span>
        <app-copy-to-clipboard
          *ngIf="bankDetails?.iban"
          type="IBAN"
          [textToCopy]="bankDetails?.iban">
        </app-copy-to-clipboard>
      </div>
    </div>

    <button
      *appCheckPermissions="{
        feature: feature.EDIT_MASTER_DATA,
        permission: permission.ADMIN
      }"
      type="button"
      class="hasomed-text-button hasomed-button-white-grey justify-center"
      (click)="openEditMasterDataDialog()">
      <i class="fa-light fa-pen-to-square hasomed-button-icon-sm"></i>
      <span class="hasomed-button-text">Stammdaten bearbeiten</span>
    </button>
  </div>
</div>
