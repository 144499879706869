<div class="dialog flex-c">
  <div class="header flex-r gap-16 align-center">
    <div class="flex-r gap-16 align-center">
      <span class="hasomed-text-h1">
        {{ data.title }}
      </span>
      <div *ngIf="data.canceled" class="label label-red">
        <i class="fa-light fa-ban label-icon"></i>
        <span class="hasomed-text-small label-text"> Abgesagt </span>
      </div>
    </div>
    <button
      type="button"
      class="hasomed-button-icon"
      matTooltip="Schließen"
      (click)="onClose()">
      <i class="fa-light fa-x"></i>
    </button>
  </div>

  <form class="dialog-form" [formGroup]="eventForm">
    <!-- Date -->
    <div class="flex-c gap-24 scrollable-content">
      <div class="formular-row">
        <label for="date" class="formular-row-label">
          <div class="label-title">Datum</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="date"
              class="hasomed-text-input flex-grow"
              formControlName="date"
              readonly />
          </div>
        </div>
      </div>

      <!-- room -->
      <div class="formular-row">
        <label for="room" class="formular-row-label">
          <div class="label-title">Raum</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="room"
              class="hasomed-text-input flex-grow"
              formControlName="room"
              readonly />
          </div>
        </div>
      </div>

      <!-- chiffre -->
      <div class="formular-row" *ngIf="eventForm.get('chiffre').value">
        <label for="chiffre" class="formular-row-label">
          <div class="label-title">Chiffre</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="chiffre"
              class="hasomed-text-input flex-grow"
              formControlName="chiffre"
              readonly />
          </div>
        </div>
      </div>

      <!-- supervisor -->
      <div class="formular-row" *ngIf="eventForm.get('supervisor').value">
        <label for="supervisor" class="formular-row-label">
          <div class="label-title">Supervisor*in</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="supervisor"
              class="hasomed-text-input flex-grow"
              formControlName="supervisor"
              readonly />
          </div>
        </div>
      </div>

      <!-- lecturer -->
      <div class="formular-row" *ngIf="eventForm.get('lecturer').value">
        <label for="lecturer" class="formular-row-label">
          <div class="label-title">Lehrpersonal</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="lecturer"
              class="hasomed-text-input flex-grow"
              formControlName="lecturer"
              readonly />
          </div>
        </div>
      </div>

      <!-- treatmentCaseStudent -->
      <div
        class="formular-row"
        *ngIf="eventForm.get('treatmentCaseStudent').value">
        <label for="treatmentCaseStudent" class="formular-row-label">
          <div class="label-title">Kandidat*in</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="treatmentCaseStudent"
              class="hasomed-text-input flex-grow"
              formControlName="treatmentCaseStudent"
              readonly />
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="formular-buttons flex-c gap-40">
      <div
        *ngIf="
          (data.type === eventType.PATIENT_SESSION ||
            data.type === eventType.SUPERVISION ||
            data.type === eventType.GROUP_SUPERVISION) &&
          userService.currentUserIsStudent()
        "
        class="flex-r flex-1 justify-space-between"
        [class.justify-end]="data.isTreatmentCaseView">
        <button
          *ngIf="!data.isTreatmentCaseView"
          type="button"
          class="hasomed-text-button hasomed-button-primary"
          (click)="onManageAppointment()">
          <i class="hasomed-button-icon-sm fa-light fa-calendar-plus"></i>
          <span class="hasomed-button-text"> Termin verwalten </span>
        </button>

        <button
          [disabled]="data.canceled"
          type="button"
          class="hasomed-text-button hasomed-button-red"
          (click)="onCancelAppointment()">
          <i class="hasomed-button-icon-sm fa-light fa-ban"></i>
          <span class="hasomed-button-text"> Termin absagen </span>
        </button>
      </div>

      <div class="flex-r flex-1" *ngIf="data.type === eventType.COURSE">
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white-grey button-full-width"
          (click)="onOpenCourseDetail()">
          <i
            class="hasomed-button-icon-sm fa-light fa-arrow-up-right-from-square"></i>
          <span class="hasomed-button-text"> Kurs Detailansicht aufrufen </span>
        </button>
      </div>

      <div class="flex-r flex-1 justify-end">
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white-grey"
          (click)="onClose()">
          <i class="hasomed-button-icon-sm fa-light fa-x"></i>
          <span class="hasomed-button-text">Schließen</span>
        </button>
      </div>
    </div>
  </form>
</div>
