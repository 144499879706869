import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/configs/environment';
import { Observable, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { Role } from '../models/permission.model';
import { CustomFileType } from '../models/custom-file-type.model';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { FileService } from './file.service';
import { DecryptionService } from './decryption.service';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private cryptoService: CryptoService,
    private fileService: FileService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  public currentUser: User;

  /**
   * Updates user information
   * @param user
   * @returns Observable
   */
  public async updateUser(user: User): Promise<Observable<any>> {
    // encrypt files
    user.files =
      user.files &&
      (await Promise.all(
        user.files.map(
          async (file: CustomFileType): Promise<CustomFileType> => {
            return {
              ...file,
              filename:
                file.filename &&
                (await this.cryptoService.encrypt(file.filename)),
              file: file.file && (await this.cryptoService.encrypt(file.file)),
            };
          }
        )
      ));

    const formData = new FormData();
    user.id && formData.append('id_user', user.id.toString());
    user.id_institute &&
      formData.append('id_institute', user.id_institute.toString());
    formData.append('email', user.email);
    formData.append('username', user.email);
    user.id_role && formData.append('id_role', user.id_role.toString());
    formData.append('isSuperadmin', user.isSuperadmin ? '1' : '0');
    user.name.genderTitle &&
      formData.append('genderTitle', user.name.genderTitle);
    user.name.academicTitle &&
      formData.append('academicTitle', user.name.academicTitle);
    user.name.firstname &&
      formData.append(
        'firstname',
        await this.cryptoService.encrypt(user.name.firstname)
      );
    user.name.lastname &&
      formData.append(
        'lastname',
        await this.cryptoService.encrypt(user.name.lastname)
      );
    user.phone && formData.append('phone', user.phone);
    user.address?.street &&
      formData.append(
        'street',
        await this.cryptoService.encrypt(user.address.street)
      );
    user.address?.houseNumber &&
      formData.append(
        'houseNumber',
        await this.cryptoService.encrypt(user.address.houseNumber)
      );
    user.address?.addressAddition &&
      formData.append(
        'addressAddition',
        await this.cryptoService.encrypt(user.address.addressAddition)
      );
    user.address?.zipCode &&
      formData.append(
        'zipCode',
        await this.cryptoService.encrypt(user.address.zipCode)
      );
    user.address?.city &&
      formData.append(
        'city',
        await this.cryptoService.encrypt(user.address.city)
      );
    user.address?.country &&
      formData.append(
        'country',
        await this.cryptoService.encrypt(user.address.country)
      );
    user.status &&
      formData.append('id_status_label', user.status?.id.toString());
    user.userIdentifier &&
      formData.append('userIdentifier', user.userIdentifier);
    user.educationCourse &&
      formData.append('educationCourseId', user.educationCourse?.id.toString());
    user.profilePicture &&
      formData.append(
        'profilePicture',
        await this.cryptoService.encrypt(user.profilePicture)
      );
    user.birthdate &&
      formData.append('birthdate', moment(user.birthdate).format('DD.MM.YYYY'));
    user.entryDate &&
      formData.append('entryDate', moment(user.entryDate).format('DD.MM.YYYY'));
    formData.append('measlesProtection', user.measlesProtection ? '1' : '0');
    formData.append(
      'additionalQualifications',
      JSON.stringify(user.additionalQualifications)
    );
    user.files && formData.append('files', JSON.stringify(user.files));

    return this.http.post(
      environment.authority_short + 'user/updateUser.php',
      formData
    );
  }

  /**
   * updateUserRole
   * updates the role of a user
   * @param id_user
   * @param id_role
   * @param id_institute
   * @returns Observable
   */
  public updateUserRole(
    id_user: number,
    id_role: number,
    id_institute: number
  ): Observable<any> {
    const formdata = new FormData();
    formdata.append('id_user', id_user.toString());
    formdata.append('id_role', id_role.toString());
    formdata.append('id_institute', id_institute.toString());

    console.debug('formdata', formdata);

    return this.http.post(
      environment.authority_short + 'user/updateUserRole.php',
      formdata
    );
  }

  /**
   * getUserInvoices
   * gets all invoices of a user
   * @param id_user
   * @returns Observable
   */
  public getUserInvoices(id_user: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'user/getUserInvoices.php?id_user=' +
          id_user.toString()
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * updateCurrentUser
   * @param userData
   */
  public async updateCurrentUser(userData: User) {
    //console.debug('newUserData', userData);
    this.currentUser = await this.parseBackendUser(userData);
  }

  /**
   * updateInstituteFromUser
   * updates the institute of a user
   * @param id_institute
   */
  public updateInstituteFromUser(
    id_institute: number,
    id_user: number
  ): Observable<any> {
    const formdata = new FormData();
    formdata.append('id_institute', id_institute.toString());
    formdata.append('id_user', id_user.toString());

    return this.http.post(
      environment.authority_short + 'user/updateInstituteFromUser.php',
      formdata
    );
  }

  /**
   * switchUsersInstitute
   * switches the institute of a user
   * @param id_institute
   * @param id_user
   */
  public switchUsersInstitute(
    id_institute: number,
    id_user: number
  ): Observable<any> {
    const formdata = new FormData();
    formdata.append('id_institute', id_institute.toString());
    formdata.append('id_user', id_user.toString());

    return this.http.post(
      environment.authority_short + 'user/switchUsersInstitute.php',
      formdata
    );
  }

  /**
   * getUserById
   * gets a user by the given id
   * @param user_id
   * @param expands
   * @returns
   */
  public getUserById(user_id: number, expands?: string): Observable<any> {
    const expand = expands ? `&expand=${expands}` : '';
    return this.http
      .get(
        environment.authority_short +
          `user/getUser.php?id_user=${user_id}` +
          expand
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createUser
   * creates a new user with the given data
   * @param userData
   * @returns
   */
  public createUser(userData: User): Observable<any> {
    const formdata = new FormData();
    formdata.append('mode', 'new_user');
    formdata.append('username', userData.email);
    formdata.append('email', userData.email);
    formdata.append('id_role', userData.id_role.toString());
    formdata.append('id_institute', userData.id_institute.toString());

    return this.http.post(
      environment.authority_short + 'user/createUser.php',
      formdata
    );
  }

  /**
   * deleteUser
   * deletes a user with the given id
   * @param user_id
   * @returns
   */
  public deleteUser(user_id: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short + `user/deleteUser.php?id_user=${user_id}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createInstituteUsers
   * creates multiple users for an institute
   * @param data
   * @returns
   */
  public createInstituteUsers(data: any): Observable<any> {
    console.debug('Institute Users Data:', data);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.authority_short + 'user/createInstituteUsers.php',
      data,
      { headers: headers }
    );
  }

  // get all users from an institute
  public getAllUsersByInstitute(id_institute: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `user/getAllUsersByInstitute.php?id_institute=${id_institute}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  // get all users without institute
  public getAllUsersWithoutInstituteOrRole(): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `user/getAllUsersWithoutInstituteOrRole.php`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  // get all users from an institute by role id
  public getInstituteUsersByRole(
    id_role: number,
    profilePicture: boolean,
    expands?: string
  ): Observable<any> {
    const id_institute = this.currentUser.id_institute;
    return this.http
      .get(
        environment.authority_short +
          `user/getInstituteUsersByRole.php?id_role=${id_role}&id_institute=${id_institute}&profilePicture=${profilePicture}&expand=${expands}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  // get all supervisors from an institute
  public getInstituteSupervisors(id_institute: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `user/getInstituteSupervisors.php?id_institute=${id_institute}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createUserIdentifier
   * creates a user identifiert for the given user
   * @param user
   * @returns The user identifier
   */
  public createUserIdentifier(user: User): string {
    let userIdentifier = null;
    if (user.userIdentifier) {
      return user.userIdentifier;
    }
    if (user.name.firstname && user.name.lastname && user.birthdate) {
      if (user.educationCourse?.id && user.id_role === 4) {
        userIdentifier =
          user.name.firstname.charAt(0) +
          user.name.lastname.charAt(0) +
          moment(user.birthdate).format('DDMMYYYY') +
          user.educationCourse.id +
          user.id;
      } else if (user.id_role !== 4) {
        userIdentifier =
          user.name.firstname.charAt(0) +
          user.name.lastname.charAt(0) +
          moment(user.birthdate).format('DDMMYYYY') +
          user.id;
      }
    }
    return userIdentifier;
  }

  /**
   * parseBackendUser
   * parses the user data from the backend and decrypts the encrypted data
   * @param userData
   * @returns User
   */
  public async parseBackendUser(userData: User): Promise<User> {
    // console.debug('Parsing user:', userData);
    return {
      id: userData.id,
      id_institute: userData.id_institute,
      email: userData.email,
      id_role: userData.id_role,
      isSuperadmin: Boolean(userData.isSuperadmin),
      name: {
        academicTitle: userData.name.academicTitle,
        genderTitle: userData.name.genderTitle,
        firstname: await this.decryptionService.decryptString(
          userData.name.firstname
        ),
        lastname: await this.decryptionService.decryptString(
          userData.name.lastname
        ),
      },
      birthdate: userData.birthdate
        ? moment(userData.birthdate, 'DD.MM.YYYY').toDate()
        : null,
      profilePicture:
        userData.profilePicture && typeof userData.profilePicture === 'string'
          ? await this.decryptionService.decryptString(userData.profilePicture)
          : null,
      educationCourse: userData.educationCourse,
      courses: userData.courses,
      userIdentifier: userData.userIdentifier,
      entryDate: userData.entryDate
        ? moment(userData.entryDate, 'DD.MM.YYYY').toDate()
        : null,
      address: userData.address && {
        street: await this.decryptionService.decryptString(
          userData.address.street
        ),
        houseNumber: await this.decryptionService.decryptString(
          userData.address.houseNumber
        ),
        addressAddition: await this.decryptionService.decryptString(
          userData.address.addressAddition
        ),
        zipCode: await this.decryptionService.decryptString(
          userData.address.zipCode
        ),
        city: await this.decryptionService.decryptString(userData.address.city),
        country: await this.decryptionService.decryptString(
          userData.address.country
        ),
      },
      phone: userData.phone,
      status: userData.status,
      isRegistered: Boolean(userData.isRegistered),
      isInvited: Boolean(userData.isInvited),
      measlesProtection: Boolean(userData.measlesProtection),
      additionalQualifications: userData.additionalQualifications,
      timeCreated: userData.timeCreated
        ? moment(userData.timeCreated, 'YYYY-MM-DD HH:mm:ss').toDate()
        : null,
      timeLastLogin: userData.timeLastLogin
        ? moment(userData.timeLastLogin, 'YYYY-MM-DD HH:mm:ss').toDate()
        : null,
      files: userData.files
        ? await Promise.all(
            userData.files?.map(async (it: any): Promise<CustomFileType> => {
              return await this.fileService.parseBackendFile(it);
            })
          )
        : null,
    };
  }

  /**
   * currentUserIsStudent
   * checks if the current user is a student
   * @returns boolean
   */
  public currentUserIsStudent(): boolean {
    return this.currentUser.id_role === Role.STUDENT;
  }

  /**
   * currentUserisAdministrator
   * checks if the current user is an administrator
   * @returns boolean
   */
  public currentUserIsAdministrator(): boolean {
    return this.currentUser.id_role === Role.ADMINISTRATOR;
  }

  /**
   * currentUserIsSuperadmin
   * checks if the current user is a superadmin
   * @returns boolean
   */
  public currentUserIsSuperadmin(): boolean {
    return this.currentUser.isSuperadmin === true;
  }

  /**
   * currentUserIsLecturer
   * checks if the current user is a lecturer
   * @returns boolean
   */
  public currentUserIsLecturer(): boolean {
    return this.currentUser.id_role === Role.LECTURER;
  }

  /**
   * inviteUser
   * invites a user with the given data
   * @param user
   * @returns
   */
  public inviteUser(user: any): Observable<any> {
    const formdata = new FormData();
    formdata.append('email', user.email.toString());
    user.name?.firstname && formdata.append('firstname', user.name.firstname);
    user.name?.lastname && formdata.append('lastname', user.name.lastname);

    return this.http.post(
      environment.authority_short + 'user/inviteUser.php',
      formdata
    );
  }

  /**
   * getUserAdditionalQualifications
   * gets all additional qualifications
   * @returns Observable<any>
   */
  public getUserAdditionalQualifications(): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'additionalQualifications/getAllAdditionalQualifications.php'
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }
}
