import { Injectable } from '@angular/core';
import { SelfAwarenessEntry } from '../models/self-awareness-entry.model';
import { Observable, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/configs/environment';
import * as moment from 'moment';
import { CustomFileType } from '../models/custom-file-type.model';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { FileService } from './file.service';
import { DecryptionService } from './decryption.service';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class SelfAwarenessService {
  constructor(
    private http: HttpClient,
    private cryptoService: CryptoService,
    private fileService: FileService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getSelfAwarenessEntriesFromStudent
   * Gets all self awareness entries from a student
   * @param id_student
   * @returns
   */
  public getSelfAwarenessEntriesFromStudent(
    id_student: number
  ): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'selfAwareness/getAllSelfAwarenessEntriesFromStudent.php?id_student=' +
          id_student
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getSelfAwarenessEntry
   * Gets a self awareness entry by id
   * @param id
   * @param includeFileData
   * @returns
   */
  public getSelfAwarenessEntry(id: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          `selfAwareness/getSelfAwarenessEntry.php?id=${id}`
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createSelfAwarenessEntry
   * Creates a new self awareness entry for a student
   * @param selfAwareness
   * @param id_student
   * @param id_institute
   * @returns
   */
  public async createSelfAwarenessEntry(
    selfAwareness: SelfAwarenessEntry,
    id_student: number,
    id_institute: number
  ): Promise<Observable<any>> {
    // encrypt files
    selfAwareness.files =
      selfAwareness.files &&
      (await Promise.all(
        selfAwareness.files.map(
          async (file: CustomFileType): Promise<CustomFileType> => {
            return {
              ...file,
              filename:
                file.filename &&
                (await this.cryptoService.encrypt(file.filename)),
              file: file.file && (await this.cryptoService.encrypt(file.file)),
            };
          }
        )
      ));

    const formData = new FormData();
    formData.append('id_student', id_student.toString());
    formData.append('id_institute', id_institute.toString());
    formData.append(
      'title',
      await this.cryptoService.encrypt(selfAwareness.title)
    );
    selfAwareness.description &&
      formData.append(
        'description',
        await this.cryptoService.encrypt(selfAwareness.description)
      );
    formData.append(
      'responsible_person',
      await this.cryptoService.encrypt(selfAwareness.responsiblePerson)
    );
    formData.append(
      'date',
      moment(selfAwareness.date).format('YYYY-MM-DD HH:mm:ss')
    );
    formData.append('duration', selfAwareness.duration.toString());
    selfAwareness.internalNote &&
      formData.append(
        'internal_note',
        await this.cryptoService.encrypt(selfAwareness.internalNote)
      );
    selfAwareness.files &&
      formData.append('files', JSON.stringify(selfAwareness.files));

    return this.http.post(
      environment.authority_short +
        'selfAwareness/createSelfAwarenessEntry.php',
      formData
    );
  }

  /**
   * updateSelfAwareness
   * Updates a self awareness entry
   * @param selfAwareness
   * @param id
   * @returns
   */
  public async updateSelfAwareness(
    selfAwareness: SelfAwarenessEntry,
    id: number
  ): Promise<Observable<any>> {
    // encrypt files
    selfAwareness.files =
      selfAwareness.files &&
      (await Promise.all(
        selfAwareness.files.map(
          async (file: CustomFileType): Promise<CustomFileType> => {
            return {
              ...file,
              filename:
                file.filename &&
                (await this.cryptoService.encrypt(file.filename)),
              file: file.file && (await this.cryptoService.encrypt(file.file)),
            };
          }
        )
      ));

    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append(
      'title',
      await this.cryptoService.encrypt(selfAwareness.title)
    );
    selfAwareness.description &&
      formData.append(
        'description',
        await this.cryptoService.encrypt(selfAwareness.description)
      );
    formData.append(
      'responsible_person',
      await this.cryptoService.encrypt(selfAwareness.responsiblePerson)
    );
    formData.append(
      'date',
      moment(selfAwareness.date).format('YYYY-MM-DD HH:mm:ss')
    );
    formData.append('duration', selfAwareness.duration.toString());
    selfAwareness.internalNote &&
      formData.append(
        'internal_note',
        await this.cryptoService.encrypt(selfAwareness.internalNote)
      );
    selfAwareness.files &&
      formData.append('files', JSON.stringify(selfAwareness.files));

    return this.http.post(
      environment.authority_short +
        'selfAwareness/updateSelfAwarenessEntry.php',
      formData
    );
  }

  /**
   * deleteSelfAwarenessEntry
   * Deletes a self awareness entry
   * @param id
   * @returns
   */
  public deleteSelfAwarenessEntry(id: number): Observable<any> {
    const formData = new FormData();
    formData.append('id', id.toString());

    return this.http.post(
      environment.authority_short +
        'selfAwareness/deleteSelfAwarenessEntry.php',
      formData
    );
  }

  /**
   * parseBackendSelfAwarenessEntry
   * @param selfAwareness
   * @returns
   */
  public async parseBackendSelfAwarenessEntry(
    selfAwareness: any
  ): Promise<SelfAwarenessEntry> {
    return {
      id: selfAwareness.id,
      title: await this.decryptionService.decryptString(selfAwareness.title),
      description: await this.decryptionService.decryptString(
        selfAwareness.description
      ),
      responsiblePerson: await this.decryptionService.decryptString(
        selfAwareness.responsiblePerson
      ),
      date: selfAwareness.date
        ? moment(selfAwareness.date, 'YYYY-MM-DD HH:mm:ss').toDate()
        : null,
      duration: selfAwareness.duration,
      internalNote: await this.decryptionService.decryptString(
        selfAwareness.internalNote
      ),
      files: selfAwareness.files
        ? await Promise.all(
            selfAwareness.files?.map(
              async (it: any): Promise<CustomFileType> => {
                return await this.fileService.parseBackendFile(it);
              }
            )
          )
        : null,
    };
  }
}
