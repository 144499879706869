import { Injectable } from '@angular/core';
import { EventDate } from '../models/event.model';
import { UserService } from './user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/configs/environment';
import { Observable, takeUntil } from 'rxjs';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getAllInstituteEventDatesForRoomPlanning
   * @returns all event dates of institute
   */
  public getAllInstituteEventDatesForRoomPlanning(
    mode: string,
    endDate?: Date
  ): Observable<any> {
    const id_institute = this.userService.currentUser.id_institute;
    let url =
      environment.authority_short +
      'event/getAllEventDatesForRoomPlanning.php?id_institute=' +
      id_institute +
      '&mode=' +
      mode;

    if (endDate) {
      url += '&endDate=' + endDate;
    }

    return this.http
      .get(url)
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getAllUserEventDates
   * @returns all event dates of user
   */
  public getAllUserEventDates(): Observable<any> {
    const id_user = this.userService.currentUser.id;
    return this.http
      .get(
        environment.authority_short +
          'event/getAllUserEventDates.php?id_user=' +
          id_user
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getAllInstituteEventDates
   * @returns all event dates of institute
   */
  public getAllInstituteEventDates(): Observable<any> {
    const id_institute = this.userService.currentUser.id_institute;
    return this.http
      .get(
        environment.authority_short +
          'event/getAllInstituteEventDates.php?id_institute=' +
          id_institute
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getAllLecturerEventDates
   * @param id_lecturer
   * @returns all event dates of lecturer
   */
  public getAllLecturerEventDates(): Observable<any> {
    const id_lecturer = this.userService.currentUser.id;
    return this.http
      .get(
        environment.authority_short +
          'event/getAllLecturerEventDates.php?id_lecturer=' +
          id_lecturer
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * updateEventDates
   */
  public updateEventDates(eventDates: EventDate[]): Observable<any> {
    console.debug('eventDates update post data: ', eventDates);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      environment.authority_short + 'event/updateEventDates.php',
      eventDates,
      { headers: headers }
    );
  }

  /**
   * getAllInstituteEvents
   */
  public getAllInstituteEvents(): Observable<any> {
    const id_institute = this.userService.currentUser.id_institute;
    return this.http
      .get(
        environment.authority_short +
          'event/getAllInstituteEvents.php?id_institute=' +
          id_institute
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getEventDatesByRoomId
   * @param id_room
   * @returns all upcoming event dates of room
   */
  public getEventDatesByRoomId(id_room: number): Observable<any> {
    return this.http
      .get(
        environment.authority_short +
          'event/getEventDatesFromRoom.php?id_room=' +
          id_room
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * deleteEventDate
   * @param id_eventDate
   */
  public deleteEventDate(id_eventDate: number): Observable<any> {
    const formData = new FormData();
    formData.append('id_event', id_eventDate.toString());

    return this.http.post(
      environment.authority_short + 'event/deleteEvent.php',
      formData
    );
  }

  /**
   *
   * cancelEventDate
   * @param id_event_date
   * @returns
   */
  public cancelEventDate(id_event_date: number): Observable<any> {
    const formData = new FormData();
    formData.append('id_event_date', id_event_date.toString());

    return this.http.post(
      environment.authority_short + 'event/cancelEventDate.php',
      formData
    );
  }

  /**
   * removeRoomFromFutureEventDates
   * @param id_room
   * @returns Observable<any>
   */
  public removeRoomFromFutureEventDates(id_room: number): Observable<any> {
    const formData = new FormData();
    formData.append('id_room', id_room.toString());

    return this.http.post(
      environment.authority_short + 'event/removeRoomFromFutureEventDates.php',
      formData
    );
  }
}
