import { Pipe, PipeTransform } from '@angular/core';
import { Label } from '../models/label.model';
import * as moment from 'moment';
import { Filter, FilterType, FilterDateRange } from '../models/filter.model';
import { User } from '../models/user.model';

@Pipe({
  name: 'filterLecturer',
})
export class FilterLecturerPipe implements PipeTransform {
  transform(lecturers: User[], filter: Filter[]): User[] {
    if (!lecturers || !filter) {
      return lecturers;
    }

    return lecturers.filter(lecturer => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.DATE_RANGE:
            const start = moment((filter.value as FilterDateRange).start)
              .startOf('day')
              .toDate();
            const end = moment((filter.value as FilterDateRange).end)
              .endOf('day')
              .toDate();
            return (
              moment(lecturer.entryDate).isSameOrAfter(start) &&
              moment(lecturer.entryDate).isSameOrBefore(end)
            );
          case FilterType.LABEL:
            if (this.isLabel(filter.value)) {
              return lecturer.status?.id === filter.value.id;
            }
            return false;
          case FilterType.PENDING_ELOGS:
            return lecturer.courses?.some(
              course => course.eLogStatusCounts.pending > 0
            );
          default:
            return true;
        }
      });
    });
  }

  private isLabel(obj: any): obj is Label {
    return obj && typeof obj === 'object' && 'name' in obj && 'color' in obj;
  }
}
