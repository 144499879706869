import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  NewsArticle,
  NewsArticleType,
} from 'src/app/models/news-article.model';
import { AlertService } from 'src/app/services/alert.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { NewsArticleService } from 'src/app/services/news-article.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import { ImageCropperDialogComponent } from 'src/app/components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { Subject, takeUntil } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-create-edit-news-article',
  templateUrl: './create-edit-news-article.component.html',
  styleUrls: ['./create-edit-news-article.component.scss'],
})
export class CreateEditNewsArticleComponent implements OnInit, OnDestroy {
  public newsForm: FormGroup;
  public initialFormValues: {};
  public uploadedImage: File;
  public newsArticles: NewsArticle[];
  public currentArticle: NewsArticle;
  private newNewsArticle: NewsArticle;
  public availableNewsArticleTypes: NewsArticleType[];
  public editMode = false;
  public isLoading = true;

  public requiredFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];

  // import from form.utils.ts
  public isRequired = isRequired;

  public tinyMceSettings = {
    toolbar:
      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | help',
    help_tabs: ['shortcuts'],
    menubar: false,
  };

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.newsForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private newsArticleService: NewsArticleService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    // create form and set data to input fields
    this.getArticleTypes();
    this.createForm();
    if (this.activatedRoute.snapshot.params['id']) {
      this.editMode = true;
      const id = +atob(this.activatedRoute.snapshot.params['id']);
      this.newsArticleService
        .getNewsArticleById(id)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            console.debug('getNewsArticleById backend response', response);
            if (response.success) {
              this.currentArticle =
                await this.newsArticleService.parseBackendNewsArticle(
                  response.data
                );

              this.newsForm.patchValue({
                title: this.currentArticle.title,
                link: this.currentArticle.link,
                tinyMCE: this.currentArticle.text,
                type: this.currentArticle.type?.id,
                articlePicture: this.currentArticle.picture,
              });

              this.initialFormValues = this.newsForm.value;
              console.debug('isArticleDraft', this.currentArticle.published);
              this.isLoading = false;
            } else {
              console.error('getNewsArticleById backend error', response);
              this.alertService.showErrorAlert(
                'Fehler',
                'Artikel konnte nicht geladen werden'
              );
              this.onCancel();
            }
          },
          error: error => {
            console.error('getNewsArticleById backend error', error);
            this.onCancel();
          },
        });
    }
    this.initialFormValues = this.newsForm.value;
  }

  /**
   * createForm
   * creates the form for the news article
   */
  private createForm() {
    this.newsForm = new FormGroup({
      title: new FormControl(this.currentArticle?.title, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      link: new FormControl(
        this.currentArticle?.link,
        Validators.maxLength(2000)
      ),
      articlePicture: new FormControl(this.currentArticle?.picture),
      tinyMCE: new FormControl(this.currentArticle?.text, [
        Validators.required,
        Validators.maxLength(10000),
      ]),
      type: new FormControl(
        this.currentArticle?.type?.id.toString() ?? null,
        Validators.required
      ),
    });
  }

  /**
   * getArticleTypes
   * get all available news article types
   */
  private getArticleTypes() {
    this.newsArticleService
      .getAllNewsTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          console.debug('getAllNewsTypes backend response', response);
          if (response.success) {
            this.availableNewsArticleTypes = response.data;
          } else {
            console.error('getAllNewsTypes backend error', response);
          }
        },
        error: error => {
          console.error('getAllNewsTypes backend error', error);
        },
      });
  }

  /**
   * buildNewArticle
   * builds a new news article object
   * @param publish
   */
  private buildNewArticle(publish?: boolean) {
    this.newNewsArticle = null;

    try {
      this.newNewsArticle = {
        id: this.currentArticle?.id ?? null,
        title: this.newsForm.value.title,
        link: this.newsForm.value.link,
        picture: this.newsForm.value.articlePicture,
        text: this.newsForm.value.tinyMCE,
        type: this.newsForm.value.type,
        createdBy: this.userService.currentUser,
        published: Boolean(publish ?? false),
      };
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
   * onSubmit
   * submits the form, creates or updates the news article and navigates back
   * @param publish
   * @returns
   */
  public async onSubmit(publish?: boolean) {
    // check if form is valid
    const formInvalid =
      this.formSubmitValidationService.validateFormAndScrollToError(
        this.newsForm
      );

    if (formInvalid) {
      return;
    }

    const publishArticle = publish ?? Boolean(this.currentArticle?.published);
    this.buildNewArticle(publishArticle);
    this.initialFormValues = this.newsForm.value;

    this.editMode
      ? await this.editNewsArticle()
      : await this.createNewsArticle();
  }

  /**
   * createNewsArticle
   * creates a new news article
   * @returns void
   */
  private async createNewsArticle(): Promise<void> {
    const createNewsArticleObservable =
      await this.newsArticleService.createNewsArticle(this.newNewsArticle);

    createNewsArticleObservable.subscribe({
      next: response => {
        console.debug('createNewsArticle backend response', response);
        if (!response.success) {
          console.error('createNewsArticle backend error', response);
          this.alertService.showErrorAlert(
            'Fehler',
            `Der Artikel '${this.newNewsArticle.title}' konnte nicht erstellt werden`
          );
        }

        this.alertService.showSuccessAlert(
          'Artikel erstellt',
          `Der Artikel '${this.newNewsArticle.title}' wurde erfolgreich erstellt`
        );
        const id = response.data;
        this.onCancel(id);
      },
      error: error => {
        console.error('createNewsArticle backend error', error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Der Artikel '${this.newNewsArticle.title}' konnte nicht erstellt werden`
        );
      },
    });
  }

  /**
   * editNewsArticle
   * updates an existing news article
   * @returns void
   */
  private async editNewsArticle(): Promise<void> {
    const updateNewsArticleObservable =
      await this.newsArticleService.updateNewsArticle(this.newNewsArticle);
    updateNewsArticleObservable.subscribe({
      next: response => {
        console.debug('updateNewsArticle backend response', response);
        if (response.success) {
          this.alertService.showSuccessAlert(
            'Artikel bearbeitet',
            `Der Artikel '${this.newNewsArticle.title}' wurde erfolgreich bearbeitet`
          );
          this.onCancel();
        } else {
          console.error('updateNewsArticle backend error', response);
          this.alertService.showErrorAlert(
            'Fehler',
            `Der Artikel '${this.newNewsArticle.title}' konnte nicht bearbeitet werden`
          );
        }
      },
      error: error => {
        console.error('updateNewsArticle backend error', error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Der Artikel '${this.newNewsArticle.title}' konnte nicht bearbeitet werden`
        );
      },
    });
  }

  /**
   * onCancel
   * navigates back to the parent route
   * @param id_newsArticle
   * @param deleteAction
   */
  public onCancel(id_newsArticle?: number, deleteAction?: boolean) {
    if (this.editMode) {
      this.router.navigate(
        [
          '../../',
          deleteAction || !this.currentArticle?.id
            ? ''
            : btoa(this.currentArticle?.id.toString()),
        ],
        {
          relativeTo: this.activatedRoute,
        }
      );
    } else {
      this.router.navigate(['../', btoa(id_newsArticle.toString())], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  /**
   * onPublish
   * publishes the current article
   * @returns void
   */
  public onPublish(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Veröffentlichen',
        message:
          this.currentArticle && this.currentArticle?.published
            ? 'Möchten Sie Ihre Änderungen wirklich veröffentlichen?'
            : 'Möchten Sie den Entwurf wirklich veröffentlichen?',
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.onSubmit(true);
      }
    });
  }

  /**
   * onUnpublish
   * unpublishes the current article
   * @param newsArticle
   */
  public onUnpublish(newsArticle: { id: number }): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Verbergen',
        message: 'Möchten Sie den Artikel wirklich verbergen?',
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.onSubmit(false);
      }
    });
  }

  /**
   * onDelete
   * deletes the current article
   * @param newsArticle
   */
  public onDelete(newsArticle: NewsArticle): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Löschen',
        message: 'Möchten Sie den Artikel wirklich löschen?',
      },
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.newsArticleService.deleteNewsArticle(newsArticle.id).subscribe({
          next: response => {
            console.debug('deleteNewsArticle backend response', response);
            if (response.success) {
              this.alertService.showSuccessAlert(
                'Artikel gelöscht',
                `Der Artikel ''${newsArticle.title}'' wurde erfolgreich gelöscht`
              );
              this.onCancel(null, true);
            } else {
              console.error('deleteNewsArticle backend error', response);
              this.alertService.showErrorAlert(
                'Fehler',
                `Der Artikel ''${newsArticle.title}'' konnte nicht gelöscht werden`
              );
            }
          },
          error: error => {
            console.error('deleteNewsArticle backend error', error);
            this.alertService.showErrorAlert(
              'Fehler',
              `Der Artikel ''${newsArticle.title}'' konnte nicht gelöscht werden`
            );
          },
        });
      }
    });
  }

  /**
   * onCreateNewArticle
   * saves the current article as draft and navigates to the create new article page
   */
  public async onCreateNewArticle(): Promise<void> {
    this.buildNewArticle(false);
    this.initialFormValues = this.newsForm.value;

    const createNewsArticleObservable =
      await this.newsArticleService.createNewsArticle(this.newNewsArticle);
    createNewsArticleObservable.subscribe({
      next: response => {
        console.debug('createNewsArticle backend response', response);
        if (response.success) {
          this.alertService.showSuccessAlert(
            'Entwurf gespeichert',
            `Der Artikel '${this.newNewsArticle.title}' wurde erfolgreich als Entwurf gespeichert`
          );
          this.currentArticle = null;
          this.newsForm.reset();
          this.initialFormValues = this.newsForm.value;
        } else {
          console.error('createNewsArticle backend error', response);
          this.alertService.showErrorAlert(
            'Fehler',
            `Der Artikel '${this.newNewsArticle.title}' konnte nicht erstellt werden`
          );
        }
      },
      error: error => {
        console.error('createNewsArticle backend error', error);
        this.alertService.showErrorAlert(
          'Fehler',
          `Der Artikel '${this.newNewsArticle.title}' konnte nicht erstellt werden`
        );
      },
    });
  }

  /**
   * imageChangeEvent
   * opens the image cropper dialog and sets the cropped image as article picture
   * @param event
   */
  public imageChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '500px',
      data: {
        image: event,
        title: 'Artikelbild zuschneiden',
        round: false,
        height: 500,
        aspectRatio: 11 / 5,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.newsForm.get('articlePicture').setValue(result);
      }
    });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }

    return this.formDeactivateService.confirmDeactivation(
      this.newsForm.value,
      this.initialFormValues
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
