import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { InstituteService } from 'src/app/services/institute.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-onb-user-role-table',
  templateUrl: './onb-user-role-table.component.html',
  styleUrls: ['./onb-user-role-table.component.scss'],
})
export class ONBUserRoleTableComponent {
  public displayedColumns: string[] = ['email', 'role', 'actions'];
  public dataSource: MatTableDataSource<User> = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public instituteService: InstituteService,
    private userService: UserService
  ) {}

  public ngOnInit() {
    this.getInstituteUsers();
  }

  private getInstituteUsers() {
    const id_institute = this.instituteService.currentInstitute.id;
    this.userService.getAllUsersByInstitute(id_institute).subscribe({
      next: async response => {
        console.debug('getAllUsersByInstitute Backend Response', response);
        if (!response.success) {
          console.error(response.message);
          return;
        }
        const users: User[] = response.data
          ? await Promise.all(
              response.data?.map(async (userData: any): Promise<User> => {
                return await this.userService.parseBackendUser(userData);
              })
            )
          : [];

        this.dataSource = new MatTableDataSource(
          users.filter((user: User) => user.isSuperadmin !== true)
        );

        /* set sortingDataAccessor */
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'email':
              return item.email;
            case 'role':
              return item.id_role;
            default:
              return item[property];
          }
        };

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      error: error => {
        console.error(error);
      },
    });
  }

  /**
   * onEditUser
   */
  public onEditUser(user: User) {
    this.router.navigate(['../user-roles'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onDeleteUser
   */
  public onDeleteUser(user: User) {
    this.userService.deleteUser(user.id).subscribe({
      next: response => {
        console.debug('deleteUser Backend Response', response);
        if (!response.success) {
          console.error(response.message);
          return;
        }
        this.dataSource.data = this.dataSource.data.filter(item => {
          return item.id !== user.id;
        });
      },
      error: error => {
        console.error(error);
      },
    });
  }
}
