<form class="flex-c" [formGroup]="filterForm">
  <div class="filter-wrapper" (click)="$event.stopPropagation()">
    <!-- STUDENT LECTURER FILTER -->
    <!-- label dropdown -->
    <div class="form-group" *ngIf="filterForm.get(filterType.LABEL)">
      <label>Etikett</label>
      <mat-form-field class="hasomed-select">
        <mat-select
          [id]="filterType.LABEL"
          class="hasomed-select"
          [formControlName]="filterType.LABEL"
          [compareWith]="compareLabel">
          <mat-option [value]="null"> </mat-option>
          <mat-option
            [value]="label"
            class="hasomed-text-body"
            *ngFor="let label of labels">
            <div class="label" [style.background]="label.color">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="hasomed-text-small label-text">
                {{ label.name }}
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- COURSE FILTER -->
    <!-- course type -->
    <div class="form-group" *ngIf="filterForm.get(filterType.COURSE_TYPE)">
      <label>Kurs-Typ</label>
      <mat-form-field class="hasomed-select">
        <mat-select
          [id]="filterType.COURSE_TYPE"
          class="hasomed-select"
          [formControlName]="filterType.COURSE_TYPE">
          <mat-option [value]="null"> </mat-option>
          <mat-option
            [value]="type"
            class="hasomed-text-body"
            *ngFor="let type of courseTypes">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- lecturer -->
    <div class="form-group" *ngIf="filterForm.get(filterType.LECTURER)">
      <label>Lehrpersonal</label>
      <mat-form-field class="hasomed-select">
        <mat-select
          [id]="filterType.LECTURER"
          class="hasomed-select"
          [formControlName]="filterType.LECTURER"
          [compareWith]="compareLecturer">
          <mat-option [value]="null"> </mat-option>
          <mat-option
            [value]="lecturer"
            class="hasomed-text-body"
            *ngFor="let lecturer of instituteLecturer">
            {{ lecturer.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- room name -->
    <div class="form-group" *ngIf="filterForm.get(filterType.ROOM_NAME)">
      <label>Raum</label>
      <mat-form-field class="hasomed-select">
        <mat-select
          [id]="filterType.ROOM_NAME"
          class="hasomed-select"
          [formControlName]="filterType.ROOM_NAME">
          <mat-option [value]="null"> </mat-option>
          <mat-option
            [value]="room"
            class="hasomed-text-body"
            *ngFor="let room of rooms">
            {{ room.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- registeredCourses -->
    <mat-slide-toggle
      *ngIf="filterForm.get(filterType.REGISTERED_COURSES)"
      hideIcon
      [formControlName]="filterType.REGISTERED_COURSES"
      (click)="$event.stopPropagation()">
      <div class="toggle-text">
        <span class="hasomed-text-body toggle-text-title">
          Angemeldete Kurse
        </span>
        <span class="hasomed-text-small toggle-text-description">
          Nur angemeldete Kurse anzeigen
        </span>
      </div>
    </mat-slide-toggle>

    <!-- finishedCourses -->
    <mat-slide-toggle
      *ngIf="filterForm.get(filterType.FINISHED_COURSES)"
      hideIcon
      [formControlName]="filterType.FINISHED_COURSES"
      (click)="$event.stopPropagation()">
      <div class="toggle-text">
        <span class="hasomed-text-body toggle-text-title">
          Abgeschlossene Kurse
        </span>
        <span class="hasomed-text-small toggle-text-description">
          Nur abgeschlossene Kurse anzeigen
        </span>
      </div>
    </mat-slide-toggle>

    <!-- openCourses -->
    <mat-slide-toggle
      *ngIf="filterForm.get(filterType.OPEN_COURSES)"
      hideIcon
      [formControlName]="filterType.OPEN_COURSES"
      (click)="$event.stopPropagation()">
      <div class="toggle-text">
        <span class="hasomed-text-body toggle-text-title"> Offene Kurse </span>
        <span class="hasomed-text-small toggle-text-description">
          Nur offene Kurse anzeigen
        </span>
      </div>
    </mat-slide-toggle>

    <!-- pendingELogs -->
    <mat-slide-toggle
      *ngIf="filterForm.get(filterType.PENDING_ELOGS)"
      hideIcon
      [formControlName]="filterType.PENDING_ELOGS"
      (click)="$event.stopPropagation()">
      <div class="toggle-text">
        <span class="hasomed-text-body toggle-text-title">
          Anwesenheit ausstehend
        </span>
        <span class="hasomed-text-small toggle-text-description">
          {{
            eLogUserFilter
              ? 'Nur Lehrpersonal mit ausstehenden Anwesenheiten in Kursen anzeigen'
              : 'Nur Kurse mit ausstehender Anwesenheit.'
          }}
        </span>
      </div>
    </mat-slide-toggle>

    <!-- ROOM FILTER -->
    <!-- room floor -->
    <div class="form-group" *ngIf="filterForm.get(filterType.ROOM_FLOOR)">
      <span class="hasomed-text-small">Etage</span>
      <mat-form-field class="hasomed-select">
        <mat-select
          [id]="filterType.ROOM_FLOOR"
          class="hasomed-select"
          [formControlName]="filterType.ROOM_FLOOR">
          <mat-option [value]="null"> </mat-option>
          <mat-option [value]="0" class="hasomed-text-body"> EG </mat-option>
          <mat-option [value]="1" class="hasomed-text-body"> 1. OG </mat-option>
          <mat-option [value]="2" class="hasomed-text-body"> 2. OG </mat-option>
          <mat-option [value]="3" class="hasomed-text-body"> 3. OG </mat-option>
          <mat-option [value]="4" class="hasomed-text-body"> 4. OG </mat-option>
          <mat-option [value]="5" class="hasomed-text-body"> 5. OG </mat-option>
          <mat-option [value]="6" class="hasomed-text-body"> 6. OG </mat-option>
        </mat-select></mat-form-field
      >
    </div>

    <!-- room capacity -->
    <div class="form-group" *ngIf="filterForm.get(filterType.ROOM_CAPACITY)">
      <span class="hasomed-text-small">Platzanzahl: Mindestens...</span>
      <mat-form-field class="hasomed-select">
        <mat-select
          [id]="filterType.ROOM_CAPACITY"
          class="hasomed-select"
          [formControlName]="filterType.ROOM_CAPACITY">
          <mat-option [value]="null"> </mat-option>
          <mat-option [value]="5" class="hasomed-text-body"> 5 </mat-option>
          <mat-option [value]="10" class="hasomed-text-body"> 10 </mat-option>
          <mat-option [value]="20" class="hasomed-text-body"> 20 </mat-option>
          <mat-option [value]="30" class="hasomed-text-body"> 30 </mat-option>
          <mat-option [value]="40" class="hasomed-text-body"> 40 </mat-option>
          <mat-option [value]="50" class="hasomed-text-body"> 50 </mat-option>
        </mat-select></mat-form-field
      >
    </div>

    <mat-slide-toggle
      *ngIf="filterForm.get(filterType.ROOM_ACTIVE)"
      hideIcon
      [formControlName]="filterType.ROOM_ACTIVE"
      (click)="$event.stopPropagation()">
      <div class="toggle-text">
        <span class="hasomed-text-body toggle-text-title"> Aktive Räume </span>
        <span class="hasomed-text-small toggle-text-description">
          Nur aktive Räume anzeigen
        </span>
      </div>
    </mat-slide-toggle>

    <!-- ROOM ORGANIZATION -->
    <!-- open room planning toggle -->
    <mat-slide-toggle
      *ngIf="filterForm.get(filterType.OPEN_ROOMPLANNING)"
      hideIcon
      [formControlName]="filterType.OPEN_ROOMPLANNING"
      (click)="$event.stopPropagation()">
      <div class="toggle-text">
        <span class="hasomed-text-body toggle-text-title">
          Raumplanung offen
        </span>
        <span class="hasomed-text-small toggle-text-description">
          Nur Veranstaltungen mit offener Raumplanung anzeigen
        </span>
      </div>
    </mat-slide-toggle>

    <div class="buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey m-z"
        (click)="clearFilter()">
        <span class="hasomed-button-text">Zurücksetzen</span>
      </button>

      <button
        type="button"
        class="hasomed-text-button hasomed-button-orange"
        (click)="applyFilter()">
        <i class="fa-light fa-filter hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Anwenden</span>
      </button>
    </div>
  </div>
</form>
