import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ProgressType,
  StudentEducationProgress,
} from 'src/app/models/education-progress.model';
import { Role } from 'src/app/models/permission.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-education-progress-dialog',
  templateUrl: './education-progress-dialog.component.html',
  styleUrls: ['./education-progress-dialog.component.scss'],
})
export class EducationProgressDialogComponent {
  public user: User;
  public educationProgress: StudentEducationProgress[] = [];
  public showName: boolean = true;
  role = Role;

  constructor(
    public dialogRef: MatDialogRef<EducationProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { user: User; educationProgress: any; showName: boolean },
    private router: Router,
    public userService: UserService
  ) {
    this.user = data.user;
    this.educationProgress = data.educationProgress;
    this.showName = data.showName;

    console.debug('user', this.user);
    console.debug('educationProgress', this.educationProgress);
  }

  ngOnInit() {}

  /**
   * onClose
   * Closes the dialog
   * @returns void
   */
  public onClose(): void {
    this.dialogRef.close();
  }

  /**
   * goToPage
   * Redirects to the given page
   * @param type
   */
  public goToPage(type: ProgressType) {
    switch (type) {
      case ProgressType.THEORETICAL:
        this.dialogRef.close();
        this.router.navigate(['eleguide/education/theoretical-education']);
        break;
      case ProgressType.PRACTICAL_1:
        this.dialogRef.close();
        this.router.navigate(['eleguide/education/practical-work']);
        break;
      case ProgressType.PRACTICAL_2:
        this.dialogRef.close();
        this.router.navigate(['eleguide/education/practical-work']);
        break;
      case ProgressType.TREATMENT:
        this.dialogRef.close();
        this.router.navigate(['eleguide/education/ambulatory-part']);
        break;
      case ProgressType.SUPERVISION:
        this.dialogRef.close();
        this.router.navigate(['eleguide/education/ambulatory-part']);
        break;
      case ProgressType.SELF_AWARENESS:
        this.dialogRef.close();
        this.router.navigate(['eleguide/education/self-awareness']);
        break;
      case ProgressType.TEACHING_ANALYSIS:
        this.dialogRef.close();
        this.router.navigate(['eleguide/education/self-awareness']);
        break;
      case ProgressType.TOTAL:
        this.dialogRef.close();
        this.router.navigate(['eleguide/organization/education-progress']);
        break;
      default:
        break;
    }
  }

  /**
   * getTooltip
   */
  public getTooltip(progressType: ProgressType) {
    switch (progressType) {
      case ProgressType.THEORETICAL:
        return 'Zur Kursübersicht';
      case ProgressType.PRACTICAL_1:
        return 'Zur Praktischen Tätigkeit';
      case ProgressType.PRACTICAL_2:
        return 'Zur Praktischen Tätigkeit';
      case ProgressType.TREATMENT:
        return 'Zu den Behandlungsfällen';
      case ProgressType.SELF_AWARENESS:
        return 'Zur Selbsterfahrung';
      case ProgressType.TEACHING_ANALYSIS:
        return 'Zur Lehranalyse';
      case ProgressType.SUPERVISION:
        return 'Zur Supervision';
      case ProgressType.TOTAL:
        return 'Zum Ausbildungsfortschritt';
      default:
        return '';
    }
  }
}
