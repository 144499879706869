<div class="table">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- email column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>E-Mail</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="email">
        </app-custom-table-sort-icon>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.email }}
      </td>
    </ng-container>

    <!-- role column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Rolle</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="role">
        </app-custom-table-sort-icon>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.id_role === 2 ? 'Verwaltung' : '' }}
        {{ row.id_role === 3 ? 'Lehrpersonal' : '' }}
        {{ row.id_role === 4 ? 'Kandidat*in' : '' }}
      </td>
    </ng-container>

    <!-- Actions column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div class="table-button-icons">
          <button
            type="button"
            class="table-button-icon"
            matTooltip="Benutzer löschen"
            (click)="onDeleteUser(row.user)">
            <i class="fa-light fa-trash-can"></i>
          </button>
          <button
            type="button"
            class="table-button-icon"
            matTooltip="Benutzer bearbeiten"
            (click)="onEditUser(row.user)">
            <i class="fa-light fa-pen"></i>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td
        class="mat-cell table-no-match hasomed-text-body font-grey"
        [attr.colspan]="displayedColumns.length">
        Keinen Nutzer angelegt.
      </td>
    </tr>
  </table>
  <mat-divider></mat-divider>
  <mat-paginator
    custom-paginator
    [appCustomLength]="dataSource.filteredData.length"
    [pageSize]="7"
    [renderButtonsNumber]="1"
    aria-label="Select page"></mat-paginator>
</div>
