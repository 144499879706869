import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { Role } from 'src/app/models/permission.model';
import { User } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';
import { EditRoleDialogComponent } from './edit-role-dialog/edit-role-dialog.component';
import * as moment from 'moment';
import { ImageDetailDialogComponent } from 'src/app/components/shared-components/image-detail-dialog/image-detail-dialog.component';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss'],
})
export class RoleManagementComponent {
  public searchForm: FormGroup;
  public isLoading = true;

  public displayedColumns: string[] = ['user', 'role', 'entryDate', 'actions'];
  public dataSource: MatTableDataSource<User> = new MatTableDataSource();
  public tableData: Subject<User[]> = new Subject();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      searchText: new FormControl(''),
    });

    this.getData();

    this.tableData.subscribe(users => {
      console.debug('users', users);
      this.dataSource = new MatTableDataSource<User>(users);

      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'user':
            return item.name.firstname + ' ' + item.name.lastname;
          case 'role':
            return item.id_role;
          case 'entryDate':
            return item.entryDate;
          default:
            return item[property];
        }
      };

      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr =
          data.name.firstname?.toLowerCase() +
          ' ' +
          data.name.lastname?.toLowerCase() +
          (data.id_role === Role.ADMINISTRATOR
            ? 'verwaltung'
            : data.id_role === Role.LECTURER
              ? 'lehrpersonal'
              : data.id_role === Role.STUDENT
                ? 'kandidat'
                : '') +
          moment(data.entryDate).format('DD.MM.YYYY');
        return dataStr.indexOf(filter) != -1;
      };

      this.isLoading = false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  /**
   * getData
   * Get all users by institute and set the table data source
   * @returns void
   */
  private async getData(): Promise<void> {
    const id_institute = this.userService.currentUser.id_institute;
    this.userService.getAllUsersByInstitute(id_institute).subscribe({
      next: async response => {
        if (!response.success) {
          console.error(response.message);
          this.isLoading = false;
          return;
        }
        console.debug('getAllUsersByInstitute response', response);

        this.tableData.next(
          response.data
            ? await Promise.all(
                response.data
                  ?.map(async (userData: User): Promise<User> => {
                    return await this.userService.parseBackendUser(userData);
                  })
                  .filter((user: User) => user.isSuperadmin !== true)
              )
            : []
        );
      },
      error: error => {
        console.error(error);
        this.isLoading = false;
      },
    });
  }

  /**
   * applyFilter
   * Filter the table
   * @param event
   * @returns void
   */
  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * getRoleName
   * Get the role name based on the role id
   * @returns void
   */
  public getRoleName(role: number): string {
    switch (role) {
      case Role.ADMINISTRATOR:
        return 'Verwaltung';
      case Role.LECTURER:
        return 'Lehrpersonal';
      case Role.STUDENT:
        return 'Kandidat';
      default:
        return '-';
    }
  }

  /**
   * getLabelBackgroundColor
   * Get the label background color
   * @param role
   * @returns
   */
  public getLabelBackgroundColor(role: number): string {
    switch (role) {
      case Role.ADMINISTRATOR:
        return '#49881D';
      case Role.LECTURER:
        return '#20BBAB';
      case Role.STUDENT:
        return '#79B63F';
      default:
        return '#BBBDBE';
    }
  }

  /**
   * openEditRoleDialog
   * Open the edit user dialog
   * @param user
   * @returns void
   */
  public openEditUserDialog(user: User): void {
    this.dialog.open(EditRoleDialogComponent, {
      data: {
        user: user,
      },
    });
  }

  /**
   * openDeleteRoleDialog
   * Open the delete user dialog
   * @param user
   * @returns void
   */
  public openDeleteUserDialog(user: User): void {
    this.alertService.showInfoAlert(
      '',
      'Diese Funktion ist noch nicht verfügbar'
    );
  }

  /**
   * openProfilePictureDialog
   * opens the profile picture dialog
   * @param user
   */
  public openProfilePictureDialog(user: User): void {
    this.dialog.open(ImageDetailDialogComponent, {
      data: {
        image: user.profilePicture,
        viewOnly: true,
      },
    });
  }
}
