import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { ChangePasswordDialogComponent } from 'src/app/components/shared-components/change-password-dialog/change-password-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { ImageDetailDialogComponent } from 'src/app/components/shared-components/image-detail-dialog/image-detail-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { CustomFileType } from 'src/app/models/custom-file-type.model';
import { User } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-study-administration-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
})
export class PersonalDataComponent implements OnInit, OnDestroy {
  public initialFormValues: {};
  public user: User;
  public today = moment();
  public isLoading = true;
  public uploadedFiles: CustomFileType[] = [];
  public existingFiles: CustomFileType[] = [];
  public imageUrl: string | ArrayBuffer | null;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formDeactivateService: FormDeactivateService,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.getUserData(this.userService.currentUser.id);
  }

  public requiredFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'GIF', mimeType: 'image/gif' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];
  public requiredFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  public getUserData(user_id: number) {
    this.userService
      .getUserById(user_id, 'educationCourse')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getUserById Backend Response', response);
          if (!response.success) {
            console.error(response.message);
            return;
          }
          this.user = await this.userService.parseBackendUser(response.data);

          this.user.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          console.debug('User', this.user);
          this.isLoading = false;

          // if measlesProtection is not set and currentUser is Student, show alert
          if (
            this.user.measlesProtection === false &&
            (this.userService.currentUserIsStudent() ||
              this.userService.currentUserIsLecturer())
          ) {
            this.alertService.showInfoAlert(
              'Impfnachweis fehlt',
              'Bitte reichen Sie Ihren Impfnachweis für Masern bei der Verwaltung ein.',
              20000
            );
          }
        },
      });
  }

  public async onEdit() {
    this.router.navigate(['edit'], {
      relativeTo: this.activatedRoute,
    });
  }

  onCancel() {
    this.router.navigate(['../../'], {
      relativeTo: this.activatedRoute,
    });
  }

  public openProfilePictureDialog(): void {
    const dialogRef = this.dialog.open(ImageDetailDialogComponent, {
      data: {
        image: this.user.profilePicture,
        viewOnly: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.debug('The dialog was closed', result);
    });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
