import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import { AuditLogService } from 'src/app/services/audit-log.service';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
})
export class AuditLogComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public filters: any;
  public availableFilters = {
    advancedFilters: null,
    defaultFilters: null,
  };
  public filterOpened: boolean = false;
  public filterEmpty: boolean = true;

  public searchForm: FormGroup;

  public displayedColumns = [
    'title',
    'oldValue',
    'newValue',
    'responsiblePerson',
    'date',
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private auditLogService: AuditLogService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.auditLogService
      .getAllAuditLogs()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('getAllAuditLogs backend response', response);
          if (!response.success) {
            return;
          }
          this.dataSource.data = response.data
            ? await Promise.all(
                response.data?.map(async (auditLog: any): Promise<any> => {
                  return await this.auditLogService.parseAuditLogObject(
                    auditLog
                  );
                })
              )
            : [];
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;

          this.dataSource.filterPredicate = (data, filter) => {
            const dataStr = data.title + data.subtitle + data.responsiblePerson;
            return dataStr.toLowerCase().indexOf(filter) != -1;
          };
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });

    this.searchForm = new FormGroup({
      searchText: new FormControl(''),
    });
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
