import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  CreateFileModel,
  CustomFileType,
  UpdateFileModel,
} from 'src/app/models/custom-file-type.model';
import { Role } from 'src/app/models/permission.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FileService } from 'src/app/services/file.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-create-edit-file',
  templateUrl: './create-edit-file.component.html',
  styleUrl: './create-edit-file.component.scss',
})
export class CreateEditFileComponent implements OnInit, OnDestroy {
  public editMode = false;
  public isLoading = true;
  public form: FormGroup = new FormGroup({
    filename: new FormControl(''),
    file: new FormControl(null, Validators.required),
    fileUpload: new FormControl(null),
    existingFiles: new FormControl(null),
    rolesWithAccess: new FormControl(null, Validators.required),
  });
  private initialFormValues: any;
  private currentFile: CustomFileType;

  public uploadedFile: CustomFileType;
  public existingFiles: CustomFileType[] = [];

  public allowedFileTypes: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  // import from form.utils.ts
  public isRequired = isRequired;

  public roles = Role;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.form.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private location: Location,
    private fileService: FileService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.editMode = true;
        this.getFile(+atob(params.id));
      }
      this.initialFormValues = this.form.value;
    });

    // subscribe to uploadedFiles changes and convert them to base64
    this.form.get('fileUpload').valueChanges.subscribe((value: any) => {
      if (!value || value.length === 0) {
        this.form.get('file').setValue(null);
        return;
      }
      let newDocument: CustomFileType;

      value.forEach((file: any) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          newDocument = {
            id: file.id,
            filename: file.name,
            file: e.target.result,
            fileSize: file.size,
            creator: !file.creator
              ? this.userService.currentUser
              : file.creator,
            timeCreated: file.timeCreated,
            timeModified: file.timeModified,
          };

          this.form.get('file').setValue(newDocument);
        };
        reader.readAsDataURL(file);
      });
    });
  }

  private getFile(id: number): void {
    const id_institute = this.userService.currentUser.id_institute;
    this.fileService
      .getInstituteFile(id, id_institute)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('file Backend response', response);
          if (!response.success) {
            console.error('File could not be loaded');
            this.isLoading = false;
            return;
          }

          this.currentFile = await this.fileService.parseBackendFile(
            response.data?.file
          );

          this.existingFiles.push(this.currentFile);

          this.form.patchValue({
            filename: this.currentFile.filename,
            rolesWithAccess: response.data.rolesWithAccess,
            uploadedFile: this.uploadedFile,
            existingFiles: this.existingFiles,
          });

          // make file not required and filename required
          this.form.get('file').setValidators(null);
          this.form.get('filename').setValidators(Validators.required);
          this.form.get('file').updateValueAndValidity();
          this.form.get('filename').updateValueAndValidity();

          this.initialFormValues = this.form.value;
          this.isLoading = false;
        },
        error: error => {
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  public onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    // this.alertService.showSuccessAlert(
    //   'Das hat geklappt!',
    //   'Die Datei wurde erfolgreich hochgeladen.'
    // );
    this.isLoading = true;
    this.editMode ? this.updateFile() : this.uploadFile();
  }

  private uploadFile(): void {
    const id_institute = this.userService.currentUser.id_institute;
    const createFile: CreateFileModel = {
      file: this.form.value.file,
      id_institute: id_institute,
      rolesWithAccess: this.form.value.rolesWithAccess,
    };
    console.debug('create file', createFile);
    this.fileService.createFile(createFile).subscribe({
      next: async response => {
        console.debug('uploadFile Backend response', response);
        if (!response.success) {
          console.error('File could not be uploaded');
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat nicht geklappt!',
            'Die Datei konnte nicht hochgeladen werden.'
          );
          return;
        }
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Die Datei wurde erfolgreich hochgeladen.'
        );
        this.isLoading = false;
        this.initialFormValues = this.form.value;
        this.onCancel();
      },
      error: error => {
        console.error(error);
        this.isLoading = false;
        this.alertService.showErrorAlert(
          'Das hat nicht geklappt!',
          'Die Datei konnte nicht hochgeladen werden.'
        );
      },
    });
  }

  /**
   * updateFile
   * edit the file
   */
  private updateFile(): void {
    const id_institute = this.userService.currentUser.id_institute;
    const updateFile: UpdateFileModel = {
      id: this.currentFile.id,
      filename: this.form.value.filename,
      file: this.form.value.file,
      id_institute: id_institute,
      rolesWithAccess: this.form.value.rolesWithAccess,
    };

    console.debug('update file', updateFile);
    this.fileService.updateInstituteFile(updateFile).subscribe({
      next: async response => {
        console.debug('updateFile Backend response', response);
        if (!response.success) {
          console.error('File could not be updated');
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat nicht geklappt!',
            'Die Datei konnte nicht aktualisiert werden.'
          );
          return;
        }
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Die Datei wurde erfolgreich aktualisiert.'
        );
        this.isLoading = false;
        this.initialFormValues = this.form.value;
        this.onCancel();
      },
      error: error => {
        console.error(error);
        this.isLoading = false;
        this.alertService.showErrorAlert(
          'Das hat nicht geklappt!',
          'Die Datei konnte nicht aktualisiert werden.'
        );
      },
    });
  }

  public onCancel(): void {
    this.location.back();
  }

  /**
   * onDeleteExistingFile
   */
  public onDeleteExistingFile() {
    this.existingFiles = [];
    this.form.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.form.value,
      this.initialFormValues
    );
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
