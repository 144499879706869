<div class="flex-c flex-grow">
  <div
    class="flex-c table course-details-main"
    [class.table-bicolor]="dataSource?.filteredData?.length > 1">
    <table
      mat-table
      [dataSource]="dataSource"
      class="course-details-table"
      matSort>
      <!-- lecturer column / using firstname for sorting -->
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Lehrpersonal
          <app-custom-table-sort-icon [sort]="sort" columnName="user">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let lecturer">
          <div class="user-container">
            <div class="user-icon">
              <img
                [attr.src]="
                  lecturer.profilePicture ??
                  '../../../../../assets/img/user.jpg'
                "
                alt=""
                class="user-icon-image" />
            </div>
            <div class="user-info">
              <span class="gray-900 hasomed-text-body">
                {{ lecturer.name.academicTitle }}
                {{ lecturer.name.firstname }}
                {{ lecturer.name.lastname }}
              </span>
              <span class="user-info hasomed-text-small"> Lehrpersonal </span>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- email column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Email
          <app-custom-table-sort-icon [sort]="sort" columnName="email">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let lecturer">
          {{ lecturer.email }}
        </td>
      </ng-container>

      <!-- additionalQualifications column -->
      <ng-container matColumnDef="additionalQualifications">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Zusatz Qualifikationen
          <app-custom-table-sort-icon
            [sort]="sort"
            columnName="additionalQualifications">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let lecturer">
          <span
            *ngFor="
              let qualification of lecturer.additionalQualifications;
              let last = last
            ">
            {{ qualification.name }}{{ !last ? ', ' : '' }}
          </span>
          <span *ngIf="!lecturer.additionalQualifications"> - </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td [attr.colspan]="columnsToDisplay.length">
          <div *ngIf="isLoading" class="loading-wrapper">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate"
              diameter="32">
            </mat-progress-spinner>
          </div>
          <div
            *ngIf="!isLoading"
            class="mat-cell table-no-match hasomed-text-body font-grey">
            Es wurde kein Lehrpersonal gefunden.
          </div>
        </td>
      </tr>
    </table>

    <mat-divider></mat-divider>
    <mat-paginator
      custom-paginator
      [appCustomLength]="dataSource.filteredData.length"
      [pageSize]="5"
      [renderButtonsNumber]="1"
      aria-label="Select page"></mat-paginator>
  </div>
  <div class="vertical-spacer"></div>
</div>
