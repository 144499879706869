<div class="table">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- Title column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Kurse</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="title">
        </app-custom-table-sort-icon>
      </th>
      <td mat-cell *matCellDef="let course" class="title-cell">
        <div class="flex-c cell-overflow-hidden">
          <span
            class="hasomed-text-body text-overflow-hidden"
            [matTooltip]="course.title">
            {{ course.title }}
          </span>
        </div>
      </td>
    </ng-container>

    <!-- Lecturer column -->
    <ng-container matColumnDef="lecturer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Lehrpersonal</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="lecturer">
        </app-custom-table-sort-icon>
      </th>
      <td mat-cell *matCellDef="let course" class="lecturer-cell">
        <div class="flex-r cell-overflow-hidden">
          <span
            class="text-overflow-hidden"
            [matTooltip]="getFullLecturerNames(course)">
            {{ getFullLecturerNames(course) }}
          </span>
        </div>
      </td>
    </ng-container>

    <!-- Course Dates Column -->
    <ng-container matColumnDef="events">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Kurstermine</span>
        <app-custom-table-sort-icon [sort]="sort" columnName="events">
        </app-custom-table-sort-icon>
      </th>
      <td class="gray-600" mat-cell *matCellDef="let course">
        <span class="">
          {{ course.startDate | date: 'dd.MM.yyyy' }}
        </span>
        <span *ngIf="course.startDate != course.endDate">
          - {{ course.endDate | date: 'dd.MM.yyyy' }}
        </span>
      </td>
    </ng-container>

    <!-- Edit column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let course">
        <div class="table-button-icons">
          <button
            type="button"
            class="table-button-icon"
            matTooltip="Kurs löschen"
            (click)="onDeleteCourse(course)">
            <i class="fa-light fa-trash-can"></i>
          </button>
          <button
            type="button"
            class="table-button-icon"
            matTooltip="Kurs bearbeiten"
            (click)="onEditCourse(course.id)">
            <i class="fa-light fa-pen"></i>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td [attr.colspan]="displayedColumns.length">
        <div *ngIf="isLoading" class="loading-wrapper">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate"
            diameter="32">
          </mat-progress-spinner>
        </div>
        <div
          *ngIf="!isLoading"
          class="mat-cell table-no-match hasomed-text-body font-grey">
          Keinen Kurs angelegt.
        </div>
      </td>
    </tr>
  </table>
  <mat-divider></mat-divider>
  <mat-paginator
    custom-paginator
    [appCustomLength]="dataSource.filteredData.length"
    [pageSize]="7"
    [renderButtonsNumber]="1"
    aria-label="Select page"></mat-paginator>
</div>
