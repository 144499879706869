import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-course-lecturers',
  templateUrl: './course-lecturers.component.html',
  styleUrls: ['./course-lecturers.component.scss'],
})
export class CourseLecturersComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<User> = new MatTableDataSource<User>();
  public columnsToDisplay = ['user', 'additionalQualifications', 'email'];
  public isLoading = true;
  public isStudent: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit(): void {
    const id_course = this.activatedRoute.snapshot.paramMap.get('id')
      ? +atob(this.activatedRoute.snapshot.paramMap.get('id'))
      : +atob(this.activatedRoute.parent?.snapshot.paramMap.get('id'));

    this.courseService
      .getCourseLecturers(id_course)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          console.debug('courseLecturers Backend response', response);
          if (!response.success) {
            console.error('Course lecturers could not be loaded');
            this.isLoading = false;
            return;
          }
          this.dataSource.data = response.data
            ? await Promise.all(
                response.data.map(async (lecturer: any) => {
                  return await this.userService.parseBackendUser(lecturer);
                })
              )
            : [];
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'user':
                return item.name.firstname;
              case 'additionalQualifications':
                return item.additionalQualifications
                  ? item.additionalQualifications[0]?.name
                  : null;
              default:
                return item[property];
            }
          };
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.isLoading = false;
        },
        error: error => {
          console.error('Course lecturers could not be loaded', error);
          this.isLoading = false;
        },
      });
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
