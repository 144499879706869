import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PermissionService } from '../services/permission.service';

export const featureGuard: CanActivateFn = (route, state): boolean => {
  const permissionService = inject(PermissionService);
  const router = inject(Router);

  if (
    permissionService.checkPermission(route.data.feature, route.data.permission)
  ) {
    return true;
  } else {
    router.navigateByUrl('/eleguide/dashboard');
  }
  return false;
};
