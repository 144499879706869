import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-session-warning',
  templateUrl: './session-warning.component.html',
  styleUrls: ['./session-warning.component.scss'],
})
export class SessionWarningComponent {
  constructor(
    public dialogRef: MatDialogRef<SessionWarningComponent>,
    private authService: AuthService
  ) {
    dialogRef.disableClose = true;
  }

  onRenewSession() {
    console.debug('Refresh session');
    // call tgp status endpoint to refresh session
    this.authService.checkTgpOnline().subscribe({
      next: response => {
        if (response.status == 200) {
          this.dialogRef.close();
        }
      },
    });
  }

  onDismiss() {
    this.dialogRef.close();
  }
}
