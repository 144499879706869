import { AbstractControl, FormControl, Validators } from '@angular/forms';

/**
 * isRequired
 * checks if the formControl is required
 * @param formControl formControl of the formGroup
 * @returns boolean
 */
export function isRequired(
  formControl: FormControl | AbstractControl
): boolean {
  if (!formControl) {
    return false;
  }

  return formControl.hasValidator(Validators.required);
}
