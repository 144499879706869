import { Pipe, PipeTransform } from '@angular/core';
import { NewsArticle } from '../models/news-article.model';

@Pipe({
  name: 'filterNewsArticles',
  pure: false,
})
export class FilterNewsArticlesPipe implements PipeTransform {
  transform(
    newsArticles: NewsArticle[],
    showOnlyPublished: boolean = true
  ): any[] {
    if (!newsArticles) {
      return [];
    }

    if (!showOnlyPublished) {
      return newsArticles;
    }

    return newsArticles.filter(newsArticle => newsArticle.published === true);
  }
}
